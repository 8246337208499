import React from "react";
type scrollDirections = "up" | "down" | "none";

export function useScrollComputed<T>({
  computeValue,
  callback,
  debug,
}: {
  computeValue: (data: [number, scrollDirections]) => T;
  callback?: (value: T) => void;
  debug?: string;
}): T {
  const scrollY = React.useRef(window.scrollY);
  const direction = React.useRef<scrollDirections>("none");
  const touchStart = React.useRef(0);
  const [value, setValue] = React.useState<T>(computeValue([scrollY.current, direction.current]));
  const refValue = React.useRef<T>(value);

  const tryUpdate = React.useCallback(() => {
    const _value = computeValue([scrollY.current, direction.current]);
    if (_value !== refValue.current) {
      refValue.current = _value;
      if (callback) callback(_value);
      else setValue(_value);
      if (debug) console.log(`ScrollComputed ${debug} change to ${_value}`);
    }
  }, [computeValue, callback, debug]);

  React.useEffect(() => {
    const handleScrollChange = () => {
      scrollY.current = window.scrollY ?? 0;
      tryUpdate();
    };

    window.addEventListener("scroll", handleScrollChange);
    return () => {
      window.removeEventListener("scroll", handleScrollChange);
    };
  }, [tryUpdate]);

  React.useEffect(() => {
    const handleWheelChange = (e: WheelEvent) => {
      const diff = e.deltaY ?? 0;
      if (diff > 0) {
        direction.current = "down";
      } else if (diff < 0) {
        direction.current = "up";
      } else {
        direction.current = "none";
      }
      tryUpdate();
    };

    //https://reactjs.org/docs/events.html#wheel-events
    window.addEventListener("wheel", handleWheelChange);
    return () => {
      window.removeEventListener("wheel", handleWheelChange);
    };
  }, [tryUpdate]);

  React.useEffect(() => {
    const handleTouchStart = (e: TouchEventInit) => {
      touchStart.current = e.touches?.[0]?.pageY ?? 0;
    };

    const handleTouchEnd = (e: TouchEventInit) => {
      const touchEnd = e.changedTouches?.[0]?.pageY ?? touchStart.current;
      const diff = touchEnd - touchStart.current;
      if (diff > 0) {
        direction.current = "up";
      } else if (diff < 0) {
        direction.current = "down";
      } else {
        direction.current = "none";
      }
      tryUpdate();
    };

    //https://developer.mozilla.org/en-US/docs/Web/API/Touch_events
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [tryUpdate]);

  return value;
}

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PatchComparablePropertyRecurringCostItemDto,
    PatchComparablePropertyRecurringCostItemDtoFromJSON,
    PatchComparablePropertyRecurringCostItemDtoFromJSONTyped,
    PatchComparablePropertyRecurringCostItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpsertComparablePropertyRequestDto
 */
export interface UpsertComparablePropertyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    mlsId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    mlsSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    area?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    bathCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    bedCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    lat?: number;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    lng?: number;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    listingStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    garageSpaces?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    hoaFeeCents?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    floor?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UpsertComparablePropertyRequestDto
     */
    listDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    listPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    livingAreaSqFt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    lotSizeSqFt?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    neighborhood?: string;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    nonGarageSpaces?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpsertComparablePropertyRequestDto
     */
    styles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    styleOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    typeOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    yearBuilt?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    renovationType?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertComparablePropertyRequestDto
     */
    hasAdditionalLivingSpace?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    additionalLivingSpaceSqFt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    soldPrice?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof UpsertComparablePropertyRequestDto
     */
    soldDate?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    county?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertComparablePropertyRequestDto
     */
    hasDoormanAttendance?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpsertComparablePropertyRequestDto
     */
    isLease?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpsertComparablePropertyRequestDto
     */
    roomsTotal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsertComparablePropertyRequestDto
     */
    flipTaxRemarks?: string | null;
    /**
     * 
     * @type {Array<PatchComparablePropertyRecurringCostItemDto>}
     * @memberof UpsertComparablePropertyRequestDto
     */
    recurringCosts?: Array<PatchComparablePropertyRecurringCostItemDto> | null;
}

export function UpsertComparablePropertyRequestDtoFromJSON(json: any): UpsertComparablePropertyRequestDto {
    return UpsertComparablePropertyRequestDtoFromJSONTyped(json, false);
}

export function UpsertComparablePropertyRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertComparablePropertyRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mlsId': !exists(json, 'mlsId') ? undefined : json['mlsId'],
        'mlsSource': !exists(json, 'mlsSource') ? undefined : json['mlsSource'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'bathCount': !exists(json, 'bathCount') ? undefined : json['bathCount'],
        'bedCount': !exists(json, 'bedCount') ? undefined : json['bedCount'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lng': !exists(json, 'lng') ? undefined : json['lng'],
        'listingStatus': !exists(json, 'listingStatus') ? undefined : json['listingStatus'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'garageSpaces': !exists(json, 'garageSpaces') ? undefined : json['garageSpaces'],
        'hoaFeeCents': !exists(json, 'hoaFeeCents') ? undefined : json['hoaFeeCents'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'listDate': !exists(json, 'listDate') ? undefined : (json['listDate'] === null ? null : new Date(json['listDate'])),
        'listPrice': !exists(json, 'listPrice') ? undefined : json['listPrice'],
        'livingAreaSqFt': !exists(json, 'livingAreaSqFt') ? undefined : json['livingAreaSqFt'],
        'lotSizeSqFt': !exists(json, 'lotSizeSqFt') ? undefined : json['lotSizeSqFt'],
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'nonGarageSpaces': !exists(json, 'nonGarageSpaces') ? undefined : json['nonGarageSpaces'],
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'styleOther': !exists(json, 'styleOther') ? undefined : json['styleOther'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeOther': !exists(json, 'typeOther') ? undefined : json['typeOther'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'yearBuilt': !exists(json, 'yearBuilt') ? undefined : json['yearBuilt'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'renovationType': !exists(json, 'renovationType') ? undefined : json['renovationType'],
        'hasAdditionalLivingSpace': !exists(json, 'hasAdditionalLivingSpace') ? undefined : json['hasAdditionalLivingSpace'],
        'additionalLivingSpaceSqFt': !exists(json, 'additionalLivingSpaceSqFt') ? undefined : json['additionalLivingSpaceSqFt'],
        'soldPrice': !exists(json, 'soldPrice') ? undefined : json['soldPrice'],
        'soldDate': !exists(json, 'soldDate') ? undefined : (json['soldDate'] === null ? null : new Date(json['soldDate'])),
        'county': !exists(json, 'county') ? undefined : json['county'],
        'hasDoormanAttendance': !exists(json, 'hasDoormanAttendance') ? undefined : json['hasDoormanAttendance'],
        'isLease': !exists(json, 'isLease') ? undefined : json['isLease'],
        'roomsTotal': !exists(json, 'roomsTotal') ? undefined : json['roomsTotal'],
        'flipTaxRemarks': !exists(json, 'flipTaxRemarks') ? undefined : json['flipTaxRemarks'],
        'recurringCosts': !exists(json, 'recurringCosts') ? undefined : (json['recurringCosts'] === null ? null : (json['recurringCosts'] as Array<any>).map(PatchComparablePropertyRecurringCostItemDtoFromJSON)),
    };
}

export function UpsertComparablePropertyRequestDtoToJSON(value?: UpsertComparablePropertyRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'area': value.area,
        'bathCount': value.bathCount,
        'bedCount': value.bedCount,
        'lat': value.lat,
        'lng': value.lng,
        'listingStatus': value.listingStatus,
        'buildingName': value.buildingName,
        'city': value.city,
        'description': value.description,
        'garageSpaces': value.garageSpaces,
        'hoaFeeCents': value.hoaFeeCents,
        'floor': value.floor,
        'listDate': value.listDate === undefined ? undefined : (value.listDate === null ? null : value.listDate.toISOString()),
        'listPrice': value.listPrice,
        'livingAreaSqFt': value.livingAreaSqFt,
        'lotSizeSqFt': value.lotSizeSqFt,
        'neighborhood': value.neighborhood,
        'nonGarageSpaces': value.nonGarageSpaces,
        'styles': value.styles,
        'styleOther': value.styleOther,
        'type': value.type,
        'typeOther': value.typeOther,
        'state': value.state,
        'yearBuilt': value.yearBuilt,
        'zipCode': value.zipCode,
        'renovationType': value.renovationType,
        'hasAdditionalLivingSpace': value.hasAdditionalLivingSpace,
        'additionalLivingSpaceSqFt': value.additionalLivingSpaceSqFt,
        'soldPrice': value.soldPrice,
        'soldDate': value.soldDate === undefined ? undefined : (value.soldDate === null ? null : value.soldDate.toISOString()),
        'county': value.county,
        'hasDoormanAttendance': value.hasDoormanAttendance,
        'isLease': value.isLease,
        'roomsTotal': value.roomsTotal,
        'flipTaxRemarks': value.flipTaxRemarks,
        'recurringCosts': value.recurringCosts === undefined ? undefined : (value.recurringCosts === null ? null : (value.recurringCosts as Array<any>).map(PatchComparablePropertyRecurringCostItemDtoToJSON)),
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReorderComparablePropertiesItem,
    ReorderComparablePropertiesItemFromJSON,
    ReorderComparablePropertiesItemFromJSONTyped,
    ReorderComparablePropertiesItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ReorderComparablePropertiesRequest
 */
export interface ReorderComparablePropertiesRequest {
    /**
     * 
     * @type {Array<ReorderComparablePropertiesItem>}
     * @memberof ReorderComparablePropertiesRequest
     */
    order: Array<ReorderComparablePropertiesItem>;
}

export function ReorderComparablePropertiesRequestFromJSON(json: any): ReorderComparablePropertiesRequest {
    return ReorderComparablePropertiesRequestFromJSONTyped(json, false);
}

export function ReorderComparablePropertiesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReorderComparablePropertiesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'order': ((json['order'] as Array<any>).map(ReorderComparablePropertiesItemFromJSON)),
    };
}

export function ReorderComparablePropertiesRequestToJSON(value?: ReorderComparablePropertiesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'order': ((value.order as Array<any>).map(ReorderComparablePropertiesItemToJSON)),
    };
}



import { PresentationEditorStep } from "./PresentationEditorStep";
import { PresentationEditorLogicProvider } from "./usePresentationEditorLogic";

export const SmartPresentationEditorStep = ({ cmaId }: { cmaId: string }) => {
  return (
    <PresentationEditorLogicProvider cmaId={cmaId}>
      <PresentationEditorStep id={cmaId} />
    </PresentationEditorLogicProvider>
  );
};

import * as React from "react";
import { Typography } from "@avenue-8/ui-2";
import { mlsList } from "src/modules/shared/constants/supported-mlss";
import { Trigger, Popover, MlsContainer, MlsItem, MlsLogo, MlsName } from "./MlsPopover.styles";

const buildKey = (mls: string) => mls.replace(/[^a-zA-Z]/g, "");

interface Props {
  /**
   * height in px
   * @default 24
   * */
  height?: number;
  id?: string;
}

export const MlsPopover = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { height = 24, id = "supported-mls-list-popover" } = props;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Trigger
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}
      >
        <img src="/images/mls_black.png" alt="MLS" height={`${height}px`} />
      </Trigger>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverOpen}
        sx={{
          pointerEvents: "none",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="body2">List of supported MLS'S</Typography>
        <MlsContainer container>
          {mlsList.map((mls) => (
            <MlsItem key={buildKey(mls.name)}>
              <MlsLogo>
                <img src={mls.logo} alt={mls.name} />
              </MlsLogo>
              <MlsName variant="body2">{mls.name}</MlsName>
            </MlsItem>
          ))}
        </MlsContainer>
      </Popover>
    </>
  );
};

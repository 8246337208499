/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SectionFetchedData,
    SectionFetchedDataFromJSON,
    SectionFetchedDataFromJSONTyped,
    SectionFetchedDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface FetchAllSectionsDataResponseDto
 */
export interface FetchAllSectionsDataResponseDto {
    /**
     * 
     * @type {Array<SectionFetchedData>}
     * @memberof FetchAllSectionsDataResponseDto
     */
    fetchedData: Array<SectionFetchedData>;
}

export function FetchAllSectionsDataResponseDtoFromJSON(json: any): FetchAllSectionsDataResponseDto {
    return FetchAllSectionsDataResponseDtoFromJSONTyped(json, false);
}

export function FetchAllSectionsDataResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FetchAllSectionsDataResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fetchedData': ((json['fetchedData'] as Array<any>).map(SectionFetchedDataFromJSON)),
    };
}

export function FetchAllSectionsDataResponseDtoToJSON(value?: FetchAllSectionsDataResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fetchedData': ((value.fetchedData as Array<any>).map(SectionFetchedDataToJSON)),
    };
}



export const WarningIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="39.5" cy="39.5" r="39.5" fill="#F3F3FA" />
      <path
        d="M38.1377 43.44H39.8297C40.0097 35.556 41.8457 33.288 41.8457 29.472C41.8457 26.52 40.5857 25.26 39.0017 25.26C37.4177 25.26 36.1217 26.52 36.1217 29.472C36.1217 33.288 37.9577 35.556 38.1377 43.44ZM39.0017 45.816C37.2017 45.816 36.1217 47.148 36.1217 48.588C36.1217 50.1 37.2017 51.432 39.0017 51.432C40.7657 51.432 41.8817 50.1 41.8817 48.588C41.8817 47.148 40.7657 45.816 39.0017 45.816Z"
        fill="#696B86"
      />
    </svg>
  );
};

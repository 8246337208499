import * as React from "react";
import { ConstrainedContainer } from "./ConstrainedContainer";
import { styledThemed as styled } from "../theme";
import { Subtitle1, Body2 } from "./typography";

const Root = styled(ConstrainedContainer)`
  margin-top: ${(p) => p.theme.presentation.spacing(3)};
  margin-bottom: ${(p) => p.theme.presentation.spacing(3)};
  text-align: center;
  @media print {
    padding-top: ${(p) => p.theme.presentation.spacing(1)};
  }
`;

interface Props {
  mobile: boolean;
  id: string;
  updatedAt: Date;
}

export const Footer = ({ id, updatedAt }: Props) => {
  const updatedAtDate = new Date(updatedAt).toLocaleDateString("en-US");
  return (
    <Root id={id}>
      {updatedAt != null ? (
        <Subtitle1 marginBottom={1.5} marginTop={1.5} data-testid="presentation-last-updated">
          Last updated on {updatedAtDate}
        </Subtitle1>
      ) : null}
      <Body2 marginTop={1.5}>
        Disclaimer: The information contained herein has been obtained through sources deemed
        reliable but cannot be guaranteed as to its accuracy. Any information of special interest,
        including but not limited to, lot size or interior square feet, should be obtained through
        independent verification.
      </Body2>
    </Root>
  );
};

import {
  SectionConfig,
  SectionConfigBase,
} from "../../../../../../../presentation/presentation-generation-logic/models/section-config";
import { SideBySideComparisonEditor } from "./components/SideBySideComparisonEditor";
import { InsightsEditor } from "./components/InsightsEditor";
import { KeyFeaturesSectionEditor } from "./components/KeyFeaturesSectionEditor";
import { styled } from "@avenue-8/ui-2";
import { FreeTextEditor } from "./components/FreeTextEditor";
import { HeaderSectionEditor } from "./components/HeaderSectionEditor";
import { GeneralHeaderSectionEditor } from "./components/GeneralHeaderSectionEditor";
import { PresentationSourceData } from "src/modules/presentation/presentation-generation-logic";
import { FreeHtmlTextEditor } from "./components/FreeHtmlTextEditor";
import { TeamProfileEditor } from "./components/TeamProfileEditor";

const SectionEditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

type PartialSourceData = {
  priceRange?: Partial<PresentationSourceData["priceRange"]>;
  coverPhotoCdnUrl?: PresentationSourceData["coverPhotoCdnUrl"];
};
export type SectionEditorOnChangeHandler = (params: {
  sectionConfig: SectionConfig;
  partialSourceData?: PartialSourceData;
}) => void;
export interface SectionEditorProps<T extends SectionConfigBase> {
  section: T;
  onChanged: SectionEditorOnChangeHandler;
  onDelete: (id: string) => void;
  sourceData: PresentationSourceData;
}

type ComponentType = (props: SectionEditorProps<any>) => any;

const DefaultComponent: ComponentType = () => {
  return <div style={{ fontSize: 14 }}>No customizations available</div>;
};

export const SectionEditor = (props: SectionEditorProps<SectionConfig>) => {
  const { section } = props;

  let Component: ComponentType;
  switch (section.type) {
    case "insights": {
      Component = InsightsEditor;
      break;
    }
    case "key-features": {
      Component = KeyFeaturesSectionEditor;
      break;
    }
    // case "neighborhood-map":{
    //   return (<p>Not Implemented</p>);
    // }
    case "side-by-side-comparision": {
      Component = SideBySideComparisonEditor;
      break;
    }
    case "free-text": {
      Component = FreeTextEditor;
      break;
    }
    case "header": {
      Component = HeaderSectionEditor;
      break;
    }
    case "general-header": {
      Component = GeneralHeaderSectionEditor;
      break;
    }
    case "agent-info": {
      Component = () => null;
      break;
    }
    case "free-html-text": {
      Component = FreeHtmlTextEditor;
      break;
    }
    case "team-profile": {
      Component = TeamProfileEditor;
      break;
    }
    default:
      Component = DefaultComponent;
  }
  return (
    <SectionEditorContainer data-testid={`presentation-section-editor-${section.type}`}>
      <Component {...props} />
      {/* <div><pre><code>{JSON.stringify(section, null, ' ')}</code></pre></div> */}
    </SectionEditorContainer>
  );
};

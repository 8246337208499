import * as React from "react";
import { ConstrainedContainer } from "../ConstrainedContainer";
import { AvoidPrintBreakInside } from "../AvoidPrintBreakInside";
import { Subtitle1, Title1 } from "../typography";
import { ResponsiveImage } from "src/modules/shared/components/ResponsiveImage";
import { HeaderSectionView } from "src/modules/presentation/presentation-generation-logic/sections/header/header-section-view";
import { PriceRange } from "./PriceRange";
import { DaysOnMarket } from "./DaysOnMarket";
import { Gallery } from "./Gallery";
import { MainImageFrame, OuterContainer } from "./HeroHeader.styles";
import { PhotoGalleryContextProvider } from "src/modules/shared/contexts/PhotoGalleryContext";

export const HeroHeader = (
  props: HeaderSectionView & { displayMode: "desktop" | "mobile" | "print" | "tablet" }
) => {
  const { clientName, propertyPhoto, displayMode, id, preferences } = props;
  const shouldShowPriceRange = (props.preferences == null ||
    props.preferences.showPriceRange === true) && (
    <PriceRange maxSellPrice={props.maxSellPrice} minSellPrice={props.minSellPrice} />
  );
  const shouldShowDaysOnMarket =
    props.preferences == null || props.preferences.showDaysOnMarket === true;
  const preparedFor = (preferences == null ||
    preferences.showClientName == null ||
    preferences.showClientName === true) && (
    <Subtitle1 marginTop={2} marginBottom={2} data-testid="prepared-for">
      Prepared For {clientName}
    </Subtitle1>
  );
  const presentationTitle = (preferences == null ||
    preferences.showPresentationTitle == null ||
    preferences.showPresentationTitle === true) && (
    <Title1 marginBottom={2.5} data-testid="presentation-title">
      <>
        {props.addressLine1}
        {props.addressLine2 ? ` Unit ${props.addressLine2}` : ""}, {props.city} {props.state}{" "}
        {props.zipCode}
      </>
    </Title1>
  );

  return (
    <PhotoGalleryContextProvider>
      <OuterContainer
        $noPaddingTop={
          preferences?.showPresentationTitle === false && preferences?.showClientName === false
        }
        id={id}
      >
        <AvoidPrintBreakInside>
          <ConstrainedContainer>
            {preparedFor}
            {presentationTitle}
          </ConstrainedContainer>
          <MainImageFrame>
            <ResponsiveImage
              alt="Property view"
              src={propertyPhoto ?? (props.photos?.[0]?.url || "")}
              size={displayMode === "print" ? "lg" : undefined}
            />
          </MainImageFrame>
        </AvoidPrintBreakInside>
        {(shouldShowPriceRange || shouldShowDaysOnMarket) && (
          <ConstrainedContainer>
            {shouldShowPriceRange && (
              <PriceRange maxSellPrice={props.maxSellPrice} minSellPrice={props.minSellPrice} />
            )}
            {shouldShowDaysOnMarket && props.daysOnMarket > 0 && (
              <DaysOnMarket
                daysOnMarket={props.daysOnMarket}
                showPriceRange={props.preferences?.showPriceRange}
              />
            )}
          </ConstrainedContainer>
        )}
        <Gallery displayMode={displayMode} photos={props.photos} />
      </OuterContainer>
    </PhotoGalleryContextProvider>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SingleAgentResponseDto,
    SingleAgentResponseDtoFromJSON,
    SingleAgentResponseDtoFromJSONTyped,
    SingleAgentResponseDtoToJSON,
    UserResponse,
    UserResponseFromJSON,
    UserResponseFromJSONTyped,
    UserResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserAndAgentResponse
 */
export interface UserAndAgentResponse {
    /**
     * 
     * @type {UserResponse}
     * @memberof UserAndAgentResponse
     */
    user: UserResponse;
    /**
     * 
     * @type {SingleAgentResponseDto}
     * @memberof UserAndAgentResponse
     */
    agent: SingleAgentResponseDto;
}

export function UserAndAgentResponseFromJSON(json: any): UserAndAgentResponse {
    return UserAndAgentResponseFromJSONTyped(json, false);
}

export function UserAndAgentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAndAgentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserResponseFromJSON(json['user']),
        'agent': SingleAgentResponseDtoFromJSON(json['agent']),
    };
}

export function UserAndAgentResponseToJSON(value?: UserAndAgentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserResponseToJSON(value.user),
        'agent': SingleAgentResponseDtoToJSON(value.agent),
    };
}



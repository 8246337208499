import { Avatar, Tabs, TabProps } from "@avenue-8/ui-2";
import * as React from "react";
import { styledThemed as styled } from "../theme";

interface Props {
  agentName: string;
  agentPhotoSrc?: string;
  agentLineInfo?: string;
  value: any;
  onTabsChange?: (e: React.SyntheticEvent<Element, Event>, value: any) => void;
  children: React.ReactElement<TabProps>[];
  hideTabs?: boolean;
}

const Root = styled.div<{ $hideTabs?: boolean }>`
  display: flex;
  align-items: center;
  ${(p) => p.$hideTabs && "padding: 0.75rem 0;"}
  @media print {
    padding: 0.75rem 0;
  }
`;

const AvatarStyled = styled(Avatar)`
  width: 4rem;
  height: 4rem;
`;

const Content = styled.div`
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  max-width: 280px;
`;

const AgentName = styled.div`
  font-family: ${(p) => p.theme.presentation.headerFontFamily};
  font-size: 1.2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AgentLine = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TabsStyled = styled(Tabs)`
  width: 100%;
  & button {
    height: 5.5rem;
    font-weight: 400;
    color: ${(p) => p.theme.av8.primaryColor};
  }
  @media print {
    display: none;
  }
`;

// this might be necessary later: https://codesandbox.io/s/material-demo-xu80m?file=/ScrollSpyTabs.js
export const TopNavigation = ({
  agentName,
  agentPhotoSrc,
  agentLineInfo,
  value,
  children,
  onTabsChange,
  hideTabs,
}: Props) => {
  return (
    <Root $hideTabs={hideTabs}>
      <AvatarStyled alt={agentName} src={agentPhotoSrc} />
      <Content>
        <AgentName>{agentName}</AgentName>
        {agentLineInfo && <AgentLine>{agentLineInfo}</AgentLine>}
      </Content>
      {!hideTabs && (
        <TabsStyled
          value={value}
          onChange={onTabsChange}
          aria-label="Presentation tabs"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
        >
          {children}
        </TabsStyled>
      )}
    </Root>
  );
};

import * as React from "react";
import { FeatureToggleService } from "./feature-toggle-service";
import { FeatureToggleStrategy } from "./feature-toggle-strategy";
import { NullFeatureToggleStrategy } from "./null-strategy";

export interface FeatureToggle<Key, Value> {
  key: Key;
  parseValue: (value: string | null) => Value;
}

export class BooleanFeatureToggle<Key> implements FeatureToggle<Key, boolean> {
  constructor(public key: Key) {}
  parseValue(value: string | null) {
    const b = value === "1";
    return b;
  }
}

interface FeatureToggleContextData {
  strategy: FeatureToggleStrategy;
  service: FeatureToggleService;
}

export function makeFeatureToggleContext(defaultStrategy?: FeatureToggleStrategy) {
  const strategy = defaultStrategy || new NullFeatureToggleStrategy();
  const FeatureToggleContext = React.createContext<FeatureToggleContextData>({
    strategy,
    service: new FeatureToggleService(strategy),
  });

  const { Consumer: FeatureToggleConsumer, Provider } = FeatureToggleContext;

  function useRealtimeFeatureToggle<Key, Value>(toggle: FeatureToggle<Key, Value>): Value {
    const { strategy } = React.useContext(FeatureToggleContext);

    const value = React.useMemo(() => {
      //using a memo to avoid calling the toggle multiple times - this prevents misleading analytics data
      const valueRaw = strategy.getFeatureToggleValue(toggle.key as any);
      return toggle.parseValue(valueRaw);
    }, [toggle, strategy]);

    const [valueState, setValueState] = React.useState(value);
    React.useEffect(() => {
      const key = toggle.key as any;
      const listener = (value: any) => {
        setValueState(toggle.parseValue(value));
      };
      strategy.addFeatureToggleListener(key, listener);
      return () => {
        strategy.removeFeatureToggleListener(key, listener);
      };
    }, [strategy, toggle]);
    return valueState;
  }

  function useFeatureToggleService() {
    const { service } = React.useContext(FeatureToggleContext);
    return service;
  }

  let lastIdentity: string | number | null = null;

  function useFeatureToggleIdentity(identity?: string | number) {
    const service = useFeatureToggleService();
    if (identity && lastIdentity !== identity) {
      service.identify(identity);
      lastIdentity = identity;
    }
  }

  return {
    useFeatureToggleIdentity,
    FeatureToggleConsumer,
    FeatureToggleProvider: ({
      strategy,
      children,
    }: {
      strategy: FeatureToggleStrategy;
      children: any;
    }) => (
      <Provider value={{ strategy, service: new FeatureToggleService(strategy) }}>
        {children}
      </Provider>
    ),
    useRealtimeFeatureToggle,
    useFeatureToggleService,
  };
}

export const {
  FeatureToggleProvider,
  FeatureToggleConsumer,
  useRealtimeFeatureToggle,
  useFeatureToggleService,
  useFeatureToggleIdentity,
} = makeFeatureToggleContext();

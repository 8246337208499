import { ImageUnavailable } from "@avenue-8/ui-2";
import React from "react";

interface StaticMapProps {
  address: string;
  lat?: number;
  lng?: number;
  zoom?: number;
  width: number;
  height: number;
  mapType?: "roadmap" | "satellite" | "hybrid" | "terrain";
}

const getMarkersQueryString = (center: string) => {
  if (process.env.NODE_ENV === "development") {
    return `icon:http://staging.getmarketcomp.com/icons/map-marker.png|${center}`;
  }
  return `icon:${window.location.origin}/icons/map-marker.png|${center}`;
};

export const StaticMap = (props: StaticMapProps) => {
  const { width, height, lat, lng, address, zoom = 15, mapType = "roadmap" } = props;
  const center = lat && lng ? `${lat},${lng}` : address;
  const markersQueryString = getMarkersQueryString(center);
  const url = `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=${zoom}&size=${width}x${height}&maptype=${mapType}&markers=${markersQueryString}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
  const [error, setError] = React.useState(false);
  return (
    <>
      {!error && (
        <img
          src={url}
          alt={address}
          onError={(e) => {
            console.warn("Error loading Google Maps Static image for", e.target);
            setError(true);
          }}
        />
      )}
      {error && <ImageUnavailable style={{ height: `${height}px` }} message="Map unavailable" />}
    </>
  );
};

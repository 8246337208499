import { HttpApiClient } from "./http-api-client";
import { MockApiClient } from "./mock-api-client";

export const getApiClient = () => {
  if ((process.env.REACT_APP_PRESENTATION_MOCK || "1") === "1") {
    console.log("USING MOCK API CLIENT");
    return new MockApiClient();
  } else {
    console.log("USING HTTP API CLIENT");
    return new HttpApiClient();
  }
};

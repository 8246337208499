export type PrintTypes = "pdf" | "html" | "image" | "raw-html";

export type PrintConfiguration = {
  printable: any;
  fallbackPrintable: string;
  type?: PrintTypes;
  documentTitle?: string;
  header?: any;
  headerStyle?: string;
  maxWidth?: number;
  targetStyle?: string | string[];
  targetStyles?: string | string[];
  properties?: any;
  gridHeaderStyle?: string;
  gridStyle?: string;
  setModalState?: (open: boolean) => void;
  onLoadingStart?: () => void;
  onLoadingEnd?: () => void;
  frameId?: string;
  ignoreElements?: string | string[];
  repeatTableHeader?: boolean;
  css?: string | string[];
  style?: string;
  scanStyles?: boolean;
  onError?: (error: any, xmlHttpRequest?: XMLHttpRequest) => void;
  onPrintDialogClose?: () => void;
  onIncompatibleBrowser?: () => void;
  base64?: boolean;
  printableElement?: HTMLElement;
  closeOnWindowFocus?: boolean;
};

export const defaultPrintConfiguration = {
  type: "pdf",
  headerStyle: "font-weight: 300;",
  maxWidth: 800,
  gridHeaderStyle: "font-weight: bold; padding: 5px; border: 1px solid #dddddd;",
  gridStyle: "border: 1px solid lightgray; margin-bottom: -1px;",
  onError: (error: Error): void => {
    throw error;
  },
  onIncompatibleBrowser: () => {
    alert("Your browser is not compatible with this feature");
  },
  frameId: "printFrame",
  documentTitle: "Document",
  targetStyle: ["clear", "display", "width", "min-width", "height", "min-height", "max-height"],
  targetStyles: ["border", "box", "break", "text-decoration"],
  ignoreElements: [] as any,
  repeatTableHeader: true,
  scanStyles: true,
  base64: false,
};

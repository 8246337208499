import * as React from "react";
import usePlacesAutocomplete from "use-places-autocomplete";
import {
  Autocomplete,
  TextField,
  TextFieldProps,
  AutocompleteRenderInputParams,
} from "@avenue-8/ui-2";
import { useGoogleMapsApi } from "src/modules/cma-v2/hooks/useGoogleMapsApi";

type OptionType = {
  title: string;
  terms: Array<{ offset: number; value: string }>;
  fromApi: boolean;
  description?: string;
};

interface Props {
  textFieldProps: TextFieldProps;
  onAddressFound: (data: OptionType) => void;
}

export const AddressInput = React.forwardRef((props: Props, ref) => {
  const { isReady: googleMapsApiIsReady } = useGoogleMapsApi({
    libraries: ["places", "geometry"],
  });
  if (!googleMapsApiIsReady) {
    return <TextField {...props.textFieldProps} />;
  }
  return <Inner {...props} ref={ref} />;
});

const Inner = React.forwardRef(({ textFieldProps, onAddressFound }: Props, ref) => {
  const { onKeyUp: innerOnKeyUp, defaultValue, ...textFieldPropsRest } = textFieldProps;

  const {
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: "USA",
      },
    },
    debounce: 300,
  });

  const options: OptionType[] =
    data?.map((x) => {
      return { title: x.description, terms: x.terms, fromApi: true };
    }) ?? [];

  const handleAddressChange = async (_: any, selectedOption: string | OptionType) => {
    if (!selectedOption || typeof selectedOption === "string") return;

    setValue(selectedOption.title.split(",")[0]);
    clearSuggestions();

    onAddressFound({ ...selectedOption, description: selectedOption.title });
  };

  return (
    <Autocomplete
      ref={ref}
      freeSolo={true}
      value={textFieldProps.value as string}
      options={options}
      blurOnSelect={true}
      inputMode="search"
      includeInputInList={true}
      disableClearable={true}
      getOptionLabel={(option: OptionType) =>
        option?.title || ((textFieldProps.value || textFieldProps.defaultValue) as string) || ""
      }
      data-testid="address-autocomplete"
      onChange={handleAddressChange}
      onInputChange={(_event, value) => {
        setValue(value);
      }}
      renderInput={(params: AutocompleteRenderInputParams) => {
        return <TextField {...textFieldPropsRest} {...params} />;
      }}
    />
  );
});

import { RouteProps } from "react-router";

export enum Role {
  Admin = "admin",
  Agent = "agent",
}

export interface RouteModel extends RouteProps {
  path: string;
  roles?: Array<Role>;
  guest?: boolean;
  redirect?: string;
}

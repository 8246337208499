import * as React from "react";
import { Typography, Grid } from "@avenue-8/ui-2";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { formatToCurrency } from "../../../../../../../shared/utils/formatters";
import { calculateSummaryStatistics } from "../../../../../SummaryStatistics";
import { groupBy } from "../../../../../../../shared/utils/group-by";
import {
  EmptyContainer,
  ListingStatus,
  SummaryPricesContainer,
  Subtitle,
  Container,
} from "./SummaryView.styles";

interface Props {
  comparableProperties: PropertyDto[];
}

export const SummaryView = (props: Props) => {
  const { comparableProperties } = props;

  const data = calculateSummaryStatistics({
    properties: comparableProperties,
    dimension: "global",
    field: "soldPrice",
  }).rows[0];

  const hasComparables = (data?.count ?? 0) > 0;
  const format = (n: number | null) =>
    hasComparables && n != null ? formatToCurrency(Math.round(n)) : "-";
  const groupedListingStatuses = groupBy(comparableProperties, (item) => item.listingStatus);
  let content: React.ReactNode;
  if (comparableProperties.length === 0) {
    content = (
      <EmptyContainer>
        <Typography variant="body1" color="secondary">
          No Listing Selected
        </Typography>
      </EmptyContainer>
    );
  } else {
    content = (
      <>
        {Object.keys(groupedListingStatuses)
          .filter((x) => !!x)
          .map((listingStatus) => {
            return (
              <ListingStatus $listingStatus={listingStatus} variant="body1" key={listingStatus}>
                {listingStatus}: {groupedListingStatuses[listingStatus].length}
              </ListingStatus>
            );
          })}

        <SummaryPricesContainer>
          <Subtitle variant="body2">Summary of Sold Prices</Subtitle>

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="primary">
                Low
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="primary">
                {format(data?.low)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="primary">
                Median
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="primary">
                {format(data?.median)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="primary">
                Average
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="primary">
                {format(data?.median)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="primary">
                High
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" color="primary">
                {format(data?.high)}
              </Typography>
            </Grid>
          </Grid>
        </SummaryPricesContainer>
      </>
    );
  }

  return (
    <Container>
      <Typography variant="h6">Listings Summary</Typography>
      {content}
    </Container>
  );
};

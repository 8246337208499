import {
  CMAAgentsApi,
  CMAApi,
  CMAPropertiesApi,
  HTTPHeaders,
  Configuration,
  ResponseContext,
} from "./generated";
import { toast } from "@avenue-8/ui-2";
import { authStorageService } from "./../../contexts/AuthContext/auth-storage-service";

function getHeaders(): HTTPHeaders {
  const headers: HTTPHeaders = {};
  const token = localStorage.getItem("authToken");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
}

function getConfig() {
  return new Configuration({
    basePath: `${process.env.REACT_APP_CMA_API_URL}`,
    headers: getHeaders(),
  });
}

function getPostMiddleware() {
  return async (context: ResponseContext) => {
    const { response, init } = context;
    const headers = init.headers as Record<string, string>;
    if (response.status === 401 && headers.Authorization) {
      authStorageService.clear();
      toast.error("Your session has expired. Please login again.", { shouldDeduplicate: true });
    }
    return response;
  };
}

export const getCMAApiClient: () => CMAApi = () => {
  return new CMAApi(getConfig()).withPostMiddleware(getPostMiddleware());
};

export const getCMAAgentsApiClient: () => CMAAgentsApi = () => {
  return new CMAAgentsApi(getConfig()).withPostMiddleware(getPostMiddleware());
};

export const getCMAPropertiesApiClient: () => CMAPropertiesApi = () => {
  return new CMAPropertiesApi(getConfig()).withPostMiddleware(getPostMiddleware());
};

import { Controller, useFormContext } from "react-hook-form";
import { TextFieldProps, SearchIcon, styled, InputAdornment } from "@avenue-8/ui-2";
import { SuggestionsInput, SuggestionsInputProps } from "../CreateCMA/SuggestionsInput";
import { isNullOrEmptyString } from "src/modules/shared/utils/validations";
import { MlsPopover } from "../MlsPopover";

const SearchButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
  cursor: pointer;
`;

interface Props {
  fieldName?: string;
  fieldProps?: TextFieldProps;
  acceptedSuggestions: SuggestionsInputProps["acceptedSuggestions"];
  required?: boolean;
  defaultValue?: string;
  handleSearch: (value: string) => void;
  onSuggestionChosen?: (chosenSuggestion: { [type: string]: string | null }) => void;
}

export const TwoStepSearchBarField = (props: Props) => {
  const {
    fieldName = "search",
    fieldProps,
    acceptedSuggestions,
    required,
    handleSearch,
    onSuggestionChosen,
  } = props;
  const { setValue } = useFormContext();

  const validationRules = required
    ? {
        validate: {
          required: (v: any) => (isNullOrEmptyString(v) ? `Missing search term` : undefined),
        },
      }
    : undefined;

  const handleSuggestionChosen = (chosenSuggestion: { [type: string]: string | null }) => {
    if (onSuggestionChosen) {
      onSuggestionChosen(chosenSuggestion);
    } else {
      setValue(fieldName, chosenSuggestion.text ?? "");
      handleSearch(chosenSuggestion.text ?? "");
    }
  };

  return (
    <Controller
      name={fieldName}
      rules={validationRules}
      render={({ ref, ...rest }) => (
        <SuggestionsInput
          onSuggestionChosen={handleSuggestionChosen}
          acceptedSuggestions={acceptedSuggestions}
          textFieldProps={{
            ...rest,
            ...fieldProps,
            inputRef: ref,
            autoFocus: true,
            fullWidth: true,
            name: fieldName,
            onChange: (e) => {
              fieldProps?.onChange?.(e);
              rest.onChange(e);
            },
            InputProps: {
              endAdornment: (
                <InputAdornment position="end">
                  <SearchButton type="submit" data-testid="search">
                    <SearchIcon size={24} />
                  </SearchButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <MlsPopover height={20} />
                </InputAdornment>
              ),
            },
          }}
        />
      )}
    />
  );
};

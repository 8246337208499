import { Button } from "@avenue-8/ui-2";
import { useAuthContext } from "../../../shared/contexts/AuthContext/AuthContext";
import { StyledDashboardBar, WelcomeMessage } from "./DashboardBar.styles";

export const DashboardBar = ({
  shrink,
  onCreateNewCma,
}: {
  shrink: boolean;
  onCreateNewCma?: () => void;
}) => {
  const {
    state: { userInfo },
  } = useAuthContext();

  const welcomeMessage = userInfo?.isFirstLogin ? "Welcome" : "Welcome back";

  const getFirstName = () => {
    const name = userInfo?.fullName;
    return name ? String(name).split(" ")[0] : "";
  };

  return (
    <StyledDashboardBar $shrink={shrink}>
      <WelcomeMessage variant="h2">
        {welcomeMessage}, <span data-name="username">{getFirstName()}.</span>
      </WelcomeMessage>

      {/* SEARCH BAR GOES HERE */}
      {onCreateNewCma && <Button onClick={onCreateNewCma}>Create new</Button>}
    </StyledDashboardBar>
  );
};

import { styled, Grid } from "@avenue-8/ui-2";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;
export const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;
export const FormItem = styled(Grid)`
  margin-bottom: 1rem !important; //weird, but I had to add this after migrationg to MUI5
`;
export const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 1rem;
`;
export const MLSActions = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;
export const LoaderContainer = styled(Grid)`
  min-height: 500px;
`;

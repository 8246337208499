export interface MapMarkerSvgProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  color?: string;
  backgroundColor?: string;
  size?: string | number;
}

export const MapMarkerSvg = ({
  color = "red",
  backgroundColor = "#F8F8F8",
  size,
  ...rest
}: MapMarkerSvgProps) => {
  return (
    <svg
      {...rest}
      width={size ?? 40}
      height={size ?? 40}
      viewBox="0 0 39 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          d="M19.9214 0.108212C11.6371 0.108212 4.92138 6.71816 4.92138 14.872C4.92138 24.6269 19.9214 35.1082 19.9214 35.1082C19.9214 35.1082 34.9214 24.6269 34.9214 14.872C34.9214 6.71816 28.2057 0.108212 19.9214 0.108212Z"
          fill={backgroundColor}
        />
        <path
          d="M19.9214 7.79522C15.7793 7.79522 12.4214 11.1002 12.4214 15.1771C12.4214 19.254 15.7793 22.559 19.9214 22.559C24.0635 22.559 27.4214 19.254 27.4214 15.1771C27.4214 11.1002 24.0635 7.79522 19.9214 7.79522Z"
          fill={color}
        />
      </g>
      <defs>
        <filter
          id="filter0_d"
          x="0.921387"
          y="0.108154"
          width="38"
          height="42.9999"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};

import React from "react";
import { Button, Grid, CropImageModal, styled } from "@avenue-8/ui-2";
import { PhotosUploader } from "../../../../cma-v2/components/PhotosUploader";
import { useAgentOnboardingLogic } from "../../../hooks/useAgentOnboardingLogic";
import { HeadshotPreview } from "./HeadshotPreview";
import { useAuthContext } from "../../../../shared/contexts/AuthContext/AuthContext";

interface UploadHeadshotFormProps {
  avatarUrl?: string;
}

const ChangeButton = styled(Button)`
  margin: 1rem auto 0 auto;
  border-color: ${(p) => p.theme.av8.input.borderColor};
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 1rem;
`;

const UploadHeadshotContainer = styled(Grid)`
  border: 1px ${(p) => p.theme.av8.input.borderColor} dashed;
  padding: 2rem 1.5rem;
`;

export const UploadHeadshotForm = (props: UploadHeadshotFormProps) => {
  const [originalHeadshotImage, setOriginalHeashotImage] = React.useState<string | null>(null);
  const [tempHeadshotImage, setTempHeadshotImage] = React.useState<string | null>(null);
  const { state, actions } = useAgentOnboardingLogic();
  const {
    state: { userInfo },
  } = useAuthContext();

  const handleUploadFiles = (photosUploderFormData: FormData) => {
    const imageFile = photosUploderFormData.get("image");
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      setOriginalHeashotImage(fileReader.result as string);
      setTempHeadshotImage(fileReader.result as string);
    };

    fileReader.readAsDataURL(imageFile as Blob);
  };

  const uploadHeadshotForm = (formData: FormData) => {
    actions.uploadAgentHeadshotPhoto(formData);
  };

  return (
    <>
      <PhotosUploader
        isUploading={false}
        onUploadFiles={handleUploadFiles}
        inputName="image"
        description="Drag + drop your headshot here or click to browse files below."
        content={
          props.avatarUrl ? (
            <UploadHeadshotContainer container item md={12} justifyContent="center">
              <HeadshotPreview
                image={userInfo?.avatar}
                openCropHeadshotModal={originalHeadshotImage ? setTempHeadshotImage : null}
                uploadStatus={state.uploadAgentHeadshotPhoto.status}
              />
              <ButtonContainer>
                <ChangeButton>Change Photo</ChangeButton>
              </ButtonContainer>
            </UploadHeadshotContainer>
          ) : null
        }
        errorMessage={
          state.uploadAgentHeadshotPhoto.status === "error"
            ? "Failed to upload your headshot."
            : null
        }
        hideThumbnailsPreview={true}
      />

      <CropImageModal
        title="Crop your headshot"
        open={Boolean(tempHeadshotImage)}
        image={originalHeadshotImage}
        onCropImage={uploadHeadshotForm}
        onClose={() => setTempHeadshotImage(null)}
        inputName="image"
      />
    </>
  );
};

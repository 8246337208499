import { useCallback, useEffect } from "react";
import packageJson from "../../../../../package.json";
import { datadogRum } from "@datadog/browser-rum";
import { authStorageService } from "../../contexts/AuthContext/auth-storage-service";
import { appEventEmitter } from "../../../../events/app-event-emitter";

const getUserData = () => {
  const authInfo = authStorageService.get();
  const user = authInfo && {
    id: String(authInfo.user.userId),
    agentId: String(authInfo.user.agentId),
    email: authInfo.user.email,
    name: authInfo.userInfo.fullName,
  };
  return user;
};

export function initDatadogRum() {
  if (
    !process.env.REACT_APP_DATADOG_APPLICATION_ID ||
    !process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ) {
    console.log("Datadog RUM is not configured.");
    return;
  }
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: process.env.REACT_APP_DATADOG_SITE ?? "us5.datadoghq.com",
    service: packageJson.name,
    version: packageJson.version,
    env: process.env.NODE_ENV,
    sampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackInteractions: true,
  });
  const user = getUserData();
  if (user) datadogRum.setUser(user);
}

export const useDatadogEventTracking = () => {
  const datadogRumListener = useCallback((_, eventType: string) => {
    switch (eventType) {
      case "login":
        {
          const user = getUserData();
          if (user) datadogRum.setUser(user);
        }
        break;
      case "logout":
        datadogRum.removeUser();
        break;
      default:
        {
          const datadogUser = datadogRum.getUser();
          if (!datadogUser || Object.keys(datadogUser).length === 0) {
            const user = getUserData();
            if (user) datadogRum.setUser(user);
          }
        }
        break;
    }
  }, []);

  useEffect(() => {
    appEventEmitter.addGlobalListener(datadogRumListener);
    return () => {
      appEventEmitter.removeGlobalListener(datadogRumListener);
    };
  }, [datadogRumListener]);
};

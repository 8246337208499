/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListCMAResponseItemDto
 */
export interface ListCMAResponseItemDto {
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    addressLine1: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    addressLine2: string;
    /**
     * 
     * @type {number}
     * @memberof ListCMAResponseItemDto
     */
    baths: number;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    propertyType: string;
    /**
     * 
     * @type {number}
     * @memberof ListCMAResponseItemDto
     */
    beds: number;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListCMAResponseItemDto
     */
    isArchived: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListCMAResponseItemDto
     */
    livingSpace: number;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    requestedBy: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    renovationType: string;
    /**
     * 
     * @type {boolean}
     * @memberof ListCMAResponseItemDto
     */
    hasAdditionalLivingSpace: boolean;
    /**
     * 
     * @type {number}
     * @memberof ListCMAResponseItemDto
     */
    additionalLivingSpaceSqFt: number;
    /**
     * 
     * @type {number}
     * @memberof ListCMAResponseItemDto
     */
    propertiesCount: number;
    /**
     * 
     * @type {boolean}
     * @memberof ListCMAResponseItemDto
     */
    canPublish: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListCMAResponseItemDto
     */
    canCustomize: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ListCMAResponseItemDto
     */
    hasUnpublishedChanges: boolean;
    /**
     * The first image from a CMA's subject property
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    coverImage?: string;
    /**
     * The name of the agent from the CMA
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    agentName?: string;
    /**
     * Date of the last update
     * @type {Date}
     * @memberof ListCMAResponseItemDto
     */
    updatedDate: Date;
    /**
     * The chosen client display name on the CMA
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    clientDisplayName?: string;
    /**
     * Which kind of presentation was chosen during creation
     * @type {string}
     * @memberof ListCMAResponseItemDto
     */
    presentationType: string;
}

export function ListCMAResponseItemDtoFromJSON(json: any): ListCMAResponseItemDto {
    return ListCMAResponseItemDtoFromJSONTyped(json, false);
}

export function ListCMAResponseItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListCMAResponseItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressLine1': json['addressLine1'],
        'addressLine2': json['addressLine2'],
        'baths': json['baths'],
        'city': json['city'],
        'propertyType': json['propertyType'],
        'beds': json['beds'],
        'id': json['id'],
        'isArchived': json['isArchived'],
        'livingSpace': json['livingSpace'],
        'requestedBy': json['requestedBy'],
        'state': json['state'],
        'status': json['status'],
        'title': json['title'],
        'zipCode': json['zipCode'],
        'renovationType': json['renovationType'],
        'hasAdditionalLivingSpace': json['hasAdditionalLivingSpace'],
        'additionalLivingSpaceSqFt': json['additionalLivingSpaceSqFt'],
        'propertiesCount': json['propertiesCount'],
        'canPublish': json['canPublish'],
        'canCustomize': json['canCustomize'],
        'hasUnpublishedChanges': json['hasUnpublishedChanges'],
        'coverImage': !exists(json, 'coverImage') ? undefined : json['coverImage'],
        'agentName': !exists(json, 'agentName') ? undefined : json['agentName'],
        'updatedDate': (new Date(json['updatedDate'])),
        'clientDisplayName': !exists(json, 'clientDisplayName') ? undefined : json['clientDisplayName'],
        'presentationType': json['presentationType'],
    };
}

export function ListCMAResponseItemDtoToJSON(value?: ListCMAResponseItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'baths': value.baths,
        'city': value.city,
        'propertyType': value.propertyType,
        'beds': value.beds,
        'id': value.id,
        'isArchived': value.isArchived,
        'livingSpace': value.livingSpace,
        'requestedBy': value.requestedBy,
        'state': value.state,
        'status': value.status,
        'title': value.title,
        'zipCode': value.zipCode,
        'renovationType': value.renovationType,
        'hasAdditionalLivingSpace': value.hasAdditionalLivingSpace,
        'additionalLivingSpaceSqFt': value.additionalLivingSpaceSqFt,
        'propertiesCount': value.propertiesCount,
        'canPublish': value.canPublish,
        'canCustomize': value.canCustomize,
        'hasUnpublishedChanges': value.hasUnpublishedChanges,
        'coverImage': value.coverImage,
        'agentName': value.agentName,
        'updatedDate': (value.updatedDate.toISOString()),
        'clientDisplayName': value.clientDisplayName,
        'presentationType': value.presentationType,
    };
}



import { useEffect, useRef, useState } from "react";
import { useTheme, FilterIcon, ChevronIcon } from "@avenue-8/ui-2";
import { DashboardBar } from "./DashboardBar";
import { DashboardBarActions } from "./DashboardBarActions";
import { useDashboardLogic } from "../../hooks/useDashboardLogic";
import { PageLayout } from "../PageLayout";
import { useScrollOffsetGreaterThan } from "../../hooks/useOffsetGreaterThan";
import { useBreakpoint } from "../../hooks/useMobile";
import { Logo } from "../../../shared/components/Logo";
import { DashboardContent } from "./DashboardContent";
import { CreateEmptyPresentationModal } from "./CreateEmptyPresentationModal";
import { appEventEmitter } from "../../../../events/app-event-emitter";
import { useAppContext } from "../../../../AppContext";
import { useInfiniteScroll } from "src/modules/shared/hooks/useInfiniteScroll";
import {
  GridHeader,
  ListControl,
  StyledUserMenu,
  PresentationTag,
  FilterTag,
} from "./Dashboard.styles";

const SORT_AND_FILTER_BY_FEATURE = false;

export const Dashboard = () => {
  const { state, actions } = useDashboardLogic();
  const [createCmaModalOpen, setCreateCmaModalOpen] = useState(false);
  const theme = useTheme();
  const isDesktopDown = useBreakpoint("desktopDown");
  const isSmallDesktopDown = useBreakpoint("smallDesktopDown");
  const isMobile = useBreakpoint("mobileDown");
  const shrinkHeaderFont = isSmallDesktopDown;
  const { actions: appActions } = useAppContext();
  const offsetGreaterThan80 = useScrollOffsetGreaterThan(80);
  const { fetchNextCmasPage } = actions;
  const pageRef = useRef<HTMLDivElement | null>(null);
  const { isFetchingNextCmasPage, hasNextCmasPage } = state;

  const handleCreateCmaClick = () => {
    if (isMobile) {
      appActions.confirm({
        title: "Creating a presentation is not supported on this device",
        message: "You can still view and share CMAs on your phone",
        confirmButtonText: "Continue",
      });
      return;
    }
    appEventEmitter.emit({ eventType: "presentation-create-modal-opened" });
    setCreateCmaModalOpen(true);
  };

  useEffect(() => {
    appEventEmitter.emit({ eventType: "dashboard-loaded" });
  }, []);

  useInfiniteScroll({
    hasNextPage: Boolean(!isFetchingNextCmasPage && hasNextCmasPage),
    fetchNextPage: () => fetchNextCmasPage && fetchNextCmasPage(),
    scrollOffset: 100,
    elementRef: pageRef,
  });

  return (
    <PageLayout
      condensedHeader={offsetGreaterThan80 || isDesktopDown}
      showHeaderShadow={offsetGreaterThan80}
      verticalHeaderLayout={isDesktopDown}
      userMenu={<StyledUserMenu />}
      headerContent={<DashboardBar shrink={offsetGreaterThan80 || shrinkHeaderFont} />}
      headerActions={
        <DashboardBarActions onCreateNewCma={handleCreateCmaClick} showActions={!isMobile} />
      }
      logo={<Logo />}
      contentReservedHeight={shrinkHeaderFont ? 180 : 272}
      headerPosition="fixed"
      pageRef={pageRef}
    >
      <GridHeader>
        <PresentationTag>My Presentations</PresentationTag>
        {SORT_AND_FILTER_BY_FEATURE && (
          <div>
            <ListControl>
              <FilterTag>Filter by</FilterTag> <FilterIcon color={theme.av8.secondaryColor} />
            </ListControl>
            <ListControl>
              <FilterTag>Sort by</FilterTag>{" "}
              <ChevronIcon color={theme.av8.secondaryColor} rotate={90} />
            </ListControl>
          </div>
        )}
      </GridHeader>

      <DashboardContent onCreateNewCma={handleCreateCmaClick} />

      <CreateEmptyPresentationModal
        open={createCmaModalOpen}
        onClose={() => setCreateCmaModalOpen(false)}
      />
    </PageLayout>
  );
};

import * as React from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  CloseIcon,
  Button,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@avenue-8/ui-2";
import { EditCmaHeaderForm, EditCmaHeaderFormModel } from "./EditCmaHeaderForm";

interface EditCmaFormProps {
  open: boolean;
  onClose: () => void;
  defaultValues: EditCmaHeaderFormModel;
  onSave: (data: EditCmaHeaderFormModel) => Promise<void>;
}

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export const EditCmaHeaderModal = ({
  onSave,
  defaultValues,
  onClose,
  ...rest
}: EditCmaFormProps) => {
  const formMethods = useForm<EditCmaHeaderFormModel>({ defaultValues });

  const {
    formState: { isSubmitting },
    reset,
  } = formMethods;

  const handleClose = isSubmitting ? undefined : onClose;

  return (
    <Dialog
      BackdropProps={{ onEntering: () => reset(defaultValues) }}
      fullWidth
      maxWidth="xs"
      {...rest}
      onClose={handleClose}
    >
      <DialogTitle id="edit-cma-header-modal">
        Edit Presentation Client and Name
        <CloseButton disabled={isSubmitting} aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...formMethods}>
          <EditCmaHeaderForm onSave={onSave} defaultValues={defaultValues} />
        </FormProvider>
        <div style={{ height: "1rem" }} />
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} onClick={handleClose} variant="text">
          Cancel
        </Button>
        <Button type="submit" disabled={isSubmitting} form="edit-cma-header" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { SideBySideComparisonItem } from "../../models";
import { getDaysOnMarket } from "../../shared/get-days-on-market";
import { calculateMonthlies } from "../../shared/recurring-costs-helper";
import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { SideBySideComparisonSectionConfig } from "./side-by-side-comparison-section-config";
import { SideBySideComparisonSectionView } from "./side-by-side-comparison-section-view";
import { v4 as uuidv4 } from "uuid";

export class SideBySideComparisonSectionWidget
  implements
    SectionWidget<
      "side-by-side-comparision",
      SideBySideComparisonSectionConfig,
      SideBySideComparisonSectionView
    >
{
  type: "side-by-side-comparision" = "side-by-side-comparision";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  buildDefaultConfig(): SideBySideComparisonSectionConfig {
    return {
      type: "side-by-side-comparision",
      navTitle: "Compare",
      id: uuidv4(),
      visibility: true,
      showBaths: true,
      showBeds: true,
      showGarageParkingSpaces: true,
      showHOAFees: true,
      showLivingSpace: true,
      showNeighborhood: true,
      showPricePerLivingSpaceSqft: true,
      showSoldPrice: true,
      showListPrice: true,
      showSoldDate: false,
      showListDate: false,
      showYearBuilt: true,
      showPropertyType: false,
      showLotSize: false,
      showDoormanAttendance: false,
      showListingStatus: true,
      showRoomsTotal: false,
      showFlipTaxRemarks: false,
      showRecurringCosts: true,
      showDaysOnMarket: false,
    };
  }

  getPrintableFields(): Array<keyof SideBySideComparisonSectionConfig> {
    const fields: Array<keyof SideBySideComparisonSectionConfig> = [
      "showBaths",
      "showBeds",
      "showGarageParkingSpaces",
      "showHOAFees",
      "showLivingSpace",
      "showNeighborhood",
      "showPricePerLivingSpaceSqft",
      "showSoldPrice",
      "showListPrice",
      "showSoldDate",
      "showListDate",
      "showYearBuilt",
      "showPropertyType",
      "showLotSize",
      "showDoormanAttendance",
      "showListingStatus",
      "showRoomsTotal",
      "showFlipTaxRemarks",
      "showRecurringCosts",
      "showDaysOnMarket",
    ];
    return fields;
  }

  buildView({
    sectionConfig: config,
    context,
  }: SectionViewBuilderContext<
    SideBySideComparisonSectionConfig,
    null
  >): SideBySideComparisonSectionView {
    if (config.type !== "side-by-side-comparision") throw new Error("Unexpected section type.");
    const { id, type, title, description, visibility } = config;
    const { comparableProperties, featuredProperty } = context.sourceData;

    const {
      showBaths = true,
      showBeds = true,
      showGarageParkingSpaces = true,
      showHOAFees = true,
      showLivingSpace = true,
      showNeighborhood = true,
      showPricePerLivingSpaceSqft = true,
      showSoldPrice = true,
      showListPrice = true,
      showSoldDate = false,
      showListDate = false,
      showYearBuilt = true,
      showPropertyType = false,
      showLotSize = false,
      showDoormanAttendance = false,
      showListingStatus = true,
      showRoomsTotal = false,
      showFlipTaxRemarks = false,
      showRecurringCosts = true,
      showDaysOnMarket = false,
    } = config;

    const buildProperties = (properties: SideBySideComparisonSectionView["properties"]) => {
      return properties?.map((property) => {
        const copy = {
          ...property,
          totalMonthlies: calculateMonthlies(property.recurringCosts ?? []),
          daysOnMarket: getDaysOnMarket(property.listDate, property.soldDate),
        } as SideBySideComparisonItem;
        delete copy.photos;
        if (!showBaths) delete copy.baths;
        if (!showBeds) delete copy.beds;
        if (!showGarageParkingSpaces) delete copy.garageParkingSpaces;
        if (!showHOAFees) delete copy.hoaFees;
        if (!showNeighborhood) delete copy.neighborhood;
        if (!showLivingSpace && !showPricePerLivingSpaceSqft) delete copy.livingSpace;
        if (!showSoldPrice && !showPricePerLivingSpaceSqft) delete copy.soldPrice;
        if (!showListPrice && !showPricePerLivingSpaceSqft) delete copy.currentPrice;
        if (!showYearBuilt) delete copy.yearBuilt;
        if (!showLotSize) delete copy.lotSize;
        if (!showPropertyType) delete copy.propertyType;
        if (!showDoormanAttendance) delete copy.hasDoormanAttendance;
        if (!showListDate) delete copy.listDate;
        if (!showSoldDate) delete copy.soldDate;
        if (!showRoomsTotal) delete copy.roomsTotal;
        if (!showFlipTaxRemarks) delete copy.flipTaxRemarks;
        if (!showRecurringCosts) delete copy.recurringCosts;
        if (!showRecurringCosts) delete copy.totalMonthlies;
        if (!showDaysOnMarket) delete copy.daysOnMarket;
        return copy;
      });
    };

    const properties = buildProperties([featuredProperty, ...comparableProperties]);

    const view: SideBySideComparisonSectionView = {
      id,
      type,
      title,
      visibility,
      description: description,
      properties: properties,
      preferences: {
        showBaths,
        showBeds,
        showGarageParkingSpaces,
        showHOAFees,
        showLivingSpace,
        showNeighborhood,
        showPricePerLivingSpaceSqft,
        showSoldPrice,
        showListPrice,
        showSoldDate,
        showListDate,
        showYearBuilt,
        showPropertyType,
        showLotSize,
        showDoormanAttendance,
        showListingStatus,
        showRoomsTotal,
        showFlipTaxRemarks,
        showRecurringCosts,
        showDaysOnMarket,
      },
    };

    return view;
  }

  getPrintPrognostic({
    sectionConfig,
  }: {
    sectionConfig: SideBySideComparisonSectionConfig;
  }): { isValid: true } | { isValid: false; message: string } {
    const printableFields = this.getPrintableFields();
    const defaultConfig = this.buildDefaultConfig();

    const config = printableFields.reduce((acc: any, field) => {
      acc[field] = sectionConfig[field] ?? defaultConfig[field];
      return acc;
    }, {} as SideBySideComparisonSectionConfig);

    const groupedFields: Array<
      [keyof SideBySideComparisonSectionConfig, keyof SideBySideComparisonSectionConfig]
    > = [
      ["showSoldPrice", "showListPrice"],
      ["showSoldDate", "showListDate"],
      ["showBaths", "showBeds"],
    ];

    let groupedRows = 0;
    groupedFields.forEach((group) => {
      if (group.some((field) => config[field])) groupedRows++;
      group.forEach((field) => {
        delete config[field];
      });
    });

    const totalRows = Object.values(config).filter(Boolean).length + groupedRows;
    const MAX_PRINTABLE_ROWS = 13;
    const isValid = totalRows <= MAX_PRINTABLE_ROWS;

    if (!isValid) {
      return {
        isValid,
        message: `Please uncheck some fields in the COMPARE section to print the PDF. (Max ${MAX_PRINTABLE_ROWS} rows)`,
      };
    }

    return { isValid };
  }
}

const createDictionary = (options: { label: string; value: string }[]) =>
  Object.assign({}, ...options.map((x) => ({ [x.value]: x.label })));

export const propertyTypeOptions = [
  { label: "Condo", value: "condo" },
  { label: "Co-op", value: "co-op" },
  { label: "Multi Family", value: "multi-family" },
  { label: "Single Family", value: "single-family" },
  { label: "Townhouse", value: "townhouse" },
  { label: "TIC", value: "tic" },
  { label: "Land", value: "land" },
  // { label: 'Other', value: 'other' }, // Since other is a free text, no reason to be an option
];
export const propertyTypeDictionary = createDictionary(propertyTypeOptions);

export const getPropertyTypeLabel = (value?: string | null) => {
  return propertyTypeDictionary[value ?? ""] || value;
};

export const propertyStatusesMap = {
  1000: { rawStatus: "Sold", status: "sold" },
  1001: { rawStatus: "Active", status: "active" },
  1002: { rawStatus: "Active Under Contract", status: "contingent" },
  1003: { rawStatus: "Contingent", status: "contingent" },
  1004: { rawStatus: "Pending", status: "pending" },
  1005: { rawStatus: "Coming Soon", status: "soon" },
  1006: { rawStatus: "Off Market", status: null },
} as const;

export const summaryStatisticsFieldsOptions = [
  { value: "soldPrice", label: "Sold Price" },
  { value: "soldToListPriceRatio", label: "Sold to List Price Ratio" },
  { value: "soldPriceBySqFt", label: "Sold Price By Sq Ft" },
  { value: "daysOnMarket", label: "Days On Market" },
];
export const summaryStatisticsFieldsDictionary = createDictionary(summaryStatisticsFieldsOptions);

export const summaryStatisticsDimensionsOptions = [
  { value: "neighborhood", label: "Neighborhood" },
  { value: "listingStatus", label: "Listing Status" },
  { value: "propertyType", label: "Property Type" },
];
export const summaryStatisticsDimensionsDictionary = createDictionary(
  summaryStatisticsDimensionsOptions
);

// Input
export const MAX_INTEGER_VALUE = 999_999_999;
export const MAX_INTEGER_SMALL_VALUE = 999;

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Typography, Grid, InputLabel, styled } from "@avenue-8/ui-2";
import { Redirect } from "react-router";
import { SpinnerBox } from "../../shared/components/SpinnerBox";
import { PasswordInput } from "./PasswordInput";
import { PasswordRequirements } from "./PasswordRequirements";
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";
import { AuthCard, ButtonContainer, Container, Form, PageContainer, Wrapper } from "./style";
import {
  ResetPasswordJwtTokenModel,
  useAuthContext,
} from "../../shared/contexts/AuthContext/AuthContext";
import { Link } from "react-router-dom";
import { authRoutes } from "../auth.routes";

interface ResetPasswordFormModel {
  password: string;
  password2: string;
}

const FormItem = styled(Grid)`
  margin-bottom: 2rem;
`;

const CustomTypography = styled(Typography)`
  padding-bottom: 24px;
`;

export const ResetPassword = ({ token }: { token: string }) => {
  const { control, handleSubmit, errors, setError, watch } = useForm();
  const { actions, state } = useAuthContext();
  const [decodedToken, setDecodedToken] = React.useState<ResetPasswordJwtTokenModel>(
    {} as ResetPasswordJwtTokenModel
  );

  React.useEffect(() => {
    const decoded = actions.validateResetPasswordToken(token);
    if (decoded) {
      setDecodedToken(decoded);
    }
  }, [token, actions]);

  React.useEffect(() => {
    if (state.resetPasswordState === "fail") {
      setError("password", {
        type: "manual",
        message: "Something went wrong.",
      });
    }
  }, [state.resetPasswordState, setError]);

  function onSave(data: ResetPasswordFormModel) {
    if (data.password !== data.password2) {
      setError("password", {
        type: "manual",
        message: "Passwords don't match, please try again.",
      });
    } else {
      actions.resetPassword(token, data.password);
    }
  }

  const isSubmitting = state.resetPasswordState === "init";

  let content: React.ReactNode;
  if (state.resetPasswordTokenState === "validating") {
    content = (
      <Container>
        <SpinnerBox />
      </Container>
    );
  } else if (
    state.resetPasswordTokenState === "invalid-token" ||
    state.resetPasswordTokenState === "token-expired"
  ) {
    content = <Redirect to="/invalid-link" />;
  } else if (
    state.resetPasswordTokenState === "valid-token" &&
    state.resetPasswordState !== "success"
  ) {
    const password = watch("password") ?? "";
    const title = decodedToken.type === "set" ? "Set" : "Reset";
    content = (
      <AuthCard>
        <CustomTypography variant="h3">MarketComp</CustomTypography>
        <Typography variant="h6">{title} password</Typography>
        <PasswordRequirements password={password} />
        <Form onSubmit={handleSubmit(onSave)}>
          <FormItem>
            <InputLabel htmlFor="email">New Password</InputLabel>
            <Controller
              name="password"
              control={control}
              defaultValue={""}
              rules={{
                required: true,
                pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
              }}
              render={(props) => (
                <PasswordInput
                  error={errors["password"]}
                  helperText={errors["password"]?.message}
                  fullWidth
                  withStrengthBar={true}
                  disabled={isSubmitting}
                  {...props}
                />
              )}
            />
          </FormItem>
          <FormItem>
            <InputLabel htmlFor="email">Confirm Password</InputLabel>
            <Controller
              name="password2"
              control={control}
              defaultValue={""}
              rules={{ required: true }}
              render={(props) => (
                <PasswordInput
                  error={errors["password2"]}
                  helperText={errors["password2"]?.message}
                  fullWidth
                  disabled={isSubmitting}
                  {...props}
                />
              )}
            />
          </FormItem>
          <ButtonContainer>
            <Button type="submit" fullWidth disabled={isSubmitting}>
              Login
            </Button>
          </ButtonContainer>
        </Form>
      </AuthCard>
    );
  } else if (state.resetPasswordState === "success") {
    content = (
      <AuthCard>
        <Wrapper>
          <SuccessIcon />
          <br />
          <br />
          <CustomTypography variant="h6">
            Your password has been changed successfully!
          </CustomTypography>
          <Button component={Link} to={authRoutes.auth.route}>
            Login
          </Button>
        </Wrapper>
      </AuthCard>
    );
  }
  return <PageContainer>{content}</PageContainer>;
};

import * as React from "react";
import { useLatest } from "./useLatest";

/**
 * Hook to listen to native form input events.
 * Created because it is not easy to have a similar and reliable listener using react-hook-form
 * Drawbacks: Non-native events - like MuiSelect - does not trigger onNativeInputChange
 */
export function useNativeFormInputListener({
  id,
  onNativeInputChange,
}: {
  id: string;
  onNativeInputChange: (key: string, value: string) => void;
}) {
  const changeHandler = useLatest(onNativeInputChange);
  React.useEffect(() => {
    const handleChange = (e: any) => {
      let value = e.target.value;
      if (value == null || value === "") value = e.target.checked;
      changeHandler.current(e.target.name, value);
    };
    document.getElementById(id)?.addEventListener("change", handleChange);
    return () => {
      document.getElementById(id)?.removeEventListener("change", handleChange);
    };
  }, [changeHandler, id]);
}

import * as React from "react";
import { InputLabel, Fieldset, FormHelperText, styled } from "@avenue-8/ui-2";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import { validateMinMax } from "../estimate-price";
import { MAX_INTEGER_VALUE } from "src/modules/shared/constants";
import { TextFieldBase } from "src/modules/cma-v2/components/TextFieldBase";

const Form = styled.form`
  width: 100%;
`;

const InputGroup = styled.div`
  display: flex;
  align-items: center;
`;

const ToContainer = styled.span`
  font-size: 10px;
  margin: 0 24px;
  text-transform: uppercase;
  color: ${(p) => p.theme.av8.input.label.color};
`;

export interface EstimatePriceFormModel {
  cmaId: string;
  estimatedPriceMin: string;
  estimatedPriceMax: string;
  notes?: string;
}

export const EstimatePriceForm = ({
  onSave,
  defaultOnBlur,
  disabled,
}: {
  onSave: (data: EstimatePriceFormModel) => void;
  defaultOnBlur: (param: { controlledOnBlur: () => void }) => Record<string, unknown>;
  disabled: boolean;
}) => {
  const { handleSubmit, errors, getValues, trigger } = useFormContext<EstimatePriceFormModel>();

  return (
    <Form onSubmit={handleSubmit(onSave)}>
      <Fieldset disabled={disabled}>
        <Controller
          name="cmaId"
          render={(p) => <input type="hidden" value={p.value} name={p.name} />}
        />
        <InputLabel htmlFor="estimate-range">Estimate Range</InputLabel>
        <InputGroup>
          <Controller
            name="estimatedPriceMin"
            rules={{
              validate: (estimatedPriceMin) =>
                validateMinMax(estimatedPriceMin, getValues("estimatedPriceMax")),
            }}
            render={({ ref, ...rest }) => (
              <NumberFormat
                customInput={TextFieldBase}
                prefix={"$"}
                thousandSeparator={true}
                decimalScale={0}
                placeholder="Min Price"
                error={!!errors.estimatedPriceMin}
                onValueChange={(v) => rest.onChange(v.value)}
                {...rest}
                {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                getInputRef={ref}
                isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
              />
            )}
          />
          <ToContainer>To</ToContainer>
          <Controller
            name="estimatedPriceMax"
            render={({ ref, ...rest }) => (
              <NumberFormat
                customInput={TextFieldBase}
                prefix={"$"}
                thousandSeparator={true}
                decimalScale={0}
                error={!!errors.estimatedPriceMin}
                placeholder="Max Price"
                onValueChange={(v) => {
                  rest.onChange(v.value);
                  trigger("estimatedPriceMin");
                }}
                {...rest}
                {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                getInputRef={ref}
                isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
              />
            )}
          />
        </InputGroup>
        <FormHelperText error={!!errors.estimatedPriceMin}>
          {errors.estimatedPriceMin?.message}
        </FormHelperText>
      </Fieldset>
    </Form>
  );
};

import * as React from "react";
import { InfoIcon, Tooltip } from "@avenue-8/ui-2";
import { IconContainer } from "../../../../../shared/components/IconContainer";
import { Title1 } from "../typography";
import { OpacityContainer } from "./HeroHeader.styles";
import { formatToCurrency } from "src/modules/shared/utils/formatters";

export const PriceRange = (props: { minSellPrice: number | null; maxSellPrice: number | null }) => {
  const { minSellPrice, maxSellPrice } = props;
  return (
    <OpacityContainer $disabled={!(minSellPrice && maxSellPrice)} data-testid="price-range">
      <Title1 marginTop={3} marginBottom={0.5} pageBreakBefore>
        {minSellPrice && minSellPrice === maxSellPrice
          ? `In today’s market, this home should sell for ${formatToCurrency(minSellPrice)}`
          : `In today’s market, this home should sell for between ${formatToCurrency(
              minSellPrice ?? 0
            )} and ${formatToCurrency(maxSellPrice ?? 0)}.`}
      </Title1>
      {!(minSellPrice && maxSellPrice) && (
        <Tooltip title="Price estimate will not be displayed until you add a range.">
          <IconContainer>
            <InfoIcon />
          </IconContainer>
        </Tooltip>
      )}
    </OpacityContainer>
  );
};

const trimNonNumericRE = /[^0-9.]/g;

export const stripNonNumericNumbers = (input: string | null | undefined) => {
  if (input == null) return input;
  if (typeof input !== "string") throw new Error(`Expected string got ${typeof input}`);
  return input.replace(trimNonNumericRE, "");
};

export const strToNullableFloat = (input: string | null | undefined) => {
  if (input == null) return null;
  if (typeof input !== "string") throw new Error(`Expected string got ${typeof input}`);
  if (input.trim().length === 0) return null;
  return parseFloat(input);
};

export const strToNullableInt = (input: string | null | undefined) => {
  if (input == null) return null;
  if (typeof input !== "string") throw new Error(`Expected string got ${typeof input}`);
  if (input.trim().length === 0) return null;
  return parseInt(input);
};

export const strToNullableString = (input: string | null | undefined) => {
  if (input == null) return null;
  if (typeof input !== "string") throw new Error(`Expected string got ${typeof input}`);
  if (input.trim().length === 0) return null;
  return input;
};

export const normalizeDecimalNumber = (value: any, decimalStep: number): string => {
  if (decimalStep >= 1 || decimalStep === 0 || decimalStep <= -1) return value;

  const roudedValue = Math.floor(value);
  const decimalPart = Number(value) - roudedValue;
  const rest = decimalPart % decimalStep;

  if (rest !== 0) {
    const newValue = roudedValue + decimalPart - rest;
    return String(newValue);
  } else if (decimalPart) {
    return String(Number(Number(value).toFixed(2)));
  } else {
    return String(Math.round(value));
  }
};

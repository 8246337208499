import React from "react";
import { useHistory } from "react-router";
import {
  Button,
  SummaryIcon,
  MapIcon,
  Grid,
  Pagination,
  Typography,
  styled,
  toast,
} from "@avenue-8/ui-2";
import { NextButton, BackButton } from "../../../Button";
import { BottomBar } from "../../CreateCMA";
import { useCreateCMALogic } from "../../../../hooks/useCreateCMALogic";
import { SearchResults } from "./components/SearchResults";
import { PropertyDto } from "../../../../../shared/apis/cma/generated";
import { SearchMapView } from "./components/SearchMapView";
import { SummaryView } from "./components/SummaryView";
import { SearchOptions } from "./components/SearchOptions";
import { usePropertySearchQueries } from "./usePropertySearch";
import { isModelEnoughToSearch } from "./search-comparable-form-model";
import { ComparablePropertyResultItemModal } from "./components/ComparablePropertyResultItemModal";
import { PhotoGalleryContextProvider } from "../../../../../shared/contexts/PhotoGalleryContext";
import { RouteChangeInterceptor } from "../../../RouteChangeInterceptor";
import { useCMARouteStepHelper } from "../../step-navigation-helper";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { usePropertySearchLogic } from "src/modules/cma-v2/hooks/usePropertySearchLogic";
import { useEditComparablePropertyLogic } from "../../EditComparableProperty/useEditComparablePropertyLogic";
import { useGoogleMapsApi } from "src/modules/cma-v2/hooks/useGoogleMapsApi";
import { GuidedTourPropertySearch } from "./components/GuidedTourPropertySearch";
import { geolocationService } from "src/modules/shared/services/geolocation";

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 1rem;
`;

const SearchPanel = styled(Grid)`
  padding-right: 1.5rem;
`;

const Tab = styled(Grid)`
  display: flex;
  gap: 2rem;
`;

const TabButton = styled(Button)<{ $active?: boolean }>`
  color: ${({ theme, $active }) => ($active ? theme.av8.primaryColor : theme.av8.secondaryColor)};
  text-transform: initial;
  letter-spacing: initial;
  padding: 0;
  font-size: 14px;
  svg {
    margin-right: 0.5rem;
  }
  &:focus,
  &:hover {
    outline: none;
    background-color: initial !important;
  }
`;

const Show = styled.div<{ $active: boolean }>`
  width: 100%;
  display: ${({ $active }) => ($active ? "block" : "none")};
  animation: ${({ $active }) =>
    $active ? "fadeShow 500ms ease-in-out" : "fadeHide 500ms ease-in-out"};

  @keyframes fadeHide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes fadeShow {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

type Coords = {
  lat: number;
  lng: number;
};

export const PropertySearchStep = () => {
  const history = useHistory();
  const { state, actions } = useCreateCMALogic();
  const [clickedResultItem, setClickedResultItem] = React.useState<PropertyDto | null>(null);
  const [resultHovered, setResultHovered] = React.useState<string | null>(null);
  const [rightPanel, setRightPanel] = React.useState<"map-view" | "summary-view">("map-view");
  const presentationType = state.cma?.presentationType;
  const cmaRouteStepHelper = useCMARouteStepHelper(presentationType);
  const nextStep = cmaRouteStepHelper.getNextStep();
  const subjectProperty = state.cma?.subjectProperty;
  const [defaultCoords, setDefaultCoords] = React.useState<Coords | undefined>();

  React.useEffect(() => {
    if (presentationType === "general") {
      geolocationService
        .getCurrentPosition()
        .then(({ coords }) => {
          setDefaultCoords({ lat: coords.latitude, lng: coords.longitude });
        })
        .catch((e) => {
          Boolean(e?.message) && console.log(e.message);
        });
      return;
    }
    if (presentationType === "cma") {
      if (!subjectProperty?.latitude || !subjectProperty?.longitude) return;
      const { latitude, longitude } = subjectProperty;
      setDefaultCoords({ lat: latitude, lng: longitude });
      return;
    }
  }, [subjectProperty, presentationType]);

  const {
    searchAndFiltersState,
    handleApplyFilters,
    handleClearFilters,
    handleApplySuggestionWithFilters,
    handleApplyMapFilters,
    handleSearch,
    handleChangePage,
  } = usePropertySearchLogic({
    initialValues: { businessType: "sale" },
    defaultCoords,
  });

  const { isReady: googleMapsApiIsReady } = useGoogleMapsApi({
    libraries: ["places", "geometry"],
  });

  const { searchQuery } = usePropertySearchQueries(
    searchAndFiltersState,
    presentationType,
    state.cma?.subjectProperty
  );
  const shouldShowResults = isModelEnoughToSearch(searchAndFiltersState);

  React.useEffect(() => {
    if (presentationType)
      appEventEmitter.emit({ eventType: "cma-search-loaded", presentationType });
  }, [presentationType]);

  const handlePopoverClick = (id: string) => {
    const property = searchQuery.data?.properties.find((p) => p.id === id);
    if (!property) return;
    setClickedResultItem(property);
  };

  const {
    actions: { setComparableProperty },
  } = useEditComparablePropertyLogic();

  const mapStatus = !googleMapsApiIsReady || !presentationType ? "loading" : searchQuery.status;

  return (
    <>
      <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5">Search</Typography>
        </Grid>
        <Tab item>
          <TabButton
            variant="text"
            $active={rightPanel === "summary-view"}
            onClick={() => setRightPanel("summary-view")}
            data-testid="summary-view"
          >
            <SummaryIcon /> Summary view
          </TabButton>
          <TabButton
            variant="text"
            $active={rightPanel === "map-view"}
            onClick={() => setRightPanel("map-view")}
            data-testid="map-tab"
          >
            <MapIcon /> Map view
          </TabButton>
        </Tab>
      </TitleHeaderGrid>

      <SearchPanel item xs={8}>
        <SearchOptions
          searchProperties={searchQuery}
          subjectProperty={state.cma?.subjectProperty}
          comparableProperties={state.cma?.comparableProperties ?? []}
          presentationType={presentationType ?? ""}
          sameBuildingAddress={state.cma?.subjectProperty.addressLine1 ?? ""}
          onDeleteProperty={(mlsId) => actions.removeCompListing(mlsId, true)}
          filters={searchAndFiltersState}
          radiusFilterCoords={defaultCoords}
          onApplyFilters={handleApplyFilters}
          onClearFilters={handleClearFilters}
          onApplySuggestionWithFilters={handleApplySuggestionWithFilters}
          onSearch={handleSearch}
          reorderComps={(properties: PropertyDto[]) => actions.reorderComps(properties)}
          onAddComparablePropertyClick={() => {
            appEventEmitter.emit({
              eventType: "cma-manual-comparable-property-modal-add-opened",
              presentationType: state.cma!.presentationType,
            });
            setComparableProperty({} as PropertyDto);
          }}
        />
        {shouldShowResults && (
          <>
            <SearchResults
              searchQuery={searchQuery}
              onResultHover={setResultHovered}
              onResultClicked={setClickedResultItem}
            />
            {searchQuery.data?.meta && searchQuery.data.meta.totalPages > 1 && (
              <Pagination
                currentPage={searchQuery.data?.meta?.currentPage || 1}
                totalPages={Math.min(searchQuery.data?.meta?.totalPages || 1, 1000)}
                onPageClick={(page: number) => handleChangePage({ page })}
              />
            )}
          </>
        )}
      </SearchPanel>

      <Grid container item xs={4}>
        <Show $active={rightPanel === "map-view"} id="map-search-wrapper">
          <SearchMapView
            status={mapStatus}
            comparableProperties={searchQuery.data?.properties || []}
            subjectProperty={state.cma?.subjectProperty}
            highlightedPropertyId={resultHovered}
            filters={searchAndFiltersState}
            applyFilters={handleApplyMapFilters}
            onPopoverClick={handlePopoverClick}
            presentationType={presentationType ?? ""}
          />
        </Show>
        <Show $active={rightPanel === "summary-view"}>
          <SummaryView comparableProperties={state.cma?.comparableProperties || []} />
        </Show>
      </Grid>
      {!!nextStep?.label && (
        <BottomBar>
          <BackButton onClick={() => cmaRouteStepHelper.handleBackClick(history)}>Back</BackButton>
          <NextButton type="submit" onClick={() => cmaRouteStepHelper.handleForwardClick(history)}>
            Next: {nextStep.label}
          </NextButton>
        </BottomBar>
      )}
      <PhotoGalleryContextProvider>
        <ComparablePropertyResultItemModal
          open={clickedResultItem ? true : false}
          onClose={() => setClickedResultItem(null)}
          resultItem={clickedResultItem}
          onRemoveCompListing={async () => actions.removeCompListing(clickedResultItem!, true)}
          onSelectCompListing={async () => actions.selectCompListing(clickedResultItem!)}
          presentationType={presentationType ?? ""}
          comparableProperties={state.cma?.comparableProperties ?? []}
        />
      </PhotoGalleryContextProvider>
      <RouteChangeInterceptor
        onIntercepting={async (location) => {
          const cma = state.cma;
          const isForward = cmaRouteStepHelper.getPathDirection(location.pathname) === "isForward";
          if (isForward) {
            if (state.isFetchingCma === true) return "cancel"; // cancel forward navigation if we are fetching the cma
            if ((cma?.comparableProperties.length ?? 0) === 0) {
              toast.error("You need to add at least one comparable property.", {
                shouldDeduplicate: true,
              });
              return "cancel";
            }
          }
          return "navigate";
        }}
      />
      <GuidedTourPropertySearch />
    </>
  );
};

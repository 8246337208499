import { styledThemed as styled } from "../theme";

export const PrintOff = styled.div`
  @media print {
    display: none;
  }
`;

export const PrintOffInline = styled.span`
  @media print {
    display: none;
  }
`;

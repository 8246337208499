import { PresentationSourceData } from "./../../models/presentation-source-data";
import {
  DefaultConfigBuilderContext,
  PresentationType,
  SectionViewBuilderContext,
  SectionWidget,
  ShouldFetchDataResolverContext,
} from "../section-widget";
import { InsightsSectionConfig } from "./insights-section-config";
import { InsightsSectionData } from "./insights-section-data";
import { InsightsSectionView } from "./insights-section-view";
import { buildMedianSalesPriceYearComparision } from "./insights-section-view-builder/build-median-sales-price-year-comparision";
import { buildAverageLivingSpaceSqftPrice } from "./insights-section-view-builder/build-average-living-space-sqft-price";
import { buildInventorySpeedComparison } from "./insights-section-view-builder/build-inventory-speed-comparison";
import { InsightItem } from "../../models";
import { InsightViewBuilder } from "./insights-section-view-builder/insights-section-view-builder";
import { v4 as uuidv4 } from "uuid";

export class InsightsSectionWidget
  implements SectionWidget<"insights", InsightsSectionConfig, InsightsSectionView>
{
  type: "insights" = "insights";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  private builders: {
    buildMedianSalesPriceYearComparision: InsightViewBuilder;
    buildAverageLivingSpaceSqftPrice: InsightViewBuilder;
    buildInventorySpeedComparison: InsightViewBuilder;
  } = {
    buildMedianSalesPriceYearComparision,
    buildAverageLivingSpaceSqftPrice,
    buildInventorySpeedComparison,
  };

  buildDefaultConfig(context: DefaultConfigBuilderContext): InsightsSectionConfig {
    const { featuredProperty } = context.sourceData;
    return {
      type: "insights",
      navTitle: "Insights",
      id: uuidv4(),
      visibility: true,
      showMedianSalesPriceYearComparision: true,
      showAverageLivingSpaceSqftPrice: true,
      showInventorySpeedComparison: true,
      neighborhood: featuredProperty.neighborhood ?? undefined,
      city: featuredProperty.city ?? undefined,
      state: featuredProperty.state ?? undefined,
      isLease: featuredProperty.isLease ?? undefined,
      propertyType: featuredProperty.propertyType ?? undefined,
    };
  }

  buildView({
    sectionConfig: config,
    context,
    sectionData: data,
  }: SectionViewBuilderContext<InsightsSectionConfig, InsightsSectionData>): InsightsSectionView {
    if (config.type !== "insights") throw new Error("Unexpected section type.");

    const {
      showMedianSalesPriceYearComparision = true,
      showAverageLivingSpaceSqftPrice = true,
      showInventorySpeedComparison = true,
    } = config;

    const insights: InsightItem[] = [];
    const buildersArr = [
      showMedianSalesPriceYearComparision
        ? this.builders.buildMedianSalesPriceYearComparision
        : null,
      showAverageLivingSpaceSqftPrice ? this.builders.buildAverageLivingSpaceSqftPrice : null,
      showInventorySpeedComparison ? this.builders.buildInventorySpeedComparison : null,
    ];
    buildersArr.forEach((builder) => {
      if (builder != null) {
        const insight = builder({ context, data: data });
        if (insight) insights.push(insight);
      }
    });

    const hasInsightsToDisplay = insights.some((insight) => insight.mode === "ok");

    const { id, type, visibility, navTitle } = config;
    const view: InsightsSectionView = {
      id,
      type,
      insights,
      visibility: !hasInsightsToDisplay ? false : visibility,
      navTitle: navTitle ?? "Insights",
    };
    return view;
  }

  shouldInvalidateData({
    prevConfig,
    nextConfig,
  }: ShouldFetchDataResolverContext<InsightsSectionConfig, InsightsSectionData>): boolean {
    const fields: Array<keyof InsightsSectionConfig> = [
      "neighborhood",
      "city",
      "state",
      "isLease",
      "propertyType",
    ];
    return fields.some((field) => prevConfig[field] !== nextConfig[field]);
  }

  canFetchData({
    config,
    presentationSourceData,
  }: {
    config: InsightsSectionConfig;
    presentationSourceData: PresentationSourceData;
  }) {
    const { featuredProperty } = presentationSourceData;
    const hasNeighborhood = !!config.neighborhood || !!featuredProperty.neighborhood;
    const hasCity = !!config.city || !!featuredProperty.city;
    const hasState = !!config.state || !!featuredProperty.state;
    const hasIsLease = config.isLease != null || featuredProperty.isLease != null;

    return hasNeighborhood && hasCity && hasState && hasIsLease;
  }
}

import { Grid, Typography, styled } from "@avenue-8/ui-2";
import { listingStatusColor } from "../../../../../../../shared/css-snippets";

export const Wrapper = styled.div`
  img {
    border-radius: 4px;
  }
  padding: 1.75rem;
`;
export const ContentTitle = styled(Typography)`
  font-size: 10px;
  line-height: 100%;
  color: ${(p) => p.theme.av8.secondaryColor};
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 8px;
`;
export const Description = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 200%;
  color: ${(p) => p.theme.av8.secondaryColor};
  background-color: ${(p) => p.theme.av8.backgroundAlt};
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  border-radius: 4px;
  overflow: auto;
  height: 100%;
  max-height: 169px;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 20px;
`;
export const StyledMap = styled.div`
  position: sticky;
  min-height: 224px;
  max-height: 400px;
  overflow: hidden;
  top: 104px;
`;
export const DetailsGrid = styled(Grid)`
  gap: 1rem 0;
`;
export const ComparisonItemListingStatus = styled(Typography)<{
  $listingStatus: string | undefined;
}>`
  font-size: 0.75rem !important;
  text-transform: uppercase;
  line-height: 1.43;
  font-weight: bolder;
  letter-spacing: 2.5px;
  max-height: 40px;

  ${(p) => listingStatusColor(p.$listingStatus)}
`;
export const ItemTitle = styled.div`
  padding-bottom: 1.25rem;
  display: flex;
  gap: 1.125rem;
  align-items: center;
`;

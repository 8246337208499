import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Button,
  InputLabel,
  IconButton,
  CloseIcon,
  styled,
} from "@avenue-8/ui-2";
import { Controller, useForm } from "react-hook-form";
import { TextFieldBase } from "../../../../TextFieldBase";
import { isNullOrEmptyString } from "../../../../../../shared/utils/validations";
import { usePresentationTemplate } from "../usePresentationTemplate";
import { usePresentationEditorLogic } from "../usePresentationEditorLogic";

const Form = styled.form`
  margin-bottom: 16px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export interface SaveCMATemplateModalProps extends Omit<DialogProps, "onBackdropClick"> {
  onClose: () => void;
}

interface SaveCMATemplateModel {
  templateName: string;
}

const defaultValues: SaveCMATemplateModel = {
  templateName: "",
};

export const SaveCMATemplateModal = ({ onSubmit, onClose, ...rest }: SaveCMATemplateModalProps) => {
  const { control, handleSubmit, errors } = useForm({ defaultValues });
  const { state } = usePresentationEditorLogic();
  const templateId = state.loadState === "loaded" ? state.templateId : null;
  const { saveCmaTemplate } = usePresentationTemplate({ templateId });

  const handleSave = async (data: SaveCMATemplateModel) => {
    saveCmaTemplate({ name: data.templateName });
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} {...rest} data-testid="save-template-modal">
      <DialogTitle id="creare-empty-cma-dialog-title">
        <Typography variant="h6">Save Template</Typography>
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent>
        <Form id="save-cma-template" onSubmit={handleSubmit(handleSave)}>
          <InputLabel htmlFor="templateName">Template Name</InputLabel>
          <Controller
            name="templateName"
            control={control}
            rules={{
              validate: {
                required: (v) => (isNullOrEmptyString(v) ? "Missing Template Name" : undefined),
              },
            }}
            render={({ ref, ...rest }) => (
              <TextFieldBase
                placeholder="Enter template name"
                autoFocus
                errors={errors}
                inputRef={ref}
                {...rest}
              />
            )}
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" data-meta-action="cancel">
          Cancel
        </Button>
        <Button form="save-cma-template" type="submit" data-meta-action="save">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

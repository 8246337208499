import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Button, Grid, Typography, Loader, styled } from "@avenue-8/ui-2";
import { Logo } from "../../../shared/components/Logo";
import { PageLayout } from "../../../cma-v2/components/PageLayout";
import { UserMenu } from "../../../cma-v2/components/UserMenu";
import { useBreakpoint } from "../../../cma-v2/hooks/useMobile";
import { useAuthContext } from "../../../shared/contexts/AuthContext/AuthContext";
import {
  PersonalDetailsForm,
  PersonalDetailsFormModel,
} from "../../../agent-onboarding/components/Steps/PersonalDetailsStep/PersonalDetailsForm";
import {
  AgentDetailsForm,
  AgentDetailsFormModel,
} from "../../../agent-onboarding/components/Steps/AgentDetailsStep/AgentDetailsForm";
import { useAppContext } from "../../../../AppContext";
import { HeadshotPreview } from "../../../agent-onboarding/components/Steps/UploadHeadshotStep/HeadshotPreview";
import {
  formDataToUrl,
  UploadHeadshotForm,
} from "../../../agent-onboarding/components/Steps/ReviewStep/UploadHeadshotForm";
import { ReviewFormModel } from "../../../agent-onboarding/components/Steps/ReviewStep/ReviewStep";
import { useAgentAccountLogic } from "../../hooks/useAgentAccountLogic";

export interface AgentAccountFormModel extends PersonalDetailsFormModel, AgentDetailsFormModel {}

const StyledUserMenu = styled(UserMenu)`
  margin: 0px auto;
`;

const StepBottomBar = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background-color: ${(p) => p.theme.av8.background};
`;

const StepCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  padding: 32px;
  background-color: ${(p) => p.theme.av8.background};
  box-shadow: 0px 4px 40px ${(p) => p.theme.av8.boxShadowColor};
  border-radius: 4px;
  width: 100%;
  max-width: 780px;
  div {
    box-sizing: border-box;
  }
`;

const StepContent = styled.div`
  width: 100%;
  padding: 8px 32px;
`;

const Footer = styled.div`
  padding: 40px;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 40px;
`;

const LoaderContainer = styled(Grid)`
  min-height: 300px;
`;

export const EditAgentAccount = () => {
  const { state: authState, actions: authActions } = useAuthContext();
  const { actions: appActions } = useAppContext();
  const history = useHistory();
  const isSmall = useBreakpoint("desktopDown");
  const [isEditing, setIsEditing] = React.useState(false);
  const [newHeadshotPhoto, setNewHeadshotPhoto] = React.useState<{
    formData: FormData;
    url: string;
  } | null>(null);
  const formId = "edit-agent-account";

  const { state, actions } = useAgentAccountLogic();
  const {
    state: { userInfo },
  } = useAuthContext();
  const loadAgentData = state.loadAgentOnboardingQuery.data?.data;

  const personalDetailsDefaultValues = loadAgentData
    ? {
        firstName: loadAgentData.firstName,
        lastName: loadAgentData.lastName,
        preferredContactEmail: loadAgentData.preferredContactEmail,
        phoneNumber: loadAgentData.phoneNumber,
      }
    : {};
  const agentDetailsDefaultValues = loadAgentData
    ? {
        licenseNumber: loadAgentData.licenseNumber,
        state: loadAgentData.state,
        brokerage: loadAgentData.brokerage,
        team: loadAgentData.team,
      }
    : {};

  const formMethods = useForm<AgentAccountFormModel>({
    defaultValues: {
      ...personalDetailsDefaultValues,
      ...agentDetailsDefaultValues,
    },
    mode: "onBlur",
  });
  const { handleSubmit } = formMethods;

  const discardChanges = async () => {
    const isDiscarded = await appActions.confirm({
      title: "Discard changes",
      message: "Are you sure you want to discard all the changes?",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    });

    if (isDiscarded) {
      history.goBack();
    }
  };

  const handleSave = async (data: Partial<ReviewFormModel>) => {
    await Promise.all([
      actions.saveAgentOnboarding({ data }),
      newHeadshotPhoto ? actions.uploadAgentHeadshotPhoto(newHeadshotPhoto.formData) : null,
    ]);
    history.goBack();
  };

  const initials =
    authState.userInfo?.fullName
      ?.split(" ")
      ?.map((x) => x[0] ?? "")
      ?.join("") ?? "";

  return (
    <PageLayout
      userMenu={
        <StyledUserMenu
          userFullName={authState.userInfo?.fullName}
          avatarUrl={authState.userInfo?.avatar}
          onLogoutClick={() => authActions.signOut()}
        />
      }
      headerContent={<></>}
      logo={<Logo />}
      contentReservedHeight={isSmall ? 96 : 192}
      headerPosition={isSmall ? "static" : "fixed"}
    >
      <StepCardLayout>
        <StepContent>
          <Container>
            <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
              <Grid item>
                <Typography variant="h5">My Account</Typography>
              </Grid>
            </TitleHeaderGrid>
            {state.loadAgentOnboardingQuery.status === "loading" && (
              <LoaderContainer container item justifyContent="center" alignItems="center">
                <Loader color="black" />
              </LoaderContainer>
            )}
            {state.loadAgentOnboardingQuery.status === "success" && (
              <FormProvider {...formMethods}>
                <Form id={formId} onSubmit={handleSubmit(handleSave)} noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid container item md={7} spacing={3}>
                      <PersonalDetailsForm
                        defaultValues={personalDetailsDefaultValues}
                        readOnly={!isEditing}
                      />
                      <AgentDetailsForm
                        defaultValues={agentDetailsDefaultValues}
                        readOnly={!isEditing}
                      />
                    </Grid>
                    <Grid container item md={5} justifyContent="center">
                      {isEditing ? (
                        <UploadHeadshotForm
                          userFullName={authState.userInfo?.fullName}
                          avatarUrl={newHeadshotPhoto?.url ?? userInfo?.avatar}
                          uploadAgentHeadshotPhoto={(headshot) => {
                            formDataToUrl(headshot, (headshotUrl) => {
                              setNewHeadshotPhoto({
                                formData: headshot,
                                url: headshotUrl as string,
                              });
                            });
                          }}
                          status={state.uploadAgentHeadshotPhoto.status}
                        />
                      ) : (
                        <HeadshotPreview
                          image={userInfo?.avatar}
                          openCropHeadshotModal={null}
                          size={152}
                          initials={initials}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Form>
              </FormProvider>
            )}
          </Container>
        </StepContent>
        <StepBottomBar>
          {isEditing ? (
            <>
              <Button onClick={discardChanges} variant="text">
                Cancel
              </Button>
              <Button form={formId} type="submit">
                Save Changes
              </Button>
            </>
          ) : (
            <Button onClick={() => setIsEditing(true)}>Edit</Button>
          )}
        </StepBottomBar>
      </StepCardLayout>
      <Footer />
    </PageLayout>
  );
};

import * as React from "react";
import { InfoIcon, Typography } from "@avenue-8/ui-2";
import {
  Trigger,
  Popover,
  BreakdownContainer,
  BreakdownItem,
  BreakdownLabel,
} from "./BreakdownPopover.styles";
import { RecurringCost } from "src/modules/presentation/presentation-generation-logic/models/comparable-property";
import { formatToCurrency } from "src/modules/shared/utils/formatters";
import { calculateMonthlies } from "src/modules/presentation/presentation-generation-logic/shared/recurring-costs-helper";

interface Props {
  /**
   * size of the icon
   * @default 16
   * */
  size?: number;
  id?: string;
  fees: RecurringCost[];
}

export const BreakdownPopover = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { size = 16, id = "fees-breakdown", fees } = props;

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const totalMonthlies = calculateMonthlies(fees);

  return (
    <>
      <Trigger
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        onClick={handlePopoverOpen}
        style={{ height: size, width: size }}
      >
        <InfoIcon size={size} />
      </Trigger>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverOpen}
        sx={{
          pointerEvents: "none",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="body2">FEE'S Breakdown</Typography>
        <BreakdownContainer container>
          {fees.map(
            (fee) =>
              fee.value != null && (
                <BreakdownItem key={fee.type}>
                  <BreakdownLabel variant="body2">{fee.label}</BreakdownLabel>
                  {fee.frequency === "yearly" && (
                    <Typography variant="body2">{formatToCurrency(fee.value)} / 12</Typography>
                  )}
                  {fee.frequency === "monthly" && (
                    <Typography variant="body2">{formatToCurrency(fee.value)}</Typography>
                  )}
                </BreakdownItem>
              )
          )}
          <BreakdownItem>
            <BreakdownLabel variant="body2">Total</BreakdownLabel>
            <Typography variant="body2">{formatToCurrency(totalMonthlies)} / mo</Typography>
          </BreakdownItem>
        </BreakdownContainer>
      </Popover>
    </>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ListingStatusItemDto,
    ListingStatusItemDtoFromJSON,
    ListingStatusItemDtoFromJSONTyped,
    ListingStatusItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListListingStatusDto
 */
export interface ListListingStatusDto {
    /**
     * 
     * @type {Array<ListingStatusItemDto>}
     * @memberof ListListingStatusDto
     */
    listingStatuses: Array<ListingStatusItemDto>;
}

export function ListListingStatusDtoFromJSON(json: any): ListListingStatusDto {
    return ListListingStatusDtoFromJSONTyped(json, false);
}

export function ListListingStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListListingStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'listingStatuses': ((json['listingStatuses'] as Array<any>).map(ListingStatusItemDtoFromJSON)),
    };
}

export function ListListingStatusDtoToJSON(value?: ListListingStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'listingStatuses': ((value.listingStatuses as Array<any>).map(ListingStatusItemDtoToJSON)),
    };
}



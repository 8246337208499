/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PropertySuggestionDto,
    PropertySuggestionDtoFromJSON,
    PropertySuggestionDtoFromJSONTyped,
    PropertySuggestionDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SuggestionsResponseDto
 */
export interface SuggestionsResponseDto {
    /**
     * List of suggested addresses
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    addressSuggestions?: Array<PropertySuggestionDto> | null;
    /**
     * List of suggested counties
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    countySuggestions?: Array<PropertySuggestionDto> | null;
    /**
     * List of suggested neighborhoods
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    neighborhoodSuggestions?: Array<PropertySuggestionDto> | null;
    /**
     * List of suggested areas
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    areaSuggestions?: Array<PropertySuggestionDto> | null;
    /**
     * List of suggested cities
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    citySuggestions?: Array<PropertySuggestionDto> | null;
    /**
     * List of suggested zip codes
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    zipCodeSuggestions?: Array<PropertySuggestionDto> | null;
    /**
     * List of suggested mlsIds
     * @type {Array<PropertySuggestionDto>}
     * @memberof SuggestionsResponseDto
     */
    mlsIdSuggestions?: Array<PropertySuggestionDto> | null;
}

export function SuggestionsResponseDtoFromJSON(json: any): SuggestionsResponseDto {
    return SuggestionsResponseDtoFromJSONTyped(json, false);
}

export function SuggestionsResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuggestionsResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addressSuggestions': !exists(json, 'addressSuggestions') ? undefined : (json['addressSuggestions'] === null ? null : (json['addressSuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
        'countySuggestions': !exists(json, 'countySuggestions') ? undefined : (json['countySuggestions'] === null ? null : (json['countySuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
        'neighborhoodSuggestions': !exists(json, 'neighborhoodSuggestions') ? undefined : (json['neighborhoodSuggestions'] === null ? null : (json['neighborhoodSuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
        'areaSuggestions': !exists(json, 'areaSuggestions') ? undefined : (json['areaSuggestions'] === null ? null : (json['areaSuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
        'citySuggestions': !exists(json, 'citySuggestions') ? undefined : (json['citySuggestions'] === null ? null : (json['citySuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
        'zipCodeSuggestions': !exists(json, 'zipCodeSuggestions') ? undefined : (json['zipCodeSuggestions'] === null ? null : (json['zipCodeSuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
        'mlsIdSuggestions': !exists(json, 'mlsIdSuggestions') ? undefined : (json['mlsIdSuggestions'] === null ? null : (json['mlsIdSuggestions'] as Array<any>).map(PropertySuggestionDtoFromJSON)),
    };
}

export function SuggestionsResponseDtoToJSON(value?: SuggestionsResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'addressSuggestions': value.addressSuggestions === undefined ? undefined : (value.addressSuggestions === null ? null : (value.addressSuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
        'countySuggestions': value.countySuggestions === undefined ? undefined : (value.countySuggestions === null ? null : (value.countySuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
        'neighborhoodSuggestions': value.neighborhoodSuggestions === undefined ? undefined : (value.neighborhoodSuggestions === null ? null : (value.neighborhoodSuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
        'areaSuggestions': value.areaSuggestions === undefined ? undefined : (value.areaSuggestions === null ? null : (value.areaSuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
        'citySuggestions': value.citySuggestions === undefined ? undefined : (value.citySuggestions === null ? null : (value.citySuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
        'zipCodeSuggestions': value.zipCodeSuggestions === undefined ? undefined : (value.zipCodeSuggestions === null ? null : (value.zipCodeSuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
        'mlsIdSuggestions': value.mlsIdSuggestions === undefined ? undefined : (value.mlsIdSuggestions === null ? null : (value.mlsIdSuggestions as Array<any>).map(PropertySuggestionDtoToJSON)),
    };
}



import React from "react";
import { Grid, Typography, styled } from "@avenue-8/ui-2";
import { AgentOnboardingStepBaseProps } from "../../AgentOnboarding";
import { UploadHeadshotForm } from "./UploadHeadshotForm";
import { useAgentOnboardingLogic } from "../../../hooks/useAgentOnboardingLogic";
import { useAuthContext } from "../../../../shared/contexts/AuthContext/AuthContext";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 2.5rem;
`;

const FormContainer = styled(Grid)`
  margin: 3.5rem auto 5rem auto;
  max-width: 368px;
`;

interface UploadHeadshotStepProps extends AgentOnboardingStepBaseProps {
  onSave: () => void;
}

export const UploadHeadshotStep = ({ formId, onSave }: UploadHeadshotStepProps) => {
  const { actions } = useAgentOnboardingLogic();
  const {
    state: { userInfo },
  } = useAuthContext();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    actions.uploadAgentHeadshotPhotoReset();
    onSave();
  };

  return (
    <Container>
      <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5">Upload your headshot</Typography>
        </Grid>
      </TitleHeaderGrid>

      <FormContainer container item md={8} spacing={3}>
        <UploadHeadshotForm avatarUrl={userInfo?.avatar} />
        <form id={formId} onSubmit={handleSubmit} />
      </FormContainer>
    </Container>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PatchCMARecurringCostItemDto,
    PatchCMARecurringCostItemDtoFromJSON,
    PatchCMARecurringCostItemDtoFromJSONTyped,
    PatchCMARecurringCostItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SubjectProperty
 */
export interface SubjectProperty {
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    mlsId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    mlsSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    addressLine1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    lotSizeSqFt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    bathCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    bedCount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    buildingName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    garageSpaces?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    hoaFeeCents?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    currentPrice?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SubjectProperty
     */
    listDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    soldPrice?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof SubjectProperty
     */
    soldDate?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    livingAreaSqFt?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    neighborhood?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubjectProperty
     */
    styles?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    styleOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    typeOther?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    state?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    lat?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    lng?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    yearBuilt?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    zipCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    floor?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectProperty
     */
    hasDoormanAttendance?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubjectProperty
     */
    isLease?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SubjectProperty
     */
    roomsTotal?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    flipTaxRemarks?: string | null;
    /**
     * 
     * @type {Array<PatchCMARecurringCostItemDto>}
     * @memberof SubjectProperty
     */
    recurringCosts?: Array<PatchCMARecurringCostItemDto> | null;
    /**
     * 
     * @type {string}
     * @memberof SubjectProperty
     */
    listingStatus?: string | null;
}

export function SubjectPropertyFromJSON(json: any): SubjectProperty {
    return SubjectPropertyFromJSONTyped(json, false);
}

export function SubjectPropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubjectProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mlsId': !exists(json, 'mlsId') ? undefined : json['mlsId'],
        'mlsSource': !exists(json, 'mlsSource') ? undefined : json['mlsSource'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'lotSizeSqFt': !exists(json, 'lotSizeSqFt') ? undefined : json['lotSizeSqFt'],
        'bathCount': !exists(json, 'bathCount') ? undefined : json['bathCount'],
        'bedCount': !exists(json, 'bedCount') ? undefined : json['bedCount'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'garageSpaces': !exists(json, 'garageSpaces') ? undefined : json['garageSpaces'],
        'hoaFeeCents': !exists(json, 'hoaFeeCents') ? undefined : json['hoaFeeCents'],
        'currentPrice': !exists(json, 'currentPrice') ? undefined : json['currentPrice'],
        'listDate': !exists(json, 'listDate') ? undefined : (json['listDate'] === null ? null : new Date(json['listDate'])),
        'soldPrice': !exists(json, 'soldPrice') ? undefined : json['soldPrice'],
        'soldDate': !exists(json, 'soldDate') ? undefined : (json['soldDate'] === null ? null : new Date(json['soldDate'])),
        'livingAreaSqFt': !exists(json, 'livingAreaSqFt') ? undefined : json['livingAreaSqFt'],
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'styles': !exists(json, 'styles') ? undefined : json['styles'],
        'styleOther': !exists(json, 'styleOther') ? undefined : json['styleOther'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'typeOther': !exists(json, 'typeOther') ? undefined : json['typeOther'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'lat': !exists(json, 'lat') ? undefined : json['lat'],
        'lng': !exists(json, 'lng') ? undefined : json['lng'],
        'yearBuilt': !exists(json, 'yearBuilt') ? undefined : json['yearBuilt'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'hasDoormanAttendance': !exists(json, 'hasDoormanAttendance') ? undefined : json['hasDoormanAttendance'],
        'isLease': !exists(json, 'isLease') ? undefined : json['isLease'],
        'roomsTotal': !exists(json, 'roomsTotal') ? undefined : json['roomsTotal'],
        'flipTaxRemarks': !exists(json, 'flipTaxRemarks') ? undefined : json['flipTaxRemarks'],
        'recurringCosts': !exists(json, 'recurringCosts') ? undefined : (json['recurringCosts'] === null ? null : (json['recurringCosts'] as Array<any>).map(PatchCMARecurringCostItemDtoFromJSON)),
        'listingStatus': !exists(json, 'listingStatus') ? undefined : json['listingStatus'],
    };
}

export function SubjectPropertyToJSON(value?: SubjectProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'lotSizeSqFt': value.lotSizeSqFt,
        'bathCount': value.bathCount,
        'bedCount': value.bedCount,
        'buildingName': value.buildingName,
        'city': value.city,
        'description': value.description,
        'garageSpaces': value.garageSpaces,
        'hoaFeeCents': value.hoaFeeCents,
        'currentPrice': value.currentPrice,
        'listDate': value.listDate === undefined ? undefined : (value.listDate === null ? null : value.listDate.toISOString()),
        'soldPrice': value.soldPrice,
        'soldDate': value.soldDate === undefined ? undefined : (value.soldDate === null ? null : value.soldDate.toISOString()),
        'livingAreaSqFt': value.livingAreaSqFt,
        'neighborhood': value.neighborhood,
        'styles': value.styles,
        'styleOther': value.styleOther,
        'type': value.type,
        'typeOther': value.typeOther,
        'state': value.state,
        'lat': value.lat,
        'lng': value.lng,
        'yearBuilt': value.yearBuilt,
        'zipCode': value.zipCode,
        'floor': value.floor,
        'hasDoormanAttendance': value.hasDoormanAttendance,
        'isLease': value.isLease,
        'roomsTotal': value.roomsTotal,
        'flipTaxRemarks': value.flipTaxRemarks,
        'recurringCosts': value.recurringCosts === undefined ? undefined : (value.recurringCosts === null ? null : (value.recurringCosts as Array<any>).map(PatchCMARecurringCostItemDtoToJSON)),
        'listingStatus': value.listingStatus,
    };
}



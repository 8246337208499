import * as React from "react";
import { useQueryClient } from "react-query";
import { MutationFilters } from "react-query/types/core/utils";

export const useOnAnyIsMutating = ({
  handler,
  mutationFilters,
}: {
  mutationFilters: MutationFilters[];
  handler: (isMutating: boolean) => void;
}) => {
  const queryClient = useQueryClient();
  const latestMutationFilters = React.useRef(mutationFilters);
  latestMutationFilters.current = mutationFilters;
  const latestHandler = React.useRef(handler);
  latestHandler.current = handler;
  const latestIsMutating = React.useRef(false);

  React.useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(() => {
      const anyIsMutating = latestMutationFilters.current.some((mutationFilter) => {
        const mutatingCount = queryClient.isMutating(mutationFilter);
        return mutatingCount > 0;
      });
      if (anyIsMutating !== latestIsMutating.current) {
        latestHandler.current(anyIsMutating);
        latestIsMutating.current = anyIsMutating;
      }
    });
    return () => {
      unsubscribe();
    };
  }, [queryClient]);
};

import React from "react";
import { Typography } from "@avenue-8/ui-2";
import { ConstrainedContainer } from "./ConstrainedContainer";
import { Title1 } from "./typography";
import { styledThemed as styled } from "../theme";
import { FreeTextSectionView } from "../../../../presentation/presentation-generation-logic/models/section-view";
import { SectionViewLayout } from "./SectionViewLayout";
import { AvoidPrintBreakInside } from "./AvoidPrintBreakInside";

interface Props extends FreeTextSectionView {}

const Content = styled(Typography)`
  max-width: 520px;
  text-align: center;
  padding: 20px 0 20px 0;
  margin: 0 auto;
`;

export const FreeText = ({ id, title, content }: Props) => {
  return (
    <SectionViewLayout>
      <AvoidPrintBreakInside>
        <ConstrainedContainer id={id}>
          <Title1 textAlign="center">{title}</Title1>
          <Content variant="body2">
            {content
              .trim()
              .split(/\n/)
              .map((x, i) => (
                <div key={i}>{x || <>&nbsp;</>}</div>
              ))}
          </Content>
        </ConstrainedContainer>
      </AvoidPrintBreakInside>
    </SectionViewLayout>
  );
};

import { cmaGetHandlers } from "./cma-get-handlers";
import { cmaListHandlers } from "./cma-list-handlers";
import { cmaPropertiesListHandlers } from "./cma-properties-list-handlers";
import { propertiesPropertyTypeHandlers } from "./properties-property-type-handlers";

export const cmaHandlers = [
  ...cmaListHandlers,
  ...cmaGetHandlers,
  ...cmaPropertiesListHandlers,
  ...propertiesPropertyTypeHandlers,
];

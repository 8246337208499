/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetricResponse
 */
export interface MetricResponse {
    /**
     * 
     * @type {string}
     * @memberof MetricResponse
     */
    dimensionName: string;
    /**
     * 
     * @type {number}
     * @memberof MetricResponse
     */
    average: number;
    /**
     * 
     * @type {number}
     * @memberof MetricResponse
     */
    median: number;
    /**
     * 
     * @type {number}
     * @memberof MetricResponse
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof MetricResponse
     */
    max: number;
}

export function MetricResponseFromJSON(json: any): MetricResponse {
    return MetricResponseFromJSONTyped(json, false);
}

export function MetricResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetricResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimensionName': json['dimensionName'],
        'average': json['average'],
        'median': json['median'],
        'min': json['min'],
        'max': json['max'],
    };
}

export function MetricResponseToJSON(value?: MetricResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimensionName': value.dimensionName,
        'average': value.average,
        'median': value.median,
        'min': value.min,
        'max': value.max,
    };
}



import {
  DeleteIcon,
  DuplicateIcon,
  EditIcon,
  LinkIcon,
  OpenIcon,
  ListItemIcon,
  ListItemText,
  MenuProps,
  Tooltip,
  styled,
} from "@avenue-8/ui-2";

import { ResponsiveContextMenu } from "../ResponsiveContextMenu";

interface CMADropdownMenuProps {
  anchorEl?: MenuProps["anchorEl"];
  onCopyPresentationLink: () => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onEdit: () => void;
  onViewPresentation: () => void;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  disabledActions?: {
    viewPresentation?: string;
    copyPresentationLink?: string;
  };
}

const ItemWrapper = styled.span`
  display: flex;
`;

const WithTooltip = ({ children, title }: { children: any; title?: string }) => {
  return title ? (
    <Tooltip title={title} arrow>
      <ItemWrapper>{children}</ItemWrapper>
    </Tooltip>
  ) : (
    <>{children}</>
  );
};

export function CMAContextMenu({
  open,
  anchorEl,
  onCopyPresentationLink,
  onDelete,
  onDuplicate,
  onEdit,
  onViewPresentation,
  onClose,
  onOpen,
  disabledActions,
}: CMADropdownMenuProps) {
  return (
    <ResponsiveContextMenu open={open} anchorEl={anchorEl} onClose={onClose} onOpen={onOpen}>
      {({ ItemComponent }) => {
        return [
          <ItemComponent key="edit" onClick={onEdit}>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </ItemComponent>,
          <WithTooltip title={disabledActions?.viewPresentation} key="view">
            <ItemComponent
              onClick={onViewPresentation}
              disabled={!!disabledActions?.viewPresentation}
            >
              <ListItemIcon>
                <OpenIcon />
              </ListItemIcon>
              <ListItemText primary="View Presentation" />
            </ItemComponent>
          </WithTooltip>,
          <WithTooltip title={disabledActions?.copyPresentationLink} key="copy-link">
            <ItemComponent
              onClick={onCopyPresentationLink}
              disabled={!!disabledActions?.copyPresentationLink}
            >
              <ListItemIcon>
                <LinkIcon />
              </ListItemIcon>
              <ListItemText primary="Copy presentation Link" />
            </ItemComponent>
          </WithTooltip>,
          <ItemComponent key="duplicate" onClick={onDuplicate}>
            <ListItemIcon>
              <DuplicateIcon />
            </ListItemIcon>
            <ListItemText primary="Duplicate" />
          </ItemComponent>,
          <ItemComponent key="delete" onClick={onDelete}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText primary="Delete" />
          </ItemComponent>,
        ];
      }}
    </ResponsiveContextMenu>
  );
}

import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputLabel, Grid, styled } from "@avenue-8/ui-2";
import { isNullOrEmptyString } from "../../../../shared/utils/validations";
import { simpleEmail, usPhoneNumber } from "../../../../shared/utils/validation-patterns";
import { TextFieldBase } from "../../../../shared/components/TextFieldBase";

const FormItem = styled(Grid)`
  margin-bottom: 1rem;
`;

export interface PersonalDetailsFormModel {
  firstName?: string | null;
  lastName?: string | null;
  preferredContactEmail?: string | null;
  phoneNumber?: string | null;
}

const validateRequired = (v: any, label: string) =>
  isNullOrEmptyString(v) ? `Missing ${label}` : undefined;

export const PersonalDetailsForm = ({
  defaultValues,
  readOnly = false,
}: {
  defaultValues: PersonalDetailsFormModel;
  readOnly?: boolean;
}) => {
  const { control, errors } = useFormContext<PersonalDetailsFormModel>();

  return (
    <>
      <FormItem item xs={12}>
        <InputLabel htmlFor="firstName">First Name</InputLabel>
        <Controller
          name="firstName"
          control={control}
          defaultValue={defaultValues?.firstName ?? ""}
          rules={{
            validate: {
              required: (v) => validateRequired(v, "First Name"),
            },
          }}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter First Name"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
      <FormItem item xs={12}>
        <InputLabel htmlFor="lastName">Last Name</InputLabel>
        <Controller
          name="lastName"
          control={control}
          defaultValue={defaultValues?.lastName ?? ""}
          rules={{
            validate: {
              required: (v) => validateRequired(v, "Last Name"),
            },
          }}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter Last Name"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
      <FormItem item xs={12}>
        <InputLabel htmlFor="preferredContactEmail">Preferred Contact email (optional)</InputLabel>
        <Controller
          name="preferredContactEmail"
          control={control}
          defaultValue={defaultValues?.preferredContactEmail ?? ""}
          rules={{
            required: false,
            pattern: { value: simpleEmail, message: "Invalid Email Address" },
          }}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter Contact Email"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
      <FormItem item xs={12}>
        <InputLabel htmlFor="phoneNumber">Phone Number (optional)</InputLabel>
        <Controller
          name="phoneNumber"
          control={control}
          defaultValue={defaultValues?.phoneNumber ?? ""}
          rules={{
            required: false,
            pattern: { value: usPhoneNumber, message: "Invalid Phone Number" },
          }}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter Phone Number"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
    </>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AggregationFilters,
    AggregationFiltersFromJSON,
    AggregationFiltersFromJSONTyped,
    AggregationFiltersToJSON,
    MetricRequest,
    MetricRequestFromJSON,
    MetricRequestFromJSONTyped,
    MetricRequestToJSON,
} from './';

/**
 * 
 * @export
 * @interface SummaryStatisticsRequest
 */
export interface SummaryStatisticsRequest {
    /**
     * 
     * @type {AggregationFilters}
     * @memberof SummaryStatisticsRequest
     */
    filters?: AggregationFilters;
    /**
     * 
     * @type {Array<MetricRequest>}
     * @memberof SummaryStatisticsRequest
     */
    metrics: Array<MetricRequest>;
}

export function SummaryStatisticsRequestFromJSON(json: any): SummaryStatisticsRequest {
    return SummaryStatisticsRequestFromJSONTyped(json, false);
}

export function SummaryStatisticsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SummaryStatisticsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filters': !exists(json, 'filters') ? undefined : AggregationFiltersFromJSON(json['filters']),
        'metrics': ((json['metrics'] as Array<any>).map(MetricRequestFromJSON)),
    };
}

export function SummaryStatisticsRequestToJSON(value?: SummaryStatisticsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filters': AggregationFiltersToJSON(value.filters),
        'metrics': ((value.metrics as Array<any>).map(MetricRequestToJSON)),
    };
}



import { styledThemed as styled } from "../../cma-v2/components/PresentationView/theme";

export const IconContainer = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid ${(p) => p.theme.av8.primaryColor};
  margin: 1rem auto 0 auto;
`;
/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListingAgentDto
 */
export interface ListingAgentDto {
    /**
     * 
     * @type {string}
     * @memberof ListingAgentDto
     */
    name: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingAgentDto
     */
    dre: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingAgentDto
     */
    brokerage: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ListingAgentDto
     */
    lastSourceUpdateDate: Date | null;
}

export function ListingAgentDtoFromJSON(json: any): ListingAgentDto {
    return ListingAgentDtoFromJSONTyped(json, false);
}

export function ListingAgentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListingAgentDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'dre': json['dre'],
        'brokerage': json['brokerage'],
        'lastSourceUpdateDate': (json['lastSourceUpdateDate'] === null ? null : new Date(json['lastSourceUpdateDate'])),
    };
}

export function ListingAgentDtoToJSON(value?: ListingAgentDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'dre': value.dre,
        'brokerage': value.brokerage,
        'lastSourceUpdateDate': (value.lastSourceUpdateDate === null ? null : value.lastSourceUpdateDate.toISOString()),
    };
}



import { ToggleButton as MuiToggleButton } from "@mui/material";
import { styled } from "@avenue-8/ui-2";

//TODO: Move ToggleButton to av8-ui-2

// These are the actual colors from the designs, but it does not match with any of the colors in the theme.
// const primaryLightColor = {
//   main: "#8c8ea1",
//   light: "#eaebf6",
// };

export const ToggleButton = styled(MuiToggleButton)`
  padding: 0.5rem 2rem;
  border: none;
  border-radius: 0.25rem;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 100%;

  border: solid 1px ${(p) => p.theme.av8.borderColor};
  background-color: ${(p) => p.theme.av8.backgroundAlt};
  color: ${(p) => p.theme.av8.secondaryColor};

  &:hover {
    background-color: ${(p) => p.theme.av8.background};
  }

  &.Mui-selected {
    border: solid 1px ${(p) => p.theme.av8.secondaryColor};
    background-color: ${(p) => p.theme.av8.secondaryColor};
    color: #fff;

    &:hover {
      background-color: ${(p) => p.theme.av8.secondaryColor}dd;
      border-color: ${(p) => p.theme.av8.secondaryColor}dd;
    }
  }
`;

import * as React from "react";

export interface GoogleMapsApiProps {
  apiKey?: string;
  language?: string;
  onLoad?: () => void;
  libraries?: string[];
}

declare global {
  interface Window {
    scriptLoadMap: any;
  }
}

const markScriptFullyLoaded = (id: string, fullyLoaded: boolean) => {
  window.scriptLoadMap = window.scriptLoadMap || {};
  window.scriptLoadMap[id] = fullyLoaded;
};

const isScriptFullyLoaded = (id: string) => {
  return window.scriptLoadMap?.[id] === true;
};

export const useGoogleMapsApi = (props?: GoogleMapsApiProps) => {
  const {
    apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    language = "en",
    onLoad,
    libraries = ["places"],
  } = props || {};
  const id = "maps.googleapis.com";
  const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=${libraries.join(
    ","
  )}&language=${language}`;
  const fullyLoaded = isScriptFullyLoaded(id);
  const [isReady, setIsReady] = React.useState(fullyLoaded);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if (fullyLoaded) {
      setIsReady(true);
      setIsLoading(false);
    }
  }, [fullyLoaded]);

  React.useEffect(() => {
    if (document.getElementById(id) == null) {
      if (isReady) return;
      const googleMapsApiPromise: Promise<void> | null = new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.id = id;
        script.src = src;
        script.async = true;
        script.onload = () => {
          console.log("Google Maps API Loaded");
          resolve();
        };
        script.onerror = () => {
          reject();
        };
        document.body.appendChild(script);
      });

      googleMapsApiPromise
        .then(() => {
          markScriptFullyLoaded(id, true);
          setIsReady(true);
          setIsLoading(false);
          onLoad?.();
        })
        .catch((e) => {
          setIsLoading(false);
          markScriptFullyLoaded(id, false);
          document.getElementById("maps.googleapis.com")?.remove();
          console.error("Error to load Google Maps API", e);
        });
    }
  }, [src, onLoad, isReady]);

  return {
    isReady,
    isLoading,
  };
};

import {
  PropertyPhoto,
  SideBySideComparisonItem,
  SideBySideComparisonSectionView,
} from "src/modules/presentation/presentation-generation-logic";
import { ResponsiveImage } from "src/modules/shared/components/ResponsiveImage";
import {
  ComparisonItemTitle,
  RatioImage,
  SinglePropertyContentGroup,
  ColorfulText,
  ListingStatus,
} from "./SideBySideComparison.styles";
import { getPropertyTypeLabel } from "../../../../../shared/constants";
import { format } from "date-fns";
import { BreakdownPopover } from "../BreakdownPopover";
import { PrintOffInline } from "../PrintOff";

type DisplayPreferences = SideBySideComparisonSectionView["preferences"];

export interface SinglePropertyContentProps {
  highlight?: boolean;
  propertyData?: SideBySideComparisonItem;
  gallery: PropertyPhoto[];
  preferences?: DisplayPreferences;
  isSubjectProperty?: boolean;
  onClick?: any;
}

export const SinglePropertyContent = ({
  propertyData,
  gallery,
  onClick,
  preferences: p,
}: SinglePropertyContentProps) => {
  return (
    <>
      <RatioImage onClick={onClick} data-meta-action="open-photo-gallery">
        <ResponsiveImage alt={gallery?.[0]?.caption ?? ""} src={gallery?.[0]?.url} size="xs" />
      </RatioImage>
      <ComparisonItemTitle data-testid="address">
        {propertyData?.addressLine1}
        {propertyData?.addressLine2 ? ` Unit ${propertyData?.addressLine2}` : ""}
      </ComparisonItemTitle>
      {(p == null || p.showPropertyType === true) && (
        <p data-testid="property-type">
          <span className="comp-label">Property Type</span>
          <span>{getPropertyTypeLabel(propertyData?.propertyType) ?? "-"}</span>
        </p>
      )}
      <SinglePropertyContentGroup>
        {(p == null || p.showListPrice === true) && (
          <p data-testid="list-price">
            <span className="comp-label">List Price</span>
            <ColorfulText $color="success">
              {propertyData?.currentPrice
                ? `$${propertyData?.currentPrice.toLocaleString("en-US")}`
                : "-"}
            </ColorfulText>
          </p>
        )}
        {(p == null || p.showSoldPrice === true) && (
          <p data-testid="sold-price">
            <span className="comp-label">Sold Price</span>
            <ColorfulText $color="danger">
              {propertyData?.soldPrice
                ? `$${propertyData?.soldPrice.toLocaleString("en-US")}`
                : "-"}
            </ColorfulText>
          </p>
        )}
      </SinglePropertyContentGroup>
      <SinglePropertyContentGroup>
        {(p == null || p.showListDate === true) && (
          <p data-testid="list-date">
            <span className="comp-label">List Date</span>
            <ColorfulText $color="success">
              {propertyData?.listDate
                ? format(new Date(propertyData?.listDate), "MM/dd/yyyy")
                : "-"}
            </ColorfulText>
          </p>
        )}
        {(p == null || p.showSoldDate === true) && (
          <p data-testid="sold-date">
            <span className="comp-label">Sold Date</span>
            <ColorfulText $color="danger">
              {propertyData?.soldDate
                ? format(new Date(propertyData?.soldDate), "MM/dd/yyyy")
                : "-"}
            </ColorfulText>
          </p>
        )}
      </SinglePropertyContentGroup>
      {(p == null || p.showListingStatus === true) && (
        <p data-testid="listing-status">
          <span className="comp-label">Listing Status</span>
          <ListingStatus $listingStatus={propertyData?.listingStatus ?? ""}>
            {propertyData?.listingStatus ?? "-"}
          </ListingStatus>
        </p>
      )}
      {(p == null || p.showYearBuilt === true) && (
        <p data-testid="year-built">
          <span className="comp-label">Year Built</span>
          <span>{propertyData?.yearBuilt ?? "-"}</span>
        </p>
      )}
      {(p == null || p.showNeighborhood === true) && (
        <p data-testid="neighborhood">
          <span className="comp-label">Neighborhood</span>
          <span>{propertyData?.neighborhood ?? "-"}</span>
        </p>
      )}
      {(p == null || p.showLivingSpace === true) && (
        <p data-testid="living-space">
          <span className="comp-label">Living Space</span>
          <span>
            {propertyData?.livingSpace?.toLocaleString("en-US") ?? "-"}{" "}
            {propertyData?.livingSpace ? "sq. ft." : ""}
          </span>
        </p>
      )}
      <SinglePropertyContentGroup>
        {(p == null || p.showBeds === true) && (
          <p data-testid="beds">
            <span className="comp-label">Beds</span>
            <span>{propertyData?.beds ?? "-"}</span>
          </p>
        )}
        {(p == null || p.showBaths === true) && (
          <p data-testid="baths">
            <span className="comp-label">Baths</span>
            <span>{propertyData?.baths ? parseFloat(propertyData.baths.toFixed(2)) : "-"}</span>
          </p>
        )}
      </SinglePropertyContentGroup>
      {(p == null || p.showRoomsTotal === true) && (
        <p data-testid="total-rooms">
          <span className="comp-label">Total Rooms</span>
          <span>{propertyData?.roomsTotal ? propertyData.roomsTotal : "-"}</span>
        </p>
      )}
      {(p == null || p.showLotSize === true) && (
        <p data-testid="lot-size">
          <span className="comp-label">Lot Size</span>
          <span>
            {propertyData?.lotSize ?? "-"} {propertyData?.lotSize ? "sq. ft." : ""}
          </span>
        </p>
      )}
      {(p == null || p.showPricePerLivingSpaceSqft === true) && (
        <p data-testid="price-per-living-space">
          <span className="comp-label">Price / Living Space</span>
          <span>
            {propertyData?.costPerInternalSqFt
              ? `$${propertyData?.costPerInternalSqFt?.toLocaleString("en-US") ?? "-"} / Sq. Ft.`
              : "-"}
          </span>
        </p>
      )}
      {(p == null || p.showGarageParkingSpaces === true) && (
        <p data-testid="garage-parking-spaces">
          <span className="comp-label">Garage Parking Spaces</span>
          <span>{propertyData?.garageParkingSpaces ?? "-"}</span>
        </p>
      )}
      {(p == null || p.showRecurringCosts === true) && (
        <p data-testid="monthlies">
          <span className="comp-label">
            Monthlies{" "}
            <PrintOffInline>
              {Boolean(propertyData?.totalMonthlies) && (
                <BreakdownPopover fees={propertyData?.recurringCosts ?? []} size={12} />
              )}
            </PrintOffInline>
          </span>
          <span>
            {propertyData?.totalMonthlies
              ? `$${propertyData.totalMonthlies.toLocaleString("en-US")}`
              : "-"}
          </span>
        </p>
      )}
      {(p == null || p.showDoormanAttendance === true) && (
        <p data-testid="doorman">
          <span className="comp-label">Doorman / Attendance</span>
          <span>
            {typeof propertyData?.hasDoormanAttendance === "boolean"
              ? propertyData?.hasDoormanAttendance
                ? "Yes"
                : "No"
              : "-"}
          </span>
        </p>
      )}
      {(p == null || p.showFlipTaxRemarks === true) && (
        <p data-testid="flip-tax">
          <span className="comp-label">Flip Tax</span>
          <span>{propertyData?.flipTaxRemarks ?? "-"}</span>
        </p>
      )}
      {(p == null || p.showDaysOnMarket === true) && (
        <p data-testid="days-on-market">
          <span className="comp-label">Days on Market</span>
          <span>
            {propertyData?.daysOnMarket && propertyData.daysOnMarket > 0
              ? propertyData.daysOnMarket
              : "-"}
          </span>
        </p>
      )}
    </>
  );
};

import { Controller, useFormContext } from "react-hook-form";
import { InputLabel, TextFieldProps, FormHelperText, styled } from "@avenue-8/ui-2";
import { SuggestionsInput } from "../CreateCMA/SuggestionsInput/SuggestionsInput";
import { isNullOrEmptyString } from "src/modules/shared/utils/validations";
import { getTextFieldErrors } from "../TextFieldBase";
import { SuggestionOption } from "../CreateCMA/suggestions-form-model";

interface Props {
  fieldName?: string;
  fieldLabel?: string;
  fieldProps?: TextFieldProps;
  required?: boolean | string;
  showFixedErrorMessage?: boolean;
  onSuggestionChosen?: (chosenSuggestion: { [type: string]: string | null }) => void;
  optionLabelText?: keyof SuggestionOption;
  numberOfSuggestions?: number;
  deduplicateSuggestions?: boolean;
}

const ErrorMessage = styled(FormHelperText)`
  margin-top: 0.25rem;
  height: 0.5rem;
  color: ${(p) => p.theme.av8.dangerColor};
`;

export const NeighborhoodField = (props: Props) => {
  const {
    fieldName = "neighborhood",
    fieldLabel = "Neighborhood",
    fieldProps,
    required,
    showFixedErrorMessage,
    onSuggestionChosen,
    optionLabelText = "neighborhood",
    numberOfSuggestions,
    deduplicateSuggestions,
  } = props;
  const { errors, setValue } = useFormContext();
  const errorMessage = errors[fieldName]?.message;

  const validationRules = {
    validate: {
      required: (v: any) =>
        isNullOrEmptyString(v)
          ? typeof required === "string"
            ? required
            : "Missing Neighborhood"
          : undefined,
    },
  };

  const fieldErrors = !showFixedErrorMessage
    ? getTextFieldErrors({ errors, name: "neighborhood" })
    : {};

  return (
    <>
      <div>
        <InputLabel htmlFor={fieldName}>{fieldLabel}</InputLabel>
        <Controller
          name={fieldName}
          rules={required ? validationRules : undefined}
          render={({ ref, ...rest }) => (
            <SuggestionsInput
              textFieldProps={{
                ...rest,
                fullWidth: true,
                inputRef: ref,
                ...fieldErrors,
                ...fieldProps,
                onChange: (e) => {
                  fieldProps?.onChange?.(e);
                  rest.onChange(e);
                },
              }}
              onSuggestionChosen={(chosenSuggestion) => {
                setValue(fieldName, chosenSuggestion.neighborhood ?? "");
                onSuggestionChosen?.(chosenSuggestion);
              }}
              acceptedSuggestions={["neighborhood"]}
              optionLabelText={optionLabelText}
              numberOfSuggestions={numberOfSuggestions}
              deduplicateSuggestions={deduplicateSuggestions}
            />
          )}
        />
      </div>
      {showFixedErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat from "react-number-format";
import {
  styled,
  InputLabel,
  Grid,
  Datepicker,
  Textarea,
  Radio,
  RadioGroup,
  FormControlLabel,
  Fieldset,
  InputAdornment,
  Select,
  SelectOption,
} from "@avenue-8/ui-2";
import {
  alphanumericOnly,
  zipcodeOnly,
  yearOnly,
  latitudeOnly,
  longitudeOnly,
} from "../../../../../shared/utils/validation-patterns";
import { AddressInput } from "../../AddressInput/AddressInput";
import { PropertyDto } from "../../../../../shared/apis/cma/generated";
import { normalizeDecimalNumber } from "../../../../../shared/utils/converters";
import { isNullOrEmptyString } from "../../../../../shared/utils/validations";
import { TextFieldBase } from "../../../TextFieldBase";
import { PropertyTypeField } from "../../../Fields/PropertyTypeField";
import { BuildingStyleField } from "../../../Fields/BuildingStyleField";
import { MAX_INTEGER_VALUE, MAX_INTEGER_SMALL_VALUE } from "../../../../../shared/constants";
import { StateField } from "../../../Fields/StateField";
import { NeighborhoodField } from "../../../Fields/NeighborhoodField";
import { BreakdownPopover } from "../../../BreakdownPopover";
import {
  getPropertyFieldsToShow,
  getRecurringCostsData,
  normalizeNumber,
  SubjectPropertyStepModel,
} from "../../subject-property";
import { calculateMonthlies } from "../../SearchSubjectProperty/subject-property-helper";
import { ListingStatusField } from "../../../Fields/ListingStatusField";

const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;

const FormItem = styled(Grid)`
  margin-bottom: 1rem !important; //weird, but I had to add this after migrationg to MUI5
`;

const CustomRadio = styled(Radio)`
  padding: 0.75rem 0;
`;

export interface SubjectPropertyFormProps {
  subjectProperty?: PropertyDto;
  isFetchingCma: boolean;
  handleSave: (data: SubjectPropertyStepModel) => Promise<void>;
  handleOnFormsValuesUpdated: (data: any) => void;
  handleUpdateLocationFieldsFromSuggestion: (suggestion: Record<string, any>) => void;
  formId?: string;
}

export const SubjectPropertyForm = (props: SubjectPropertyFormProps) => {
  const {
    subjectProperty,
    isFetchingCma,
    handleSave,
    handleOnFormsValuesUpdated,
    handleUpdateLocationFieldsFromSuggestion,
    formId,
  } = props;
  const { handleSubmit, errors, setValue, getValues, watch } = useFormContext();

  const handleBlur = React.useCallback(() => {
    return handleOnFormsValuesUpdated(getValues());
  }, [handleOnFormsValuesUpdated, getValues]);

  const defaultOnBlur = React.useCallback(
    ({ controlledOnBlur }: { controlledOnBlur: () => void }) => {
      return {
        onBlur: () => {
          handleBlur();
          controlledOnBlur();
        },
      };
    },
    [handleBlur]
  );

  const { annualTax, monthlyHoa, state, propertyType } = watch([
    "annualTax",
    "monthlyHoa",
    "state",
    "propertyType",
  ]);

  // This is necessary since we have fixed fields to represent the fees
  const recurringCostsData = getRecurringCostsData({
    annualTax: normalizeNumber(annualTax)?.toString(),
    monthlyHoa: normalizeNumber(monthlyHoa)?.toString(),
    state,
    propertyType,
  });
  const recurringData = Object.values(recurringCostsData);
  const totalMonthlies = Math.round(calculateMonthlies(recurringData));
  const fieldsToShow = getPropertyFieldsToShow({ state });

  return (
    <Form onSubmit={handleSubmit(handleSave)} noValidate id={formId}>
      <Fieldset disabled={isFetchingCma}>
        <Controller
          name="cmaId"
          render={(p) => <input type="hidden" value={p.value} name={p.name} />}
        />
        <Grid container item spacing={3}>
          <FormItem item md={6} xs={12}>
            <InputLabel htmlFor="addressLine1">Address</InputLabel>
            <Controller
              name="addressLine1"
              rules={{
                validate: {
                  required: (v) => (isNullOrEmptyString(v) ? "Missing Address" : undefined),
                },
              }}
              render={({ ref, ...rest }) => {
                return (
                  <AddressInput
                    onAddressFound={handleUpdateLocationFieldsFromSuggestion}
                    textFieldProps={{
                      ...rest,
                      type: "text",
                      fullWidth: true,
                      error: !!errors.addressLine1,
                      helperText: errors.addressLine1?.message,
                      inputRef: ref,
                      placeholder: "Enter Address",
                      inputProps: { maxLength: 100 },
                    }}
                  />
                );
              }}
            />
          </FormItem>
          <FormItem item md={6} xs={12}>
            <InputLabel htmlFor="addressLine2">Address 2 / Unit No.</InputLabel>
            <Controller
              name="addressLine2"
              rules={{ required: false }}
              render={({ ref, ...rest }) => (
                <TextFieldBase
                  placeholder="Enter Address 2 / Unit No."
                  errors={errors}
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>

          <FormItem item md={6} xs={12}>
            <InputLabel htmlFor="city">City</InputLabel>
            <Controller
              name="city"
              rules={{
                validate: {
                  required: (v) => (isNullOrEmptyString(v) ? "Missing City" : undefined),
                },
              }}
              render={({ ref, ...rest }) => (
                <TextFieldBase
                  placeholder="Enter City"
                  errors={errors}
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <StateField
              selectProps={{
                onBlur: handleBlur,
              }}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="zip">Zip Code</InputLabel>
            <Controller
              name="zip"
              rules={{
                required: "Missing Zip Code",
                pattern: { value: zipcodeOnly, message: "Invalid zip code" },
              }}
              render={({ ref, ...rest }) => (
                <TextFieldBase
                  placeholder="Enter Zip Code"
                  errors={errors}
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>

          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="listingPrice">Listing price</InputLabel>
            <Controller
              name="listingPrice"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  prefix={"$"}
                  thousandSeparator={true}
                  placeholder="Enter Listing Price"
                  onValueChange={(v) => rest.onChange(v.value)}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="listDate">List date</InputLabel>
            <Controller
              name="listDate"
              render={({ ref, ...rest }) => (
                <Datepicker
                  id="listDate"
                  fullWidth
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="soldPrice">Sold price</InputLabel>
            <Controller
              name="soldPrice"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  prefix={"$"}
                  thousandSeparator={true}
                  placeholder="Enter Sold Price"
                  onValueChange={(v) => rest.onChange(v.value)}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="soldDate">Sold date</InputLabel>
            <Controller
              name="soldDate"
              render={({ ref, ...rest }) => (
                <Datepicker
                  id="soldDate"
                  fullWidth
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>

          <FormItem item md={6} xs={12}>
            <NeighborhoodField
              fieldProps={{
                onChange: (_) => {
                  handleOnFormsValuesUpdated(getValues());
                },
              }}
              required
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="livingSpace">Living Space</InputLabel>
            <Controller
              name="livingSpace"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  suffix={" sq. ft."}
                  thousandSeparator={true}
                  decimalScale={0}
                  placeholder="Enter Living Space"
                  onValueChange={(v) => rest.onChange(v.value)}
                  {...rest}
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="lotSize">Lot Size</InputLabel>
            <Controller
              name="lotSize"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  {...rest}
                  suffix={" sq. ft."}
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={0}
                  placeholder="Enter Lot Size"
                  onValueChange={(v) => rest.onChange(v.value)}
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>

          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="beds">Beds</InputLabel>
            <Controller
              name="beds"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  {...rest}
                  onValueChange={(v) => rest.onChange(v.value)}
                  decimalScale={0}
                  allowNegative={false}
                  placeholder="i.e 1, 2 or 0 for Studio"
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_SMALL_VALUE}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="baths">Baths</InputLabel>
            <Controller
              name="baths"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  allowNegative={false}
                  {...rest}
                  placeholder="Enter Baths"
                  onValueChange={(v) => rest.onChange(v.value)}
                  onBlur={(e) => {
                    setValue(
                      "baths",
                      e.target.value.length ? normalizeDecimalNumber(e.target.value, 0.25) : ""
                    );
                    defaultOnBlur({ controlledOnBlur: rest.onBlur }).onBlur();
                  }}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_SMALL_VALUE}
                  getInputRef={ref}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="garageSpaces">Garage Space(s)</InputLabel>
            <Controller
              name="garageSpaces"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  onValueChange={(v) => rest.onChange(v.value)}
                  customInput={TextFieldBase}
                  errors={errors}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  decimalScale={0}
                  allowNegative={false}
                  placeholder={"Enter Garage Space(s)"}
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_SMALL_VALUE}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="yearBuilt">Year Built</InputLabel>
            <Controller
              name="yearBuilt"
              rules={{
                pattern: { value: yearOnly, message: "Invalid year" },
              }}
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  decimalScale={0}
                  placeholder={"Enter Year"}
                  allowNegative={false}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= 9999}
                  getInputRef={ref}
                />
              )}
            />
          </FormItem>

          <FormItem item md={6} xs={12}>
            <PropertyTypeField.Select
              propertyType={subjectProperty?.propertyType}
              selectProps={{ onBlur: handleBlur }}
            />
          </FormItem>
          <FormItem item md={6} xs={12}>
            <PropertyTypeField.Other textFieldProps={{ onBlur: handleBlur }} />
          </FormItem>
          <FormItem item md={6} xs={12}>
            <BuildingStyleField.Autocomplete autocompleteProps={{ onBlur: handleBlur }} />
          </FormItem>
          <FormItem item md={6} xs={12}>
            <BuildingStyleField.Other textFieldProps={{ onBlur: handleBlur }} />
          </FormItem>

          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="floor">Entry Level{/*replaces floor*/}</InputLabel>
            <Controller
              name="floor"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  placeholder={"Enter Entry Level"}
                  customInput={TextFieldBase}
                  errors={errors}
                  decimalScale={0}
                  allowNegative={false}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                  getInputRef={ref}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="roomsTotal">Total Rooms</InputLabel>
            <Controller
              name="roomsTotal"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  {...rest}
                  onValueChange={(v) => rest.onChange(v.value)}
                  decimalScale={0}
                  allowNegative={false}
                  placeholder="Enter Total Rooms"
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_SMALL_VALUE}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="lease" id="lease-label">
              Lease
            </InputLabel>
            <Controller
              name="lease"
              render={({ ref, ...rest }) => (
                <Select
                  labelId="lease-label"
                  fullWidth
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                >
                  <SelectOption value="yes">Yes</SelectOption>
                  <SelectOption value="no">No</SelectOption>
                </Select>
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <ListingStatusField selectProps={{ onBlur: handleBlur, required: false }} />
          </FormItem>

          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="lat">Latitude</InputLabel>
            <Controller
              name="lat"
              rules={{
                required: "Missing Latitude",
                pattern: { value: latitudeOnly, message: "Invalid Latitude" },
              }}
              render={({ ref, ...rest }) => (
                <NumberFormat
                  onValueChange={(v) => rest.onChange(v.value)}
                  placeholder={"Enter Latitude"}
                  customInput={TextFieldBase}
                  errors={errors}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  getInputRef={ref}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="lng">Longitude</InputLabel>
            <Controller
              name="lng"
              rules={{
                required: "Missing Longitude",
                pattern: { value: longitudeOnly, message: "Invalid Longitude" },
              }}
              render={({ ref, ...rest }) => (
                <NumberFormat
                  placeholder={"Enter Longitude"}
                  customInput={TextFieldBase}
                  errors={errors}
                  onValueChange={(v) => rest.onChange(v.value)}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  getInputRef={ref}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="mlsId">MLS ID</InputLabel>
            <Controller
              name="mlsId"
              rules={{
                pattern: { value: alphanumericOnly, message: "Invalid MLS ID" },
              }}
              render={({ ref, ...rest }) => (
                <TextFieldBase
                  placeholder="Enter MLS ID"
                  errors={errors}
                  inputRef={ref}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                />
              )}
            />
          </FormItem>
          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="doorman">Doorman / Attendance</InputLabel>
            <Controller
              name="doorman"
              render={({ value, onChange, ...rest }) => (
                <RadioGroup
                  row
                  value={value}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  onChange={onChange}
                >
                  <FormControlLabel value="yes" label="Yes" control={<CustomRadio />} />
                  &nbsp;&nbsp;
                  <FormControlLabel value="no" label="No" control={<CustomRadio />} />
                </RadioGroup>
              )}
            />
          </FormItem>

          <FormItem item md={3} xs={12}>
            <InputLabel htmlFor="monthlyHoa">{recurringCostsData["hoa-fees"].label}</InputLabel>
            <Controller
              name="monthlyHoa"
              render={({ ref, ...rest }) => (
                <NumberFormat
                  customInput={TextFieldBase}
                  errors={errors}
                  prefix={"$"}
                  thousandSeparator={true}
                  placeholder={`Enter ${recurringCostsData["hoa-fees"].label}`}
                  onValueChange={(v) => rest.onChange(v.value)}
                  {...rest}
                  {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                  getInputRef={ref}
                  isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                />
              )}
            />
          </FormItem>
          {fieldsToShow.annualTax && (
            <FormItem item md={3} xs={12}>
              <InputLabel htmlFor="annualTax">Annual Tax</InputLabel>
              <Controller
                name="annualTax"
                defaultValue=""
                render={({ ref, ...rest }) => (
                  <NumberFormat
                    customInput={TextFieldBase}
                    errors={errors}
                    prefix={"$"}
                    thousandSeparator={true}
                    placeholder={`Enter Annual Tax`}
                    onValueChange={(v) => rest.onChange(v.value)}
                    {...rest}
                    {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                    getInputRef={ref}
                    isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
                  />
                )}
              />
            </FormItem>
          )}
          {fieldsToShow.monthlies && (
            <FormItem item md={3} xs={12}>
              <InputLabel htmlFor="monthlies">Monthlies</InputLabel>
              <NumberFormat
                name="monthlies"
                readOnly
                customInput={TextFieldBase}
                inputProps={{ readOnly: true }}
                prefix={"$"}
                thousandSeparator
                placeholder={`Enter ${recurringCostsData["tax"].label}`}
                value={totalMonthlies}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <BreakdownPopover fees={recurringData} />
                    </InputAdornment>
                  ),
                }}
              />
            </FormItem>
          )}
          {fieldsToShow.flipTaxRemarks && (
            <FormItem item md={3} xs={12}>
              <InputLabel htmlFor="flipTaxRemarks">Flip Tax</InputLabel>
              <Controller
                name="flipTaxRemarks"
                rules={{ required: false }}
                defaultValue=""
                render={({ ref, ...rest }) => (
                  <TextFieldBase
                    placeholder="Enter Flip Tax"
                    errors={errors}
                    {...rest}
                    {...defaultOnBlur({ controlledOnBlur: rest.onBlur })}
                    inputRef={ref}
                  />
                )}
              />
            </FormItem>
          )}

          <FormItem item xs={12}>
            <InputLabel htmlFor="description">Description</InputLabel>
            <Controller
              name="description"
              render={(props) => (
                <Textarea
                  minRows={5}
                  maxRows={15}
                  maxLength={5000}
                  {...props}
                  {...defaultOnBlur({ controlledOnBlur: props.onBlur })}
                />
              )}
            />
          </FormItem>
        </Grid>
      </Fieldset>
    </Form>
  );
};

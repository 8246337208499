import { useQuery } from "react-query";
import { getCMAPropertiesApiClient } from "../../../../shared/apis/cma/api-factories";
import { SuggestionsResponseDto } from "../../../../shared/apis/cma/generated";
import {
  convertSuggestionsQueryToSuggestionsRequest,
  SuggestionsQueryModel,
} from "../suggestions-form-model";
import { useDebounce } from "../../../hooks/useDebounceValue";

export function useSuggestionsQueries(state: SuggestionsQueryModel) {
  const debouncedSearchTerm: string = useDebounce<string>(state.text, 300);
  const debouncedState: SuggestionsQueryModel = {
    ...state,
    text: debouncedSearchTerm,
  };

  const suggestionsQuery = useQuery<SuggestionsResponseDto>(
    ["suggestions", debouncedState],
    async () => {
      return await getCMAPropertiesApiClient().propertyControllerListSuggestions(
        convertSuggestionsQueryToSuggestionsRequest(debouncedState)
      );
    },
    {
      enabled: debouncedState.text.length > 1,
      refetchOnWindowFocus: false,
    }
  );
  return { suggestionsQuery };
}

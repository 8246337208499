import { Button, styled, ListItem } from "@avenue-8/ui-2";

export const Sidebar = styled.nav`
  width: 100%;
  max-width: 304px;
  min-width: 304px;
  border: 1px solid ${(p) => p.theme.av8.borderColor};
  box-sizing: border-box;
  background-color: ${(p) => p.theme.av8.background};
`;
export const TemplateOption = styled(ListItem)<{ $selected?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(p) => (p.$selected ? p.theme.av8.backgroundAlt : "transparent")};
`;
export const TemplateOptionText = styled.div`
  flex: 1;
`;
export const SidebarHeader = styled.div`
  padding: 24px 16px;
  border-bottom: solid 1px ${(p) => p.theme.av8.borderColor};
`;
export const SidebarFooter = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;
export const SaveButton = styled(Button)`
  font-weight: bold;
`;

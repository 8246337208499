/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    avatarUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    agentId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserResponse
     */
    role?: string;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    lastLoginDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserResponse
     */
    completedOnboardingAt: Date;
    /**
     * 
     * @type {number}
     * @memberof UserResponse
     */
    createdById: number;
}

export function UserResponseFromJSON(json: any): UserResponse {
    return UserResponseFromJSONTyped(json, false);
}

export function UserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'avatarUrl': !exists(json, 'avatarUrl') ? undefined : json['avatarUrl'],
        'agentId': !exists(json, 'agentId') ? undefined : json['agentId'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'id': json['id'],
        'lastLoginDate': (new Date(json['lastLoginDate'])),
        'completedOnboardingAt': (new Date(json['completedOnboardingAt'])),
        'createdById': json['createdById'],
    };
}

export function UserResponseToJSON(value?: UserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'avatarUrl': value.avatarUrl,
        'agentId': value.agentId,
        'role': value.role,
        'id': value.id,
        'lastLoginDate': (value.lastLoginDate.toISOString()),
        'completedOnboardingAt': (value.completedOnboardingAt.toISOString()),
        'createdById': value.createdById,
    };
}



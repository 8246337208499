import { Dialog, DialogTitle, IconButton, Button, styled } from "@avenue-8/ui-2";

export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;
export const CustomDialog = styled(Dialog)`
  width: 100%;
  margin: 0 auto;
  .MuiPaper-root {
    max-width: 1024px;
  }
  .MuiDialogContent-root {
    padding: 2rem;
    padding-top: 0;
    padding-bottom: 1.5rem;
  }
  .MuiDialogTitle-root {
    padding: 2rem 2rem 1.5rem 2rem;
  }
  .MuiDialogActions-root {
    padding: 0;
    border-top: 1px solid ${(p) => p.theme.av8.input.borderColor};
  }
`;
export const CustomSelectButton = styled(Button)`
  max-width: 180px;
  align-self: flex-end;
`;
export const SelectButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 2rem;
`;
export const CustomTitle = styled(DialogTitle)`
  font-size: ${(p) => p.theme.av8.typography.headers.size};
  padding-bottom: 0 !important;
`;

import { PlusIcon, styled } from "@avenue-8/ui-2";
import { media } from "../../../shared/css-snippets";

const CreateCmaCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  background: ${(p) => p.theme.av8.background};
  color: ${(p) => (p.color ? p.color : p.theme.av8.primaryColor)};
  letter-spacing: 2px;
`;

const Message = styled.span`
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;

  font-size: ${(p) => p.theme.av8.typography.sizes.mobile.small};
  ${() => media.desktopUp} {
    font-size: ${(p) => p.theme.av8.typography.sizes.desktop.small};
  }
`;

export function CreateCmaCard(
  props: React.HTMLAttributes<HTMLDivElement> & { message?: string; color?: string }
) {
  const { message = "Create new", ...rest } = props;
  return (
    <CreateCmaCardContainer {...rest} data-testid="create-cma-card">
      <PlusIcon />
      <Message>{message}</Message>
    </CreateCmaCardContainer>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectComparablePropertyCoverImageRequestDto
 */
export interface SelectComparablePropertyCoverImageRequestDto {
    /**
     * 
     * @type {number}
     * @memberof SelectComparablePropertyCoverImageRequestDto
     */
    properyImageId: number;
}

export function SelectComparablePropertyCoverImageRequestDtoFromJSON(json: any): SelectComparablePropertyCoverImageRequestDto {
    return SelectComparablePropertyCoverImageRequestDtoFromJSONTyped(json, false);
}

export function SelectComparablePropertyCoverImageRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectComparablePropertyCoverImageRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'properyImageId': json['properyImageId'],
    };
}

export function SelectComparablePropertyCoverImageRequestDtoToJSON(value?: SelectComparablePropertyCoverImageRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'properyImageId': value.properyImageId,
    };
}



import { listingStatusColor } from "src/modules/shared/css-snippets";
import { styledThemed as styled } from "../../theme";
import { PrintOff } from "../PrintOff";

export const ComparisionRowPrint = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  @media print {
    page-break-after: always;
  }
`;
export const RatioImage = styled.div`
  display: "block";
  padding-bottom: 66%;
  position: relative;
  width: 100%;
  cursor: pointer;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const ComparisionPrintItem = styled.div<{ $isSubjectProperty?: boolean }>`
  line-height: 1.4rem;
  font-size: 0.7rem;
  box-sizing: content-box;
  padding: 1rem;
  background-color: ${(p) =>
    p.$isSubjectProperty ? p.theme.presentation.backgroundColorAlt : "inherit"};
  & > p > span,
  & > div > p > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    transition: opacity linear 0.15s;
    font-size: 0.8em;

    &.comp-label {
      overflow: initial;
      text-overflow: initial;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-transform: none;
      letter-spacing: 0.1em;
      transform: translateY(0.5em);
      color: ${(p) => p.theme.av8.primaryColor}aa;
      font-size: 0.8em;
      line-height: ${() => 1.5 / 0.8}em;
      text-transform: uppercase;
    }
  }
  & > p,
  & > div > p {
    flex: 1;
    margin: 0;
    margin-bottom: 0.25rem;
  }

  & > p:first-of-type {
    margin-top: 0.25rem;
  }
`;
export const SwiperContainer = styled.div`
  overflow: hidden;
  padding-left: 0px;
  padding-right: 0px;
  position: relative;

  & > .swiper-container {
    overflow: visible;
    padding-bottom: 60px;
  }

  & .swiper-pagination-bullet {
    margin: 0 30px !important;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${(p) => p.theme.av8.primaryColor};
  }
`;
export const FloatOverContainer = styled.div`
  position: absolute;
  z-index: 3;
  background-color: ${(p) => p.theme.presentation.backgroundColorAlt};
  box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
`;

export const PrintOffContainer = styled(PrintOff)`
  margin: auto;
  display: flex;
  justify-content: center;
`;
export const ComparisonItemTitle = styled.p`
  height: 3em;
  line-height: 1.6;
`;

export const SinglePropertyContentGroup = styled.div`
  display: flex;
  width: 100%;
  margin: 0;
`;

type ComparisonItemProps = {
  $highlight: boolean;
  $fixedLabels: boolean;
  $displayMode: "mobile" | "desktop" | "print" | "tablet";
};
export const ComparisionItem = styled.div<ComparisonItemProps>`
  padding: 10px;
  line-height: 1.5rem;
  max-width: ${(p) => (p.$displayMode === "mobile" ? "120px" : "200px")};
  min-width: ${(p) => (p.$displayMode === "mobile" ? "120px" : "200px")};
  font-size: ${(p) => (p.$displayMode === "mobile" ? "0.6rem" : "0.7rem")};
  background-color: ${(p) => (p.$highlight ? p.theme.presentation.backgroundColorAlt : "inherit")};
  box-sizing: content-box;
  & > p > span,
  & > div > p > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    transition: opacity linear 0.15s;
    flex: 1;

    &.comp-label {
      overflow: initial;
      text-overflow: initial;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-transform: none;
      letter-spacing: 0.1em;
      transform: translateY(0.5em);
      color: ${(p) => p.theme.av8.primaryColor}aa;
      opacity: ${(p) => (p.$fixedLabels || p.$highlight || p.$displayMode === "print" ? 1 : 0)};
      font-size: 0.85em;
      line-height: ${() => 1.5 / 0.8}em;
      text-transform: uppercase;
    }
  }
  & > div > p {
    flex: 1;
    margin: 0;
  }
  &:hover,
  .active {
    & > p > span,
    & > div > p > span {
      opacity: 1;
    }
  }
`;

export const NavigationButtonsContainer = styled.div`
  display: flex;
  position: absolute;
  top: 8rem;
  right: 1rem;
  z-index: 1;
`;

export const NavButton = styled.button`
  border: 1px solid ${(p) => p.theme.av8.input.borderColor};
  background-color: ${(p) => p.theme.av8.background};
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-of-type {
    margin-right: 1rem;
  }
`;
export const ColorfulText = styled.span<{ $color?: "success" | "warning" | "danger" }>`
  color: ${(p) => {
    switch (p.$color) {
      case "success":
        return p.theme.av8.successColor;
      case "danger":
        return p.theme.av8.dangerColor;
      case "warning":
        return p.theme.av8.warningColor;
      default:
        return p.theme.av8.secondaryColor;
    }
  }};
`;
export const ListingStatus = styled.span<{ $listingStatus: string | undefined }>`
  ${(p) => listingStatusColor(p.$listingStatus)}
`;

export interface DraftKey {
  formKey: string;
  entityKey?: string;
}

export interface DraftRecord<T = any> {
  value: T;
  storedWhen: number;
  expiry: number;
  updateId?: string; //version hash or timestamp
}

export type Drafts = {
  [key: string]: DraftRecord;
};

export interface DraftManagerStorage {
  getItem: (key: string) => string | null;
  setItem: (key: string, value: string) => void;
}

/**
 * Drafts, by default, will be stored for two days
 * and only most 20 recent drafts will be kept per form type.
 */
export class DraftManager<T = any> {
  private debug: boolean;

  constructor(private storage: DraftManagerStorage, { debug = false }: { debug?: boolean } = {}) {
    this.debug = debug;
  }

  private draftKey(formKey: string) {
    return `drafts_${formKey}`;
  }

  public storeDraft(
    { formKey, entityKey = "null" }: DraftKey,
    data: any,
    { ttl, updateId }: { ttl?: number; updateId?: string } = {}
  ) {
    if (this.debug) {
      console.log("store draft", JSON.stringify({ formKey, entityKey }));
    }
    const formDraftsStr = this.storage.getItem(this.draftKey(formKey)) || "{}";
    const formDrafts: Drafts = JSON.parse(formDraftsStr);
    const storedWhen = new Date().getTime();
    const expiry = storedWhen + (ttl ?? 48 * 60 * 60 * 1000);
    formDrafts[entityKey] = { value: data, expiry, storedWhen, updateId };
    this.storage.setItem(this.draftKey(formKey), JSON.stringify(formDrafts));
  }

  public loadDraft({ formKey, entityKey = "null" }: DraftKey): DraftRecord<T> | null {
    if (this.debug) {
      console.log("load draft", JSON.stringify({ formKey, entityKey }));
    }
    const formDraftsStr = this.storage.getItem(this.draftKey(formKey));
    if (!formDraftsStr) return null;
    let formDrafts: Drafts = JSON.parse(formDraftsStr);
    formDrafts = this.clearExpired(formKey, formDrafts);
    return formDrafts[entityKey] || null;
  }

  public loadSet(formKey: string): Drafts {
    const formDraftsStr = this.storage.getItem(this.draftKey(formKey));
    if (!formDraftsStr) return {};
    return JSON.parse(formDraftsStr);
  }

  public deleteDraft({ formKey, entityKey = "null" }: DraftKey): void {
    if (this.debug) {
      console.log("delete draft", JSON.stringify({ formKey, entityKey }));
    }
    const formDraftsStr = this.storage.getItem(this.draftKey(formKey));
    if (!formDraftsStr) return;
    const formDrafts: Drafts = JSON.parse(formDraftsStr);
    delete formDrafts[entityKey];
    this.storage.setItem(this.draftKey(formKey), JSON.stringify(formDrafts));
  }

  private clearExpired(formKey: string, drafts: Drafts): Drafts {
    if (this.debug) {
      console.log("clear expired", JSON.stringify({ formKey }));
    }
    const now = new Date().getTime();
    const clearedFormsDrafts: Drafts = {};
    Object.entries(drafts)
      .sort(([_keyA, valueA], [_keyB, valueB]) => {
        return valueB.expiry - valueA.expiry;
      })
      .slice(0, 20)
      .filter(([_key, record]) => {
        return record.expiry > now;
      })
      .forEach(([key, record]) => {
        return (clearedFormsDrafts[key] = record);
      });
    this.storage.setItem(this.draftKey(formKey), JSON.stringify(clearedFormsDrafts));
    return clearedFormsDrafts;
  }
}

export const draftManager = new DraftManager(localStorage);

import * as React from "react";
import { styledThemed as styled } from "../theme";
import { EyeHiddenIcon, useTheme } from "@avenue-8/ui-2";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  visibility?: boolean | null;
  children: React.ReactNode;
  sectionId?: string;
}

const Root = styled.div<{ $visibility?: boolean | null }>`
  background-color: ${(p) => {
    if (p.$visibility === false) return `${p.theme.av8.borderColor}`;
  }};
  opacity: ${(p) => {
    if (p.$visibility === false) return 0.25;
  }};
  filter: ${(p) => {
    if (p.$visibility === undefined) return "none";
    if (p.$visibility === true) return "none";
    else return "grayscale(100%)";
  }};
  position: relative;
  @media print {
    margin: 0 0.5cm;
  }
`;

const ShowHideContainer = styled.div<{ $hidden?: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  left: 20px;
`;

export const SectionWrapper = React.memo(({ visibility, sectionId, children, ...rest }: Props) => {
  const theme = useTheme();
  return (
    <Root {...rest} $visibility={visibility} id={`section_${sectionId}`}>
      {visibility === false && (
        <ShowHideContainer>
          <EyeHiddenIcon color={theme.av8.typography.secondaryColor} />
        </ShowHideContainer>
      )}
      {children}
    </Root>
  );
});

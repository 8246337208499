import React from "react";

interface Props {
  authorizedComponent: any;
  unauthorizedComponent?: any;
  role: string | Array<string>;
  entityRole?: string;
}

export const roleIsAllowed = (role: string | Array<string>, entityRole?: string) => {
  if (!entityRole) return false;
  if (typeof role === "string") {
    return role === entityRole;
  }
  return role.includes(entityRole);
};

export default function RoleAuthorized({
  role,
  authorizedComponent,
  unauthorizedComponent,
  entityRole,
}: Props) {
  return (
    <>{roleIsAllowed(role, entityRole) ? authorizedComponent : unauthorizedComponent || null}</>
  );
}

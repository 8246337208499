import * as React from "react";
import { styled, AlertIcon } from "@avenue-8/ui-2";

const Wrapper = styled.div`
  text-align: center;
  padding: 2rem 0;
`;

const IconWrapper = styled.div`
  border-radius: 50%;
  border: solid 1px ${(p) => p.theme.av8.secondaryColor};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: 300;
  color: ${(p) => p.theme.av8.secondaryColor};
  line-height: 1;
`;

const Title = styled.div`
  margin-top: 1rem;
  font-family: ${(p) => p.theme.av8.typography.headers.fontFamily};
  font-size: ${(p) => p.theme.av8.typography.headers.size};
  color: ${(p) => p.theme.av8.primaryColor};
`;

const Message = styled.div`
  margin-top: 1rem;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  font-size: 14px;
  color: ${(p) => p.theme.av8.secondaryColor};
`;

export const NoResultsFound = ({
  title = "No Results Found",
  message,
}: {
  title?: React.ReactNode;
  message: React.ReactNode;
}) => {
  return (
    <Wrapper>
      <IconWrapper>
        <AlertIcon density="dense" />
      </IconWrapper>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Wrapper>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PaginationMetaDto
 */
export interface PaginationMetaDto {
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    totalItems: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    currentPage: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    itemCount: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    itemsPerPage: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationMetaDto
     */
    totalPages: number;
}

export function PaginationMetaDtoFromJSON(json: any): PaginationMetaDto {
    return PaginationMetaDtoFromJSONTyped(json, false);
}

export function PaginationMetaDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginationMetaDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalItems': json['totalItems'],
        'currentPage': json['currentPage'],
        'itemCount': json['itemCount'],
        'itemsPerPage': json['itemsPerPage'],
        'totalPages': json['totalPages'],
    };
}

export function PaginationMetaDtoToJSON(value?: PaginationMetaDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalItems': value.totalItems,
        'currentPage': value.currentPage,
        'itemCount': value.itemCount,
        'itemsPerPage': value.itemsPerPage,
        'totalPages': value.totalPages,
    };
}



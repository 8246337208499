/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ListListingStatusDto,
    ListListingStatusDtoFromJSON,
    ListListingStatusDtoToJSON,
    ListPropertyResponseDto,
    ListPropertyResponseDtoFromJSON,
    ListPropertyResponseDtoToJSON,
    ListPropertyStyleDto,
    ListPropertyStyleDtoFromJSON,
    ListPropertyStyleDtoToJSON,
    ListPropertyTypeDto,
    ListPropertyTypeDtoFromJSON,
    ListPropertyTypeDtoToJSON,
    SearchV2Dto,
    SearchV2DtoFromJSON,
    SearchV2DtoToJSON,
    SuggestionsResponseDto,
    SuggestionsResponseDtoFromJSON,
    SuggestionsResponseDtoToJSON,
    SummaryStatisticsRequest,
    SummaryStatisticsRequestFromJSON,
    SummaryStatisticsRequestToJSON,
    SummaryStatisticsResponse,
    SummaryStatisticsResponseFromJSON,
    SummaryStatisticsResponseToJSON,
} from '../models';

export interface PropertyControllerListPropertyTypesRequest {
    neighborhoodFilter?: string;
    cityFilter?: string;
    stateFilter?: string;
}

export interface PropertyControllerListSuggestionsRequest {
    text: string;
    size?: number;
    address?: boolean;
    county?: boolean;
    neighborhood?: boolean;
    area?: boolean;
    city?: boolean;
    zipCode?: boolean;
    mlsId?: boolean;
}

export interface PropertyControllerSearchV2Request {
    searchV2Dto: SearchV2Dto;
}

export interface PropertyControllerSummaryStatisticsRequest {
    summaryStatisticsRequest: SummaryStatisticsRequest;
}

/**
 * 
 */
export class CMAPropertiesApi extends runtime.BaseAPI {

    /**
     */
    async propertyControllerListListingStatusRaw(): Promise<runtime.ApiResponse<ListListingStatusDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/listing-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListListingStatusDtoFromJSON(jsonValue));
    }

    /**
     */
    async propertyControllerListListingStatus(): Promise<ListListingStatusDto> {
        const response = await this.propertyControllerListListingStatusRaw();
        return await response.value();
    }

    /**
     */
    async propertyControllerListPropertyTypesRaw(requestParameters: PropertyControllerListPropertyTypesRequest): Promise<runtime.ApiResponse<ListPropertyTypeDto>> {
        const queryParameters: any = {};

        if (requestParameters.neighborhoodFilter !== undefined) {
            queryParameters['neighborhoodFilter'] = requestParameters.neighborhoodFilter;
        }

        if (requestParameters.cityFilter !== undefined) {
            queryParameters['cityFilter'] = requestParameters.cityFilter;
        }

        if (requestParameters.stateFilter !== undefined) {
            queryParameters['stateFilter'] = requestParameters.stateFilter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/property-type`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPropertyTypeDtoFromJSON(jsonValue));
    }

    /**
     */
    async propertyControllerListPropertyTypes(requestParameters: PropertyControllerListPropertyTypesRequest): Promise<ListPropertyTypeDto> {
        const response = await this.propertyControllerListPropertyTypesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async propertyControllerListStylesRaw(): Promise<runtime.ApiResponse<ListPropertyStyleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/styles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPropertyStyleDtoFromJSON(jsonValue));
    }

    /**
     */
    async propertyControllerListStyles(): Promise<ListPropertyStyleDto> {
        const response = await this.propertyControllerListStylesRaw();
        return await response.value();
    }

    /**
     * It is possible to control which kind of suggestion will be returned: - Address suggestions - County suggestions - Neighborhood suggestions - Area suggestions - City suggestions - Zip code suggestions - MlsId suggestions
     * Returns text suggestions to be used as a search-as-you-type on search bars.
     */
    async propertyControllerListSuggestionsRaw(requestParameters: PropertyControllerListSuggestionsRequest): Promise<runtime.ApiResponse<SuggestionsResponseDto>> {
        if (requestParameters.text === null || requestParameters.text === undefined) {
            throw new runtime.RequiredError('text','Required parameter requestParameters.text was null or undefined when calling propertyControllerListSuggestions.');
        }

        const queryParameters: any = {};

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.address !== undefined) {
            queryParameters['address'] = requestParameters.address;
        }

        if (requestParameters.county !== undefined) {
            queryParameters['county'] = requestParameters.county;
        }

        if (requestParameters.neighborhood !== undefined) {
            queryParameters['neighborhood'] = requestParameters.neighborhood;
        }

        if (requestParameters.area !== undefined) {
            queryParameters['area'] = requestParameters.area;
        }

        if (requestParameters.city !== undefined) {
            queryParameters['city'] = requestParameters.city;
        }

        if (requestParameters.zipCode !== undefined) {
            queryParameters['zipCode'] = requestParameters.zipCode;
        }

        if (requestParameters.mlsId !== undefined) {
            queryParameters['mlsId'] = requestParameters.mlsId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/suggestions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SuggestionsResponseDtoFromJSON(jsonValue));
    }

    /**
     * It is possible to control which kind of suggestion will be returned: - Address suggestions - County suggestions - Neighborhood suggestions - Area suggestions - City suggestions - Zip code suggestions - MlsId suggestions
     * Returns text suggestions to be used as a search-as-you-type on search bars.
     */
    async propertyControllerListSuggestions(requestParameters: PropertyControllerListSuggestionsRequest): Promise<SuggestionsResponseDto> {
        const response = await this.propertyControllerListSuggestionsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async propertyControllerSearchV2Raw(requestParameters: PropertyControllerSearchV2Request): Promise<runtime.ApiResponse<ListPropertyResponseDto>> {
        if (requestParameters.searchV2Dto === null || requestParameters.searchV2Dto === undefined) {
            throw new runtime.RequiredError('searchV2Dto','Required parameter requestParameters.searchV2Dto was null or undefined when calling propertyControllerSearchV2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/search-v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchV2DtoToJSON(requestParameters.searchV2Dto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPropertyResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async propertyControllerSearchV2(requestParameters: PropertyControllerSearchV2Request): Promise<ListPropertyResponseDto> {
        const response = await this.propertyControllerSearchV2Raw(requestParameters);
        return await response.value();
    }

    /**
     */
    async propertyControllerSummaryStatisticsRaw(requestParameters: PropertyControllerSummaryStatisticsRequest): Promise<runtime.ApiResponse<SummaryStatisticsResponse>> {
        if (requestParameters.summaryStatisticsRequest === null || requestParameters.summaryStatisticsRequest === undefined) {
            throw new runtime.RequiredError('summaryStatisticsRequest','Required parameter requestParameters.summaryStatisticsRequest was null or undefined when calling propertyControllerSummaryStatistics.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/properties/summary-statistics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SummaryStatisticsRequestToJSON(requestParameters.summaryStatisticsRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SummaryStatisticsResponseFromJSON(jsonValue));
    }

    /**
     */
    async propertyControllerSummaryStatistics(requestParameters: PropertyControllerSummaryStatisticsRequest): Promise<SummaryStatisticsResponse> {
        const response = await this.propertyControllerSummaryStatisticsRaw(requestParameters);
        return await response.value();
    }

}

import { Controller } from "react-hook-form";
import { Checkbox, CheckboxProps, styled } from "@avenue-8/ui-2";

interface Props {
  /**
   * label and value for the checkbox
   * @defaultValue [{label: "type A", value: "myid"}]
   **/
  options: { label: string; value: unknown }[];
  fieldName: string;
  fieldProps?: any;
  columns?: number;
  checkboxProps?: CheckboxProps;
}

const Container = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(p) => p.columns}, 1fr);
`;

const CheckboxField = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  padding: 0.5rem;
`;

export const CheckboxSetField = (props: Props) => {
  const {
    options = [{ label: "Other", value: "other" }],
    fieldName,
    fieldProps,
    columns = 1,
    checkboxProps,
  } = props;

  return (
    <Container columns={columns}>
      {options.map((option, i) => {
        const key = options.length > 1 ? `${fieldName}.${i}` : fieldName;
        return (
          <Controller
            key={key}
            name={key}
            defaultValue={false}
            render={(props) => (
              <CheckboxField>
                <Checkbox
                  {...checkboxProps}
                  {...fieldProps}
                  id={key}
                  onChange={(e) => {
                    checkboxProps?.onChange?.(e, e.target.checked);
                    props.onChange(e.target.checked ? option.value : false);
                  }}
                  checked={Boolean(props.value)}
                  name={props.name}
                  value={props.value}
                />
                <CheckboxLabel htmlFor={key}>{option.label}</CheckboxLabel>
              </CheckboxField>
            )}
          />
        );
      })}
    </Container>
  );
};

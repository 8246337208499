import { Button, ButtonProps, ChevronIcon } from "@avenue-8/ui-2";

const NextButton = (props: ButtonProps) => {
  return (
    <Button data-key="next-button" {...props}>
      {props.children}
      <ChevronIcon />
    </Button>
  );
};

export default NextButton;

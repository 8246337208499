import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@avenue-8/ui-2";

export type DialogAction = { primary?: boolean; label: string; onClick: () => void };

export const DynamicDialog = (props: {
  title?: string;
  message: string;
  actions: DialogAction[];
  open: boolean;
  onClose?: () => void;
  actionsAsList?: boolean;
}) => {
  const { title, message, actions, onClose, open, actionsAsList, ...rest } = props;

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose ? onClose : undefined} open={open} {...rest}>
      {Boolean(title) && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
      </DialogContent>
      {!actionsAsList && (
        <DialogActions>
          {actions.map((action) => {
            return (
              <Button
                onClick={() => action.onClick()}
                variant={action.primary ? undefined : "text"}
                key={action.label}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      )}
      {actionsAsList && (
        <List>
          {actions.map((action) => {
            return (
              <ListItem disablePadding key={action.label}>
                <ListItemButton onClick={() => action.onClick()} key={action.label}>
                  <Typography>{action.label}</Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </Dialog>
  );
};

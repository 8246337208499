import { PresentationViewBuilderContext } from "../../../presentation-view-builder";
import { InsightsSectionData } from "../insights-section-data";
import { InsightItem } from "../insights-section-view";
// import { SectionViewBuilder } from "../section-view-builder";
// import { buildMedianSalesPriceYearComparision } from "./build-median-sales-price-year-comparision";
// import { buildAverageLivingSpaceSqftPrice } from "./build-average-living-space-sqft-price";
// import { buildInventorySpeedComparison } from "./build-inventory-speed-comparison";
// import { InsightsSectionData } from "../../../models/";

// const roundToFixed = (num: number, decimalPlaces: number) => {
//   const div = Math.pow(10, decimalPlaces);
//   return Math.round(num * div) / div;
// };

export const INSIGHTS_DAYS = 90;

export type InsightViewBuilder = (context: {
  context: PresentationViewBuilderContext;
  data: InsightsSectionData | null;
}) => InsightItem | null;

// I'm not moving this one because it's not being used - maybe it will be used in the future
// const buildInsight2: InsightViewBuilder = ({ data }) => {
//   const { averageSalesPriceComparedToListing } = data || {};
//   if (!averageSalesPriceComparedToListing) {
//     return null;
//   }
//   const { area, change } = averageSalesPriceComparedToListing;
//   const changeF = roundToFixed(change, 2).toFixed(2);
//   let copy;
//   if (change > 5.0) {
//     copy = `The average sales price in ${area} is ${changeF}% over asking, indicating home owners may be pricing below market value.`;
//   } else if (change <= 5.0 && change >= -5.0) {
//     copy = `The average sales price in ${area} is close to list at ${changeF}%, indicating that home owners may be pricing at expected market value.`;
//   } else {
//     copy = `The average sales price in ${area} is ${changeF}% under asking, indicating home owners may be pricing above market value.`;
//   }
//   return {
//     content: copy,
//     footer: `Average over/under calculated over the past ${INSIGHTS_DAYS} day period.`,
//     mode: "ok",
//   };
// };

// const builders = {
//   buildMedianSalesPriceYearComparision,
//   buildInsight2,
//   buildAverageLivingSpaceSqftPrice,
//   buildInventorySpeedComparison,
// };

import { EventEmitter } from "../modules/shared/event-emitter";

export class AppEventEmitter extends EventEmitter<Event> { }

const allEvts = new Set<string>();
function regEvt<T extends string>(evt: T) {
  if (allEvts.has(evt)) {
    throw new Error(`Duplicated event ${evt}`);
  }
  allEvts.add(evt);
  return evt;
}

type PresentationType = "cma" | "general" | string; 

const CMAArchivedEvt = regEvt("cma-archived");
const CMAClientNameChangedEvt = regEvt("cma-client-display-name-changed");
const CMAClonedEvt = regEvt("cma-cloned");
const CMAComparablePropertiesDetailedListItemChangedEvt = regEvt("cma-comparable-properties-detailed-list-item-changed"); /*TODO*/
const CMAComparablePropertiesDetailedListOpenedEvt = regEvt("cma-comparable-properties-detailed-list-opened");
const CMAComparablePropertiesOrderChangedEvt = regEvt("cma-comparable-properties-order-changed");
const CMACompsTableOpenedEvt = regEvt("cma-comps-table-opened");
const CMACreatedEvt = regEvt("cma-created");
const CMADashboardFiltersChangedEvt = regEvt("cma-dashboard-filters-changed");
const CMADashboardLoadedEvt = regEvt("dashboard-loaded");
const CMADashboardRefreshedEvt = regEvt("cma-dashboard-refreshed");
const CMADashboardSortChangedEvt = regEvt("cma-dashboard-sort-changed");
const CMADeletedEvt = regEvt("cma-deleted");
const CMAEstimatedPriceSetEvt = regEvt("cma-estimated-price-set");
const CMAPropertyAddedEvt = regEvt("cma-comparable-property-added");
const CMAPropertyRemovedEvt = regEvt("cma-comparable-property-removed");
const CMAPublishedEvt = regEvt("cma-published");
const CMASearchFilterChanged = regEvt("cma-search-filter-changed");
const CMASearchFiltersClearedEvt = regEvt("cma-search-filters-cleared");
const CMASearchItemsLoadedEvt = regEvt("cma-search-items-loaded");
const CMASearchLoadedEvt = regEvt("cma-search-loaded");
const CMASearchPolygonAppliedEvt = regEvt("cma-search-polygon-applied");
const CMASearchRadiusAppliedEvt = regEvt("cma-search-radius-applied");
const CMASearchGeolocationButtonClickedEvt = regEvt("cma-search-geolocation-button-clicked");
const CMASearchCenterSubjectPropertyButtonClickedEvt = regEvt("cma-search-center-subject-property-button-clicked");
const CMASearchShapeClearedEvt = regEvt("cma-search-shape-cleared");
const CMASearchSortChangedEvt = regEvt("cma-search-sort-changed");
const CMASearchSummaryStatsChangedEvt = regEvt("cma-search-summary-stats-changed"); /*TODO*/
const CMASearchSummaryStatsOpenedEvt = regEvt("cma-search-summary-stats-opened"); /*TODO*/
const CMASetEstimatedPriceLoadedEvt = regEvt("cma-set-estimated-price-loaded");
const CMASubjectPropertyCoverImageSelectedEvt = regEvt("cma-subject-property-cover-image-selected");
const CMASubjectPropertyImageAddedEvt = regEvt("cma-subject-property-image-added");
const CMASubjectPropertyImageRemovedEvt = regEvt("cma-subject-property-image-removed"); 
const CMASubjectPropertyMLSDataImportedEvt = regEvt("cma-subject-property-mls-data-imported");
const CMASubjectPropertyOpenedEvt = regEvt("cma-subject-property-opened");
const CMASubjectPropertySavedEvt = regEvt("cma-subject-property-saved");
const CMATitleChangedEvt = regEvt("cma-title-changed");
const CMAUnarchivedEvt = regEvt("cma-unarchived");
const CMAManualComparablePropertyCreatedEvt = regEvt("cma-manual-comparable-property-created");
const CMAManualComparablePropertyUpdatedEvt = regEvt("cma-manual-comparable-property-updated");
const CMAManualComparablePropertyModalAddOpenedEvt = regEvt("cma-manual-comparable-property-modal-add-opened");
const CMAManualComparablePropertyModalEditOpenedEvt = regEvt("cma-manual-comparable-property-modal-edit-opened");
const LogoutEvt = regEvt("logout");
const LoginEvt = regEvt("login");
const MyAccountClickedEvt = regEvt("my-account-clicked");
const MyAccountUpdatedEvt = regEvt("my-account-updated");
const PresentationEditorDisplayModeChangedEvt = regEvt("presentation-editor-display-mode-changed");
const PresentationEditorLoadedEvt = regEvt("presentation-editor-loaded");
const PresentationLinkCopiedToClipboardEvt = regEvt("presentation-link-copied-to-clipboard");
const PresentationPreviewClickedEvt = regEvt("presentation-preview-clicked");
const PresentationSavedEvt = regEvt("presentation-saved");
const PresentationSectionEditedEvt = regEvt("presentation-section-edited");
const PresentationSectionMovedEvt = regEvt("presentation-section-moved");
const PresentationSectionAddedEvt = regEvt("presentation-section-added");
const PresentationSectionRemovedEvt = regEvt("presentation-section-removed");
const PresentationSectionViewDisabledEvt = regEvt("presentation-section-view-disabled");
const PresentationSectionViewEnabledEvt = regEvt("presentation-section-view-enabled");
const PresentationShareEmailSentEvt = regEvt("presentation-share-email-sent");
const PresentationShareModalOpenedEvt = regEvt("presentation-share-modal-opened");
const PresentationPrintButtonClickedEvt = regEvt("presentation-print-button-clicked");
const PresentationDownloadButtonClickedEvt = regEvt("presentation-download-button-clicked");
const PresentationViewLoadedEvt = regEvt("presentation-view-loaded");
const PresentationTemplateSelectedEvt = regEvt("presentation-template-selected");
const PresentationTemplateDeletedEvt = regEvt("presentation-template-deleted");
const PresentationNeighborhoodSearchFilterChangedEvt = regEvt("presentation-neighborhood-search-filter-changed");
const PresentationSectionsDataFetchedEvt = regEvt("presentation-sections-data-fetched");
const PresentationCreateModalOpenedEvt = regEvt("presentation-create-modal-opened");
const GeneralPresentationCoverImageAddedEvt = regEvt("general-presentation-cover-image-added")
const GeneralPresentationCoverImageRemovedEvt = regEvt("general-presentation-cover-image-removed")

const GuidedTourStartedEvt = regEvt("guided-tour-started");
const GuidedTourUpdatedEvt = regEvt("guided-tour-updated");
const GuidedTourFinishedEvt = regEvt("guided-tour-finished");
const GuidedTourSkippedEvt = regEvt("guided-tour-skipped");
const GuidedTourDontShowAgainEvt = regEvt("guided-tour-dont-show-again");


export interface CMAArchived { eventType: typeof CMAArchivedEvt; presentationType: PresentationType }
export interface CMAClientNameChanged { eventType: typeof CMAClientNameChangedEvt; presentationType: PresentationType }
export interface CMACloned { eventType: typeof CMAClonedEvt; presentationType: PresentationType }
export interface CMAComparablePropertiesDetailedListItemChanged { eventType: typeof CMAComparablePropertiesDetailedListItemChangedEvt; direction: "next" | "prior"; presentationType: PresentationType }
export interface CMAComparablePropertiesDetailedListOpened { eventType: typeof CMAComparablePropertiesDetailedListOpenedEvt; presentationType: PresentationType }
export interface CMAComparablePropertiesFilterChanged { eventType: typeof CMASearchFilterChanged; filter: string; value: any; presentationType: PresentationType }
export interface CMAComparablePropertiesOrderChanged { eventType: typeof CMAComparablePropertiesOrderChangedEvt; from: number; to: number; presentationType: PresentationType }
export interface CMACompsTableOpened { eventType: typeof CMACompsTableOpenedEvt; presentationType: PresentationType }
export interface CMACreated { eventType: typeof CMACreatedEvt; presentationType: PresentationType }
export interface CMADashboardFiltersChanged { eventType: typeof CMADashboardFiltersChangedEvt; filter: "status" | "agent"; }
export interface CMADashboardLoaded { eventType: typeof CMADashboardLoadedEvt; }
export interface CMADashboardRefreshed { eventType: typeof CMADashboardRefreshedEvt; }
export interface CMADashboardSortChanged { eventType: typeof CMADashboardSortChangedEvt; sortBy: string; }
export interface CMADeleted { eventType: typeof CMADeletedEvt; presentationType: PresentationType }
export interface CMAEstimatedPriceSet { eventType: typeof CMAEstimatedPriceSetEvt; from: "estimate-price-step" | "presentation-sidebar" }
export interface CMAPropertyAdded { eventType: typeof CMAPropertyAddedEvt; presentationType: PresentationType }
export interface CMAPropertyRemoved { eventType: typeof CMAPropertyRemovedEvt; presentationType: PresentationType }
export interface CMAPublished { eventType: typeof CMAPublishedEvt; presentationType: PresentationType }
export interface CMASearchFiltersCleared { eventType: typeof CMASearchFiltersClearedEvt; presentationType: PresentationType }
export interface CMASearchItemsLoaded { eventType: typeof CMASearchItemsLoadedEvt; presentationType: PresentationType }
export interface CMASearchLoaded { eventType: typeof CMASearchLoadedEvt; presentationType: PresentationType }
export interface CMASearchPolygonApplied { eventType: typeof CMASearchPolygonAppliedEvt; presentationType: PresentationType }
export interface CMASearchRadiusApplied { eventType: typeof CMASearchRadiusAppliedEvt; presentationType: PresentationType }
export interface CMASearchGeolocationButtonClicked { eventType: typeof CMASearchGeolocationButtonClickedEvt; presentationType: PresentationType }
export interface CMASearchCenterSubjectPropertyButtonClicked { eventType: typeof CMASearchCenterSubjectPropertyButtonClickedEvt; presentationType: PresentationType }
export interface CMASearchShapeCleared { eventType: typeof CMASearchShapeClearedEvt; presentationType: PresentationType }
export interface CMASearchSortChanged { eventType: typeof CMASearchSortChangedEvt; criteria: string; asc: boolean; presentationType: PresentationType }
export interface CMASearchSummaryStatsChanged { eventType: typeof CMASearchSummaryStatsChangedEvt; field: "measure" | "dimension"; presentationType: PresentationType }
export interface CMASearchSummaryStatsOpened { eventType: typeof CMASearchSummaryStatsOpenedEvt; presentationType: PresentationType }
export interface CMASetEstimatedPriceLoaded { eventType: typeof CMASetEstimatedPriceLoadedEvt; }
export interface CMASubjectPropertyCoverImageSelected { eventType: typeof CMASubjectPropertyCoverImageSelectedEvt; }
export interface CMASubjectPropertyImageAdded { eventType: typeof CMASubjectPropertyImageAddedEvt; }
export interface CMASubjectPropertyImageRemoved { eventType: typeof CMASubjectPropertyImageRemovedEvt; }
export interface CMASubjectPropertyMLSDataImported { eventType: typeof CMASubjectPropertyMLSDataImportedEvt; }
export interface CMASubjectPropertyOpened { eventType: typeof CMASubjectPropertyOpenedEvt; }
export interface CMASubjectPropertySaved { eventType: typeof CMASubjectPropertySavedEvt; }
export interface CMATitleChanged { eventType: typeof CMATitleChangedEvt; presentationType: PresentationType }
export interface CMAUnarchived { eventType: typeof CMAUnarchivedEvt; presentationType: PresentationType }
export interface Logout { eventType: typeof LogoutEvt; }
export interface Login { eventType: typeof LoginEvt; userId: string; email: string; }
export interface MyAccountClicked { eventType: typeof MyAccountClickedEvt; }
export interface MyAccountUpdated { eventType: typeof MyAccountUpdatedEvt; }
export interface PresentationEditorDisplayModeChanged { eventType: typeof PresentationEditorDisplayModeChangedEvt; mode: "mobile" | "desktop" | "print" | "responsive" | "tablet"; presentationType: PresentationType }
export interface PresentationEditorLoaded { eventType: typeof PresentationEditorLoadedEvt; presentationType: PresentationType }
export interface PresentationLinkCopiedToClipboard { eventType: typeof PresentationLinkCopiedToClipboardEvt; from: "presentation-editor" | "share-presentation-modal" | "dashboard"; presentationType: PresentationType }
export interface PresentationPreviewClicked { eventType: typeof PresentationPreviewClickedEvt; presentationType: PresentationType  }
export interface PresentationSaved { eventType: typeof PresentationSavedEvt; presentationType: PresentationType }
export interface PresentationSectionEdited { eventType: typeof PresentationSectionEditedEvt; sectionType: string; change: any; presentationType: PresentationType }
export interface PresentationSectionMoved { eventType: typeof PresentationSectionMovedEvt; sectionType: string; from: number; to: number; presentationType: PresentationType }
export interface PresentationSectionAdded { eventType: typeof PresentationSectionAddedEvt; sectionType: string; presentationType: PresentationType }
export interface PresentationSectionRemoved { eventType: typeof PresentationSectionRemovedEvt; sectionType: string; presentationType: PresentationType }
export interface PresentationSectionViewDisabled { eventType: typeof PresentationSectionViewDisabledEvt; sectionType: string; presentationType: PresentationType }
export interface PresentationSectionViewEnabled { eventType: typeof PresentationSectionViewEnabledEvt; sectionType: string; presentationType: PresentationType }
export interface PresentationShareEmailSent { eventType: typeof PresentationShareEmailSentEvt; presentationType: PresentationType }
export interface PresentationShareModalOpened { eventType: typeof PresentationShareModalOpenedEvt; presentationType: PresentationType }
export interface PresentationPrintButtonClicked { eventType: typeof PresentationPrintButtonClickedEvt; presentationType: PresentationType }
export interface PresentationDownloadButtonClicked { eventType: typeof PresentationDownloadButtonClickedEvt; presentationType: PresentationType }
export interface PresentationViewLoaded { eventType: typeof PresentationViewLoadedEvt; presentationType: PresentationType }
export interface PresentationTemplateSelected { eventType: typeof PresentationTemplateSelectedEvt; presentationType: PresentationType }
export interface PresentationTemplateDeleted { eventType: typeof PresentationTemplateDeletedEvt; presentationType: PresentationType }
export interface PresentationNeighborhoodSearchFilterChanged { eventType: typeof PresentationNeighborhoodSearchFilterChangedEvt; value: any; presentationType: PresentationType }
export interface CMAManualComparablePropertyCreated { eventType: typeof CMAManualComparablePropertyCreatedEvt; presentationType: PresentationType; }
export interface CMAManualComparablePropertyUpdated { eventType: typeof CMAManualComparablePropertyUpdatedEvt; presentationType: PresentationType; }
export interface CMAManualComparableModalAddOpened { eventType: typeof CMAManualComparablePropertyModalAddOpenedEvt; presentationType: PresentationType;}
export interface CMAManualComparableModalEditOpened { eventType: typeof CMAManualComparablePropertyModalEditOpenedEvt; presentationType: PresentationType;}
export interface PresentationSectionsDataFetched { eventType: typeof PresentationSectionsDataFetchedEvt; sections: any; presentationType: PresentationType }
export interface PresentationCreateModalOpened { eventType: typeof PresentationCreateModalOpenedEvt; }


export interface GeneralPresentationCoverImageAdded { eventType: typeof GeneralPresentationCoverImageAddedEvt; }
export interface GeneralPresentationCoverImageRemoved { eventType: typeof GeneralPresentationCoverImageRemovedEvt; }

export interface GuidedTourStarted { eventType: typeof GuidedTourStartedEvt; tourName: string; }
export interface GuidedTourUpdated { eventType: typeof GuidedTourUpdatedEvt; tourName: string; action: string; step: number; size: number; }
export interface GuidedTourSkipped { eventType: typeof GuidedTourSkippedEvt; tourName: string; step: number; size: number; }
export interface GuidedTourDontShowAgain { eventType: typeof GuidedTourDontShowAgainEvt; tourName: string; }
export interface GuidedTourFinished { eventType: typeof GuidedTourFinishedEvt; tourName: string; }



if (process.env.NODE_ENV === "development" && !process.env.REACT_APP_HIDE_ALL_APP_EVENTS) {
  console.log('ALL_EVENTS', allEvts);
} 

export type Event =
  | CMAArchived 
  | CMAClientNameChanged 
  | CMACloned 
  | CMAComparablePropertiesDetailedListItemChanged 
  | CMAComparablePropertiesDetailedListOpened 
  | CMAComparablePropertiesFilterChanged 
  | CMAComparablePropertiesOrderChanged 
  | CMACompsTableOpened 
  | CMACreated 
  | CMADashboardFiltersChanged 
  | CMADashboardLoaded 
  | CMADashboardRefreshed 
  | CMADashboardSortChanged 
  | CMADeleted 
  | CMAEstimatedPriceSet 
  | CMAPropertyAdded 
  | CMAPropertyRemoved 
  | CMAPublished 
  | CMASearchFiltersCleared 
  | CMASearchItemsLoaded 
  | CMASearchLoaded 
  | CMASearchPolygonApplied 
  | CMASearchRadiusApplied 
  | CMASearchGeolocationButtonClicked 
  | CMASearchCenterSubjectPropertyButtonClicked
  | CMASearchShapeCleared 
  | CMASearchSortChanged 
  | CMASearchSummaryStatsChanged 
  | CMASearchSummaryStatsOpened 
  | CMASetEstimatedPriceLoaded 
  | CMASubjectPropertyCoverImageSelected 
  | CMASubjectPropertyImageAdded 
  | CMASubjectPropertyImageRemoved 
  | CMASubjectPropertyMLSDataImported 
  | CMASubjectPropertyOpened 
  | CMASubjectPropertySaved 
  | CMATitleChanged 
  | CMAUnarchived 
  | Logout 
  | Login 
  | MyAccountClicked 
  | MyAccountUpdated 
  | PresentationEditorDisplayModeChanged 
  | PresentationEditorLoaded 
  | PresentationLinkCopiedToClipboard 
  | PresentationPreviewClicked 
  | PresentationSaved 
  | PresentationSectionEdited 
  | PresentationSectionMoved
  | PresentationSectionAdded
  | PresentationSectionRemoved 
  | PresentationSectionViewDisabled 
  | PresentationSectionViewEnabled 
  | PresentationShareEmailSent 
  | PresentationShareModalOpened
  | PresentationPrintButtonClicked 
  | PresentationDownloadButtonClicked
  | PresentationViewLoaded 
  | PresentationTemplateSelected
  | PresentationTemplateDeleted
  | PresentationNeighborhoodSearchFilterChanged
  | CMAManualComparablePropertyCreated
  | CMAManualComparablePropertyUpdated
  | CMAManualComparableModalAddOpened
  | CMAManualComparableModalEditOpened
  | PresentationSectionsDataFetched
  | PresentationCreateModalOpened
  | GeneralPresentationCoverImageAdded
  | GeneralPresentationCoverImageRemoved
  | GuidedTourStarted
  | GuidedTourUpdated
  | GuidedTourSkipped
  | GuidedTourDontShowAgain
  | GuidedTourFinished

const singletonEmitter = new AppEventEmitter() as AppEventEmitter;

(window as any).appEventEmitter = singletonEmitter;

export const appEventEmitter = singletonEmitter;
/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserItem,
    UserItemFromJSON,
    UserItemFromJSONTyped,
    UserItemToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListUsersResponse
 */
export interface ListUsersResponse {
    /**
     * 
     * @type {Array<UserItem>}
     * @memberof ListUsersResponse
     */
    items: Array<UserItem>;
    /**
     * 
     * @type {object}
     * @memberof ListUsersResponse
     */
    meta: object;
}

export function ListUsersResponseFromJSON(json: any): ListUsersResponse {
    return ListUsersResponseFromJSONTyped(json, false);
}

export function ListUsersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListUsersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(UserItemFromJSON)),
        'meta': json['meta'],
    };
}

export function ListUsersResponseToJSON(value?: ListUsersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(UserItemToJSON)),
        'meta': value.meta,
    };
}



import * as React from "react";
import { useIsMutating } from "react-query";

export const useListenPresentationSync = (sync?: () => void) => {
  const addRemoveCompMutationCount = useIsMutating(["add-remove-comp"]);
  const pendingMutations = [addRemoveCompMutationCount].reduce((prev, sum) => prev + sum, 0);
  const mutations = React.useRef<number>(pendingMutations);

  React.useEffect(() => {
    if (mutations.current !== pendingMutations) {
      mutations.current = pendingMutations;
      if (mutations.current === 0) {
        sync?.();
      }
    }
  }, [pendingMutations, sync]);

  return {
    pendingMutations,
  };
};

import {
  DebugHeapTracker,
  DefaultHeapTracker,
  HeapCombinedTracker,
  HeapTracker,
  SafeHeapTracker,
} from "./heap-trackers";

export function heapFactory(
  heapMode: string | "DEBUG" | "COMBINED" | "DEFAULT" | string | undefined
): HeapTracker {
  function getInnerInstance() {
    if (heapMode === "DEBUG") {
      return new DebugHeapTracker();
    }
    if (heapMode === "COMBINED") {
      return new HeapCombinedTracker([new DebugHeapTracker(), new DefaultHeapTracker()]);
    }
    if (heapMode === "DEFAULT") {
      return new DefaultHeapTracker();
    } else {
      console.warn("Heap is not configured.");
      return new DebugHeapTracker();
    }
  }

  const innerInstance = getInnerInstance();

  return new SafeHeapTracker(innerInstance);
}

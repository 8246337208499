import * as React from "react";
import { Subtitle1 } from "../typography";

export const DaysOnMarket = (props: { daysOnMarket: number; showPriceRange?: boolean }) => {
  const { daysOnMarket, showPriceRange } = props;
  return (
    <Subtitle1 marginTop={showPriceRange === true ? 1 : 2} data-testid="days-on-market">
      {daysOnMarket} days on the market
    </Subtitle1>
  );
};

import { InsightsSectionConfig } from "src/modules/presentation/presentation-generation-logic";
import { InsightsEditorFormModel } from "./";

export const convertInsightsEditorFormModelToPartialConfig = (
  data: InsightsEditorFormModel
): Partial<InsightsSectionConfig> => {
  return {
    neighborhood: data.neighborhood,
    propertyType: data.propertyType,
    city: data.city,
    state: data.state,
    isLease: data.listingType === "lease",
  };
};

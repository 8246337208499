import { cmaRoutes } from "../../cma.routes";
import * as React from "react";
import { InfiniteData, useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useAppContext } from "../../../../AppContext";
import { toast } from "@avenue-8/ui-2";
import { appEventEmitter } from "../../../../events/app-event-emitter";
import { getCMAApiClient } from "../../../shared/apis/cma/api-factories";
import { ListCMAResponseDto, ListCMAResponseItemDto } from "../../../shared/apis/cma/generated";

export const useCloneCMA = ({
  latestOrderBy,
  getLoadedCMAItem,
}: {
  latestOrderBy: React.MutableRefObject<string>;
  getLoadedCMAItem: (cmaId: string) => ListCMAResponseItemDto | undefined;
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const {
    actions: { confirm, watchPromise },
  } = useAppContext();

  const replaceClonePlaceholder = async (
    cmaId: string,
    buildReplacement: (placeholder: ListCMAResponseItemDto) => ListCMAResponseItemDto | null
  ) => {
    queryClient.cancelQueries("cmas");
    queryClient.setQueryData<InfiniteData<ListCMAResponseDto> | null>("cmas", (data) => {
      const tempCloneId = `tempclone-${cmaId}`;
      if (data == null) return null;
      return {
        ...data,
        pages: data.pages.map((page) => {
          const itemIndex = page.items.findIndex((item) => item.id === tempCloneId);
          if (itemIndex !== -1) {
            return {
              ...page,
              items: page.items
                .map((item) => {
                  if (item.id !== tempCloneId) return item;
                  return buildReplacement(item);
                })
                .filter((item) => item != null) as ListCMAResponseItemDto[],
            };
          }
          return page;
        }),
      };
    });
  };

  const { mutate: cloneCMAMutate } = useMutation(
    async ({ cmaId }: { cmaId: string }) => {
      return watchPromise(getCMAApiClient().cMAControllerClone({ id: cmaId }));
    },
    {
      mutationKey: "clone-cma",
      onMutate: ({ cmaId }) => {
        queryClient.cancelQueries(["cmas"]);
        queryClient.setQueryData<InfiniteData<ListCMAResponseDto> | null>("cmas", (data) => {
          if (data == null) return null;

          const orderBy = latestOrderBy.current;

          let cmaPosition: { pageIndex: number; itemIndex: number } = {
            pageIndex: -1,
            itemIndex: -1,
          };
          const pagesClone = data.pages.map((page, pageIndex) => {
            const itemIndex = page.items.findIndex((item) => item.id === cmaId);
            if (itemIndex !== -1) cmaPosition = { pageIndex, itemIndex };
            return page;
          });

          if (cmaPosition.pageIndex === -1) return data;
          const cma = data.pages[cmaPosition.pageIndex].items[cmaPosition.itemIndex];
          const cmaClone = {
            ...cma,
            id: `tempclone-${cmaId}`,
            updatedDate: new Date(),
          };

          if (orderBy === "created-desc" || orderBy === "updated-desc") {
            pagesClone[0].items = [cmaClone, ...pagesClone[0].items];
          } else {
            console.warn("Unhandled optimistic update using orderBy:", orderBy);
            //fallback ordering
            pagesClone[0].items.splice(cmaPosition.itemIndex, 0, cmaClone);
          }

          const optimisticUpdatedData: InfiniteData<ListCMAResponseDto> = {
            ...data,
            pages: pagesClone,
          };
          return optimisticUpdatedData;
        });
      },
      onSuccess: ({ newlyCreatedCMAId }, { cmaId }) => {
        appEventEmitter.emit({ eventType: "cma-cloned", presentationType: getLoadedCMAItem(cmaId)!.presentationType });
        replaceClonePlaceholder(cmaId, (placeholder) => {
          return {
            ...placeholder,
            id: newlyCreatedCMAId,
          };
        });
        toast.success("Presentation successfully duplicated.", {
          action: {
            label: "Open",
            onClick: () => {
              const duplicatedCma = getLoadedCMAItem(cmaId);
              if (!duplicatedCma) {
                history.push(cmaRoutes.dashboard.route);
                return;
              }
              const presentationType = duplicatedCma.presentationType;
              if (presentationType === "cma") {
                history.push(cmaRoutes.edit.generate({ id: newlyCreatedCMAId }));
              } else if (presentationType === "general") {
                history.push(cmaRoutes.editGeneralPresentation.generate({ id: newlyCreatedCMAId }));
              }
            },
          },
        });
      },
      onError: (e, { cmaId }) => {
        toast.error("Failed to duplicate.");
        replaceClonePlaceholder(cmaId, () => null);
        throw e;
      },
    }
  );

  const cloneCMA = async (cmaId: string) => {
    const ongoingClone = Boolean(
      queryClient.getMutationCache().find({
        mutationKey: "clone-cma",
        predicate: (a) => {
          return a.options.variables.cmaId === cmaId && a.state.status === "loading";
        },
      })
    );
    if (ongoingClone) return;

    const { hasUnpublishedChanges } = getLoadedCMAItem(cmaId) || {};
    const confirmed = await confirm({
      title: "Duplicate?",
      message: `${
        hasUnpublishedChanges ? "Duplicating a CMA will copy all unpublished changes. " : ""
      }Are you sure you want to duplicate this CMA?`,
      confirmButtonText: "Duplicate",
      cancelButtonText: "Cancel",
    });
    if (confirmed) cloneCMAMutate({ cmaId });
  };

  return cloneCMA;
};

import { cmaRoutes } from "src/modules/cma-v2/cma.routes";
import { RouterProps } from "react-router";

type NavigateAction = "isForward" | "isBackwards" | "leaveFlow";

type StepKeys = "subject-property" | "search" | "estimate" | "customize-presentation";

export class CMARouteStepHelper {
  private orderedSteps: StepKeys[];
  private orderedStepLabels: string[];
  private router: typeof cmaRoutes.editStep | typeof cmaRoutes.editGeneralPresentationStep | null;
  constructor(presentationType: "cma" | "general" | string | undefined) {
    this.orderedSteps = [];
    this.orderedStepLabels = [];
    this.router = null;

    if (presentationType === "general") {
      this.orderedSteps = ["search", "customize-presentation"];
      this.orderedStepLabels = ["Search", "Customize Presentation"];
      this.router = cmaRoutes.editGeneralPresentationStep;
    }
    if (presentationType === "cma") {
      this.orderedSteps = ["subject-property", "search", "estimate", "customize-presentation"];
      this.orderedStepLabels = ["Subject Property", "Search", "Estimate", "Customize Presentation"];
      this.router = cmaRoutes.editStep;
    }
  }

  private getStepDataByPath(path: string) {
    const match = this.router?.match(path);
    if (match == null) return null;
    const key = match.params.step as StepKeys;
    const index = this.orderedSteps.indexOf(key);

    return {
      index,
      key,
      label: this.orderedStepLabels[index],
      path: this.router?.generate(match.params as any),
      params: match.params,
    };
  }

  private getStepDataByIndex(index: number) {
    const key = this.orderedSteps[index];
    if (key == null) return null;
    const match = this.router?.match(window.location.pathname);
    if (match == null) return null;
    const params = { ...match.params, step: key };
    return {
      index,
      key,
      label: this.orderedStepLabels[index],
      path: this.router?.generate(params as any),
      params,
    };
  }

  public getCurrentStep() {
    return this.getStepDataByPath(window.location.pathname);
  }

  public getNextStep() {
    const step = this.getStepDataByPath(window.location.pathname);
    if (step == null || step.index === -1 || step.index === this.orderedSteps.length - 1)
      return null;
    return this.getStepDataByIndex(step.index + 1);
  }

  public getPreviousStep() {
    const step = this.getCurrentStep();
    if (step == null || step.index === -1 || step.index === 0) return null;
    return this.getStepDataByIndex(step.index - 1);
  }

  private getPathStepOffset(path: string) {
    const currentStep = this.getCurrentStep();
    const targetStep = this.getStepDataByPath(path);
    if (currentStep == null || targetStep == null || currentStep.params.id !== targetStep.params.id)
      return null;
    return targetStep.index - currentStep.index;
  }

  public getPathDirection(path: string) {
    const offset = this.getPathStepOffset(path);
    if (offset == null) return null;
    return offset > 0 ? "isForward" : offset < 0 ? "isBackwards" : null;
  }

  handleBackClick = async (history: RouterProps["history"]) => {
    const previousPath = this.getPreviousStep()?.path;
    history.push(previousPath || cmaRoutes.dashboard.route);
  };

  handleForwardClick = async (history: RouterProps["history"]) => {
    const nextPath = this.getNextStep()?.path;
    if (nextPath) history.push(nextPath);
  };
}

export const useCMARouteStepHelper = (presentationType: "cma" | "general" | string | undefined) => {
  const cmaRouteStepHelper = new CMARouteStepHelper(presentationType);
  return cmaRouteStepHelper;
};

interface NavigationContext {
  action: NavigateAction;
  hasChanges: boolean;
  validForm: boolean;
  save: () => Promise<void>;
}

type NavigationResult = "cancel" | "navigate" | "prompt";

export async function handleSavableStepNavigation(
  context: NavigationContext
): Promise<NavigationResult> {
  if (context.action === "isBackwards") {
    return await handleBackwardsNavigation(context);
  } else if (context.action === "isForward") {
    return await handleForwardNavigation(context);
  } else if (context.action === "leaveFlow") {
    return await handleLeaveFlowNavigation(context);
  } else {
    throw new Error("Unknown action");
  }
}

async function handleBackwardsNavigation({
  validForm,
  hasChanges,
  save,
}: NavigationContext): Promise<NavigationResult> {
  if (hasChanges) {
    if (validForm) {
      save(); //don't wait
      return "navigate";
    } else {
      return "cancel";
    }
  } else {
    return "navigate";
  }
}

async function handleForwardNavigation({
  validForm,
  hasChanges,
  save,
}: NavigationContext): Promise<NavigationResult> {
  if (!validForm) return "cancel";
  else {
    if (hasChanges) {
      await save();
    }
    return "navigate";
  }
}

async function handleLeaveFlowNavigation({
  hasChanges,
}: NavigationContext): Promise<NavigationResult> {
  if (hasChanges) {
    return "prompt";
  }
  return "navigate";
}

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompsPreferencesDto
 */
export interface CompsPreferencesDto {
    /**
     * 
     * @type {number}
     * @memberof CompsPreferencesDto
     */
    priceMin: number;
    /**
     * 
     * @type {number}
     * @memberof CompsPreferencesDto
     */
    priceMax: number;
    /**
     * 
     * @type {string}
     * @memberof CompsPreferencesDto
     */
    bedsMin: string;
    /**
     * 
     * @type {string}
     * @memberof CompsPreferencesDto
     */
    bedsMax: string;
    /**
     * 
     * @type {string}
     * @memberof CompsPreferencesDto
     */
    bathsMin: string;
    /**
     * 
     * @type {string}
     * @memberof CompsPreferencesDto
     */
    bathsMax: string;
    /**
     * 
     * @type {number}
     * @memberof CompsPreferencesDto
     */
    livingSpaceMin: number;
    /**
     * 
     * @type {number}
     * @memberof CompsPreferencesDto
     */
    livingSpaceMax: number;
    /**
     * 
     * @type {string}
     * @memberof CompsPreferencesDto
     */
    extraInformation?: string;
}

export function CompsPreferencesDtoFromJSON(json: any): CompsPreferencesDto {
    return CompsPreferencesDtoFromJSONTyped(json, false);
}

export function CompsPreferencesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompsPreferencesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'priceMin': json['priceMin'],
        'priceMax': json['priceMax'],
        'bedsMin': json['bedsMin'],
        'bedsMax': json['bedsMax'],
        'bathsMin': json['bathsMin'],
        'bathsMax': json['bathsMax'],
        'livingSpaceMin': json['livingSpaceMin'],
        'livingSpaceMax': json['livingSpaceMax'],
        'extraInformation': !exists(json, 'extraInformation') ? undefined : json['extraInformation'],
    };
}

export function CompsPreferencesDtoToJSON(value?: CompsPreferencesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'priceMin': value.priceMin,
        'priceMax': value.priceMax,
        'bedsMin': value.bedsMin,
        'bedsMax': value.bedsMax,
        'bathsMin': value.bathsMin,
        'bathsMax': value.bathsMax,
        'livingSpaceMin': value.livingSpaceMin,
        'livingSpaceMax': value.livingSpaceMax,
        'extraInformation': value.extraInformation,
    };
}



import * as React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Typography, Loader, styled } from "@avenue-8/ui-2";
import { useAgentOnboardingLogic } from "../../../hooks/useAgentOnboardingLogic";
import { AgentOnboardingStepBaseProps } from "../../AgentOnboarding";
import { AgentDetailsForm, AgentDetailsFormModel } from "../AgentDetailsStep/AgentDetailsForm";
import {
  PersonalDetailsForm,
  PersonalDetailsFormModel,
} from "../PersonalDetailsStep/PersonalDetailsForm";
import { AgreementForm, AgreementFormModel } from "./AgreementForm";
import { UploadHeadshotForm } from "./UploadHeadshotForm";
import { useAuthContext } from "../../../../shared/contexts/AuthContext/AuthContext";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 2.5rem;
`;

const LoaderContainer = styled(Grid)`
  min-height: 300px;
`;

interface ReviewStepProps extends AgentOnboardingStepBaseProps {
  data?: ReviewFormModel;
  onSave: (data: ReviewFormModel) => Promise<any>;
}

export interface ReviewFormModel
  extends PersonalDetailsFormModel,
    AgentDetailsFormModel,
    AgreementFormModel {}

export const ReviewStep = ({ formId, onSave }: ReviewStepProps) => {
  const { state, actions } = useAgentOnboardingLogic();
  const {
    state: { userInfo },
  } = useAuthContext();
  const formMethods = useForm<ReviewFormModel>({
    defaultValues: { ...state.agentDetailsForm },
    mode: "onBlur",
  });
  const { handleSubmit } = formMethods;

  return (
    <Container>
      <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5">Review your Information</Typography>
        </Grid>
      </TitleHeaderGrid>
      {state.loadAgentOnboardingQuery.status === "loading" && (
        <LoaderContainer container item justifyContent="center" alignItems="center">
          <Loader color="black" />
        </LoaderContainer>
      )}
      {state.loadAgentOnboardingQuery.status === "success" && (
        <FormProvider {...formMethods}>
          <Form id={formId} onSubmit={handleSubmit(onSave)} noValidate autoComplete="off">
            <Grid container spacing={1} justifyContent="space-between">
              <Grid container item md={7} spacing={3}>
                <PersonalDetailsForm defaultValues={state.personalDetailsForm} />
                <AgentDetailsForm defaultValues={state.agentDetailsForm} />
              </Grid>
              <Grid container item md={5} justifyContent="center">
                <UploadHeadshotForm
                  userFullName={userInfo?.fullName}
                  avatarUrl={userInfo?.avatar}
                  uploadAgentHeadshotPhoto={actions.uploadAgentHeadshotPhoto}
                  status={state.uploadAgentHeadshotPhoto.status}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <AgreementForm defaultValues={state.agreementForm} />
            </Grid>
          </Form>
        </FormProvider>
      )}
    </Container>
  );
};

import { dataFactory } from "src/mocks/dataFactory";
import { rest } from "msw";
import {
  PropertyControllerSearchV2Request,
  ListPropertyResponseDto,
  PropertyDto,
} from "src/modules/shared/apis/cma/generated";

export const cmaPropertiesListHandlers = [
  // [Shouldn't be a GET?]
  rest.post<PropertyControllerSearchV2Request, any, ListPropertyResponseDto>(
    `${process.env.REACT_APP_CMA_API_URL}/properties/search-v2`,
    (req, res, ctx) => {
      const page = Number(req.url.searchParams.get("page"));
      function propertiesFactory({ items }: { items?: number }): PropertyDto[] {
        return Array(items ?? 1)
          .fill(null)
          .map((_) => dataFactory.getPropertyDto());
      }

      const properties = propertiesFactory({ items: 5 });

      return res(
        ctx.status(200),
        ctx.json({
          properties,
          meta: {
            currentPage: page,
            totalItems: properties.length,
            itemCount: properties.length,
            itemsPerPage: 10,
            totalPages: 1,
          },
        })
      );
    }
  ),
];

import React from "react";

export abstract class ActionInvoker<Action, ActionInvokerContext, State> {
  dispatch: (action: Action) => void = () => {};
  context: ActionInvokerContext = null as any;
  state: State = null as any;
}

export function makeReducerContext<
  State,
  Action,
  ActionInvokerContext,
  Invoker extends ActionInvoker<Action, ActionInvokerContext, State>
>({
  defaultState,
  reducer,
  defaultInvoker,
}: {
  reducer: (state: State, action: Action) => State;
  defaultInvoker?: Invoker;
  defaultState?: State;
}) {
  const ContainerContext = React.createContext<{ state: State; actions: Invoker }>({
    state: defaultState,
  } as any);

  const ContainerContextProvider = (props: {
    children: React.ReactNode;
    invokerContext?: ActionInvokerContext;
    invoker?: Invoker;
    initialState?: State;
  }) => {
    if (defaultState == null && props.initialState == null)
      throw new Error("Could not resolve initial state.");

    if (defaultInvoker == null && props.invoker == null)
      throw new Error("Could not resolve a invoker.");

    const localInitialStateRef = React.useRef(props.initialState);

    const [state, dispatch] = React.useReducer(
      reducer,
      (localInitialStateRef.current ?? defaultState) as State
    );
    const invokerInstance = React.useMemo(
      () => props.invoker ?? defaultInvoker,
      [props.invoker]
    ) as Invoker;
    invokerInstance.dispatch = dispatch;
    invokerInstance.context = props.invokerContext as any;
    invokerInstance.state = state;
    const context = React.useMemo(
      () => ({
        state,
        actions: invokerInstance,
      }),
      [state, invokerInstance]
    );

    return <ContainerContext.Provider value={context}>{props.children}</ContainerContext.Provider>;
  };

  return {
    useReducerContext: () => React.useContext(ContainerContext),
    ReducerContextProvider: ContainerContextProvider,
  };
}

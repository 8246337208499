/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgentDto,
    AgentDtoFromJSON,
    AgentDtoFromJSONTyped,
    AgentDtoToJSON,
    CompsPreferencesDto,
    CompsPreferencesDtoFromJSON,
    CompsPreferencesDtoFromJSONTyped,
    CompsPreferencesDtoToJSON,
    PropertyDto,
    PropertyDtoFromJSON,
    PropertyDtoFromJSONTyped,
    PropertyDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CMADto
 */
export interface CMADto {
    /**
     * 
     * @type {string}
     * @memberof CMADto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CMADto
     */
    estimatedPriceMin?: number;
    /**
     * 
     * @type {number}
     * @memberof CMADto
     */
    estimatedPriceMax?: number;
    /**
     * 
     * @type {AgentDto}
     * @memberof CMADto
     */
    agent: AgentDto;
    /**
     * 
     * @type {PropertyDto}
     * @memberof CMADto
     */
    subjectProperty: PropertyDto;
    /**
     * 
     * @type {Array<PropertyDto>}
     * @memberof CMADto
     */
    comparableProperties: Array<PropertyDto>;
    /**
     * 
     * @type {Date}
     * @memberof CMADto
     */
    listDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CMADto
     */
    actionedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CMADto
     */
    requestedDate?: Date;
    /**
     * 
     * @type {CompsPreferencesDto}
     * @memberof CMADto
     */
    preferences?: CompsPreferencesDto;
    /**
     * 
     * @type {string}
     * @memberof CMADto
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof CMADto
     */
    title: string;
    /**
     * 
     * @type {boolean}
     * @memberof CMADto
     */
    isArchived: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CMADto
     */
    hasUnpublishedChanges: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CMADto
     */
    canPublish: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CMADto
     */
    canCustomize: boolean;
    /**
     * 
     * @type {string}
     * @memberof CMADto
     */
    clientDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof CMADto
     */
    coverPhotoCdnUrl?: string;
    /**
     * Which kind of presentation was chosen during creation
     * @type {string}
     * @memberof CMADto
     */
    presentationType: string;
    /**
     * Most recent date that the CMA updated
     * @type {Date}
     * @memberof CMADto
     */
    updateDate: Date;
}

export function CMADtoFromJSON(json: any): CMADto {
    return CMADtoFromJSONTyped(json, false);
}

export function CMADtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CMADto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'estimatedPriceMin': !exists(json, 'estimatedPriceMin') ? undefined : json['estimatedPriceMin'],
        'estimatedPriceMax': !exists(json, 'estimatedPriceMax') ? undefined : json['estimatedPriceMax'],
        'agent': AgentDtoFromJSON(json['agent']),
        'subjectProperty': PropertyDtoFromJSON(json['subjectProperty']),
        'comparableProperties': ((json['comparableProperties'] as Array<any>).map(PropertyDtoFromJSON)),
        'listDate': !exists(json, 'listDate') ? undefined : (new Date(json['listDate'])),
        'actionedDate': !exists(json, 'actionedDate') ? undefined : (new Date(json['actionedDate'])),
        'requestedDate': !exists(json, 'requestedDate') ? undefined : (new Date(json['requestedDate'])),
        'preferences': !exists(json, 'preferences') ? undefined : CompsPreferencesDtoFromJSON(json['preferences']),
        'status': json['status'],
        'title': json['title'],
        'isArchived': json['isArchived'],
        'hasUnpublishedChanges': json['hasUnpublishedChanges'],
        'canPublish': json['canPublish'],
        'canCustomize': json['canCustomize'],
        'clientDisplayName': json['clientDisplayName'],
        'coverPhotoCdnUrl': !exists(json, 'coverPhotoCdnUrl') ? undefined : json['coverPhotoCdnUrl'],
        'presentationType': json['presentationType'],
        'updateDate': (new Date(json['updateDate'])),
    };
}

export function CMADtoToJSON(value?: CMADto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'estimatedPriceMin': value.estimatedPriceMin,
        'estimatedPriceMax': value.estimatedPriceMax,
        'agent': AgentDtoToJSON(value.agent),
        'subjectProperty': PropertyDtoToJSON(value.subjectProperty),
        'comparableProperties': ((value.comparableProperties as Array<any>).map(PropertyDtoToJSON)),
        'listDate': value.listDate === undefined ? undefined : (value.listDate.toISOString()),
        'actionedDate': value.actionedDate === undefined ? undefined : (value.actionedDate.toISOString()),
        'requestedDate': value.requestedDate === undefined ? undefined : (value.requestedDate.toISOString()),
        'preferences': CompsPreferencesDtoToJSON(value.preferences),
        'status': value.status,
        'title': value.title,
        'isArchived': value.isArchived,
        'hasUnpublishedChanges': value.hasUnpublishedChanges,
        'canPublish': value.canPublish,
        'canCustomize': value.canCustomize,
        'clientDisplayName': value.clientDisplayName,
        'coverPhotoCdnUrl': value.coverPhotoCdnUrl,
        'presentationType': value.presentationType,
        'updateDate': (value.updateDate.toISOString()),
    };
}



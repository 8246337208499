import { ImageUnavailable } from "@avenue-8/ui-2";
import { CardActionArea, CardProps, CardContent, IconButtonProps } from "@mui/material"; // [TO DO] - Move to @avenue-8/ui-2
import { MoreVert } from "@mui/icons-material";
import { format } from "date-fns";
import {
  CustomCard,
  ConstrainedCardMedia,
  Title,
  BottomInfo,
  TextLine,
  MainIconButton,
  CardTag,
  CardTagInnerText,
} from "./CMACard.styles";

interface CMACardProps {
  title: string;
  clientDisplayName: string;
  updatedAt: Date;
  imageUrl?: string;
  cardProps?: CardProps;
  iconButtonProps?: IconButtonProps;
  disabled?: boolean;
  presentationType: string;
}

export const CMACard = ({
  disabled = false,
  imageUrl,
  title,
  clientDisplayName,
  updatedAt,
  iconButtonProps,
  cardProps,
  presentationType,
}: CMACardProps) => {
  const { onClick, ...iconButtonRest } = iconButtonProps || {};

  const noPropagateOnClick = (e: any) => {
    e.stopPropagation();
    !disabled && onClick?.(e);
  };

  return (
    <CustomCard $disabled={disabled} elevation={0} {...cardProps} data-testid="cma-card">
      <CardActionArea>
        <ConstrainedCardMedia image={imageUrl} title={title}>
          {!imageUrl && <ImageUnavailable />}
          {presentationType === "cma" && (
            <CardTag>
              <CardTagInnerText variant="body2" color="textSecondary">
                CMA
              </CardTagInnerText>
            </CardTag>
          )}
        </ConstrainedCardMedia>
        <CardContent>
          <Title variant="body1" data-testid="cma-title">
            {title}
          </Title>
          <BottomInfo>
            <TextLine variant="body2" color="textSecondary" data-testid="client-name">
              {clientDisplayName}
            </TextLine>
            <TextLine variant="body2" color="textSecondary">
              updated at {format(updatedAt, "MM/dd/yyyy")}
            </TextLine>
          </BottomInfo>
        </CardContent>
      </CardActionArea>
      <MainIconButton {...iconButtonRest} onClick={noPropagateOnClick} size="small">
        <MoreVert />
      </MainIconButton>
    </CustomCard>
  );
};

import { EventEmitter2 } from "eventemitter2";

interface BasicEvent {
  eventType: any;
}

export class EventEmitter<TEvent extends BasicEvent> {
  private emitter = new EventEmitter2({ wildcard: true });

  emit(appEvent: TEvent) {
    try {
      const { eventType, ...rest } = appEvent;
      setTimeout(() => {
        this.emitter.emit(eventType, rest, eventType);
      }, 0);
    } catch (e) {
      console.error(e);
    }
  }

  addListener(appEvent: TEvent["eventType"], handler: (data: Omit<TEvent, "eventType">) => void) {
    this.emitter.addListener(appEvent, handler);
  }

  removeListener(appEvent: TEvent["eventType"], fn: any) {
    this.emitter.removeListener(appEvent, fn);
  }

  addGlobalListener(handler: (data: { [key: string]: any }, eventType: string) => void) {
    this.emitter.addListener("*", handler);
  }

  removeGlobalListener(handler: (data: { [key: string]: any }, eventType: string) => void) {
    this.emitter.removeListener("*", handler);
  }
}

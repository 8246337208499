/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateEmptyCMADto
 */
export interface CreateEmptyCMADto {
    /**
     * 
     * @type {string}
     * @memberof CreateEmptyCMADto
     */
    agentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmptyCMADto
     */
    clientDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmptyCMADto
     */
    presentationType: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmptyCMADto
     */
    presentationName?: string;
}

export function CreateEmptyCMADtoFromJSON(json: any): CreateEmptyCMADto {
    return CreateEmptyCMADtoFromJSONTyped(json, false);
}

export function CreateEmptyCMADtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmptyCMADto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'agentId': !exists(json, 'agentId') ? undefined : json['agentId'],
        'clientDisplayName': json['clientDisplayName'],
        'presentationType': json['presentationType'],
        'presentationName': !exists(json, 'presentationName') ? undefined : json['presentationName'],
    };
}

export function CreateEmptyCMADtoToJSON(value?: CreateEmptyCMADto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'agentId': value.agentId,
        'clientDisplayName': value.clientDisplayName,
        'presentationType': value.presentationType,
        'presentationName': value.presentationName,
    };
}



import * as React from "react";
import { styledThemed as styled } from "../theme";

interface Props {
  children: React.ReactNode;
  pageBreakBefore?: boolean;
  pageBreakAfter?: boolean;
  condensedSpaceWhenPrint?: boolean;
}

const Layout = styled.div<{
  $pageBreakBefore?: boolean;
  $pageBreakAfter?: boolean;
  $condensedSpaceWhenPrint?: boolean;
}>`
  padding-top: ${(p) => p.theme.presentation.spacing(3)};
  padding-bottom: ${(p) => p.theme.presentation.spacing(3)};
  position: relative;
  @media print {
    padding-bottom: 0;
    ${(p) => (p.$condensedSpaceWhenPrint ? "padding-top: 0" : "")};
    ${(p) => (p.$condensedSpaceWhenPrint ? "padding-bottom: 0" : "")};
    ${(p) => (p.$pageBreakBefore ? "page-break-before: always;" : "")};
    ${(p) => (p.$pageBreakAfter ? "page-break-after: always;" : "")};
  }
`;

export const SectionViewLayout = ({
  children,
  pageBreakAfter,
  pageBreakBefore,
  condensedSpaceWhenPrint,
  ...rest
}: Props) => {
  return (
    <Layout
      {...rest}
      $pageBreakBefore={pageBreakBefore}
      $pageBreakAfter={pageBreakAfter}
      $condensedSpaceWhenPrint={condensedSpaceWhenPrint}
    >
      {children}
    </Layout>
  );
};

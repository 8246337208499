import React from "react";
import { Typography, Button, Dialog, DialogContent, DialogTitle, styled } from "@avenue-8/ui-2";
import { LoadFailedBox } from "../../../shared/components/LoadFailedBox";
import { SpinnerBox } from "../../../shared/components/SpinnerBox";
import { TransitionPanel } from "../../../shared/components/TransitionPanel";
import { EditAgentAccountForm } from "./components/EditAgentAccountForm";
import { useCreateAgentAccountContext } from "./CreateAgentAccountContext";

const ButtonActions = styled.div`
  display: flex;
  margin: 0 1rem 1rem 1rem;
  justify-content: flex-end;
  button {
    margin: 0.5rem;
  }
`;

const ResetPasswordButton = styled(Button)`
  // small css specialization to avoid using !important
  &[type="button"] {
    margin-right: auto;
  }
`;

const CustomDialogContent = styled(DialogContent)`
  min-width: 240px;
`;

export interface CreateAgentAccountProps {
  agentAccountId?: string;
  onClose: () => void;
  onComplete: () => void;
}

export const CreateAgentAccount = (props: CreateAgentAccountProps) => {
  const { agentAccountId, onClose, onComplete } = props;
  const { state, actions } = useCreateAgentAccountContext();

  const [isResetingPassword, setResetingPassword] = React.useState(false);

  const handleResetPassword = async () => {
    if (state.loadState === "loaded") {
      const email = state.agentAccount?.user?.email;
      if (!email) {
        throw new Error("Agent email must be informed to request a password reset");
      }
      await actions.resetPasswordForAgent({ email });
      setResetingPassword(false);
    }
  };

  const formButtons = (
    <>
      {agentAccountId && (
        <ResetPasswordButton onClick={() => setResetingPassword(true)} type="button">
          Reset password
        </ResetPasswordButton>
      )}
      <Button onClick={onClose} variant="text" type="button">
        Cancel
      </Button>
      <Button form={"agent-account-form"} type="submit">
        Save
      </Button>
    </>
  );
  const resetPasswordButtons = (
    <>
      <Button onClick={() => setResetingPassword(false)} type="button" variant="text">
        Back
      </Button>
      <Button onClick={handleResetPassword} color="primary" type="button">
        Send
      </Button>
    </>
  );
  const resetPasswordContent = (
    <Typography variant="body2">
      Resetting this user's password will email the agent. Continue?
    </Typography>
  );

  React.useEffect(() => {
    actions.load(props.agentAccountId);
  }, [actions, props.agentAccountId]);

  let content: React.ReactNode;
  if (state.loadState === "not-loaded") {
    content = (
      <CustomDialogContent>
        <SpinnerBox />
      </CustomDialogContent>
    );
  } else if (state.loadState === "load-failed") {
    content = (
      <CustomDialogContent>
        <LoadFailedBox />
      </CustomDialogContent>
    );
  } else if (state.loadState === "loaded") {
    if (isResetingPassword) {
      content = (
        <TransitionPanel aboutToChange={state.sendResetPasswordEmailState === "init"}>
          <DialogTitle id="agent-account-modal-title">Reset agent account</DialogTitle>
          <DialogContent>{resetPasswordContent}</DialogContent>
          <ButtonActions>{resetPasswordButtons}</ButtonActions>
        </TransitionPanel>
      );
    } else {
      content = (
        <TransitionPanel aboutToChange={state.saveState === "saving"}>
          <DialogTitle id="agent-account-modal-title">
            {agentAccountId ? "Edit agent account" : "Create agent account"}
          </DialogTitle>
          <DialogContent>
            <EditAgentAccountForm
              id={"agent-account-form"}
              data={state.formData}
              onChanged={() => actions.setIsDirty()}
              onSave={async (data) => {
                if (state.saveState !== "saving") {
                  const saved = await actions.save(data);
                  if (saved) {
                    onClose();
                    onComplete();
                  }
                }
              }}
            />
          </DialogContent>
          <ButtonActions>{formButtons}</ButtonActions>
        </TransitionPanel>
      );
    }
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby="agent-account-modal">
      {content}
    </Dialog>
  );
};

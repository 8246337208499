import * as React from "react";
import { DialogProps, DialogContent, DialogActions, Typography, CloseIcon } from "@avenue-8/ui-2";
import { CMADto, PropertyDto } from "src/modules/shared/apis/cma/generated";
import SearchSubjectPropertyResultItem from "../../../../SearchSubjectProperty/SearchSubjectPropertyResultItem";
import { appEventEmitter } from "../../../../../../../../events/app-event-emitter";
import { buildPropertyKey } from "../../../../../../../shared/utils/buildPropertyKey";
import {
  CustomDialog,
  CustomTitle,
  CloseButton,
  SelectButtonContainer,
  CustomSelectButton,
} from "./ComparablePropertyResultItemModal.styles";

interface Props extends Omit<DialogProps, "onBackdropClick"> {
  onClose: () => void;
  resultItem: PropertyDto | null;
  onRemoveCompListing: () => Promise<boolean | void>;
  onSelectCompListing: () => Promise<void>;
  comparableProperties: CMADto["comparableProperties"];
  presentationType: string;
}

export const ComparablePropertyResultItemModal = (props: Props) => {
  const {
    onSubmit,
    onClose,
    resultItem,
    onRemoveCompListing,
    onSelectCompListing,
    comparableProperties,
    presentationType,
    ...rest
  } = props;

  const isResultSelected = comparableProperties.find(
    (property) => buildPropertyKey(property) === buildPropertyKey(resultItem || {})
  );

  const handleSelectListing = async () => {
    if (!resultItem) return;
    if (isResultSelected) {
      const removed = await onRemoveCompListing();
      if (removed) onClose();
    } else {
      await onSelectCompListing();
      onClose();
    }
  };

  const backdropProps = React.useMemo(
    () => ({
      onEntered: () => {
        appEventEmitter.emit({
          eventType: "cma-comparable-properties-detailed-list-opened",
          presentationType,
        });
      },
    }),
    [presentationType]
  );

  return (
    <CustomDialog
      BackdropProps={backdropProps}
      scroll="body"
      fullWidth
      maxWidth={false}
      onClose={onClose}
      {...rest}
    >
      <CustomTitle id="search-subject-property-dialog-title">
        {resultItem?.addressLine1 ?? " - "}
        <Typography variant="body2" color="primary">
          {resultItem?.addressLine2 ? `${resultItem?.addressLine2}, ` : ""}{" "}
          {resultItem?.city ?? "-"} {resultItem?.zipCode ?? "-"}
        </Typography>
        <CloseButton aria-label="close" onClick={onClose} data-testid="modal-close">
          <CloseIcon />
        </CloseButton>
      </CustomTitle>
      <DialogContent>
        {resultItem && (
          <SearchSubjectPropertyResultItem
            property={resultItem}
            showHeader={false}
            showDivider={false}
          />
        )}
      </DialogContent>
      <DialogActions>
        <SelectButtonContainer>
          <CustomSelectButton onClick={handleSelectListing} data-testid="modal-remove">
            {isResultSelected ? "Remove" : "Select"} Listing
          </CustomSelectButton>
        </SelectButtonContainer>
      </DialogActions>
    </CustomDialog>
  );
};

import * as React from "react";
import { useMutation, useQueryClient } from "react-query";
import { useAppContext } from "../../../../../AppContext";
import { toast } from "@avenue-8/ui-2";
import { makeBLContext } from "../../../../shared/hooks/makeBLContext";
import {
  COMPARABLE_PROPERTY_FORM_DEFAULT_STATE,
  convertPropertyDtoToEditComparablePropertyFormModel,
  EditComparablePropertyFormModel,
} from "./comparable-property-converter";
import {
  PropertyDto,
  PropertyImageDto,
  UpsertComparablePropertyRequestDto,
} from "../../../../shared/apis/cma/generated";
import { getCMAApiClient } from "src/modules/shared/apis/cma/api-factories";
import { appEventEmitter } from "./../../../../../events/app-event-emitter";
import { useCreateCMALogic } from "src/modules/cma-v2/hooks/useCreateCMALogic";

type Props = {
  comparableProperty?: PropertyDto | null;
};

type ComparableImageHandlingTypes = { id: string; mlsId: string; photoId: string };

function useEditComparablePropertyLogicInner(props: Props) {
  const [comparableProperty, setComparableProperty] = React.useState<
    PropertyDto | null | undefined
  >(props.comparableProperty);

  const cmaApi = getCMAApiClient();
  const { actions: appActions } = useAppContext();
  const {
    state: { cma },
  } = useCreateCMALogic();
  const queryClient = useQueryClient();

  const editComparablePropertyForm: EditComparablePropertyFormModel = React.useMemo(() => {
    const form = comparableProperty
      ? convertPropertyDtoToEditComparablePropertyFormModel(comparableProperty)
      : { ...COMPARABLE_PROPERTY_FORM_DEFAULT_STATE };

    return form;
  }, [comparableProperty]);

  const { mutateAsync: saveComparableProperty, status: saveComparablePropertyStatus } = useMutation(
    async ({ data, id }: { data: UpsertComparablePropertyRequestDto; id: string }) =>
      await appActions.watchPromise(
        cmaApi.cMAControllerUpsertComparableProperty({
          id,
          upsertComparablePropertyRequestDto: {
            ...data,
          },
        }),
        { blocking: true, message: "Saving changes..." }
      ),
    {
      onSuccess: async (_data, variables) => {
        toast.success("Comparable property successfully saved.");
        if (!variables.data.mlsId && !variables.data.mlsSource) {
          appEventEmitter.emit({
            eventType: "cma-manual-comparable-property-created",
            presentationType: cma!.presentationType,
          });
        } else {
          appEventEmitter.emit({
            eventType: "cma-manual-comparable-property-updated",
            presentationType: cma!.presentationType,
          });
        }
        await queryClient.invalidateQueries(["cmas", variables.id]);
      },
      onError: (error) => {
        toast.error("Failed to save comparable property.");
        throw error;
      },
    }
  );

  const {
    mutateAsync: uploadComparablePropertyImage,
    status: uploadComparablePropertyImageStatus,
  } = useMutation(
    async ({
      id,
      mlsId,
      data,
    }: {
      id: string;
      mlsId: string;
      data: FormData;
    }): Promise<PropertyImageDto[]> =>
      await appActions.watchPromise(
        getCMAApiClient().cMAControllerAddComprablePropertyImage({
          id,
          mlsId,
          images: data.getAll("images") as Blob[],
        }),
        {
          blocking: true,
          message: "Uploading photos...",
        }
      ),
    {
      onSuccess: (_data, variables) => {
        toast.success("Images successfully uploaded.");
        queryClient.invalidateQueries(["cmas", variables.id]);
      },
      onError: (error) => {
        toast.error("Failed to upload the images.");
        throw error;
      },
    }
  );

  const {
    mutateAsync: removeComparablePropertyImage,
    status: removeComparablePropertyImageStatus,
  } = useMutation(
    async ({ id, mlsId, photoId }: ComparableImageHandlingTypes): Promise<void> =>
      await appActions.watchPromise(
        getCMAApiClient().cMAControllerDeleteComparablePropertyImage({
          id,
          mlsId,
          imgId: parseInt(photoId),
        }),
        {
          blocking: true,
          message: "Removing images...",
        }
      ),
    {
      onSuccess: (_data, variables) => {
        toast.success("Images successfully removed.");
        queryClient.invalidateQueries(["cmas", variables.id]);
      },
      onError: (error) => {
        toast.error("Failed to remove the images.");
        throw error;
      },
    }
  );

  const { mutateAsync: makeCoverPhotoInComparableProperty } = useMutation(
    async ({ id, mlsId, photoId }: ComparableImageHandlingTypes): Promise<void> => {
      return await appActions.watchPromise(
        getCMAApiClient().cMAControllerSelectComparableCoverImage({
          id,
          mlsId,
          selectComparablePropertyCoverImageRequestDto: { properyImageId: parseInt(photoId) },
        }),
        {
          blocking: true,
          message: "Changing cover photo...",
        }
      );
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to change cover photo.");
      },
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(["cmas", variables.id]);
        toast.success("Cover photo changed succesfully.");
      },
    }
  );

  return {
    state: {
      saveComparablePropertyStatus,
      editComparablePropertyForm,
      comparableProperty,
      uploadComparablePropertyImageStatus,
      removeComparablePropertyImageStatus,
    },
    actions: {
      saveComparableProperty,
      setComparableProperty,
      uploadComparablePropertyImage,
      removeComparablePropertyImage,
      makeCoverPhotoInComparableProperty,
    },
  };
}

export const {
  LogicContextProvider: EditComparablePropertyLogicProvider,
  useLogicContext: useEditComparablePropertyLogic,
} = makeBLContext({ useLogic: useEditComparablePropertyLogicInner });

import { cssMaxLines } from "../../../../shared/css-snippets";
import { styledThemed as styled, PresentationTheme } from "../theme";

interface BasicProps {
  marginBottom?: number;
  marginTop?: number;
  mobileMarginBottom?: number;
  mobileMarginTop?: number;
  textAlign?: "center" | "left" | "right";
  pageBreakBefore?: boolean;
}

export const baseStyle = (p: BasicProps & { theme: PresentationTheme }) => {
  return `margin-top: ${
    p.theme.presentation.mobile
      ? p.mobileMarginTop
        ? p.mobileMarginTop + "rem"
        : p.theme.presentation.spacing(p.marginTop ?? 0)
      : p.marginTop
      ? p.marginTop + "rem"
      : "0rem"
  };
  margin-bottom: ${
    p.theme.presentation.mobile
      ? p.mobileMarginBottom
        ? p.mobileMarginBottom + "rem"
        : p.theme.presentation.spacing(p.marginBottom ?? 0)
      : p.marginBottom
      ? p.marginBottom + "rem"
      : "0rem"
  };
  ${p.textAlign ? `text-align: ${p.textAlign};` : ""}
  ${p.pageBreakBefore ? "page-break-before: always;" : ""}
  `;
};

export const Title1 = styled.h1<BasicProps>`
  ${(p) => p.theme.presentation.fontAsCSS("h1")};
  ${(p) => baseStyle(p)};
  ${() => cssMaxLines(3)};
`;

export const Subtitle1 = styled.h1<BasicProps>`
  ${(p) => p.theme.presentation.fontAsCSS("subtitle1")}
  ${(p) => baseStyle(p)}
`;

export const Subtitle2 = styled.h1<BasicProps>`
  ${(p) => p.theme.presentation.fontAsCSS("subtitle2")}
  ${(p) => baseStyle(p)}
  text-align: center;
`;

export const Body2 = styled.p<BasicProps>`
  ${(p) => p.theme.presentation.fontAsCSS("body2")}
  ${(p) => baseStyle(p)}
  text-align: center;
  font-style: italic;
  font-size: 0.8rem;
`;

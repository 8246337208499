import * as React from "react";
import { RearrangeIcon, DeleteIcon, Button, EditIcon, Typography } from "@avenue-8/ui-2";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { SideBySideItem } from "./SideBySideItem";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { NavigationOptions, useSwiper } from "../../../../../../hooks/useSwiper";
import { SideBySideExpandedItem } from "./SideBySideExpandedItem";
import { appEventEmitter } from "../../../../../../../../events/app-event-emitter";
import { CustomOverlineButton } from "../../../../../../../shared/components/CustomOverlineButton";
import { useCreateCMALogic } from "src/modules/cma-v2/hooks/useCreateCMALogic";
import {
  SideBySideContainer,
  FloatOverContainer,
  SwiperContainer,
  DraggableContainer,
  DragHandleContainer,
  DragContent,
  DragActions,
  CollapseButtonContainer,
  InternalDragActions,
  CompSourceContainer,
} from "./SideBySideProperties.styles";

export interface SideBySidePropertiesProps {
  subjectProperty: PropertyDto | undefined;
  properties: PropertyDto[] | undefined;
  handleChangeProperties?: (properties: PropertyDto[]) => void;
  navigation?: NavigationOptions;
  onDeleteProperty?: (property: PropertyDto) => void;
  onEditProperty?: (property: PropertyDto) => void;
  onClose: () => void;
  onEditClick?: (property: PropertyDto) => void;
}

export const SideBySideProperties = (props: SideBySidePropertiesProps) => {
  const {
    properties,
    handleChangeProperties,
    subjectProperty,
    onClose = () => {},
    onDeleteProperty = () => {},
    onEditClick,
    navigation,
  } = props;
  const [expandedItem, setExpandedItem] = React.useState<string | null>(null);
  const { state } = useCreateCMALogic();
  const swiper = useSwiper({ navigation });
  const lastCoord = React.useRef<{ x?: number; y?: number }>({});

  React.useEffect(() => {
    swiper.init();
    return () => {
      swiper.remove();
    };
  }, [swiper]);

  if (!properties || properties.length === 0) {
    onClose();
    return null;
  }

  const handleMouseDown = (e: React.MouseEvent) => {
    lastCoord.current = { x: e.pageX, y: e.pageY };
  };

  const handleMouseUp = (e: React.MouseEvent, propertyId: string) => {
    const threshold = 10;
    if (lastCoord.current.x && Math.abs(lastCoord.current.x - e.pageX) < threshold) {
      handlePropertyClick(propertyId);
    }
  };

  const handlePropertyClick = (propertyId: string): void => {
    if (!expandedItem || expandedItem !== propertyId) {
      setExpandedItem(propertyId);
    }
  };

  const reorderProperties = (
    properties: PropertyDto[],
    destinationIndex: number,
    sourceIndex: number
  ): PropertyDto[] => {
    const newProperties = [...properties];
    const [removed] = newProperties.splice(sourceIndex, 1);
    newProperties.splice(destinationIndex, 0, removed);
    return newProperties;
  };

  const handleDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    swiper.init();

    if (!destination || destination?.index === source.index) {
      return;
    }

    appEventEmitter.emit({
      eventType: "cma-comparable-properties-order-changed",
      from: source.index,
      to: destination.index,
      presentationType: state.cma!.presentationType,
    });

    const newProperties = reorderProperties(properties ?? [], destination.index, source.index);
    handleChangeProperties?.(newProperties);
  };

  return (
    <SideBySideContainer>
      <FloatOverContainer>
        <SideBySideItem propertyData={subjectProperty} isSubjectProperty />
      </FloatOverContainer>
      <DragDropContext onDragEnd={handleDragEnd} onBeforeCapture={() => swiper.remove()}>
        <Droppable droppableId="droppable-properties" direction="horizontal">
          {(provided, snapshot) => (
            <SwiperContainer
              className="_swiper-container"
              {...provided.droppableProps}
              ref={provided.innerRef}
              $isDraggingOver={snapshot.isDraggingOver}
            >
              {properties?.map((property, index) => {
                const isExpanded = expandedItem === property.id;
                const isManuallyAdded = property.mlsSource === "CUSTOM";
                return (
                  <Draggable draggableId={property.id} index={index} key={property.id}>
                    {(provided, snapshot) => (
                      <DraggableContainer
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        className="_swiper-item"
                        $isDragging={snapshot.isDragging}
                        $isExpanded={isExpanded}
                      >
                        <DragHandleContainer
                          {...provided.dragHandleProps}
                          $isDragging={snapshot.isDragging}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <RearrangeIcon rotate={90} size={16} />
                        </DragHandleContainer>
                        <DragContent
                          $isExpanded={isExpanded}
                          onMouseUp={(e) => handleMouseUp(e, property.id)}
                          onMouseDown={handleMouseDown}
                        >
                          {isExpanded && <SideBySideExpandedItem propertyData={property} />}
                          {!isExpanded && (
                            <SideBySideItem propertyData={property} isSubjectProperty={false} />
                          )}
                        </DragContent>
                        <DragActions $isExpanded={isExpanded}>
                          {isExpanded && (
                            <CollapseButtonContainer>
                              <Button
                                variant="text"
                                size="small"
                                onClick={() => handlePropertyClick("")}
                              >
                                {"<"} Collapse details
                              </Button>
                            </CollapseButtonContainer>
                          )}
                          <InternalDragActions $isExpanded={isExpanded}>
                            <CustomOverlineButton
                              onClick={() => onEditClick?.(property)}
                              variant="text"
                            >
                              <EditIcon size={16} />
                              Edit
                            </CustomOverlineButton>
                            <CustomOverlineButton
                              onClick={(e) => {
                                e.stopPropagation();
                                property && onDeleteProperty?.(property);
                              }}
                              variant="text"
                            >
                              <DeleteIcon size={16} />
                              Remove
                            </CustomOverlineButton>
                          </InternalDragActions>
                          <CompSourceContainer $isExpanded={isExpanded}>
                            {isManuallyAdded ? (
                              <Typography color={"#ABACB7"} variant="caption">
                                Manually added property
                              </Typography>
                            ) : (
                              <>
                                <Typography color={"#ABACB7"} variant="caption">
                                  Imported from
                                </Typography>
                                <img src="/images/mls_black.png" alt="MLS" />
                              </>
                            )}
                          </CompSourceContainer>
                        </DragActions>
                      </DraggableContainer>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </SwiperContainer>
          )}
        </Droppable>
      </DragDropContext>
    </SideBySideContainer>
  );
};

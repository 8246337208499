import * as React from "react";
import { Button, MapGeometry } from "@avenue-8/ui-2";
import { StyledBackdrop, Box, ButtonBar } from "./SwitchGeometryConfirmation.styles";

interface Props {
  currentGeometry?: MapGeometry;
  selectedGeometry?: MapGeometry;
  onConfirmed: (confirmed: boolean) => void;
}

export const SwitchGeometryConfirmation = (props: Props) => {
  const { selectedGeometry, currentGeometry, onConfirmed } = props;
  const lastSelectedGeometry = React.useRef<MapGeometry | undefined>();
  if (selectedGeometry) lastSelectedGeometry.current = selectedGeometry;
  const lastCurrentGeometry = React.useRef<MapGeometry | undefined>();
  if (selectedGeometry && currentGeometry) lastCurrentGeometry.current = currentGeometry;
  const buttonRef = React.createRef<any>();

  React.useLayoutEffect(() => {
    if (selectedGeometry != null) buttonRef.current?.focus();
  }, [selectedGeometry, buttonRef]);

  return (
    <StyledBackdrop
      onClick={() => onConfirmed(false)}
      open={selectedGeometry != null && currentGeometry != null}
    >
      <Box onClick={(e) => e.stopPropagation()}>
        When switching to the {lastSelectedGeometry.current?.shape} tool, the{" "}
        {lastCurrentGeometry.current?.shape} will be cleared. Would you like to continue?
        <ButtonBar>
          <Button onClick={() => onConfirmed(false)} variant="text">
            Cancel
          </Button>
          <Button ref={buttonRef} onClick={() => onConfirmed(true)} variant="text">
            Continue
          </Button>
        </ButtonBar>
      </Box>
    </StyledBackdrop>
  );
};

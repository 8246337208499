import { Button, Grid, styled, Typography } from "@avenue-8/ui-2";
import { PropertyDto } from "../../../../shared/apis/cma/generated/models";
import { PhotosSwiper } from "./PhotosSwiper";
import { getPropertyTypeLabel } from "../../../../shared/constants";
import { listingStatusColor } from "../../../../shared/css-snippets";
import {
  ContentLabel,
  ContentText,
  PropertyCardContainer,
  DescriptionContainer,
  VerticalDivider,
  Divider,
  GridRow,
  AgentInfo,
  MapContainer,
} from "./SearchSubjectPropertyResultItem.styles";
import {
  formatText,
  formatToCurrency,
  formatToSqft,
  formatYesNo,
} from "src/modules/shared/utils/formatters";
import { getDaysOnMarket } from "src/modules/shared/utils/get-days-on-market";
import { StaticMap } from "./StaticMap";
import { BreakdownPopover } from "../../BreakdownPopover";
import { calculateMonthlies } from "./subject-property-helper";
import { getPropertyFieldsToShow } from "../subject-property";

export interface SearchSubjectPropertyResultItemProps {
  property: PropertyDto;
  onSelectProperty?: () => void;
  showDivider?: boolean;
  showHeader?: boolean;
}

const SearchSubjectPropertyResultItem = (props: SearchSubjectPropertyResultItemProps) => {
  const { property, onSelectProperty, showDivider = true, showHeader = true } = props;

  if (!showHeader && onSelectProperty)
    throw new Error(
      "SearchSubjectPropertyResultItem: showHeader is required when onSelectProperty is provided"
    );

  const daysOnMarket = getDaysOnMarket(property.listDate, property.soldDate);

  const agentInfo = [
    property?.listingAgent.name ? formatText(property.listingAgent?.name) : undefined,
    property?.listingAgent?.dre ? `DRE ${formatText(property.listingAgent?.dre)}` : undefined,
    property?.mlsSource ? formatText(property.mlsSource) : undefined,
  ]
    .filter(Boolean)
    .join(", ");

  const totalMonthlies = calculateMonthlies(property.recurringCosts ?? []);

  const fieldsToShow = getPropertyFieldsToShow({ state: property.state ?? "" });

  return (
    <PropertyCardContainer container spacing={2} data-testid="mls-result">
      {showHeader && (
        <Grid container item justifyContent="space-between">
          <div>
            <Typography variant="h6" data-testid="address1">
              {property?.addressLine1 ?? "-"}
            </Typography>
            <Typography variant="body2" color="primary" data-testid="address2">
              {property?.addressLine2 ? `${property?.addressLine2}, ` : ""} {property?.city ?? "-"}{" "}
              {property?.zipCode ?? "-"}
            </Typography>
          </div>
          {!!onSelectProperty && (
            <div>
              <Button onClick={onSelectProperty} data-testid="select-property">
                Select Property
              </Button>
            </div>
          )}
        </Grid>
      )}

      <Grid item md={12}>
        <PhotosSwiper
          spaceBetween={16}
          photosPerSlide={4}
          photos={property.images}
          centeredSlides={false}
          swiperId={property.id}
        />
      </Grid>

      <Grid container item>
        <Grid item flex={1}>
          <Typography variant="h6">About this Property</Typography>
          <DescriptionContainer data-testid="description">
            {property?.description ?? "No description available"}
          </DescriptionContainer>
          <AgentInfo variant="body2" data-testid="agent-info">
            {agentInfo}
          </AgentInfo>
          <MapContainer>
            <StaticMap
              address={`${property?.addressLine1}, ${property?.city}, ${property?.state} ${property?.zipCode}`}
              lat={property?.latitude}
              lng={property?.longitude}
              height={227}
              width={465}
            />
          </MapContainer>
        </Grid>
        <VerticalDivider />
        <Grid item flex={1}>
          <Typography variant="h6">Details</Typography>
          <Grid container item md={12} flex={1} marginTop={1} columnSpacing={2}>
            <Grid
              container
              item
              md={6}
              rowSpacing={2}
              aria-label="left-column"
              alignContent="flex-start"
            >
              <GridRow container item>
                <Grid item>
                  <ContentLabel>Listing Status</ContentLabel>
                </Grid>
                <Grid item flex={1}>
                  <ListingStatus
                    variant="body2"
                    $listingStatus={property.listingStatus}
                    data-testid="listing-status"
                  >
                    {property.listingStatus}
                  </ListingStatus>
                </Grid>
              </GridRow>

              <GridRow container item data-testid="listing-price">
                <StatBlock
                  statTitle="Listing Price"
                  statContent={formatToCurrency(property.listPrice)}
                />
              </GridRow>

              <GridRow container item data-testid="sold-price">
                <StatBlock
                  statTitle="Sold Price"
                  statContent={formatToCurrency(property.soldPrice)}
                />
              </GridRow>

              <GridRow container item data-testid="beds">
                <StatBlock statTitle="Bedrooms" statContent={formatText(property.beds)} />
              </GridRow>

              <GridRow container item data-testid="baths">
                <StatBlock statTitle="Bathrooms" statContent={formatText(property.baths)} />
              </GridRow>

              <GridRow container item data-testid="total-rooms">
                <StatBlock statTitle="Total Rooms" statContent={formatText(property.roomsTotal)} />
              </GridRow>

              <GridRow container item data-testid="neighborhood">
                <StatBlock
                  statTitle="Neighborhood"
                  statContent={formatText(property.neighborhood)}
                />
              </GridRow>

              <GridRow container item data-testid="property-type">
                <StatBlock
                  statTitle="Property Type"
                  statContent={formatText(getPropertyTypeLabel(property?.propertyType))}
                />
              </GridRow>

              {/* Moved to the right column since there are fewer fields for now */}
              {/* <GridRow container item data-testid="mls-id">
                <StatBlock statTitle="MLS ID" statContent={formatText(property.mlsId)} />
              </GridRow> */}

              <GridRow container item data-testid="monthlies">
                {/* <StatBlock
                  statTitle="Monthly Fees"
                  statContent={formatToCurrency(totalMonthlyFees)}
                /> */}
                <StatBlock
                  statTitle={
                    <>
                      Monthlies
                      {Boolean(totalMonthlies) && (
                        <BreakdownPopover fees={property.recurringCosts ?? []} />
                      )}
                    </>
                  }
                  statContent={formatToCurrency(totalMonthlies)}
                />
              </GridRow>
            </Grid>

            <Grid
              container
              item
              md={6}
              rowSpacing={2}
              aria-label="right-column"
              alignContent="flex-start"
            >
              <GridRow container item data-testid="living-space">
                <StatBlock
                  statTitle="Living Space"
                  statContent={formatToSqft(property.livingSpace)}
                />
              </GridRow>

              <GridRow container item data-testid="lot-size">
                <StatBlock statTitle="Lot Size" statContent={formatToSqft(property.lotSize)} />
              </GridRow>

              <GridRow container item data-testid="year-built">
                <StatBlock statTitle="Year Built" statContent={formatText(property.yearBuilt)} />
              </GridRow>

              <GridRow container item data-testid="doorman">
                <StatBlock
                  statTitle="Doorman"
                  statContent={formatYesNo(property.hasDoormanAttendance)}
                />
              </GridRow>

              <GridRow container item data-testid="parking-space">
                <StatBlock
                  statTitle="Parking Spaces"
                  statContent={formatText(property.garageSpaces)}
                />
              </GridRow>

              {/* HIDDEN SINCE IT IS BEING SHOWN IN THE BREAKDOWN */}
              {/* <GridRow container item data-testid="monthly-hoa">
                <StatBlock
                  statTitle={hoaFees?.label ?? "Monthly HOA"}
                  statContent={formatToCurrency(hoaFees?.value)}
                />
              </GridRow> */}

              <GridRow container item data-testid="days-on-market">
                <StatBlock
                  statTitle="Days on Market"
                  statContent={daysOnMarket > 0 ? daysOnMarket : "-"}
                />
              </GridRow>

              <GridRow container item data-testid="floor">
                <StatBlock statTitle="Floor Number" statContent={formatText(property.floor)} />
              </GridRow>

              <GridRow container item data-testid="mls-id">
                <StatBlock statTitle="MLS ID" statContent={formatText(property.mlsId)} />
              </GridRow>

              {fieldsToShow.flipTaxRemarks && (
                <GridRow container item data-testid="flip-tax-remarks">
                  <StatBlock
                    statTitle="Flip Tax"
                    statContent={formatText(property.flipTaxRemarks)}
                  />
                </GridRow>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {showDivider && (
        <Grid container item md={12}>
          <Divider />
        </Grid>
      )}
    </PropertyCardContainer>
  );
};

export const StatBlock = ({
  statTitle,
  statContent,
}: {
  statTitle: React.ReactNode;
  statContent: any;
}) => {
  return (
    <>
      <Grid item>
        <ContentLabel variant="body2">{statTitle}</ContentLabel>
      </Grid>
      <Grid item flex={1}>
        <ContentText variant="body2" color="secondary">
          {statContent}
        </ContentText>
      </Grid>
    </>
  );
};

const ListingStatus = styled(ContentText)<{ $listingStatus: string | undefined }>`
  text-transform: capitalize;
  font-weight: 500;
  ${(p) => listingStatusColor(p.$listingStatus)}
`;

export default SearchSubjectPropertyResultItem;

interface Options {
  behavior?: "smooth" | "auto";
  offset?: number;
}

export const scrollToExtended = async (
  element: HTMLElement,
  { offset, behavior }: Options = {}
) => {
  return new Promise<void>((resolve, reject) => {
    try {
      window.scrollBy({
        top: element.getBoundingClientRect().top - (offset ?? 0),
        behavior: behavior ?? "smooth",
      });
    } catch (e) {
      reject(e);
      return;
    }

    let scrollTimeout: NodeJS.Timeout;
    const scrollListener = () => {
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        document.removeEventListener("scroll", scrollListener);
        resolve();
      }, 100);
    };
    document.addEventListener("scroll", scrollListener);
  });
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RadiusParams
 */
export interface RadiusParams {
    /**
     * 
     * @type {number}
     * @memberof RadiusParams
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof RadiusParams
     */
    longitude: number;
    /**
     * 
     * @type {number}
     * @memberof RadiusParams
     */
    distance: number;
}

export function RadiusParamsFromJSON(json: any): RadiusParams {
    return RadiusParamsFromJSONTyped(json, false);
}

export function RadiusParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RadiusParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'distance': json['distance'],
    };
}

export function RadiusParamsToJSON(value?: RadiusParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'latitude': value.latitude,
        'longitude': value.longitude,
        'distance': value.distance,
    };
}



import { useState } from "react";
import { ResponsiveContextMenu } from "./ResponsiveContextMenu";
import { Avatar, IconButton, ListItemText, styled } from "@avenue-8/ui-2";
import { useMobile } from "../../presentation/hooks/useMobile";
import RoleAuthorized from "../../auth/components/RoleAuthorized";
import { Role } from "../../../routes/routes";

export interface UserMenuProps {
  userFullName?: string;
  avatarUrl?: string;
  userRole?: string;
  onAccountClick?: () => void;
  onAgentAccountsClick?: () => void;
  onLogoutClick: () => void;
}

const IconWrapper = styled.div`
  position: relative;
`;

const CustomSizeAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  position: relative;
  margin-top: -3px;
`;

export const UserMenu = (props: UserMenuProps) => {
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const initials =
    props.userFullName
      ?.split(" ")
      ?.map((x) => x[0] ?? "")
      ?.join("") ?? "";
  const open = Boolean(anchorEl);

  return (
    <IconWrapper>
      <IconButton
        aria-label="User menu"
        data-testid="user-menu-button"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        size="small"
      >
        <CustomSizeAvatar alt={props.userFullName} src={props.avatarUrl}>
          {initials}
        </CustomSizeAvatar>
      </IconButton>
      <ResponsiveContextMenu
        desktopCustomPosition={{ right: 0 }}
        onOpen={() => {}}
        anchorEl={anchorEl}
        menuPositionProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        {({ ItemComponent }) => {
          return [
            !isMobile && !!props.onAccountClick && (
              <RoleAuthorized
                key="my-account"
                authorizedComponent={
                  <ItemComponent onClick={props.onAccountClick} data-testid="my-account">
                    <ListItemText>My Account</ListItemText>
                  </ItemComponent>
                }
                role={Role.Agent}
                entityRole={props.userRole}
              />
            ),
            !!props.onAgentAccountsClick && (
              <RoleAuthorized
                key="agent-account"
                authorizedComponent={
                  <ItemComponent onClick={props.onAgentAccountsClick} data-testid="agent-account">
                    <ListItemText>Agent Accounts</ListItemText>
                  </ItemComponent>
                }
                role={Role.Admin}
                entityRole={props.userRole}
              />
            ),
            <ItemComponent key="logout" onClick={props.onLogoutClick} data-testid="log-out">
              <ListItemText>Log Out</ListItemText>
            </ItemComponent>,
          ];
        }}
      </ResponsiveContextMenu>
    </IconWrapper>
  );
};

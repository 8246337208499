import { GalleryPhoto } from "@avenue-8/ui-2";
import {
  PropertyPhoto,
  SideBySideComparisonItem,
  SideBySideComparisonSectionView,
} from "src/modules/presentation/presentation-generation-logic";
import { usePhotoGalleryContext } from "src/modules/shared/contexts/PhotoGalleryContext";
import { ComparisionItem } from "./SideBySideComparison.styles";
import { SinglePropertyContent } from "./SinglePropertyContent";

type DisplayPreferences = SideBySideComparisonSectionView["preferences"];
interface SinglePropertyProps {
  highlight?: boolean;
  fixedLabels: boolean;
  displayMode: "mobile" | "desktop" | "print" | "tablet";
  propertyData?: SideBySideComparisonItem;
  gallery: PropertyPhoto[];
  preferences?: DisplayPreferences;
  isSubjectProperty?: boolean;
}
export const SingleDynamicProperty = (props: SinglePropertyProps) => {
  const { highlight = false, displayMode, gallery, fixedLabels } = props;
  const { changeGalleryPhoto } = usePhotoGalleryContext();
  const galleryPhotos: GalleryPhoto[] = gallery.map((photo, i) => ({
    id: `id-${i}`,
    src: photo.url,
    alt: photo.caption || `Property photo ${i}`,
  }));
  return (
    <ComparisionItem
      $highlight={highlight}
      $fixedLabels={fixedLabels}
      $displayMode={displayMode}
      data-testid="comparison-item"
    >
      <SinglePropertyContent {...props} onClick={() => changeGalleryPhoto(0, galleryPhotos)} />
    </ComparisionItem>
  );
};

import * as React from "react";
import { useGoogleMapsApi } from "src/modules/cma-v2/hooks/useGoogleMapsApi";
// Proxy the usePlacesAutocomplete hook with some default values
import usePlacesAutocompleteLib, { HookArgs } from "use-places-autocomplete";

interface Props extends HookArgs {}

export function usePlacesAutocomplete(props: Props = {}) {
  const { isReady: googleMapsApiIsReady } = useGoogleMapsApi({
    libraries: ["places", "geometry"],
  });
  const requestOptions: Props["requestOptions"] = {
    componentRestrictions: {
      country: "USA",
    },
    ...props.requestOptions,
  };

  const places = usePlacesAutocompleteLib({
    requestOptions,
    debounce: 300,
    initOnMount: false,
  });

  React.useEffect(() => {
    if (googleMapsApiIsReady) {
      places.init();
    }
  }, [googleMapsApiIsReady, places]);

  return places;
}

import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, Typography, Loader, styled } from "@avenue-8/ui-2";
import { useAgentOnboardingLogic } from "../../../hooks/useAgentOnboardingLogic";
import { AgentOnboardingStepBaseProps } from "../../AgentOnboarding";
import { PersonalDetailsForm, PersonalDetailsFormModel } from "./PersonalDetailsForm";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 2.5rem;
`;

const LoaderContainer = styled(Grid)`
  min-height: 300px;
`;

interface PersonalDetailsStepProps extends AgentOnboardingStepBaseProps {
  data?: PersonalDetailsFormModel;
  onSave: (data: PersonalDetailsFormModel) => Promise<any>;
}

export const PersonalDetailsStep = ({ formId, onSave }: PersonalDetailsStepProps) => {
  const { state } = useAgentOnboardingLogic();
  const formMethods = useForm<PersonalDetailsFormModel>({
    defaultValues: { ...state.personalDetailsForm },
  });
  const { handleSubmit } = formMethods;

  return (
    <Container>
      <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5">Enter your Personal Details</Typography>
        </Grid>
      </TitleHeaderGrid>
      {state.loadAgentOnboardingQuery.status === "loading" && (
        <LoaderContainer container item justifyContent="center" alignItems="center">
          <Loader color="black" />
        </LoaderContainer>
      )}
      {state.loadAgentOnboardingQuery.status === "success" && (
        <FormProvider {...formMethods}>
          <Form id={formId} onSubmit={handleSubmit(onSave)} noValidate autoComplete="off">
            <Grid container item md={7} spacing={3}>
              <PersonalDetailsForm defaultValues={state.personalDetailsForm} />
            </Grid>
          </Form>
        </FormProvider>
      )}
    </Container>
  );
};

export const itHasMoreThanOneWord = (text: string): boolean => {
  return text.trim().split(" ").length > 1;
};

export const itStartsWithNumber = (text: string): boolean => {
  return Number.isInteger(Number(text[0]));
};

export const itIsAlphaAndNumericSingleWord = (text: string): boolean => {
  return Boolean(
    text.split(" ").length === 1 &&
      text.match(/[a-zA-Z]/) &&
      text.match(/[0-9]/) &&
      text.match(/^[a-zA-Z0-9]+$/)
  );
};

export const itIsAlphanumericSingleWord = (text: string): boolean => {
  return Boolean(text.split(" ").length === 1 && text.match(/^[a-zA-Z0-9]+$/));
};

export const itIsNumericSingleWord = (text: string): boolean => {
  return Boolean(text.split(" ").length === 1 && text.match(/^[0-9]+$/));
};

// 1210-1212 Ortega Street
export const itIsMultiFamilyAddressFormat = (text: string): boolean => {
  const terms = text.split(" ");
  if (terms.length < 2) return false;

  const numberRange = terms[0].split("-");

  if (numberRange.length !== 2) return false;

  return itIsNumericSingleWord(numberRange[0]) && itIsNumericSingleWord(numberRange[1]);
};

export const itIsMlsIdDashFormat = (text: string): boolean => {
  if (itHasMoreThanOneWord(text)) return false;

  const terms = text.split("-");
  if (terms.length !== 2) return false;
  return (
    itIsAlphanumericSingleWord(terms[0]) && terms[0].length !== 5 && itIsNumericSingleWord(terms[1])
  );
};

export const itIsCompleteAddress = (searchText: string): boolean => {
  const hasMoreThanOneWord = itHasMoreThanOneWord(searchText);
  const startsWithNumber = itStartsWithNumber(searchText);
  const isMultiFamilyAddressFormat = itIsMultiFamilyAddressFormat(searchText);
  return (hasMoreThanOneWord && startsWithNumber) || isMultiFamilyAddressFormat;
};

export const itIsMlsId = (searchText: string): boolean => {
  const isAlphaAndNumericSingleWord = itIsAlphaAndNumericSingleWord(searchText);
  const isNumericSingleWord = itIsNumericSingleWord(searchText);
  const isMlsIdDashFormat = itIsMlsIdDashFormat(searchText);
  return (
    isAlphaAndNumericSingleWord ||
    isMlsIdDashFormat ||
    (isNumericSingleWord && searchText.length !== 5)
  );
};

import { Dialog, Button, DialogTitle, DialogContent, styled } from "@avenue-8/ui-2";
import { UserItem } from "../../../shared/apis/user/generated";
import { SendResetPasswordEmailState } from "./AgentAccountsContext";
import { TransitionPanel } from "../../../shared/components/TransitionPanel";

interface Props {
  open: boolean;
  setAgent: (data: UserItem | null) => void;
  onConfirm: (email: string) => Promise<void>;
  agentEmail?: string;
  state?: SendResetPasswordEmailState;
}

const ButtonActions = styled.div`
  display: flex;
  margin: 8px 16px;
  justify-content: flex-end;
  button {
    margin: 0.5rem;
  }
`;

export const AgentResetPasswordModal = (props: Props) => {
  const { open, agentEmail, setAgent, onConfirm, state } = props;

  if (!agentEmail) {
    return null;
  }

  return (
    <Dialog open={open} aria-labelledby="reset-password-for-agent">
      <TransitionPanel aboutToChange={state === "init"}>
        <DialogTitle id="reset-password-for-agent">Reset agent account</DialogTitle>
        <DialogContent>
          Resetting this user's password will email the agent. Continue?
        </DialogContent>
        <ButtonActions>
          <Button onClick={() => setAgent(null)} variant="outlined" color="primary" type="button">
            Cancel
          </Button>
          <Button
            onClick={() => onConfirm(agentEmail)}
            variant="contained"
            color="primary"
            type="button"
          >
            Confirm
          </Button>
        </ButtonActions>
      </TransitionPanel>
    </Dialog>
  );
};

import { Button, Typography, ThemeProvider, styled } from "@avenue-8/ui-2";
import { ErrorBoundary } from "react-error-boundary";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  justify-content: center;
`;

const ErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: () => void;
}) => {
  if (process.env.NODE_ENV === "development") {
    console.log(error);
  }
  return (
    <ThemeProvider darkMode={false}>
      <Container>
        <Typography variant="h4">Something went wrong.</Typography>
        <br />
        <Button onClick={() => resetErrorBoundary()} variant="outlined">
          Back
        </Button>
      </Container>
    </ThemeProvider>
  );
};

export const ErrorBoundaryProvider = ({ children }: { children: any }) => {
  return <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>{children}</ErrorBoundary>;
};

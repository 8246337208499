import { styled, Typography, ThemeProvider } from "@avenue-8/ui-2";
import { WarningIcon } from "../../../assets/icons/WarningIcon";

export const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f3f3fa;
`;

export const WarningCard = styled.div`
  background-color: #ffffff;
  padding: 32px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
`;

export const Title = styled(Typography)`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const WarningPage = () => {
  return (
    <ThemeProvider darkMode={false}>
      <PageContainer>
        <WarningCard>
          <WarningIcon />
          <Title variant="h4">This link has expired.</Title>
          <Typography variant="body2">
            Please reach out to your MarketComp contact to get a new one sent to you.
          </Typography>
        </WarningCard>
      </PageContainer>
    </ThemeProvider>
  );
};

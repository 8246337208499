/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthCodeParams
 */
export interface AuthCodeParams {
    /**
     * 
     * @type {string}
     * @memberof AuthCodeParams
     */
    authCode: string;
}

export function AuthCodeParamsFromJSON(json: any): AuthCodeParams {
    return AuthCodeParamsFromJSONTyped(json, false);
}

export function AuthCodeParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthCodeParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authCode': json['authCode'],
    };
}

export function AuthCodeParamsToJSON(value?: AuthCodeParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authCode': value.authCode,
    };
}



import React from "react";
import CropIcon from "@mui/icons-material/Crop";
import { Typography, Avatar, styled } from "@avenue-8/ui-2";

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageContainer = styled.div`
  position: relative;

  button {
    display: none;
  }

  &:hover {
    button {
      display: flex;
    }
  }
  margin: 0 auto;
`;

const Image = styled.img<{ size: number }>`
  border-radius: 50%;
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
`;

const CropImageButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: ${(p) => p.theme.av8.background};
  border: none;
  border: 1px solid ${(p) => p.theme.av8.primaryColor};
  padding: 6px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const UploadSuccess = styled(Typography)`
  color: ${(p) => p.theme.av8.successColor};
  margin: 24px 0 0 0;
  text-align: center;
`;

const EmptyAvatar = styled(Avatar)`
  width: 152px;
  height: 152px;
  margin: 0 auto;
  font-size: 48px;
`;

interface HeadshotPreviewProps {
  image?: string;
  openCropHeadshotModal: null | ((image: string) => void);
  uploadStatus?: string;
  size?: number;
  initials?: string;
}

export const HeadshotPreview = ({
  image,
  openCropHeadshotModal,
  uploadStatus,
  size,
  initials,
}: HeadshotPreviewProps) => {
  const imgRef = React.useRef<HTMLImageElement | null>(null);

  const handleCropImageButton = () => {
    const image = imgRef.current;
    if (image) {
      openCropHeadshotModal?.(image.src);
    }
  };

  return (
    <>
      {image ? (
        <PreviewContainer>
          <ImageContainer>
            {openCropHeadshotModal && (
              <CropImageButton
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCropImageButton();
                }}
              >
                <CropIcon fontSize="small" color="primary" />
              </CropImageButton>
            )}
            <Image src={image} ref={imgRef} size={size ?? 120} />
          </ImageContainer>
          {uploadStatus && uploadStatus === "success" && (
            <UploadSuccess variant="body2">Your headshot was successfully uploaded.</UploadSuccess>
          )}
        </PreviewContainer>
      ) : initials ? (
        <EmptyAvatar>{initials}</EmptyAvatar>
      ) : null}
    </>
  );
};

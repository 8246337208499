import { Breakpoint, useTheme } from "@mui/material";
import * as React from "react";

//to resolve
export const useMobile = (breakpoint: Breakpoint = "md") => {
  const theme = useTheme();
  const brkpoint = theme.breakpoints.values[breakpoint];
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < brkpoint);

  React.useEffect(() => {
    const handleSizeChange = () => {
      const _isMobile = window.innerWidth < brkpoint;
      if (isMobile !== _isMobile) return setIsMobile(_isMobile);
    };
    window.addEventListener("resize", handleSizeChange);
    return () => {
      window.removeEventListener("resize", handleSizeChange);
    };
  }, [isMobile, brkpoint]);

  return isMobile;
};

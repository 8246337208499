import * as React from "react";
import { Button, styled } from "@avenue-8/ui-2";

const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0;
  gap: 24px;
`;

export type DashboardBarActionsProps = {
  onCreateNewCma: () => void;
  showActions: boolean;
};
export function DashboardBarActions({ onCreateNewCma, showActions }: DashboardBarActionsProps) {
  return (
    <ActionsContainer>
      {/* SEARCH BAR GOES HERE */}

      {showActions && <Button onClick={onCreateNewCma} data-testid="create-cma-button">Create new</Button>}

    </ActionsContainer>
  );
}

import { Grid, Typography, Popover as MuiPopover, styled } from "@avenue-8/ui-2";

export const Popover = styled(MuiPopover)`
  & .MuiPopover-paper {
    padding: 1rem 2rem;
    border-radius: 0.5rem;
    background: ${(p) => p.theme.av8.backgroundAlt};
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.av8.input.borderColor};
    max-width: 340px;
  }
`;
export const MlsContainer = styled(Grid)`
  margin-top: 2rem;
  text-align: left;
  gap: 1rem;
  img {
    filter: grayscale(100%);
  }
`;
export const Trigger = styled(Typography)`
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  cursor: default;
`;
export const MlsItem = styled.div`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  align-items: center;
  gap: 1rem;
`;
export const MlsLogo = styled.div`
  img {
    width: 64px;
    mix-blend-mode: multiply;
  }
  padding: 0 0.5rem;
`;
export const MlsName = styled(Typography)`
  font-size: 12px;
  flex: 1;
`;

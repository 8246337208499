import { useState } from "react";
import { styled, Grid } from "@avenue-8/ui-2";
import { useBreakpoint } from "../../hooks/useMobile";
import { SmartUserMenu } from "../SmartUserMenu";
import { Logo } from "../../../shared/components/Logo";
import { GeneralPresentationStepper } from "./GeneralPresentationStepper";
import { useCreateCMALogic } from "../../hooks/useCreateCMALogic";
import { SubjectPropertyHeader } from "../SubjectPropertyHeader";
import { EditCmaHeaderModal } from "./EditCmaHeaderModal/EditCmaHeaderModal";
import { PropertySearchStep } from "./Steps/Search/PropertySearchStep";
import { SmartPresentationEditorStep } from "./Steps/PresentationEditor/SmartPresentationEditorStep";
import { CreateCMAPageLayout } from "./CreateCMAPageLayout";
import { EditComparableProperty } from "./EditComparableProperty/EditComparableProperty";

const StyledUserMenu = styled(SmartUserMenu)`
  margin: 0px auto;
`;

export const CreateGeneralPresentation = () => {
  const isSmall = useBreakpoint("desktopDown");
  const { state, actions } = useCreateCMALogic();
  const { currentStep } = state;
  const [editCmaModalOpen, setEditCmaModalOpen] = useState(false);
  if (currentStep == null) return null; //we always need to have a step selected
  const currentStepKey = currentStep.params.step;

  const clientDisplayName = state.cma?.clientDisplayName ?? "";
  const cmaTitle =
    state.cma?.title ||
    `${state.cma?.subjectProperty?.addressLine1 ?? ""} ${
      state.cma?.subjectProperty?.addressLine2 ?? ""
    }`.trim() ||
    "Untitled CMA";

  let stepContent: React.ReactNode;
  if (currentStepKey === "search") {
    stepContent = <PropertySearchStep />;
  } else if (currentStepKey === "customize-presentation") {
    stepContent = <SmartPresentationEditorStep cmaId={state.cmaId!} />;
  } else {
    stepContent = null;
  }

  return (
    <CreateCMAPageLayout
      userMenu={<StyledUserMenu />}
      headerContent={
        <SubjectPropertyHeader
          clientName={clientDisplayName}
          address={cmaTitle}
          onEditClick={() => setEditCmaModalOpen(true)}
          loading={state.cmaStatus === "loading"}
        />
      }
      logo={<Logo />}
      contentReservedHeight={isSmall ? 24 : 170}
      headerPosition={isSmall ? "static" : "fixed"}
    >
      <Grid container data-key={`${currentStepKey}-step-container`}>
        <Grid container item xs={12}>
          <GeneralPresentationStepper currentStep={currentStep.index} />
        </Grid>
        {stepContent}
      </Grid>
      {state.cma && (
        <EditCmaHeaderModal
          onSave={async ({ cmaTitle, clientName }) => {
            await actions.updateCmaHeader({
              id: state.cma!.id,
              clientDisplayName: clientName,
              title: cmaTitle,
            });
            setEditCmaModalOpen(false);
          }}
          defaultValues={{
            clientName: clientDisplayName,
            cmaTitle: cmaTitle,
          }}
          onClose={() => setEditCmaModalOpen(false)}
          open={editCmaModalOpen}
        />
      )}
      <EditComparableProperty />
    </CreateCMAPageLayout>
  );
};

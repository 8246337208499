import { PropertyDto } from "../../../../../shared/apis/cma/generated";

export interface Property {
  id: string;
  mlsId: string;
  addressLine1?: string;
  addressLine2?: string;
  mlsSource?: string;
  baths?: number;
  beds?: number;
  images: Array<{ url: string; id: any }>;
  propertyType?: string;
  soldDate?: Date;
  sellingPrice?: number;
  livingSpace?: number;
  lotSize?: number;
  city?: string;
  state?: string;
  zipCode?: string;
  lat?: number;
  lng?: number;
  listingStatus?: string;
  neighborhood?: string;
  currentPrice?: number;
}

export function propertyFromPropertyDto(property?: PropertyDto): Property | undefined {
  if (!property) return property;
  const {
    id,
    addressLine1,
    addressLine2,
    mlsId = "",
    baths,
    beds,
    listingStatus,
    livingSpace,
    soldPrice: sellingPrice,
    lotSize,
    propertyType,
    soldDate,
    city,
    mlsSource,
    state,
    zipCode,
    latitude: lat,
    longitude: lng,
    neighborhood,
  } = property;

  const p: Property = {
    id,
    addressLine1,
    addressLine2,
    baths,
    beds,
    mlsId,
    listingStatus,
    livingSpace,
    lotSize,
    mlsSource,
    propertyType,
    sellingPrice,
    soldDate,
    city,
    state,
    zipCode,
    lng,
    lat,
    neighborhood,
    currentPrice: listingStatus === "sold" ? sellingPrice : property.listPrice,
    images: property.images.map(({ url, cdnUrl }) => {
      return { url: cdnUrl || url, id: null };
    }),
  };

  return p;
}

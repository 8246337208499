import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { FreeTextSectionConfig } from "./free-text-section-config";
import { FreeTextSectionView } from "./free-text-section-view";
import { v4 as uuidv4 } from "uuid";

export class FreeTextSectionWidget
  implements SectionWidget<"free-text", FreeTextSectionConfig, FreeTextSectionView>
{
  type: "free-text" = "free-text";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  buildDefaultConfig(): FreeTextSectionConfig {
    return {
      type: "free-text",
      navTitle: "Untitled Section",
      id: uuidv4(),
      visibility: true,
      title: "Untitled Section",
      content: "Custom content",
    };
  }

  buildView({
    sectionConfig: config,
  }: SectionViewBuilderContext<FreeTextSectionConfig, null>): FreeTextSectionView {
    if (config.type !== "free-text") throw new Error("Unexpected section type.");
    const { id, type, visibility, content, title, navTitle } = config;
    const view: FreeTextSectionView = {
      id,
      type,
      visibility,
      content,
      title,
      navTitle,
    };
    return view;
  }
}

import * as React from "react";
import { useTheme, CloseIcon, ChevronIcon, Card } from "@avenue-8/ui-2";
import { SideBySidePropertiesProps, SideBySideProperties } from "./";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { appEventEmitter } from "../../../../../../../../events/app-event-emitter";
import { useEditComparablePropertyLogic } from "../../../../EditComparableProperty/useEditComparablePropertyLogic";
import { useCreateCMALogic } from "src/modules/cma-v2/hooks/useCreateCMALogic";
import {
  ScrollableModal,
  StyledBackdrop,
  Layout,
  LeftSpace,
  Content,
  ModalHeaderContainer,
  ModalTitle,
  CloseButton,
  ModalButtonsContainer,
  NavButton,
} from "./SideBySidePropertiesModal.styles";

interface Props extends SideBySidePropertiesProps {
  open: boolean;
  onClose: () => void;
  title: string;
  onDeleteProperty: (property: PropertyDto) => void;
  reorderComps: (properties: PropertyDto[]) => void;
}

export const SideBySidePropertiesModal = ({
  open,
  onClose,
  title,
  properties,
  reorderComps,
  ...rest
}: Props) => {
  const {
    actions: { setComparableProperty },
    state: { comparableProperty: selectedComparableProperty },
  } = useEditComparablePropertyLogic();
  const [propertiesState, setPropertiesState] = React.useState(properties);
  const [isDirty, setIsDirty] = React.useState(false);
  const {
    state: { presentationType, cma },
  } = useCreateCMALogic();
  const theme = useTheme();

  const handleLayoutClick = (e: any) => e.stopPropagation();

  React.useEffect(() => {
    setPropertiesState(properties);
  }, [properties]);

  const handleClose = () => {
    if (propertiesState && isDirty) {
      reorderComps(propertiesState);
    }
    setIsDirty(false);
    onClose();
  };

  const handleChangeProperties = (newProperties: PropertyDto[]) => {
    setPropertiesState(newProperties);
    setIsDirty(true);
  };

  return (
    <ScrollableModal
      onClick={onClose}
      open={open}
      BackdropComponent={StyledBackdrop}
      BackdropProps={{
        onEntered: () => {
          appEventEmitter.emit({
            eventType: "cma-comps-table-opened",
            presentationType: presentationType!,
          });
        },
        unmountOnExit: true,
      }}
      $hidden={Boolean(selectedComparableProperty)}
    >
      <Layout onClick={handleLayoutClick}>
        <LeftSpace />
        <Content>
          <ModalHeaderContainer>
            <ModalTitle variant="h1">{title}</ModalTitle>
            <CloseButton onClick={handleClose}>
              <CloseIcon />
            </CloseButton>
          </ModalHeaderContainer>
          <Card>
            <SideBySideProperties
              {...rest}
              properties={propertiesState}
              handleChangeProperties={handleChangeProperties}
              onEditClick={(property: PropertyDto) => {
                appEventEmitter.emit({
                  eventType: "cma-manual-comparable-property-modal-edit-opened",
                  presentationType: cma!.presentationType,
                });
                setComparableProperty(property);
              }}
              onClose={handleClose}
              navigation={{
                nextEl: ".nextElement",
                prevEl: ".prevElement",
              }}
            />
          </Card>
          <ModalButtonsContainer>
            <NavButton className="prevElement">
              <ChevronIcon rotate={180} color={theme.av8.primaryColor} />
            </NavButton>
            <NavButton className="nextElement">
              <ChevronIcon color={theme.av8.primaryColor} />
            </NavButton>
          </ModalButtonsContainer>
        </Content>
      </Layout>
    </ScrollableModal>
  );
};

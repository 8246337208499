import { styled } from "@avenue-8/ui-2";
import { Tooltip } from "@mui/material";

export const EditorCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditorTooltip = styled(Tooltip)`
  margin: 0px;
  border-color: ${p => p.theme.av8.input.borderColor};
  color: ${p => p.theme.av8.input.borderColor};
`;

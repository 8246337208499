import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { useAppContext } from "src/AppContext";
import { CMAEstimatedPriceSet, appEventEmitter } from "src/events/app-event-emitter";
import { getCMAApiClient } from "src/modules/shared/apis/cma/api-factories";

interface Props {
  onSuccess?: () => void;
}
export const useSetEstimatePriceMutation = (props?: Props) => {
  const { onSuccess } = props || {};
  const queryClient = useQueryClient();
  const { actions: appActions } = useAppContext();

  const mutation = useMutation(
    async ({
      min,
      max,
      cmaId,
    }: {
      min?: number | null;
      max?: number | null;
      cmaId: string;
      eventOptions: { from: CMAEstimatedPriceSet["from"] };
      disableInvalidateQueries?: boolean;
    }) => {
      return appActions.watchPromise(
        getCMAApiClient().cMAControllerSetEstimatedPrice({
          id: cmaId,
          setEstimatedPriceRequestDto: { min, max },
        })
      );
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to set the estimate range.");
      },
      onSuccess: (_, variables) => {
        appEventEmitter.emit({
          eventType: "cma-estimated-price-set",
          from: variables.eventOptions.from,
        });
        onSuccess?.();
        queryClient.invalidateQueries(["cmas", variables.cmaId]);
        if (!variables.disableInvalidateQueries) {
          setTimeout(() => queryClient.invalidateQueries(["presentations", variables.cmaId]), 2000);
        }
      },
    }
  );
  return mutation;
};

import { styledThemed as styled } from "../theme";

type ConstrainedContainerProps = {
  $noMobilePadding?: boolean;
  $textAlign?: "left" | "center" | "right";
};
export const ConstrainedContainer = styled.div<ConstrainedContainerProps>`
  padding: 0px 1rem;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  ${(p) => `text-align: ${p.$textAlign}` ?? ""};
  @media print {
    max-width: auto;
    padding: 0;
  }
  @media (max-width: 1040px) {
    ${(p) => (p.$noMobilePadding ? `padding: 0;` : "")}
  }
`;

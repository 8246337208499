import * as React from "react";
import { itIsCompleteAddress, itIsMlsId } from "src/modules/shared/utils/search-string";
import { appEventEmitter } from "../../../events/app-event-emitter";
import {
  INITIAL_MAP_FILTERS,
  INITIAL_GEOGRAPHICAL_FILTERS,
  INITIAL_FORM_FILTERS,
  INITIAL_INDIRECT_FILTERS,
} from "../components/CreateCMA/Steps/Search/comparable-filters";
import {
  SearchComparableFormModel,
  SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES,
} from "../components/CreateCMA/Steps/Search/search-comparable-form-model";

interface Props {
  initialValues?: Partial<SearchComparableFormModel>;
  defaultCoords?: {
    lat: number;
    lng: number;
  };
}

export const usePropertySearchLogic = (props?: Props) => {
  const { initialValues, defaultCoords } = props || {};
  const [lastSearch, setLastSearch] = React.useState("");
  const [searchAndFiltersState, setSearchAndFiltersState] =
    React.useState<SearchComparableFormModel>({
      ...SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES,
      search: lastSearch,
      ...initialValues,
    });

  const resolveRadiusFilter = React.useCallback(
    (input: SearchComparableFormModel): boolean => {
      if (input.radius && defaultCoords) {
        if (Number(input.radius) === 0) return true;
        input.search = "";
        setLastSearch(input.search);

        const mapFilters: SearchComparableFormModel["mapFilters"] = {
          shape: "circle",
          center: { lat: defaultCoords.lat, lng: defaultCoords.lng },
          radius: Number(input.radius) * 1609.34,
        };

        setSearchAndFiltersState({
          ...searchAndFiltersState,
          ...input,
          page: 1,
          ...INITIAL_GEOGRAPHICAL_FILTERS,
          radius: input.radius,
          mapFilters,
        });
        return true;
      }
      return false;
    },
    [defaultCoords, searchAndFiltersState]
  );

  // HANDLERS

  const handleApplyFilters = React.useCallback(
    (input: SearchComparableFormModel) => {
      const data = { ...searchAndFiltersState, ...input };
      /**
       * Update Neighborhood or Area filter:
       *  Remove map filter.
       *  Remove same building detection.
       *  Remove radius filter.
       **/
      if (
        searchAndFiltersState.neighborhood !== data.neighborhood ||
        searchAndFiltersState.mlsArea !== data.mlsArea
      ) {
        setLastSearch(data.search ?? "");
        setSearchAndFiltersState({
          ...searchAndFiltersState,
          ...data,
          page: 1,
          mapFilters: { ...INITIAL_MAP_FILTERS },
          radius: INITIAL_FORM_FILTERS.radius,
          sameBuildingSearch: false,
          search: data.search ?? "",
        });
        return;
      }

      /**
       * Same building detection:
       *  Remove map filter.
       *  Remove geographical filters.
       **/
      if (data.sameBuildingSearch) {
        data.search = data.sameBuildingAddress ?? "";
        setLastSearch(data.search);
        const isApplyingSameBuilding =
          data.sameBuildingSearch && !searchAndFiltersState.sameBuildingSearch;

        const filtersExceptions: Pick<
          SearchComparableFormModel,
          "propertyTypes" | "doorman" | "lotSizeMax" | "lotSizeMin"
        > = {
          propertyTypes: isApplyingSameBuilding
            ? INITIAL_FORM_FILTERS.propertyTypes
            : data.propertyTypes,
          doorman: isApplyingSameBuilding ? INITIAL_FORM_FILTERS.doorman : data.doorman,
          lotSizeMax: INITIAL_FORM_FILTERS.lotSizeMax,
          lotSizeMin: INITIAL_FORM_FILTERS.lotSizeMin,
        };

        setSearchAndFiltersState({
          ...searchAndFiltersState,
          ...data,
          page: 1,
          ...INITIAL_GEOGRAPHICAL_FILTERS,
          mapFilters: { ...INITIAL_MAP_FILTERS },
          ...filtersExceptions,
        });
        return;
      }

      /**
       * Radius Filter:
       *  Update map filter.
       *  Remove geographical filters.
       *  Update radius filter.
       **/
      if (resolveRadiusFilter(input)) return;

      setLastSearch(data.search ?? "");
      setSearchAndFiltersState({
        ...searchAndFiltersState,
        ...data,
        page: 1,
      });
    },
    [searchAndFiltersState, resolveRadiusFilter]
  );

  /**
   * Full search used by the searchbar when pressing enter
   **/
  const handleSearch = (input: Pick<SearchComparableFormModel, "search">) => {
    const search = input.search ?? "";
    const data = { ...searchAndFiltersState, ...input };
    let params: SearchComparableFormModel = {};

    /**
     * If it is detected as MLS ID or exact address, then remove all filters
     * (existing filters, same building detection, and map).
     **/
    if (itIsCompleteAddress(search) || itIsMlsId(search)) {
      params = {
        ...SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES,
        search: data.search,
        page: 1,
      };
      handleApplyFilters(params);
      return;
    }

    params = {
      ...data,
      ...INITIAL_GEOGRAPHICAL_FILTERS,
      mapFilters: INITIAL_MAP_FILTERS,
      sameBuildingSearch: false,
      page: 1,
    };
    handleApplyFilters(params);
  };

  const handleClearFilters = (presentationType: string) => {
    setSearchAndFiltersState({
      ...searchAndFiltersState,
      ...INITIAL_FORM_FILTERS,
      ...INITIAL_INDIRECT_FILTERS,
      mapFilters: INITIAL_MAP_FILTERS,
    });
    appEventEmitter.emit({ eventType: "cma-search-filters-cleared", presentationType });
  };

  /**
   * Map filter:
   *  Keep all existing filters except neighborhood and MLS area.
   *  Remove same building detection.
   **/
  const handleApplyMapFilters = (data: SearchComparableFormModel) => {
    const params: SearchComparableFormModel = {
      ...data,
      ...INITIAL_GEOGRAPHICAL_FILTERS,
      sameBuildingSearch: false,
      mapFilters: data.mapFilters,
      search: "",
      page: 1,
    };
    handleApplyFilters(params);
  };

  /**
   * Used when we want to apply the chosen suggestion to the search term
   **/
  const handleApplySuggestionAsSearch = React.useCallback(
    (data: SearchComparableFormModel, chosenSuggestion: { [type: string]: string | null }) => {
      let search = "";
      if (chosenSuggestion.type === "mlsId") {
        search = chosenSuggestion.mlsId ?? "";
      } else {
        search = chosenSuggestion.text ?? "";
      }

      handleApplyFilters({
        ...data,
        search,
        page: 1,
      });
    },
    [handleApplyFilters]
  );

  const handleApplySuggestionWithFilters = React.useCallback(
    (data: SearchComparableFormModel, chosenSuggestion: { [type: string]: string | null }) => {
      const { neighborhood, city, state, county, mlsId, area } = chosenSuggestion;
      const search = chosenSuggestion.text || data.search;

      // If an MLS ID was found the user provided a MLS ID or and exact address
      if (mlsId) {
        handleApplyFilters({
          ...SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES,
          page: 1,
          search: mlsId,
        });
        return;
      }

      const suggestionFilters: Partial<SearchComparableFormModel> = {
        search,
        neighborhood: neighborhood ?? INITIAL_FORM_FILTERS.neighborhood,
        city: city ?? INITIAL_INDIRECT_FILTERS.city,
        state: state ?? INITIAL_INDIRECT_FILTERS.state,
        county: county ?? INITIAL_INDIRECT_FILTERS.county,
        mlsArea: area ?? INITIAL_FORM_FILTERS.mlsArea,
        mapFilters: INITIAL_MAP_FILTERS,
        sameBuildingSearch: false,
        radius: INITIAL_FORM_FILTERS.radius,
      };

      handleApplyFilters({
        ...data,
        ...suggestionFilters,
        page: 1,
      });
    },
    [handleApplyFilters]
  );

  const handleChangePage = React.useCallback((input: Pick<SearchComparableFormModel, "page">) => {
    setSearchAndFiltersState((oldState) => ({ ...oldState, ...input }));
  }, []);

  return {
    searchAndFiltersState,
    lastSearch,
    setLastSearch,
    handleApplyFilters,
    handleSearch,
    handleApplySuggestionAsSearch,
    handleApplySuggestionWithFilters,
    handleClearFilters,
    handleApplyMapFilters,
    handleChangePage,
  };
};

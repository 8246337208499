import { Backdrop, styled } from "@avenue-8/ui-2";

export const StyledBackdrop = styled(Backdrop)`
  position: absolute;
  z-index: 10;
  background: transparent;
`;
export const Box = styled.div`
  padding: 1rem 1.5rem;
  background: ${(p) => p.theme.av8.background};
  color: ${(p) => p.theme.av8.secondaryColor};
  font-size: 14px;
  margin: 1rem;
`;
export const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 1rem -1rem -0.5em -1rem;
`;

export interface NavigationOptions {
  nextEl: string;
  prevEl: string;
}

// Inspired by this solution https://codepen.io/thenutz/pen/VwYeYEE

export function useSwiper(options?: { className?: string; navigation?: NavigationOptions }) {
  const { navigation, className = "._swiper-container" } = options || {};
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  const getSwiperElement = () => document.querySelector(className) as HTMLElement;
  const getSwiperPrevElement = () =>
    navigation ? (document.querySelector(navigation.prevEl) as HTMLButtonElement) : null;
  const getSwiperNextElement = () =>
    navigation ? (document.querySelector(navigation.nextEl) as HTMLButtonElement) : null;

  const handleMouseDown = (e: MouseEvent) => {
    const swiper = getSwiperElement();
    if (!swiper) return;
    isDown = true;
    swiper.classList.add("active");
    startX = e.pageX - swiper.offsetLeft;
    scrollLeft = swiper.scrollLeft;
  };

  const handleMouseMove = () => {
    const swiper = getSwiperElement();
    if (!swiper) return;
    isDown = false;
    swiper.classList.remove("active");
  };

  const handleMouseUp = () => {
    const swiper = getSwiperElement();
    if (!swiper) return;
    isDown = false;
    swiper.classList.remove("active");
  };

  const handleMouseLeave = (e: MouseEvent) => {
    if (!isDown) return;
    const swiper = getSwiperElement();
    if (!swiper) return;
    e.preventDefault();
    const x = e.pageX - swiper.offsetLeft;
    const walk = x - startX;
    swiper.scrollLeft = scrollLeft - walk;
  };

  const scrollSlider = (direction: "left" | "right") => {
    const swiper = getSwiperElement();
    if (!swiper) return;

    (window as any).swiper = swiper;

    let movePx = 0;
    const children = Array.from(swiper.children) as HTMLElement[];

    if (direction === "right") {
      const maxVisibleSliderPos = swiper.scrollLeft + swiper.offsetWidth;

      const hiddenSlidesLeft = children.filter((slide) => slide.offsetLeft < maxVisibleSliderPos);
      const lastVisibleSlide = hiddenSlidesLeft.slice(-1)[0];

      if (lastVisibleSlide.offsetWidth > swiper.offsetWidth) {
        const nextSlide = children[hiddenSlidesLeft.length] ?? lastVisibleSlide;
        movePx = nextSlide.offsetLeft;
      } else {
        movePx = lastVisibleSlide.offsetLeft;
      }
    } else {
      const hiddenSlidesRight = children
        .filter((item) => item.offsetLeft < swiper.scrollLeft)
        .reverse();

      let totalWidth = 0;
      for (let i = 0; i < hiddenSlidesRight.length; i++) {
        totalWidth += hiddenSlidesRight[i].offsetWidth;
        if (totalWidth > swiper.offsetWidth) {
          const closestItem = hiddenSlidesRight[i - 1] ?? hiddenSlidesRight[i];
          movePx = closestItem.offsetLeft;
          break;
        }
      }
    }

    swiper.scrollTo({
      top: 0,
      left: movePx,
      behavior: "smooth",
    });
  };

  const scrollSliderLeft = () => {
    scrollSlider("left");
    const prevEl = getSwiperPrevElement();
    if (!prevEl) return;
    prevEl.disabled = true;
    setTimeout(() => {
      prevEl.disabled = false;
    }, 500);
  };

  const scrollSliderRight = () => {
    scrollSlider("right");
    const nextEl = getSwiperNextElement();
    if (!nextEl) return;
    nextEl.disabled = true;
    setTimeout(() => {
      nextEl.disabled = false;
    }, 500);
  };

  const init = () => {
    const swiper = getSwiperElement();
    const prevEl = getSwiperPrevElement();
    const nextEl = getSwiperNextElement();

    swiper?.addEventListener("mousedown", handleMouseDown);
    swiper?.addEventListener("mouseleave", handleMouseMove);
    swiper?.addEventListener("mouseup", handleMouseUp);
    swiper?.addEventListener("mousemove", handleMouseLeave);
    prevEl?.addEventListener("click", scrollSliderLeft);
    nextEl?.addEventListener("click", scrollSliderRight);
  };

  const remove = () => {
    const swiper = getSwiperElement();
    const prevEl = getSwiperPrevElement();
    const nextEl = getSwiperNextElement();

    swiper?.removeEventListener("mousedown", handleMouseDown);
    swiper?.removeEventListener("mouseleave", handleMouseMove);
    swiper?.removeEventListener("mouseup", handleMouseUp);
    swiper?.removeEventListener("mousemove", handleMouseLeave);
    prevEl?.removeEventListener("click", scrollSliderLeft);
    nextEl?.removeEventListener("click", scrollSliderRight);
  };

  return {
    init,
    remove,
    scrollSlider,
    getSwiperElement,
  };
}

import { Grid, styled, Typography } from "@avenue-8/ui-2";

export const PropertyCardContainer = styled(Grid)`
  padding: 16px 0 0 0;
`;
export const Divider = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.av8.input.borderColor};
  width: 100%;
  margin: 16px auto;
`;
export const DescriptionContainer = styled(Typography)`
  font-weight: 300;
  font-size: 14px;
  line-height: 180%;
  overflow: auto;
  min-height: 100px;
  max-height: 200px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  margin-top: 0.5rem;
`;
export const ContentLabel = styled(Typography)`
  font-size: 10px;
  line-height: 100%;
  color: ${(p) => p.theme.av8.secondaryColor};
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const ContentText = styled(Typography)`
  overflow-wrap: break-word;
  color: ${(p) => p.theme.av8.primaryColor};
  width: 100%;
`;
export const VerticalDivider = styled.div`
  background: ${(p) => p.theme.av8.dividerColor};
  width: 1px;
  margin: 0 1rem;
  margin-top: 0.25rem;
`;
export const GridRow = styled(Grid)`
  align-items: center;
  wrap: nowrap;
  width: 100%;
  gap: 0.5rem;

  div:first-of-type {
    width: 120px;
  }
`;
export const AgentInfo = styled(Typography)`
  margin-top: 0.75rem;
`;
export const MapContainer = styled.div`
  margin-top: 1rem;
  width: 100%;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  min-height: 227px;
  img {
    width: 100%;
  }
`;

import { styled } from "@avenue-8/ui-2";
import { Header, PageLayout, PageLayoutProps, LayoutRoot, HeaderFrame } from "../PageLayout";

const SolidLayoutRoot = styled(LayoutRoot)`
  background: ${(p) => p.theme.av8.background};
`;

const GrayHeaderFrame = styled(HeaderFrame)`
  background: ${(p) => p.theme.av8.backgroundAlt};
`;

export const CreateCMAPageLayout = (props: PageLayoutProps) => {
  return (
    <PageLayout
      {...props}
      HeaderComponent={Header}
      LayoutRootComponent={SolidLayoutRoot}
      HeaderFrameComponent={GrayHeaderFrame}
    />
  );
};

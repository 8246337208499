import { toast } from "@avenue-8/ui-2";
import { useMutation, useQueryClient } from "react-query";
import { appEventEmitter } from "src/events/app-event-emitter";
import { getCMAApiClient } from "src/modules/shared/apis/cma/api-factories";

export const useChangeCoverPhotoMutation = () => {
  const queryClient = useQueryClient();
  const changeCoverPhotoMutation = useMutation(
    async (params: { id: string; data: FormData }) => {
      const response = await getCMAApiClient().cMAControllerUploadCoverPhoto({
        ...params,
        images: params.data.getAll("images") as Blob[],
      });
      return [response.url];
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to upload cover photo.");
      },
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(["cmas", variables.id]);
        appEventEmitter.emit({
          eventType: "general-presentation-cover-image-added",
        });
        toast.success("Cover photo uploaded succesfully.");
      },
    }
  );
  return changeCoverPhotoMutation;
};

import { InsightItem } from "../insights-section-view";
import { INSIGHTS_DAYS, InsightViewBuilder } from "./insights-section-view-builder";
import { roundToFixed } from "./utils";

export const buildMedianSalesPriceYearComparision: InsightViewBuilder = ({ context, data }) => {
  const { medianSalesPriceChange } = data || {};
  const notEnoughData: InsightItem | null = context.editMode
    ? {
        content: "Median Sales Price Year Comparision",
        footer: "Not enough data was provided",
        mode: "not-enough-data",
      }
    : null;

  const { area, change } = medianSalesPriceChange || {};
  const hasEnoughData = medianSalesPriceChange && change;

  if (!hasEnoughData) {
    return notEnoughData;
  }

  const changePercent = roundToFixed(change * 100, 1);
  const changePercentFormated = Math.abs(changePercent).toFixed(1);
  const propertyType = data?.propertyType ?? context.sourceData.featuredProperty.propertyType;
  const businessType = data?.isLease ? "leases" : "sales";

  let copy: string;
  if (changePercent >= 1.0) {
    copy = `The median ${businessType} price in ${area} has increased by ${changePercentFormated}% year-on-year.`;
  } else if (changePercent < 1.0 && changePercent > 0.0) {
    copy = `The median ${businessType} price in ${area} has roughly stayed the same year-on-year, increasing by ${changePercentFormated}% year-on-year.`;
  } else if (changePercent < 0.0 && changePercent > -1.0) {
    copy = `The median ${businessType} price in ${area} has roughly stayed the same year-on-year, decreasing by ${changePercentFormated}% year-on-year.`;
  } else {
    copy = `The median ${businessType} price in ${area} has decreased by ${changePercentFormated}% year-on-year.`;
  }

  const getFooter = () => {
    if (propertyType) {
      return `Comparison is for ${propertyType} over the past ${INSIGHTS_DAYS} day period compared to the same period last year.`;
    } else {
      return `Comparison is over the past ${INSIGHTS_DAYS} day period compared to the same period last year.`;
    }
  };

  return {
    content: copy,
    footer: getFooter(),
    mode: "ok",
  };
};

import React from "react";
import { baseStyledThemed as styled } from "../theme";
interface TransitionPanelProps {
  aboutToChange: boolean;
  disableEventsWhenAboutToChange?: boolean;
  style?: Record<string, unknown>;
  children: React.ReactNode;
}

interface ContainerProps {
  aboutToChange: boolean;
  disableEventsWhenAboutToChange: boolean;
}

const Container = styled.div<ContainerProps>`
  pointer-events: ${(props) =>
    props.aboutToChange && props.disableEventsWhenAboutToChange ? "none" : "initial"};
  width: 100%;
  transition: 0.3s;
  opacity: ${(props) => (props.aboutToChange ? 0.3 : 1)};
  animation: ${(props) =>
    props.aboutToChange ? "fadingAnimation 1.8s infinite ease-in-out" : "none"};

  @keyframes fadingAnimation {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.4;
    }
  }
`;

export const TransitionPanel = ({
  aboutToChange,
  disableEventsWhenAboutToChange = true,
  style = {},
  children,
}: TransitionPanelProps) => {
  return (
    <Container
      style={style}
      aboutToChange={aboutToChange}
      disableEventsWhenAboutToChange={disableEventsWhenAboutToChange}
    >
      {children}
    </Container>
  );
};

import { generateRoute as route } from "../shared/utils/generate-route";

export interface EditPresentationParams {
  id: string;
}

type CMASteps = "subject-property" | "estimate" | "search" | "customize-presentation";
type GeneralPresentationSteps = "search" | "customize-presentation";
export interface EditCMAStepParams {
  id: string;
  step: CMASteps;
}

export interface EditGeneralPresentationStepParams {
  id: string;
  step: GeneralPresentationSteps;
}

const baseRoute = "/presentations";
/**
 * All module routes should be placed here.
 * We can generate and match all paths using this
 * object and even use it to register react-router <Route>.
 */
export const cmaRoutes = {
  dashboard: route<void>(baseRoute),
  // CMA
  edit: route<EditPresentationParams>(`${baseRoute}/cma/:id/edit`), //this route is used only for redirection
  editStep: route<EditCMAStepParams>(
    `${baseRoute}/cma/:id/edit/:step(subject-property|estimate|search|customize-presentation)`
  ),
  // General Presentation
  editGeneralPresentation: route<EditPresentationParams>(`${baseRoute}/general/:id/edit`), //this route is used only for redirection
  editGeneralPresentationStep: route<EditGeneralPresentationStepParams>(
    `${baseRoute}/general/:id/edit/:step(search|customize-presentation)`
  ),
};

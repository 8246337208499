import { calculateMonthlies } from "./shared/recurring-costs-helper";
import { buildSectionView } from "./section-view-builder";
import {
  PropertyPhoto,
  PresentationView,
  PresentationSourceData,
  PresentationEditData,
} from "./models";
import { getDaysOnMarket } from "./shared/get-days-on-market";

export interface PresentationViewBuilderContext {
  sourceData: PresentationSourceData;
  editMode: boolean;
}

export function buildPresentationView(
  presentation: PresentationEditData,
  editMode = false
): PresentationView {
  const id = presentation.id;
  const { sourceData, sections } = presentation;
  const { featuredProperty, comparableProperties } = sourceData;

  let sectionViews = sections.map(({ config, data }) => {
    const view = buildSectionView({
      sectionConfig: config,
      sectionData: data,

      context: { sourceData, editMode },
    });

    view.navTitle = config.navTitle;
    return view;
  });

  if (!editMode) {
    //this will ensure that we don't include unnecessary sections when building the final presentation
    sectionViews = sectionViews.filter((x) => x.visibility !== false);
  }

  const subjectProperty = (({
    id,
    addressLine1,
    addressLine2,
    listDate,
    soldDate,
    photos,
    state,
    zipCode,
    city,
    currentPrice: listPrice,
    soldPrice,
    recurringCosts,
  }) => ({
    id,
    state: state ?? "-",
    addressLine1: addressLine1 ?? "No address",
    addressLine2: addressLine2 ?? "",
    listPrice,
    soldPrice,
    listDate,
    daysOnMarket: getDaysOnMarket(listDate, soldDate),
    totalMonthlies: calculateMonthlies(recurringCosts ?? []),
    zipCode: zipCode ?? "-",
    city: city ?? "-",
    photos: photos?.[0]?.url ?? null,
  }))(featuredProperty);

  const galleries: { [key: string]: Array<PropertyPhoto> } = {};
  galleries[featuredProperty.id] = featuredProperty.photos ?? [];
  comparableProperties.forEach((p) => {
    galleries[p.id] = p.photos ?? [];
  });

  const {
    agent,
    client,
    priceRange,
    clientDisplayName,
    updatedAt,
    presentationName,
    presentationType,
  } = sourceData;
  return {
    client: { ...client, name: clientDisplayName },
    agent,
    id,
    sections: sectionViews,
    presentationName,
    presentationType,
    galleries,
    subjectProperty,
    priceRange,
    updatedAt,
  };
}

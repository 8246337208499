import * as React from "react";
import { Dialog, styled } from "@avenue-8/ui-2";

const Container = styled.div`
  width: 100%;
  max-height: 100vh;
`;

type FullScreenDialogProps = {
  toggleFullScreen: (open: boolean) => void;
  children: React.ReactNode;
  open: boolean;
};

export default function FullScreenDialog({
  toggleFullScreen,
  open,
  children,
}: FullScreenDialogProps) {
  const handleClose = () => {
    toggleFullScreen(!open);
  };

  return (
    <div>
      {open ? (
        <Dialog fullScreen open={open} onClose={handleClose}>
          <Container>{children}</Container>
        </Dialog>
      ) : (
        children
      )}
    </div>
  );
}

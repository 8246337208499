/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MetricResponse,
    MetricResponseFromJSON,
    MetricResponseFromJSONTyped,
    MetricResponseToJSON,
} from './';

/**
 * 
 * @export
 * @interface AggregateMetricResponse
 */
export interface AggregateMetricResponse {
    /**
     * 
     * @type {string}
     * @memberof AggregateMetricResponse
     */
    dimension: string;
    /**
     * 
     * @type {Array<MetricResponse>}
     * @memberof AggregateMetricResponse
     */
    metrics: Array<MetricResponse>;
}

export function AggregateMetricResponseFromJSON(json: any): AggregateMetricResponse {
    return AggregateMetricResponseFromJSONTyped(json, false);
}

export function AggregateMetricResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregateMetricResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dimension': json['dimension'],
        'metrics': ((json['metrics'] as Array<any>).map(MetricResponseFromJSON)),
    };
}

export function AggregateMetricResponseToJSON(value?: AggregateMetricResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dimension': value.dimension,
        'metrics': ((value.metrics as Array<any>).map(MetricResponseToJSON)),
    };
}



export const SuccessIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="79"
      height="79"
      viewBox="0 0 79 79"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.5 79C61.3152 79 79 61.3152 79 39.5C79 17.6848 61.3152 0 39.5 0C17.6848 0 0 17.6848 0 39.5C0 61.3152 17.6848 79 39.5 79Z"
        fill="#F3F3FA"
      />
      <path
        d="M23 40L35 52L58.5 28.5"
        stroke="#696B86"
        strokeWidth="4"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UserInfo
     */
    avatar: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserInfo
     */
    isFirstLogin: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserInfo
     */
    completedOnboardingAt: Date | null;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['fullName'],
        'avatar': json['avatar'],
        'isFirstLogin': json['isFirstLogin'],
        'completedOnboardingAt': (json['completedOnboardingAt'] === null ? null : new Date(json['completedOnboardingAt'])),
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'avatar': value.avatar,
        'isFirstLogin': value.isFirstLogin,
        'completedOnboardingAt': (value.completedOnboardingAt === null ? null : value.completedOnboardingAt.toISOString()),
    };
}



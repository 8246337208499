import React from "react";
import { styled } from "@avenue-8/ui-2";

interface PasswordReqProps {
  password: string;
}

//"^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$"
export const letterRegex = new RegExp("^(?=.*[A-Za-z])");
export const numberRegex = new RegExp("^(?=.*?[0-9])");
export const specialRegex = new RegExp("^(?=.*?[#?!@$%^&*-])");
export const atleast8Char = new RegExp("^.{8,}");

const RequirementsContainer = styled.div`
  padding: 12px 0;
  font-family: "Roboto";
`;

export const PasswordRequirements = ({ password }: PasswordReqProps) => {
  return (
    <RequirementsContainer>
      Make sure your password is:
      <ul>
        <li style={{ color: atleast8Char.test(password) ? "#25C281" : undefined }}>
          8 characters minimum
        </li>
        <li style={{ color: letterRegex.test(password) ? "#25C281" : undefined }}>
          At least 1 letter
        </li>
        <li style={{ color: numberRegex.test(password) ? "#25C281" : undefined }}>
          At least 1 number
        </li>
        <li style={{ color: specialRegex.test(password) ? "#25C281" : undefined }}>
          At least 1 special character
        </li>
      </ul>
    </RequirementsContainer>
  );
};

import * as React from "react";
import { Typography, Button, Checkbox, CloseIcon } from "@avenue-8/ui-2";
import { TooltipRenderProps } from "react-joyride";
import {
  TooltipCard,
  CustomCardContent,
  CloseButton,
  CustomCardActions,
  CustomFormControlLabel,
  CardActionsButtons,
} from "./GuidedTourTooltip.styles";
import { useGuidedTourLogic } from "./useGuidedTourLogic";

export const GuidedTourTooltip = ({
  index,
  step,
  tooltipProps,
  primaryProps,
  backProps,
  skipProps,
  size,
}: TooltipRenderProps) => {
  const {
    state: { dontShowAgain },
    actions: { setDontShowAgain },
  } = useGuidedTourLogic();
  return (
    <TooltipCard {...tooltipProps}>
      <CustomCardContent>
        <Typography variant="h5" component="h2">
          {step.title}
        </Typography>
        <Typography variant="body2">{step.content}</Typography>
        <CloseButton {...skipProps}>
          <CloseIcon />
        </CloseButton>
      </CustomCardContent>
      <CustomCardActions>
        <CustomFormControlLabel
          htmlFor="dont-show-again"
          label="Don't show again"
          control={
            <Checkbox
              name="dont-show-again"
              id="dont-show-again"
              onChange={(e) => setDontShowAgain(e.target.checked)}
              value={dontShowAgain}
              checked={dontShowAgain}
            />
          }
        />
        <CardActionsButtons>
          {step.showSkipButton && (
            <Button color="secondary" variant="text" {...skipProps}>
              skip
            </Button>
          )}
          {index > 0 && (
            <Button variant="text" {...backProps}>
              Back
            </Button>
          )}
          <Button {...primaryProps}>
            {index + 1 === size ? "Finish" : "Next"} {index + 1}/{size}
          </Button>
        </CardActionsButtons>
      </CustomCardActions>
    </TooltipCard>
  );
};

import { useAuthContext } from "./AuthContext";

interface AuthenticatedProps {
  notAuthenticatedContent?: React.ReactNode;
  children: any;
}

export const Authenticated = function ({ children, notAuthenticatedContent }: AuthenticatedProps) {
  const {
    state: { user },
  } = useAuthContext();
  if (!user) return notAuthenticatedContent || null;
  return children;
};

import {
  PresentationView,
  PresentationConfig,
  PresentationEditData,
} from "../../../presentation/presentation-generation-logic/models";

export class HttpApiClient {
  private host: string;
  private defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  };
  constructor() {
    this.host =
      process.env.REACT_APP_PRESENTATION_API_URL || "http://localhost:3001/cma-presentations";
  }

  private async fetchJSON(
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
    url: string,
    body: any,
    options: {
      emptyResponse?: boolean;
    } = {}
  ) {
    const token = localStorage.getItem("authToken");
    const headers: { [key: string]: string } = { ...this.defaultHeaders };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const opts = {
      method,
      headers,
      body: body != null ? JSON.stringify(body) : null,
    };

    const emptyResponse = options.emptyResponse === true;

    const response = await fetch(url, opts);
    if (response.status >= 200 && response.status < 300) {
      if (!emptyResponse) return response.json();
    } else {
      throw new Error(`Error. Status: ${response.status}`);
    }
  }

  async getPresentationEdit(id: string): Promise<PresentationEditData> {
    const url = `${this.host}/${encodeURIComponent(id)}/edit`;
    const result = await this.fetchJSON("GET", url, null);
    return result;
  }
  async getPresentationView(id: string): Promise<PresentationView> {
    const url = `${this.host}/${encodeURIComponent(id)}/view`;
    const result = await this.fetchJSON("GET", url, null);
    return result;
  }
  async putPresentationConfig(config: PresentationConfig): Promise<void> {
    const url = `${this.host}/${encodeURIComponent(config.id)}/edit`;
    await this.fetchJSON("PATCH", url, config, { emptyResponse: true });
  }
}

import * as React from "react";
import {
  ActionInvokerContext,
  CreateAgentAccountContextProvider,
} from "./CreateAgentAccountContext";
import { useAppContext } from "../../../../AppContext";
import { CreateAgentAccount, CreateAgentAccountProps } from "./CreateAgentAccount";
import { toast } from "react-toastify";

export const CreateAgentAccountWithContext = (props: CreateAgentAccountProps) => {
  const { actions } = useAppContext();
  return React.useMemo(() => {
    const context: ActionInvokerContext = {
      confirm: async ({ title, message, confirmButtonText }) => {
        return actions.confirm({ message, title, confirmButtonText });
      },
      wrapLoadPromise: (promise) => {
        actions.watchPromise(promise);
        return promise;
      },
      showToast: toast,
    };

    return (
      <CreateAgentAccountContextProvider invokerContext={context}>
        <CreateAgentAccount {...props} />
      </CreateAgentAccountContextProvider>
    );
  }, [actions, props]);
};

declare const window: any;

export interface HeapTracker {
  track: (eventName: string, payload?: any) => void;
  identify: (data: { userId: any; email: string }) => void;
}

export class DebugHeapTracker implements HeapTracker {
  track(eventName: string, payload: any) {
    if (process.env.REACT_APP_HIDE_ALL_APP_EVENTS) return;
    console.log(
      `[Heap] Event: ${eventName}, Payload: ${payload ? JSON.stringify(payload) : "empty"}`
    );
  }

  identify(data: { userId: any; email: string }) {
    console.log(`[Heap] Identify: ${JSON.stringify(data)}`);
  }
}

export class DefaultHeapTracker implements HeapTracker {
  track(eventName: string, payload: any) {
    if (!window.heap) console.warn("Heap is not ready to track");
    else {
      window.heap.track(eventName, payload);
    }
  }

  identify(data: { userId: any; email: string }) {
    const heap = window.heap;
    if (!heap) {
      console.warn("Heap is not ready to identify");
      return;
    }
    heap.identify(data.userId);
    heap.addUserProperties({ email: data.email?.toLowerCase() });
  }
}

export class HeapCombinedTracker implements HeapTracker {
  constructor(private facades: HeapTracker[]) {}
  track(eventName: string, payload: any) {
    this.facades.forEach((h) => {
      try {
        h.track(eventName, payload);
      } catch (e) {
        console.error(e);
      }
    });
  }

  identify(data: { userId: any; email: string }) {
    this.facades.forEach((h) => {
      try {
        h.identify(data);
      } catch (e) {
        console.error(e);
      }
    });
  }
}

export class SafeHeapTracker implements HeapTracker {
  constructor(private inner: HeapTracker) {}
  track(eventName: string, payload: any) {
    try {
      this.inner.track(eventName, payload);
    } catch (e) {
      console.error(e);
    }
  }

  identify(data: { userId: any; email: string }) {
    try {
      this.inner.identify(data);
    } catch (e) {
      console.error(e);
    }
  }
}

import { CMADto } from "./../../../../../shared/apis/cma/generated/models/CMADto";
import { SetEstimatedPriceRequestDto } from "../../../../../shared/apis/cma/generated";
import { stripNonNumericNumbers, strToNullableInt } from "../../../../../shared/utils/converters";
import { EstimatePriceFormModel } from "./components/EstimatePriceForm";

const normalizeNumber = (input: string | null | undefined) =>
  strToNullableInt(stripNonNumericNumbers(input));

export const convertEstimatePriceFormModelToEstimatedPriceDto = (data: EstimatePriceFormModel) => {
  const estimatePrice: SetEstimatedPriceRequestDto = {
    max: normalizeNumber(data.estimatedPriceMax) ?? null,
    min: normalizeNumber(data.estimatedPriceMin) ?? null,
  };

  return estimatePrice;
};

export const convertCmaDtoToEstimatePriceFormModel = (cma: CMADto) => {
  const estimatePrice: EstimatePriceFormModel = {
    cmaId: cma.id,
    estimatedPriceMin: cma?.estimatedPriceMin?.toString() ?? "",
    estimatedPriceMax: cma?.estimatedPriceMax?.toString() ?? "",
    notes: "",
  };

  return estimatePrice;
};

export const validateMinMax = (min: any, max: any) => {
  const minValue = normalizeNumber(min?.trim());
  const maxValue = normalizeNumber(max?.trim());
  if (min === "" && max === "") return true;

  if (min == null || max == null) return "Both values must be provided.";

  if (Number(minValue) <= 0 || Number(maxValue) <= 0) {
    return "Minimum and maximum price must be greater than 0";
  }

  if (Number(maxValue) < Number(minValue)) {
    return "Maximum price must be greater than or equal to minimum price.";
  }

  if ((minValue && !maxValue) || (maxValue && !minValue)) {
    return "Invalid estimate range";
  }

  return true;
};

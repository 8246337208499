import { GeocodeResult, getGeocode, getZipCode } from "use-places-autocomplete";

interface GeocodeResultItem {
  country?: string;
  state?: string;
  city?: string;
  neighborhood?: string;
  streetName?: string;
  streetNumber?: string;
  premise?: string;
  subpremise?: string;
  zipCode?: string | null;
  lat?: string;
  lng?: string;
  formattedAddress?: string;
}

const parseGeocodeResult = async (result: GeocodeResult): Promise<GeocodeResultItem> => {
  const geocodeResult = result;
  let country = "",
    state = "",
    city = "",
    neighborhood = "",
    streetName = "",
    streetNumber = "",
    premise = "",
    subpremise = "";
  // list of possible item.types from geocode api https://developers.google.com/maps/documentation/geocoding/requests-geocoding#Types
  geocodeResult.address_components.forEach((item: any) => {
    country = country ? country : item.types.includes("country") ? item?.long_name : "";
    state = state
      ? state
      : item.types.includes("administrative_area_level_1")
      ? item?.short_name
      : "";
    city = city ? city : item.types.includes("locality") ? item?.long_name : "";
    neighborhood = neighborhood
      ? neighborhood
      : item.types.includes("neighborhood")
      ? item?.long_name
      : "";
    streetName = streetName ? streetName : item.types.includes("route") ? item?.long_name : "";
    streetNumber = streetNumber
      ? streetNumber
      : item.types.includes("street_number")
      ? item?.long_name
      : "";
    premise = premise ? premise : item.types.includes("premise") ? item?.long_name : "";
    subpremise = subpremise ? subpremise : item.types.includes("subpremise") ? item?.long_name : "";
  });
  const zipCode = (await getZipCode(geocodeResult, false)) || "";

  const lat = Number(geocodeResult.geometry.location.lat()).toFixed(5);
  const lng = Number(geocodeResult.geometry.location.lng()).toFixed(5);
  const parsedResult = {
    country,
    state,
    city,
    neighborhood,
    streetName,
    streetNumber,
    premise,
    subpremise,
    zipCode,
    lat,
    lng,
    formattedAddress: geocodeResult.formatted_address || "",
  };
  return parsedResult;
};

export const geocodeSearch = async (searchTerm: string): Promise<GeocodeResultItem | null> => {
  try {
    const geocodeResult = await getGeocode({ address: searchTerm });
    if (geocodeResult && geocodeResult.length > 0) {
      const parsedGeocodeResult = await parseGeocodeResult(geocodeResult[0]);
      return parsedGeocodeResult;
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

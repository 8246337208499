import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  InputLabel,
  Typography,
  FormHelperText,
  styled,
  PlusIcon,
  MinusIcon,
  Button,
  Tooltip,
} from "@avenue-8/ui-2";
import NumberFormat from "react-number-format";
import { getTextFieldErrors, TextFieldBase } from "../TextFieldBase";
import { MAX_INTEGER_SMALL_VALUE } from "src/modules/shared/constants";

interface Props {
  id?: string;
  name?: string;
  description?: string;
  step?: number;
  showFixedErrorMessage?: boolean;
  coords:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

const ErrorMessage = styled(FormHelperText)`
  margin-top: 0.25rem;
  height: 0.5rem;
  color: ${(p) => p.theme.av8.dangerColor};
`;

const RadiusContainer = styled.div`
  display: flex;
  position: relative;
  align-items: stretch;
`;

const PlusMinusContainer = styled.div`
  height: 48px;
  position: absolute;
  display: flex;
  align-items: center;
  right: 0.25rem;
  top: 0;
`;

const PlusMinusButton = styled(Button)`
  border: none !important;
  width: 48px;
  min-width: 48px;
  height: calc(48px - 0.25rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

const RadiusInput = styled(NumberFormat)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const RadiusField = (props: Props) => {
  const {
    id = "radius-input",
    name = "radius",
    step = 1,
    description,
    showFixedErrorMessage,
    coords,
  } = props;
  const { errors, setValue } = useFormContext();
  const errorMessage = errors[name]?.message;
  const fieldErrors = !showFixedErrorMessage ? getTextFieldErrors({ errors, name: name }) : {};
  const inputRef = React.useRef(document.getElementById(id) as HTMLInputElement);

  React.useEffect(() => {
    inputRef.current = document.getElementById(id) as HTMLInputElement;
  }, [id]);

  function handleChangeInputValue(value: number) {
    inputRef.current.value =
      value === 0
        ? ""
        : value.toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 2,
          });
    setValue(name, value);
    const event = new Event("change", { bubbles: true });
    inputRef.current.dispatchEvent(event);
  }

  const handlePlusClick = () => {
    const oldValue = parseFloat(inputRef.current.value || "0");
    const newValue = Math.min(oldValue + step, MAX_INTEGER_SMALL_VALUE);
    handleChangeInputValue(newValue);
  };

  const handleMinusClick = () => {
    const oldValue = parseFloat(inputRef.current.value || "0");
    const newValue = Math.max(oldValue - step, 0);
    handleChangeInputValue(newValue);
  };

  const tooltipTitle = !coords ? "You need to allow location access to use this filter." : "";

  return (
    <>
      <InputLabel htmlFor={name}>Radius</InputLabel>
      <Controller
        name={name}
        render={({ ref, onChange, ...rest }) => (
          <Tooltip title={tooltipTitle}>
            <RadiusContainer>
              <RadiusInput
                customInput={TextFieldBase}
                {...rest}
                {...fieldErrors}
                disabled={!coords}
                min={0}
                max={MAX_INTEGER_SMALL_VALUE}
                inputRef={ref}
                errors={errors}
                id={id}
                autoComplete="off"
                suffix=" miles"
                onValueChange={(v) => onChange(v.value)}
                onKeyUp={(e) => {
                  if (e.key === "ArrowUp") {
                    return handlePlusClick();
                  }
                  if (e.key === "ArrowDown") {
                    return handleMinusClick();
                  }
                }}
                isAllowed={({ value }) =>
                  (Number(value ?? 0) >= 0 || value === "") &&
                  Number(value) <= MAX_INTEGER_SMALL_VALUE
                }
              />
              <PlusMinusContainer>
                <PlusMinusButton type="button" onClick={handleMinusClick} disabled={!coords}>
                  <MinusIcon size={24} />
                </PlusMinusButton>
                <PlusMinusButton type="button" onClick={handlePlusClick} disabled={!coords}>
                  <PlusIcon size={24} />
                </PlusMinusButton>
              </PlusMinusContainer>
            </RadiusContainer>
          </Tooltip>
        )}
      />
      {Boolean(description) && <Typography variant="body2">{description}</Typography>}
      {showFixedErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

import { styled } from "@avenue-8/ui-2";
import { SmartUserMenu } from "../SmartUserMenu";
import { media } from "../../../shared/css-snippets";

export const GridHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${(p) => p.theme.av8.typography.primaryColor};
`;

export const ListControl = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0.75rem;
  gap: 0.5rem;
  cursor: pointer;
  vertical-align: bottom;

  &:first-of-type {
    margin-left: 0;
    ${() => media.mobileDown} {
      span {
        display: none;
      }
    }
  }
  &:last-of-type {
    margin-right: 0;
  }
`;

export const StyledUserMenu = styled(SmartUserMenu)`
  margin: 0px auto;
`;

export const PresentationTag = styled.p`
  font-size: 14px;
  color: ${(p) => p.theme.av8.secondaryColor};
`;

export const FilterTag = styled.span`
  font-size: 14px;
  color: ${(p) => p.theme.av8.secondaryColor};
`;

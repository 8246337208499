import * as React from "react";
import {
  Select,
  InputLabel,
  TextFieldProps,
  Autocomplete,
  AutocompleteRenderInputParams,
  ListItem,
  Checkbox,
  CheckboxIcon,
  FormHelperText,
  styled,
  useTheme,
} from "@avenue-8/ui-2";
import { KeyboardArrowDown, Close } from "@mui/icons-material";
import { Controller, useFormContext } from "react-hook-form";
import { TextFieldBase } from "../TextFieldBase";
import { usePropertyStylesQueries } from "../../queries/usePropertyStyleQueries";

const CustomAutocomplete = styled(Autocomplete)`
  .MuiInput-root {
    padding: 0 2rem 0 1rem !important;
  }
` as typeof Autocomplete;

const AutoCompleteTag = styled.div`
  padding: 6px 0;
`;

const AutocompleteOption = styled(ListItem)`
  &.MuiListItem-root {
    padding: 1rem 2rem;
  }
`;

export type BuildingStyleAutocompleteProps = {
  label?: string;
  name?: string;
  autocompleteProps?: {
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  };
};
const BuildingStyleAutocomplete = (props: BuildingStyleAutocompleteProps) => {
  const { label = "Building Style", name = "buildingStyles", autocompleteProps } = props;
  const { errors, setValue, getValues } = useFormContext();
  const theme = useTheme();
  const { propertyStylesQuery } = usePropertyStylesQueries();
  const buildingStyles = propertyStylesQuery.data?.propertyStyles || [];

  const handleChangeBuildingStyles = (buildingStyles: string[]) => {
    setValue("buildingStyles", [...buildingStyles]);

    const buildingStyleOther = getValues("buildingStyleOther") || "";

    if (buildingStyles.includes("Other")) {
      if (!buildingStyleOther.trim()) {
        setValue("buildingStyleOther", "Other");
      }
    } else if (buildingStyleOther.trim()) {
      setValue("buildingStyleOther", "");
    }
  };

  return (
    <>
      <InputLabel htmlFor={name} id="building-style-label">
        {label}
      </InputLabel>
      {buildingStyles?.length ? (
        <Controller
          name={name}
          render={({ onChange, value, ...rest }) => (
            <CustomAutocomplete
              multiple
              id={name}
              fullWidth
              clearIcon={<Close style={{ color: theme.av8.primaryColor }} fontSize={"small"} />}
              popupIcon={<KeyboardArrowDown style={{ color: theme.av8.primaryColor }} />}
              disableCloseOnSelect
              limitTags={3}
              value={value ?? []}
              renderTags={(values: string[]) => {
                return values?.map((value, index) => (
                  <AutoCompleteTag key={index}>
                    {
                      <>
                        {value}
                        {index === values?.length - 1 ? <span></span> : <span>,&nbsp;</span>}
                      </>
                    }
                  </AutoCompleteTag>
                ));
              }}
              getOptionLabel={(option: string) => option}
              {...rest}
              onBlur={(e: any) => {
                autocompleteProps?.onBlur?.(e);
                rest.onBlur();
              }}
              options={buildingStyles.map((x) => x.style) ?? []}
              renderOption={(liProps, buildingStyle: string, { selected }) => (
                <AutocompleteOption {...liProps}>
                  <Checkbox
                    icon={<CheckboxIcon color="transparent" />}
                    checkedIcon={<CheckboxIcon color={theme.av8.primaryColor} />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {buildingStyle}
                </AutocompleteOption>
              )}
              renderInput={(params: AutocompleteRenderInputParams) => {
                return (
                  <TextFieldBase
                    error={!!errors.buildingStyles}
                    {...params}
                    inputProps={{ ...params.inputProps }}
                  />
                );
              }}
              onChange={(e, values) => {
                onChange(e);
                handleChangeBuildingStyles(values as string[]);
              }}
            />
          )}
        />
      ) : (
        <Select value="" />
      )}
      <FormHelperText error={!!errors[name]}>{errors[name]?.message}</FormHelperText>
    </>
  );
};

export type BuildingStyleOtherProps = {
  label?: string;
  name?: string;
  textFieldProps?: TextFieldProps;
};
const BuildingStyleOther = (props: BuildingStyleOtherProps) => {
  const { label = "If other style, specify", name = "buildingStyleOther", textFieldProps } = props;
  const { errors, setValue, getValues } = useFormContext();

  const handleChangeBuildingStylesOther = (buildingStyleOther: string) => {
    const buildingStyles = getValues("buildingStyles") as string[];

    if (buildingStyleOther.trim()) {
      if (!buildingStyles.includes("Other")) {
        setValue("buildingStyles", [...buildingStyles, "Other"]);
      }
    } else {
      setValue(
        "buildingStyles",
        buildingStyles.filter((style) => style !== "Other")
      );
    }
  };

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Controller
        name={name}
        rules={{ required: false }}
        render={({ ref, onChange, ...rest }) => (
          <TextFieldBase
            placeholder="N/A"
            errors={errors}
            inputRef={ref}
            {...rest}
            onBlur={(e) => {
              textFieldProps?.onBlur?.(e);
              rest.onBlur();
            }}
            onChange={(e) => {
              onChange(e);
              handleChangeBuildingStylesOther(e.target.value);
            }}
          />
        )}
      />
    </>
  );
};

export const BuildingStyleField = {
  Autocomplete: BuildingStyleAutocomplete,
  Other: BuildingStyleOther,
};

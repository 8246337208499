import { Controller } from "react-hook-form";
import { Switch, Typography, EnergyIcon } from "@avenue-8/ui-2";
import { SwitchContainer, SwitchLabel } from "./SmartSwitch.styles";

interface Props {
  name: string;
  label: string;
  description: string;
  showIcon?: boolean;
}

export const SmartSwitch = ({ name, label, description, showIcon }: Props) => {
  return (
    <SwitchContainer>
      <div>
        <SwitchLabel>
          {showIcon && <EnergyIcon />}
          <Typography variant="body1">
            <strong>{label}</strong>
          </Typography>
        </SwitchLabel>
        <Typography variant="body2">{description}</Typography>
      </div>
      <div>
        <Controller
          name={name}
          render={({ value, onChange }) => (
            <Switch label="" onChange={onChange} name={name} state={value} />
          )}
        />
      </div>
    </SwitchContainer>
  );
};

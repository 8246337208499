import { styled } from "@avenue-8/ui-2";

export const CoverPhotoContainer = styled.div`
  height: 100%;
  min-height: 180px;
  position: relative;
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 auto 16px;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }

  div {
    height: 180px;
  }
`;
export const Container = styled.div`
  width: 100%;
`;
export const ThumbnailsContainer = styled.div`
  margin: 8px auto 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  min-height: 48px;
  overflow: hidden;
  position: relative;
`;
export const ThumbnailsContainerExtended = styled(ThumbnailsContainer)<{ $expanded?: boolean }>`
  min-height: 0;
  max-height: ${(p) => (p.$expanded ? "100%" : "0px")};
`;
export const ThumbImage = styled.div`
  height: 0;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer;
  margin: 0;
  box-sizing: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px auto 0;
`;
export const ActionButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${(p) => p.theme.av8.secondaryColor};
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  padding: 8px 0;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;

    &[data-meta-action="add-more-photos"] {
      cursor: not-allowed;
    }

    &[data-meta-action="see-more-photos"] {
      cursor: default;
    }
  }
`;

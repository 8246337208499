import { InsightItem } from "../insights-section-view";
import { INSIGHTS_DAYS, InsightViewBuilder } from "./insights-section-view-builder";

export const buildAverageLivingSpaceSqftPrice: InsightViewBuilder = ({ context, data }) => {
  const { averageSalesPricePerSquareFoot } = data || {};
  const notEnoughData: InsightItem | null = context.editMode
    ? {
        content: "Average Living Space Sqft Price",
        footer: "Not enough data was provided",
        mode: "not-enough-data",
      }
    : null;

  if (!averageSalesPricePerSquareFoot) return notEnoughData;

  const { area, livingSpace, lot } = averageSalesPricePerSquareFoot;
  const lotF = lot ? Math.round(lot).toFixed(0) : undefined;
  const livingSpaceF = livingSpace ? Math.round(livingSpace).toFixed(0) : undefined;
  if (!lot && !livingSpace) return notEnoughData;
  const propertyType = data?.propertyType ?? context.sourceData.featuredProperty.propertyType;
  let copy;
  if (lot && livingSpace) {
    copy = `${area} averages $${livingSpaceF}/sq. ft. of living space and $${lotF}/sq. ft. of lot space.`;
  } else if (lot) {
    copy = `${area} averages $${lotF}/sq. ft. of lot space.`;
  } else {
    copy = `${area} averages $${livingSpaceF}/sq. ft. of living space.`;
  }

  const getFooter = () => {
    if (propertyType) {
      return `Price/sq ft calculated over the last ${INSIGHTS_DAYS} days for ${propertyType}.`;
    } else {
      return `Price/sq ft calculated over the last ${INSIGHTS_DAYS} days.`;
    }
  };

  return {
    content: copy,
    footer: getFooter(),
    mode: "ok",
  };
};

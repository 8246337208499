import { useEffect } from "react";

export function useBodyStyle(style: any) {
  useEffect(() => {
    let key: any;
    for (key in style) {
      window.document.body.style[key as any] = style[key];
    }
    return () => {
      window.document.body.style[key as any] = "";
    };
  }, [style]);
}

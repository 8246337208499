import { RecurringCostItemDto } from "src/modules/shared/apis/cma/generated";

export const calculateMonthlies = (recurringCosts: RecurringCostItemDto[]): number => {
  return recurringCosts.reduce((acc, curr) => {
    const value = curr.value != null ? Number(curr.value) : 0;
    if (curr.frequency === "yearly") {
      return acc + value / 12;
    }
    return acc + value;
  }, 0);
};

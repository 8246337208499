import copy from "copy-to-clipboard";

type Scope = "local" | "external";

export const copyToClipboard = (path: string, scope: Scope = "external") => {
  let baseUrl = path ?? "";
  if (scope === "local") {
    baseUrl = `${window.location.origin}${path}`;
  }
  copy(baseUrl);
};

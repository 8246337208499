import { styled } from "@avenue-8/ui-2";

export const SideBySideContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
export const SwiperContainer = styled.div<{ $isDraggingOver?: boolean }>`
  background-color: ${(p) =>
    p.$isDraggingOver ? p.theme.av8.backgroundAlt : p.theme.av8.background};
  display: flex;
  overflow-x: hidden;
  padding: 0;
  position: relative;

  & .MuiTypography-body1 {
    font-size: 0.875rem;
  }

  [data-rbd-draggable-id] {
    top: auto !important;
    z-index: 100;
  }

  &._swiper-container {
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;
    /* Add this to css-snippets later */
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;
export const FloatOverContainer = styled.div`
  display: inline-block;
  background-color: ${(p) => p.theme.av8.backgroundAlt};
`;
export const DragHandleContainer = styled.div<{ $isDragging?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  z-index: 1000;
  svg {
    color: ${(p) => p.theme.av8.primaryColor};
    cursor: ${(p) => (p.$isDragging ? "move" : "pointer")};
  }
`;
export const DraggableContainer = styled.div<{ $isExpanded: boolean; $isDragging: boolean }>`
  background-color: ${(p) => (p.$isDragging ? p.theme.av8.backgroundAlt : p.theme.av8.background)};
  position: relative;
  border-right: ${(p) => (p.$isDragging ? "none" : `1px solid ${p.theme.av8.dividerColor}`)};
  ${(p) => !p.$isDragging && !p.$isExpanded && "cursor: pointer"};
  width: ${(p) => (p.$isExpanded ? "744px" : "218px")};
`;
export const DragActions = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(p) => (p.$isExpanded ? "space-between" : "flex-end")};
  align-items: center;
  padding: ${(p) => (p.$isExpanded ? "0 1.5rem" : "0 1.5rem 1rem 1.5rem")};
`;
export const CollapseButtonContainer = styled.div`
  flex: 1;
  order: 1;
`;
export const InternalDragActions = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(p) => (p.$isExpanded ? "flex-end" : "space-between")};
  order: ${(p) => (p.$isExpanded ? 2 : 1)};
  flex: 1;
  gap: 1.5rem;
`;
export const CompSourceContainer = styled.div<{ $isExpanded: boolean }>`
  padding: ${(p) => (p.$isExpanded ? "0" : "1rem 0.5rem 1rem 0.5rem")};
  order: ${(p) => (p.$isExpanded ? 1 : 2)};
  width: ${(p) => (p.$isExpanded ? "initial" : "100%")};
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const DragContent = styled.div<{ $isExpanded: boolean }>`
  height: 720px;
  width: ${(p) => (p.$isExpanded ? "744px" : "218px")};
`;

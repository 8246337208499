/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetTemplateResponseDto,
    GetTemplateResponseDtoFromJSON,
    GetTemplateResponseDtoToJSON,
    ListTemplateResponseDto,
    ListTemplateResponseDtoFromJSON,
    ListTemplateResponseDtoToJSON,
    UpsertTemplateRequestDto,
    UpsertTemplateRequestDtoFromJSON,
    UpsertTemplateRequestDtoToJSON,
    UpsertTemplateResponseDto,
    UpsertTemplateResponseDtoFromJSON,
    UpsertTemplateResponseDtoToJSON,
} from '../models';

export interface TemplateControllerDeleteTemplateRequest {
    id: string;
}

export interface TemplateControllerGetTemplateRequest {
    id: string;
}

export interface TemplateControllerUpsertTemplateRequest {
    upsertTemplateRequestDto: UpsertTemplateRequestDto;
}

/**
 * 
 */
export class PresentationTemplatesApi extends runtime.BaseAPI {

    /**
     * Delete a presentation template.
     */
    async templateControllerDeleteTemplateRaw(requestParameters: TemplateControllerDeleteTemplateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling templateControllerDeleteTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a presentation template.
     */
    async templateControllerDeleteTemplate(requestParameters: TemplateControllerDeleteTemplateRequest): Promise<void> {
        await this.templateControllerDeleteTemplateRaw(requestParameters);
    }

    /**
     * Returns all templates for the logged-in user.
     */
    async templateControllerGetLoggedUserTemplatesRaw(): Promise<runtime.ApiResponse<ListTemplateResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations-templates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTemplateResponseDtoFromJSON(jsonValue));
    }

    /**
     * Returns all templates for the logged-in user.
     */
    async templateControllerGetLoggedUserTemplates(): Promise<ListTemplateResponseDto> {
        const response = await this.templateControllerGetLoggedUserTemplatesRaw();
        return await response.value();
    }

    /**
     * Get presentation template.
     */
    async templateControllerGetTemplateRaw(requestParameters: TemplateControllerGetTemplateRequest): Promise<runtime.ApiResponse<GetTemplateResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling templateControllerGetTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations-templates/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetTemplateResponseDtoFromJSON(jsonValue));
    }

    /**
     * Get presentation template.
     */
    async templateControllerGetTemplate(requestParameters: TemplateControllerGetTemplateRequest): Promise<GetTemplateResponseDto> {
        const response = await this.templateControllerGetTemplateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Insert or create a presentation template.
     */
    async templateControllerUpsertTemplateRaw(requestParameters: TemplateControllerUpsertTemplateRequest): Promise<runtime.ApiResponse<UpsertTemplateResponseDto>> {
        if (requestParameters.upsertTemplateRequestDto === null || requestParameters.upsertTemplateRequestDto === undefined) {
            throw new runtime.RequiredError('upsertTemplateRequestDto','Required parameter requestParameters.upsertTemplateRequestDto was null or undefined when calling templateControllerUpsertTemplate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cma-presentations-templates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertTemplateRequestDtoToJSON(requestParameters.upsertTemplateRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsertTemplateResponseDtoFromJSON(jsonValue));
    }

    /**
     * Insert or create a presentation template.
     */
    async templateControllerUpsertTemplate(requestParameters: TemplateControllerUpsertTemplateRequest): Promise<UpsertTemplateResponseDto> {
        const response = await this.templateControllerUpsertTemplateRaw(requestParameters);
        return await response.value();
    }

}

import React from "react";
import { InputLabel, styled, Typography } from "@avenue-8/ui-2";
import { Photo, PhotosUploader } from "../PhotosUploader";
import { PropertyPhotosPreview } from "../PropertyPhotosPreview";

const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;

type Props = {
  photos: Photo[];
  singlePhoto?: boolean;
  isUploading: boolean;
  disabledMessage?: boolean | string;
  compact?: boolean;
  description?: string;
  formId?: string;
  inputId?: string;
  inputLabel?: string;
  onUploadFiles?: (data: FormData) => Promise<any[]>;
  removePropertyPhoto?: (data: { photoId: string }) => Promise<void>;
  makeCoverPhoto?: (data: { photoId: string }) => Promise<void>;
};

export const MediaFieldForm = (props: Props) => {
  const {
    photos,
    isUploading = false,
    disabledMessage,
    compact,
    description,
    formId = "media-form",
    inputId = "property-photos",
    inputLabel = "Media",
    onUploadFiles,
    removePropertyPhoto,
    makeCoverPhoto,
    singlePhoto,
  } = props;
  return (
    <Form id={formId}>
      <InputLabel htmlFor={inputId}>{inputLabel}</InputLabel>
      {disabledMessage ? (
        <>
          <PropertyPhotosPreview photos={photos} inputId={inputId} disableAddMorePhotos={true} />
          <Typography variant="body2">{disabledMessage}</Typography>
        </>
      ) : (
        <PhotosUploader
          content={
            photos.length > 0 && (
              <PropertyPhotosPreview
                photos={photos}
                inputId={inputId}
                removePropertyPhoto={removePropertyPhoto}
                makeCoverPhoto={makeCoverPhoto}
                singlePhoto={singlePhoto}
              />
            )
          }
          description={description ?? "Drag + drop your photos here or input by MLS I.D or address"}
          inputId={inputId}
          isUploading={isUploading}
          onUploadFiles={onUploadFiles}
          disableClickEvent={true}
          compact={compact}
        />
      )}
    </Form>
  );
};

import { Grid } from "@avenue-8/ui-2";
import * as React from "react";
import { summaryStatisticsDimensionsDictionary } from "../../../../shared/constants";
import { formatToCurrency } from "../../../../shared/utils/formatters";
import {
  DimensionTitle,
  HeaderContainer,
  MetricTitle,
  ResultContainer,
  ResultValue,
  SummaryStatisticsContainer,
  Title,
} from "./SummaryStatisticsTable.styles";
import { SummaryStatisticsDataSet, Field, Dimension } from "../";

const getFieldFormatter: (field: Field) => (value: number) => string = (field) => {
  const currencyFields = ["soldPrice", "soldPriceBySqFt"];
  const numberFields = ["daysOnMarket"];
  const ratioFields = ["soldToListPriceRatio"];

  if (currencyFields.includes(field)) return formatToCurrency;
  if (numberFields.includes(field)) return (value) => value.toFixed(0);
  if (ratioFields.includes(field)) return (value) => `${value.toFixed(0)}%`;
  return (value) => value.toString();
};

interface SummaryStatisticsTableProps {
  field: Field;
  dimension: Dimension;
  dataSet: SummaryStatisticsDataSet;
  metricAndDimensionNode: React.ReactNode;
}

export const SummaryStatisticsTable = ({
  field,
  dataSet: { rows },
  metricAndDimensionNode,
  dimension,
}: SummaryStatisticsTableProps) => {
  const format = (n: number | null) => (n == null ? "-" : getFieldFormatter(field)(n));

  const content = rows.map((row) => (
    <Grid container spacing={2} key={row.dimension}>
      <Grid item xs={4}>
        <ResultValue>{row.dimension}</ResultValue>
      </Grid>
      <Grid item xs={2}>
        <ResultValue>{format(row.average)}</ResultValue>
      </Grid>
      <Grid item xs={2}>
        <ResultValue>{format(row.median)}</ResultValue>
      </Grid>
      <Grid item xs={2}>
        <ResultValue>{format(row.low)}</ResultValue>
      </Grid>
      <Grid item xs={2}>
        <ResultValue>{format(row.high)}</ResultValue>
      </Grid>
    </Grid>
  ));

  return (
    <SummaryStatisticsContainer>
      <HeaderContainer>
        <Title>Listings Summary</Title>
        {metricAndDimensionNode}
      </HeaderContainer>
      <ResultContainer container>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <DimensionTitle>{summaryStatisticsDimensionsDictionary[dimension]}</DimensionTitle>
          </Grid>
          <Grid item xs={2}>
            <MetricTitle>AVERAGE</MetricTitle>
          </Grid>
          <Grid item xs={2}>
            <MetricTitle>MEDIAN</MetricTitle>
          </Grid>
          <Grid item xs={2}>
            <MetricTitle>LOW</MetricTitle>
          </Grid>
          <Grid item xs={2}>
            <MetricTitle>HIGH</MetricTitle>
          </Grid>
        </Grid>
        {content}
      </ResultContainer>
    </SummaryStatisticsContainer>
  );
};

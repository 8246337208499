import * as React from "react";
import { ImageUnavailable, styled, useTheme } from "@avenue-8/ui-2";

const CDN_URL = "https://ucarecdn.com/";

export interface ResponsiveImageProps {
  src: string;
  alt: string;
  loading?: "lazy" | "eager";
  width?: number;
  height?: number;
  style?: React.CSSProperties;
  size?: ResponsiveImageDimentionType;
}

export type ResponsiveImageDimentionType = "xs" | "sm" | "md" | "lg" | "xl";
export const dimensions = {
  xs: { width: 320, height: 180 },
  sm: { width: 640, height: 360 },
  md: { width: 960, height: 540 },
  lg: { width: 1280, height: 720 },
  xl: { width: 1920, height: 1080 },
};

const StyledImageUnavailable = styled(ImageUnavailable)`
  position: absolute;
`;

const isUploadcareURL = (url: string) => url.startsWith(CDN_URL);

export const ResponsiveImage = ({
  src,
  alt,
  width,
  height,
  size,
  ...rest
}: ResponsiveImageProps) => {
  const [imageSrc, setImageSrc] = React.useState<string>("");
  const theme = useTheme();
  const breakpointsValues = theme.breakpoints.values;

  const getDefaultWidthAndHeightFromScreenSize = (size?: ResponsiveImageProps["size"]) => {
    let referenceWidth = window.innerWidth;
    if (size && breakpointsValues[size] < window.innerWidth) {
      referenceWidth = breakpointsValues[size];
    }

    if (referenceWidth >= theme.breakpoints.values.xl) {
      return dimensions.xl;
    }
    if (referenceWidth >= theme.breakpoints.values.lg) {
      return dimensions.lg;
    }
    if (referenceWidth >= theme.breakpoints.values.md) {
      return dimensions.md;
    }
    if (referenceWidth >= theme.breakpoints.values.sm) {
      return dimensions.sm;
    }
    return dimensions.xs;
  };

  const defaultValues = getDefaultWidthAndHeightFromScreenSize(size);

  const buildPreviewParams = React.useCallback(() => {
    if (width && height) {
      return `${width}x${height}`;
    }

    if ((width && !height) || (!width && height)) {
      console.warn(
        "ResponsiveImage: Both width and height must be specified. Using default values."
      );
    }

    return `${defaultValues.width}x${defaultValues.height}`;
  }, [defaultValues, width, height]);

  React.useEffect(() => {
    if (src && isUploadcareURL(src)) {
      const urlWithoutTrailingSlash = src.endsWith("/") ? src.slice(0, -1) : src;
      const newSrc = `${urlWithoutTrailingSlash}/-/preview/${buildPreviewParams()}/`;
      setImageSrc(newSrc);
    } else {
      setImageSrc(src);
    }
  }, [src, buildPreviewParams]);

  return src ? <img src={imageSrc} alt={alt} {...rest} /> : <StyledImageUnavailable />;
};

import { styled, Button, Typography } from "@avenue-8/ui-2";

export const PhotoUploadContainer = styled.div`
  width: 100%;
  position: relative;

  @keyframes fadingAnimation {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 0.4;
    }
  }
`;
export const PhotoUploadDefaultWrapper = styled.div<{ disabled?: boolean }>`
  ${(p) => (p.disabled === true ? "pointer-events: none;" : "cursor: pointer;")}
  display: flex;
  flex-direction: column;
  margin: 0 auto 16px;
  color: ${(p) => (p.disabled === true ? "rgba(0,0,0,.2)" : p.theme.av8.input.color)};
  border: 1px ${(p) => (p.disabled === true ? "rgba(0,0,0,.2)" : p.theme.av8.input.borderColor)}
    dashed;
  box-sizing: border-box;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 3px;
  overflow: hidden;

  p {
    margin: 0.25rem 0;
  }

  input {
    border-radius: 1rem;
    outline: none !important;
  }
`;
export const PhotoUploadContent = styled.div<{ $compact?: boolean }>`
  padding: ${(p) => (p.$compact ? `1rem 0 0 0` : `3rem 2.5rem 2.5rem 2.5rem`)};
`;
export const ContentContainer = styled.div`
  position: relative;
`;
export const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  animation: fadingAnimation 1.8s infinite ease-in-out;
  background-color: ${(p) => p.theme.av8.backdropColor};
`;
export const DescriptionContainer = styled.div`
  margin: 16px auto 0;
`;
export const MaxSizeText = styled(Typography)`
  text-transform: uppercase;
  font-size: 10px;
  color: ${(p) => p.theme.av8.secondaryColor};
  letter-spacing: 2px;
`;
export const BrowseButton = styled(Button)`
  border-color: ${(p) => p.theme.av8.input.borderColor};
  margin: 16px auto;
`;
export const ThumbnailsUploadingContainer = styled.aside`
  margin: 8px auto 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  min-height: 48px;
  overflow: hidden;
  position: relative;
`;
export const ThumbImage = styled.div`
  height: 0;
  padding-bottom: 100%;
  position: relative;
  cursor: pointer;
  margin: 0;
  box-sizing: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
  animation: fadingAnimation 1.8s infinite ease-in-out;
`;
export const PhotoUploadCustomWrapper = styled.div`
  position: relative;
  padding-bottom: 8px;
`;
export const ErrorText = styled(Typography)`
  color: ${(p) => p.theme.av8.dangerColor};
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  width: 100%;
  left: 0;
  margin-top: 16px !important;
`;

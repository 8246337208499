import React from "react";
import { ConstrainedContainer } from "./ConstrainedContainer";
import { styledThemed as styled } from "../theme";
import { FreeHtmlTextSectionView } from "../../../../presentation/presentation-generation-logic/models/section-view";
import { SectionViewLayout } from "./SectionViewLayout";
import { AvoidPrintBreakInside } from "./AvoidPrintBreakInside";
import { defaultTextEditorContentStyle } from "src/modules/shared/components/TextEditor/TextEditor.styles";

interface Props extends FreeHtmlTextSectionView {}

const FreeTextHtmlContent = styled.div`
  max-width: 520px;
  margin: 0 auto;

  ${() => defaultTextEditorContentStyle}
`;

export const FreeHtmlText = ({ id, htmlContent }: Props) => {
  return (
    <SectionViewLayout>
      <AvoidPrintBreakInside>
        <ConstrainedContainer id={id}>
          <FreeTextHtmlContent dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </ConstrainedContainer>
      </AvoidPrintBreakInside>
    </SectionViewLayout>
  );
};

import { styled } from "@avenue-8/ui-2";
import { media } from "../../shared/css-snippets";
import { breakpoints } from "../../shared/breakpoints";
import { useBreakpoint } from "../hooks/useMobile";
import { useScrollOffsetGreaterThan } from "../hooks/useOffsetGreaterThan";

const DEBUG_LAYOUT = false;

interface LayoutRootStyledProps {
  $isSmall: boolean;
}

export const LayoutRoot = styled.div<LayoutRootStyledProps>`
  position: relative;
  background: ${({
    theme: {
      av8: { background, backgroundAlt },
    },
    $isSmall,
  }) => {
    const height = $isSmall ? "340px" : "402px";
    return `linear-gradient(180deg, ${background} 0%, ${background} ${height}, ${backgroundAlt} ${height}, ${backgroundAlt} 100%)`;
  }};
  min-height: 100vh;
`;

interface HeaderFrameStyledProps {
  $showShadow: boolean;
  $position: "static" | "fixed";
  $condensedHeader: boolean;
}

export const HeaderFrame = styled.div<HeaderFrameStyledProps>`
  background: ${(p) => p.theme.av8.background};
  position: ${(p) => p.$position};
  box-sizing: border-box;
  z-index: 10;
  transition: padding-top 300ms, padding-bottom 300ms;
  width: 100%;
  ${(p) => p.$showShadow && `box-shadow: 0 1px 3px ${p.theme.av8.boxShadowColor};`}
  padding: 3rem 0;
  ${(p) => p.$condensedHeader && "padding: 1rem 0 1rem 0rem;"}
  ${() => media.desktopDown} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

interface HeaderStyleProp {
  $verticalLayout: boolean;
}

export const Header = styled.div<HeaderStyleProp>`
  display: flex;
  flex-direction: row;
  max-width: ${() => breakpoints.desktop}px;
  margin: 0px auto;
  gap: 8px 0;

  ${(p) => p.$verticalLayout && "flex-wrap: wrap;"}

  ${() => media.desktopDown} {
    max-width: ${() => breakpoints.smallDesktop}px;
  }
`;

const HeaderContent = styled.div<{ $verticalLayout: boolean }>`
  ${() => (DEBUG_LAYOUT ? "background: yellow;" : "")}
  flex: 1;
  ${(p) =>
    p.$verticalLayout &&
    `
    flex: 0 0 100%;
    width: 100%;
    order: 4;
  `}
`;

const HeaderActions = styled.div<{ $verticalLayout: boolean }>`
  ${() => (DEBUG_LAYOUT ? "background: yellow;" : "")}
  ${(p) =>
    p.$verticalLayout &&
    `
    display: flex;
    order: 2;
    align-items: center;
    margin-right: 24px;
    padding-top: 1rem;
  `}
`;

const LogoContainer = styled.div<{ $verticalLayout: boolean; $centerVertical: boolean }>`
  ${() => (DEBUG_LAYOUT ? "background: purple;" : "")}
  flex: 0 0 9rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${(p) =>
    p.$verticalLayout &&
    `
    order: 1;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    a {
      padding: 0;
    }
  `}
  ${(p) => p.$centerVertical && `align-items: center;`}
`;

const UserMenuContainer = styled.div<{ $verticalLayout: boolean }>`
  ${() => (DEBUG_LAYOUT ? "background: blue;" : "")}
  flex: 0 0 9rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${(p) =>
    p.$verticalLayout &&
    `
    order: 3;
    flex: 0;
    align-items: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
  `}
`;

const ContentFrame = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  ${() => media.desktopDown} {
    padding: 0 1rem 0 1rem;
  }
`;

const Content = styled.div<{ $contentExtraPadding?: number }>`
  ${() => (DEBUG_LAYOUT ? "background: green;" : "")}
  padding: ${(p) => p.$contentExtraPadding || 0}px 9rem 0 9rem;
  max-width: ${() => breakpoints.desktop}px;
  box-sizing: border-box;
  margin: 0px auto;
  ${() => media.desktopDown} {
    max-width: ${() => breakpoints.smallDesktop}px;
    padding: ${(p) => p.$contentExtraPadding}px 0rem 0rem 0rem;
  }
`;

export interface PageLayoutProps {
  contentReservedHeight?: number;
  logo: React.ReactNode;
  userMenu: React.ReactNode;
  headerContent: React.ReactNode;
  headerActions?: React.ReactNode;
  headerPosition?: "static" | "fixed";
  showHeaderShadow?: boolean;
  children: any;
  condensedHeader?: boolean;
  verticalHeaderLayout?: boolean;
  // HeaderComponent?: StyledComponent<"div", any, HeaderStyleProp>;
  // HeaderFrameComponent?: StyledComponent<"div", any, HeaderFrameStyledProps>;
  // LayoutRootComponent?: StyledComponent<"div", any, LayoutRootStyledProps>;
  HeaderComponent?: any;
  HeaderFrameComponent?: any;
  LayoutRootComponent?: any;
  pageRef?: any;
}

export const PageLayout = ({
  contentReservedHeight,
  headerContent,
  headerActions,
  logo,
  userMenu,
  showHeaderShadow = false,
  children,
  headerPosition = "fixed",
  condensedHeader,
  verticalHeaderLayout,
  HeaderComponent = Header,
  HeaderFrameComponent = HeaderFrame,
  LayoutRootComponent = LayoutRoot,
  pageRef,
}: PageLayoutProps) => {
  const isSmall = useBreakpoint("desktopDown");
  const shrink = useScrollOffsetGreaterThan(80) || isSmall;
  const _condensedHeader = condensedHeader ?? shrink;
  const _showHeaderShadow = showHeaderShadow ?? (!isSmall && shrink);
  const _verticalHeaderLayout = verticalHeaderLayout ?? isSmall;

  return (
    <LayoutRootComponent $isSmall={isSmall} ref={pageRef}>
      <HeaderFrameComponent
        $showShadow={_showHeaderShadow}
        $position={headerPosition}
        $condensedHeader={_condensedHeader}
        className={headerPosition === "fixed" ? "mui-fixed" : undefined}
      >
        <HeaderComponent $verticalLayout={_verticalHeaderLayout}>
          <LogoContainer
            $verticalLayout={_verticalHeaderLayout}
            $centerVertical={!_verticalHeaderLayout && _condensedHeader}
          >
            {logo}
          </LogoContainer>
          <HeaderContent $verticalLayout={_verticalHeaderLayout}>{headerContent}</HeaderContent>
          {headerActions && (
            <HeaderActions $verticalLayout={_verticalHeaderLayout}>{headerActions}</HeaderActions>
          )}
          <UserMenuContainer $verticalLayout={_verticalHeaderLayout}>{userMenu}</UserMenuContainer>
        </HeaderComponent>
      </HeaderFrameComponent>
      <ContentFrame>
        <Content $contentExtraPadding={contentReservedHeight}>{children}</Content>
      </ContentFrame>
    </LayoutRootComponent>
  );
};

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { usePlacesAutocomplete } from "../../../../shared/hooks/usePlacesAutocomplete";
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  CloseIcon,
  styled,
  toast,
  Fieldset,
} from "@avenue-8/ui-2";
import { EditComparablePropertyForm } from "./EditComparablePropertyForm";
import { EditComparablePropertyFormModel } from "./comparable-property-converter";
import { geocodeSearch } from "../AddressInput/geocode-search";
import { DynamicDialog } from "../../DynamicDialog";
import { MediaFieldForm } from "../../MediaFieldForm";
import { Photo } from "../../PhotosUploader";

const StyledModal = styled(Dialog)`
  width: 100%;
  margin: 0 auto;
  max-width: calc(1248px + 4rem);
  animation: ${(p) => `${p.open ? "animate-fade-show" : "animate-fade-hide"}  500ms ease-in-out`};
`;

const StyledTitle = styled(DialogTitle)`
  padding: 1.5rem 2rem 1rem 2rem;
`;

const ModalContent = styled(DialogContent)`
  width: 100%;
  padding: 1rem 2rem;
  position: relative;
`;

const Actions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  padding-top: 1rem;
`;

const ConfirmButton = styled(Button)`
  padding-left: 4rem;
  padding-right: 4rem;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export interface EditComparablePropertyModalProps {
  onClose: () => void;
  open: boolean;
  isUploading: boolean;
  isSaving: boolean;
  defaultValues: EditComparablePropertyFormModel;
  onSave: (data: EditComparablePropertyFormModel) => Promise<void>;
  onUploadComparablePropertyImage?: (data: FormData) => any;
  onRemoveComparablePropertyImage?: (params: { photoId: string }) => Promise<any>;
  onMakeCoverPhotoInComparableProperty?: (params: { photoId: string }) => Promise<any>;
  propertyPhotos: Photo[];
}

export const EditComparablePropertyModal = (props: EditComparablePropertyModalProps) => {
  const {
    defaultValues,
    isUploading,
    isSaving,
    open,
    onMakeCoverPhotoInComparableProperty,
    onRemoveComparablePropertyImage,
    onUploadComparablePropertyImage,
    onClose,
    onSave,
    propertyPhotos,
  } = props;

  const formMethods = useForm<EditComparablePropertyFormModel>({
    defaultValues: { ...defaultValues },
    mode: "onChange",
    reValidateMode: "onChange",
  });
  const {
    trigger,
    setValue,
    getValues,
    reset,
    formState: { isDirty },
  } = formMethods;
  const [confirmCloseDialogOpen, setConfirmCloseDialogOpen] = React.useState(false);
  const [suggestionsToChoose, setSuggestionsToChoose] = React.useState<any[]>([]);
  const hasSearched = React.useRef(false);
  const [lastAddressSearched, setLastAddressSearched] = React.useState<string | null>(null);

  const {
    suggestions: { data: suggestionsData },
    setValue: setAutocompleteSuggestionValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleUpdateLocationFieldsFromSuggestion = React.useCallback(
    async (suggestion: Record<string, any>): Promise<void> => {
      const geocodeResult = await geocodeSearch(suggestion.description);
      if (geocodeResult) {
        const { city, neighborhood, state, zipCode: zip, lat, lng } = geocodeResult;
        const streetNameFromSuggetion =
          suggestion.structured_formatting?.main_text || suggestion.title.split(", ")[0];
        const address1 = `${geocodeResult.streetNumber ? `${geocodeResult.streetNumber} ` : ""}${
          geocodeResult.streetName || streetNameFromSuggetion || ""
        }`.trim();
        const address2 = `${geocodeResult.premise ? `${geocodeResult.premise} ` : ""} ${
          geocodeResult.subpremise ? `${geocodeResult.subpremise} ` : ""
        }`.trim();
        setValue("addressLine1", address1 ?? "");
        setValue("addressLine2", address2 ?? "");
        setValue("city", city ?? "");
        setValue("neighborhood", neighborhood ?? "");
        setValue("state", state ?? "");
        setValue("zip", zip ?? "");
        setValue("lat", lat ?? "");
        setValue("lng", lng ?? "");

        const hasEmptyGeocodeField = !(await trigger([
          "addressLine1",
          "city",
          "state",
          "zip",
          "neighborhood",
          "lat",
          "lng",
        ]));
        if (hasEmptyGeocodeField) {
          setLastAddressSearched(address1);
          toast.info("Some fields couldn't be auto filled, please specify them manually.");
        }
        setSuggestionsToChoose([]);
        clearSuggestions();
      }
    },
    [clearSuggestions, setValue, trigger]
  );

  React.useEffect(() => {
    if (suggestionsData && suggestionsData.length > 0) {
      const actions = suggestionsData.map((result: any) => {
        return {
          label: result.description,
          onClick: () => handleUpdateLocationFieldsFromSuggestion(result),
        };
      });
      setSuggestionsToChoose(actions);
    }
    if (hasSearched.current && suggestionsData && suggestionsData.length === 0) {
      toast.error(
        `We cannot find your address. Please confirm if it is correct: ${getValues("addressLine1")}`
      );
    }
    hasSearched.current = false;
  }, [getValues, handleUpdateLocationFieldsFromSuggestion, hasSearched, suggestionsData]);

  React.useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const handleConfirmClick = async (_e: React.MouseEvent) => {
    const { addressLine1, lat, lng, neighborhood, zip, city, state } = getValues();
    const hasEmptyLocationFields = [lat, lng, addressLine1, neighborhood, zip, city, state].some(
      (field) => !field?.toString().trim()
    );
    const isValid = await trigger();

    const isSameAddress = !addressLine1 || addressLine1 === lastAddressSearched;
    if (hasEmptyLocationFields && !isSameAddress) {
      const searchString = addressLine1.trim();
      clearSuggestions();
      setLastAddressSearched(searchString);
      setAutocompleteSuggestionValue(searchString);
      hasSearched.current = true;
      return;
    }
    if (isDirty) {
      if (!isValid) return;
      onSave(getValues());
    }
    if (isEditing) {
      onClose();
    }
  };

  const handleCloseWithPrompt = async () => {
    if (isDirty) {
      setConfirmCloseDialogOpen(true);
      return;
    }
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  const handleConfirmDialogClose = () => {
    setConfirmCloseDialogOpen(false);
    onClose();
  };

  const handleConfirmDialogSave = async () => {
    const isValid = await trigger();
    if (isValid) {
      await onSave(getValues());
      setConfirmCloseDialogOpen(false);
      onClose();
    } else {
      setConfirmCloseDialogOpen(false);
    }
  };

  const isEditing = React.useMemo(() => Boolean(defaultValues?.mlsId), [defaultValues?.mlsId]);
  const title = isEditing ? "Edit a Comparable Property" : "Add a Comparable Property";

  return (
    <StyledModal
      open={open}
      fullWidth
      maxWidth={false}
      scroll={"paper"}
      BackdropProps={{
        unmountOnExit: true,
      }}
      onClose={handleCloseWithPrompt}
    >
      <StyledTitle>
        <span>{title}</span>
        <CloseButton aria-label="close" onClick={handleCloseWithPrompt}>
          <CloseIcon />
        </CloseButton>
      </StyledTitle>
      <ModalContent>
        <Grid container item spacing={3}>
          <Grid container item md={8} xs={12} component={Fieldset} disabled={isSaving}>
            <FormProvider {...formMethods}>
              <EditComparablePropertyForm
                handleSave={onSave}
                handleUpdateLocationFieldsFromSuggestion={handleUpdateLocationFieldsFromSuggestion}
              />
            </FormProvider>
          </Grid>
          <Grid container item md={4} xs={12} justifyContent={"flex-start"}>
            <MediaFieldForm
              disabledMessage={
                !isEditing && "Please save your comparable property before adding photos."
              }
              photos={propertyPhotos || []}
              isUploading={isUploading}
              onUploadFiles={onUploadComparablePropertyImage}
              removePropertyPhoto={onRemoveComparablePropertyImage}
              makeCoverPhoto={onMakeCoverPhotoInComparableProperty}
            />
          </Grid>
        </Grid>
      </ModalContent>
      <Actions>
        <ConfirmButton
          form="edit-comparable-property"
          onClick={handleConfirmClick}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Confirm"}
        </ConfirmButton>
      </Actions>

      <DynamicDialog
        message={
          suggestionsToChoose.length > 1
            ? `Please select an address to autofill.`
            : `Do you want to autofill from this address?`
        }
        onClose={() => setSuggestionsToChoose([])}
        open={suggestionsToChoose.length > 0}
        actionsAsList
        actions={suggestionsToChoose}
      />

      <DynamicDialog
        title={"Do you want to save?"}
        message={"You have made changes to this comparable property that have not been saved."}
        open={confirmCloseDialogOpen}
        actions={[
          {
            label: "No, discard",
            onClick: () => handleConfirmDialogClose(),
          },
          {
            label: "Save",
            onClick: async () => await handleConfirmDialogSave(),
            primary: true,
          },
        ]}
      />
    </StyledModal>
  );
};

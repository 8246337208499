import * as React from "react";
import {
  Button,
  LinkIcon,
  EllipsesIcon,
  DownloadIcon,
  ShareIcon,
  CircularProgress,
  styled,
} from "@avenue-8/ui-2";
import { ResponsiveContextMenu } from "../../../../ResponsiveContextMenu";

const Container = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  cursor: pointer;
`;

const PresentationMenuItemText = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 2rem;
  line-height: 2rem;
  min-width: 16rem;
`;

const PresentationMenuButton = styled(Button)`
  min-width: initial;
  width: 48px;
  padding: 0;
`;

export type PresentationMenuProps = {
  onDownload: () => void;
  onPrintPDF: () => void;
  onCopyLink: () => void;
  isDownloading?: boolean;
};

export const PresentationMenu = ({
  onDownload,
  onPrintPDF,
  onCopyLink,
  isDownloading,
}: PresentationMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<React.ReactNode | null>(null);
  const onClose = () => setAnchorEl(null);
  const handleDownload = () => {
    onDownload();
    onClose();
  };

  const handlePrintPDF = () => {
    onPrintPDF();
    onClose();
  };

  const handleCopyLink = () => {
    onCopyLink();
    onClose();
  };

  return (
    <Container>
      <PresentationMenuButton
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-meta-action="presentation-more-actions"
      >
        <EllipsesIcon />
      </PresentationMenuButton>
      <ResponsiveContextMenu
        anchorEl={anchorEl}
        menuPositionProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {({ ItemComponent }) => {
          return [
            <ItemComponent
              key={"save-pdf"}
              onClick={handleDownload}
              disabled={isDownloading}
              data-meta-action="save-pdf"
            >
              {!isDownloading ? (
                <PresentationMenuItemText>
                  <DownloadIcon /> Save PDF
                </PresentationMenuItemText>
              ) : (
                <PresentationMenuItemText>
                  <CircularProgress size={24} /> Saving PDF...
                </PresentationMenuItemText>
              )}
            </ItemComponent>,
            <ItemComponent key={"print-pdf"} onClick={handlePrintPDF} data-meta-action="print-pdf">
              <PresentationMenuItemText>
                <ShareIcon /> Print PDF
              </PresentationMenuItemText>
            </ItemComponent>,
            <ItemComponent
              key={"copy-presentation-link"}
              onClick={handleCopyLink}
              data-meta-action="copy-presentation-link"
            >
              <PresentationMenuItemText>
                <LinkIcon /> Copy Presentation Link
              </PresentationMenuItemText>
            </ItemComponent>,
          ];
        }}
      </ResponsiveContextMenu>
    </Container>
  );
};

import React from "react";
import { defaultTextEditorContentStyle, EditorWrapper } from "./TextEditor.styles";
import { Editor as TinyMCEEditor } from "@tinymce/tinymce-react";
import { Editor } from "tinymce";

export type TextEditorProps = {
  // css string style
  editorContentStyle?: string;
  htmlContent: string;
  editorRef: React.MutableRefObject<Editor | null>;
  height?: number;
};

export const TextEditor = (props: TextEditorProps) => {
  const {
    editorContentStyle = defaultTextEditorContentStyle,
    htmlContent,
    editorRef,
    height = 500,
  } = props;
  return (
    <EditorWrapper>
      <TinyMCEEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={htmlContent}
        init={{
          body_class: "text-editor-body",
          height,
          auto_focus: true,
          menubar: false,
          plugins: ["advlist", "autolink", "lists", "link", "preview", "anchor", "wordcount"],
          toolbar:
            "undo redo | casechange blocks | fontsize | bold italic | link |" +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist | removeformat",
          content_style: editorContentStyle,
          link_default_target: "_blank",
          block_formats: "Paragraph=p; Heading 1=h1; Heading 2=h2;",
          font_size_formats: "8px 10px 12px 14px 18px 24px 36px 48px",
        }}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      />
    </EditorWrapper>
  );
};

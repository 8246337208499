import { toast } from "react-toastify";
import { Role } from "../../../../routes/routes";
import { getUsersApiClient } from "../../../shared/apis/user/api-factories";
import { ListUsersResponse } from "../../../shared/apis/user/generated";
import { ActionInvoker, makeReducerContext } from "../../../shared/hooks/makeReducerContext";

// STATE
interface BaseState {}
interface NotLoadedState extends BaseState {
  loadState: "not-loaded";
}
interface FailState extends BaseState {
  loadState: "failed";
}
type RefreshState = "idle" | "refreshing" | "refreshed" | "error";
export type SortByState = "created-desc" | "created-asc";

export interface ModalState {
  open: boolean;
  agentAccountId?: string;
}

interface LoadedState extends BaseState {
  loadState: "loaded";
  refreshState: RefreshState;
  listResponse: ListUsersResponse;
  sortByOrder?: SortByState;
  modal?: ModalState;
  sendResetPasswordEmailState?: SendResetPasswordEmailState;
}
type State = LoadedState | NotLoadedState | FailState;
const defaultState: State = {
  loadState: "not-loaded",
};

// ACTION PAYLOADS
interface Load {
  type: "load";
  listResponse: ListUsersResponse;
} // Load everything we need to refresh the AgentAccounts
interface LoadFail {
  type: "load-fail";
}
interface RefreshInit {
  type: "refresh-init";
}
interface RefreshFail {
  type: "refresh-fail";
}
interface RefreshLoad {
  type: "refresh";
  listResponse: ListUsersResponse;
}

interface SetModalOpen {
  type: "set-modal-open";
  open: boolean;
  agentAccountId?: string;
}

export type SendResetPasswordEmailState = "idle" | "init" | "success" | "fail";
interface SendResetPasswordEmailInit {
  type: "send-reset-password-email-init";
}
interface SendResetPasswordEmail {
  type: "send-reset-password-email";
  sendResetPasswordEmailState?: SendResetPasswordEmailState;
}

type Action =
  | Load
  | LoadFail
  | RefreshInit
  | RefreshFail
  | RefreshLoad
  | SetModalOpen
  | SendResetPasswordEmailInit
  | SendResetPasswordEmail;

export interface PaginationMetaProps {
  currentPage: number;
  totalPages: number;
  pageOffset?: number;
}

export interface ActionInvokerContext {
  wrapLoadPromise: <T>(promise: Promise<T>) => Promise<T>;
  confirm: (windowConfig: {
    title: string;
    message: string;
    confirmButtonText: string;
  }) => Promise<boolean>;
  showToast: {
    success: (message: string) => void;
    error: (message: string) => void;
  };
}

// ACTION INVOKER
export class AgentAccountsActions extends ActionInvoker<Action, ActionInvokerContext, State> {
  userApi = getUsersApiClient;

  wrapLoadPromise<T>(promise: Promise<T>) {
    if (this.context.wrapLoadPromise) return this.context.wrapLoadPromise(promise);
    return promise;
  }

  async load({ page }: { page?: number } = {}) {
    try {
      const listResponse = await this.wrapLoadPromise(
        this.userApi().userControllerListUsers({
          page: page ?? 1,
          limit: 10,
          role: Role.Agent,
        })
      );
      this.dispatch({ type: "load", listResponse });
    } catch (e) {
      this.dispatch({ type: "load-fail" });
      console.error(e);
    }
  }

  async refresh({
    page,
  }: {
    page?: number;
  } = {}): Promise<void> {
    if (this.state.loadState !== "loaded") return;
    try {
      this.dispatch({ type: "refresh-init" });
      const meta = this.state.listResponse?.meta as PaginationMetaProps;
      const pageFilter = page ?? meta.currentPage;

      const listResponse = await this.wrapLoadPromise(
        this.userApi().userControllerListUsers({
          page: pageFilter,
          limit: 10,
          role: Role.Agent,
        })
      );
      this.dispatch({ type: "refresh", listResponse });
    } catch (e) {
      this.dispatch({ type: "refresh-fail" });
      console.error(e);
    }
  }

  setModalOpen({ open, agentAccountId }: ModalState) {
    if (this.state.loadState !== "loaded") return;
    this.dispatch({ type: "set-modal-open", open, agentAccountId: agentAccountId ?? "" });
  }

  async resetPasswordForAgent(params: { email: string }) {
    if (this.state.loadState !== "loaded") return;
    this.dispatch({ type: "send-reset-password-email-init" });

    try {
      await this.wrapLoadPromise(
        this.userApi().userControllerStartResetPassword({ userStartResetPasswordParams: params })
      );
      toast.success("Password reset email sent", {
        toastId: "agent-modal-sent-reset-password",
      });
      this.dispatch({ type: "send-reset-password-email" });
    } catch (e) {
      console.error(e);
      toast.error("Failed to send password reset email", {
        toastId: "agent-modal-sent-reset-password-failed",
      });
      this.dispatch({ type: "send-reset-password-email", sendResetPasswordEmailState: "fail" });
    }
  }

  setSendResetPasswordEmailState(sendResetPasswordEmailState?: SendResetPasswordEmailState) {
    this.dispatch({
      type: "send-reset-password-email",
      sendResetPasswordEmailState: sendResetPasswordEmailState ?? "idle",
    });
  }
}
const defaultInvoker = new AgentAccountsActions();

//REDUCER
const reducer: (state: State, action: Action) => State = (state, action) => {
  if (state.loadState === "loaded") {
    switch (action.type) {
      case "refresh-init": {
        return { ...state, refreshState: "refreshing" };
      }
      case "refresh": {
        return {
          ...state,
          refreshState: "refreshed",
          listResponse: action.listResponse,
        };
      }
      case "refresh-fail": {
        return { ...state, refreshState: "error" };
      }
      case "set-modal-open": {
        const { open, agentAccountId } = action;
        return { ...state, modal: { agentAccountId, open } };
      }
      case "send-reset-password-email-init": {
        return { ...state, sendResetPasswordEmailState: "init" };
      }
      case "send-reset-password-email": {
        return {
          ...state,
          sendResetPasswordEmailState: action.sendResetPasswordEmailState ?? "success",
        };
      }
      default:
        break;
    }
  } else if (state.loadState === "not-loaded") {
    switch (action.type) {
      case "load": {
        return {
          ...state,
          listResponse: action.listResponse,
          loadState: "loaded",
          refreshState: "idle",
          sortByOrder: "created-desc",
        };
      }
      case "load-fail": {
        return { ...state, loadState: "failed" };
      }
      default:
        break;
    }
  }
  console.warn(`Could not find reducer handler for action ${action.type}`);
  return state;
};

export const {
  useReducerContext: useAgentAccountsContext,
  ReducerContextProvider: AgentAccountsContextProvider,
} = makeReducerContext<State, Action, ActionInvokerContext, AgentAccountsActions>({
  reducer,
  defaultState,
  defaultInvoker,
});

import * as React from "react";
import { TextField, TextFieldProps } from "@avenue-8/ui-2";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";

export type TextFieldBaseProps = TextFieldProps & { errors?: DeepMap<FieldValues, FieldError> };

export const TextFieldBase = React.forwardRef((props: TextFieldBaseProps, ref) => {
  const { name, errors } = props;
  const error = {
    error: name && errors ? !!errors[name] : false,
    helperText: name && errors ? errors[name]?.message : undefined,
  };
  const inputProps = {
    ...props.inputProps,
    maxLength: props.inputProps?.maxLength || 100,
  };
  return (
    <TextField
      type="text"
      fullWidth={true}
      id={props.id ?? name}
      {...props}
      {...error}
      inputProps={inputProps}
      inputRef={ref}
    />
  );
});

export const getTextFieldErrors = ({
  name,
  errors,
}: {
  name?: string;
  errors: DeepMap<FieldValues, FieldError>;
}) => {
  return {
    error: name && errors ? !!errors[name] : false,
    helperText: name && errors ? errors[name]?.message : undefined,
  };
};

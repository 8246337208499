import { useRef } from "react";
import { useAppContext } from "../../../AppContext";
import { BlockingLoader } from "@avenue-8/ui-2";

export const AppBlockingLoader = () => {
  const { state } = useAppContext();
  const blockingPromises = state.activePromises.filter((x) => x.blocking);
  let messages = blockingPromises
    .filter((x) => x.message)
    .map((x, i) => (
      <div key={i}>
        <span>{x.message}</span>
      </div>
    ));
  if (messages.length === 0) messages = [<span key="default">Loading</span>];
  const lastLoadingMessages = useRef(messages);
  const open = blockingPromises.length > 0;
  if (open) {
    lastLoadingMessages.current = messages;
  }
  return <BlockingLoader open={open} message={lastLoadingMessages.current} />;
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgentEditParams
 */
export interface AgentEditParams {
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    license?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    headshotUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    sourceId?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof AgentEditParams
     */
    email: string;
}

export function AgentEditParamsFromJSON(json: any): AgentEditParams {
    return AgentEditParamsFromJSONTyped(json, false);
}

export function AgentEditParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgentEditParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'license': !exists(json, 'license') ? undefined : json['license'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'headshotUrl': !exists(json, 'headshotUrl') ? undefined : json['headshotUrl'],
        'sourceId': !exists(json, 'sourceId') ? undefined : json['sourceId'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'email': json['email'],
    };
}

export function AgentEditParamsToJSON(value?: AgentEditParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'title': value.title,
        'license': value.license,
        'phone': value.phone,
        'headshotUrl': value.headshotUrl,
        'sourceId': value.sourceId,
        'state': value.state,
        'email': value.email,
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchComparablePropertyRecurringCostItemDto
 */
export interface PatchComparablePropertyRecurringCostItemDto {
    /**
     * 
     * @type {string}
     * @memberof PatchComparablePropertyRecurringCostItemDto
     */
    label: string;
    /**
     * 
     * @type {number}
     * @memberof PatchComparablePropertyRecurringCostItemDto
     */
    value?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PatchComparablePropertyRecurringCostItemDto
     */
    frequency: string;
    /**
     * 
     * @type {string}
     * @memberof PatchComparablePropertyRecurringCostItemDto
     */
    type: string;
}

export function PatchComparablePropertyRecurringCostItemDtoFromJSON(json: any): PatchComparablePropertyRecurringCostItemDto {
    return PatchComparablePropertyRecurringCostItemDtoFromJSONTyped(json, false);
}

export function PatchComparablePropertyRecurringCostItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchComparablePropertyRecurringCostItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'frequency': json['frequency'],
        'type': json['type'],
    };
}

export function PatchComparablePropertyRecurringCostItemDtoToJSON(value?: PatchComparablePropertyRecurringCostItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'value': value.value,
        'frequency': value.frequency,
        'type': value.type,
    };
}



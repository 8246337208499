/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyStyleItemDto
 */
export interface PropertyStyleItemDto {
    /**
     * 
     * @type {string}
     * @memberof PropertyStyleItemDto
     */
    style: string;
}

export function PropertyStyleItemDtoFromJSON(json: any): PropertyStyleItemDto {
    return PropertyStyleItemDtoFromJSONTyped(json, false);
}

export function PropertyStyleItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyStyleItemDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'style': json['style'],
    };
}

export function PropertyStyleItemDtoToJSON(value?: PropertyStyleItemDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'style': value.style,
    };
}



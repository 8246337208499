import { Controller, useFormContext } from "react-hook-form";
import { Input, InputLabel } from "@avenue-8/ui-2";
import { validateMinMax } from "./min-max-field-helper";
import { Fields, StyledLabel, ErrorMessage } from "./MinMaxField.styles";

interface Props {
  fieldName: string;
  /**
   * 2 labels to put over of the min and max fields
   * @defaultValue ["Minimum", "maximum"]
   **/
  fieldLabels?: string[];
  component?: React.ElementType;
  fieldProps?: any;
}

export const MinMaxField = (props: Props) => {
  const {
    fieldLabels = ["Minimum", "Maximum"],
    fieldName,
    component: Component = Input,
    fieldProps,
  } = props;
  const { errors, getValues, clearErrors } = useFormContext();
  const errorMessage = errors[`${fieldName}Min`]?.message || errors[`${fieldName}Max`]?.message;

  return (
    <>
      <Fields>
        <div>
          <InputLabel htmlFor={`${fieldName}Min`}>{fieldLabels[0]}</InputLabel>
          <Controller
            name={`${fieldName}Min`}
            rules={{
              validate: (value) => {
                if (!validateMinMax(value, getValues(`${fieldName}Max`))) {
                  return `Minimum must be lower than maximum ${fieldName}`;
                }
                clearErrors(`${fieldName}Max`);
              },
            }}
            render={({ ref, ...rest }) => (
              <Component
                {...rest}
                {...fieldProps}
                errors={{ [`${fieldName}Min`]: Boolean(errorMessage) }}
                inputRef={ref}
              />
            )}
          />
        </div>
        <StyledLabel>to</StyledLabel>
        <div>
          <InputLabel htmlFor={`${fieldName}Max`}>{fieldLabels[1]}</InputLabel>
          <Controller
            name={`${fieldName}Max`}
            rules={{
              validate: (value) => {
                if (!validateMinMax(getValues(`${fieldName}Min`), value)) {
                  return `Minimum must be lower than maximum ${fieldName}`;
                }
                clearErrors(`${fieldName}Min`);
              },
            }}
            render={({ ref, ...rest }) => (
              <Component
                {...rest}
                {...fieldProps}
                errors={{ [`${fieldName}Max`]: Boolean(errorMessage) }}
                inputRef={ref}
              />
            )}
          />
        </div>
      </Fields>
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </>
  );
};

import { useHistory } from "react-router";
import { appEventEmitter } from "../../../events/app-event-emitter";
import { useAuthContext } from "../../shared/contexts/AuthContext/AuthContext";
import { UserMenu } from "./UserMenu";

export const SmartUserMenu = ({
  disableAccountClick,
  ...rest
}: {
  className?: string;
  disableAccountClick?: boolean;
}) => {
  const { state: authState, actions: authActions } = useAuthContext();
  const history = useHistory();

  return (
    <UserMenu
      {...rest}
      userFullName={authState.userInfo?.fullName}
      userRole={authState.user?.role}
      avatarUrl={authState.userInfo?.avatar}
      onAccountClick={
        !disableAccountClick
          ? () => {
              history.push("/agent-account/edit");
              appEventEmitter.emit({ eventType: "my-account-clicked" });
            }
          : undefined
      }
      onLogoutClick={() => {
        authActions.signOut();
      }}
      onAgentAccountsClick={() => {
        history.push("/agent-account");
      }}
    />
  );
};

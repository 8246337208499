import { InputLabel, FormHelperText, styled } from "@avenue-8/ui-2";

export const StyledLabel = styled(InputLabel)`
  padding-top: 18px;
  margin-bottom: 0 !important; // :/ MuiInputLabel-root
`;
export const ErrorMessage = styled(FormHelperText)`
  margin-top: 0.25rem;
  height: 0.5rem;
  color: ${(p) => p.theme.av8.dangerColor};
`;
export const Fields = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;

  & > div {
    max-width: calc(50% - 2.25rem);
    & > label {
      min-height: 1em;
    }
  }
`;

import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextFieldBase } from "../../TextFieldBase";

export interface EditCmaHeaderFormModel {
  clientName: string;
  cmaTitle: string;
}

export interface EditCmaHeaderFormProps {
  onSave: (data: EditCmaHeaderFormModel) => Promise<void>;
  defaultValues: EditCmaHeaderFormModel;
}

export const EditCmaHeaderForm = ({ onSave, defaultValues }: EditCmaHeaderFormProps) => {
  const { control, handleSubmit, errors } = useFormContext<EditCmaHeaderFormModel>();

  return (
    <form id={"edit-cma-header"} onSubmit={handleSubmit(onSave)} autoComplete="off">
      <Controller
        control={control}
        rules={{ required: "Required" }}
        name="clientName"
        defaultValue={defaultValues.clientName}
        render={({ ref, ...fieldRest }) => {
          const error = errors["clientName"];
          return (
            <TextFieldBase
              label={"Prepared For"}
              autoFocus
              id={fieldRest.name}
              error={!!error}
              helperText={error?.message}
              {...fieldRest}
              inputRef={ref}
              fullWidth
              placeholder="Enter client name"
            />
          );
        }}
      />

      <div style={{ height: "1rem" }} />

      <Controller
        control={control}
        name="cmaTitle"
        defaultValue={defaultValues.cmaTitle}
        rules={{ required: "Required" }}
        render={({ ref, ...fieldRest }) => {
          const error = errors["cmaTitle"];
          return (
            <TextFieldBase
              label="CMA Title"
              autoFocus
              id={fieldRest.name}
              error={!!error}
              helperText={error?.message}
              {...fieldRest}
              inputRef={ref}
              fullWidth
              placeholder="CMA Title"
            />
          );
        }}
      />
    </form>
  );
};

import { useAppContext } from "./AppContext";
import { ThemeProvider, ConfirmDialog } from "@avenue-8/ui-2";

export const AppConfirmDialog = () => {
  const { state } = useAppContext();

  return (
    <ThemeProvider darkMode={false}>
      <ConfirmDialog
        open={state.confirm?.resolve != null}
        dialogTitle={state.confirm?.title}
        onCancel={() => state.confirm?.resolve?.(false)}
        onConfirm={() => state.confirm?.resolve?.(true)}
        dialogMessage={state.confirm?.message}
        confirmLabel={state.confirm?.confirmButtonText}
        cancelLabel={state.confirm?.cancelButtonText}
      />
    </ThemeProvider>
  );
};

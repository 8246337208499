import React from "react";
import { useCreateCMALogic } from "src/modules/cma-v2/hooks/useCreateCMALogic";
import {
  convertEditComparablePropertyFormModelToComparablePropertyRequestDto,
  EditComparablePropertyFormModel,
  getPropertyPhotos,
} from "./comparable-property-converter";
import { EditComparablePropertyModal } from "./EditComparablePropertyModal";
import { useEditComparablePropertyLogic } from "./useEditComparablePropertyLogic";

export const EditComparableProperty = () => {
  const [hasCreatedComparableProperty, setHasCreatedComparableProperty] = React.useState(false);
  const { state: createCmaState } = useCreateCMALogic();
  const {
    state,
    actions: {
      setComparableProperty,
      saveComparableProperty,
      uploadComparablePropertyImage,
      removeComparablePropertyImage,
      makeCoverPhotoInComparableProperty,
    },
  } = useEditComparablePropertyLogic();

  const comparableProperties = React.useMemo(
    () => createCmaState.cma?.comparableProperties ?? [],
    [createCmaState.cma?.comparableProperties]
  );

  React.useEffect(() => {
    state.comparableProperty?.mlsId &&
      setComparableProperty(
        comparableProperties.find((comp) => comp.mlsId === state.comparableProperty?.mlsId)
      );
  }, [setComparableProperty, comparableProperties, state.comparableProperty?.mlsId]);

  const defaultValues = React.useMemo(
    () => ({ ...state.editComparablePropertyForm }),
    [state.editComparablePropertyForm]
  );

  const onClose = () => {
    setComparableProperty(undefined);
  };

  const onSave = async (data: EditComparablePropertyFormModel) => {
    const propertyData = convertEditComparablePropertyFormModelToComparablePropertyRequestDto(data);
    await saveComparableProperty({ data: propertyData, id: createCmaState.cmaId! });
    if (!propertyData.mlsId) setHasCreatedComparableProperty(true);
  };

  React.useEffect(() => {
    if (hasCreatedComparableProperty) {
      const selectedComps = comparableProperties.slice(-1)[0];
      setComparableProperty(selectedComps);
      setHasCreatedComparableProperty(false);
    }
  }, [hasCreatedComparableProperty, comparableProperties, setComparableProperty]);

  const handleOnUpload = async (data: FormData) =>
    uploadComparablePropertyImage({
      id: createCmaState.cmaId ?? "",
      mlsId: `${state.comparableProperty?.mlsSource}:${state.comparableProperty?.mlsId}`,
      data,
    });

  const handleOnRemovePropertyPhoto = async (params: { photoId: string }) =>
    removeComparablePropertyImage({
      id: createCmaState.cmaId ?? "",
      mlsId: `${state.comparableProperty?.mlsSource}:${state.comparableProperty?.mlsId}`,
      photoId: params.photoId,
    });

  const handleOnMakeCoverPhoto = async (params: { photoId: string }) =>
    makeCoverPhotoInComparableProperty({
      id: createCmaState.cmaId ?? "",
      mlsId: `${state.comparableProperty?.mlsSource}:${state.comparableProperty?.mlsId}`,
      photoId: params.photoId,
    });

  return (
    <EditComparablePropertyModal
      open={Boolean(state.comparableProperty)}
      defaultValues={defaultValues}
      isUploading={state.uploadComparablePropertyImageStatus === "loading"}
      isSaving={state.saveComparablePropertyStatus === "loading"}
      onClose={onClose}
      onSave={onSave}
      onUploadComparablePropertyImage={handleOnUpload}
      onRemoveComparablePropertyImage={handleOnRemovePropertyPhoto}
      onMakeCoverPhotoInComparableProperty={handleOnMakeCoverPhoto}
      propertyPhotos={state.comparableProperty ? getPropertyPhotos(state.comparableProperty) : []}
    />
  );
};

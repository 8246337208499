import React from "react";
import {
  DialogProps,
  DialogContent,
  CloseIcon,
  BlockingLoader,
  Pagination,
  toast,
} from "@avenue-8/ui-2";
import {
  isModelEnoughToSearch,
  SearchComparableFormModel,
  SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES,
} from "../Steps/Search/search-comparable-form-model";
import { FormProvider, useForm } from "react-hook-form";
import SearchSubjectPropertyResultItem from "./SearchSubjectPropertyResultItem";
import { PropertyDto } from "../../../../shared/apis/cma/generated";
import { NoResultsFound } from "./NoResultsFound";
import { usePropertySearchQueries } from "../Steps/Search/usePropertySearch";
import { buildPropertyKey } from "../../../../shared/utils/buildPropertyKey";
import { TwoStepSearchBarField } from "../../Fields/TwoStepSearchBarField";
import { usePropertySearchLogic } from "src/modules/cma-v2/hooks/usePropertySearchLogic";
import {
  CustomDialog,
  DialogCustomTitle,
  CloseButton,
  Form,
  ResultsText,
  Divider,
} from "./SearchSubjectPropertyModal.styles";

export interface SearchSubjectPropertyModalProps extends Omit<DialogProps, "onBackdropClick"> {
  cmaId: string;
  presentationType?: string;
  onClose: () => void;
  importSubjectProperty: (params: { mlsSource: string; mlsId: string; cmaId: string }) => void;
}

export const SearchSubjectPropertyModal = ({
  onClose,
  importSubjectProperty,
  presentationType,
  cmaId,
  ...rest
}: SearchSubjectPropertyModalProps) => {
  const defaultValues: SearchComparableFormModel = SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES;

  const formMethods = useForm<SearchComparableFormModel>({
    defaultValues,
    shouldUnregister: false,
  });
  const { getValues, handleSubmit, reset } = formMethods;
  const dialogContentRef = React.useRef<HTMLDivElement>(null);
  const {
    lastSearch,
    searchAndFiltersState,
    handleApplyFilters,
    handleApplySuggestionAsSearch,
    handleChangePage,
  } = usePropertySearchLogic();
  const { searchQuery } = usePropertySearchQueries(searchAndFiltersState, presentationType);

  const loading =
    searchQuery.status === "loading" || (searchQuery.isFetching && searchQuery.isPreviousData);

  const shouldShowResults = isModelEnoughToSearch(searchAndFiltersState);

  const handleSearch = (searchParams: SearchComparableFormModel): void => {
    handleApplyFilters(searchParams);
    const inputElement = document.activeElement as HTMLInputElement | undefined;
    inputElement?.blur();
  };

  const handleClickedPage = (clickedPage: number): void => {
    handleChangePage({ page: clickedPage });
    dialogContentRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  React.useEffect(() => {
    reset({ ...defaultValues, search: lastSearch });
  }, [defaultValues, lastSearch, reset]);

  const handleImport = async (subjectProperty: PropertyDto) => {
    if (subjectProperty.mlsId) {
      importSubjectProperty({
        mlsSource: subjectProperty.mlsSource || "",
        mlsId: subjectProperty.mlsId,
        cmaId,
      });
      onClose();
    } else {
      toast.error("This property does not have a MLS ID");
    }
  };

  const results = (searchQuery.data?.properties ?? []).map((property, i) => {
    return (
      <SearchSubjectPropertyResultItem
        key={`${i}-${buildPropertyKey(property)}`}
        onSelectProperty={() => handleImport(property)}
        property={property}
      />
    );
  });

  return (
    <CustomDialog fullWidth maxWidth={false} onClose={onClose} {...rest}>
      <DialogCustomTitle id="search-subject-property-dialog-title">
        Select Subject Property by MLS ID or Address
        <CloseButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </DialogCustomTitle>
      <DialogContent ref={dialogContentRef}>
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(handleSearch)} autoComplete="off" noValidate>
            <TwoStepSearchBarField
              acceptedSuggestions={["address", "mlsId"]}
              fieldProps={{
                placeholder: "Search for the property by MLS ID or Address",
                value: getValues().search,
              }}
              required
              handleSearch={(value: string) => handleSearch({ ...getValues(), search: value })}
              onSuggestionChosen={(chosenSuggestion) => {
                handleApplySuggestionAsSearch(getValues(), chosenSuggestion);
              }}
            />
          </Form>
        </FormProvider>

        {!loading && !!lastSearch && (
          <>
            <ResultsText>{`Results for "${lastSearch}"`}</ResultsText>
            <Divider />
          </>
        )}

        {shouldShowResults && results}

        {searchQuery.data?.properties?.length === 0 && (
          <NoResultsFound
            message={
              <>
                We could not find a property matching this address.
                <br />
                Please check for spelling or widen your search.
              </>
            }
          />
        )}

        {searchQuery.data?.meta && searchQuery.data.meta.totalPages > 1 && (
          <Pagination
            currentPage={searchQuery.data?.meta?.currentPage || 1}
            totalPages={Math.min(searchQuery.data?.meta?.totalPages || 1, 1000)}
            onPageClick={handleClickedPage}
          />
        )}
      </DialogContent>
      <BlockingLoader open={loading} message={"Searching properties..."} color={"black"} />
    </CustomDialog>
  );
};

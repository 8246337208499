import * as React from "react";
import { Button, Typography, styled, toast, BlockingLoader } from "@avenue-8/ui-2";
import { BackButton } from "../../../Button";
import { media } from "../../../../../shared/css-snippets";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { BottomBar } from "../../CreateCMA";
import { useHistory } from "react-router";
import { useCMARouteStepHelper } from "../../step-navigation-helper";
import { SharePresentationModal } from "./components/SharePresentationModal";
import { copyToClipboard } from "../../../../../shared/utils/copy-to-clipboard";
import { asyncPopupUrlOpener } from "../../../../../shared/utils/async-popup-opener";
import { usePresentationEditorLogic } from "./usePresentationEditorLogic";
import { PresentationMenu } from "./components/PresentationMenu";
import { printIframeUrl } from "../../../../../shared/utils/print-iframe-url";
import { printJS } from "../../../../../shared/services/print";
import { PresentationSaveIndicator } from "./PresentationSaveIndicator";
import { sectionWidgetRegistry } from "src/modules/presentation/presentation-generation-logic/sections/section-widget-registry";
import { useListenPresentationSync } from "./useListenPresentationSync";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p) => p.theme.av8.background};
  position: sticky;
  z-index: 11;
  top: 86px;
  padding-top: 11px;
  padding-bottom: 11px;
  z-index: 5;

  ${() => media.desktopDown} {
    top: 0px;
    padding-bottom: 20px;
  }
`;

export const PreviewButton = styled(Button)`
  padding-left: 60px;
  padding-right: 60px;
`;

export const PresentationContainer = styled.div`
  display: flex;
`;

export const SidebarContainer = styled.div`
  flex: 0 0 272px;
`;

export const InternalSpacing = styled.div`
  flex: 0 0 1rem;
`;

export const PreviewContainer = styled.div`
  flex: 1;
  overflow-x: hidden;
  color: ${(p) => p.theme.av8.typography.primaryColor};
`;

export const Frame = styled.div`
  margin: 0px auto;
  box-shadow: inset 0px 0px 3px
    ${(p) => (p.theme.av8.isDark ? "rgba(255,255,255,.14)" : "rgba(0,0,0,.14)")};
  overflow: hidden;
`;

export const HeaderActions = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

export const PresentationEditorLayout = ({
  sidebar,
  preview,
  maxFrameWidth,
}: {
  sidebar: React.ReactElement;
  preview: React.ReactElement;
  maxFrameWidth?: number;
}) => {
  const {
    state: presentationEditorState,
    actions: { sharePresentationByEmail, generatePresentationPdf },
    state,
  } = usePresentationEditorLogic();
  const [sharePresentationModalOpen, setSharePresentationModalOpen] = React.useState(false);
  const [isLoadingPdfToPrint, setIsLoadingPdfToPrint] = React.useState(false);
  const history = useHistory();
  const sourceData = state.loadState === "loaded" ? state.sourceData : undefined;
  const cmaRouteStepHelper = useCMARouteStepHelper(sourceData?.presentationType);
  const presentationLink = `${window.location.origin}/presentation/${sourceData?.id}/view`;

  const validatePresentationToPrint = (): boolean => {
    if (state.loadState !== "loaded") return false;

    let isValid = true;
    state.sections.forEach((section) => {
      const sectionWidget = sectionWidgetRegistry.get(section.type);
      if (sectionWidget && sectionWidget.getPrintPrognostic) {
        const printPrognostic = sectionWidget.getPrintPrognostic({
          sectionConfig: section.config,
        });
        if (printPrognostic && !printPrognostic.isValid) {
          toast.info(printPrognostic.message, { shouldDeduplicate: true });
          isValid = false;
        }
      }
    });

    return isValid;
  };

  const handleCopyLink = (from: "presentation-editor" | "share-presentation-modal") => {
    copyToClipboard(presentationLink);
    appEventEmitter.emit({
      eventType: "presentation-link-copied-to-clipboard",
      from,
      presentationType: sourceData?.presentationType ?? "",
    });
    toast.success("The link has been copied.");
  };

  const handleDownload = async () => {
    if (!validatePresentationToPrint()) return;
    appEventEmitter.emit({
      eventType: "presentation-download-button-clicked",
      presentationType: sourceData!.presentationType,
    });
    const { url } = await generatePresentationPdf({ isAttachment: true });
    window.location.href = url;
  };

  const getLocalUrl = (url: string) => {
    const baseUrl = "/presentation/print/";
    const urlParts = url.split("/");
    const presentationName = urlParts[urlParts.length - 1];
    const origin = window.location.origin;
    return `${origin}${baseUrl}${presentationName}`;
  };

  const handlePrintPDF = async () => {
    if (!validatePresentationToPrint()) return;
    if (process.env.NODE_ENV === "development") {
      printIframeUrl(`${process.env.REACT_APP_CMA_FRONTEND_URL}/samples/presentation-sample.pdf`);
    } else {
      const { url } = await generatePresentationPdf({ isAttachment: false });
      appEventEmitter.emit({
        eventType: "presentation-print-button-clicked",
        presentationType: sourceData!.presentationType,
      });
      printJS({
        printable: getLocalUrl(url),
        type: "pdf",
        setModalState: setIsLoadingPdfToPrint,
        fallbackPrintable: presentationLink,
        onPrintDialogClose: () => {
          setIsLoadingPdfToPrint(false);
        },
      });
    }
  };

  const handlePreviewButtonClick = async () => {
    appEventEmitter.emit({
      eventType: "presentation-preview-clicked",
      presentationType: sourceData?.presentationType ?? "",
    });
    asyncPopupUrlOpener({
      buildUrl: async () => presentationLink,
    });
  };

  const { pendingMutations } = useListenPresentationSync();

  return (
    <Layout>
      <HeaderContainer>
        <Typography variant="h5">Customize Presentation</Typography>
        <HeaderActions>
          <PresentationSaveIndicator
            isSaving={presentationEditorState.isSaving}
            updatedAt={sourceData?.updatedAt}
            message={
              Boolean(pendingMutations) && "Saving changes. The presentation will be ready soon..."
            }
          />
          <PreviewButton
            disabled={presentationEditorState.isSaving}
            variant="outlined"
            onClick={handlePreviewButtonClick}
            data-meta-action="preview-presentation"
          >
            Preview
          </PreviewButton>
          <PresentationMenu
            onCopyLink={() => handleCopyLink("presentation-editor")}
            onDownload={handleDownload}
            onPrintPDF={handlePrintPDF}
          />
        </HeaderActions>
      </HeaderContainer>
      <PresentationContainer>
        <SidebarContainer>{sidebar}</SidebarContainer>
        <InternalSpacing />
        <PreviewContainer>
          <Frame style={{ maxWidth: maxFrameWidth }}>{preview}</Frame>
        </PreviewContainer>
      </PresentationContainer>
      <BottomBar>
        <BackButton onClick={() => cmaRouteStepHelper.handleBackClick(history)}>Back</BackButton>
        <Button
          data-key="share-presentation-button"
          onClick={() => setSharePresentationModalOpen(true)}
        >
          Share Presentation
        </Button>
      </BottomBar>
      <SharePresentationModal
        open={sharePresentationModalOpen}
        onClose={() => setSharePresentationModalOpen(false)}
        onCopyPresentationLink={() => handleCopyLink("share-presentation-modal")}
        presentationLink={presentationLink}
        onSharePresentation={sharePresentationByEmail}
        presentationType={sourceData?.presentationType ?? ""}
      />
      <BlockingLoader
        open={isLoadingPdfToPrint}
        unmountOnExit={true}
        message="Please wait while we prepare your PDF to be printed."
      />
    </Layout>
  );
};

import { Grid, Typography, Popover as MuiPopover, styled } from "@avenue-8/ui-2";

export const Popover = styled(MuiPopover)`
  & .MuiPopover-paper {
    padding: 1rem;
    border-radius: 0.5rem;
    background: ${(p) => p.theme.av8.backgroundAlt};
    text-transform: uppercase;
    text-align: center;
    box-sizing: border-box;
    border: 1px solid ${(p) => p.theme.av8.input.borderColor};
    max-width: 240px;
    top: 10;
  }
`;
export const Trigger = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
  border: solid 1px ${(p) => p.theme.av8.secondaryColor};
  color: ${(p) => p.theme.av8.secondaryColor};
  border-radius: 50%;
`;
export const BreakdownContainer = styled(Grid)`
  margin-top: 2rem;
  text-align: left;
  gap: 0.5rem;
`;
export const BreakdownItem = styled.div`
  display: flex;
  width: 100%;
  text-transform: uppercase;
  align-items: center;
  gap: 1rem;
  &:last-child {
    border-top: solid 1px ${(p) => p.theme.av8.input.borderColor};
    padding: 1rem 0 0 0;
    margin-top: 0.5rem;
  }
  & > :last-child {
    text-transform: initial;
  }
`;
export const BreakdownLabel = styled(Typography)`
  font-size: 12px;
  flex: 1;
`;

import * as React from "react";
import { Box, Paper } from "@avenue-8/ui-2";
import { FloatingAgentBanner } from "./components/FloatingAgentBanner";
import { useDisplayMode } from "../../hooks/useMobile";
import { Footer } from "./components/Footer";
import { PresentationThemeProvider } from "./theme";
import { BackdropMenu } from "./components/BackdropMenu";
import ScrollSpy from "react-scrollspy";
import { SectionDivider } from "./components/SectionDivider";
import { SectionWrapper } from "./components/SectionWrapper";
import { SectionView } from "./components/SectionView";
import { PrintOff } from "./components/PrintOff";
import { baseStyledThemed as styled } from "../../../shared/theme";
import {
  Agent,
  Client,
  SectionView as SectionViewModel,
  PropertyPhoto,
} from "../../../presentation/presentation-generation-logic/models";
import { useNavbarLogic } from "./components/Navbar/useNavbarLogic";
import { Navbar } from "./components/Navbar";
interface Props {
  presentationId: string;
  displayMode?: "responsive" | "mobile" | "desktop" | "print" | "tablet";
  renderNav?: boolean;
  showDivider?: boolean;
  client: Client;
  agent: Agent;
  editMode?: boolean;
  sections: SectionViewModel[];
  galleries: { [key: string]: PropertyPhoto[] };
  subjectProperty: {
    id: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    zipCode: string;
    daysOnMarket: number;
    propertyPhoto?: string | null;
    state: string;
  };
  priceRange: {
    max: number | null;
    min: number | null;
  };
  updatedAt: Date;
}

const Root = styled.div<{ $displayMode: string }>`
  position: relative;
  max-width: 100%;
  @media print {
    @page {
      size: landscape;
      margin: 0;
    }
    margin: 0;
    -webkit-print-color-adjust: exact;
  }
  ${(p) => p.$displayMode === "print" && `margin: 0`}
`;

const BottomNavPaper = styled(Paper)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0.5rem;
  z-index: 6;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  @media print {
    display: none;
  }
`;

export const PresentationView = ({
  presentationId,
  displayMode: displayModeProp,
  renderNav: renderNavProp,
  showDivider: showDividerProp,
  sections,
  editMode,
  agent,
  galleries,
  updatedAt,
}: Props) => {
  const agentName = agent.name ?? "Avenue8";
  const agentPhotoSrc = agent.photo ?? "/images/logo/a8-logo.png";
  const showDivider = showDividerProp ?? false;
  const screenDisplayMode = useDisplayMode();
  const displayMode =
    displayModeProp && displayModeProp !== "responsive" ? displayModeProp : screenDisplayMode;
  const renderNav = renderNavProp ?? true;
  const footerId = `presentation-${presentationId}-footer`;
  const offset = 120;

  const {
    backdropMenuOpen,
    setBackdropMenuOpen,
    handleBackdropMenuSectionClick,
    setSectionAndScroll,
    sectionId,
    setSectionId,
    watchScroll,
  } = useNavbarLogic({
    initialSectionId: sections[0].id,
    offset,
  });

  const agentLineInfo = agent.licenseNumber ? `License Number ${agent.licenseNumber}` : undefined;
  const filteredSections = sections.filter((s) => !["agent-info"].includes(s.type));
  const sectionHeaders: Array<{ id: string; label: string }> = filteredSections.map(
    ({ id, title, navTitle, type }) => ({ id, label: navTitle || title || type })
  );

  const handleOpenMenuClick = () => {
    setBackdropMenuOpen(!backdropMenuOpen);
  };

  return (
    <PresentationThemeProvider mobile={displayMode === "mobile"}>
      <Root $displayMode={displayMode}>
        {renderNav && !editMode && displayMode === "desktop" && (
          <>
            <Navbar
              sectionId={sectionId}
              setSectionAndScroll={setSectionAndScroll}
              agentName={agentName}
              agentPhoto={agentPhotoSrc}
              agentLineInfo={agentLineInfo}
              sectionHeaders={sectionHeaders}
              position="fixed"
            />
            <PrintOff style={{ height: "6rem" }} />
          </>
        )}
        {renderNav && editMode && ["desktop", "print"].includes(displayMode) && (
          <>
            <Navbar
              sectionId={sectionId}
              setSectionAndScroll={setSectionAndScroll}
              agentName={agentName}
              agentPhoto={agentPhotoSrc}
              agentLineInfo={agentLineInfo}
              sectionHeaders={sectionHeaders}
              position="static"
              hideTabs
            />
          </>
        )}

        <Box hidden={true}>
          <ScrollSpy
            offset={-offset}
            onUpdate={(target: HTMLElement) => {
              if (watchScroll) {
                setSectionId(target?.id);
              }
            }}
            items={[
              // heroId,
              ...sections.map((x) => x.id),
              // footerId,
            ]}
          />
        </Box>

        {sections.map((section) => {
          return (
            <div key={section.id}>
              {showDivider && <SectionDivider />}
              <SectionWrapper
                visibility={section.visibility ?? true}
                // onEditClick={sectionEditHandler}
                // onVisibilityToggleClick={sectionVisibilityHandler}
                sectionId={section.id}
                data-testid={`presentation-section-view-${section.type}`}
              >
                <SectionView
                  section={section}
                  displayMode={displayMode}
                  galleries={galleries}
                  editMode={editMode}
                />
              </SectionWrapper>
            </div>
          );
        })}
        {showDivider && <SectionDivider />}
        <SectionWrapper sectionId={footerId}>
          <Footer id={footerId} updatedAt={updatedAt} mobile={displayMode === "mobile"} />
        </SectionWrapper>
        {renderNav && !editMode && ["mobile", "tablet"].includes(displayMode) && (
          <>
            <BottomNavPaper>
              <FloatingAgentBanner
                agentName={agentName}
                agentPhotoSrc={agentPhotoSrc}
                agentLineInfo={agentLineInfo}
                onOptionsClick={handleOpenMenuClick}
                optionsDisplayIcon={backdropMenuOpen ? "close" : "more"}
              />
            </BottomNavPaper>
            <BackdropMenu
              zIndex={5}
              headline="Jump to a Section"
              open={backdropMenuOpen}
              onBackdropClick={() => setBackdropMenuOpen(false)}
            >
              {sectionHeaders.map((x) => (
                <a
                  key={x.id}
                  data-section={x.id}
                  href={`#${x.id}`}
                  onClick={handleBackdropMenuSectionClick}
                >
                  {x.label}
                </a>
              ))}
            </BackdropMenu>
            <PrintOff style={{ height: "5rem" }} />
          </>
        )}
      </Root>
    </PresentationThemeProvider>
  );
};

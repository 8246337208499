import { Button, ButtonProps, ChevronIcon } from "@avenue-8/ui-2";

const BackButton = (props: ButtonProps) => {
  return (
    <Button variant="text" data-key="back-button" {...props}>
      <ChevronIcon rotate={180} />
      {props.children}
    </Button>
  );
};

export default BackButton;

export const getNextTitleNumber = (params: {
  titles: (string | undefined)[];
  prefix: string;
}): number => {
  const { titles, prefix } = params;
  const navTitleNumbers = titles
    .map((item) => Number(item?.split(prefix)[1] ?? 1))
    .sort((a, b) => a - b);

  const newTitleNumber =
    navTitleNumbers.length > 0 ? navTitleNumbers[navTitleNumbers.length - 1] + 1 : 1;

  return newTitleNumber;
};

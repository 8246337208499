import { useEffect } from "react";
import { baseStyledThemed as styled } from "../../shared/theme";
import { useHistory } from "react-router-dom";
import { useQueryParams, StringParam } from "use-query-params";
import { Avenue8BackgroundIcon } from "../../../assets/icons/A8BackgroundIcon";
import { useAuthContext } from "../../shared/contexts/AuthContext/AuthContext";
import { AuthCard, PageContainer } from "./style";
import { Loader, Typography } from "@avenue-8/ui-2";
import { cmaRoutes } from "src/modules/cma-v2/cma.routes";
import { authRoutes } from "../auth.routes";

const BackgroundIconContainer = styled.div`
  position: absolute;
  left: 50px;
  bottom: 0px;
`;

const CustomTypography = styled(Typography)`
  padding-bottom: 24px;
`;

const LoaderContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 2rem;
`;

export const LoginSuccessPage = () => {
  const { actions } = useAuthContext();
  const [queryParam] = useQueryParams({ token: StringParam, info: StringParam });
  const history = useHistory();

  useEffect(() => {
    if (queryParam.token) {
      actions.authenticate({
        authToken: queryParam.token as string,
        remember: localStorage.getItem("rememberMe") === "true",
        userInfo: JSON.parse(queryParam.info ? atob(queryParam.info) : "{}"),
      });
      history.push(cmaRoutes.dashboard.route);
    } else {
      history.push(authRoutes.authFailure.route);
    }
  });

  return (
    <PageContainer>
      <AuthCard>
        <CustomTypography variant="h3">MarketComp</CustomTypography>
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
        <Typography variant="h6">Authenticating...</Typography>
        <BackgroundIconContainer>
          <Avenue8BackgroundIcon />
        </BackgroundIconContainer>
      </AuthCard>
    </PageContainer>
  );
};

import { InfiniteData, useMutation, useQueryClient } from "react-query";
import { useAppContext } from "../../../../AppContext";
import { toast } from "@avenue-8/ui-2";
import { appEventEmitter } from "../../../../events/app-event-emitter";
import { getCMAApiClient } from "../../../shared/apis/cma/api-factories";
import { ListCMAResponseDto, ListCMAResponseItemDto } from "../../../shared/apis/cma/generated";

export const useDeleteCMA = ({
  getLoadedCMAItem,
}: {
  getLoadedCMAItem: (cmaId: string) => ListCMAResponseItemDto | undefined;
}) => {
  const queryClient = useQueryClient();
  const {
    actions: { confirm, watchPromise },
  } = useAppContext();

  const { mutate: deleteCMAMutate } = useMutation(
    async ({ cmaId }: { cmaId: string }) => {
      return await watchPromise(getCMAApiClient().cMAControllerDelete({ id: cmaId }));
    },
    {
      mutationKey: "delete-cma",
      onMutate: async ({ cmaId }) => {
        queryClient.cancelQueries(["cmas"]);
        const previousCMAs: InfiniteData<ListCMAResponseDto> | undefined =
          queryClient.getQueryData("cmas");
        if (!previousCMAs) return;
        queryClient.setQueryData<InfiniteData<ListCMAResponseDto> | null>("cmas", (old) => {
          if (old == null) return null;
          const optimisticUpdatedData: InfiniteData<ListCMAResponseDto> = {
            ...old,
            pages: old.pages.map((page) => {
              return {
                ...page,
                items: page.items.filter((item) => item.id !== cmaId),
              };
            }),
          };
          return optimisticUpdatedData;
        });
      },
      onError: (e) => {
        toast.error("Failed to delete.");
        console.error(e);
      },
      onSuccess: (_data, { cmaId }) => {
        appEventEmitter.emit({
          eventType: "cma-deleted",
          presentationType: getLoadedCMAItem(cmaId)!.presentationType,
        });
        toast.success("CMA successfully deleted.");
      },
    }
  );

  const deleteCMA = async ({ cmaId, cmaName }: { cmaId: string; cmaName: string }) => {
    const confirmed = await confirm({
      title: `Delete "${cmaName}"?`,
      message: "Are you sure you want to delete this CMA? This action cannot be undone.",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    });
    if (confirmed) deleteCMAMutate({ cmaId });
  };

  return deleteCMA;
};

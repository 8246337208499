import * as React from "react";
import { RouteComponentProps } from "react-router";
import { LoginSuccessPage } from "./components/LoginSuccessPage";
import { RouteModel } from "../../routes/routes";
import GenerateRoutes from "../../routes/GenerateRoutes";
import { ResetPassword } from "./components/ResetPassword";
import { LoginPage } from "./components/LoginPage";
import { ThemeProvider, toastStyles, styled } from "@avenue-8/ui-2";
import { AppBlockingLoader } from "../shared/components/AppBlockingLoader";
import { cmaRoutes } from "../cma-v2/cma.routes";
import { authRoutes } from "./auth.routes";

const Page = styled.div`
  background: ${(p) => p.theme.av8.background};
  min-height: 100vh;
  position: relative;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  ${toastStyles}
`;

const renderAgentLogin = () => {
  return <LoginPage />;
};

const renderForgoPasswordPassword = () => {
  return <LoginPage forgotPassword={true} />;
};

const renderAdminLoginSuccess = () => {
  return <LoginSuccessPage />;
};

const renderAdminLoginError = () => {
  return <LoginPage failure={true} />;
};

const renderResetPassword = (props: RouteComponentProps<any>) => {
  return <ResetPassword token={props.match.params.token} />;
};

const routes: RouteModel[] = [
  {
    path: authRoutes.auth.route,
    render: renderAgentLogin,
    exact: true,
    guest: true,
    redirect: cmaRoutes.dashboard.route,
  },
  {
    path: authRoutes.authSuccess.route,
    render: renderAdminLoginSuccess,
    exact: true,
  },
  {
    path: authRoutes.authFailure.route,
    render: renderAdminLoginError,
    exact: true,
  },
  {
    path: authRoutes.resetPassword.route,
    render: renderResetPassword,
    exact: true,
  },
  {
    path: authRoutes.forgotPassword.route,
    render: renderForgoPasswordPassword,
    exact: true,
  },
];

export const AuthModule = React.memo(() => {
  return (
    <ThemeProvider darkMode={false}>
      <AppBlockingLoader />
      <Page>
        <GenerateRoutes routes={routes} />
      </Page>
    </ThemeProvider>
  );
});

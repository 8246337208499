import * as React from "react";
import {
  Typography,
  Grid,
  Button,
  InputLabel,
  IconButton,
  CloseIcon,
  Dialog,
  Paper,
  styled,
} from "@avenue-8/ui-2";
import { Controller, useForm } from "react-hook-form";
import { TextFieldBase } from "../../../../TextFieldBase";
import { Link } from "react-router-dom";
import validator from "validator";
import { cmaRoutes } from "src/modules/cma-v2/cma.routes";
import { appEventEmitter } from "src/events/app-event-emitter";

const TransparentPaper = styled(Paper)`
  background-color: transparent;
  box-shadow: none;
  overflow: visible !important;
  pointer-events: none;
`;

const ConstrainedPaper = styled(Paper)`
  max-width: 760px;
  width: 100%;
  margin: 3rem auto;
  &:first-of-type {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
  pointer-events: all;
`;

const ConstrainedPaperSmall = styled(ConstrainedPaper)`
  max-width: 574px;
`;

const Card = styled.div`
  position: relative;
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 2rem 1rem 2rem;
`;

const CardContent = styled.div`
  padding: 0 2rem 2rem 2rem;
  &:last-child {
    padding: 0rem 2rem 2rem 2rem;
  }
  &:first-of-type {
    padding: 2rem;
  }
`;

type CardActionsProps = {
  $justify?: "center" | "flex-start" | "flex-end" | string;
};
const CardActions = styled.div<CardActionsProps>`
  display: flex;
  justify-content: ${(p) => p.$justify ?? "flex-end"};
  padding: 0rem 2rem 2rem 2rem;
`;

const Form = styled.form`
  padding: 0.5rem 0 0;
`;

const FormItem = styled(Grid)`
  &:not(:last-child) {
    padding-bottom: 1.5rem;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 1.25rem;
  top: 1rem;
`;

const ButtonLink = styled(Button)`
  padding: 0.5rem 1rem;
  font-weight: bold;
` as typeof Button & typeof Link;

const InputGroup = styled.div`
  display: flex;
  gap: 1rem;
  > :last-child {
    min-width: 140px;
  }
`;

const OneLineTextarea = styled(TextFieldBase)`
  textarea {
    padding: 10px 10px 1px 16px !important;
  }
`;

const validateEmailList = (value: string) => {
  const MAX_NUMBER_OF_EMAILS = 20;
  const emails = value.split(",").map((email) => email.trim());
  const invalidEmails = emails.filter((email) => !validator.isEmail(email));

  if (invalidEmails.length) return "Invalid Email(s) Address(es)";
  if (emails.length > MAX_NUMBER_OF_EMAILS)
    return `You can only share ${MAX_NUMBER_OF_EMAILS} emails at a time`;

  return undefined;
};

export interface SharePresentationModalProps {
  onClose: () => void;
  onCopyPresentationLink: () => void;
  onSharePresentation: (params: { emails: string[]; agentComment?: string }) => Promise<any>;
  presentationLink: string;
  open: boolean;
  presentationType: string;
}

interface SharePresentationModalModel {
  clientEmails: string;
  message: string;
}

const defaultValues: SharePresentationModalModel = {
  clientEmails: "",
  message: "",
};

export const SharePresentationModal = ({
  open,
  presentationLink,
  onClose,
  onCopyPresentationLink,
  onSharePresentation,
  presentationType,
}: SharePresentationModalProps) => {
  const { control, handleSubmit, errors, reset } = useForm({ defaultValues, mode: "onChange" });
  const [submited, setSubmited] = React.useState(false);

  const handleSharePresentation = async (data: SharePresentationModalModel) => {
    const emails = data.clientEmails.split(",").map((email) => email.trim());
    await onSharePresentation({
      emails,
      agentComment: data.message,
    });
    reset({ ...defaultValues });
    setSubmited(true);
  };

  const handleClose = () => {
    onClose();
    setSubmited(false);
  };

  return (
    <>
      <Dialog
        PaperComponent={TransparentPaper}
        maxWidth="lg"
        fullWidth={true}
        scroll="body"
        open={open && !submited}
        onClose={handleClose}
        BackdropProps={{
          onEntered: () => {
            appEventEmitter.emit({
              eventType: "presentation-share-modal-opened",
              presentationType,
            });
          },
        }}
      >
        <ConstrainedPaper>
          <Card>
            <CardTitle>
              <Typography variant="h6">Share Your Presentation</Typography>
              <CloseButton aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </CloseButton>
            </CardTitle>
            <CardContent>
              <Form id="presentation-email-message" onSubmit={(e) => e.preventDefault()}>
                <FormItem>
                  <InputLabel htmlFor="clientEmails">Client Email(s):</InputLabel>
                  <Controller
                    name="clientEmails"
                    control={control}
                    rules={{ validate: { required: (v) => validateEmailList(v) } }}
                    render={({ ref, ...rest }) => (
                      <OneLineTextarea
                        placeholder="Enter client email(s) separated by commas"
                        errors={errors}
                        inputRef={ref}
                        multiline={true}
                        {...rest}
                        inputProps={{ maxLength: 1000 }}
                      />
                    )}
                  />
                </FormItem>
                <FormItem>
                  <InputLabel htmlFor="message">Custom Message to Client:</InputLabel>
                  <Controller
                    name="message"
                    control={control}
                    render={({ ref, ...rest }) => (
                      <TextFieldBase
                        placeholder="Enter custom message to client"
                        errors={errors}
                        inputRef={ref}
                        multiline={true}
                        minRows={2}
                        maxRows={8}
                        inputProps={{ maxLength: 500 }}
                        {...rest}
                      />
                    )}
                  />
                </FormItem>
              </Form>
            </CardContent>
            <CardActions>
              <Button
                form="presentation-email-message"
                onClick={handleSubmit(handleSharePresentation)}
                data-meta-action="send-presentation-email"
              >
                Send
              </Button>
            </CardActions>
          </Card>
        </ConstrainedPaper>

        <ConstrainedPaper>
          <Card>
            <CardContent>
              <FormItem>
                <InputLabel htmlFor="presentationLink">Link to Presentation:</InputLabel>
                <InputGroup>
                  <TextFieldBase
                    id="presentationLink"
                    placeholder="Link to presentation"
                    value={presentationLink}
                    inputProps={{ readOnly: true }}
                  />
                  <Button
                    onClick={onCopyPresentationLink}
                    data-meta-action="copy-presentation-link"
                  >
                    Copy Link
                  </Button>
                </InputGroup>
              </FormItem>
            </CardContent>
            <CardActions $justify="center">
              <ButtonLink
                to={cmaRoutes.dashboard.route}
                component={Link}
                variant="text"
                data-meta-action="return-to-dashboard"
              >
                Return to Dashboard
              </ButtonLink>
            </CardActions>
          </Card>
        </ConstrainedPaper>
      </Dialog>

      <PresentationSharedModal open={open && submited} onClose={handleClose} />
    </>
  );
};

const PresentationSharedModal = ({ open, onClose }: { open: boolean; onClose: () => void }) => {
  return (
    <Dialog
      PaperComponent={TransparentPaper}
      maxWidth="lg"
      fullWidth={true}
      scroll="body"
      open={open}
      onClose={onClose}
    >
      <ConstrainedPaperSmall>
        <Card>
          <CardTitle>
            <Typography variant="h6">Your presentation has been shared.</Typography>
          </CardTitle>
          <CardContent>
            <Typography variant="body2">
              Your client will receive an e-mail with your presentation.
            </Typography>
          </CardContent>
          <CardActions>
            <Button to={cmaRoutes.dashboard.route} component={Link}>
              Return to Dashboard
            </Button>
          </CardActions>
        </Card>
      </ConstrainedPaperSmall>
    </Dialog>
  );
};

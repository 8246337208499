import * as React from "react";
import { Redirect, Route, Switch } from "react-router";
import { roleIsAllowed } from "../modules/auth/components/RoleAuthorized";
import { NotFound } from "../modules/shared/components/NotFound";
import { useAuthContext } from "../modules/shared/contexts/AuthContext/AuthContext";
import { Role, RouteModel } from "./routes";

interface Props {
  routes: RouteModel[];
  userRole?: Role | string;
}

const GenerateRoutes = ({ routes, userRole }: Props) => {
  const { actions: authActions } = useAuthContext();
  return (
    <Switch>
      {routes.map((route) => {
        const exact = route.exact === false ? route.exact : true;
        if (route.roles) {
          const shouldRender = roleIsAllowed(route.roles, userRole);
          return (
            <Route
              {...route}
              render={shouldRender ? route.render : () => <NotFound />}
              key={route.path}
              exact={exact}
            />
          );
        } else if (route.guest && authActions.isAuthenticated()) {
          return (
            <Route
              {...route}
              render={() => <Redirect to={route.redirect || "/"} />}
              key={route.path}
              exact={exact}
            />
          );
        } else {
          return <Route {...route} render={route.render} key={route.path} exact={exact} />;
        }
      })}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default React.memo(GenerateRoutes);

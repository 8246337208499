import { FormControlLabel, IconButton, styled } from "@avenue-8/ui-2";
import { Card, CardActions, CardContent } from "@mui/material";

export const TooltipCard = styled(Card)`
  max-width: 560px;
  min-width: 400px;
  position: relative;
`;
export const CustomCardContent = styled(CardContent)`
  padding: 1.5rem;
  padding-right: 7rem;
  p {
    margin-top: 1rem;
  }
`;
export const CustomFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 3rem;
  }
  & .MuiCheckbox-root {
    padding-left: 0;
    left: 0;
  }
  & .MuiFormControlLabel-label {
    margin-left: 1rem;
  }
`;
export const CustomCardActions = styled(CardActions)`
  padding: 1.5rem;
  justify-content: space-between;
`;
export const CardActionsButtons = styled.div`
  display: flex;
  gap: 0.5rem;
`;
export const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 1rem;
  top: 1rem;
`;

import { generatePath, matchPath } from "react-router";

/**
 * Create a object able to generate or match a route path.
 * When you match, you recieve the params of the route of type T.
 * When you generate, you must provide the params of type T.
 * @param route - a react-router route path - like /my-path/:someId/:someOtherId
 */
export const generateRoute = <T>(route: string) => {
  return {
    route,
    generate: (routeParams: T) => generatePath(route, routeParams),
    match: (path: string | string) =>
      matchPath<T>(path, {
        path: route,
        exact: true,
        sensitive: true,
      }),
  };
};

import { Typography, ImageUnavailable, styled } from "@avenue-8/ui-2";
import { format } from "date-fns";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { listingStatusColor } from "../../../../../../../shared/css-snippets";

const RatioImage = styled.div`
  display: block;
  padding-bottom: 66%;
  position: relative;
  width: 100%;
  cursor: pointer;
  & > img,
  & div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }
`;

const ComparisonItemLabelBox = styled.div<{ $flex?: boolean }>`
  margin-top: 12px;

  ${(p) =>
    p.$flex &&
    `
      display: flex;

      & div:not(:first-of-type) {
        margin-left: 30px;
      }
    `}
`;

const ComparisonItemAddressLabelBox = styled(ComparisonItemLabelBox)`
  height: 85px;
`;

const ComparisonItemNeighborhoodLabelBox = styled(ComparisonItemLabelBox)`
  height: 56px;
`;

const ComparisonItemTitle = styled(Typography)`
  font-weight: 500;
  font-size: 0.875rem;
`;

const ComparisonItemLabel = styled(Typography)`
  font-size: 0.7rem;
  text-transform: uppercase;
  line-height: 1.43;
  font-weight: 400;
  letter-spacing: 2.5px;
  color: ${(p) => p.theme.av8.secondaryColor};
`;

const ComparisonItemListingStatus = styled(Typography)<{ $listingStatus: string | undefined }>`
  font-size: 0.75rem !important;
  text-transform: uppercase;
  line-height: 1.43;
  font-weight: bolder;
  letter-spacing: 2.5px;
  max-height: 40px;

  ${(p) => listingStatusColor(p.$listingStatus)}
`;

const ComparisionItem = styled.div<{ $highlight: boolean | undefined }>`
  padding: 32px 24px 12px 24px;
  line-height: 1.5rem;
  max-width: 170px;
  min-width: 169px;
  font-size: 0.7rem;
  box-sizing: content-box;
  height: 100%;
`;

export interface SinglePropertyProps {
  propertyData?: PropertyDto;
  isSubjectProperty?: boolean;
}

export const SideBySideItem = (props: SinglePropertyProps) => {
  const { isSubjectProperty, propertyData } = props;
  const gallery = propertyData?.images ?? [];

  return (
    <ComparisionItem $highlight={isSubjectProperty}>
      <RatioImage>
        {gallery?.[0] ? (
          <img alt={gallery?.[0]?.caption ?? ""} src={gallery?.[0]?.cdnUrl || gallery?.[0]?.url} />
        ) : (
          <ImageUnavailable />
        )}
      </RatioImage>
      <ComparisonItemAddressLabelBox>
        <ComparisonItemTitle variant="body1">
          {propertyData?.addressLine1}
          {propertyData?.addressLine2 ? ` Unit ${propertyData?.addressLine2}` : ""}
        </ComparisonItemTitle>
        <Typography variant="body1">
          {propertyData?.city && propertyData?.state
            ? `${propertyData?.city}, ${propertyData?.state} `
            : ""}
          {propertyData?.zipCode}
        </Typography>
      </ComparisonItemAddressLabelBox>
      <ComparisonItemLabelBox>
        <ComparisonItemLabel variant="body2">List Price</ComparisonItemLabel>
        <Typography variant="body1">
          {propertyData?.listPrice ? `$${propertyData?.listPrice.toLocaleString("en-US")}` : "-"}
        </Typography>
      </ComparisonItemLabelBox>
      <ComparisonItemLabelBox>
        <ComparisonItemLabel variant="body2">Sold Price</ComparisonItemLabel>
        <Typography variant="body1">
          {propertyData?.soldPrice ? `$${propertyData?.soldPrice.toLocaleString("en-US")}` : "-"}
        </Typography>
      </ComparisonItemLabelBox>
      <ComparisonItemLabelBox>
        <ComparisonItemLabel variant="body2">Listing Status</ComparisonItemLabel>
        {propertyData?.listingStatus === "Sold" ? (
          <ComparisonItemListingStatus variant="body1" $listingStatus={propertyData?.listingStatus}>
            {propertyData?.listingStatus && propertyData?.soldDate
              ? `${propertyData?.listingStatus} ${format(propertyData?.soldDate, "MM/dd/yyyy")}`
              : propertyData?.listingStatus
              ? propertyData?.listingStatus
              : "-"}
          </ComparisonItemListingStatus>
        ) : (
          <ComparisonItemListingStatus variant="body1" $listingStatus={propertyData?.listingStatus}>
            {propertyData?.listingStatus && propertyData?.listDate
              ? `${propertyData?.listingStatus} ${format(propertyData?.listDate, "MM/dd/yyyy")}`
              : propertyData?.listingStatus
              ? propertyData?.listingStatus
              : "-"}
          </ComparisonItemListingStatus>
        )}
      </ComparisonItemLabelBox>
      <ComparisonItemLabelBox $flex>
        <div>
          <ComparisonItemLabel variant="body2">Beds</ComparisonItemLabel>
          <Typography variant="body1">{propertyData?.beds ?? "-"}</Typography>
        </div>
        <div>
          <ComparisonItemLabel variant="body2">Baths</ComparisonItemLabel>
          <Typography variant="body1">{propertyData?.baths ?? "-"}</Typography>
        </div>
      </ComparisonItemLabelBox>
      <ComparisonItemLabelBox>
        <ComparisonItemLabel variant="body2">Property Type</ComparisonItemLabel>
        <Typography variant="body2" color="primary">
          {propertyData?.propertyType ?? "-"}
        </Typography>
      </ComparisonItemLabelBox>
      <ComparisonItemNeighborhoodLabelBox>
        <ComparisonItemLabel variant="body2">Neighborhood</ComparisonItemLabel>
        <Typography variant="body2" color="primary">
          {propertyData?.neighborhood ?? "-"}
        </Typography>
      </ComparisonItemNeighborhoodLabelBox>
      <ComparisonItemLabelBox>
        <ComparisonItemLabel variant="body2">Year Built</ComparisonItemLabel>
        <Typography variant="body2" color="primary">
          {propertyData?.yearBuilt ?? "-"}
        </Typography>
      </ComparisonItemLabelBox>
    </ComparisionItem>
  );
};

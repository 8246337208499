import * as React from "react";
import { Select, InputLabel, SelectOption, SelectProps, FormHelperText } from "@avenue-8/ui-2";
import { Controller, useFormContext } from "react-hook-form";
import { useListingStatusQueries } from "../../queries/useListingStatusQueries";
import { ListingStatusItemDto } from "src/modules/shared/apis/cma/generated";

const renderListingStatusOptions = (list?: Array<ListingStatusItemDto>) => {
  return list?.map((item) => {
    return (
      <SelectOption value={item.listingStatus} key={`listing-status-${item.id}`}>
        {item.listingStatus}
      </SelectOption>
    );
  });
};

export type ListingStatusFieldSelectProps = {
  label?: string;
  name?: string;
  selectProps?: SelectProps;
};
export const ListingStatusField = (props: ListingStatusFieldSelectProps) => {
  const { label = "Status", name = "listingStatus", selectProps } = props;
  const { errors } = useFormContext();
  const { listingStatusesQuery } = useListingStatusQueries();
  const listingStatuses = listingStatusesQuery.data?.listingStatuses || [];

  return (
    <>
      <InputLabel htmlFor={name} id="listing-status-label">
        {label}
      </InputLabel>
      {listingStatuses.length ? (
        <Controller
          name={name}
          rules={{ required: selectProps?.required === false ? false : "Missing Status" }}
          render={({ ref, ...rest }) => (
            <Select
              labelId="listing-status-label"
              fullWidth
              inputRef={ref}
              {...rest}
              error={!!errors[name]}
              {...selectProps}
              onBlur={(e) => {
                selectProps?.onBlur?.(e);
                rest.onBlur();
              }}
            >
              {renderListingStatusOptions(listingStatuses)}
            </Select>
          )}
        />
      ) : (
        <Select value="" disabled />
      )}
      <FormHelperText error={!!errors[name]}>{errors[name]?.message}</FormHelperText>
    </>
  );
};

import { calculateMonthlies } from "../../shared/recurring-costs-helper";
import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { KeyFeaturesSectionConfig } from "./key-features-section-config";
import { KeyFeaturesSectionView } from "./key-features-section-view";
import { v4 as uuidv4 } from "uuid";

export class KeyFeaturesSectionWidget
  implements SectionWidget<"key-features", KeyFeaturesSectionConfig, KeyFeaturesSectionView>
{
  type: "key-features" = "key-features";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  buildDefaultConfig(): KeyFeaturesSectionConfig {
    return {
      type: "key-features",
      navTitle: "Key Features",
      id: uuidv4(),
      visibility: true,
      showListPrice: true,
      showSoldPrice: true,
      showYearBuilt: true,
      showPropertyType: false,
      showNeighborhood: true,
      showLivingSpace: true,
      showBeds: true,
      showBaths: true,
      showGarageParkingSpaces: true,
      showLotSize: false,
      showPricePerLivingSpaceSqft: true,
      showHOAFees: true,
      showDoormanAttendance: false,
    };
  }

  buildView({
    sectionConfig: config,
    context,
  }: SectionViewBuilderContext<KeyFeaturesSectionConfig, null>): KeyFeaturesSectionView {
    if (config.type !== "key-features") throw new Error("Unexpected section type.");

    const { featuredProperty } = context.sourceData;

    const { id, type, title, visibility } = config;

    const {
      showBaths = true,
      showBeds = true,
      showGarageParkingSpaces = true,
      showHOAFees = true,
      showLivingSpace = true,
      showNeighborhood = true,
      showPricePerLivingSpaceSqft = true,
      showListPrice = true,
      showSoldPrice = true,
      showYearBuilt = true,
      showLotSize = false,
      showPropertyType = false,
      showDoormanAttendance = false,
      showRoomsTotal = false,
      showFlipTaxRemarks = false,
      showRecurringCosts = true,
    } = config;

    const view: KeyFeaturesSectionView = {
      id,
      type,
      baths: featuredProperty.baths,
      beds: featuredProperty.beds,
      navTitle: "Key Features",
      title,
      visibility,
      description: featuredProperty.description,
      garageParkingSpaces: featuredProperty.garageParkingSpaces,
      hoaFees: featuredProperty.hoaFees,
      livingSpace: featuredProperty.livingSpace,
      lotSize: featuredProperty.lotSize,
      neighborhood: featuredProperty.neighborhood,
      yearBuilt: featuredProperty.yearBuilt,
      propertyType: featuredProperty.propertyType,
      costPerInternalSqFt: featuredProperty.costPerInternalSqFt,
      listPrice: featuredProperty.currentPrice,
      soldPrice: featuredProperty.soldPrice,
      hasDoormanAttendance: featuredProperty.hasDoormanAttendance,
      roomsTotal: featuredProperty.roomsTotal,
      flipTaxRemarks: featuredProperty.flipTaxRemarks,
      recurringCosts: featuredProperty.recurringCosts,
      totalMonthlies: calculateMonthlies(featuredProperty.recurringCosts ?? []),
      preferences: {
        showBaths,
        showBeds,
        showGarageParkingSpaces,
        showHOAFees,
        showLivingSpace,
        showNeighborhood,
        showPricePerLivingSpaceSqft,
        showListPrice,
        showSoldPrice,
        showYearBuilt,
        showLotSize,
        showPropertyType,
        showDoormanAttendance,
        showRoomsTotal,
        showFlipTaxRemarks,
        showRecurringCosts,
      },
    };
    return view;
  }
}

import * as React from "react";
import { Button, ButtonProps, styled } from "@avenue-8/ui-2";
import { DesktopMac, PhoneAndroid, Print } from "@mui/icons-material";

type Value = "responsive" | "desktop" | "mobile" | "print" | "tablet";

interface Props {
  value?: Value;
  onValueChange?: (value: Value | null) => void;
}

const FixedPaddingButton = styled(Button)<{ selected: boolean }>`
  min-width: 0;
  width: 60px;
  border-width: 1px;
  border-color: ${(p) => p.theme.av8.borderColor};
  color: ${(p) =>
    p.selected ? p.theme.av8.typography.primaryColor : p.theme.av8.typography.secondaryColor};
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
`;

export const DevicePicker = ({ value, onValueChange }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const inputValue = e.currentTarget.dataset.key as any;
    onValueChange?.(value === inputValue ? null : inputValue);
  };

  const DeviceButton = ({
    selected,
    ...rest
  }: Omit<ButtonProps, "variant"> & { selected: boolean }) => {
    return (
      <FixedPaddingButton selected={selected} variant={selected ? "outlined" : "text"} {...rest} />
    );
  };

  return (
    <ButtonContainer>
      <DeviceButton data-key="desktop" selected={value === "desktop"} onClick={handleClick}>
        <DesktopMac />
      </DeviceButton>
      <DeviceButton data-key="mobile" selected={value === "mobile"} onClick={handleClick}>
        <PhoneAndroid />
      </DeviceButton>
      <DeviceButton data-key="print" selected={value === "print"} onClick={handleClick}>
        <Print />
      </DeviceButton>
    </ButtonContainer>
  );
};

export type TeamProfile = {
  name: string;
  slug: string;
  logo: string;
  website: string | null;
  phoneNumber: string | null;
  licenseNumber: string | null;
  description: string | null;
};

const teamsObj: { [key: string]: TeamProfile } = {
  "red-hat-real-estate": {
    name: "Red Hat Real Estate",
    slug: "red-hat-real-estate",
    logo: "red-hat-real-estate.png",
    website: "https://www.redhatrealestate.com/",
    phoneNumber: "415-990-4495",
    licenseNumber: null,
    description: null,
  },
  "range-homes": {
    name: "Range Homes",
    slug: "range-homes",
    logo: "range-homes.png",
    website: "https://rangehomes.com/",
    phoneNumber: "925-226-8568",
    licenseNumber: "02111757",
    description: null,
  },
  "urban-hillsides-real-estate": {
    name: "Urban Hillsides Real Estate",
    slug: "urban-hillsides-real-estate",
    logo: "urban-hillsides-real-estate.png",
    website: "https://urbanhillsides.com/",
    phoneNumber: "323-386-4663",
    licenseNumber: null,
    description: null,
  },
  "king-realty-group": {
    name: "King Realty Group",
    slug: "king-realty-group",
    logo: "king-realty-group.png",
    website: "https://king-realtygroup.com/",
    phoneNumber: "415-282-2280",
    licenseNumber: "01384425",
    description: null,
  },
  "the-whipple-group": {
    name: "The Whipple Group",
    slug: "the-whipple-group",
    logo: "the-whipple-group.png",
    website: "https://www.thewhipplegroup.com/",
    phoneNumber: "562-972-4336",
    licenseNumber: "01355505",
    description: null,
  },
  "kindred-realty": {
    name: "Kindred Realty",
    slug: "kindred-realty",
    logo: "kindred-realty.png",
    website: "https://www.kindredrealtors.com/",
    phoneNumber: "510-439-9637",
    licenseNumber: "02023006",
    description: null,
  },
};

export const teamsMap = new Map<string, TeamProfile>(Object.entries(teamsObj));

export const teams = Object.values(teamsObj);

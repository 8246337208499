import { NeighborhoodMapSectionWidget } from "./neighborhood-map/neighborhood-map-section-widget";
import { InsightsSectionWidget } from "./insights/insights-section-widget";
import { FreeTextSectionWidget } from "./free-text/free-text-section-widget";
import { GeneralHeaderSectionWidget } from "./general-header/general-header-section-widget";
import { HeaderSectionWidget } from "./header/header-section-widget";
import { SectionWidget } from "./section-widget";
import { KeyFeaturesSectionWidget } from "./key-features/key-features-section-widget";
import { SideBySideComparisonSectionWidget } from "./side-by-side-comparison/side-by-side-comparison-section-widget";
import { FreeHtmlTextSectionWidget } from "./free-html-text/free-html-text-section-widget";
import { TeamProfileSectionWidget } from "./team-profile/team-profile-section-widget";

const registry = new Map<string, SectionWidget<any, any, any>>();
const register = (sectionWidget: SectionWidget<any, any, any>) => {
  registry.set(sectionWidget.type, sectionWidget);
};

register(new HeaderSectionWidget());
register(new GeneralHeaderSectionWidget());
register(new FreeTextSectionWidget()); // Deprecated (keeping for backwards compatibility)
register(new InsightsSectionWidget());
register(new KeyFeaturesSectionWidget());
register(new NeighborhoodMapSectionWidget());
register(new SideBySideComparisonSectionWidget());
register(new FreeHtmlTextSectionWidget());
register(new TeamProfileSectionWidget());

//here we can use feature flag to register optional section widgets

export const sectionWidgetRegistry = registry;

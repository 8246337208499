export type MapFilter =
  | {
      shape: "circle";
      center: { lat: number; lng: number };
      /**
       * Radius in meters
       */
      radius: number;
    }
  | {
      shape: "polygon";
      points: Array<{ lat: number; lng: number }>;
    }
  | {
      shape: "none";
    };

export const INITIAL_MAP_FILTERS: MapFilter = { shape: "none" };

export const INITIAL_FORM_FILTERS = {
  sold: false as boolean | string,
  active: false as boolean | string,
  pending: false as boolean | string,
  contingent: false as boolean | string,
  listLastDays: "",
  soldLastDays: "",
  propertyTypes: [] as (number | boolean)[],
  doorman: "",
  mlsArea: "",
  priceMin: "",
  priceMax: "",
  bedsMin: "",
  bedsMax: "",
  bathsMin: "",
  bathsMax: "",
  garageSpacesMin: "",
  garageSpacesMax: "",
  livingSpaceMin: "",
  livingSpaceMax: "",
  lotSizeMin: "",
  lotSizeMax: "",
  yearBuiltMin: "",
  yearBuiltMax: "",
  neighborhood: "",
  smartSearch: false,
  sameBuildingSearch: false,
  sameBuildingAddress: "", // this is not included in the request
  businessType: "",
  /**
   * radius in miles
   */
  radius: "",
};

export const INITIAL_INDIRECT_FILTERS = {
  city: "",
  county: "",
  state: "",
};

export const INITIAL_GEOGRAPHICAL_FILTERS = {
  neighborhood: INITIAL_FORM_FILTERS.neighborhood,
  mlsArea: INITIAL_FORM_FILTERS.mlsArea,
  city: INITIAL_INDIRECT_FILTERS.city,
  state: INITIAL_INDIRECT_FILTERS.state,
  county: INITIAL_INDIRECT_FILTERS.county,
  radius: INITIAL_FORM_FILTERS.radius,
};

import * as React from "react";
import { Step } from "react-joyride";
import { GuidedTour } from "src/modules/shared/components/GuidedTour";

const steps: Step[] = [
  {
    title: "Start by using the search bar",
    content: "",
    target: "#search-field-wrapper",
    disableBeacon: true,
  },
  {
    title: "You can also start by doing a map search",
    content: "",
    target: "#map-search-wrapper",
    placement: "left",
  },
  {
    title: "Refine your search by applying filters",
    content: "",
    target: "#open-filters-button",
  },
  {
    title: "Can't find what you're looking for?",
    content: "Add a manual comparable property.",
    target: "#manually-add-comparable-property-copy-wrapper",
  },
];

export const GuidedTourPropertySearch = () => {
  return <GuidedTour steps={steps} guidedTourSlug="best-ways-to-search" runTimeout={250} />;
};

import { Box, CircularProgress } from "@avenue-8/ui-2";
import * as React from "react";
import { baseStyledThemed as styled } from "../../../shared/theme";
import { PresentationView as PresentationViewModel } from "../../../presentation/presentation-generation-logic/models";
import { getApiClient } from "../../../shared/apis/presentation-api-client/api-client-factory";
import { PresentationView } from "./PresentationView";
import { appEventEmitter } from "../../../../events/app-event-emitter";

const PresentationViewWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden;
  @media print {
    background-color: #fff;
  }
`;

export const SmartPresentationView = ({ presentationId }: { presentationId: string }) => {
  const [presentation, setPresentation] = React.useState<PresentationViewModel>();
  const [loadError, setLoadError] = React.useState<boolean>(false);

  React.useEffect(() => {
    const client = getApiClient();
    client
      .getPresentationView(presentationId)
      .then((fetchedPresentation) => {
        setPresentation(fetchedPresentation);
        appEventEmitter.emit({
          eventType: "presentation-view-loaded",
          presentationType: fetchedPresentation.presentationType,
        });
      })
      .catch(() => setLoadError(true));
  }, [presentationId]);

  if (loadError) {
    return (
      <Box p={10} textAlign="center">
        Failed to load the requested presentation.
      </Box>
    );
  }

  if (!presentation) {
    return (
      <Box p={10} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <PresentationViewWrapper>
      <PresentationView
        presentationId={presentationId}
        editMode={false}
        sections={presentation.sections ?? []}
        agent={presentation.agent}
        subjectProperty={presentation.subjectProperty}
        galleries={presentation.galleries}
        client={presentation.client}
        priceRange={presentation.priceRange}
        updatedAt={presentation.updatedAt}
      />
    </PresentationViewWrapper>
  );
};

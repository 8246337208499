/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AgentCreationParams,
    AgentCreationParamsFromJSON,
    AgentCreationParamsToJSON,
    AgentEditParams,
    AgentEditParamsFromJSON,
    AgentEditParamsToJSON,
    AgentImageResponseDto,
    AgentImageResponseDtoFromJSON,
    AgentImageResponseDtoToJSON,
    ListAgentResponseDto,
    ListAgentResponseDtoFromJSON,
    ListAgentResponseDtoToJSON,
    PaginatedAgentListResponse,
    PaginatedAgentListResponseFromJSON,
    PaginatedAgentListResponseToJSON,
    SingleAgentResponseDto,
    SingleAgentResponseDtoFromJSON,
    SingleAgentResponseDtoToJSON,
} from '../models';

export interface AgentControllerAddHeadshotPhotoRequest {
    id: number;
    image: Blob;
}

export interface AgentControllerCreateAgentRequest {
    agentCreationParams: AgentCreationParams;
}

export interface AgentControllerDeleteAgentRequest {
    id: number;
}

export interface AgentControllerEditAgentRequest {
    id: number;
    agentEditParams: AgentEditParams;
}

export interface AgentControllerGetAllAgentsRequest {
    page: number;
    limit: number;
}

/**
 * 
 */
export class CMAAgentsApi extends runtime.BaseAPI {

    /**
     */
    async agentControllerAddHeadshotPhotoRaw(requestParameters: AgentControllerAddHeadshotPhotoRequest): Promise<runtime.ApiResponse<AgentImageResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling agentControllerAddHeadshotPhoto.');
        }

        if (requestParameters.image === null || requestParameters.image === undefined) {
            throw new runtime.RequiredError('image','Required parameter requestParameters.image was null or undefined when calling agentControllerAddHeadshotPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/cmas/agents/{id}/headshot-image`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AgentImageResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async agentControllerAddHeadshotPhoto(requestParameters: AgentControllerAddHeadshotPhotoRequest): Promise<AgentImageResponseDto> {
        const response = await this.agentControllerAddHeadshotPhotoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async agentControllerCreateAgentRaw(requestParameters: AgentControllerCreateAgentRequest): Promise<runtime.ApiResponse<SingleAgentResponseDto>> {
        if (requestParameters.agentCreationParams === null || requestParameters.agentCreationParams === undefined) {
            throw new runtime.RequiredError('agentCreationParams','Required parameter requestParameters.agentCreationParams was null or undefined when calling agentControllerCreateAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/agents/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AgentCreationParamsToJSON(requestParameters.agentCreationParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleAgentResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async agentControllerCreateAgent(requestParameters: AgentControllerCreateAgentRequest): Promise<SingleAgentResponseDto> {
        const response = await this.agentControllerCreateAgentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async agentControllerDeleteAgentRaw(requestParameters: AgentControllerDeleteAgentRequest): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling agentControllerDeleteAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/agents/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async agentControllerDeleteAgent(requestParameters: AgentControllerDeleteAgentRequest): Promise<string> {
        const response = await this.agentControllerDeleteAgentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async agentControllerEditAgentRaw(requestParameters: AgentControllerEditAgentRequest): Promise<runtime.ApiResponse<SingleAgentResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling agentControllerEditAgent.');
        }

        if (requestParameters.agentEditParams === null || requestParameters.agentEditParams === undefined) {
            throw new runtime.RequiredError('agentEditParams','Required parameter requestParameters.agentEditParams was null or undefined when calling agentControllerEditAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/agents/{id}/edit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AgentEditParamsToJSON(requestParameters.agentEditParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => SingleAgentResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async agentControllerEditAgent(requestParameters: AgentControllerEditAgentRequest): Promise<SingleAgentResponseDto> {
        const response = await this.agentControllerEditAgentRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async agentControllerGetAllAgentsRaw(requestParameters: AgentControllerGetAllAgentsRequest): Promise<runtime.ApiResponse<PaginatedAgentListResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling agentControllerGetAllAgents.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling agentControllerGetAllAgents.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/agents/paginated`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedAgentListResponseFromJSON(jsonValue));
    }

    /**
     */
    async agentControllerGetAllAgents(requestParameters: AgentControllerGetAllAgentsRequest): Promise<PaginatedAgentListResponse> {
        const response = await this.agentControllerGetAllAgentsRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async agentControllerGetAllAgentsSimpleRaw(): Promise<runtime.ApiResponse<ListAgentResponseDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/agents/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListAgentResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async agentControllerGetAllAgentsSimple(): Promise<ListAgentResponseDto> {
        const response = await this.agentControllerGetAllAgentsSimpleRaw();
        return await response.value();
    }

}

import * as React from "react";
import SwiperCore, { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { GalleryPhoto } from "@avenue-8/ui-2";
import { PropertyPhoto } from "../../../../../presentation/presentation-generation-logic/models";
import { usePhotoGalleryContext } from "../../../../../shared/contexts/PhotoGalleryContext";
import { ResponsiveImage } from "../../../../../shared/components/ResponsiveImage";
import { VerticalSpace } from "../VerticalSpace";
import { PrintOff } from "../PrintOff";
import { NotPrintOff } from "../NotPrintOff";
import { PrintGallery } from "../PrintGallery";
import { SwiperContainer, RatioImage, GalleryIconContainer } from "./HeroHeader.styles";
import { AvoidPrintBreakInside } from "../AvoidPrintBreakInside";
import { GalleryIcon } from "../Icons/GalleryIcon";

SwiperCore.use([Virtual]);

const PRINT_COLS = 3;
const PRINT_ROWS = 3;

const getPropertiesPrintRows = (photos: PropertyPhoto[]) => {
  const photosToPrint = photos.slice(1, PRINT_ROWS * PRINT_COLS + 1); // 9 photos after the cover photo
  const printRows = [];
  while (photosToPrint.length > 0) printRows.push(photosToPrint.splice(0, PRINT_COLS));
  return printRows;
};

export const Gallery = (props: { photos?: PropertyPhoto[]; displayMode?: string }) => {
  const { photos, displayMode } = props;
  const { changeGalleryPhoto } = usePhotoGalleryContext();
  const propertiesPrintRows: Array<PropertyPhoto[]> = getPropertiesPrintRows(photos || []);
  const galleryPhotos: GalleryPhoto[] =
    photos?.map((photo, i) => ({
      id: `id-${i}`,
      src: photo.url,
      alt: photo.caption || `Property photo ${i}`,
    })) ?? [];

  if (displayMode === "print") return <PrintGallery photos={propertiesPrintRows} />;
  return (
    <>
      <NotPrintOff>
        <AvoidPrintBreakInside>
          <PrintGallery photos={propertiesPrintRows} />
        </AvoidPrintBreakInside>
      </NotPrintOff>
      <PrintOff>
        <VerticalSpace amount={4} />
        <SwiperContainer>
          <Swiper
            pagination={{ clickable: true, dynamicBullets: true }}
            centeredSlides={false}
            spaceBetween={10}
            grabCursor
            mousewheel
            slidesPerView={displayMode === "mobile" ? 1.1 : 2}
            virtual={{
              addSlidesAfter: 2,
              addSlidesBefore: 2,
            }}
          >
            {photos?.map(({ caption, url }, index) => {
              return (
                <SwiperSlide virtualIndex={index} key={index}>
                  <RatioImage onClick={() => changeGalleryPhoto(index, galleryPhotos)}>
                    <ResponsiveImage alt={caption ?? ""} src={url} size="sm" />
                    <GalleryIconContainer>
                      <GalleryIcon style={{ width: "30px" }} />
                    </GalleryIconContainer>
                  </RatioImage>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SwiperContainer>
      </PrintOff>
    </>
  );
};

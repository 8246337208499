import { useState } from "react";
import { styled, Grid, CompsTableIcon, Button } from "@avenue-8/ui-2";
import { useBreakpoint } from "../../hooks/useMobile";
import { SmartUserMenu } from "../SmartUserMenu";
import { Logo } from "../../../shared/components/Logo";
import { CMAStepper } from "./CMAStepper";
import { SubjectPropertyStep } from "./Steps/SubjectProperty/SubjectPropertyStep";
import { useCreateCMALogic } from "../../hooks/useCreateCMALogic";
import { SubjectPropertyHeader } from "../SubjectPropertyHeader";
import { EditCmaHeaderModal } from "./EditCmaHeaderModal/EditCmaHeaderModal";
import { PropertySearchStep } from "./Steps/Search/PropertySearchStep";
import { SmartPresentationEditorStep } from "./Steps/PresentationEditor/SmartPresentationEditorStep";
import { EstimateStep } from "./Steps/Estimate/EstimateStep";
import { CreateCMAPageLayout } from "./CreateCMAPageLayout";
import { SideBySidePropertiesModal } from "./Steps/Search/components/SideBySideProperties/SideBySidePropertiesModal";
import { PropertyDto } from "../../../shared/apis/cma/generated";
import { EditComparableProperty } from "./EditComparableProperty/EditComparableProperty";

const StyledUserMenu = styled(SmartUserMenu)`
  margin: 0px auto;
`;

export const BottomBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: sticky;
  bottom: 0;
  padding: 1rem 1px;
  gap: 1rem;
  margin: 1rem 0 0 auto;
  width: 100%;
  background-color: ${(p) => p.theme.av8.background};
  z-index: 3;
`;

export const CreateCMA = () => {
  const isSmall = useBreakpoint("desktopDown");
  const { state, actions } = useCreateCMALogic();
  const { cma, currentStep } = state;
  const [editCmaModalOpen, setEditCmaModalOpen] = useState(false);

  const [isSideBySideModalOpened, setIsSideBySideModalOpened] = useState(false);
  const numberOfComparableProperties = cma?.comparableProperties.length ?? 0;
  const hasSeletedComps = numberOfComparableProperties > 0;

  if (currentStep == null) return null; //we always need to have a step selected
  const currentStepKey = currentStep.params.step;

  const clientDisplayName = state.cma?.clientDisplayName;
  const cmaTitle =
    state.cma?.title ||
    `${state.cma?.subjectProperty?.addressLine1 ?? ""} ${
      state.cma?.subjectProperty?.addressLine2 ?? ""
    }`.trim() ||
    "Untitled CMA";

  let stepContent: React.ReactNode;
  if (currentStepKey === "subject-property") {
    stepContent = <SubjectPropertyStep cmaId={state.cmaId!} />;
  } else if (currentStepKey === "search") {
    stepContent = <PropertySearchStep />;
  } else if (currentStepKey === "estimate") {
    stepContent = <EstimateStep cmaId={state.cmaId} />;
  } else if (currentStepKey === "customize-presentation") {
    stepContent = <SmartPresentationEditorStep cmaId={state.cmaId!} />;
  } else {
    stepContent = null;
  }

  return (
    <CreateCMAPageLayout
      userMenu={<StyledUserMenu />}
      headerContent={
        <SubjectPropertyHeader
          clientName={clientDisplayName}
          address={cmaTitle}
          onEditClick={() => setEditCmaModalOpen(true)}
          loading={state.cmaStatus === "loading"}
        />
      }
      logo={<Logo />}
      contentReservedHeight={isSmall ? 24 : 170}
      headerPosition={isSmall ? "static" : "fixed"}
    >
      <Grid container data-key={`${currentStepKey}-step-container`}>
        <Grid container item xs={12}>
          <Grid item xs={10}>
            <CMAStepper currentStep={currentStep.index} />
          </Grid>
          {currentStepKey === "estimate" && (
            <Grid item xs={2}>
              {hasSeletedComps && (
                <Button
                  fullWidth
                  endIcon={<CompsTableIcon />}
                  onClick={() => setIsSideBySideModalOpened(true)}
                >
                  Comps ({numberOfComparableProperties})
                </Button>
              )}
              <SideBySidePropertiesModal
                open={isSideBySideModalOpened}
                title={`${String(cma?.comparableProperties.length).padStart(
                  2,
                  "0"
                )} listings selected`}
                onClose={() => setIsSideBySideModalOpened(false)}
                subjectProperty={cma?.subjectProperty}
                properties={cma?.comparableProperties}
                onDeleteProperty={(property) => actions.removeCompListing(property, true)}
                reorderComps={(properties: PropertyDto[]) => actions.reorderComps(properties)}
              />
            </Grid>
          )}
        </Grid>
        {stepContent}
      </Grid>
      {state.cma && (
        <EditCmaHeaderModal
          onSave={async ({ cmaTitle, clientName }) => {
            await actions.updateCmaHeader({
              id: state.cma!.id,
              clientDisplayName: clientName,
              title: cmaTitle,
            });
            setEditCmaModalOpen(false);
          }}
          defaultValues={{
            clientName: clientDisplayName ?? "",
            cmaTitle: cmaTitle,
          }}
          onClose={() => setEditCmaModalOpen(false)}
          open={editCmaModalOpen}
        />
      )}
      <EditComparableProperty />
    </CreateCMAPageLayout>
  );
};

import { Typography, styled } from "@avenue-8/ui-2";
import { listingStatusColor } from "../../../../../../../shared/css-snippets";

export const Container = styled.div`
  position: sticky;
  box-sizing: border-box;
  width: 100%;
  min-height: 23rem;
  overflow: hidden;
  top: 104px;
  background-color: ${(p) => p.theme.av8.backgroundAlt};
  padding: 2rem;
`;
export const EmptyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 12rem;
`;
export const SummaryPricesContainer = styled.div`
  margin-top: 2.5rem;
`;
export const Subtitle = styled(Typography)`
  margin-bottom: 1rem;
`;
export const ListingStatus = styled(Typography)<{ $listingStatus: string }>`
  ${(p) => listingStatusColor(p.$listingStatus)}
  font-size: 14px;
  line-height: 1rem;
  margin: 1rem 0;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
`;

import * as React from "react";
import {
  GalleryPhoto,
  PhotoGallery,
  DeleteIcon,
  PhotoGalleryProps,
  ImageUnavailable,
  Fade,
} from "@avenue-8/ui-2";
import {
  ThumbImage,
  Container,
  CoverPhotoContainer,
  ThumbnailsContainer,
  ThumbnailsContainerExtended,
  Footer,
  ActionButton,
} from "./PropertyPhotosPreview.styles";

interface Photo {
  caption?: string;
  url: string;
  id: string;
}

interface Props {
  photos: Photo[];
  inputId?: string;
  singlePhoto?: boolean;
  makeCoverPhoto?: (params: { photoId: string }) => Promise<void>;
  removePropertyPhoto?: (params: { photoId: string }) => Promise<void>;
  disableAddMorePhotos?: boolean;
}

const MAX_THUMBNAILS_COUNT = 6;

export const PropertyPhotosPreview = (props: Props) => {
  const {
    photos = [],
    inputId,
    singlePhoto,
    makeCoverPhoto,
    removePropertyPhoto,
    disableAddMorePhotos,
  } = props;
  const [expandedThumbnails, setExpandedThumbnails] = React.useState(false);
  const [photoGalleryIndex, setPhotoGalleryIndex] = React.useState<number>(-1);
  const [resetIndex, setResetIndex] = React.useState(0); //this is a hack - ideally we should make the PhotoGallery controlled

  const thumbs = photos.map(({ caption, url }, index) => {
    return (
      <ThumbImage
        key={"thumb-" + index}
        style={{ backgroundImage: `url(${url})` }}
        aria-label={caption}
        onClick={() => setPhotoGalleryIndex(index)}
      />
    );
  });

  const openInputFile = () => {
    if (inputId) document.getElementById(inputId)?.click();
  };

  const actions: PhotoGalleryProps["actions"] = [];
  if (makeCoverPhoto) {
    actions.push({
      text: "Make Cover Photo",
      onClick: async (currentSlide: GalleryPhoto) => {
        await makeCoverPhoto({ photoId: currentSlide.id });
        setPhotoGalleryIndex(0);
        setResetIndex(resetIndex + 1);
      },
    });
  }
  if (removePropertyPhoto) {
    actions.push({
      text: "Delete Photo",
      icon: () => <DeleteIcon />,
      onClick: (currentSlide: GalleryPhoto) => {
        removePropertyPhoto({ photoId: currentSlide.id });
      },
    });
  }

  return (
    <Container>
      <CoverPhotoContainer onClick={() => setPhotoGalleryIndex(0)}>
        {photos.length > 0 ? (
          <img src={photos[0].url} alt={photos[0].caption} />
        ) : (
          <ImageUnavailable />
        )}
      </CoverPhotoContainer>

      {photos.length > 1 && (
        <>
          <ThumbnailsContainer>{thumbs.slice(0, MAX_THUMBNAILS_COUNT)}</ThumbnailsContainer>
          <Fade in={expandedThumbnails}>
            <ThumbnailsContainerExtended $expanded={expandedThumbnails}>
              {thumbs.slice(MAX_THUMBNAILS_COUNT, thumbs.length)}
            </ThumbnailsContainerExtended>
          </Fade>
        </>
      )}

      <Footer>
        {!singlePhoto && (
          <>
            <ActionButton
              type="button"
              onClick={openInputFile}
              disabled={disableAddMorePhotos}
              data-meta-action="add-more-photos"
            >
              Add more photos +
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() => setExpandedThumbnails(!expandedThumbnails)}
              disabled={thumbs.length <= MAX_THUMBNAILS_COUNT}
              data-meta-action="see-more-photos"
            >
              {expandedThumbnails ? "See less" : "See more"}
            </ActionButton>
          </>
        )}
        {singlePhoto && photos.length > 0 && (
          <>
            <ActionButton
              type="button"
              onClick={openInputFile}
              disabled={disableAddMorePhotos}
              data-meta-action="add-more-photos"
            >
              Change Photo
            </ActionButton>
            <ActionButton
              type="button"
              onClick={() => removePropertyPhoto?.({ photoId: "none" })}
              data-meta-action="see-more-photos"
            >
              Remove
            </ActionButton>
          </>
        )}
      </Footer>
      {photos.length > 0 && (
        <PhotoGallery
          key={resetIndex}
          open={photoGalleryIndex !== -1}
          onClose={() => setPhotoGalleryIndex(-1)}
          photos={photos.map((photo) => ({
            src: photo.url,
            alt: photo.caption ?? "Property photo",
            id: photo.id,
          }))}
          initialIndex={photoGalleryIndex}
          actions={actions}
        />
      )}
    </Container>
  );
};

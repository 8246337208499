import { useQuery } from "react-query";
import { getCMAPropertiesApiClient } from "../../shared/apis/cma/api-factories";

const CACHE_TIME_IN_MS = 1000 * 60 * 10; // 10 minutes

export function usePropertyStylesQueries() {
  const propertyStylesQuery = useQuery(
    ["property-styles"],
    async () => getCMAPropertiesApiClient().propertyControllerListStyles(),
    {
      cacheTime: CACHE_TIME_IN_MS,
      staleTime: CACHE_TIME_IN_MS,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  return { propertyStylesQuery };
}

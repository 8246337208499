import { styled } from "@avenue-8/ui-2";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const FieldsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`;
export const ContainerColumn = styled.div`
  width: calc(50% - 1rem);
`;
export const Divider = styled.div<{ $type: "column" | "row" }>`
  background: ${(p) => p.theme.av8.dividerColor};
  ${(p) => p.$type === "column" && `width: 1px`};
  ${(p) => p.$type === "row" && `height: 1px`};
`;
export const FilterLabel = styled.h4`
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
`;
export const SelectSpacer = styled.div`
  margin-left: 1.5rem;
  margin-bottom: 1rem;
`;
export const FieldSpace = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: column;
  grid-template-rows: 1em 1fr;
  grid-column-gap: 5.5rem;
  margin-bottom: 1.5rem;
`;
export const FieldItem = styled.div`
  margin-bottom: 0.5rem;
`;

import { Dialog, DialogContentText, DialogTitle, styled, IconButton } from "@avenue-8/ui-2";

export const Form = styled.form`
  margin-bottom: 16px;
`;
export const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;
export const CustomDialog = styled(Dialog)`
  width: 100%;
  margin: 0 auto;
  & .MuiPaper-root {
    max-width: 1024px;
  }
`;
export const ResultsText = styled(DialogContentText)`
  color: ${(p) => p.theme.av8.primaryColor};
`;
export const Divider = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.av8.input.borderColor};
  width: 100%;
  margin: 16px auto;
`;
export const DialogCustomTitle = styled(DialogTitle)`
  font-size: ${(p) => p.theme.av8.typography.headers.size};
`;

import { styledThemed as styled } from "../theme";

export const NotPrintOff = styled.div<{ $disabled?: boolean }>`
  ${(p) =>
    !p.$disabled
      ? `
        @media not print {
          display: none !important;
        }
      `
      : ""}
`;

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Button, Typography, Grid, styled, InputLabel } from "@avenue-8/ui-2";
import { PasswordInput } from "./PasswordInput";
import { GoogleLoginButton } from "../../shared/components/GoogleLoginButton";
import { ForgotPassword } from "./ForgotPassword";
import { AuthCard, ButtonContainer, Form, PageContainer } from "./style";
import { useAuthContext } from "../../shared/contexts/AuthContext/AuthContext";
import { TextFieldBase } from "../../shared/components/TextFieldBase";
import { simpleEmail } from "../../shared/utils/validation-patterns";
import { isNullOrEmptyString } from "../../shared/utils/validations";
import { useQueryParams, StringParam } from "use-query-params";
import { getTextFieldErrors } from "../../cma-v2/components/TextFieldBase";
import { cmaRoutes } from "src/modules/cma-v2/cma.routes";
import { authRoutes } from "../auth.routes";
import { SpinnerBox } from "src/modules/shared/components/SpinnerBox";

export interface LoginFormModel {
  password: string;
  email: string;
  remember: boolean;
}

const FormItem = styled(Grid)`
  margin-bottom: 2rem;
`;

const CustomTypography = styled(Typography)`
  padding-bottom: 24px;
`;

const StyledGoogleButton = styled(GoogleLoginButton)`
  width: 100%;
  margin: 1rem 0;
  color: ${(p) => p.theme.av8.primaryColor};
  display: flex;
  justify-content: center;
`;

const LoginDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;
  flex-grow: 0;
  height: 2rem;
  p {
    z-index: 2;
    background: ${(p) => p.theme.av8.background};
    padding: 0 0.5rem;
    text-transform: uppercase;
    font-size: 12px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: ${(p) => p.theme.av8.input.borderColor};
    top: 1rem;
    left: 0;
    z-index: 1;
  }
`;

const ErrorMessage = styled(Typography)`
  text-align: center;
`;

export const LoginPage = ({
  forgotPassword,
  failure,
}: {
  forgotPassword?: boolean;
  failure?: boolean;
}) => {
  const [{ reason: failureReason, authCode }] = useQueryParams({ reason: StringParam, authCode: StringParam });
  const { control, handleSubmit, errors, setValue, getValues } = useForm({
    defaultValues: {
      email: "",
      password: "",
      remember: true,
    },
  });
  const [forgotPasswordModalState, setForgotPasswordModalState] = React.useState<{
    open: boolean;
    email?: string;
  }>({
    open: forgotPassword ?? false,
    email: "",
  });
  const [showSpinner, setShowSpinner] = React.useState(false);
  const { actions, state } = useAuthContext();
  const history = useHistory();

  const failureMessage = failure ? failureReason || "Login failed. Please try again" : undefined;

  const signInWithAuthCode = React.useCallback(async () => {
    if (authCode) {
      setShowSpinner(true);
      await actions.authenticateWithAuthCode({
        authCode,
      });
      setShowSpinner(false);
    }
  }, [authCode, actions]);

  React.useEffect(() => {
    signInWithAuthCode();
  }, [signInWithAuthCode, authCode]);
  
  React.useEffect(() => {
    if (state.authenticateState === "success") {
      history.push(cmaRoutes.dashboard.route);
    } else if (state.authenticateState === "fail") {
      setValue("password", "");
    }
  }, [state.authenticateState, history, setValue]);

  async function onSignIn(data: LoginFormModel) {
    await actions.authenticateWithEmailAndPassword(data);
  }

  const isSubmitting = state.authenticateState === "init";

  const handleForgotPassword = () => {
    setForgotPasswordModalState({ open: true, email: getValues("email") });
  };

  const handleCloseForgotPasswordModal = () => {
    setForgotPasswordModalState({ open: false });
    history.push(authRoutes.auth.route);
  };

  const content = (
    <AuthCard>
      <CustomTypography variant="h3">MarketComp</CustomTypography>
      <Typography variant="h6">Login</Typography>
      <br />
      <StyledGoogleButton href={`${process.env.REACT_APP_CMA_API_URL}/auth/google`} />
      {failureMessage && (
        <ErrorMessage color="error" variant="body2">
          {failureMessage}
        </ErrorMessage>
      )}
      <LoginDivider>
        <Typography variant="body2">or</Typography>
      </LoginDivider>
      {isSubmitting && showSpinner ? (
        <SpinnerBox />
      ) : (
        <Form onSubmit={handleSubmit(onSignIn)}>
          <InputLabel htmlFor="email">E-mail</InputLabel>
          <FormItem>
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: simpleEmail,
                  message: "Invalid email address",
                },
                validate: {
                  required: (v) => (isNullOrEmptyString(v) ? `Missing email address` : undefined),
                },
              }}
              render={({ ref, ...rest }) => (
                <TextFieldBase
                  fullWidth
                  disabled={isSubmitting}
                  type="text"
                  errors={errors}
                  inputRef={ref}
                  {...rest}
                />
              )}
            />
          </FormItem>
          <FormItem>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Controller
              name="password"
              control={control}
              rules={{
                validate: {
                  required: (v) => (isNullOrEmptyString(v) ? `Missing password` : undefined),
                },
              }}
              render={(props) => (
                <PasswordInput
                  fullWidth
                  disabled={isSubmitting}
                  {...getTextFieldErrors({ name: "password", errors })}
                  {...props}
                />
              )}
            />
          </FormItem>
          <ButtonContainer>
            <Button type="submit" fullWidth disabled={isSubmitting}>
              {isSubmitting ? "Authenticating..." : "Login"}
            </Button>
            <Button variant="text" fullWidth onClick={handleForgotPassword}>
              Forgot password?
            </Button>
          </ButtonContainer>
        </Form>
      )}
    </AuthCard>
  );

  const forgotPasswordModal = forgotPasswordModalState.open && (
    <ForgotPassword
      email={forgotPasswordModalState.email}
      onClose={handleCloseForgotPasswordModal}
    />
  );

  return (
    <PageContainer>
      {content}
      {forgotPasswordModal}
    </PageContainer>
  );
};

import * as React from "react";
import { useDropzone } from "react-dropzone";
import { PhotoIcon, Typography } from "@avenue-8/ui-2";
import { SpinnerBox } from "src/modules/shared/components/SpinnerBox";
import {
  ErrorText,
  PhotoUploadContainer,
  PhotoUploadDefaultWrapper,
  ThumbImage,
  PhotoUploadCustomWrapper,
  ContentContainer,
  LoaderContainer,
  PhotoUploadContent,
  DescriptionContainer,
  BrowseButton,
  MaxSizeText,
  ThumbnailsUploadingContainer,
} from "./PhotosUploader.styles";

const IMG_MAX_SIZE_MB = 4;

export interface Photo {
  id: string;
  url: string;
  caption?: string;
}

interface ExtendedFile extends File {
  preview: string;
}

interface DisabledProps {
  disabled: true;
}

interface EnabledProps {
  disabled?: false;
  isUploading: boolean;
  onUploadFiles?: (form: any) => void;
  inputId?: string;
}

interface CommonProps {
  content?: React.ReactNode;
  description?: string;
  disableClickEvent?: boolean;
  inputName?: string;
  errorMessage?: string | null;
  hideThumbnailsPreview?: boolean;
  compact?: boolean;
}

type Props = (DisabledProps | EnabledProps) & CommonProps;

export const PhotosUploader = (props: Props) => {
  const [files, setFiles] = React.useState<ExtendedFile[]>([]);
  const [filesError, setFilesError] = React.useState(
    !props.disabled ? props.errorMessage ?? "" : ""
  );

  React.useEffect(() => {
    setFilesError(props.errorMessage ?? "");
  }, [props.errorMessage]);

  const filterInvalidFiles = (files: File[]) => {
    const validFiles = files.filter((file) => {
      return file.size <= IMG_MAX_SIZE_MB * 1000 * 1000;
    });
    const containsBigFiles = validFiles.length < files.length;
    if (containsBigFiles) {
      setFilesError("Maximum Image file size exceeded.");
      return validFiles;
    }

    //clear error message
    if (filesError) setFilesError("");
    return validFiles;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: async (acceptedFiles: File[]) => {
      if (props.disabled !== true) {
        if (props.isUploading) return;

        const validFiles = filterInvalidFiles(acceptedFiles);
        if (validFiles.length === 0) return;

        setFiles(
          validFiles.map((file: File) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );

        const data = new FormData();

        for (let i = 0; i < validFiles.length; i++) {
          data.append(props.inputName ?? "images", validFiles[i]);
        }
        props.onUploadFiles?.(data);
      }
    },
  });

  React.useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => {
        if (file.preview) URL.revokeObjectURL(file.preview);
      });
    },
    [files]
  );

  if (props.disabled) {
    return (
      <PhotoUploadContainer>
        <PhotoUploadDefaultWrapper disabled={true}>
          <PhotoIcon />
          {props.content ?? <p>Upload is disabled.</p>}
        </PhotoUploadDefaultWrapper>
      </PhotoUploadContainer>
    );
  }

  const thumbs = files.map((file, index) => (
    <ThumbImage
      key={index + "_" + file.name}
      style={{ backgroundImage: `url(${file.preview})` }}
      aria-label={file.name}
    />
  ));

  const { content, description, disableClickEvent, inputId, compact } = props;

  return (
    <PhotoUploadContainer>
      <input {...getInputProps()} id={inputId} />
      {content ? (
        <PhotoUploadCustomWrapper>
          <ContentContainer
            {...getRootProps()}
            onClick={disableClickEvent === true ? () => null : getRootProps().onClick}
          >
            {props.isUploading && (
              <LoaderContainer>
                <SpinnerBox />
              </LoaderContainer>
            )}
            {content}
            <ErrorMessage message={filesError} />
          </ContentContainer>
        </PhotoUploadCustomWrapper>
      ) : (
        <PhotoUploadDefaultWrapper {...getRootProps()}>
          {props.isUploading ? (
            <SpinnerBox />
          ) : (
            <PhotoUploadContent $compact={compact}>
              <PhotoIcon />
              <DescriptionContainer>
                <Typography variant="body2">
                  {description ?? "Drag 'n' drop photos here, or click to select files"}
                </Typography>
              </DescriptionContainer>
              <BrowseButton data-testid="browse-files">Browse Files</BrowseButton>
              <MaxSizeText>Max size {IMG_MAX_SIZE_MB}MB</MaxSizeText>
              <ErrorMessage message={filesError} />
            </PhotoUploadContent>
          )}
        </PhotoUploadDefaultWrapper>
      )}

      {!props.hideThumbnailsPreview && (thumbs.length > 1 || props.isUploading) && (
        <ThumbnailsUploadingContainer>
          {props.isUploading && <>{thumbs}</>}
        </ThumbnailsUploadingContainer>
      )}
    </PhotoUploadContainer>
  );
};

const ErrorMessage = ({ message }: { message?: string }) => {
  return <ErrorText variant="body2">{message ?? ""}</ErrorText>;
};

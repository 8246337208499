import React from "react";
import { ConstrainedContainer } from "./ConstrainedContainer";
import GoogleMapReact from "google-map-react";
import { styledThemed as styled } from "../theme";
import { Title1 } from "./typography";
import FullScreenDialog from "./Fullscreen";
import { Fullscreen } from "@mui/icons-material";
import { MapMarkerSvg } from "../../../../shared/components/MapMarkerSvg";
import {
  NeighborhoodMapProperty,
  NeighborhoodMapSectionView,
  PropertyPhoto,
} from "../../../../presentation/presentation-generation-logic/models";
import {
  GalleryPhoto,
  MapPinIcon,
  SubjectPropIcon,
  Divider,
  IconButton,
  Popover,
} from "@avenue-8/ui-2";
import {
  PhotoGalleryContextProvider,
  usePhotoGalleryContext,
} from "../../../../shared/contexts/PhotoGalleryContext";
import { SectionViewLayout } from "./SectionViewLayout";
import { PrintOff } from "./PrintOff";

const GalleryTextContainer = styled.div`
  margin: 4px;
  text-align: center;
`;

const ImagesLink = styled.a`
  color: ${(p) => p.theme.av8.primaryColor};
  display: inline-block;
  text-align: center;
  padding: 5px 8px;
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const MapConstraint = styled.div<{ fullScreen: boolean }>`
  max-width: ${(p) => (p.fullScreen ? "100%" : "600px")};
  margin: 0px auto;
  overflow: hidden;
`;

const MapFrame = styled.div<{ fullScreen: boolean }>`
  height: ${(p) => (p.fullScreen ? "100vh" : "50vh")};
  max-height: ${(p) => (p.fullScreen ? "100vh" : "480px")};
  position: relative;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
`;

const InnerMarkerColor = styled.div<{ markerColor: string }>`
  ${(p) =>
    p.markerColor
      ? `
      background-color: ${p.markerColor};
    }
  `
      : ""}
  height: 0.7em;
  width: 0.7em;
  border-radius: 2em;
  display: inline-block;
  margin-right: 8px;
`;

const MapLegend = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 360px;
  margin: ${(p) => p.theme.presentation.spacing(1)} auto 0 auto;
`;

const MapLegendItem = styled.div<{ markerColor?: string }>`
  ${(p) =>
    p.markerColor
      ? `
    &::before{
      content: "";
      display: block;
      background-color: ${p.markerColor};
      width: 0.7em;
      height: 0.7em;
      border-radius: 50%;
      top: calc(50% - 0.35em);
      position: absolute;
      left: 0.75em;
    }
  `
      : ""}
  display: flex;
  align-items: center;
  font-size: 0.7em;
  text-align: center;
  position: relative;
  white-space: nowrap;
  padding: 1em;
  padding-left: 2em;
  box-sizing: border-box;
`;

const MarkerNumber = styled.div`
  position: absolute;
  left: 18px;
  top: 8px;
  font-size: 0.63rem;
  color: white;
`;

const MarkerContainer = styled.div`
  width: 39px;
  height: 44px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  top: -44px;
  left: -19.5px;
  z-index: 10;
`;

const PopoverContent = styled.div`
  padding: 0.5rem;
  font-size: 0.7rem;
`;

const PropertyDetailDivider = styled(Divider)`
  margin: 0.4em 0 !important;
`;

const FullScreenIconButton = styled(IconButton)`
  border-radius: 2px;
  background-color: #fff !important;
  color: #666666;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  padding: 8px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  &:hover {
    color: #333333;
  }
`;

interface Props extends NeighborhoodMapSectionView {
  title?: string | null;
  galleries?: { [key: string]: PropertyPhoto[] };
}

interface MarkerProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  propertyType: string;
  lat: number;
  lng: number;
  numProperties: number;
}

// colors here need to be hardcoded due to code mimicing with the backend
const colors = {
  subject: "blue",
  active: "#107F10", // successColor
  sold: "#D90D02", // dangerColor
  pending: "#FFCE45", // warningColor
  other: "#131319", // primaryColor
};

const getColorByType = (type: string) => {
  if (type === "comparable-active") return colors.active;
  else if (type === "comparable-sold") return colors.sold;
  else if (type === "comparable-pending") return colors.pending;
  return colors.other;
};

const Marker = ({
  propertyType,
  lat: _lat,
  lng: _lng,
  numProperties,
  ...divProps
}: MarkerProps) => {
  return (
    <MarkerContainer {...(divProps as any)}>
      {numProperties > 1 ? <MarkerNumber>{numProperties}</MarkerNumber> : null}
      {propertyType === "subject" ? (
        <MapPinIcon />
      ) : (
        <MapMarkerSvg color={getColorByType(propertyType)} />
      )}
    </MarkerContainer>
  );
};

interface PropertyDetailProps {
  property: NeighborhoodMapProperty;
  gallery?: PropertyPhoto[];
  lighboxCallbacks?: { onLightboxClosed?: (e: any) => void };
}

const PropertyDetail = React.memo(({ property, gallery }: PropertyDetailProps) => {
  const { changeGalleryPhoto } = usePhotoGalleryContext();
  let galleryPhotos: GalleryPhoto[];
  if (gallery !== undefined) {
    galleryPhotos = gallery.map((photo, i) => ({
      id: `id-${i}`,
      src: photo.url,
      alt: photo.caption || `Property photo ${i}`,
    }));
  }
  return (
    <div>
      <InnerMarkerColor markerColor={getColorByType(property.type)}></InnerMarkerColor>
      {property.tooltip}
      {gallery && (
        <>
          <GalleryTextContainer>
            <ImagesLink
              onClick={() => {
                changeGalleryPhoto(0, galleryPhotos);
              }}
            >
              Show Photos
            </ImagesLink>
          </GalleryTextContainer>
        </>
      )}
    </div>
  );
});

const popoverStyle = { marginTop: "-5px" };
export const NeighborhoodMap = ({ title, properties: allProperties, id, galleries }: Props) => {
  const properties = allProperties?.filter((x) => x.lat && x.lng) ?? [];

  const [marker, setMarker] = React.useState<{
    el: HTMLElement;
    open: boolean;
    properties: Array<{ property: NeighborhoodMapProperty; gallery?: PropertyPhoto[] }>;
  } | null>(null);
  const [fullScreenMap, setFullScreenMap] = React.useState<boolean>(false);

  const onMarkerClick = (
    e: React.MouseEvent<HTMLDivElement>,
    properties: NeighborhoodMapProperty[]
  ) => {
    setMarker({
      el: e.currentTarget,
      open: true,
      properties: properties.map((x) => {
        return { property: x, gallery: galleries?.[x.id] };
      }),
    });
    e.stopPropagation();
    return false;
  };

  return (
    <SectionViewLayout pageBreakBefore pageBreakAfter>
      <PhotoGalleryContextProvider>
        <ConstrainedContainer id={id} $noMobilePadding={true}>
          <Title1 textAlign="center" marginBottom={3}>
            {title ?? "The Neighborhood at a Glance"}
          </Title1>
          <FullScreenDialog open={fullScreenMap} toggleFullScreen={setFullScreenMap}>
            <MapConstraint fullScreen={fullScreenMap}>
              <MapFrame fullScreen={fullScreenMap}>
                <MapContainer data-testid="map-container">
                  <PrintOff>
                    <FullScreenIconButton
                      color={"primary"}
                      onClick={() => setFullScreenMap(!fullScreenMap)}
                    >
                      <Fullscreen />
                    </FullScreenIconButton>
                  </PrintOff>
                  <GoogleMap onMarkerClick={onMarkerClick} properties={properties} />
                </MapContainer>
              </MapFrame>
            </MapConstraint>
          </FullScreenDialog>
          <MapLegend data-testid="map-legend">
            {Boolean(properties.find((p) => p.type === "subject")) && (
              <MapLegendItem>
                <SubjectPropIcon />
                Subject Property
              </MapLegendItem>
            )}
            <MapLegendItem markerColor={colors.sold}>Comparable Sold</MapLegendItem>
            <MapLegendItem markerColor={colors.pending}>Comparable Pending</MapLegendItem>
            <MapLegendItem markerColor={colors.active}>Comparable Active</MapLegendItem>
            <MapLegendItem markerColor={colors.other}>Other</MapLegendItem>
          </MapLegend>
          <Popover
            open={marker?.open ?? false}
            anchorEl={marker?.el}
            onClose={() => setMarker({ ...(marker as any), open: false })}
            style={popoverStyle}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <PopoverContent>
              {marker?.properties.map((m, i) => {
                return (
                  <div key={m.property.id + "-div"}>
                    {i > 0 && <PropertyDetailDivider />}
                    <PropertyDetail
                      key={m.property.id}
                      gallery={m.gallery}
                      property={m.property}
                      lighboxCallbacks={{
                        onLightboxClosed: () => {
                          setMarker({
                            ...marker,
                            open: false,
                          });
                        },
                      }}
                    />
                  </div>
                );
              })}
            </PopoverContent>
          </Popover>
        </ConstrainedContainer>
      </PhotoGalleryContextProvider>
    </SectionViewLayout>
  );
};

type GoogleMapProps = {
  properties: NeighborhoodMapProperty[];
  onMarkerClick: (
    e: React.MouseEvent<HTMLDivElement>,
    properties: NeighborhoodMapProperty[]
  ) => any;
};

// TODO: Export component
const GoogleMap = ({ properties, onMarkerClick }: GoogleMapProps) => {
  const groupedProperties: { [key: string]: NeighborhoodMapProperty[] } = {};

  properties?.forEach((p) => {
    const key = `lat=${p.lat.toFixed(4)}&long=${p.lng.toFixed(4)}`;
    groupedProperties[key] = (groupedProperties[key] ?? []).concat([p]);
  });

  return (
    <GoogleMapReact
      options={{
        clickableIcons: false,
        fullscreenControl: false,
      }}
      onGoogleApiLoaded={(api) => {
        const bounds = new api.maps.LatLngBounds();
        properties?.forEach(({ lat, lng }) => {
          bounds.extend({ lat, lng });
        });
        api.map.fitBounds(bounds, 50);
      }}
      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "" }}
      yesIWantToUseGoogleMapApiInternals
      defaultCenter={{
        lat: 37.773972,
        lng: -122.431297,
      }}
      defaultZoom={11}
      style={{
        width: "100%",
      }}
    >
      {Object.keys(groupedProperties).map((groupKey) => {
        const properties = groupedProperties[groupKey];
        const p1 = properties[0];
        const pType = properties.find((x) => x.type === "subject") ? "subject" : p1.type;
        return (
          <Marker
            lat={p1.lat}
            lng={p1.lng}
            onClick={(e) => onMarkerClick(e, properties)}
            key={groupKey}
            propertyType={pType}
            numProperties={properties.length}
          />
        );
      })}
    </GoogleMapReact>
  );
};

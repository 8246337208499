import {
  List,
  ListItemButton,
  Menu,
  MenuItem,
  BottomDrawer,
  MenuProps,
  Card,
  ClickAwayListener,
  Fade,
  styled,
} from "@avenue-8/ui-2";
import { useMobile } from "../../presentation/hooks/useMobile";
import { useMemo, useEffect, useRef } from "react";

const NoWrapList = styled(List)`
  & .MuiListItemText-root {
    white-space: nowrap;
  }
`;

const MenuItemStyled = styled(MenuItem)`
  &:focus {
    background-color: ${(p) => p.theme.av8.background};
  }
`;

export interface ItemComponentProps {
  onClick: () => void;
  children: any;
  disabled?: boolean;
}

export interface ResponsiveContextMenuProps {
  open: boolean;
  onClose: () => void;
  onOpen?: () => void;
  children: (props: {
    ItemComponent: new (...args: any[]) => React.Component<ItemComponentProps, any>;
  }) => any;
  isMobile?: boolean;
  desktopCustomPosition?: React.CSSProperties;
  menuPositionProps?: Pick<MenuProps, "anchorOrigin" | "anchorReference" | "transformOrigin">;
  anchorEl: any;
  container?: any;
}

export const ResponsiveContextMenu = ({
  children,
  open,
  onClose,
  onOpen,
  isMobile,
  menuPositionProps,
  desktopCustomPosition,
  anchorEl,
}: ResponsiveContextMenuProps) => {
  const mobileDetected = useMobile();
  const mobile = isMobile != null ? isMobile : mobileDetected;
  const openDelayed = useRef(open); //small hack to make the ClickAwayListener to work properly
  useEffect(() => {
    setTimeout(() => {
      openDelayed.current = open;
    }, 10);
  }, [open]);
  const items = useMemo(() => {
    const ItemComponent = (
      mobile ? ListItemButton : desktopCustomPosition ? ListItemButton : MenuItemStyled
    ) as any;
    return children({ ItemComponent });
  }, [mobile, children, desktopCustomPosition]);

  if (mobile) {
    return (
      <BottomDrawer open={open} onOpen={onOpen || (() => {})} onClose={onClose}>
        <List>{items}</List>
      </BottomDrawer>
    );
  }

  if (desktopCustomPosition) {
    return (
      <div style={{ position: "absolute", zIndex: 100000, ...desktopCustomPosition }}>
        <ClickAwayListener
          onClickAway={() => {
            if (openDelayed.current) onClose();
          }}
        >
          <Fade in={open} unmountOnExit={true} mountOnEnter={true}>
            <Card elevation={1}>
              <NoWrapList onClick={onClose}>{items}</NoWrapList>
            </Card>
          </Fade>
        </ClickAwayListener>
      </div>
    );
  }

  return (
    <Menu {...menuPositionProps} anchorEl={anchorEl} open={open} onClose={onClose}>
      {items}
    </Menu>
  );
};

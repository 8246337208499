import React from "react";
import {
  Loader,
  MapGeometry,
  geometryHasPoints,
  MapView,
  MapViewProps,
  SubjectPropIcon,
} from "@avenue-8/ui-2";
import { appEventEmitter } from "../../../../../../../../events/app-event-emitter";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { buildPropertyKey } from "../../../../../../../shared/utils/buildPropertyKey";
import { MapFilter } from "../../comparable-filters";
import { Property, propertyFromPropertyDto } from "../../property";
import {
  SearchComparableFormModel,
  isModelEnoughToSearch,
} from "../../search-comparable-form-model";
import { SwitchGeometryConfirmation } from "../SwitchGeometryConfirmation";
import {
  getColorByType,
  parseComparablePropertyType,
} from "../../../../../../../shared/utils/properties-map-utils";
import cuid from "cuid";
import { LoaderContainer, StyledMap } from "./SearchMapView.styles";

interface Props {
  status: "loading" | "idle" | "error" | "success";
  highlightedPropertyId: string | null;
  filters: SearchComparableFormModel;
  applyFilters: (data: SearchComparableFormModel) => void;
  comparableProperties?: PropertyDto[];
  subjectProperty?: PropertyDto;
  onPopoverClick?: (id: string) => void;
  bootstrapURLKeys?: MapViewProps["bootstrapURLKeys"];
  presentationType: string;
}

const getLatLng = (property?: { latitute?: number; longitude?: number }) => {
  if (!property) return;
  const { latitute, longitude } = property;
  if (latitute && longitude) return { lat: latitute, lng: longitude };
};

export const SearchMapView = (props: Props) => {
  const {
    status,
    comparableProperties,
    subjectProperty,
    highlightedPropertyId,
    filters,
    applyFilters,
    onPopoverClick,
    bootstrapURLKeys,
    presentationType,
  } = props;
  const [draft, setDraft] = React.useState<MapGeometry>({ shape: "none", key: "draft" });
  const [confirmGeometry, setConfirmGeometry] = React.useState<MapGeometry | undefined>(undefined);

  const geometries = React.useMemo(() => {
    const _geometries: MapGeometry[] = [];
    if (filters.mapFilters && filters.mapFilters.shape !== "none") {
      _geometries.push({ ...filters.mapFilters, editable: false, key: "active-map-filter" });
    }
    return _geometries;
  }, [filters.mapFilters]);

  const handleClearGeometries = () => {
    applyFilters({ ...filters, mapFilters: { shape: "none" }, radius: "" });
    appEventEmitter.emit({
      eventType: "cma-search-shape-cleared",
      presentationType,
    });
  };

  const handleGeometryCreate = (geometry: MapGeometry) => {
    geometry.key = cuid();
    let mapFilter: MapFilter | undefined;
    if (geometry.shape === "circle") {
      const { center, radius } = geometry;
      if (center != null && radius != null) {
        mapFilter = { shape: "circle", center, radius };
      }
    } else if (geometry.shape === "polygon") {
      mapFilter = { shape: "polygon", points: geometry.points };
    }
    if (mapFilter != null) {
      applyFilters({ mapFilters: mapFilter });
      if (mapFilter.shape === "polygon")
        appEventEmitter.emit({
          eventType: "cma-search-polygon-applied",
          presentationType,
        });
      else if (mapFilter.shape === "circle")
        appEventEmitter.emit({
          eventType: "cma-search-radius-applied",
          presentationType,
        });
      setDraft({ shape: "none", key: "null" });
    }
  };

  const properties = React.useMemo(() => {
    const comps = isModelEnoughToSearch(filters) ? comparableProperties || [] : [];
    const properties = [
      ...comps.map((p) => propertyFromPropertyDto(p)),
      propertyFromPropertyDto(subjectProperty),
    ]
      .filter((x) => x?.lat && x?.lng)
      .map((property: Property = {} as Property) => {
        return {
          lat: property.lat || 0,
          lng: property.lng || 0,
          id: buildPropertyKey(property),
          subjectProperty: buildPropertyKey(property) === buildPropertyKey(subjectProperty || {}),
          propertyData: {
            id: property.id,
            cover: property?.images?.[0]?.url || null,
            address: property.addressLine1,
            status: property.listingStatus,
            price: property.currentPrice,
            baths: property.baths,
            beds: property.beds,
            type: property.propertyType,
            neighborhood: property.neighborhood,
          },
          color: getColorByType(parseComparablePropertyType(property.listingStatus as any)),
        };
      });
    return properties;
  }, [comparableProperties, filters, subjectProperty]);

  const customActions = React.useMemo(() => {
    const actions: MapViewProps["customActions"] = [];
    if (presentationType === "cma") {
      actions.push({
        id: "center-presentation",
        Icon: () => <SubjectPropIcon />,
        onClick: (methods) => {
          methods.moveMapTo(methods.center);
          appEventEmitter.emit({
            eventType: "cma-search-center-subject-property-button-clicked",
            presentationType,
          });
        },
      });
    }
    return actions;
  }, [presentationType]);

  if (status === "loading") {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }
  if (status === "error") {
    return null;
  }

  return (
    <StyledMap>
      <SwitchGeometryConfirmation
        currentGeometry={draft}
        selectedGeometry={confirmGeometry}
        onConfirmed={(confirmed) => {
          if (confirmed) {
            setDraft(confirmGeometry!);
          }
          setConfirmGeometry(undefined);
        }}
      />
      <MapView
        isExternallyHidden={presentationType === "general" && properties.length === 0}
        hasGeolocationButton={presentationType === "general"}
        customActions={customActions}
        onGeolocationButtonClick={() => {
          appEventEmitter.emit({
            eventType: "cma-search-geolocation-button-clicked",
            presentationType,
          });
        }}
        defaultCenter={getLatLng(subjectProperty)}
        draft={draft}
        onDraftChanged={async ({ geometry, action }) => {
          if (action === "new-shape") {
            const currentShape: "circle" | "polygon" | "none" | undefined =
              (draft?.shape !== "none" ? draft.shape : null) || filters.mapFilters?.shape;
            if (
              currentShape != null &&
              currentShape !== "none" &&
              geometry.shape !== currentShape &&
              geometryHasPoints(draft)
            ) {
              setConfirmGeometry(geometry);
              return;
            }
          }
          setDraft(geometry);
        }}
        properties={properties}
        geometries={geometries}
        onClearGeometries={handleClearGeometries}
        onGeometryCreate={handleGeometryCreate}
        highlightPropertyId={highlightedPropertyId}
        onPopoverClick={onPopoverClick}
        bootstrapURLKeys={bootstrapURLKeys}
      />
    </StyledMap>
  );
};

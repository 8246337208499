import { useEffect } from "react";
import { useAuthContext } from "./AuthContext";

/**
 * Triggers authentication reconciliation on certain events.
 * The auth data reconciliation logic is contained in the AuthContext.
 * Reconciliation is very cheap - there is no server calls.
 */
export const AuthenticationReconciliator = () => {
  const {
    actions: { reconciliateAuthentication },
  } = useAuthContext();
  useEffect(() => {
    const reconciliate = () => {
      setTimeout(() => {
        if (!window.document.hasFocus()) return;
        reconciliateAuthentication();
      }, 1);
    };

    window.addEventListener("focus", reconciliate);

    reconciliate();
    const interval = setInterval(reconciliate, 5000);

    return () => {
      clearInterval(interval);
      window.removeEventListener("focus", reconciliate);
    };
  }, [reconciliateAuthentication]);
  return null;
};

import * as React from "react";
import { Stepper, StepperItem } from "@avenue-8/ui-2";

enum GeneralPresentationStepperSteps {
  "Search",
  "Customize Presentation",
  "Finished",
}

export const GeneralPresentationStepper = ({
  currentStep,
  onClickStep,
}: {
  currentStep: GeneralPresentationStepperSteps;
  onClickStep?: (step: GeneralPresentationStepperSteps) => void;
}) => {
  const getHandleClick = (step: GeneralPresentationStepperSteps) => {
    return onClickStep ? () => onClickStep(step) : undefined;
  };

  return (
    <Stepper data-testid="steppers">
      <StepperItem
        order={GeneralPresentationStepperSteps["Search"]}
        currentStep={currentStep}
        onClick={getHandleClick(GeneralPresentationStepperSteps["Search"])}
        data-testid="stepper-search"
      >
        Search
      </StepperItem>
      <StepperItem
        order={GeneralPresentationStepperSteps["Customize Presentation"]}
        currentStep={currentStep}
        onClick={getHandleClick(GeneralPresentationStepperSteps["Customize Presentation"])}
        data-testid="stepper-customize"
      >
        Customize Presentation
      </StepperItem>
    </Stepper>
  );
};

import * as React from "react";
import { Stepper, StepperItem } from "@avenue-8/ui-2";

export enum AgentOnboardingSteps {
  "Personal Details",
  "Agent Details",
  "Upload Headshot",
  "Review",
  "Finish",
}

export const AgentOnboardingStepper = ({
  currentStep,
  onClickStep,
}: {
  currentStep: AgentOnboardingSteps;
  onClickStep?: (step: AgentOnboardingSteps) => void;
}) => {
  const getHandleClick = (step: AgentOnboardingSteps) => {
    return onClickStep ? () => onClickStep(step) : undefined;
  };

  return (
    <Stepper>
      <StepperItem
        order={AgentOnboardingSteps["Personal Details"]}
        currentStep={currentStep}
        onClick={getHandleClick(AgentOnboardingSteps["Personal Details"])}
      >
        Personal Details
      </StepperItem>
      <StepperItem
        order={AgentOnboardingSteps["Agent Details"]}
        currentStep={currentStep}
        onClick={getHandleClick(AgentOnboardingSteps["Agent Details"])}
      >
        Agent Details
      </StepperItem>
      <StepperItem
        order={AgentOnboardingSteps["Upload Headshot"]}
        currentStep={currentStep}
        onClick={getHandleClick(AgentOnboardingSteps["Upload Headshot"])}
      >
        Upload Headshot
      </StepperItem>
      <StepperItem
        order={AgentOnboardingSteps["Review"]}
        currentStep={currentStep}
        onClick={getHandleClick(AgentOnboardingSteps["Review"])}
      >
        Review
      </StepperItem>
    </Stepper>
  );
};

import _emotionStyled from "@emotion/styled";
import { StyreneAFontFamily, BookmaniaFontFamily } from "./fonts";

// Color names from https://www.htmlcsscolor.com/
const colors = {
  black: "#131319",
  white: "#FFFFFF",
  ghostWhite: "#FAFAFF",
  green: "#107F10",
  red: "#D90D02",
  scampi: "#73738C",
  lavender: "#EEEEFD",
  manatee: "#8C8EA1",
  persianBlue: "#2A29A6",
  quartz: "#D5D6EA",
};

export const avenue8Theme = {
  fontFamily: StyreneAFontFamily,
  headerFontFamily: BookmaniaFontFamily,
  primaryColor: colors.persianBlue,
};

export type BaseTheme = typeof avenue8Theme;

type StyledTaggedTemplate<BaseTheme, ComponentProps = any> = <ExtensionProps = unknown>(
  strings: TemplateStringsArray,
  ...rest: Array<(props: ExtensionProps & ComponentProps & { theme: BaseTheme }) => any>
) => React.ComponentType<ExtensionProps & ComponentProps>;

type StyledLikeThemed<BaseTheme = any> = {
  [TTag in keyof JSX.IntrinsicElements]: StyledTaggedTemplate<
    BaseTheme,
    JSX.IntrinsicElements[TTag]
  >;
} &
  (<C>(
    elementType: React.Component<C> | React.FC<C> | React.JSXElementConstructor<C>
  ) => StyledTaggedTemplate<BaseTheme, C>);

export const baseStyledThemed = _emotionStyled as any as StyledLikeThemed<BaseTheme>;

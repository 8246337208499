export function GalleryIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        fill="#fff"
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
      ></path>
      <path
        fill="#2A29A6"
        fillRule="evenodd"
        d="M14.273 15.546H13v10.182c0 .7.573 1.273 1.273 1.273h10.181v-1.273H14.274V15.546zM25.728 13h-8.91c-.7 0-1.272.573-1.272 1.273v8.909c0 .7.573 1.273 1.273 1.273h8.909c.7 0 1.273-.573 1.273-1.273v-8.91c0-.7-.573-1.272-1.273-1.272zm-.001 10.182h-8.91v-8.909h8.91v8.91z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

import { SectionConfig, SectionView, InvalidView, SectionConfigBase } from "../models";
import { SectionData } from "../models/section-data/section-data";
import { PresentationViewBuilderContext } from "../presentation-view-builder";
import { SectionViewBuilder as GenericSectionViewBuilder } from "../sections/section-widget";
import { sectionWidgetRegistry } from "../sections/section-widget-registry";

export type SectionViewBuilder = GenericSectionViewBuilder<
  SectionConfig,
  SectionView,
  SectionData | null
>;

export interface SectionViewBuilderContext<
  C extends SectionConfigBase,
  D extends SectionData | null
> {
  sectionConfig: C;
  sectionData: D;
  context: PresentationViewBuilderContext;
}

export const buildSectionView: SectionViewBuilder = (data) => {
  const { sectionConfig } = data;
  const sectionId = sectionConfig.id;
  const builder = sectionWidgetRegistry.get(sectionConfig.type);

  if (!builder) {
    return { type: "invalid", id: sectionId } as InvalidView;
  }

  return builder.buildView(data);
};

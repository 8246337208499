import { useEffect } from "react";
import { useHistory } from "react-router";
import { authRoutes } from "src/modules/auth/auth.routes";
import { useAuthContext } from "./AuthContext";
import { Authenticated } from "./Authenticated";

/**
 * Component that add some redirection logic. Ideally, it should not exist.
 * We should have redirection logic  handled in the same place where we define the application routes.
 * Redirect is just a special type of route.
 * TODO: Refactor the app based on the description above.
 */
export const AuthenticatedRedirect = function ({ children }: { children: any }) {
  const {
    state: { user, userInfo },
    actions: { isAuthenticated },
  } = useAuthContext();
  const history = useHistory();

  const getRedirectUrlQuery = () => {
    return window.location.pathname.indexOf("/auth") === -1
      ? `?url=${encodeURIComponent(window.location.pathname)}`
      : "";
  };

  const { role } = user || {};
  const { completedOnboardingAt } = userInfo || {};

  useEffect(() => {
    if (!isAuthenticated()) {
      const query = getRedirectUrlQuery();
      history.push(`${authRoutes.auth.route}${query}`);
      return;
    }
    if (
      role === "agent" &&
      !completedOnboardingAt &&
      !history.location.pathname.startsWith("/agent-onboarding")
    ) {
      history.push("/agent-onboarding");
      return;
    }
  }, [isAuthenticated, role, completedOnboardingAt, history]);

  return <Authenticated>{children}</Authenticated>;
};

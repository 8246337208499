import * as React from "react";
import { Select, InputLabel, SelectOption, SelectProps, FormHelperText } from "@avenue-8/ui-2";
import { Controller, useFormContext } from "react-hook-form";
import { usaStatesTitleCase, StateTitleCase } from "../../../shared/constants/usaStates";

const renderStateOptions = (list?: Array<StateTitleCase>) => {
  return list?.map((item) => {
    return (
      <SelectOption value={item.abbreviation} key={`state-${item.abbreviation}`}>
        {item.abbreviation}
      </SelectOption>
    );
  });
};

export type StateFieldSelectProps = {
  label?: string;
  name?: string;
  selectProps?: SelectProps;
};
export const StateField = (props: StateFieldSelectProps) => {
  const { label = "State", name = "state", selectProps } = props;
  const { errors } = useFormContext();
  return (
    <>
      <InputLabel htmlFor={name} id="state-label">
        {label}
      </InputLabel>
      <Controller
        name={name}
        rules={{ required: "Missing State" }}
        render={({ ref, ...rest }) => (
          <Select
            labelId="state-label"
            fullWidth
            inputRef={ref}
            {...rest}
            error={!!errors[name]}
            {...selectProps}
            onBlur={(e) => {
              selectProps?.onBlur?.(e);
              rest.onBlur();
            }}
          >
            {renderStateOptions(usaStatesTitleCase)}
          </Select>
        )}
      />
      <FormHelperText error={!!errors[name]}>{errors[name]?.message}</FormHelperText>
    </>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PresentationConfigDto,
    PresentationConfigDtoFromJSON,
    PresentationConfigDtoFromJSONTyped,
    PresentationConfigDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpsertTemplateRequestDto
 */
export interface UpsertTemplateRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpsertTemplateRequestDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UpsertTemplateRequestDto
     */
    name: string;
    /**
     * 
     * @type {PresentationConfigDto}
     * @memberof UpsertTemplateRequestDto
     */
    data: PresentationConfigDto;
}

export function UpsertTemplateRequestDtoFromJSON(json: any): UpsertTemplateRequestDto {
    return UpsertTemplateRequestDtoFromJSONTyped(json, false);
}

export function UpsertTemplateRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsertTemplateRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'data': PresentationConfigDtoFromJSON(json['data']),
    };
}

export function UpsertTemplateRequestDtoToJSON(value?: UpsertTemplateRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'data': PresentationConfigDtoToJSON(value.data),
    };
}



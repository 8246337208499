/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RangeFilterParams
 */
export interface RangeFilterParams {
    /**
     * 
     * @type {number}
     * @memberof RangeFilterParams
     */
    startAt?: number;
    /**
     * 
     * @type {number}
     * @memberof RangeFilterParams
     */
    endAt?: number;
}

export function RangeFilterParamsFromJSON(json: any): RangeFilterParams {
    return RangeFilterParamsFromJSONTyped(json, false);
}

export function RangeFilterParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RangeFilterParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startAt': !exists(json, 'startAt') ? undefined : json['startAt'],
        'endAt': !exists(json, 'endAt') ? undefined : json['endAt'],
    };
}

export function RangeFilterParamsToJSON(value?: RangeFilterParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startAt': value.startAt,
        'endAt': value.endAt,
    };
}



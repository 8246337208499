import { TextField, TextFieldProps } from "@avenue-8/ui-2";
import { Controller, ControllerRenderProps, DeepMap, FieldError, useForm } from "react-hook-form";
import { alphanumericOnly, simpleEmail } from "../../../../shared/utils/validation-patterns";
import { StateTextField } from "../../StateTextField";

const TextFieldRenderer =
  ({
    textFieldProps,
    errors,
    errorMessage,
    notifyChange,
    component,
  }: {
    component?: any;
    notifyChange?: () => void;
    errorMessage?: string;
    errors?: DeepMap<Record<string, any>, FieldError>;
    textFieldProps: TextFieldProps;
  }) =>
  (props: ControllerRenderProps<Record<string, any>>) => {
    const error = errors?.[props.name];
    const TextFieldC = (component ?? TextField) as typeof TextField;
    return (
      <TextFieldC
        {...textFieldProps}
        variant="outlined"
        margin="normal"
        size="small"
        fullWidth={true}
        error={error != null}
        helperText={error != null ? errorMessage ?? "The field is invalid." : undefined}
        onChange={(e) => {
          props.onChange(e.target.value);
          notifyChange?.();
        }}
        value={props.value}
      />
    );
  };

export interface EditAgentAccountFormModel {
  agentAccountId?: string;
  name: string;
  email: string;
  dre?: string;
  state?: string;
}

export interface EditAgentAccountFormProps {
  id: string;
  data?: EditAgentAccountFormModel;
  onSave: (data: EditAgentAccountFormModel) => void;
  onChanged?: () => void;
}

export const EditAgentAccountForm = (props: EditAgentAccountFormProps) => {
  const { id, data, onChanged, onSave } = props;
  const { control, handleSubmit, errors, watch } = useForm();
  const notifyChange = () => onChanged?.();

  const isDreFilled = !!String(watch("dre") ?? "").trim();

  const handleSave = (formData: EditAgentAccountFormModel) => {
    onSave(formData);
  };

  const validateEmptyField = (value: any) => !!value.trim();

  return (
    <form id={id} onSubmit={handleSubmit(handleSave)}>
      <Controller
        name="name"
        defaultValue={data?.name ?? ""}
        control={control}
        rules={{ required: true, validate: validateEmptyField }}
        render={TextFieldRenderer({
          notifyChange,
          errors,
          textFieldProps: {
            InputLabelProps: { required: true },
            label: "Name",
            type: "text",
          },
        })}
      />
      <Controller
        name="email"
        defaultValue={data?.email ?? ""}
        control={control}
        rules={{ required: true, pattern: simpleEmail, validate: validateEmptyField }}
        render={TextFieldRenderer({
          notifyChange,
          errors,
          textFieldProps: {
            InputLabelProps: { required: true },
            label: "Email address",
            type: "email",
          },
        })}
      />
      <Controller
        name="dre"
        defaultValue={data?.dre ?? ""}
        control={control}
        rules={{ required: false, pattern: alphanumericOnly }}
        render={TextFieldRenderer({
          notifyChange,
          errors,
          textFieldProps: {
            InputLabelProps: { required: false },
            label: "License Number",
            type: "text",
          },
        })}
      />
      <Controller
        name="state"
        defaultValue={data?.state ?? ""}
        control={control}
        rules={{ required: isDreFilled }}
        render={TextFieldRenderer({
          component: StateTextField,
          notifyChange,
          errors,
          textFieldProps: {
            InputLabelProps: { required: isDreFilled },
            label: "State",
            type: "text",
          },
        })}
      />
    </form>
  );
};

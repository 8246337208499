import { rest } from "msw";
import {
  LocalAuthControllerAuthenticateRequest,
  LocalLoginResponse,
  LocalLoginParams,
} from "src/modules/shared/apis/user/generated";

export const TEST_JWT_TOKEN =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3RAYXZlbnVlOC5jb20iLCJyb2xlIjoiYWdlbnQiLCJhZ2VudElkIjowLCJ1c2VySWQiOjAsImlhdCI6MTY0NDg1MDE1NiwiZXhwIjozNjQ0OTM2NTU2fQ.70Q2Dke_XnU1hvb3e9k7vDur8iF2AssXfpV6eaUTF9I";

export const TEST_USER_INFO: LocalLoginResponse["info"] = {
  fullName: "John Doe",
  avatar: "https://placekitten.com/200/200?image=1",
  isFirstLogin: false,
  completedOnboardingAt: new Date(2022, 6, 15),
};

export const TEST_USER_INFO_BASE64 = window.btoa(JSON.stringify(TEST_USER_INFO));

export const authHandlers = [
  // This request is not testable like we would want because we use this as a link and not as an API call. (It basically redirects to the /auth/success route that is being tested directly on LoginSuccessPage)
  rest.get(`${process.env.REACT_APP_CMA_API_URL}/auth/google`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.set(
        "Location",
        `/auth/success?token=${TEST_JWT_TOKEN}&info=${encodeURIComponent(TEST_USER_INFO_BASE64)}`
      )
    );
  }),
  rest.post<LocalAuthControllerAuthenticateRequest, LocalLoginParams, LocalLoginResponse>(
    `${process.env.REACT_APP_CMA_API_URL}/auth/login`,
    (req, res, ctx) => {
      return res(
        ctx.status(201),
        ctx.json({
          token: TEST_JWT_TOKEN,
          info: TEST_USER_INFO,
        })
      );
    }
  ),
];

export const authenticateWithEmailAndPasswordFailMockHandler = rest.post<
  LocalAuthControllerAuthenticateRequest,
  LocalLoginParams,
  any
>(`${process.env.REACT_APP_CMA_API_URL}/auth/login`, (req, res, ctx) => {
  return res(
    ctx.delay(250),
    ctx.status(401),
    ctx.json({
      customMessage: "User or password incorrect",
      path: "/auth/login",
      serverMessage: "User or password incorrect",
      statusCode: 401,
      timestamp: "2022-08-03T21:00:26.464Z",
    })
  );
});

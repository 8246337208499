import { getStatusByListingStatus } from "./../../../../shared/utils/normalizePropertyStatus"; // TODO: remove this external dependency
import { ComparableProperty } from "../../models";
import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { NeighborhoodMapSectionConfig } from "./neighborhood-map-section-config";
import {
  NeighborhoodMapProperty,
  NeighborhoodMapSectionView,
} from "./neighborhood-map-section-view";
import { v4 as uuidv4 } from "uuid";

export class NeighborhoodMapSectionWidget
  implements
    SectionWidget<"neighborhood-map", NeighborhoodMapSectionConfig, NeighborhoodMapSectionView>
{
  type: "neighborhood-map" = "neighborhood-map";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  buildDefaultConfig(): NeighborhoodMapSectionConfig {
    return {
      type: "neighborhood-map",
      navTitle: "Key Features",
      id: uuidv4(),
      visibility: true,
    };
  }

  private parseComparablePropertyType = (
    property: ComparableProperty
  ): NeighborhoodMapProperty["type"] => {
    const normalizedStatus = getStatusByListingStatus(property.listingStatus as any);
    if (!normalizedStatus) return "other";
    if (normalizedStatus === "sold") return "comparable-sold";
    if (["active", "soon"].includes(normalizedStatus)) return "comparable-active";
    if (["pending", "contingent"].includes(normalizedStatus)) return "comparable-pending";
    return "other";
  };

  buildView({
    sectionConfig: config,
    context,
  }: SectionViewBuilderContext<NeighborhoodMapSectionConfig, null>): NeighborhoodMapSectionView {
    if (config.type !== "neighborhood-map") throw new Error("Unexpected section type.");
    const { id, type, visibility } = config;
    const { comparableProperties, featuredProperty } = context.sourceData;
    const properties = [...comparableProperties, featuredProperty];
    const view: NeighborhoodMapSectionView = {
      id,
      type,
      navTitle: "Neighborhood",
      visibility,
      properties: properties.map((property) => {
        return {
          id: property.id,
          lat: property.latitude as NeighborhoodMapProperty["lat"], // it was being casted by casting the whole object
          lng: property.longitude as NeighborhoodMapProperty["lng"], // maybe we can default it to 0? or allow null on its model
          tooltip: `${property.addressLine1} ${
            property.addressLine2 ? ` ${property.addressLine2}` : ""
          }`,
          type:
            property === featuredProperty ? "subject" : this.parseComparablePropertyType(property),
        } as NeighborhoodMapProperty;
      }),
    };
    return view;
  }
}

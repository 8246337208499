export function asyncPopupUrlOpener(config: { buildUrl: () => Promise<string> }) {
  const windowRef = window.open();

  config.buildUrl().then((url) => {
    if (windowRef) {
      windowRef.location.href = url;
    } else {
      //fallback - use the current window
      window.location.href = url;
    }
  });
}

import { ManualCompCTAContainer } from "./SearchOptions.styles";
import { Typography } from "@avenue-8/ui-2";

interface Props {
  onAddComparablePropertyClick: () => void;
}

export const ManualCompCTA = (props: Props) => (
  <ManualCompCTAContainer id="manually-add-comparable-property-copy-wrapper">
    <Typography variant="body2" color="secondary">
      Can't find the result that you are looking for? You can manually{" "}
      <Typography
        role="button"
        data-meta-action="add-manual-comp"
        variant="body2"
        color="secondary"
        onClick={props.onAddComparablePropertyClick}
        component="span"
      >
        add a comparable property.
      </Typography>
    </Typography>
  </ManualCompCTAContainer>
);

import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CloseIcon } from "@avenue-8/ui-2";
import { SearchComparableFormModel } from "../../search-comparable-form-model";
import { SearchFiltersForm } from "../SearchFiltersForm";
import {
  StyledModal,
  ModalTitle,
  ModalContent,
  CloseButton,
  Actions,
  StyledButton,
} from "./SearchFiltersModal.stytes";

interface Props {
  onClose: () => void;
  filters: SearchComparableFormModel;
  onApplyFilters: (data: SearchComparableFormModel) => void;
  onClearFilters: (presentationType: string) => void;
  open: boolean;
  presentationType: string | undefined;
  radiusFilterCoords:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
}

export const SearchFiltersModal = (props: Props) => {
  const {
    open,
    filters,
    onApplyFilters,
    onClearFilters,
    onClose,
    presentationType,
    radiusFilterCoords,
  } = props;
  const formMethods = useForm<SearchComparableFormModel>({
    defaultValues: { ...filters },
    reValidateMode: "onChange",
  });
  const { reset } = formMethods; // to avoid unnucesary rerenders from formMethods

  const handleClear = () => {
    onClearFilters(presentationType!);
  };

  React.useEffect(() => {
    reset({ ...filters });
  }, [filters, reset]);

  return (
    <StyledModal
      open={open}
      fullWidth
      maxWidth={false}
      scroll={"paper"}
      BackdropProps={{
        unmountOnExit: true,
      }}
      onClose={onClose}
    >
      <FormProvider {...formMethods}>
        <ModalTitle id="filters-modal-title">Filters</ModalTitle>
        <CloseButton variant="text" onClick={onClose} data-testid="close-modal">
          <CloseIcon />
        </CloseButton>
        <ModalContent>
          <SearchFiltersForm
            onApplyFilters={onApplyFilters}
            onClose={onClose}
            presentationType={presentationType}
            radiusFilterCoords={radiusFilterCoords}
          />
        </ModalContent>
        <Actions>
          <StyledButton onClick={handleClear} data-testid="clear-filters">
            Clear filters
            <CloseIcon />
          </StyledButton>
          <StyledButton type="submit" form="search-step-filters">
            Apply filters
          </StyledButton>
        </Actions>
      </FormProvider>
    </StyledModal>
  );
};

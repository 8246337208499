import { heapFactory } from "./heap-tracker-factory";
import { HeapTracker } from "./heap-trackers";

let singletonInstance: HeapTracker;

export const useHeap = () => {
  if (!singletonInstance) {
    singletonInstance = heapFactory(process.env.REACT_APP_HEAP_MODE);
  }
  return singletonInstance;
};

import { Controller, useFormContext } from "react-hook-form";
import { InputLabel, FormHelperText, styled } from "@avenue-8/ui-2";
import { SuggestionsInput } from "../CreateCMA/SuggestionsInput";
import { getTextFieldErrors } from "../TextFieldBase";

interface Props {
  fieldName?: string;
  fieldLabel?: string;
  fieldProps?: any;
  showFixedErrorMessage?: boolean;
}

const ErrorMessage = styled(FormHelperText)`
  margin-top: 0.25rem;
  height: 0.5rem;
  color: ${(p) => p.theme.av8.dangerColor};
`;

export const MlsAreaField = (props: Props) => {
  const {
    fieldName = "mlsArea",
    fieldLabel = "MLS Area",
    fieldProps,
    showFixedErrorMessage,
  } = props;
  const { errors, setValue } = useFormContext();
  const errorMessage = errors[fieldName]?.message;

  const fieldErrors = !showFixedErrorMessage
    ? getTextFieldErrors({ errors, name: "neighborhood" })
    : {};

  return (
    <>
      <div>
        <InputLabel htmlFor={fieldName}>{fieldLabel}</InputLabel>
        <Controller
          name={fieldName}
          render={({ ref, ...rest }) => (
            <SuggestionsInput
              textFieldProps={{
                ...rest,
                fullWidth: true,
                inputRef: ref,
                ...fieldProps,
                ...fieldErrors,
                onChange: (e) => {
                  fieldProps?.onChange?.(e);
                  rest.onChange(e);
                },
              }}
              onSuggestionChosen={(chosenSuggestion) => {
                setValue(fieldName, chosenSuggestion.area ?? "");
              }}
              acceptedSuggestions={["area"]}
              optionLabelText="area"
            />
          )}
        />
      </div>
      {showFixedErrorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
};

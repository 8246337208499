/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MlsFilter
 */
export interface MlsFilter {
    /**
     * 
     * @type {string}
     * @memberof MlsFilter
     */
    mlsId: string;
    /**
     * Market
     * @type {string}
     * @memberof MlsFilter
     */
    mlsSource: string;
}

export function MlsFilterFromJSON(json: any): MlsFilter {
    return MlsFilterFromJSONTyped(json, false);
}

export function MlsFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MlsFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mlsId': json['mlsId'],
        'mlsSource': json['mlsSource'],
    };
}

export function MlsFilterToJSON(value?: MlsFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
    };
}



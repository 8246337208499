import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { InputLabel, Grid, Typography, Button, Loader, Fieldset, toast } from "@avenue-8/ui-2";
import { BackButton, NextButton } from "../../../Button";
import { SearchSubjectPropertyModal } from "../../SearchSubjectProperty/SearchSubjectPropertyModal";
import { CustomPrompt, PromptAction } from "../../../CustomPrompt";
import { CMAStatus } from "../../../../../shared/cma-status";
import { useCreateCMALogic } from "../../../../hooks/useCreateCMALogic";
import {
  convertSubjectPropertyStepModelToSubjectProperty,
  getSubjectPropertyPhotos,
  SubjectPropertyStepModel,
} from "../../subject-property";
import { PhotosUploader } from "../../../PhotosUploader";
import { PropertyPhotosPreview } from "../../../PropertyPhotosPreview";
import { PhotoGalleryContextProvider } from "../../../../../shared/contexts/PhotoGalleryContext";
import { useSubjectPropertyStepLogic } from "../../../../hooks/useSubjectPropertyStepLogic";
import { handleSavableStepNavigation, useCMARouteStepHelper } from "../../step-navigation-helper";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { BottomBar } from "../../CreateCMA";
import { SubjectPropertyForm } from "./SubjectPropertyForm";
import { DynamicDialog } from "../../../DynamicDialog";
import { geocodeSearch } from "../../AddressInput/geocode-search";
import { usePlacesAutocomplete } from "src/modules/shared/hooks/usePlacesAutocomplete";
import { MlsPopover } from "../../../MlsPopover";
import {
  Container,
  TitleHeaderGrid,
  MLSActions,
  LoaderContainer,
  Form,
  FormItem,
} from "./SubjectPropertyStep.styles";

interface SubjectPropertyStepProps {
  cmaId: string;
}

export const SubjectPropertyStep = ({ cmaId }: SubjectPropertyStepProps) => {
  const { state } = useCreateCMALogic();

  const {
    actions: {
      updateSubjectPropertyFormDraft,
      discardSubjectPropertyFormDraft,
      updateCmaSubjectProperty,
      uploadSubjectPropertyPhotos,
      makeCoverPhoto,
      removeSubjectPropertyPhoto,
      getSubjectPropertyFormDraft,
    },
    state: {
      subjectPropertyForm,
      uploadSubjectPropertyPhotos: { status: uploadSubjectPropertyPhotosStatus },
    },
    importSubjectPropertyMutation,
  } = useSubjectPropertyStepLogic();
  React.useEffect(() => {
    appEventEmitter.emit({ eventType: "cma-subject-property-opened" });
  }, []);
  const photos = state.cma ? getSubjectPropertyPhotos(state.cma) : [];
  const [suggestionsToChoose, setSuggestionsToChoose] = React.useState<any[]>([]);

  const cmaRouteStepHelper = useCMARouteStepHelper(state.cma?.presentationType);
  const nextStep = cmaRouteStepHelper.getNextStep();
  const history = useHistory();

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: { ...subjectPropertyForm },
  });

  const { trigger, setValue, getValues, reset } = formMethods;

  const {
    suggestions: { data: suggestionsData },
    setValue: setAutocompleteSuggestionValue,
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleOnFormsValuesUpdated = React.useCallback(
    (data: any) => {
      updateSubjectPropertyFormDraft(data);
    },
    [updateSubjectPropertyFormDraft]
  );

  const [searchPropertyModalOpen, setSearchPropertyModalOpen] = React.useState(false);
  const hasSearched = React.useRef(false);
  const [lastAddressSearched, setLastAddressSearched] = React.useState<string | null>(null);

  const data = subjectPropertyForm;
  const isCmaPublished = state.cma?.status === CMAStatus.ACTIVE;
  const cma = state.cma;

  const discardChangesModalActions: PromptAction[] = [
    {
      label: "Discard changes",
      handler: (context) => {
        discardSubjectPropertyFormDraft();
        context.confirmNavigation();
      },
    },
    {
      label: "Apply",
      handler: async (context) => {
        //Should ALSO await save on leave
        const validForm = await trigger();
        if (!validForm) {
          context.close();
          toast.error("Please fill in all required fields.");
          return;
        }

        await updateCmaSubjectProperty({
          id: data.cmaId,
          subjectProperty: convertSubjectPropertyStepModelToSubjectProperty(getValues()),
        });
        context.confirmNavigation();
      },
      primary: true,
    },
  ];

  const handleSave = async (data: SubjectPropertyStepModel) => {
    await updateCmaSubjectProperty({
      subjectProperty: convertSubjectPropertyStepModelToSubjectProperty(data),
      id: data.cmaId,
    });
  };

  const handleUpdateLocationFieldsFromSuggestion = React.useCallback(
    async (suggestion: Record<string, any>): Promise<void> => {
      const geocodeResult = await geocodeSearch(suggestion.description);
      if (geocodeResult) {
        const { city, neighborhood, state, zipCode: zip, lat, lng } = geocodeResult;
        const streetNameFromSuggetion =
          suggestion.structured_formatting?.main_text || suggestion.title.split(", ")[0];
        const address1 = `${geocodeResult.streetNumber ? `${geocodeResult.streetNumber} ` : ""}${
          geocodeResult.streetName || streetNameFromSuggetion || ""
        }`.trim();
        const address2 = `${geocodeResult.premise ? `${geocodeResult.premise} ` : ""} ${
          geocodeResult.subpremise ? `${geocodeResult.subpremise} ` : ""
        }`.trim();
        setValue("addressLine1", address1 ?? "");
        setValue("addressLine2", address2 ?? "");
        setValue("city", city ?? "");
        setValue("neighborhood", neighborhood ?? "");
        setValue("state", state ?? "");
        setValue("zip", zip ?? "");
        setValue("lat", lat ?? "");
        setValue("lng", lng ?? "");

        handleOnFormsValuesUpdated({
          ...getValues(),
          addressLine1: address1 || "",
          addressLine2: address2 || "",
          city: city || "",
          neighborhood: neighborhood || "",
          state: state || "",
          zip: zip || "",
          lat: lat || "",
          lng: lng || "",
        });

        const hasEmptyGeocodeField = !(await trigger([
          "addressLine1",
          "city",
          "state",
          "zip",
          "neighborhood",
          "lat",
          "lng",
        ]));
        if (hasEmptyGeocodeField) {
          setLastAddressSearched(address1);
          toast.info("Some fields couldn't be auto filled, please specify them manually.");
        }
        setSuggestionsToChoose([]);
        clearSuggestions();
      }
    },
    [clearSuggestions, getValues, handleOnFormsValuesUpdated, setValue, trigger]
  );

  const handleNextClick = async (_e: React.MouseEvent) => {
    const { addressLine1, lat, lng, neighborhood, zip, city, state } = getValues();
    const hasEmptyLocationFields = [lat, lng, addressLine1, neighborhood, zip, city, state].filter(
      (field) => !field || !field?.trim()
    );
    await trigger();
    if (hasEmptyLocationFields.length > 0) {
      if (!addressLine1 || addressLine1 === lastAddressSearched) return;
      const searchString = addressLine1.trim();
      clearSuggestions();
      setLastAddressSearched(searchString);
      setAutocompleteSuggestionValue(searchString);
      hasSearched.current = true;
      return;
    }
    cmaRouteStepHelper.handleForwardClick(history);
  };

  React.useEffect(() => {
    if (suggestionsData && suggestionsData.length > 0) {
      const actions = suggestionsData.map((result: any) => {
        return {
          label: result.description,
          onClick: () => handleUpdateLocationFieldsFromSuggestion(result),
        };
      });
      setSuggestionsToChoose(actions);
    }
    if (hasSearched.current && suggestionsData && suggestionsData.length === 0) {
      toast.error(
        `We cannot find your address. Please confirm if it is correct: ${getValues("addressLine1")}`
      );
    }
    hasSearched.current = false;
  }, [getValues, handleUpdateLocationFieldsFromSuggestion, hasSearched, suggestionsData]);

  React.useEffect(() => {
    reset(subjectPropertyForm);
  }, [reset, subjectPropertyForm]);

  return (
    <Container>
      <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5" data-testid="header-title">
            Property details + media
          </Typography>
        </Grid>
        <MLSActions>
          <MlsPopover id="subject-property-supported-mls-list-popover" />
          <Button onClick={() => setSearchPropertyModalOpen(true)} data-testid="mls-button">
            Fill in details via MLS
          </Button>
        </MLSActions>
      </TitleHeaderGrid>
      {state.cmaStatus === "loading" && (
        <LoaderContainer container item justifyContent="center" alignItems="center">
          <Loader color="black" />
        </LoaderContainer>
      )}
      {state.cmaStatus === "success" && (
        <Grid container item spacing={3}>
          <Grid item md={8} xs={12}>
            <FormProvider {...formMethods}>
              <SubjectPropertyForm
                subjectProperty={cma?.subjectProperty}
                isFetchingCma={state.isFetchingCma}
                handleSave={handleSave}
                handleOnFormsValuesUpdated={handleOnFormsValuesUpdated}
                handleUpdateLocationFieldsFromSuggestion={handleUpdateLocationFieldsFromSuggestion}
              />
            </FormProvider>
          </Grid>
          <Grid container item md={4} xs={12}>
            <Form id="cma-subject-property-photos">
              <Fieldset disabled={state.isFetchingCma}>
                <FormItem item xs={12}>
                  <InputLabel htmlFor="media">Media</InputLabel>
                  <PhotosUploader
                    content={
                      photos.length > 0 && (
                        <PropertyPhotosPreview
                          photos={photos}
                          inputId={"cma-property-photos"}
                          makeCoverPhoto={makeCoverPhoto}
                          removePropertyPhoto={({ photoId }) =>
                            removeSubjectPropertyPhoto({ photoId, cmaId })
                          }
                        />
                      )
                    }
                    description="Drag + drop your photos here or input by MLS I.D or address"
                    onUploadFiles={(data) => {
                      uploadSubjectPropertyPhotos(data);
                    }}
                    isUploading={uploadSubjectPropertyPhotosStatus === "loading"}
                    disableClickEvent={true}
                    inputId={"cma-property-photos"}
                  />
                </FormItem>
              </Fieldset>
            </Form>
          </Grid>
        </Grid>
      )}
      {!!nextStep?.label && (
        <BottomBar>
          <BackButton onClick={() => cmaRouteStepHelper.handleBackClick(history)}>Back</BackButton>
          <NextButton onClick={handleNextClick}>Next: {nextStep.label}</NextButton>
        </BottomBar>
      )}
      <CustomPrompt
        when={true}
        actions={discardChangesModalActions}
        onClose={(context) => context.close()}
        onPrompting={async (location) => {
          const hasChanges = !!getSubjectPropertyFormDraft();
          const validForm = await trigger();

          const values = getValues();
          return handleSavableStepNavigation({
            action: cmaRouteStepHelper.getPathDirection(location.pathname) ?? "leaveFlow",
            hasChanges,
            save: () => {
              return handleSave(values);
            },
            validForm,
          });
        }}
        title="Apply changes?"
        message={
          isCmaPublished
            ? "You have made changes to this CMA that have not been published to your presentation. Would you like to apply these changes? These will be visible to your client."
            : "You have made changes to this CMA that have not been saved. Would you like to apply these changes? These changes will only be visible to your client after completing the CMA."
        }
      />
      <DynamicDialog
        message={
          suggestionsToChoose.length > 1
            ? `Please select an address to autofill.`
            : `Do you want to autofill from this address?`
        }
        onClose={() => setSuggestionsToChoose([])}
        open={suggestionsToChoose.length > 0}
        actionsAsList
        actions={suggestionsToChoose}
      />
      <PhotoGalleryContextProvider>
        <SearchSubjectPropertyModal
          open={searchPropertyModalOpen}
          onClose={() => setSearchPropertyModalOpen(false)}
          presentationType={cma?.presentationType}
          cmaId={data.cmaId}
          importSubjectProperty={importSubjectPropertyMutation.mutateAsync}
        />
      </PhotoGalleryContextProvider>
    </Container>
  );
};

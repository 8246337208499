/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LocalLoginParams
 */
export interface LocalLoginParams {
    /**
     * 
     * @type {string}
     * @memberof LocalLoginParams
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof LocalLoginParams
     */
    password: string;
}

export function LocalLoginParamsFromJSON(json: any): LocalLoginParams {
    return LocalLoginParamsFromJSONTyped(json, false);
}

export function LocalLoginParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LocalLoginParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
        'password': json['password'],
    };
}

export function LocalLoginParamsToJSON(value?: LocalLoginParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
    };
}



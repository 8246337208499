import React, { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import { TextFieldProps, TextField } from "@avenue-8/ui-2";

interface PasswordInputProps {
  label?: string;
  withStrengthBar?: boolean;
}

export const PasswordInput = React.forwardRef(
  (props: PasswordInputProps & TextFieldProps, ref: any) => {
    const { label, withStrengthBar, value, onChange, ...rest } = props;
    const [passwordValue, setPasswordValue] = useState<any>(value ?? "");

    const onChangeHandler = (e: any) => {
      setPasswordValue(e.target.value);
      onChange && onChange(e);
    };

    return (
      <>
        <TextField
          ref={ref}
          type="password"
          value={value}
          label={label}
          onChange={onChangeHandler}
          id={rest.id ?? rest.name}
          {...rest}
        />
        {withStrengthBar && passwordValue?.length > 0 ? (
          <PasswordStrengthBar password={passwordValue} minLength={8} />
        ) : null}
      </>
    );
  }
);

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ReorderComparablePropertiesItem
 */
export interface ReorderComparablePropertiesItem {
    /**
     * 
     * @type {string}
     * @memberof ReorderComparablePropertiesItem
     */
    mlsId: string;
    /**
     * 
     * @type {string}
     * @memberof ReorderComparablePropertiesItem
     */
    mlsSource: string;
}

export function ReorderComparablePropertiesItemFromJSON(json: any): ReorderComparablePropertiesItem {
    return ReorderComparablePropertiesItemFromJSONTyped(json, false);
}

export function ReorderComparablePropertiesItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReorderComparablePropertiesItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mlsId': json['mlsId'],
        'mlsSource': json['mlsSource'],
    };
}

export function ReorderComparablePropertiesItemToJSON(value?: ReorderComparablePropertiesItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
    };
}



import * as React from "react";

interface Props {
  elementRef: React.MutableRefObject<HTMLElement | null>;
  fetchNextPage: () => void;
  hasNextPage: boolean;
  scrollOffset?: number;
}

export const useInfiniteScroll = (props: Props) => {
  const { elementRef, scrollOffset = 0, fetchNextPage, hasNextPage = true } = props;

  const handleIsBottom = React.useCallback(() => {
    if (!elementRef.current) return false;
    return elementRef.current.getBoundingClientRect().bottom <= window.innerHeight + scrollOffset;
  }, [scrollOffset, elementRef]);

  React.useEffect(() => {
    const trackScrolling = () => {
      if (hasNextPage && handleIsBottom()) {
        fetchNextPage();
      }
    };
    document.addEventListener("scroll", trackScrolling);
    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, [fetchNextPage, handleIsBottom, hasNextPage]);
};

export function formatToCurrency(
  value: number | undefined,
  options?: Intl.NumberFormatOptions | undefined
) {
  const {
    style = "currency",
    currency = "USD",
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
  } = options || {};
  if (value == null) {
    return "-";
  }
  return value.toLocaleString("en-US", {
    style,
    currency,
    maximumFractionDigits,
    minimumFractionDigits,
  });
}

export function formatText(value: string | number | undefined | null) {
  return (value ?? "-").toString();
}

export function formatToSqft(
  value: number | undefined,
  options?: Intl.NumberFormatOptions | undefined
) {
  const { style = "decimal", maximumFractionDigits = 0, minimumFractionDigits = 0 } = options || {};
  return value != null
    ? `${value.toLocaleString("en-US", {
        style,
        maximumFractionDigits,
        minimumFractionDigits,
      })} Sq. ft.`
    : "-";
}

export function formatYesNo(value: boolean | undefined) {
  return value != null ? (value ? "Yes" : "No") : "-";
}

import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { TeamProfileSectionConfig } from "./team-profile-section-config";
import { TeamProfileSectionView } from "./team-profile-section-view";
import { v4 as uuidv4 } from "uuid";

export class TeamProfileSectionWidget
  implements SectionWidget<"team-profile", TeamProfileSectionConfig, TeamProfileSectionView>
{
  type: "team-profile" = "team-profile";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  buildDefaultConfig(): TeamProfileSectionConfig {
    return {
      type: "team-profile",
      navTitle: "Team Profile",
      title: "The Team",
      id: uuidv4(),
    };
  }

  buildView({
    sectionConfig: config,
  }: SectionViewBuilderContext<TeamProfileSectionConfig, null>): TeamProfileSectionView {
    if (config.type !== "team-profile") throw new Error("Unexpected section type.");
    const {
      id,
      type,
      navTitle,
      title,
      name,
      slug,
      logo,
      website,
      phoneNumber,
      licenseNumber,
      description,
    } = config;

    const visibility = !slug ? false : config.visibility;

    const view: TeamProfileSectionView = {
      id,
      type,
      navTitle,
      title,
      name,
      slug,
      logo,
      website,
      visibility,
      phoneNumber,
      licenseNumber,
      description,
    };

    return view;
  }
}

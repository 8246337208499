import {
  PresentationTemplatesApi,
  CMAPresentationApi,
  HTTPHeaders,
  Configuration,
  ResponseContext,
} from "./generated";
import { authStorageService } from "./../../contexts/AuthContext/auth-storage-service";
import { toast } from "@avenue-8/ui-2";

function getHeaders(): HTTPHeaders {
  const headers: HTTPHeaders = {};
  const token = localStorage.getItem("authToken");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return headers;
}

function getConfig() {
  return new Configuration({
    basePath: `${process.env.REACT_APP_CMA_API_URL}`,
    headers: getHeaders(),
  });
}

function getPostMiddleware() {
  return async (context: ResponseContext) => {
    const { response, init } = context;
    const headers = init.headers as Record<string, string>;
    if (response.status === 401 && headers.Authorization) {
      authStorageService.clear();
      toast.error("Your session has expired. Please login again.", { shouldDeduplicate: true });
    }
    return response;
  };
}

export const getPresentationTemplatesApiClient: () => PresentationTemplatesApi = () => {
  return new PresentationTemplatesApi(getConfig()).withPostMiddleware(getPostMiddleware());
};

export const getCMAPresentationApi: () => CMAPresentationApi = () => {
  return new CMAPresentationApi(getConfig()).withPostMiddleware(getPostMiddleware());
};

import { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { appEventEmitter, Login } from "../../events/app-event-emitter";
import { useHeap } from "./services/heap/useHeap";

/**
 * A hook that add all Heap integrations.
 * Should be used on a top level component
 */
export const useHeapIntegration = () => {
  useRouteTracking();
  useHeapEventTracking();
};

/**
 * Listen to path changes and send them to Heap
 */
const useRouteTracking = () => {
  const heapTracker = useHeap();
  const history = useHistory();

  //listen to page route changes
  useEffect(() => {
    const unregister = history.listen((_location, action) => {
      heapTracker.track("page-route-changed", { location: window.location.toString(), action });
    });
    return () => {
      unregister();
    };
  }, [history, heapTracker]);
};

/**
 * Listen to app events and forward them to Heap
 */
const useHeapEventTracking = () => {
  const heapTracker = useHeap();

  const heapListener = useCallback(
    (data: any, eventType: string) => {
      if (eventType === "login") {
        const { userId, email } = data as Login;
        heapTracker.identify({ userId, email });
      }
      setTimeout(() => {
        if(eventType.startsWith('cma-') && data.presentationType){
          if(data.presentationType === "cma"){
            delete data.presentationType;
            heapTracker.track(eventType, data);
            return;
          }
          if(data.presentationType === "general"){
            const presentationEvent = eventType.replace('cma-', 'general-');
            delete data.presentationType;
            heapTracker.track(presentationEvent, data);
            return;
          }
        }
        if(eventType.startsWith('presentation-') && data.presentationType){
          if(data.presentationType === "cma"){
            delete data.presentationType;
            heapTracker.track(eventType, data);
            return;
          }
          if(data.presentationType === "general"){
            const presentationEvent = eventType.replace('presentation-', 'general-presentation-');
            delete data.presentationType;
            heapTracker.track(presentationEvent, data);
            return;
          }
        }
        heapTracker.track(eventType, data);
      }, 50);
    },
    [heapTracker]
  );

  useEffect(() => {
    appEventEmitter.addGlobalListener(heapListener);
    return () => {
      appEventEmitter.removeGlobalListener(heapListener);
    };
  }, [heapTracker, heapListener]);
};

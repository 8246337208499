import { Link } from "react-router-dom";
import { styled } from "@avenue-8/ui-2";
import { cmaRoutes } from "src/modules/cma-v2/cma.routes";

const LogoContainer = styled(Link)`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: ${(p) => p.theme.av8.primaryColor};
  padding: 0.5rem;
  font-family: ${(p) => p.theme.av8.typography.headers.fontFamily};
`;

export function Logo() {
  return (
    <LogoContainer to={cmaRoutes.dashboard.route} data-testid="logo">
      MarketComp
    </LogoContainer>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRangeFilter,
    DateRangeFilterFromJSON,
    DateRangeFilterFromJSONTyped,
    DateRangeFilterToJSON,
    OrderByParams,
    OrderByParamsFromJSON,
    OrderByParamsFromJSONTyped,
    OrderByParamsToJSON,
    PointParams,
    PointParamsFromJSON,
    PointParamsFromJSONTyped,
    PointParamsToJSON,
    RadiusParams,
    RadiusParamsFromJSON,
    RadiusParamsFromJSONTyped,
    RadiusParamsToJSON,
    RangeFilterParams,
    RangeFilterParamsFromJSON,
    RangeFilterParamsFromJSONTyped,
    RangeFilterParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface SearchV2Dto
 */
export interface SearchV2Dto {
    /**
     * 
     * @type {string}
     * @memberof SearchV2Dto
     */
    search?: string;
    /**
     * 
     * @type {number}
     * @memberof SearchV2Dto
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchV2Dto
     */
    limit?: number;
    /**
     * 
     * @type {RadiusParams}
     * @memberof SearchV2Dto
     */
    radius?: RadiusParams;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    price?: RangeFilterParams;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchV2Dto
     */
    propertyStatus?: Array<number>;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    numBedrooms?: RangeFilterParams;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    numBathrooms?: RangeFilterParams;
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchV2Dto
     */
    propertyTypes?: Array<number>;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    livingSpace?: RangeFilterParams;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    lotSize?: RangeFilterParams;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    parkingGarageSpaces?: RangeFilterParams;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    hoaMonthlyFee?: RangeFilterParams;
    /**
     * 
     * @type {Array<PointParams>}
     * @memberof SearchV2Dto
     */
    polygonPoints?: Array<PointParams>;
    /**
     * 
     * @type {DateRangeFilter}
     * @memberof SearchV2Dto
     */
    soldDate?: DateRangeFilter;
    /**
     * 
     * @type {DateRangeFilter}
     * @memberof SearchV2Dto
     */
    listDate?: DateRangeFilter;
    /**
     * 
     * @type {RangeFilterParams}
     * @memberof SearchV2Dto
     */
    yearBuilt?: RangeFilterParams;
    /**
     * 
     * @type {string}
     * @memberof SearchV2Dto
     */
    neighborhood?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV2Dto
     */
    area?: string;
    /**
     * 
     * @type {OrderByParams}
     * @memberof SearchV2Dto
     */
    orderBy?: OrderByParams;
    /**
     * 
     * @type {boolean}
     * @memberof SearchV2Dto
     */
    removeNulls?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchV2Dto
     */
    hasDoormanAttendance?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SearchV2Dto
     */
    isLease?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SearchV2Dto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV2Dto
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchV2Dto
     */
    county?: string;
}

export function SearchV2DtoFromJSON(json: any): SearchV2Dto {
    return SearchV2DtoFromJSONTyped(json, false);
}

export function SearchV2DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchV2Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'search': !exists(json, 'search') ? undefined : json['search'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'limit': !exists(json, 'limit') ? undefined : json['limit'],
        'radius': !exists(json, 'radius') ? undefined : RadiusParamsFromJSON(json['radius']),
        'price': !exists(json, 'price') ? undefined : RangeFilterParamsFromJSON(json['price']),
        'propertyStatus': !exists(json, 'propertyStatus') ? undefined : json['propertyStatus'],
        'numBedrooms': !exists(json, 'numBedrooms') ? undefined : RangeFilterParamsFromJSON(json['numBedrooms']),
        'numBathrooms': !exists(json, 'numBathrooms') ? undefined : RangeFilterParamsFromJSON(json['numBathrooms']),
        'propertyTypes': !exists(json, 'propertyTypes') ? undefined : json['propertyTypes'],
        'livingSpace': !exists(json, 'livingSpace') ? undefined : RangeFilterParamsFromJSON(json['livingSpace']),
        'lotSize': !exists(json, 'lotSize') ? undefined : RangeFilterParamsFromJSON(json['lotSize']),
        'parkingGarageSpaces': !exists(json, 'parkingGarageSpaces') ? undefined : RangeFilterParamsFromJSON(json['parkingGarageSpaces']),
        'hoaMonthlyFee': !exists(json, 'hoaMonthlyFee') ? undefined : RangeFilterParamsFromJSON(json['hoaMonthlyFee']),
        'polygonPoints': !exists(json, 'polygonPoints') ? undefined : ((json['polygonPoints'] as Array<any>).map(PointParamsFromJSON)),
        'soldDate': !exists(json, 'soldDate') ? undefined : DateRangeFilterFromJSON(json['soldDate']),
        'listDate': !exists(json, 'listDate') ? undefined : DateRangeFilterFromJSON(json['listDate']),
        'yearBuilt': !exists(json, 'yearBuilt') ? undefined : RangeFilterParamsFromJSON(json['yearBuilt']),
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'orderBy': !exists(json, 'orderBy') ? undefined : OrderByParamsFromJSON(json['orderBy']),
        'removeNulls': !exists(json, 'removeNulls') ? undefined : json['removeNulls'],
        'hasDoormanAttendance': !exists(json, 'hasDoormanAttendance') ? undefined : json['hasDoormanAttendance'],
        'isLease': !exists(json, 'isLease') ? undefined : json['isLease'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'county': !exists(json, 'county') ? undefined : json['county'],
    };
}

export function SearchV2DtoToJSON(value?: SearchV2Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'search': value.search,
        'page': value.page,
        'limit': value.limit,
        'radius': RadiusParamsToJSON(value.radius),
        'price': RangeFilterParamsToJSON(value.price),
        'propertyStatus': value.propertyStatus,
        'numBedrooms': RangeFilterParamsToJSON(value.numBedrooms),
        'numBathrooms': RangeFilterParamsToJSON(value.numBathrooms),
        'propertyTypes': value.propertyTypes,
        'livingSpace': RangeFilterParamsToJSON(value.livingSpace),
        'lotSize': RangeFilterParamsToJSON(value.lotSize),
        'parkingGarageSpaces': RangeFilterParamsToJSON(value.parkingGarageSpaces),
        'hoaMonthlyFee': RangeFilterParamsToJSON(value.hoaMonthlyFee),
        'polygonPoints': value.polygonPoints === undefined ? undefined : ((value.polygonPoints as Array<any>).map(PointParamsToJSON)),
        'soldDate': DateRangeFilterToJSON(value.soldDate),
        'listDate': DateRangeFilterToJSON(value.listDate),
        'yearBuilt': RangeFilterParamsToJSON(value.yearBuilt),
        'neighborhood': value.neighborhood,
        'area': value.area,
        'orderBy': OrderByParamsToJSON(value.orderBy),
        'removeNulls': value.removeNulls,
        'hasDoormanAttendance': value.hasDoormanAttendance,
        'isLease': value.isLease,
        'city': value.city,
        'state': value.state,
        'county': value.county,
    };
}



import React from "react";
import { Grid, CropImageModal, styled } from "@avenue-8/ui-2";
import { PhotosUploader } from "../../../../cma-v2/components/PhotosUploader";
import { HeadshotPreview } from "../UploadHeadshotStep/HeadshotPreview";
import { MutationStatus } from "react-query";

interface UploadHeadshotFormProps {
  userFullName?: string;
  avatarUrl?: string;
  uploadAgentHeadshotPhoto: (data: FormData) => void;
  status: MutationStatus;
}

const ChangePhotoButton = styled.div`
  text-transform: capitalize;
  text-decoration: underline;
  letter-spacing: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  text-align: center;
  display: inline-block;
`;

const ButtonContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 16px;
`;

export const formDataToUrl = (
  photoFormData: FormData,
  onLoadEndEvent: (result: string | ArrayBuffer | null) => void
) => {
  const imageFile = photoFormData.get("image");
  const fileReader = new FileReader();

  fileReader.onloadend = () => onLoadEndEvent(fileReader.result);

  fileReader.readAsDataURL(imageFile as Blob);
};

export const UploadHeadshotForm = (props: UploadHeadshotFormProps) => {
  const [originalHeadshotImage, setOriginalHeashotImage] = React.useState<string | null>(null);
  const [tempHeadshotImage, setTempHeadshotImage] = React.useState<string | null>(null);

  const handleUploadFiles = (photosUploderFormData: FormData) => {
    formDataToUrl(photosUploderFormData, (result) => {
      setOriginalHeashotImage(result as string);
      setTempHeadshotImage(result as string);
    });
  };

  const uploadHeadshotForm = (formData: FormData) => {
    props.uploadAgentHeadshotPhoto(formData);
  };

  const initials =
    props.userFullName
      ?.split(" ")
      ?.map((x) => x[0] ?? "")
      ?.join("") ?? "";

  return (
    <>
      <PhotosUploader
        isUploading={false}
        onUploadFiles={handleUploadFiles}
        inputName="image"
        description="Drag + drop your headshot here or click to browse files below."
        content={
          <Grid container item md={12} justifyContent="center">
            <HeadshotPreview
              image={props.avatarUrl}
              openCropHeadshotModal={originalHeadshotImage ? setTempHeadshotImage : null}
              uploadStatus={props.status}
              size={152}
              initials={initials}
            />
            <ButtonContainer>
              <ChangePhotoButton role="button">Change Photo</ChangePhotoButton>
            </ButtonContainer>
          </Grid>
        }
        errorMessage={props.status === "error" ? "Failed to upload your headshot." : null}
        hideThumbnailsPreview={true}
      />

      <CropImageModal
        title="Crop your headshot"
        open={Boolean(tempHeadshotImage)}
        image={originalHeadshotImage}
        onCropImage={uploadHeadshotForm}
        onClose={() => setTempHeadshotImage(null)}
        inputName="image"
      />
    </>
  );
};

import React from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Button, ButtonGroup, Grow, Paper, Popper, MenuList, styled } from "@avenue-8/ui-2";

const FloatingPoper = styled(Popper)`
  z-index: 2;
`;

interface Props {
  mainOutlinedButton: any;
  children: any;
}

export const SplitButton = ({ children, mainOutlinedButton }: Props) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup variant="outlined" color="primary" ref={anchorRef} aria-label="split button">
        {mainOutlinedButton}
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <FloatingPoper
        open={open}
        anchorEl={anchorRef.current}
        onClick={() => {
          setOpen(false);
        }}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "right top" : "right bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={() => handleClose}>
                <MenuList id="split-button-menu">{children}</MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </FloatingPoper>
    </>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GetOnboardingResponse,
    GetOnboardingResponseFromJSON,
    GetOnboardingResponseToJSON,
    SaveOnboardingRequest,
    SaveOnboardingRequestFromJSON,
    SaveOnboardingRequestToJSON,
} from '../models';

export interface OnboardingControllerSaveOnboardingRequest {
    saveOnboardingRequest: SaveOnboardingRequest;
}

/**
 * 
 */
export class OnboardingApi extends runtime.BaseAPI {

    /**
     * Get logged user onboarding data.
     */
    async onboardingControllerGetOnboardingRaw(): Promise<runtime.ApiResponse<GetOnboardingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnboardingResponseFromJSON(jsonValue));
    }

    /**
     * Get logged user onboarding data.
     */
    async onboardingControllerGetOnboarding(): Promise<GetOnboardingResponse> {
        const response = await this.onboardingControllerGetOnboardingRaw();
        return await response.value();
    }

    /**
     * Save logged user onboarding data.
     */
    async onboardingControllerSaveOnboardingRaw(requestParameters: OnboardingControllerSaveOnboardingRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.saveOnboardingRequest === null || requestParameters.saveOnboardingRequest === undefined) {
            throw new runtime.RequiredError('saveOnboardingRequest','Required parameter requestParameters.saveOnboardingRequest was null or undefined when calling onboardingControllerSaveOnboarding.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/onboarding`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SaveOnboardingRequestToJSON(requestParameters.saveOnboardingRequest),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Save logged user onboarding data.
     */
    async onboardingControllerSaveOnboarding(requestParameters: OnboardingControllerSaveOnboardingRequest): Promise<boolean> {
        const response = await this.onboardingControllerSaveOnboardingRaw(requestParameters);
        return await response.value();
    }

}

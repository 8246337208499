import { Button, styled } from "@avenue-8/ui-2";

export const CustomOverlineButton = styled(Button)`
  min-width: unset;
  color: ${(p) => p.theme.av8.typography.secondaryColor};
  line-height: 0.7rem;
  font-size: 0.7rem;
  & > svg {
    margin-right: 4px;
  }
  padding: 0;
`;

import * as React from "react";
import { styled, Grid, Typography, InputLabel, Loader } from "@avenue-8/ui-2";
import { EstimatePriceForm, EstimatePriceFormModel } from "./components/EstimatePriceForm";
import { FormProvider, useForm } from "react-hook-form";
import SubjectPropertySummary from "./components/SubjectPropertySummary";
import { useCreateCMALogic } from "../../../../hooks/useCreateCMALogic";
import { CustomPrompt, PromptAction } from "../../../CustomPrompt";
import { convertEstimatePriceFormModelToEstimatedPriceDto } from "./estimate-price";
import { SummaryStatistics } from "../../../SummaryStatistics";
import { toast } from "react-toastify";
import { handleSavableStepNavigation, useCMARouteStepHelper } from "../../step-navigation-helper";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { BottomBar } from "../../CreateCMA";
import { useHistory } from "react-router";
import { BackButton, NextButton } from "../../../Button";
import { useListingSummaryFeatureToggle } from "../../../../feature-toggles";

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 1rem;
`;

const LoaderContainer = styled(Grid)`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

interface EstimateStepProps {
  cmaId?: string;
}

export const EstimateStep = ({ cmaId }: EstimateStepProps) => {
  const listingsSummaryEnabled = useListingSummaryFeatureToggle();
  const {
    state: { cma, cmaStatus, estimatePriceForm, isFetchingCma },
    actions,
  } = useCreateCMALogic();
  const history = useHistory();
  const cmaRouteStepHelper = useCMARouteStepHelper(cma?.presentationType);
  const nextStep = cmaRouteStepHelper.getNextStep();

  const { updateEstimatePriceFormDraft } = actions;
  const formMethods = useForm<EstimatePriceFormModel>({
    defaultValues: { ...estimatePriceForm },
    mode: "all",
  });
  const { getValues, trigger, reset } = formMethods;
  const subjectProperty = cma?.subjectProperty;

  React.useEffect(() => {
    reset({ ...estimatePriceForm });
  }, [reset, estimatePriceForm]);

  React.useEffect(() => appEventEmitter.emit({ eventType: "cma-set-estimated-price-loaded" }), []);

  const handleSave = async (data: EstimatePriceFormModel) => {
    const estimatePrice = convertEstimatePriceFormModelToEstimatedPriceDto(data);
    await actions.setEstimatePrice({
      ...estimatePrice,
      cmaId: cmaId!,
      eventOptions: {
        from: "estimate-price-step",
      },
    });
  };

  const discardChangesModalActions: PromptAction[] = [
    {
      label: "Discard changes",
      handler: (context) => {
        actions.discardEstimatePriceFormDraft();
        context.confirmNavigation();
      },
    },
    {
      label: "Apply",
      handler: async (context) => {
        const validForm = await formMethods.trigger();
        if (!validForm) {
          context.close();
          toast.error("Please fill in all required fields.");
          return;
        }
        const estimatePrice = convertEstimatePriceFormModelToEstimatedPriceDto(
          formMethods.getValues()
        );
        if (cmaId)
          await actions.setEstimatePrice({
            ...estimatePrice,
            cmaId,
            eventOptions: {
              from: "estimate-price-step",
            },
          });
        context.confirmNavigation();
      },
      primary: true,
    },
  ];

  const handleOnFormsValuesUpdated = React.useCallback(
    async (data: any) => {
      updateEstimatePriceFormDraft(data);
    },
    [updateEstimatePriceFormDraft]
  );

  const defaultOnBlur = React.useCallback(
    ({ controlledOnBlur }: { controlledOnBlur: () => void }) => {
      const handleBlur = () => {
        handleOnFormsValuesUpdated(getValues());
      };
      return {
        onBlur: () => {
          handleBlur();
          controlledOnBlur();
        },
      };
    },
    [getValues, handleOnFormsValuesUpdated]
  );

  return (
    <Grid container>
      <TitleHeaderGrid container alignItems={"center"}>
        <Grid item>
          <Typography variant="h5">Estimate</Typography>
        </Grid>
      </TitleHeaderGrid>
      {cmaStatus === "loading" && (
        <LoaderContainer container item>
          <Loader />
        </LoaderContainer>
      )}
      {cmaStatus === "success" && (
        <Grid container item spacing={4}>
          <Grid item md={7}>
            <InputLabel htmlFor="subject-property">Subject Property</InputLabel>
            {!!subjectProperty && <SubjectPropertySummary property={subjectProperty} />}
          </Grid>

          <Grid item md={5}>
            <FormProvider {...formMethods}>
              <EstimatePriceForm
                onSave={handleSave}
                defaultOnBlur={defaultOnBlur}
                disabled={isFetchingCma}
              />
            </FormProvider>
          </Grid>

          {listingsSummaryEnabled && (
            <Grid item md={12}>
              <SummaryStatistics />
            </Grid>
          )}
        </Grid>
      )}
      {!!nextStep?.label && (
        <BottomBar>
          <BackButton onClick={() => cmaRouteStepHelper.handleBackClick(history)}>Back</BackButton>
          <NextButton type="submit" onClick={() => cmaRouteStepHelper.handleForwardClick(history)}>
            Next: {nextStep.label}
          </NextButton>
        </BottomBar>
      )}
      <CustomPrompt
        when={true}
        actions={discardChangesModalActions}
        onClose={(context) => context.close()}
        onPrompting={async (location) => {
          const hasChanges = !!actions.getEstimatePriceFormDraft();
          const validForm = await trigger();

          return handleSavableStepNavigation({
            action: cmaRouteStepHelper.getPathDirection(location.pathname) ?? "leaveFlow",
            hasChanges,
            save: async () => {
              await handleSave(getValues());
            },
            validForm,
          });
        }}
        title="Apply changes?"
        message={
          cma?.canPublish
            ? "You have made changes to this CMA that have not been published to your presentation. Would you like to apply these changes? These will be visible to your client."
            : "You have made changes to this CMA that have not been saved. Would you like to apply these changes? These changes will only be visible to your client after completing the CMA."
        }
      />
    </Grid>
  );
};

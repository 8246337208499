declare global {
  interface Window {
    StyleMedia: any;
    chrome: any;
  }
  interface Document {
    documentMode?: any;
  }
}

const Browser = {
  // Firefox 1.0+
  isFirefox: () => {
    return "InstallTrigger" in window;
  },
  // Internet Explorer 6-11
  isIE: () => {
    return navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode;
  },
  // Edge 20+
  isEdge: () => {
    return !Browser.isIE() && !!window.StyleMedia;
  },
  // Chrome 1+
  isChrome: (context = window) => {
    return !!context.chrome;
  },
  // At least Safari 3+: "[object HTMLElementConstructor]"
  isSafari: () => {
    return (
      Object.prototype.toString.call(window.HTMLElement).indexOf("Constructor") > 0 ||
      navigator.userAgent.toLowerCase().indexOf("safari") !== -1
    );
  },
  // IOS Chrome
  isIOSChrome: () => {
    return navigator.userAgent.toLowerCase().indexOf("crios") !== -1;
  },
};

export default Browser;

import { TextField, TextFieldProps } from "@avenue-8/ui-2";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";

const TextFieldBase = (props: TextFieldProps & { errors?: DeepMap<FieldValues, FieldError> }) => {
  const { name, errors } = props;
  const error = {
    error: name && errors ? !!errors[name] : false,
    helperText: name && errors ? errors[name]?.message : undefined,
  };
  return <TextField type="text" fullWidth={true} id={props.id ?? name} {...props} {...error} />;
};

export default TextFieldBase;

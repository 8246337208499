import * as React from "react";
import { ConstrainedContainer } from "../ConstrainedContainer";
import { AvoidPrintBreakInside } from "../AvoidPrintBreakInside";
import { Subtitle1, Title1 } from "../typography";
import { ResponsiveImage } from "src/modules/shared/components/ResponsiveImage";
import { GeneralHeaderSectionView } from "src/modules/presentation/presentation-generation-logic/sections/general-header/general-header-section-view";
import { MainImageFrame, OuterContainer } from "./HeroHeader.styles";

export const GeneralHeroHeader = (
  props: GeneralHeaderSectionView & { displayMode: "desktop" | "mobile" | "print" | "tablet" }
) => {
  const { clientName, propertyPhoto, displayMode, id, preferences, presentationName } = props;

  const preparedFor = (preferences == null || preferences?.showClientName === true) && (
    <Subtitle1 marginTop={2} marginBottom={2} data-test-id="prepared-for">
      Prepared For {clientName}
    </Subtitle1>
  );
  const presentationTitle = (preferences == null ||
    preferences?.showPresentationTitle === true) && (
    <Title1 marginBottom={2.5} data-testid="presentation-title">
      {presentationName}
    </Title1>
  );

  return (
    <OuterContainer
      $noPaddingTop={
        preferences?.showPresentationTitle === false && preferences?.showClientName === false
      }
      id={id}
    >
      <AvoidPrintBreakInside>
        <ConstrainedContainer>
          {preparedFor}
          {presentationTitle}
        </ConstrainedContainer>
        <MainImageFrame>
          <ResponsiveImage
            alt="Property view"
            src={propertyPhoto ?? ""}
            size={displayMode === "print" ? "lg" : undefined}
          />
        </MainImageFrame>
      </AvoidPrintBreakInside>
    </OuterContainer>
  );
};

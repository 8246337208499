import * as React from "react";
import { makeBLContext } from "src/modules/shared/hooks/makeBLContext";

function useGuidedTourLogicInner() {
  const [dontShowAgain, setDontShowAgain] = React.useState(false);

  return {
    state: {
      dontShowAgain,
    },
    actions: {
      setDontShowAgain,
    },
  };
}

export const {
  LogicContextProvider: GuidedTourLogicProvider,
  useLogicContext: useGuidedTourLogic,
} = makeBLContext({ useLogic: useGuidedTourLogicInner });

import * as React from "react";
import { Grid, Typography, styled } from "@avenue-8/ui-2";
import { AgentOnboardingStepBaseProps } from "../../AgentOnboarding";

const Container = styled(Grid)`
  position: relative;
  width: 100%;
  padding: 1.5rem 0 4.5rem;
  gap: 1.5rem;
`;

interface FinishStepProps extends AgentOnboardingStepBaseProps {
  onSave: () => void;
}

export const FinishStep = ({ formId, onSave }: FinishStepProps) => {
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave();
  };

  return (
    <Container container>
      <Grid item>
        <Typography variant="h2">You're all set.</Typography>
      </Grid>
      <Grid item md={8}>
        <Typography variant="body2">We’ve finished setting up your account.</Typography>
        <Typography variant="body2">
          Head to the dashboard to begin creating your first CMA with us.
        </Typography>
      </Grid>
      <form id={formId} onSubmit={handleSubmit} />
    </Container>
  );
};

import { RecurringCost } from "../models/comparable-property";

export const calculateMonthlies = (recurringCosts: RecurringCost[]): number => {
  return recurringCosts.reduce((acc, curr) => {
    const value = curr.value != null ? Number(curr.value) : 0;
    if (curr.frequency === "yearly") {
      return acc + value / 12;
    }
    return acc + value;
  }, 0);
};

import { format } from "date-fns";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { Grid, CheckboxIcon, ImageUnavailable, useTheme } from "@avenue-8/ui-2";
import { buildPropertyKey } from "../../../../../../../shared/utils/buildPropertyKey";
import {
  PropertyCardContainer,
  PropertyImageColumn,
  PropertyImage,
  PropertyResultCardInnerContainer,
  PropertyDataColumn,
  PropertyResultItem,
  Address1Value,
  PropertyItemValue,
  PropertyBathsAndBedsValue,
  PropertyColumn,
  PropertyItemLabel,
  PropertyToggleContainer,
  PropertyCheckbox,
  PropertyListinStatus,
} from "./ComparablePropertyResultItem.styles";

interface Props {
  property: PropertyDto;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  onClickProperty: () => void;
  onSelectProperty: (action: string) => void;
  isCompsListingMutating: (mlsId: string) => boolean;
  isChecked?: boolean;
}

export const ComparablePropertyResultItem = (props: Props) => {
  const {
    property,
    onMouseEnter,
    onMouseLeave,
    onSelectProperty,
    onClickProperty,
    isCompsListingMutating,
    isChecked,
  } = props;
  const theme = useTheme();
  const coverImage = property.images[0];
  return (
    <PropertyCardContainer
      container
      onMouseEnter={onMouseEnter}
      onClick={() => onClickProperty()}
      onMouseLeave={onMouseLeave}
      data-testid="search-result-item"
    >
      <PropertyImageColumn item md={3} data-testid="search-result-item-image">
        <PropertyImage>
          {coverImage ? (
            <img alt={coverImage?.caption} src={coverImage.url} />
          ) : (
            <ImageUnavailable />
          )}
        </PropertyImage>
      </PropertyImageColumn>

      <Grid item md={9}>
        <PropertyResultCardInnerContainer container columnSpacing={1}>
          <PropertyDataColumn item md={4}>
            <PropertyResultItem>
              <Address1Value
                variant="body2"
                color="primary"
                data-testid="search-result-item-address"
              >
                {[
                  property?.addressLine1 ?? "-",
                  property?.addressLine2 && `, Unit ${property?.addressLine2}`,
                ]
                  .join(" ")
                  .trim()}
              </Address1Value>
              <PropertyItemValue
                variant="body2"
                color="primary"
                data-testid="search-result-item-city-state"
              >
                {[
                  property?.city ? `${property.city + ", " + property?.state}` : "-",
                  property?.zipCode,
                ]
                  .join(" ")
                  .trim()}
              </PropertyItemValue>
            </PropertyResultItem>

            <PropertyResultItem $flex>
              <PropertyItemValue
                variant="body2"
                color="primary"
                data-testid="search-result-item-sold-price"
              >
                {property.soldPrice
                  ? `$${property.soldPrice.toLocaleString("en-US")}`
                  : property.listPrice
                  ? `$${property.listPrice.toLocaleString("en-US")}`
                  : "-"}
              </PropertyItemValue>

              <PropertyBathsAndBedsValue
                variant="body2"
                data-testid="search-result-item-baths-beds"
              >
                {property.beds && `${property.beds}BD`}
                {property.beds && property.baths && " | "}
                {property.baths && `${property.baths}BA`}
                {!property.beds && !property.baths && "-"}
              </PropertyBathsAndBedsValue>
            </PropertyResultItem>
          </PropertyDataColumn>

          <PropertyColumn item md={3}>
            <PropertyResultItem>
              <PropertyItemLabel variant="body2">Property Type</PropertyItemLabel>
              <PropertyItemValue
                variant="body2"
                color="primary"
                data-testid="search-result-item-property-type"
              >
                {property.propertyType ?? "-"}
              </PropertyItemValue>
            </PropertyResultItem>

            <PropertyResultItem>
              <PropertyItemLabel variant="body2">Neighborhood</PropertyItemLabel>
              <PropertyItemValue
                variant="body2"
                color="primary"
                data-testid="search-result-item-neighborhood"
              >
                {property.neighborhood ?? "-"}
              </PropertyItemValue>
            </PropertyResultItem>
          </PropertyColumn>

          <PropertyColumn item md={2}>
            <PropertyResultItem>
              <PropertyItemLabel variant="body2">Sq. ft.</PropertyItemLabel>
              <PropertyItemValue
                variant="body2"
                color="primary"
                data-testid="search-result-item-living-space"
              >
                {property.livingSpace
                  ? `${property.livingSpace.toLocaleString("en-US")} Sq. Ft.`
                  : "-"}
              </PropertyItemValue>
            </PropertyResultItem>
            <PropertyResultItem>
              <PropertyItemLabel variant="body2">Year Built</PropertyItemLabel>
              <PropertyItemValue
                variant="body2"
                color="primary"
                data-testid="search-result-item-year"
              >
                {property.yearBuilt ? property.yearBuilt : "-"}
              </PropertyItemValue>
            </PropertyResultItem>
          </PropertyColumn>

          <Grid item md={3}>
            <PropertyToggleContainer>
              <PropertyCheckbox
                disabled={isCompsListingMutating(buildPropertyKey(property) || "")}
                onChange={() => (isChecked ? onSelectProperty("REMOVE") : onSelectProperty("ADD"))}
                onClick={(e) => e.stopPropagation()}
                checked={isChecked}
                checkedIcon={<CheckboxIcon color={theme.av8.primaryColor} />}
                icon={<CheckboxIcon color="transparent" />}
                data-testid="search-result-item-checkbox"
              />
              <div data-testid="search-result-item-listing-status">
                {property.listingStatus === "Sold" ? (
                  <>
                    <PropertyListinStatus variant="body2" $listingStatus={property.listingStatus}>
                      {property.listingStatus ?? "-"}
                    </PropertyListinStatus>
                    <PropertyListinStatus variant="body2" $listingStatus={property.listingStatus}>
                      {property.soldDate ? format(property.soldDate, "MM/dd/yyyy") : "-"}
                    </PropertyListinStatus>
                  </>
                ) : (
                  <PropertyListinStatus variant="body2" $listingStatus={property.listingStatus}>
                    {property.listingStatus ?? "-"}
                  </PropertyListinStatus>
                )}
              </div>
            </PropertyToggleContainer>
          </Grid>
        </PropertyResultCardInnerContainer>
      </Grid>
    </PropertyCardContainer>
  );
};

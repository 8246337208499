import { rest } from "msw";
import {
  CMAControllerListRequest,
  ListCMAResponseDto,
  ListCMAResponseItemDto,
} from "src/modules/shared/apis/cma/generated";
import { dataFactory } from "../dataFactory";

export const cmaListHandlers = [
  rest.get<CMAControllerListRequest, any, ListCMAResponseDto>(
    `${process.env.REACT_APP_CMA_API_URL}/cmas`,
    (req, res, ctx) => {
      const page = Number(req.url.searchParams.get("page"));
      const numberOfItems = page < 2 ? 7 : 8;
      const items = Array(numberOfItems ?? 1)
        .fill(null)
        .map((_) => {
          const cma = dataFactory.getRandomCmaDto();
          return {
            addressLine1: cma.subjectProperty.addressLine1,
            addressLine2: cma.subjectProperty.addressLine2,
            baths: cma.subjectProperty.baths,
            beds: cma.subjectProperty.beds,
            city: cma.subjectProperty.city,
            id: cma.id,
            state: cma.subjectProperty.state,
            status: cma.subjectProperty.listingStatus,
            zipCode: cma.subjectProperty.zipCode,
            updatedDate: cma.updateDate,
            coverImage: cma.coverPhotoCdnUrl,
          } as ListCMAResponseItemDto;
        });

      return res(
        ctx.status(200),
        ctx.json({
          items,
          meta: {
            currentPage: page,
            itemCount: numberOfItems,
            itemsPerPage: numberOfItems,
            totalItems: numberOfItems * 2,
            totalPages: 2,
          },
        })
      );
    }
  ),
];

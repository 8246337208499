import * as React from "react";
import { Route, RouteComponentProps, Switch } from "react-router";
import { Link } from "react-router-dom";
import { useBodyStyle } from "../shared/hooks/useBodyStyle";
import { SmartPresentationView as SmartPresentationViewV2 } from "../cma-v2/components/PresentationView/SmartPresentationView";
import { ThemeProvider } from "@avenue-8/ui-2";
import { Role, RouteModel } from "../../routes/routes";
import GenerateRoutes from "../../routes/GenerateRoutes";
import { useAuthContext } from "../shared/contexts/AuthContext/AuthContext";

const renderViewPresentationV2 = ({ match }: RouteComponentProps<any>) => {
  return (
    <ThemeProvider darkMode={false}>
      <SmartPresentationViewV2 presentationId={match.params.id} />
    </ThemeProvider>
  );
};

const baseRoute = "/presentation";

export const PresentationModule = () => {
  const {
    state: { user },
  } = useAuthContext();

  useBodyStyle({ background: "#fafafa" });

  const routes: RouteModel[] = React.useMemo(
    () => [
      {
        path: `${baseRoute}/:id/view`,
        render: renderViewPresentationV2,
      },
      {
        // Not being used
        path: `${baseRoute}/:id/print`,
        render: () => <p>Print Presentation</p>,
        roles: [Role.Admin, Role.Agent],
      },
      {
        // This is handled by Netlify so it's not actually a route
        path: `${baseRoute}/print/:presentationName`,
        render: (props) => <p>Print Presentation {props.match.params.presentationName}...</p>,
        roles: [Role.Admin, Role.Agent],
      },
    ],
    []
  );

  return (
    <>
      <Switch>
        <GenerateRoutes routes={routes} userRole={user?.role} />
        <Route>
          <ul>
            <li>
              <Link to={`${baseRoute}/sample/view`}>View Sample</Link>
            </li>
            <li>
              <Link to={`${baseRoute}/sample/edit`}>Edit Sample</Link>
            </li>
          </ul>
        </Route>
      </Switch>
    </>
  );
};

import * as React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Grid,
  Typography,
  Pagination,
  MenuItem,
  styled,
} from "@avenue-8/ui-2";
import { SplitButton } from "../../../shared/components/SplitButton";
import { CreateAgentAccountWithContext } from "../CreateAgentAccount/CreateAgentAccountWithContext";
import { PaginationMetaProps, useAgentAccountsContext } from "./AgentAccountsContext";
import { SpinnerBox } from "../../../shared/components/SpinnerBox";
import { LoadFailedBox } from "../../../shared/components/LoadFailedBox";
import { UserItem } from "../../../shared/apis/user/generated";
import { AgentResetPasswordModal } from "./AgentResetPasswordModal";
import { Logo } from "../../../shared/components/Logo";
import { useBreakpoint } from "../../../cma-v2/hooks/useMobile";
import { LayoutRoot, PageLayout } from "../../../cma-v2/components/PageLayout";
import { useAuthContext } from "../../../shared/contexts/AuthContext/AuthContext";
import { UserMenu } from "../../../cma-v2/components/UserMenu";

const CustomThCell = styled.th`
  display: table-cell;
  padding: 16px;
  font-size: 0.7rem;
  text-transform: uppercase;
  text-align: left;
  line-height: 1.43;
  border-bottom: 1px solid rgba(42, 41, 166, 0.35);
  vertical-align: inherit;
  max-width: 180px;
  font-weight: 400;
  letter-spacing: 2.5px;
  color: #666666;
`;

const CustomTrCell = styled.td`
  display: table-cell;
  padding: 16px;
  font-size: 0.85rem;
  text-align: left;
  line-height: 1.25rem;
  border-bottom: 1px solid rgba(42, 41, 166, 0.35);
  max-width: 180px;
  height: 40px;
  font-weight: 400;
  vertical-align: initial;
  position: relative;
  overflow-wrap: break-word;
`;

const ActionsTrCell = styled(CustomTrCell)`
  text-align: right;
`;

const StyledUserMenu = styled(UserMenu)`
  margin: 0px auto;
`;

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 2rem;
`;

const SolidLayoutRoot = styled(LayoutRoot)`
  background: ${(p) => p.theme.av8.background};
`;

export const AgentAccounts = () => {
  const { state, actions } = useAgentAccountsContext();
  const { state: authState, actions: authActions } = useAuthContext();
  const loadState = state.loadState;
  const initialLoad = React.useRef(false);
  const [isResettingPasswordForAgent, setIsResettingPasswordForAgent] =
    React.useState<UserItem | null>(null);
  const [agentModalState, setAgentModalState] = React.useState<{
    open: boolean;
    agentAccountId?: string;
  }>({
    open: false,
  });

  React.useEffect(() => {
    if (initialLoad.current === true) return;
    if (loadState === "not-loaded") {
      actions.load();
    }
    initialLoad.current = true;
  }, [actions, loadState, initialLoad]);

  function getFormattedDate(date?: Date | string) {
    const timeZoneShort = new Date()
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2];
    return date ? `${new Date(date).toLocaleString("en-US")} ${timeZoneShort}` : null;
  }

  const handlePageClick = React.useMemo(
    () => (page: number) => {
      actions.refresh({ page });
    },
    [actions]
  );

  const handleResetPassword = async (email: string) => {
    await actions.resetPasswordForAgent({ email });
    setIsResettingPasswordForAgent(null);
  };

  let list: React.ReactNode;

  if (state.loadState === "not-loaded") {
    list = <SpinnerBox />;
  } else if (state.loadState === "failed") {
    list = <LoadFailedBox />;
  } else if (state.loadState === "loaded") {
    list = (
      <>
        <TableContainer style={{ margin: "1rem 0", minHeight: "250px" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <CustomThCell>Agent Name</CustomThCell>
                <CustomThCell>Email Address</CustomThCell>
                <CustomThCell>Last Logged In</CustomThCell>
                <CustomThCell>Created By</CustomThCell>
                <CustomThCell>Created On</CustomThCell>
                <CustomThCell></CustomThCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.listResponse.items.map((user) => (
                <TableRow key={user.id}>
                  <CustomTrCell>
                    {user.firstName} {user.lastName}
                  </CustomTrCell>
                  <CustomTrCell>{user.email}</CustomTrCell>
                  <CustomTrCell>{getFormattedDate(user.lastLoginDate) ?? "-"}</CustomTrCell>
                  <CustomTrCell>
                    {user.createdByFirstName} {user.createdByLastName}
                  </CustomTrCell>
                  <CustomTrCell>{getFormattedDate(user.createdOn) ?? "-"}</CustomTrCell>
                  <ActionsTrCell>
                    <SplitButton
                      mainOutlinedButton={
                        <Button
                          onClick={() =>
                            setAgentModalState({ open: true, agentAccountId: String(user.id) })
                          }
                        >
                          Edit
                        </Button>
                      }
                      children={
                        <MenuItem onClick={() => setIsResettingPasswordForAgent(user)}>
                          Reset password
                        </MenuItem>
                      }
                    />
                  </ActionsTrCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          {...(state.listResponse.meta as PaginationMetaProps)}
          onPageClick={handlePageClick}
        />
      </>
    );
  }

  const isSmall = useBreakpoint("desktopDown");

  return (
    <PageLayout
      userMenu={
        <StyledUserMenu
          userFullName={authState.userInfo?.fullName}
          avatarUrl={authState.userInfo?.avatar}
          onLogoutClick={() => authActions.signOut()}
        />
      }
      headerContent={<></>}
      logo={<Logo />}
      contentReservedHeight={isSmall ? 96 : 192}
      headerPosition={isSmall ? "static" : "fixed"}
      LayoutRootComponent={SolidLayoutRoot}
    >
      <TitleHeaderGrid container item alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h4"> Agent Accounts</Typography>
        <Button onClick={() => setAgentModalState({ open: true })}>Create New Account</Button>
      </TitleHeaderGrid>
      {list}
      {agentModalState.open && (
        <CreateAgentAccountWithContext
          agentAccountId={agentModalState.agentAccountId}
          onClose={() => setAgentModalState({ open: false })}
          onComplete={() => actions.refresh()}
        />
      )}
      {state.loadState === "loaded" && (
        <AgentResetPasswordModal
          open={Boolean(isResettingPasswordForAgent)}
          agentEmail={isResettingPasswordForAgent?.email}
          setAgent={setIsResettingPasswordForAgent}
          onConfirm={handleResetPassword}
          state={state.sendResetPasswordEmailState}
        />
      )}
    </PageLayout>
  );
};

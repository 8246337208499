import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { HeaderSectionConfig } from "./header-section-config";
import { HeaderSectionView } from "./header-section-view";
import { getDaysOnMarket } from "../../shared/get-days-on-market";
import { v4 as uuidv4 } from "uuid";

export class HeaderSectionWidget
  implements SectionWidget<"header", HeaderSectionConfig, HeaderSectionView>
{
  type: "header" = "header";

  supportedPresentationTypes: PresentationType[] = ["cma"];

  buildDefaultConfig(): HeaderSectionConfig {
    return {
      type: "header",
      navTitle: "Header",
      id: uuidv4(),
      visibility: true,
      showDaysOnMarket: true,
      showClientName: true,
      showPresentationTitle: true,
      showPriceRange: false,
    };
  }

  buildView({
    sectionConfig: config,
    context,
  }: SectionViewBuilderContext<HeaderSectionConfig, null>): HeaderSectionView {
    if (config.type !== "header") throw new Error("Unexpected section type.");
    const { priceRange, featuredProperty, clientDisplayName, presentationName } =
      context.sourceData;
    const {
      id,
      type,
      visibility,
      showDaysOnMarket = true,
      showClientName = true,
      showPresentationTitle = true,
    } = config;
    let { showPriceRange = !(priceRange.min == null && priceRange.max == null) } = config;
    if (!context.editMode && priceRange.min == null && priceRange.max == null) {
      showPriceRange = false;
    }

    const view: HeaderSectionView = {
      id,
      presentationName,
      type,
      navTitle: "Header",
      visibility,
      addressLine1: featuredProperty.addressLine1 ?? "No address",
      addressLine2: featuredProperty.addressLine2 ?? "",
      clientName: clientDisplayName,
      propertyPhoto: featuredProperty.photos && featuredProperty.photos[0]?.url,
      maxSellPrice: priceRange.max,
      minSellPrice: priceRange.min,
      zipCode: featuredProperty.zipCode ?? "-",
      photos: featuredProperty.photos,
      daysOnMarket: getDaysOnMarket(featuredProperty.listDate, featuredProperty.soldDate),
      state: featuredProperty.state ?? "-",
      city: featuredProperty.city ?? "-",
      preferences: {
        showPriceRange,
        showDaysOnMarket,
        showClientName,
        showPresentationTitle,
      },
    };
    return view;
  }
}

import { IconButton, Typography, styled, Modal, Backdrop } from "@avenue-8/ui-2";
import { media } from "../../../../../../../shared/css-snippets";
import { breakpoints } from "../../../../../../../shared/breakpoints";

export const ScrollableModal = styled(Modal)<{ $hidden: boolean }>`
  overflow-y: scroll;
  transition: opacity 500ms ease-in-out;
  opacity: ${(p) => (p.$hidden ? "0" : "1")};
  animation: ${(p) =>
    p.$hidden ? "animate-fade-hide 500ms ease-in-out" : "animate-fade-show 500ms ease-in-out"};
`;
export const StyledBackdrop = styled(Backdrop)`
  background: ${(p) => p.theme.av8.backdropColor};
`;
export const Layout = styled.div`
  display: flex;
  justify-content: start;
  max-width: 100vw;
  min-height: 100vh;
  align-items: center;
`;
export const LeftSpace = styled.div`
  width: calc((100vw - ${() => breakpoints.desktop}px) / 2);
  ${() => media.desktopDown} {
    width: calc((100vw - ${() => breakpoints.smallDesktop}px) / 2);
  }
  ${() => media.smallDesktopDown} {
    width: 0;
  }
`;
export const Content = styled.div`
  position: relative;
  width: calc(100vw - (100vw - ${() => breakpoints.desktop}px) / 2);
  ${() => media.desktopDown} {
    width: calc(100vw - (100vw - ${() => breakpoints.smallDesktop}px) / 2);
  }
  ${() => media.smallDesktopDown} {
    width: 100vw;
  }
`;
export const CloseButton = styled(IconButton)`
  width: 35px;
  height: 35px;
  padding: 5px;
`;
export const NavButton = styled(IconButton)`
  border: 1px solid ${(p) => p.theme.av8.input.borderColor};
  background-color: ${(p) => p.theme.av8.background};
  width: 48px;
  height: 48px;

  &.prevElement {
    margin-right: 18px;
  }
`;
export const ModalTitle = styled(Typography)`
  margin-top: 10px;
  font-size: 30px;
  margin-bottom: 10px;
`;
export const ModalHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
`;
export const ModalButtonsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 98%;
`;

import * as React from "react";
import { Location } from "history";
import { useState } from "react";
import { useHistory, Prompt, useLocation } from "react-router";
import { DynamicDialog } from "./DynamicDialog";

type HandlerType = (context: {
  confirmNavigation: () => void;
  close: () => void;
}) => void | Promise<void>;

export interface PromptAction {
  label: string;
  handler?: HandlerType;
  primary?: boolean;
}

type PromptingResult = "prompt" | "navigate" | "cancel";

export const CustomPrompt = (props: {
  when: boolean;
  actions: PromptAction[];
  title?: string;
  message?: string;
  onClose?: HandlerType;
  onPrompting?: (location: Location) => Promise<PromptingResult>;
  onLoadingChanged?: (loading: boolean) => void;
}) => {
  const { actions, when, onPrompting, title, message, onClose, onLoadingChanged } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const confirmedNavigation = React.useRef(false);
  const lastLocation = React.useRef<Location | null>(null);
  const promptBehavior = React.useRef<PromptingResult>("prompt");
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    confirmedNavigation.current = false;
    lastLocation.current = null;
    promptBehavior.current = "prompt";
  }, [location]);

  const handlePrompting = React.useCallback(
    (shouldPromptResult: PromptingResult) => {
      promptBehavior.current = shouldPromptResult;
      if (!lastLocation.current) return;
      if (promptBehavior.current === "cancel") return;
      else if (promptBehavior.current === "navigate") {
        // history.push will trigger handleLocationChange again
        // but in this time it should allow to proceed to next location without prompting
        history.push(lastLocation.current.pathname);
      } else {
        setModalOpen(true);
      }
    },
    [history]
  );

  const handleLocationChanged = React.useCallback(
    (location: Location): boolean => {
      lastLocation.current = location;
      if (confirmedNavigation.current === true) return true;

      if (onPrompting != null) {
        //since we have a onPromptin callback, we should resolve it to know if the prompt is required

        //if it is already resolved as false, we can skip the prompt
        if (promptBehavior.current === "navigate") return true; //in case it was defined before

        onLoadingChanged?.(true);
        onPrompting(location)
          .then(handlePrompting, (e) => {
            console.error(e);
          })
          .finally(() => {
            onLoadingChanged?.(false);
          });
        return false;
      }
      setModalOpen(true);
      return false;
    },
    [handlePrompting, onPrompting, onLoadingChanged]
  );

  const context = {
    confirmNavigation: () => {
      confirmedNavigation.current = true;
      if (lastLocation.current) {
        history.push(lastLocation.current.pathname);
      }
    },
    close: () => {
      confirmedNavigation.current = false;
      setModalOpen(false);
    },
  };

  return (
    <>
      <Prompt when={when} message={handleLocationChanged} />
      <DynamicDialog
        open={modalOpen}
        onClose={() => onClose?.(context)}
        title={title ?? "Proceed?"}
        message={message ?? ""}
        actions={actions.map((action) => {
          return {
            label: action.label,
            primary: action.primary ?? false,
            onClick: () => {
              action.handler?.(context);
            },
          };
        })}
      />
    </>
  );
};

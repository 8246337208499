import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAppContext } from "../../../../../../AppContext";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { SectionConfig } from "../../../../../presentation/presentation-generation-logic";
import { getPresentationTemplatesApiClient } from "../../../../../shared/apis/presentation/api-factories";
import { LoadedState, usePresentationEditorLogic } from "./usePresentationEditorLogic";
import { toast } from "@avenue-8/ui-2";
import {
  ListTemplateResponseDto,
  TemplateListItemDto,
} from "../../../../../shared/apis/presentation/generated";
import { v4 as uuidv4 } from "uuid";

const CACHE_TIME_IN_MS = 1000 * 60 * 10; // 10 minutes

export function usePresentationTemplate({ templateId }: { templateId?: string | null }) {
  const { state, actions: presentationActions } = usePresentationEditorLogic();
  const { actions: appActions } = useAppContext();
  const queryClient = useQueryClient();

  const listTemplatesQuery = useQuery(
    ["templates"],
    async () =>
      await getPresentationTemplatesApiClient().templateControllerGetLoggedUserTemplates(),
    {
      cacheTime: CACHE_TIME_IN_MS,
      staleTime: CACHE_TIME_IN_MS,
      refetchOnWindowFocus: false,
    }
  );

  const getTemplateQuery = useQuery(
    ["templates", templateId],
    async () =>
      await appActions.watchPromise(
        getPresentationTemplatesApiClient().templateControllerGetTemplate({
          id: templateId || "",
        }),
        {
          blocking: true,
          message: "Loading template...",
        }
      ),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!templateId,
      onSuccess: async (template) => {
        if (
          await appActions.confirm({
            title: `Apply this template?`,
            message: "Are you sure you want to apply this template? This action cannot be undone.",
            cancelButtonText: "Cancel",
            confirmButtonText: "Apply",
          })
        ) {
          presentationActions.applyTemplate(template.data.sections as SectionConfig[]);
          return true;
        } else {
          presentationActions.setTemplateId(null);
        }
      },
    }
  );

  const deleteTemplateMutation = useMutation(
    async (template: TemplateListItemDto) => {
      if (
        await appActions.confirm({
          title: `Delete the template "${template.name}"?`,
          message: "This cannot be undone.",
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
        })
      ) {
        await getPresentationTemplatesApiClient().templateControllerDeleteTemplate({
          id: template.id,
        });
        return true;
      } else {
        return false;
      }
    },
    {
      onMutate: async () => {
        const queryKey = ["templates"];
        queryClient.setQueryData<ListTemplateResponseDto | undefined>(queryKey, (old) => {
          if (old == null) return old;
          const optimisticDeletedData: ListTemplateResponseDto = {
            ...old,
            items: old.items.filter((item) => item.id !== templateId),
          };
          return optimisticDeletedData;
        });
      },
      onSuccess: (deleted) => {
        if (deleted) {
          appEventEmitter.emit({ eventType: "presentation-template-deleted", presentationType: (state as LoadedState).sourceData.presentationType });
          queryClient.invalidateQueries(["templates"]);
          toast.success("Deleted template successfully.");
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to delete template.");
        queryClient.invalidateQueries(["templates"]);
      },
    }
  );

  const { mutateAsync: saveCmaTemplate } = useMutation(
    async (data: { name: string }) => {
      if (state.loadState === "loaded") {
        const template = listTemplatesQuery.data?.items?.find(
          (template) => template.name === data.name
        );
        return await getPresentationTemplatesApiClient().templateControllerUpsertTemplate({
          upsertTemplateRequestDto: {
            name: data.name,
            data: {
              id: "",
              sections: state.sections.map(x => ({...x.config, id: uuidv4()})),
            },
            id: template ? template.id : undefined,
          },
        });
      }
    },
    {
      onSuccess: () => {
        toast.success("Template saved succesfully");
        queryClient.invalidateQueries(["templates"]);
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to save template.");
      },
    }
  );

  return {
    listTemplatesQuery,
    getTemplateQuery,
    deleteTemplateMutation,
    saveCmaTemplate,
  };
}

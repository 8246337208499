import * as React from "react";
import { Fade, CircularProgress, Tooltip, Typography, styled } from "@avenue-8/ui-2";
import CloudDoneOutlinedIcon from "@mui/icons-material/CloudDoneOutlined";

export const PresentationSaveIndicatorRoot = styled.div`
  align-items: center;
  display: flex;
`;

const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

interface Props {
  isSaving: boolean;
  updatedAt: Date | undefined;
  message?: React.ReactNode;
}

export const PresentationSaveIndicator = React.memo((props: Props) => {
  const { isSaving, updatedAt, message } = props;
  const updatedAtDate = updatedAt
    ? new Date(updatedAt).toLocaleTimeString("en-US", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
    : null;

  return (
    <PresentationSaveIndicatorRoot data-testid="presentation-save-indicator">
      <Fade
        in={isSaving}
        unmountOnExit
        timeout={{
          enter: 500,
          exit: 0,
        }}
      >
        <ProgressContainer>
          {message && <Typography variant="caption">{message}</Typography>}
          <CircularProgress thickness={2} size={28} disableShrink />
        </ProgressContainer>
      </Fade>
      <Fade
        in={!isSaving}
        unmountOnExit
        timeout={{
          enter: 1000,
          exit: 0,
        }}
      >
        <Tooltip title={updatedAtDate ? `Last updated on ${updatedAtDate}` : ""} arrow>
          <CloudDoneOutlinedIcon />
        </Tooltip>
      </Fade>
    </PresentationSaveIndicatorRoot>
  );
});

/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PresentationConfigDto,
    PresentationConfigDtoFromJSON,
    PresentationConfigDtoFromJSONTyped,
    PresentationConfigDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface GetTemplateResponseDto
 */
export interface GetTemplateResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetTemplateResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetTemplateResponseDto
     */
    name: string;
    /**
     * 
     * @type {PresentationConfigDto}
     * @memberof GetTemplateResponseDto
     */
    data: PresentationConfigDto;
}

export function GetTemplateResponseDtoFromJSON(json: any): GetTemplateResponseDto {
    return GetTemplateResponseDtoFromJSONTyped(json, false);
}

export function GetTemplateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTemplateResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'data': PresentationConfigDtoFromJSON(json['data']),
    };
}

export function GetTemplateResponseDtoToJSON(value?: GetTemplateResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'data': PresentationConfigDtoToJSON(value.data),
    };
}



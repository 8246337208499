import * as React from "react";
import { TopNavigation } from "../TopNavigation";
import { Tab } from "@avenue-8/ui-2";
import { FixedTopNavigation, StaticTopNavigation } from "./Navbar.styles";

type NavbarProps = {
  agentName: string;
  agentPhoto: string;
  agentLineInfo: string | undefined;
  sectionId: string;
  sectionHeaders: Array<{ id: string; label: string }>;
  hideTabs?: boolean;
  position?: "fixed" | "static";
  setSectionAndScroll: (value: any) => void;
};

export const Navbar = (props: NavbarProps) => {
  const {
    agentName,
    agentPhoto,
    agentLineInfo,
    sectionHeaders,
    sectionId,
    hideTabs = false,
    setSectionAndScroll,
    position = "fixed",
  } = props;

  const StyledNav = position === "fixed" ? FixedTopNavigation : StaticTopNavigation;

  return (
    <StyledNav>
      <TopNavigation
        agentName={agentName}
        agentPhotoSrc={agentPhoto}
        agentLineInfo={agentLineInfo}
        value={sectionId}
        onTabsChange={(e, value) => setSectionAndScroll(value)}
        hideTabs={hideTabs}
      >
        {sectionHeaders.map((x) => (
          <Tab value={x.id} key={x.id} label={x.label} />
        ))}
      </TopNavigation>
    </StyledNav>
  );
};

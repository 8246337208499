import { Typography, styled } from "@avenue-8/ui-2";

export const NoResultsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40%;
`;
export const NoResultsTitle = styled(Typography)`
  font-weight: bold;
  margin-bottom: 15px;
`;
export const NoResultsSubtitle = styled(Typography)`
  width: 400px;
  text-align: center;
`;
export const Wrapper = styled.div`
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

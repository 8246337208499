import { FeatureToggleStrategy } from "./feature-toggle-strategy";

export class NullFeatureToggleStrategy implements FeatureToggleStrategy {
  getFeatureToggleValue(_key: string): any {
    throw new Error("You must set a FeatureToggleStrategy.");
  }
  identify() {}
  addFeatureToggleListener(_key: string, _listener: (value: any) => void) {}
  removeFeatureToggleListener(_key: string, _listener: (value: any) => void) {}
  refreshFlags() {}
}

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ListingAgentDto,
    ListingAgentDtoFromJSON,
    ListingAgentDtoFromJSONTyped,
    ListingAgentDtoToJSON,
    PropertyImageResponseDto,
    PropertyImageResponseDtoFromJSON,
    PropertyImageResponseDtoFromJSONTyped,
    PropertyImageResponseDtoToJSON,
    RecurringCostItemDto,
    RecurringCostItemDtoFromJSON,
    RecurringCostItemDtoFromJSONTyped,
    RecurringCostItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PropertyDto
 */
export interface PropertyDto {
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    mlsId?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    mlsSource?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    area?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    baths?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    beds?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    listingStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    buildingName?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    garageSpaces?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    hoaFees?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    floor?: number;
    /**
     * 
     * @type {Date}
     * @memberof PropertyDto
     */
    listDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    listPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    livingSpace?: number;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    lotSize?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    neighborhood?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    nonGarageSpaces?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    propertyStyle?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PropertyDto
     */
    propertyStyles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    propertyStyleOther?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    propertyType?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    propertyTypeOther?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    yearBuilt?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    zipCode?: string;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    renovationType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDto
     */
    hasAdditionalLivingSpace?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    additionalLivingSpaceSqFt?: number;
    /**
     * 
     * @type {Array<PropertyImageResponseDto>}
     * @memberof PropertyDto
     */
    images: Array<PropertyImageResponseDto>;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    soldPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof PropertyDto
     */
    soldDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    county?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDto
     */
    smartResult?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDto
     */
    hasDoormanAttendance: boolean;
    /**
     * 
     * @type {ListingAgentDto}
     * @memberof PropertyDto
     */
    listingAgent: ListingAgentDto;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyDto
     */
    isLease?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PropertyDto
     */
    roomsTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyDto
     */
    flipTaxRemarks?: string;
    /**
     * 
     * @type {Array<RecurringCostItemDto>}
     * @memberof PropertyDto
     */
    recurringCosts?: Array<RecurringCostItemDto>;
}

export function PropertyDtoFromJSON(json: any): PropertyDto {
    return PropertyDtoFromJSONTyped(json, false);
}

export function PropertyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'mlsId': !exists(json, 'mlsId') ? undefined : json['mlsId'],
        'mlsSource': !exists(json, 'mlsSource') ? undefined : json['mlsSource'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'baths': !exists(json, 'baths') ? undefined : json['baths'],
        'beds': !exists(json, 'beds') ? undefined : json['beds'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'listingStatus': !exists(json, 'listingStatus') ? undefined : json['listingStatus'],
        'buildingName': !exists(json, 'buildingName') ? undefined : json['buildingName'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'garageSpaces': !exists(json, 'garageSpaces') ? undefined : json['garageSpaces'],
        'hoaFees': !exists(json, 'hoaFees') ? undefined : json['hoaFees'],
        'floor': !exists(json, 'floor') ? undefined : json['floor'],
        'listDate': !exists(json, 'listDate') ? undefined : (new Date(json['listDate'])),
        'listPrice': !exists(json, 'listPrice') ? undefined : json['listPrice'],
        'livingSpace': !exists(json, 'livingSpace') ? undefined : json['livingSpace'],
        'lotSize': !exists(json, 'lotSize') ? undefined : json['lotSize'],
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'nonGarageSpaces': !exists(json, 'nonGarageSpaces') ? undefined : json['nonGarageSpaces'],
        'propertyStyle': !exists(json, 'propertyStyle') ? undefined : json['propertyStyle'],
        'propertyStyles': !exists(json, 'propertyStyles') ? undefined : json['propertyStyles'],
        'propertyStyleOther': !exists(json, 'propertyStyleOther') ? undefined : json['propertyStyleOther'],
        'propertyType': !exists(json, 'propertyType') ? undefined : json['propertyType'],
        'propertyTypeOther': !exists(json, 'propertyTypeOther') ? undefined : json['propertyTypeOther'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'yearBuilt': !exists(json, 'yearBuilt') ? undefined : json['yearBuilt'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'renovationType': !exists(json, 'renovationType') ? undefined : json['renovationType'],
        'hasAdditionalLivingSpace': !exists(json, 'hasAdditionalLivingSpace') ? undefined : json['hasAdditionalLivingSpace'],
        'additionalLivingSpaceSqFt': !exists(json, 'additionalLivingSpaceSqFt') ? undefined : json['additionalLivingSpaceSqFt'],
        'images': ((json['images'] as Array<any>).map(PropertyImageResponseDtoFromJSON)),
        'soldPrice': !exists(json, 'soldPrice') ? undefined : json['soldPrice'],
        'soldDate': !exists(json, 'soldDate') ? undefined : (new Date(json['soldDate'])),
        'county': !exists(json, 'county') ? undefined : json['county'],
        'smartResult': !exists(json, 'smartResult') ? undefined : json['smartResult'],
        'hasDoormanAttendance': json['hasDoormanAttendance'],
        'listingAgent': ListingAgentDtoFromJSON(json['listingAgent']),
        'isLease': !exists(json, 'isLease') ? undefined : json['isLease'],
        'roomsTotal': !exists(json, 'roomsTotal') ? undefined : json['roomsTotal'],
        'flipTaxRemarks': !exists(json, 'flipTaxRemarks') ? undefined : json['flipTaxRemarks'],
        'recurringCosts': !exists(json, 'recurringCosts') ? undefined : ((json['recurringCosts'] as Array<any>).map(RecurringCostItemDtoFromJSON)),
    };
}

export function PropertyDtoToJSON(value?: PropertyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'area': value.area,
        'baths': value.baths,
        'beds': value.beds,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'listingStatus': value.listingStatus,
        'buildingName': value.buildingName,
        'city': value.city,
        'description': value.description,
        'garageSpaces': value.garageSpaces,
        'hoaFees': value.hoaFees,
        'floor': value.floor,
        'listDate': value.listDate === undefined ? undefined : (value.listDate.toISOString()),
        'listPrice': value.listPrice,
        'livingSpace': value.livingSpace,
        'lotSize': value.lotSize,
        'neighborhood': value.neighborhood,
        'nonGarageSpaces': value.nonGarageSpaces,
        'propertyStyle': value.propertyStyle,
        'propertyStyles': value.propertyStyles,
        'propertyStyleOther': value.propertyStyleOther,
        'propertyType': value.propertyType,
        'propertyTypeOther': value.propertyTypeOther,
        'state': value.state,
        'yearBuilt': value.yearBuilt,
        'zipCode': value.zipCode,
        'renovationType': value.renovationType,
        'hasAdditionalLivingSpace': value.hasAdditionalLivingSpace,
        'additionalLivingSpaceSqFt': value.additionalLivingSpaceSqFt,
        'images': ((value.images as Array<any>).map(PropertyImageResponseDtoToJSON)),
        'soldPrice': value.soldPrice,
        'soldDate': value.soldDate === undefined ? undefined : (value.soldDate.toISOString()),
        'county': value.county,
        'smartResult': value.smartResult,
        'hasDoormanAttendance': value.hasDoormanAttendance,
        'listingAgent': ListingAgentDtoToJSON(value.listingAgent),
        'isLease': value.isLease,
        'roomsTotal': value.roomsTotal,
        'flipTaxRemarks': value.flipTaxRemarks,
        'recurringCosts': value.recurringCosts === undefined ? undefined : ((value.recurringCosts as Array<any>).map(RecurringCostItemDtoToJSON)),
    };
}



/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserItem
 */
export interface UserItem {
    /**
     * 
     * @type {number}
     * @memberof UserItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof UserItem
     */
    lastLoginDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    createdByFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserItem
     */
    createdByLastName?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserItem
     */
    createdOn: Date;
}

export function UserItemFromJSON(json: any): UserItem {
    return UserItemFromJSONTyped(json, false);
}

export function UserItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'lastLoginDate': !exists(json, 'lastLoginDate') ? undefined : (new Date(json['lastLoginDate'])),
        'createdByFirstName': !exists(json, 'createdByFirstName') ? undefined : json['createdByFirstName'],
        'createdByLastName': !exists(json, 'createdByLastName') ? undefined : json['createdByLastName'],
        'createdOn': (new Date(json['createdOn'])),
    };
}

export function UserItemToJSON(value?: UserItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'lastLoginDate': value.lastLoginDate === undefined ? undefined : (value.lastLoginDate.toISOString()),
        'createdByFirstName': value.createdByFirstName,
        'createdByLastName': value.createdByLastName,
        'createdOn': (value.createdOn.toISOString()),
    };
}



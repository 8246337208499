import * as React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Global, css } from "@emotion/react"; // [TO DO] - Move to @avenue-8/ui-2
import { SectionView } from "../../../../../../presentation/presentation-generation-logic/models/section-view";
import {
  EyeHiddenIcon,
  RearrangeIcon,
  ChevronIcon,
  List,
  ListProps,
  Accordion,
  AccordionDetails,
  useTheme,
} from "@avenue-8/ui-2";
import { SectionEditor, SectionEditorOnChangeHandler } from "./SectionEditor/SectionEditor";
import {
  PresentationSourceData,
  SectionConfig,
} from "../../../../../../presentation/presentation-generation-logic/models";
import {
  AccordionItemText,
  AccordionSummaryContent,
  AccordionSummaryStyled,
  ShowHideContainer,
  DragHandleContainer,
} from "./SectionListEditor.styles";

interface Props extends ListProps {
  sectionsView: SectionView[];
  sectionsConfig: SectionConfig[];
  onSortEnd: (data: { oldIndex: number; newIndex: number }) => void;
  onUpdateSection: SectionEditorOnChangeHandler;
  onDeleteSection: (id: string) => any;
  onToggleSectionVisibility: (id: string) => any;
  templateId: string | undefined | null;
  onItemExpanded?: (sectionId?: string) => void;
  sourceData: PresentationSourceData;
}

export const SectionListEditor = (props: Props) => {
  const {
    sectionsView,
    sectionsConfig,
    onSortEnd,
    onUpdateSection,
    onDeleteSection,
    onToggleSectionVisibility,
    onItemExpanded,
    templateId,
    sourceData,
    ...listProps
  } = props;
  const [sectionExpanded, setSectionExpanded] = React.useState<string | undefined>();
  const theme = useTheme();

  const currentTemplateId = templateId || "new";

  const handleChange = (sectionId: string | undefined) => {
    const expandId = sectionId === sectionExpanded ? undefined : sectionId;
    onItemExpanded?.(expandId);
    setSectionExpanded(expandId);
  };

  const handleToggleSectionVisibility = (e: any, id: string) => {
    e.stopPropagation();
    onToggleSectionVisibility(id);
  };

  const allowedSections = sectionsView.filter((s) => !["agent-info"].includes(s.type));

  return (
    <>
      <SortableListContainer
        onSortEnd={onSortEnd}
        helperClass={helperClass}
        lockToContainerEdges={true}
        useDragHandle={true}
        lockAxis="y"
        listProps={listProps}
      >
        {allowedSections.map((section, index) => {
          return (
            <SortableItem
              key={`${section.type}-${section.id}-${currentTemplateId}`}
              index={index}
              text={section.navTitle?.trim() || section.title?.trim() || "Untitled Section"}
              onExpand={handleChange}
              expanded={sectionExpanded === section.id}
              onHide={(e) => {
                handleToggleSectionVisibility(e, section.id);
              }}
              onUpdateSection={onUpdateSection}
              onDeleteSection={onDeleteSection}
              sectionConfig={sectionsConfig.find((s) => s.id === section.id)}
              sourceData={sourceData}
            />
          );
        })}
      </SortableListContainer>
      <Global
        styles={css`
          .${helperClass} {
            z-index: 10000;
            background: ${theme.av8.backgroundAlt};
            box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
          }
        `}
      />
    </>
  );
};

const DragHandle = SortableHandle(() => {
  return (
    <DragHandleContainer data-testid="drag-icon">
      <RearrangeIcon />
    </DragHandleContainer>
  );
});

const helperClass = "presentation-editor-section-list-editor-helper";

interface SortableItemProps {
  text: string;
  onExpand?: (sectionId: string | undefined) => void;
  expanded: boolean;
  onHide: (e: any) => void;
  onUpdateSection: SectionEditorOnChangeHandler;
  sectionConfig?: SectionConfig;
  onDeleteSection: (sectionId: string) => void;
  sourceData: PresentationSourceData;
}
const SortableItem = SortableElement((props: SortableItemProps) => {
  const {
    text,
    onExpand,
    expanded,
    onHide,
    sectionConfig,
    onUpdateSection,
    onDeleteSection,
    sourceData,
  } = props;
  const theme = useTheme();
  return (
    <Accordion
      square
      elevation={0}
      expanded={expanded}
      TransitionProps={{ unmountOnExit: true }} //this greatly improves performance because closed components are no longer rendered on every re-render
      onChange={() => onExpand?.(sectionConfig?.id)}
      data-testid={`${sectionConfig?.type}-section-accordion`}
    >
      <AccordionSummaryStyled
        aria-controls={`${sectionConfig?.id}-content`}
        id={`${sectionConfig?.id}-header`}
        expandIcon={<ChevronIcon rotate={90} color={theme.av8.primaryColor} />}
      >
        <AccordionSummaryContent>
          <DragHandle />
          <AccordionItemText>{text}</AccordionItemText>
          <ShowHideContainer onClick={onHide} $hidden={sectionConfig?.visibility === false}>
            <EyeHiddenIcon className={"eye-hidden-icon"} color={theme.av8.input.borderColor} />
          </ShowHideContainer>
        </AccordionSummaryContent>
      </AccordionSummaryStyled>
      <AccordionDetails>
        <SectionEditor
          onChanged={onUpdateSection}
          onDelete={onDeleteSection}
          section={sectionConfig as any}
          sourceData={sourceData}
        />
      </AccordionDetails>
    </Accordion>
  );
});

const SortableListContainer = SortableContainer(
  ({ listProps, children }: { listProps: ListProps; children: React.ReactNodeArray }) => (
    <List {...(listProps as any)} component="div">
      {children}
    </List>
  )
);

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgentDto,
    AgentDtoFromJSON,
    AgentDtoFromJSONTyped,
    AgentDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PaginatedAgentListResponse
 */
export interface PaginatedAgentListResponse {
    /**
     * 
     * @type {Array<AgentDto>}
     * @memberof PaginatedAgentListResponse
     */
    items: Array<AgentDto>;
    /**
     * 
     * @type {object}
     * @memberof PaginatedAgentListResponse
     */
    meta: object;
    /**
     * 
     * @type {object}
     * @memberof PaginatedAgentListResponse
     */
    links: object;
}

export function PaginatedAgentListResponseFromJSON(json: any): PaginatedAgentListResponse {
    return PaginatedAgentListResponseFromJSONTyped(json, false);
}

export function PaginatedAgentListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaginatedAgentListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(AgentDtoFromJSON)),
        'meta': json['meta'],
        'links': json['links'],
    };
}

export function PaginatedAgentListResponseToJSON(value?: PaginatedAgentListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(AgentDtoToJSON)),
        'meta': value.meta,
        'links': value.links,
    };
}



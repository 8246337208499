import * as React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import GenerateRoutes from "../../routes/GenerateRoutes";
import { Role, RouteModel } from "../../routes/routes";
import { ThemeProvider, toastStyles, styled } from "@avenue-8/ui-2";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { CreateCMA } from "./components/CreateCMA/CreateCMA";
import { Redirect, RouteComponentProps } from "react-router";
import { useAuthContext } from "../shared/contexts/AuthContext/AuthContext";
import { AuthenticatedRedirect } from "../shared/contexts/AuthContext/AuthenticatedRedirect";
import { AuthenticationReconciliator } from "../shared/contexts/AuthContext/AuthenticationReconciliator";
import { CreateCMALogicProvider } from "./hooks/useCreateCMALogic";
import {
  cmaRoutes,
  EditCMAStepParams,
  EditGeneralPresentationStepParams,
  EditPresentationParams,
} from "./cma.routes";
import { DahsboardLogicProvider } from "./hooks/useDashboardLogic/useDashboardLogic";
import { AppBlockingLoader } from "../shared/components/AppBlockingLoader";
import { EditComparablePropertyLogicProvider } from "./components/CreateCMA/EditComparableProperty/useEditComparablePropertyLogic";
import { CreateGeneralPresentation } from "./components/CreateCMA/CreateGeneralPresentation";

const Page = styled.div`
  background: ${(p) => p.theme.av8.background};
  min-height: 100vh;
  position: relative;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  ${() => toastStyles}
`;

const renderDashboard = () => {
  return (
    <CreateCMALogicProvider cmaId={undefined} presentationType={undefined}>
      <DahsboardLogicProvider>
        <Dashboard />
      </DahsboardLogicProvider>
    </CreateCMALogicProvider>
  );
};

const renderEditStep = (props: RouteComponentProps<EditCMAStepParams>) => {
  return (
    <CreateCMALogicProvider cmaId={props.match.params.id} presentationType={"cma"}>
      <EditComparablePropertyLogicProvider>
        <CreateCMA />
      </EditComparablePropertyLogicProvider>
    </CreateCMALogicProvider>
  );
};

const renderEditGeneralPresentationStep = (
  props: RouteComponentProps<EditGeneralPresentationStepParams>
) => {
  return (
    <CreateCMALogicProvider cmaId={props.match.params.id} presentationType={"general"}>
      <EditComparablePropertyLogicProvider>
        <CreateGeneralPresentation />
      </EditComparablePropertyLogicProvider>
    </CreateCMALogicProvider>
  );
};

const renderEdit = (props: RouteComponentProps<EditPresentationParams>) => {
  const { id } = props.match.params;
  return <Redirect to={cmaRoutes.editStep.generate({ id, step: "subject-property" })} />;
};

const renderEditGeneralPresentation = (props: RouteComponentProps<EditPresentationParams>) => {
  const { id } = props.match.params;
  return <Redirect to={cmaRoutes.editGeneralPresentationStep.generate({ id, step: "search" })} />;
};

const routes: RouteModel[] = [
  {
    path: cmaRoutes.dashboard.route,
    render: renderDashboard,
    exact: true,
    roles: [Role.Admin, Role.Agent],
  },
  {
    path: cmaRoutes.editStep.route,
    component: renderEditStep,
    exact: true,
    roles: [Role.Admin, Role.Agent],
  },
  {
    path: cmaRoutes.edit.route,
    component: renderEdit,
    exact: true,
    roles: [Role.Admin, Role.Agent],
  },

  {
    path: cmaRoutes.editGeneralPresentationStep.route,
    component: renderEditGeneralPresentationStep,
    exact: true,
    roles: [Role.Admin, Role.Agent],
  },
  {
    path: cmaRoutes.editGeneralPresentation.route,
    component: renderEditGeneralPresentation,
    exact: true,
    roles: [Role.Admin, Role.Agent],
  },
];

export const CMAV2Module = React.memo(() => {
  const {
    state: { user },
  } = useAuthContext();

  return (
    <>
      <AuthenticationReconciliator />
      <AuthenticatedRedirect>
        <ThemeProvider darkMode={false}>
          <AppBlockingLoader />
          <Page>
            <GenerateRoutes routes={routes} userRole={user?.role} />
          </Page>
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </AuthenticatedRedirect>
    </>
  );
});

import {
  SearchIcon,
  Grid,
  Typography,
  Select,
  SelectOption,
  InputLabel,
  Fieldset,
  Button,
  styled,
} from "@avenue-8/ui-2";
import { InputAdornment } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useIsMutating } from "react-query";
import { appEventEmitter } from "src/events/app-event-emitter";
import { NeighborhoodField } from "src/modules/cma-v2/components/Fields/NeighborhoodField";
import {
  PropertyTypeField,
  PropertyTypeFieldSelectProps,
} from "src/modules/cma-v2/components/Fields/PropertyTypeField";
import { InsightsSectionConfig } from "../../../../../../../../../presentation/presentation-generation-logic/models/section-config";
import { SectionEditorProps } from "../../SectionEditor";
import { LabeledCheckbox, LabeledCheckboxFieldModel } from "../LabeledCheckbox";
import { convertInsightsEditorFormModelToPartialConfig } from "./insights-editor";

const checkboxes: Array<LabeledCheckboxFieldModel<keyof InsightsSectionConfig>> = [
  {
    label: "Median Sales Price Year Comparision",
    name: "showMedianSalesPriceYearComparision",
    checked: true,
  },
  {
    label: "Average Living Space Sqft Price",
    name: "showAverageLivingSpaceSqftPrice",
    checked: true,
  },
  { label: "Inventory Speed Comparison", name: "showInventorySpeedComparison", checked: true },
];

const EditorContainer = styled(Fieldset)`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export interface InsightsEditorFormModel {
  neighborhood: string;
  city: string;
  state: string;
  propertyType: string;
  listingType: "sale" | "lease";
}

export const InsightsEditor = ({
  section,
  onChanged,
  sourceData,
  onDelete,
}: SectionEditorProps<InsightsSectionConfig>) => {
  const insightsSectionForm: InsightsEditorFormModel = React.useMemo(() => {
    return {
      neighborhood: section.neighborhood ?? "",
      propertyType: section.propertyType ?? "",
      city: section.city ?? "",
      state: section.state ?? "",
      listingType: section.isLease === true ? "lease" : "sale",
    };
  }, [section]);

  const [propertyTypeFilter, setPropertyTypeFilter] = React.useState<
    PropertyTypeFieldSelectProps["filter"]
  >({
    neighborhoodFilter: insightsSectionForm.neighborhood,
    cityFilter: insightsSectionForm.city,
    stateFilter: insightsSectionForm.state,
  });
  const formMethods = useForm<InsightsEditorFormModel>({
    defaultValues: { ...insightsSectionForm },
    mode: "onBlur",
    reValidateMode: "onChange",
  });
  const { getValues, setValue, watch, trigger, reset, control } = formMethods;
  const lastSelectedNeighborhood = React.useRef<string | null>(insightsSectionForm.neighborhood);
  const [formattedNeighborhood, setFormattedNeighborhood] = React.useState<string | null>(
    [insightsSectionForm.neighborhood, insightsSectionForm.city, insightsSectionForm.state]
      .filter(Boolean)
      .join(", ")
  );

  const isMutating = useIsMutating(["fetch-presentation-sections-data"]);
  const isFetchingSectionsData = isMutating > 0;

  const handleFetchSectionsData = async (data: InsightsEditorFormModel) => {
    const newSectionConfig = convertInsightsEditorFormModelToPartialConfig(data);

    const isValid = await trigger();
    if (!isValid) return;

    onChanged({
      sectionConfig: {
        ...section,
        ...newSectionConfig,
      },
    });
  };

  const initialModel = React.useMemo(() => {
    return checkboxes.reduce((acc: any, checkbox) => {
      acc[checkbox.name] = section[checkbox.name] ?? checkbox.checked;
      return acc;
    }, {} as InsightsSectionConfig);
  }, [section]);

  const [model, setModel] = useState(initialModel);

  const onChangeSwitch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name as keyof InsightsSectionConfig;
      const updatedModel = { ...model, [name]: !model[name] };
      setModel(updatedModel);
      onChanged({ sectionConfig: { ...section, ...updatedModel } });
    },
    [model, setModel, onChanged, section]
  );

  const handleSuggestionChosen = async (chosenSuggestion: { [type: string]: string | null }) => {
    if (chosenSuggestion.text === formattedNeighborhood) return;

    setFormattedNeighborhood(chosenSuggestion.text);
    lastSelectedNeighborhood.current = chosenSuggestion.neighborhood;
    setValue("city", chosenSuggestion.city);
    setValue("state", chosenSuggestion.state);
    setValue("propertyType", "");

    const values = getValues();
    setPropertyTypeFilter({
      neighborhoodFilter: values.neighborhood,
      cityFilter: values.city,
      stateFilter: values.state,
    });

    handleFetchSectionsData(values);

    appEventEmitter.emit({
      eventType: "presentation-neighborhood-search-filter-changed",
      value: [values.neighborhood, values.city, values.state].join(", "),
      presentationType: sourceData.presentationType,
    });
  };

  const handleNeighborhoodBlur = (_e: any) => {
    if (
      lastSelectedNeighborhood.current &&
      lastSelectedNeighborhood.current !== getValues("neighborhood")
    ) {
      setValue("neighborhood", "");
    }
    trigger();
  };

  const neighborhoodIsValid = React.useMemo(() => {
    const neighborhood = watch("neighborhood");
    return !!neighborhood && neighborhood === lastSelectedNeighborhood.current;
  }, [watch]);

  React.useEffect(() => {
    reset({ ...insightsSectionForm });
  }, [reset, insightsSectionForm]);

  return (
    <EditorContainer disabled={isFetchingSectionsData}>
      <FormProvider {...formMethods}>
        <Controller
          control={control}
          name="city"
          render={(props) => <input {...props} type="hidden" />}
        />
        <Controller
          control={control}
          name="state"
          render={(props) => <input {...props} type="hidden" />}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">Search a neighborhood to generate insights.</Typography>
          </Grid>
          <Grid item xs={12}>
            <NeighborhoodField
              optionLabelText="text"
              onSuggestionChosen={handleSuggestionChosen}
              required="You must select one of the suggested neighborhoods"
              fieldProps={{
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end" style={{ marginRight: "0.75rem" }}>
                      <SearchIcon size={24} />
                    </InputAdornment>
                  ),
                },
                onChange: (_) => {
                  setValue("propertyType", "");
                },
                onBlur: handleNeighborhoodBlur,
                disabled: isFetchingSectionsData,
              }}
              numberOfSuggestions={20}
              deduplicateSuggestions={false}
            />
            {formattedNeighborhood && (
              <Typography variant="body2" sx={{ marginTop: "0.5rem" }}>
                <strong>Selected neighborhood:</strong>
                <br />
                {formattedNeighborhood}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <PropertyTypeField.Select
              label="Property Type (Optional)"
              selectProps={{
                onChange: () => handleFetchSectionsData(getValues()),
                disabled: isFetchingSectionsData || !neighborhoodIsValid,
              }}
              filter={propertyTypeFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel htmlFor={"listingType"} id="listing-type-label">
              Listing Type
            </InputLabel>
            <Controller
              name={"listingType"}
              render={({ onChange, ...rest }) => (
                <Select
                  labelId="listing-type-label"
                  fullWidth
                  {...rest}
                  onChange={(e) => {
                    onChange(e);
                    handleFetchSectionsData(getValues());
                  }}
                  disabled={isFetchingSectionsData}
                >
                  <SelectOption value={"sale"} key={`listing-type-sale`}>
                    Sale
                  </SelectOption>
                  <SelectOption value={"lease"} key={`listing-type-lease`}>
                    Lease
                  </SelectOption>
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </FormProvider>
      <Grid container spacing={2}>
        {checkboxes.map((field) => {
          return (
            <Grid item xs={12} key={field.name}>
              <LabeledCheckbox
                label={field.label}
                checked={Boolean(model[field.name])}
                onChange={onChangeSwitch}
                name={field.name}
              />
            </Grid>
          );
        })}
      </Grid>
      <Button variant="text" onClick={() => onDelete(section.id)}>
        Delete Section
      </Button>
    </EditorContainer>
  );
};

export class SortOption<Key> {
  constructor({
    key,
    label,
    initialDirection,
    reverseSort,
  }: {
    key: Key;
    label: string;
    initialDirection: "asc" | "desc" | null; //use null for values that can't be reversed at all
    reverseSort?: boolean; //when the label does not match the key - like for distance & Proximity
  }) {
    this.key = key;
    this.label = label;
    this.initialDirection = initialDirection;
    this.reverseSort = reverseSort ?? false;
  }

  readonly key: Key;
  readonly label: string;
  readonly reverseSort: boolean;
  readonly initialDirection: "asc" | "desc" | null;
}

const Relevance = new SortOption<"relevance">({
  key: "relevance",
  label: "Relevance",
  initialDirection: null,
});
const Distance = new SortOption<"distance">({
  key: "distance",
  label: "Distance",
  initialDirection: "asc",
  reverseSort: true,
});
const Price = new SortOption<"price">({ key: "price", label: "Price", initialDirection: "asc" });
const ListDate = new SortOption<"listdate">({
  key: "listdate",
  label: "List Date",
  initialDirection: "desc",
});
const SoldDate = new SortOption<"solddate">({
  key: "solddate",
  label: "Sold Date",
  initialDirection: "desc",
});
const LivingSpace = new SortOption<"livingspace">({
  key: "livingspace",
  label: "Living Space",
  initialDirection: "desc",
});
const LotSize = new SortOption<"lotsize">({
  key: "lotsize",
  label: "Lot Size",
  initialDirection: "desc",
});

export const COMPARABLE_SORT_OPTIONS_ARRAY = [
  Relevance,
  Distance,
  Price,
  ListDate,
  SoldDate,
  LivingSpace,
  LotSize,
];

export type SortOptionKey = typeof COMPARABLE_SORT_OPTIONS_ARRAY[number]["key"];

export const COMPARABLE_SORT_OPTIONS_MAP: Map<
  SortOptionKey,
  SortOption<any>
> = COMPARABLE_SORT_OPTIONS_ARRAY.reduce((pValue, value) => {
  pValue.set(value.key, value);
  return pValue;
}, new Map<SortOptionKey, SortOption<any>>());

export const INITIAL_SORT_OPTION: SortOption<any> = COMPARABLE_SORT_OPTIONS_MAP.get(
  "relevance"
) as any;

import { toast } from "@avenue-8/ui-2";
import { appEventEmitter } from "../../../../events/app-event-emitter";
import { getAuthApiClient, getUsersApiClient } from "../../apis/user/api-factories";
import { UserControllerResetPasswordRequest } from "../../apis/user/generated";
import { ActionInvoker, makeReducerContext } from "../../hooks/makeReducerContext";
import { authStorageService } from "./auth-storage-service";

export interface UserJwtTokenModel {
  email: string;
  role: string;
  agentId: number;
  iat: number;
  exp: number;
  userId: number;
}

export interface UserInfoModel {
  fullName: string;
  isFirstLogin: boolean;
  avatar?: string;
  completedOnboardingAt?: Date | null;
}

export interface ResetPasswordJwtTokenModel {
  email: string;
  type: "reset" | "set";
  iat: number;
  exp: number;
}

interface State {
  authenticationToken?: string | null;
  user?: UserJwtTokenModel | null;
  userInfo?: UserInfoModel | null;
  authenticateState?: AuthenticateState;
  authUpdatedAt?: number;
  resetPasswordToken?: string | null;
  resetPasswordTokenState?: ResetPasswordTokenState;
  resetPasswordState?: ResetPasswordState;
  resetPasswordType?: "reset" | "set" | null;
  forgotPasswordState?: ForgotPasswordState;
}

const defaultState: () => State = () => {
  const { authToken, user, userInfo } = authStorageService.get() || {};
  return {
    authenticationToken: authToken,
    user,
    userInfo,
  };
};

// ACTION PAYLOADS
interface Authenticate {
  type: "authenticate";
  authToken: string;
  user: UserJwtTokenModel | null;
  userInfo: UserInfoModel;
  updatedAt: number;
}
interface AuthenticateInit {
  type: "authenticate-init";
}
interface AuthenticateFail {
  type: "authenticate-fail";
}
type AuthenticateState = "idle" | "init" | "success" | "fail";

interface SignOut {
  type: "sign-out";
}

interface ResetPasswordInit {
  type: "reset-password-init";
}
interface ResetPassword {
  type: "reset-password";
  token: string;
}

interface ResetPasswordFail {
  type: "reset-password-fail";
}

interface ReconciliateAuthentication {
  type: "reconciliate-authentication";
  authenticationToken: string | null;
  user?: UserJwtTokenModel | null;
  userInfo?: UserInfoModel | null;
  authenticateState?: AuthenticateState;
  authUpdatedAt: number;
}

export type ResetPasswordTokenState =
  | "validating"
  | "valid-token"
  | "invalid-token"
  | "token-expired";
export type ResetPasswordState = "init" | "success" | "fail";

interface ValidateResetPasswordTokenInit {
  type: "validate-reset-password-token-init";
  resetPasswordTokenState: ResetPasswordTokenState;
}

interface ValidateResetPasswordToken {
  type: "validate-reset-password-token";
  resetPasswordToken?: string | null;
  resetPasswordTokenState: ResetPasswordTokenState;
  resetPasswordType?: "reset" | "set";
}

export type ForgotPasswordState = "idle" | "init" | "success";
interface SendResetPasswordEmailInit {
  type: "send-reset-password-email-init";
}
interface SendResetPasswordEmail {
  type: "send-reset-password-email";
  forgotPasswordState?: ForgotPasswordState;
}

type Action =
  | AuthenticateInit
  | Authenticate
  | AuthenticateFail
  | SignOut
  | ResetPassword
  | ValidateResetPasswordTokenInit
  | ValidateResetPasswordToken
  | ReconciliateAuthentication
  | ResetPasswordInit
  | ResetPassword
  | ResetPasswordFail
  | SendResetPasswordEmailInit
  | SendResetPasswordEmail;

interface ActionInvokerContext {
  goToLoginPage: () => void;
  removeQueriesCache: () => void;
}

// ACTION INVOKER
class AppActions extends ActionInvoker<Action, ActionInvokerContext, State> {
  userApi = getUsersApiClient;
  authApi = getAuthApiClient;

  authenticate({
    authToken,
    userInfo,
    remember = false,
  }: {
    authToken: string;
    userInfo: UserInfoModel;
    remember?: boolean;
  }) {
    const updatedAt = Date.now();
    authStorageService.store({ remember, authToken, userInfo, updatedAt });
    const authStorage = authStorageService.get();
    if (!authStorage) return;

    const { user } = authStorage;

    this.dispatch({
      type: "authenticate",
      authToken,
      user,
      userInfo,
      updatedAt,
    });
    appEventEmitter.emit({
      eventType: "login",
      userId: user!.userId.toString(),
      email: user!.email,
    });
  }

  updateUserInfo(userInfo: UserInfoModel) {
    const authStorage = authStorageService.get();
    if (!authStorage) return;
    authStorageService.store({
      remember: authStorage.remember,
      authToken: authStorage.authToken,
      userInfo,
    });
  }

  async authenticateWithEmailAndPassword({
    email,
    password,
    remember = false,
  }: {
    email: string;
    password: string;
    remember?: boolean;
  }) {
    this.dispatch({ type: "authenticate-init" });

    try {
      const { token: authToken, info: userInfo } =
        await this.authApi().localAuthControllerAuthenticate({
          localLoginParams: {
            email,
            password,
          },
        });
      const updatedAt = Date.now();

      authStorageService.store({ remember, authToken, userInfo, updatedAt });
      const authStorage = authStorageService.get();
      if (!authStorage) return;
      const { user } = authStorage;

      this.dispatch({
        type: "authenticate",
        authToken,
        user,
        userInfo,
        updatedAt,
      });
      appEventEmitter.emit({ eventType: "login", userId: user!.userId.toString(), email });
    } catch (error) {
      console.error(error);
      this.dispatch({ type: "authenticate-fail" });
      toast.error("Invalid e-mail or password", { shouldDeduplicate: true });
    }
  }

  async authenticateWithAuthCode({ authCode, remember = false }: { authCode: string; remember?: boolean }) {
    this.dispatch({ type: "authenticate-init" });

    try {
      const { token: authToken, info: userInfo } =
        await this.authApi().authCodeControllerAuthenticate({
          authCodeParams: {
            authCode,
          }
        });
      const updatedAt = Date.now();

      authStorageService.store({ remember, authToken, userInfo, updatedAt });
      const authStorage = authStorageService.get();
      if (!authStorage) return;
      const { user } = authStorage;

      this.dispatch({
        type: "authenticate",
        authToken,
        user,
        userInfo,
        updatedAt,
      });

      const decodedAuthCode = parseJwt(authCode);
      
      appEventEmitter.emit({
        eventType: "login",
        userId: user!.userId.toString(),
        email: decodedAuthCode?.email ?? "",
      });
    } catch (error) {
      console.error(error);
      this.dispatch({ type: "authenticate-fail" });
      toast.error("Invalid authentication code", { shouldDeduplicate: true });
    }
  }

  signOut() {
    this.dispatch({ type: "sign-out" });
    authStorageService.clear();
    this.context.removeQueriesCache();
    this.context.goToLoginPage();
    appEventEmitter.emit({ eventType: "logout" });
  }

  isAuthenticated = () => {
    return Boolean(
      this.state.user && !authStorageService.authTokenHasExpired(this.state.authenticationToken)
    );
  };

  /**
   * Update authentication data with data stored in localstorage
   */
  reconciliateAuthentication = () => {
    const { authToken, user, userInfo, updatedAt } = authStorageService.get() || {};

    if (authStorageService.authTokenHasExpired(authToken)) {
      toast.error("Your session has expired. Please login again.", { shouldDeduplicate: true });
      this.signOut();
      return;
    }

    const authenticationDataIsOutdated = authToken && this.state.authUpdatedAt !== updatedAt;

    if (authenticationDataIsOutdated) {
      this.dispatch({
        type: "reconciliate-authentication",
        authenticationToken: authToken,
        authenticateState: "idle",
        user,
        userInfo,
        authUpdatedAt: updatedAt || 0,
      });
    }
  };

  validateResetPasswordToken(token: string) {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1])) as ResetPasswordJwtTokenModel;

      this.dispatch({
        type: "validate-reset-password-token-init",
        resetPasswordTokenState: "validating",
      });

      if (Date.now() >= decodedToken.exp * 1000) {
        this.dispatch({
          type: "validate-reset-password-token",
          resetPasswordTokenState: "token-expired",
          resetPasswordToken: token,
        });
      } else {
        this.dispatch({
          type: "validate-reset-password-token",
          resetPasswordToken: token,
          resetPasswordTokenState: "valid-token",
          resetPasswordType: decodedToken.type,
        });
        return decodedToken;
      }
    } catch (error) {
      this.dispatch({
        type: "validate-reset-password-token",
        resetPasswordTokenState: "invalid-token",
        resetPasswordToken: token,
      });
    }
  }

  async resetPassword(token: string, password: string) {
    try {
      this.dispatch({ type: "reset-password-init" });
      const params: UserControllerResetPasswordRequest = {
        userResetPasswordParams: {
          token,
          password,
        },
      };

      // TO DO: USE THE TOKEN RETURNED BY THE ENDPOINT TO AUTHENTICATE
      await this.userApi().userControllerResetPassword(params);

      this.dispatch({ type: "reset-password", token });
    } catch (error) {
      console.error(error);
      this.dispatch({ type: "reset-password-fail" });
    }
  }

  async sendResetPasswordEmail(params: { email: string }) {
    try {
      this.dispatch({ type: "send-reset-password-email-init" });
      await this.userApi().userControllerStartResetPassword({
        userStartResetPasswordParams: params,
      });
      this.dispatch({ type: "send-reset-password-email" });
    } catch (error) {
      this.dispatch({ type: "send-reset-password-email" });
    }
  }

  resetForgotPasswordModalState() {
    this.dispatch({ type: "send-reset-password-email", forgotPasswordState: "idle" });
  }
}
const defaultInvoker = new AppActions();

//REDUCER
const reducer: (state: State, action: Action) => State = (state, action) => {
  switch (action.type) {
    case "authenticate-init": {
      return { ...state, authenticateState: "init" };
    }
    case "authenticate": {
      return {
        ...state,
        authenticationToken: action.authToken,
        user: action.user,
        authenticateState: "success",
        userInfo: action.userInfo,
        authUpdatedAt: action.updatedAt,
      };
    }
    case "authenticate-fail": {
      return {
        ...state,
        authenticationToken: null,
        user: null,
        userInfo: null,
        authenticateState: "fail",
      };
    }
    case "reconciliate-authentication": {
      return {
        ...state,
        ...action,
      };
    }
    case "sign-out": {
      return {
        ...state,
        authenticationToken: null,
        user: null,
        userInfo: null,
        authenticateState: "idle",
      };
    }
    case "validate-reset-password-token-init": {
      const { resetPasswordTokenState } = action;
      return { ...state, resetPasswordTokenState, resetPasswordType: null };
    }
    case "validate-reset-password-token": {
      const { resetPasswordToken, resetPasswordTokenState, resetPasswordType } = action;
      return { ...state, resetPasswordToken, resetPasswordTokenState, resetPasswordType };
    }
    case "reset-password-init": {
      return { ...state, resetPasswordState: "init", resetPasswordType: null };
    }
    case "reset-password": {
      const { token } = action;
      return { ...state, resetPasswordToken: token, resetPasswordState: "success" };
    }
    case "reset-password-fail": {
      return { ...state, resetPasswordState: "fail", resetPasswordToken: null };
    }
    case "send-reset-password-email-init": {
      return { ...state, forgotPasswordState: "init" };
    }
    case "send-reset-password-email": {
      return { ...state, forgotPasswordState: action.forgotPasswordState ?? "success" };
    }
    default:
      break;
  }
  console.warn(`Could not find reducer handler for action ${(action as any).type}`);
  return state;
};

export const { useReducerContext: useAuthContext, ReducerContextProvider: AuthContextProvider } =
  makeReducerContext<State, Action, ActionInvokerContext, AppActions>({
    reducer,
    defaultState: defaultState(),
    defaultInvoker,
  });

const parseJwt = (token: string): {
  email: string;
} | null => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
import {
  DateRangeFilter,
  PropertyControllerSearchV2Request,
  PropertyDto,
  RangeFilterParams,
} from "../../../../../shared/apis/cma/generated";
import { getStatusKeysByStatus } from "../../../../../shared/utils/normalizePropertyStatus";
import {
  INITIAL_FORM_FILTERS,
  INITIAL_INDIRECT_FILTERS,
  INITIAL_MAP_FILTERS,
  MapFilter,
} from "./comparable-filters";
import { INITIAL_SORT_OPTION } from "./comparable-sort-options";
import { deepEqual } from "ts-deep-equal";
import { numberOnly } from "src/modules/shared/utils/validation-patterns";

const INITIAL_PAGINATION_OPTION = {
  limit: 10,
  page: 1,
};

export const rangeDatesListingStatusOptions = [
  {
    label: "Last 30 days",
    value: '{"M": -1}',
  },
  {
    label: "Last 60 days",
    value: '{"M": -2}',
  },
  {
    label: "Last 3 months",
    value: '{"M": -3}',
  },
  {
    label: "Last 6 months",
    value: '{"M": -6}',
  },
  {
    label: "Last 12 months",
    value: '{"M": -12}',
  },
  {
    label: "Last 18 months",
    value: '{"M": -18}',
  },
  {
    label: "Last 24 months",
    value: '{"M": -24}',
  },
  {
    label: "All-time",
    value: "all-time",
  },
];

export const numberConverter = (source?: string): number | undefined => {
  return source ? Number(source.replace(new RegExp(numberOnly, "g"), "")) : undefined;
};

export const rangeConverter = (
  inputRange: (string | undefined)[]
): RangeFilterParams | undefined => {
  if (inputRange[0] || inputRange[1])
    return {
      startAt: numberConverter(inputRange[0]),
      endAt: numberConverter(inputRange[1]),
    };
  return undefined;
};

export const relativeDateConverter = (
  lastDays: string | undefined
): DateRangeFilter | undefined => {
  if (!lastDays) return undefined;

  if (lastDays === "all-time")
    return {
      startAt: null,
      endAt: null,
    };

  const { Y: year = 0, M: month = 0, D: day = 0 } = JSON.parse(lastDays);

  const date = new Date();
  date.setDate(date.getDate() + day);
  date.setMonth(date.getMonth() + month);
  date.setFullYear(date.getFullYear() + year);

  return {
    startAt: date,
    endAt: new Date(),
  };
};

const parseStatus = (statuses: (string | boolean | undefined)[]): number[] | undefined => {
  const filteredStatuses = statuses.filter((x) => Boolean(x)) as string[];
  if (!filteredStatuses.length) return undefined;

  const statusesIds: Set<number> = new Set();
  filteredStatuses.forEach((status) =>
    getStatusKeysByStatus(status as any).forEach((statusId) => statusesIds.add(statusId))
  );
  return [...statusesIds];
};

const extractPolygonFilter = (mapFilter?: MapFilter) => {
  if (mapFilter && mapFilter.shape === "polygon") {
    return mapFilter.points.map(({ lat, lng: lon }) => ({ lat, lon }));
  }
  return undefined;
};

const extractRadiusFilter = (mapFilter?: MapFilter) => {
  if (mapFilter && mapFilter.shape === "circle" && mapFilter.radius) {
    const {
      center: { lat, lng },
      radius,
    } = mapFilter;
    return {
      latitude: lat,
      longitude: lng,
      distance: (radius * 0.621371) / 1000,
    };
  }
  return undefined;
};

const parseSearchValue = (search?: string, sameBuildingSearch?: boolean, address?: string) => {
  const result = sameBuildingSearch ? address : search;
  return result;
};

export const convertSearchStepModelToPropertySearch = (
  input: Partial<SearchComparableFormModel>,
  subjectProperty?: PropertyDto
): PropertyControllerSearchV2Request["searchV2Dto"] => {
  const propertyTypes = (input.propertyTypes || [])
    ?.filter((x) => Boolean(x))
    .map((x) => Number(x));
  const convertedForm: PropertyControllerSearchV2Request["searchV2Dto"] = {
    search: parseSearchValue(input.search, input.sameBuildingSearch, subjectProperty?.addressLine1),
    propertyStatus: parseStatus([input.active, input.sold, input.contingent, input.pending]),
    soldDate: relativeDateConverter(input.soldLastDays),
    listDate: relativeDateConverter(input.listLastDays),
    yearBuilt: rangeConverter([input.yearBuiltMin, input.yearBuiltMax]),
    area: input.mlsArea,
    neighborhood: input.neighborhood,
    price: rangeConverter([input.priceMin, input.priceMax]),
    numBedrooms: rangeConverter([input.bedsMin, input.bedsMax]),
    numBathrooms: rangeConverter([input.bathsMin, input.bathsMax]),
    propertyTypes: propertyTypes?.length ? propertyTypes.filter((x) => Boolean(x)) : undefined,
    livingSpace: rangeConverter([input.livingSpaceMin, input.livingSpaceMax]),
    lotSize: rangeConverter([input.lotSizeMin, input.lotSizeMax]),
    parkingGarageSpaces: rangeConverter([input.garageSpacesMin, input.garageSpacesMax]),
    hasDoormanAttendance: input.doorman ? input.doorman === "yes" : undefined,
    // hoaMonthlyFee: rangeConverter([input.?, input.?]),
    removeNulls: !input.smartSearch,
    polygonPoints: extractPolygonFilter(input.mapFilters),
    radius: extractRadiusFilter(input.mapFilters),
    page: input.page,
    limit: input.limit,
    orderBy: input.sort
      ? {
          order: input.sort.asc ? "asc" : "desc",
          term: input.sort.criteria,
          latitude: subjectProperty?.latitude ?? 0,
          longitude: subjectProperty?.longitude ?? 0,
        }
      : undefined,
    city: input.city || undefined,
    state: input.state || undefined,
    county: input.county || undefined,
    isLease: input.businessType ? input.businessType === "rent" : undefined,
  };
  const filteredValues = Object.fromEntries(
    Object.entries(convertedForm).filter(([_k, v]) => v !== undefined)
  );
  return filteredValues;
};

export const SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES = {
  ...INITIAL_FORM_FILTERS,
  ...INITIAL_INDIRECT_FILTERS,
  ...INITIAL_PAGINATION_OPTION,
  search: "",
  sort: {
    criteria: INITIAL_SORT_OPTION.key,
    asc: INITIAL_SORT_OPTION.initialDirection === "asc",
  },
  mapFilters: INITIAL_MAP_FILTERS,
};

export type SearchComparableFormModel = Partial<
  typeof SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES & { mapFilters: MapFilter }
>;

const hasValue = (base: unknown, value: unknown) => {
  if (Array.isArray(value)) {
    return (value as any[]).filter((x) => Boolean(x)).length > 0;
  }
  return !deepEqual(base, value);
};

export const isModelEnoughToSearch = (model: SearchComparableFormModel): boolean => {
  const searchEnablers: Array<keyof SearchComparableFormModel> = [
    ...(Object.keys(INITIAL_FORM_FILTERS) as Array<keyof typeof INITIAL_FORM_FILTERS>),
    "search",
    "mapFilters",
  ];
  const fieldsToIgnore = ["businessType"];

  return Boolean(
    searchEnablers
      .filter((x) => !fieldsToIgnore.includes(x))
      .find((key) => {
        return hasValue(SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES[key], model[key]);
      })
  );
};

export const activeFiltersCount = (model: SearchComparableFormModel): number => {
  const filtersAvailable: Array<keyof SearchComparableFormModel> = [
    ...(Object.keys(INITIAL_FORM_FILTERS) as Array<keyof typeof INITIAL_FORM_FILTERS>),
    "mapFilters",
  ];
  const fieldsToIgnore = ["businessType", "radius"];

  const changedValues = filtersAvailable
    .filter((x) => !fieldsToIgnore.includes(x))
    .filter((key) => {
      return hasValue(SEARCH_COMPARABLE_FORM_MODEL_DEFAULT_VALUES[key], model[key]);
    });
  return changedValues.length;
};

export const normalizeModel = (model: SearchComparableFormModel) => {
  const filteredEntries = Object.entries(model).map(([k, v]) => {
    if (v === null || v === undefined || v === "") return [];
    if (Array.isArray(v)) {
      const onlyChecked = v.filter((x) => Boolean(x));
      if (!onlyChecked.length) {
        return [];
      }
      return [k, onlyChecked];
    }
    if (k === "mapFilters" && (v as SearchComparableFormModel["mapFilters"])?.shape === "none")
      return [];
    return [k, v];
  });

  return Object.fromEntries(filteredEntries);
};

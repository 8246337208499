type GuidedTourBaseContent = {
  completed?: boolean;
  dontShowAgain?: boolean;
};

type GuidedTourContent = GuidedTourBaseContent;

export type IGuidedTour = {
  "best-ways-to-search": GuidedTourContent;
};

const initialState = {
  "best-ways-to-search": {
    completed: false,
    dontShowAgain: false,
  },
};

class GuidedTourStorageService {
  private guidedTour: IGuidedTour;
  private static instance = new GuidedTourStorageService();

  constructor() {
    const guidedTour = localStorage.getItem("guidedTour");

    if (guidedTour) {
      try {
        const guidedTourJson = JSON.parse(guidedTour) as IGuidedTour;
        this.guidedTour = guidedTourJson;
      } catch {
        localStorage.removeItem("guidedTour");
        this.guidedTour = this.setInitialState();
      }
    } else {
      this.guidedTour = this.setInitialState();
    }

    if (GuidedTourStorageService.instance) {
      return GuidedTourStorageService.instance;
    }
  }

  private setInitialState() {
    localStorage.setItem("guidedTour", JSON.stringify(this.guidedTour));
    return initialState;
  }

  getGuidedTour(key: keyof IGuidedTour): GuidedTourContent {
    return this.guidedTour[key];
  }

  setGuidedTour(key: keyof IGuidedTour, guidedTourContent: GuidedTourContent) {
    this.guidedTour[key] = guidedTourContent;
    localStorage.setItem("guidedTour", JSON.stringify(this.guidedTour));
  }

  clear() {
    localStorage.removeItem("guidedTour");
    this.guidedTour = {
      ...initialState,
    };
  }
}

export const guidedTourStorageService = new GuidedTourStorageService();

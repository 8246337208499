import * as React from "react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useDebounceFunction } from "src/modules/cma-v2/hooks/useDebounceFunction";
import { HeaderSectionConfig } from "src/modules/presentation/presentation-generation-logic/sections/header/header-section-config";
import { EstimatePriceFormModel } from "../../../../../Estimate/components/EstimatePriceForm";
import { convertEstimatePriceFormModelToEstimatedPriceDto } from "../../../../../Estimate/estimate-price";
import { SectionEditorProps } from "../../SectionEditor";
import { EstimateRangeForm } from "../EstimateRangeForm";
import { LabeledCheckbox } from "../LabeledCheckbox";
import { InfoIcon } from "@avenue-8/ui-2";
import { IconContainer } from "src/modules/shared/components/IconContainer";
import { EditorCheckboxContainer, EditorTooltip } from "./HeaderSectionEditor.styles";

export const HeaderSectionEditor = ({
  section,
  onChanged,
  sourceData,
}: SectionEditorProps<HeaderSectionConfig>) => {
  const initialSectionState = React.useMemo(() => {
    const {
      showDaysOnMarket = true,
      showPriceRange = false,
      showClientName = true,
      showPresentationTitle = true,
    } = section;
    return {
      showDaysOnMarket,
      showPriceRange,
      showClientName,
      showPresentationTitle,
    };
  }, [section]);
  const estimatePriceForm: EstimatePriceFormModel = React.useMemo(() => {
    return {
      cmaId: sourceData.id,
      estimatedPriceMin: sourceData.priceRange?.min?.toString() || "",
      estimatedPriceMax: sourceData.priceRange?.max?.toString() || "",
    };
  }, [sourceData]);

  const [sectionState, setSectionState] = useState(initialSectionState);

  const formMethods = useForm<EstimatePriceFormModel>({
    defaultValues: { ...estimatePriceForm },
    mode: "all",
  });
  const { trigger, reset, getValues } = formMethods;

  React.useEffect(() => {
    reset({ ...estimatePriceForm });
  }, [reset, estimatePriceForm]);

  const saveEstimatePrice = async () => {
    const data = getValues();
    const isValid = await trigger();
    if (!isValid) return;

    const estimatePrice = convertEstimatePriceFormModelToEstimatedPriceDto(data);

    onChanged({
      sectionConfig: section,
      partialSourceData: {
        priceRange: {
          min: estimatePrice.min,
          max: estimatePrice.max,
        },
      },
    });
  };
  const handleSaveEstimatePrice = useDebounceFunction(saveEstimatePrice);

  const onChangeSwitch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const updatedModel = { ...sectionState, [name]: !(sectionState as any)[name] };
      setSectionState(updatedModel);
      onChanged({ sectionConfig: { ...section, ...updatedModel } });
    },
    [sectionState, setSectionState, onChanged, section]
  );

  return (
    <>
      <EditorCheckboxContainer>
        <LabeledCheckbox
          label="Client Name"
          onChange={onChangeSwitch}
          checked={sectionState.showClientName}
          name="showClientName"
        />
        <EditorTooltip title="You can edit this field in the header above.">
          <IconContainer>
            <InfoIcon />
          </IconContainer>
        </EditorTooltip>
      </EditorCheckboxContainer>
      <EditorCheckboxContainer>
        <LabeledCheckbox
          label="Presentation Title"
          onChange={onChangeSwitch}
          checked={sectionState.showPresentationTitle}
          name="showPresentationTitle"
        />
      </EditorCheckboxContainer>
      <LabeledCheckbox
        label="Price Range"
        onChange={onChangeSwitch}
        checked={sectionState.showPriceRange}
        name="showPriceRange"
      />
      <FormProvider {...formMethods}>
        <EstimateRangeForm handleSave={handleSaveEstimatePrice} />
      </FormProvider>
      <LabeledCheckbox
        label="Days On Market"
        onChange={onChangeSwitch}
        checked={sectionState.showDaysOnMarket}
        name="showDaysOnMarket"
      />
    </>
  );
};

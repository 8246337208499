import { propertyStatusesMap } from "../constants";

export type PropertyStatusesKey = keyof typeof propertyStatusesMap;

export const getStatusKeysByStatus = (
  status: typeof propertyStatusesMap[PropertyStatusesKey]["status"]
): PropertyStatusesKey[] => {
  const propertyMapKey = Object.entries(propertyStatusesMap)
    .filter(([_k, v]) => v.status === status)
    ?.map(([k, _v]) => Number(k)) as PropertyStatusesKey[];

  return propertyMapKey;
};

export const getStatusByListingStatus = (
  listingStatus: typeof propertyStatusesMap[PropertyStatusesKey]["rawStatus"]
) => {
  const propertyMapValue = Object.entries(propertyStatusesMap).find(
    ([_k, v]) => v.rawStatus === listingStatus
  )?.[1];

  return propertyMapValue?.status;
};

import { AgentAccounts } from "./components/AgentAccounts";
import * as React from "react";
import {
  AgentAccountsContextProvider,
  ActionInvokerContext,
} from "./components/AgentAccounts/AgentAccountsContext";
import { useBodyStyle } from "../shared/hooks/useBodyStyle";
import { Role, RouteModel } from "../../routes/routes";
import GenerateRoutes from "../../routes/GenerateRoutes";
import { useAuthContext } from "../shared/contexts/AuthContext/AuthContext";
import { useAppContext } from "../../AppContext";
import { EditAgentAccount } from "./components/EditAgentAccount/EditAgentAccount";
import { ThemeProvider, toast } from "@avenue-8/ui-2";
import { AgentAccountLogicProvider } from "./hooks/useAgentAccountLogic";
import { AppBlockingLoader } from "../shared/components/AppBlockingLoader";
import { AuthenticationReconciliator } from "../shared/contexts/AuthContext/AuthenticationReconciliator";
import { AuthenticatedRedirect } from "../shared/contexts/AuthContext/AuthenticatedRedirect";

export const renderAgentAccounts = () => {
  return <AgentAccounts />;
};

export const renderEditAccount = () => {
  return (
    <AgentAccountLogicProvider>
      <EditAgentAccount />
    </AgentAccountLogicProvider>
  );
};

const baseRoute = "/agent-account";
const routes: RouteModel[] = [
  {
    path: `${baseRoute}`,
    render: renderAgentAccounts,
    roles: [Role.Admin],
    exact: true,
  },
  {
    path: `${baseRoute}/edit`,
    render: renderEditAccount,
    roles: [Role.Agent],
    exact: true,
  },
];

export const AgentAccountModule = React.memo(() => {
  const {
    state: { user },
  } = useAuthContext();
  useBodyStyle({ background: "white" });
  const { actions: appActions } = useAppContext();

  // this way we allow a sub context to invoke the AppContext indirectly
  // we use a memo because this only needs to be build once. the [actions] never change
  const invokerContext: ActionInvokerContext = React.useMemo(() => {
    return {
      confirm: async ({ title, message, confirmButtonText }) => {
        return appActions.confirm({ message, title, confirmButtonText });
      },
      wrapLoadPromise: (promise) => {
        appActions.watchPromise(promise);
        return promise;
      },
      showToast: toast,
    };
  }, [appActions]);

  return (
    <>
      <AuthenticationReconciliator />
      <AuthenticatedRedirect>
        <AgentAccountsContextProvider invokerContext={invokerContext}>
          <ThemeProvider darkMode={false}>
            <AppBlockingLoader />
            <GenerateRoutes routes={routes} userRole={user?.role} />
          </ThemeProvider>
        </AgentAccountsContextProvider>
      </AuthenticatedRedirect>
    </>
  );
});

import * as React from "react";
import { scrollToExtended } from "src/modules/shared/utils/scroll-to-extended";

interface UseNavbarLogic {
  initialSectionId: string;
  offset?: number;
}

export const useNavbarLogic = (props: UseNavbarLogic) => {
  const { initialSectionId, offset = 120 } = props;
  const [watchScroll, setWatchScroll] = React.useState(true);
  const [sectionId, setSectionId] = React.useState(initialSectionId);
  const [backdropMenuOpen, setBackdropMenuOpen] = React.useState(false);

  const setSectionAndScroll = (id: any) => {
    const element = document.getElementById(id);
    if (element) {
      setWatchScroll(false);
      setSectionId(id);
      scrollToExtended(element, { offset }).then(() => {
        setWatchScroll(true);
      });
    }
  };

  const handleBackdropMenuSectionClick = async (e: any) => {
    const section = e.currentTarget?.dataset?.section;
    e.preventDefault();
    setBackdropMenuOpen(false);
    await new Promise((r) => setTimeout(r, 200));
    setSectionAndScroll(section);
  };

  return {
    watchScroll,
    setWatchScroll,
    sectionId,
    setSectionId,
    setSectionAndScroll,
    handleBackdropMenuSectionClick,
    backdropMenuOpen,
    setBackdropMenuOpen,
  };
};

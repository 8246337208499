import React from "react";
import { Controller, DeepMap, FieldError, FieldValues, useForm } from "react-hook-form";
import {
  Button,
  Typography,
  Grid,
  InputLabel,
  DialogTitle,
  DialogContent,
  styled,
} from "@avenue-8/ui-2";
import { SuccessIcon } from "../../../assets/icons/SuccessIcon";
import { simpleEmail } from "../../shared/utils/validation-patterns";
import { AuthDialog, ButtonContainer, Form, Wrapper } from "./style";
import { useAuthContext } from "../../shared/contexts/AuthContext/AuthContext";
import { TextFieldBase } from "../../shared/components/TextFieldBase";

interface ForgotPasswordFormModel {
  email: string;
}

interface Props {
  email?: string;
  onClose: () => void;
}

const FormItem = styled(Grid)`
  margin-bottom: 2rem;
`;

const CustomDialogTitle = styled(DialogTitle)`
  padding: 1.5rem 2rem;
`;

const CustomTypography = styled(Typography)`
  padding-bottom: 24px;
`;

const CustomButton = styled(Button)`
  margin-bottom: 24px;
`;

export const ForgotPassword = ({ email, onClose }: Props) => {
  const { control, handleSubmit, errors } = useForm();
  const { actions, state } = useAuthContext();

  async function onSubmit(data: ForgotPasswordFormModel) {
    await actions.sendResetPasswordEmail(data);
  }

  React.useEffect(() => {
    return () => {
      actions.resetForgotPasswordModalState();
    };
  }, [actions]);

  const isSubmitting = state.forgotPasswordState === "init";

  const handleErrors = (name: string, errors: DeepMap<FieldValues, FieldError>) => {
    return {
      error: !!errors[name],
      helperText: errors[name]?.message,
    };
  };

  const modal = (
    <AuthDialog open={true} fullWidth aria-labelledby="forgot-password-for-agent" onClose={onClose}>
      {state.forgotPasswordState === "success" ? (
        <DialogContent>
          <Wrapper>
            <SuccessIcon />
            <br />
            <br />
            <CustomTypography variant="h6">
              Please check your email to reset your password.
            </CustomTypography>
            <CustomButton fullWidth onClick={onClose}>
              Close
            </CustomButton>
          </Wrapper>
        </DialogContent>
      ) : (
        <>
          <CustomDialogTitle id="reset-password-for-agent">Forgot password?</CustomDialogTitle>
          <DialogContent>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormItem>
                <InputLabel htmlFor="email">E-mail</InputLabel>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={email ?? ""}
                  rules={{
                    required: true,
                    pattern: simpleEmail,
                  }}
                  render={(props) => (
                    <TextFieldBase
                      fullWidth
                      disabled={isSubmitting}
                      type="email"
                      {...handleErrors("email", errors)}
                      {...props}
                    />
                  )}
                />
              </FormItem>
              <ButtonContainer>
                <Button type="submit" fullWidth disabled={isSubmitting}>
                  {isSubmitting ? "Sending email..." : "Reset Password"}
                </Button>
                <Button variant="text" fullWidth onClick={onClose}>
                  Back
                </Button>
              </ButtonContainer>
            </Form>
          </DialogContent>
        </>
      )}
    </AuthDialog>
  );

  return <>{modal}</>;
};

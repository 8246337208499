/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AgentCreationParams,
    AgentCreationParamsFromJSON,
    AgentCreationParamsFromJSONTyped,
    AgentCreationParamsToJSON,
    UserCreationParams,
    UserCreationParamsFromJSON,
    UserCreationParamsFromJSONTyped,
    UserCreationParamsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateUserWithOptionalAgent
 */
export interface CreateUserWithOptionalAgent {
    /**
     * 
     * @type {UserCreationParams}
     * @memberof CreateUserWithOptionalAgent
     */
    user: UserCreationParams;
    /**
     * 
     * @type {AgentCreationParams}
     * @memberof CreateUserWithOptionalAgent
     */
    agent?: AgentCreationParams;
}

export function CreateUserWithOptionalAgentFromJSON(json: any): CreateUserWithOptionalAgent {
    return CreateUserWithOptionalAgentFromJSONTyped(json, false);
}

export function CreateUserWithOptionalAgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserWithOptionalAgent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': UserCreationParamsFromJSON(json['user']),
        'agent': !exists(json, 'agent') ? undefined : AgentCreationParamsFromJSON(json['agent']),
    };
}

export function CreateUserWithOptionalAgentToJSON(value?: CreateUserWithOptionalAgent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserCreationParamsToJSON(value.user),
        'agent': AgentCreationParamsToJSON(value.agent),
    };
}



import { rest } from "msw";
import {
  PropertyControllerListPropertyTypesRequest,
  ListPropertyTypeDto,
} from "src/modules/shared/apis/cma/generated";

export const propertiesPropertyTypeHandlers = [
  rest.get<PropertyControllerListPropertyTypesRequest, any, ListPropertyTypeDto>(
    `${process.env.REACT_APP_CMA_API_URL}/properties/property-type`,
    (req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          propertyTypes: [
            {
              id: 1,
              propertyType: "Condominium",
            },
            {
              id: 2,
              propertyType: "Rental",
            },
            {
              id: 3,
              propertyType: "Single Family Home",
            },
            {
              id: 4,
              propertyType: "Other",
            },
          ],
        })
      );
    }
  ),
];

const BASE_PATH = "/images/mls";

export const mlsList = [
  {
    name: "CLAW",
    logo: `${BASE_PATH}/claw.jpeg`,
  },
  {
    name: "San Francisco Association of REALTORS",
    logo: `${BASE_PATH}/san-francisco-association-of-realtors.jpeg`,
  },
  {
    name: "CRMLS",
    logo: `${BASE_PATH}/crmls.jpeg`,
  },
  {
    name: "bridgeMLS/CCAR/Bay East AOR",
    logo: `${BASE_PATH}/bridge-mls_ccar_bay-east-aor.jpeg`,
  },
  {
    name: "OneKey™ MLS",
    logo: `${BASE_PATH}/onekey-mls.jpeg`,
  },
  {
    name: "REBNY",
    logo: `${BASE_PATH}/rebny.png`,
  },
  {
    name: "BAREIS",
    logo: `${BASE_PATH}/bareis.jpeg`,
  },
  {
    name: "MLSListings Inc",
    logo: `${BASE_PATH}/mlslistings.jpeg`,
  },
];

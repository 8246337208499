import React, { Suspense } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router";
import { ToastContainer } from "react-toastify";
import { PresentationModule } from "./modules/presentation/PresentationModule";
import { AuthModule } from "./modules/auth/AuthModule";
import { AgentAccountModule } from "./modules/agent-account/AgentAccountModule";
import { Backdrop, CircularProgress, styled, toastStyles, ThemeProvider } from "@avenue-8/ui-2";
import { StyledEngineProvider } from "@mui/material";
import { AppConfirmDialog } from "./AppConfirmDialog";
import { useHeapIntegration } from "./modules/shared/useHeapIntegration";
import { initializeGTM } from "./gtm";
import { NotFound } from "./modules/shared/components/NotFound";
import { WarningPage } from "./modules/shared/components/WarningPage";
import { useFeatureToggleIdentity } from "./modules/shared/contexts/FeatureToggleContex/FeatureToggleContext";
import { CMAV2Module } from "./modules/cma-v2";
import {
  AuthContextProvider,
  useAuthContext,
} from "./modules/shared/contexts/AuthContext/AuthContext";
import { AgentOnboardingModule } from "./modules/agent-onboarding";
import { QueryClientProvider, QueryClient, focusManager } from "react-query";
import { authRoutes } from "./modules/auth/auth.routes";
import { onWindowFocus } from "./modules/shared/utils/on-windows-focus";
import { startMockServiceWorker } from "./mocks/start";
import { useDatadogEventTracking } from "./modules/shared/services/datadog-rum/datadog-rum";

// GLOBAL STYLE
import "./styles/index.css";
import "./modules/shared/fonts/styrenea.scss";
import "./modules/shared/fonts/bookmania.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-image-crop/dist/ReactCrop.css";
import "./styles/App.scss";
import "./styles/animation.css";
import "./styles/tinymce.css";

initializeGTM();

startMockServiceWorker();

focusManager.setEventListener(onWindowFocus);
const queryClient = new QueryClient();

const LazyComponentExplorer = React.lazy(
  () => import("./modules/component-explorer/ComponentExplorer")
);

const FeatureToggleIdentityBinder = () => {
  const { state } = useAuthContext();
  useFeatureToggleIdentity(state.user?.userId);
  return null;
};

const StyledToastContainer = styled.div`
  ${toastStyles}
`;

const App = () => {
  useHeapIntegration();
  useDatadogEventTracking();

  const history = useHistory();
  const authInvokerContext = {
    goToLoginPage: () => history.push(authRoutes.auth.route),
    removeQueriesCache: () => queryClient.removeQueries(),
  };

  const { contentRoutes } = React.useMemo(() => {
    const contentRoutes = (
      <Switch>
        <Route exact path="/">
          <Redirect to={authRoutes.auth.route} />
        </Route>
        <Route path="/login">
          <Redirect to={authRoutes.auth.route} />
        </Route>
        <Route path="/auth">
          <AuthModule />
        </Route>
        <Route path="/presentation">
          <PresentationModule />
        </Route>
        <Route path="/presentations">
          <CMAV2Module />
        </Route>
        <Route path="/agent-onboarding">
          <AgentOnboardingModule />
        </Route>
        <Route path="/invalid-link">
          <WarningPage />
        </Route>
        <Route path="/agent-account">
          <AgentAccountModule />
        </Route>
        {process.env.NODE_ENV !== "production" && (
          <Route path="/component-explorer">
            <Suspense
              fallback={
                <Backdrop style={{ color: "white" }} open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              }
            >
              <LazyComponentExplorer />
            </Suspense>
          </Route>
        )}
        <Route path="*" exact={true} component={NotFound} />
      </Switch>
    );
    return { contentRoutes };
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <AuthContextProvider invokerContext={authInvokerContext}>
        <QueryClientProvider client={queryClient}>{contentRoutes}</QueryClientProvider>
        <FeatureToggleIdentityBinder />
        <AppConfirmDialog />
        <ThemeProvider>
          <StyledToastContainer>
            <ToastContainer position="top-center" closeButton={false} />
          </StyledToastContainer>
        </ThemeProvider>
      </AuthContextProvider>
    </StyledEngineProvider>
  );
};

export default App;

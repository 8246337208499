import { differenceInDays } from 'date-fns';
export const getDaysOnMarket = (
  listDate?: Date | null,
  soldDate?: Date | null,
) => {
  return listDate
    ? soldDate
      ? differenceInDays(new Date(soldDate), new Date(listDate))
      : differenceInDays(new Date(), new Date(listDate))
    : 0;
};

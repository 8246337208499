import { summaryStatisticsDimensionsDictionary, summaryStatisticsFieldsDictionary } from '../../../../shared/constants';
import { ByDivider, CustomSelect, Form } from "./SummaryStatisticsFilter.styles";
import { SelectOption } from "@avenue-8/ui-2";
import { Dimension, Field } from '../calculate-summary-statistics';

interface SummaryStatisticsFilterProps {
  dimension: Dimension;
  field: Field;
  onChange: (data: { dimension: Dimension, field: Field }) => void;
}

export const SummaryStatisticsFilter = ({
  dimension,
  field,
  onChange,
}: SummaryStatisticsFilterProps) => {
  return (
    <Form>
      <CustomSelect
        labelId="Field"
        id="field"
        value={field}
        onChange={(e) => onChange({ field: e.target.value as any, dimension })}
      >
        {Object.keys(summaryStatisticsFieldsDictionary).map((field) => {
          return (
            <SelectOption value={field} key={field}>
              {summaryStatisticsFieldsDictionary[field]}
            </SelectOption>
          );
        })}
      </CustomSelect>
      <ByDivider variant="overline">BY</ByDivider>
      <CustomSelect
        labelId="Dimension"
        id="dimensions"
        value={dimension}
        onChange={(e) => onChange({ dimension: e.target.value as any, field })}
      >
        {Object.keys(summaryStatisticsDimensionsDictionary).map((dimension) => {
          return (
            <SelectOption value={dimension} key={dimension}>
              {summaryStatisticsDimensionsDictionary[dimension]}
            </SelectOption>
          );
        })}
      </CustomSelect>
    </Form>
  );
};

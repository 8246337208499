/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OnboardingData
 */
export interface OnboardingData {
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    preferredContactEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    licenseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    brokerage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    team?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OnboardingData
     */
    headshotUrl?: string | null;
}

export function OnboardingDataFromJSON(json: any): OnboardingData {
    return OnboardingDataFromJSONTyped(json, false);
}

export function OnboardingDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardingData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'preferredContactEmail': !exists(json, 'preferredContactEmail') ? undefined : json['preferredContactEmail'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'licenseNumber': !exists(json, 'licenseNumber') ? undefined : json['licenseNumber'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'brokerage': !exists(json, 'brokerage') ? undefined : json['brokerage'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'headshotUrl': !exists(json, 'headshotUrl') ? undefined : json['headshotUrl'],
    };
}

export function OnboardingDataToJSON(value?: OnboardingData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'preferredContactEmail': value.preferredContactEmail,
        'phoneNumber': value.phoneNumber,
        'licenseNumber': value.licenseNumber,
        'state': value.state,
        'brokerage': value.brokerage,
        'team': value.team,
        'headshotUrl': value.headshotUrl,
    };
}



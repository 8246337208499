import { styled } from "@avenue-8/ui-2";

export const SwitchContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;
export const SwitchLabel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { getCMAApiClient } from "../../shared/apis/cma/api-factories";
import { SubjectProperty } from "../../shared/apis/cma/generated";
import { useCreateCMALogic } from "./useCreateCMALogic";
import { useAppContext } from "../../../AppContext";
import { toast } from "react-toastify";
import { draftManager } from "../../shared/services/draft-manager";
import {
  convertCmaToSubjectPropertyStepModel,
  convertSubjectPropertyStepModelToSubjectProperty,
  SubjectPropertyStepModel,
  SUBJECT_PROPERTY_FORM_DEFAULT_STATE,
} from "../components/CreateCMA/subject-property";
import { appEventEmitter } from "../../../events/app-event-emitter";
import { buildPropertyKey } from "../../shared/utils/buildPropertyKey";

const draftFormKey = "subjectProperty";

export const useSubjectPropertyStepLogic = () => {
  const { actions: appActions } = useAppContext();
  const {
    state: { cma },
  } = useCreateCMALogic();
  const queryClient = useQueryClient();

  const { mutateAsync: updateCmaSubjectProperty, status: updateCmaSubjectPropertyStatus } =
    useMutation(
      async ({
        id,
        subjectProperty,
      }: {
        id: string;
        subjectProperty: Partial<SubjectProperty>;
      }) => {
        return await appActions.watchPromise(
          getCMAApiClient().cMAControllerPatch({
            id,
            patchCMARequestDto: { subjectProperty },
          }),
          { blocking: true, message: "Saving Subject Property details" }
        );
      },
      {
        onError: (error) => {
          console.error(error);
          toast.error("Failed to update the subject property information.");
        },
        onSuccess: () => {
          appEventEmitter.emit({ eventType: "cma-subject-property-saved" });
          queryClient.invalidateQueries("cmas");
          if (cma) draftManager.deleteDraft({ formKey: draftFormKey, entityKey: cma.id });
        },
      }
    );

  const importSubjectPropertyMutation = useMutation(
    async (params: { mlsSource: string; mlsId: string; cmaId: string }) => {
      return await appActions.watchPromise(
        getCMAApiClient().cMAControllerUpsert({
          upsertCMASubjectPropertyByMlsIdRequestDto: {
            id: params.cmaId,
            mlsId: buildPropertyKey(params),
          },
        }),
        {
          blocking: true,
          message: "Importing property...",
        }
      );
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to import subject property.");
      },
      onSuccess: () => {
        appEventEmitter.emit({ eventType: "cma-subject-property-mls-data-imported" });
        if (cma) {
          draftManager.deleteDraft({ formKey: draftFormKey, entityKey: cma.id });
          queryClient.invalidateQueries(["cmas", cma.id]);
        }
        toast.success("Subject property imported successfully.");
      },
    }
  );

  const { mutateAsync: uploadSubjectPropertyPhotos, status: uploadSubjectPropertyPhotosStatus } =
    useMutation(
      async (data: FormData) => {
        await updateCmaWithCurrentDraft();
        return await appActions.watchPromise(
          getCMAApiClient().cMAControllerAddSubjectPropertyPhoto({
            id: cma!.id,
            images: data.getAll("images") as Blob[],
          }),
          {
            blocking: true,
            message: "Uploading photos...",
          }
        );
      },
      {
        onError: (error) => {
          console.error(error);
          toast.error("Failed to upload files.");
        },
        onSuccess: () => {
          appEventEmitter.emit({ eventType: "cma-subject-property-image-added" });
          if (cma) {
            queryClient.invalidateQueries(["cmas", cma.id]);
          }
          toast.success("Photos uploaded succesfully.");
        },
      }
    );

  const { mutateAsync: removeSubjectPropertyPhoto, status: removeSubjectPropertyPhotoStatus } =
    useMutation(
      async ({ photoId, cmaId }: { photoId: string; cmaId: string }) => {
        await updateCmaWithCurrentDraft();
        return await appActions.watchPromise(
          getCMAApiClient().cMAControllerRemoveSubjectPropertyPhoto({
            id: cmaId,
            imgId: Number(photoId),
          }),
          {
            blocking: true,
            message: "Removing photo...",
          }
        );
      },
      {
        onError: (error) => {
          console.error(error);
          toast.error("Failed to remove file.");
        },
        onSuccess: () => {
          appEventEmitter.emit({ eventType: "cma-subject-property-image-removed" });
          if (cma) {
            queryClient.invalidateQueries(["cmas", cma.id]);
          }
          toast.success("Photo removed succesfully.");
        },
      }
    );

  const { mutateAsync: makeCoverPhoto } = useMutation(
    async ({ photoId }: { photoId: string }) => {
      await updateCmaWithCurrentDraft();
      return await appActions.watchPromise(
        getCMAApiClient().cMAControllerSelectCoverImage({
          id: cma!.id,
          selectCMACoverImageDto: { properyImageId: parseInt(photoId) },
        }),
        {
          blocking: true,
          message: "Changing cover photo...",
        }
      );
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to change cover photo.");
      },
      onSuccess: () => {
        appEventEmitter.emit({ eventType: "cma-subject-property-cover-image-selected" });
        if (cma) {
          queryClient.invalidateQueries(["cmas", cma.id]);
        }
        toast.success("Cover photo changed succesfully.");
      },
    }
  );

  const subjectPropertyForm: SubjectPropertyStepModel = React.useMemo(() => {
    const form = cma
      ? convertCmaToSubjectPropertyStepModel(cma)
      : { ...SUBJECT_PROPERTY_FORM_DEFAULT_STATE };
    const draft = draftManager.loadDraft({ formKey: draftFormKey, entityKey: cma?.id });
    if (draft == null) {
      return form;
    }
    //check for outated drafts
    if (draft?.updateId !== (cma?.updateDate?.toISOString() || "new")) {
      draftManager.deleteDraft({ formKey: draftFormKey, entityKey: cma?.id });
      return form;
    }
    return { ...draft.value };
  }, [cma]);

  const updateSubjectPropertyFormDraft = React.useCallback(
    (data: any) => {
      draftManager.storeDraft({ formKey: draftFormKey, entityKey: cma?.id }, data, {
        updateId: cma?.updateDate?.toISOString() || "new",
      });
    },
    [cma?.id, cma?.updateDate]
  );

  const discardSubjectPropertyFormDraft = React.useCallback(() => {
    draftManager.deleteDraft({ formKey: draftFormKey, entityKey: cma?.id });
  }, [cma]);

  const getSubjectPropertyFormDraft = React.useCallback(() => {
    return draftManager.loadDraft({ formKey: draftFormKey, entityKey: cma?.id });
  }, [cma]);

  const updateCmaWithCurrentDraft = React.useCallback(async () => {
    const draft = getSubjectPropertyFormDraft();
    draft &&
      (await updateCmaSubjectProperty({
        id: cma!.id,
        subjectProperty: convertSubjectPropertyStepModelToSubjectProperty(draft.value),
      }));
  }, [cma, getSubjectPropertyFormDraft, updateCmaSubjectProperty]);

  return {
    importSubjectPropertyMutation,
    state: {
      subjectPropertyForm,
      updateCmaSubjectPropertyStatus,
      uploadSubjectPropertyPhotos: {
        status: uploadSubjectPropertyPhotosStatus,
      },
      removeSubjectPropertyPhoto: {
        status: removeSubjectPropertyPhotoStatus,
      },
    },
    actions: {
      discardSubjectPropertyFormDraft,
      makeCoverPhoto,
      removeSubjectPropertyPhoto,
      updateCmaSubjectProperty,
      updateSubjectPropertyFormDraft,
      uploadSubjectPropertyPhotos,
      getSubjectPropertyFormDraft,
    },
  };
};

import { SummaryStatisticsTable } from "./SummaryStatisticsTable";
import { SummaryStatisticsFilter } from "./SummaryStatisticsFilter";
import React from "react";
import { calculateSummaryStatistics, Dimension, Field } from ".";
import { useCreateCMALogic } from "../../hooks/useCreateCMALogic";

export const SummaryStatistics = () => {
  const {
    state: { cma },
  } = useCreateCMALogic();

  const [filter, setFilter] = React.useState<{ field: Field; dimension: Dimension }>({
    field: "soldPrice",
    dimension: "neighborhood",
  });

  return React.useMemo(() => {
    const dataSet = calculateSummaryStatistics({
      properties: cma?.comparableProperties ?? [],
      field: filter.field as any,
      dimension: filter.dimension as any,
    });
    return (
      <SummaryStatisticsTable
        key={`${filter.field}-${filter.dimension}`}
        metricAndDimensionNode={<SummaryStatisticsFilter {...filter} onChange={setFilter} />}
        field={filter.field as any}
        dimension={filter.dimension as any}
        dataSet={dataSet}
      />
    );
  }, [cma, filter]);
};

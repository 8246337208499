import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled } from "@avenue-8/ui-2";

export const StyledModal = styled(Dialog)`
  width: 64rem;
  margin: 0 auto;
`;
export const ModalTitle = styled(DialogTitle)`
  font-size: ${(p) => p.theme.av8.typography.headers.size};
  padding: 2rem 2rem 0rem 2rem;
`;
export const ModalContent = styled(DialogContent)`
  height: calc(100vh - 4.75rem);
  padding: 1rem 2rem;
  position: relative;
`;
export const Actions = styled(DialogActions)`
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  padding-top: 1rem;
`;
export const StyledButton = styled(Button)`
  line-height: 1.5rem;
  height: 3rem;
  display: flex;
  align-items: center;
  margin: 0 !important;
  & > span {
    gap: 0.5rem;
  }
`;
export const CloseButton = styled(Button)`
  position: absolute;
  right: 2rem;
  top: 2rem;
  padding: 0;
  min-width: unset;
`;

import { styled } from "@avenue-8/ui-2";

export const defaultTextEditorContentStyle = `
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Canela";
    font-weight: normal;
    margin-top: 0;
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2rem;
  }
  p {
    color: #73738C;
    font-family: Roboto;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 150%;
    margin: 0;
  }
  ul, ol {
    padding-left: 1rem;
    color: #131319;
  }
  .text-editor-body {
    width: 100%;
    max-width: 520px;
    margin: auto;
    padding: 2rem 0;
  }
`;

export const EditorWrapper = styled.div`
  height: 100%;
  width: 100%;

  ${() => defaultTextEditorContentStyle}
`;

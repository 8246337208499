/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertySuggestionDto
 */
export interface PropertySuggestionDto {
    /**
     * The suggested text
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    mlsId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    mlsSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    county?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    addressLine1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    addressLine2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    area?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    neighborhood?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PropertySuggestionDto
     */
    zipCode?: string | null;
}

export function PropertySuggestionDtoFromJSON(json: any): PropertySuggestionDto {
    return PropertySuggestionDtoFromJSONTyped(json, false);
}

export function PropertySuggestionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertySuggestionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'text': json['text'],
        'mlsId': !exists(json, 'mlsId') ? undefined : json['mlsId'],
        'mlsSource': !exists(json, 'mlsSource') ? undefined : json['mlsSource'],
        'county': !exists(json, 'county') ? undefined : json['county'],
        'addressLine1': !exists(json, 'addressLine1') ? undefined : json['addressLine1'],
        'addressLine2': !exists(json, 'addressLine2') ? undefined : json['addressLine2'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'neighborhood': !exists(json, 'neighborhood') ? undefined : json['neighborhood'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
    };
}

export function PropertySuggestionDtoToJSON(value?: PropertySuggestionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'text': value.text,
        'mlsId': value.mlsId,
        'mlsSource': value.mlsSource,
        'county': value.county,
        'addressLine1': value.addressLine1,
        'addressLine2': value.addressLine2,
        'area': value.area,
        'neighborhood': value.neighborhood,
        'city': value.city,
        'state': value.state,
        'zipCode': value.zipCode,
    };
}



import * as React from "react";
import {
  Checkbox,
  FormControlLabel,
  Typography,
  CheckboxIcon,
  styled,
  useTheme,
} from "@avenue-8/ui-2";

export const Label = styled(Typography)`
  color: ${(p) => p.theme.av8.primaryColor};
  font-size: 14px;
  font-weight: 400;
`;

const CustomCheckbox = styled(Checkbox)`
  padding: 0;
  margin-right: 0.5rem;
`;

export type LabeledCheckboxFieldModel<T> = {
  label: string;
  name: T;
  checked: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const LabeledCheckbox = React.memo(
  ({ label, checked, onChange, name }: LabeledCheckboxFieldModel<string>) => {
    const theme = useTheme();
    return (
      <FormControlLabel
        control={
          <CustomCheckbox
            checked={checked}
            onChange={onChange}
            name={name}
            checkedIcon={
              <CheckboxIcon color={theme.av8.primaryColor} backgroundColor="transparent" />
            }
            icon={<CheckboxIcon color="transparent" backgroundColor="transparent" />}
          />
        }
        label={<Label>{label}</Label>}
      />
    );
  }
);

import { useMediaQuery } from "@mui/material";
import { media } from "../../shared/css-snippets";

export function useBreakpoint(key: keyof typeof media) {
  return useMediaQuery(media[key]);
}

export const useMobile = () => useBreakpoint("mobileDown");

export const useDisplayMode = () => {
  const mobile = useBreakpoint("mobileDown");
  const tablet = useBreakpoint("tabletDown");
  return mobile ? "mobile" : tablet ? "tablet" : "desktop";
};

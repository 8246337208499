import { toast } from "@avenue-8/ui-2";
import { useMutation, useQueryClient } from "react-query";
import { appEventEmitter } from "src/events/app-event-emitter";
import { getCMAApiClient } from "src/modules/shared/apis/cma/api-factories";

export const useRemoveCoverPhotoMutation = () => {
  const queryClient = useQueryClient();
  const changeCoverPhotoMutation = useMutation(
    async (params: { id: string }) => {
      await getCMAApiClient().cMAControllerDeleteCoverPhoto(params);
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to remove cover photo.");
      },
      onSuccess: (_data, variables) => {
        queryClient.invalidateQueries(["cmas", variables.id]);
        appEventEmitter.emit({
          eventType: "general-presentation-cover-image-removed",
        });
        toast.success("Cover photo removed succesfully.");
      },
    }
  );
  return changeCoverPhotoMutation;
};

import { rest } from "msw";
import { CMAControllerGetRequest, CMADto } from "src/modules/shared/apis/cma/generated";

export const cmaGetHandlers = [
  rest.get<CMAControllerGetRequest, CMAControllerGetRequest, CMADto>(
    `${process.env.REACT_APP_CMA_API_URL}/cmas/:id`,
    (req, res, ctx) => {
      const { id } = req.params;
      return res(
        ctx.status(200),
        ctx.json({
          id,
          actionedDate: new Date(),
          title: "Some title",
          presentationType: "cma",
          clientDisplayName: "Client Name",
          agent: {
            id: 1,
            name: "Agent",
            title: "Super Agent",
            email: "agent@007.com",
            headshotUrl: "",
            license: "923874",
            phone: "888-8888",
            sourceId: "system",
            state: "AL",
          },
          canCustomize: false,
          canPublish: false,
          comparableProperties: [],
          estimatedPriceMin: 1000000,
          estimatedPriceMax: 1200000,
          hasUnpublishedChanges: false,
          listDate: new Date(),
          requestedDate: new Date(),
          status: "Active",
          isArchived: false,
          subjectProperty: {
            addressLine1: "Address Line 1",
            addressLine2: "Address Line 2",
            area: "area",
            baths: 1,
            beds: 1,
            buildingName: "Awesome Building",
            city: "San Francisco",
            garageSpaces: 1,
            hoaFees: 12,
            listDate: new Date(),
            listPrice: 1000000,
            soldPrice: 900000,
            id: "1",
            images: [
              {
                id: "1",
                url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                caption: "",
              },
              {
                id: "2",
                url: "https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                caption: "",
              },
              {
                id: "3",
                url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
                caption: "",
              },
            ],
            latitude: 1,
            livingSpace: 800,
            longitude: 1,
            lotSize: 300,
            mlsId: "12343",
            mlsSource: "no-source",
            neighborhood: "neighborhood",
            nonGarageSpaces: 0,
            soldDate: new Date(),
            state: "NY",
            propertyStyle: "rococo",
            propertyType: "Single Family Home",
            yearBuilt: 1985,
            zipCode: "39042",
            description: "Description",
            hasDoormanAttendance: false,
            listingAgent: {
              brokerage: "Brokerage",
              dre: "123456",
              lastSourceUpdateDate: new Date(),
              name: "John Doe",
            },
          },
          updateDate: new Date(),
        })
      );
    }
  ),
];

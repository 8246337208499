/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DateRangeFilter,
    DateRangeFilterFromJSON,
    DateRangeFilterFromJSONTyped,
    DateRangeFilterToJSON,
    MlsFilter,
    MlsFilterFromJSON,
    MlsFilterFromJSONTyped,
    MlsFilterToJSON,
} from './';

/**
 * 
 * @export
 * @interface AggregationFilters
 */
export interface AggregationFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof AggregationFilters
     */
    cities?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AggregationFilters
     */
    neighborhoods?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregationFilters
     */
    propertyTypes?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof AggregationFilters
     */
    listingStatus?: Array<number>;
    /**
     * 
     * @type {DateRangeFilter}
     * @memberof AggregationFilters
     */
    soldDate?: DateRangeFilter;
    /**
     * 
     * @type {Array<MlsFilter>}
     * @memberof AggregationFilters
     */
    mlsIds?: Array<MlsFilter>;
}

export function AggregationFiltersFromJSON(json: any): AggregationFilters {
    return AggregationFiltersFromJSONTyped(json, false);
}

export function AggregationFiltersFromJSONTyped(json: any, ignoreDiscriminator: boolean): AggregationFilters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cities': !exists(json, 'cities') ? undefined : json['cities'],
        'neighborhoods': !exists(json, 'neighborhoods') ? undefined : json['neighborhoods'],
        'propertyTypes': !exists(json, 'propertyTypes') ? undefined : json['propertyTypes'],
        'listingStatus': !exists(json, 'listingStatus') ? undefined : json['listingStatus'],
        'soldDate': !exists(json, 'soldDate') ? undefined : DateRangeFilterFromJSON(json['soldDate']),
        'mlsIds': !exists(json, 'mlsIds') ? undefined : ((json['mlsIds'] as Array<any>).map(MlsFilterFromJSON)),
    };
}

export function AggregationFiltersToJSON(value?: AggregationFilters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cities': value.cities,
        'neighborhoods': value.neighborhoods,
        'propertyTypes': value.propertyTypes,
        'listingStatus': value.listingStatus,
        'soldDate': DateRangeFilterToJSON(value.soldDate),
        'mlsIds': value.mlsIds === undefined ? undefined : ((value.mlsIds as Array<any>).map(MlsFilterToJSON)),
    };
}



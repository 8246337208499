import Joyride, { Step, CallBackProps } from "react-joyride";
import React from "react";
import { GuidedTourTooltip } from "src/modules/shared/components/GuidedTour/GuidedTourTooltip";
import {
  guidedTourStorageService,
  IGuidedTour,
} from "src/modules/shared/services/guided-tour/guided-tour-service";
import {
  GuidedTourLogicProvider,
  useGuidedTourLogic,
} from "src/modules/shared/components/GuidedTour/useGuidedTourLogic";
import { appEventEmitter } from "src/events/app-event-emitter";

interface Props {
  steps: Step[];
  guidedTourSlug: keyof IGuidedTour;
  runTimeout?: number;
}

const GuidedTourInner = (props: Props) => {
  const { steps, guidedTourSlug, runTimeout = 0 } = props;
  const [run, setRun] = React.useState(false);
  const guidedTour = guidedTourStorageService.getGuidedTour(guidedTourSlug);
  const {
    state: { dontShowAgain },
  } = useGuidedTourLogic();

  React.useEffect(() => {
    setTimeout(() => {
      setRun(true);
    }, runTimeout);
  }, [runTimeout]);

  const handleCallback = (state: CallBackProps) => {
    if (state.action === "reset" && dontShowAgain) {
      guidedTourStorageService.setGuidedTour(guidedTourSlug, {
        ...guidedTour,
        dontShowAgain,
      });
    }
    if (state.index === steps.length - 1) {
      guidedTourStorageService.setGuidedTour(guidedTourSlug, {
        ...guidedTour,
        completed: true,
      });
    }
    handleEvents(state);
  };

  const handleEvents = (state: CallBackProps) => {
    const handleDontShowAgainEvent = () => {
      appEventEmitter.emit({
        eventType: "guided-tour-dont-show-again",
        tourName: guidedTourSlug,
      });
    };

    // START
    if (state.action === "start" && state.lifecycle === "init") {
      appEventEmitter.emit({
        eventType: "guided-tour-started",
        tourName: guidedTourSlug,
      });
    }

    // NEXT and PREV
    if (["prev", "next"].includes(state.action) && state.lifecycle === "ready") {
      appEventEmitter.emit({
        eventType: "guided-tour-updated",
        tourName: guidedTourSlug,
        action: state.action,
        step: state.index + 1,
        size: state.size,
      });
    }

    // SKIP
    if (state.action === "skip" && state.lifecycle === "complete") {
      appEventEmitter.emit({
        eventType: "guided-tour-skipped",
        tourName: guidedTourSlug,
        step: state.index + 1,
        size: state.size,
      });
      dontShowAgain && handleDontShowAgainEvent();
    }

    // FINISH
    if (state.status === "finished" && state.lifecycle === "complete") {
      appEventEmitter.emit({
        eventType: "guided-tour-finished",
        tourName: guidedTourSlug,
      });
      dontShowAgain && handleDontShowAgainEvent();
    }
  };

  if (guidedTour.dontShowAgain) {
    return null;
  }

  return (
    <Joyride
      steps={steps}
      continuous
      run={run}
      callback={handleCallback}
      tooltipComponent={GuidedTourTooltip}
      showProgress
      disableScrolling
      disableOverlayClose
      showSkipButton={false}
    />
  );
};

export const GuidedTour = (props: Props) => {
  return (
    <GuidedTourLogicProvider>
      <GuidedTourInner {...props} />
    </GuidedTourLogicProvider>
  );
};

import { Grid, Typography, ImageUnavailable, styled } from "@avenue-8/ui-2";
import { PropertyDto } from "../../../../../../shared/apis/cma/generated";
import { listingStatusColor } from "../../../../../../shared/css-snippets";

interface Props {
  property: PropertyDto;
}

const PropertyCardContainer = styled.div`
  margin: 0 auto 24px;
  display: flex;
`;

const PropertyImage = styled.div`
  width: 172px;
  min-height: 112px;
  max-height: 112px;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;

  & img {
    object-fit: cover;
    width: 100%;
    object-position: center;
    border-radius: 4px;
  }

  & div {
    width: 100%;
    height: 100%;
  }
`;

const PropertyResultCardContainer = styled(Grid)`
  border-top: 1px solid ${(p) => p.theme.av8.dividerColor};
  padding-top: 23px;
  align-items: flex-start;
`;

const ImageContainer = styled.div`
  min-width: 172px;
  margin-right: 24px;
`;

const PropertyCardText = styled(Typography)`
  font-size: 12px;
  line-height: 100%;
`;

const PropertyItemLabel = styled(Typography)`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  color: ${(p) => p.theme.av8.secondaryColor};
  margin-bottom: 10px;
  line-height: 100%;
`;

const PropertyItemStrongLabel = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 8px;
  line-height: 100%;
  max-lines: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const PropertyListingStatus = styled(Typography)<{ $listingStatus: string | undefined }>`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;

  ${(p) => listingStatusColor(p.$listingStatus)}
`;

const PropertyGroupInfo = styled(Grid)`
  &:not(:first-of-type):not(:last-child) {
    padding: 0 16px;
  }
  min-height: 80px;
`;

const FlexPropertyGroupInfo = styled(PropertyGroupInfo)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PropertyInfo = styled.div`
  &:not(:first-of-type) {
    margin-top: 16px;
  }
`;

const PropertyInfoWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const getRoundedBaths = (baths: number) => {
  return Math.round(baths) === Number(baths) ? Math.round(baths) : baths;
};

const SubjectPropertySummary = ({ property }: Props) => {
  const coverImage = property.images.length ? property.images[0] : null;

  return (
    <PropertyCardContainer>
      <ImageContainer>
        <PropertyImage>
          {coverImage ? (
            <img src={coverImage?.cdnUrl || coverImage?.url} alt={coverImage.caption} />
          ) : (
            <ImageUnavailable />
          )}
        </PropertyImage>
      </ImageContainer>

      <PropertyResultCardContainer container>
        <FlexPropertyGroupInfo item md={5}>
          <PropertyInfo>
            <PropertyItemStrongLabel variant="body2" color="primary">
              {property?.addressLine1 ?? "-"}
              {property?.addressLine2 ? <>{`, Unit ${property?.addressLine2}`}</> : null}{" "}
            </PropertyItemStrongLabel>

            <PropertyCardText variant="body2" color="primary">
              {property?.city ? <>{property.city + ", " + property?.state + " "}</> : ""}
              {property?.zipCode ?? ""}
            </PropertyCardText>
          </PropertyInfo>

          <PropertyInfo>
            <PropertyInfoWrapper>
              <PropertyCardText variant="body2" color="primary">
                {property.soldPrice
                  ? `$${property.soldPrice.toLocaleString("en-US")}`
                  : property.listPrice
                  ? `$${property.listPrice.toLocaleString("en-US")}`
                  : "-"}
              </PropertyCardText>
              <PropertyCardText variant="body2">
                {property.beds ?? 0}BD
                <>&nbsp; | &nbsp;</>
                {getRoundedBaths(property.baths ?? 0)}BA
              </PropertyCardText>
            </PropertyInfoWrapper>
          </PropertyInfo>
        </FlexPropertyGroupInfo>

        <PropertyGroupInfo item md={5}>
          <PropertyInfo>
            <PropertyItemLabel variant="body2">Property Type</PropertyItemLabel>
            <PropertyCardText variant="body2" color="primary">
              {property.propertyType ?? "-"}
            </PropertyCardText>
          </PropertyInfo>

          <PropertyInfo>
            <PropertyItemLabel variant="body2">Neighborhood</PropertyItemLabel>
            <PropertyCardText variant="body2" color="primary">
              {property.neighborhood ?? "-"}
            </PropertyCardText>
          </PropertyInfo>
        </PropertyGroupInfo>

        <PropertyGroupInfo item md={2}>
          <PropertyInfo>
            <PropertyItemLabel variant="body2">Sq. Ft.</PropertyItemLabel>
            <PropertyCardText variant="body2" color="primary">
              {property.livingSpace ? `${property.livingSpace.toLocaleString("en-US")} sq ft` : "-"}
            </PropertyCardText>
          </PropertyInfo>
          <PropertyInfo>
            <PropertyItemLabel variant="body2">Status</PropertyItemLabel>
            <PropertyListingStatus variant="body2" $listingStatus={property.listingStatus}>
              {property.listingStatus ?? "-"}
            </PropertyListingStatus>
          </PropertyInfo>
        </PropertyGroupInfo>
      </PropertyResultCardContainer>
    </PropertyCardContainer>
  );
};

export default SubjectPropertySummary;

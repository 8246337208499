/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateUserWithOptionalAgent,
    CreateUserWithOptionalAgentFromJSON,
    CreateUserWithOptionalAgentToJSON,
    ListUsersResponse,
    ListUsersResponseFromJSON,
    ListUsersResponseToJSON,
    UserAndAgentResponse,
    UserAndAgentResponseFromJSON,
    UserAndAgentResponseToJSON,
    UserAndOptionalAgentResponse,
    UserAndOptionalAgentResponseFromJSON,
    UserAndOptionalAgentResponseToJSON,
    UserResetPasswordParams,
    UserResetPasswordParamsFromJSON,
    UserResetPasswordParamsToJSON,
    UserStartResetPasswordParams,
    UserStartResetPasswordParamsFromJSON,
    UserStartResetPasswordParamsToJSON,
} from '../models';

export interface UserControllerCreateUserWithOptionalAgentRequest {
    createUserWithOptionalAgent: CreateUserWithOptionalAgent;
}

export interface UserControllerDeleteUserRequest {
    id: number;
}

export interface UserControllerGetUserWithAgentRequest {
    id: string;
}

export interface UserControllerListUsersRequest {
    page: number;
    limit: number;
    role?: string;
}

export interface UserControllerResetPasswordRequest {
    userResetPasswordParams: UserResetPasswordParams;
}

export interface UserControllerStartResetPasswordRequest {
    userStartResetPasswordParams: UserStartResetPasswordParams;
}

export interface UserControllerUpdateUserWithOptionalAgentRequest {
    id: number;
    createUserWithOptionalAgent: CreateUserWithOptionalAgent;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * Creates a user. If an agent dto is passed, it also creates an associated agent
     */
    async userControllerCreateUserWithOptionalAgentRaw(requestParameters: UserControllerCreateUserWithOptionalAgentRequest): Promise<runtime.ApiResponse<UserAndOptionalAgentResponse>> {
        if (requestParameters.createUserWithOptionalAgent === null || requestParameters.createUserWithOptionalAgent === undefined) {
            throw new runtime.RequiredError('createUserWithOptionalAgent','Required parameter requestParameters.createUserWithOptionalAgent was null or undefined when calling userControllerCreateUserWithOptionalAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserWithOptionalAgentToJSON(requestParameters.createUserWithOptionalAgent),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAndOptionalAgentResponseFromJSON(jsonValue));
    }

    /**
     * Creates a user. If an agent dto is passed, it also creates an associated agent
     */
    async userControllerCreateUserWithOptionalAgent(requestParameters: UserControllerCreateUserWithOptionalAgentRequest): Promise<UserAndOptionalAgentResponse> {
        const response = await this.userControllerCreateUserWithOptionalAgentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete single user. Temp version. May not work indefinetely. For testing purposes only.
     */
    async userControllerDeleteUserRaw(requestParameters: UserControllerDeleteUserRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userControllerDeleteUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Delete single user. Temp version. May not work indefinetely. For testing purposes only.
     */
    async userControllerDeleteUser(requestParameters: UserControllerDeleteUserRequest): Promise<boolean> {
        const response = await this.userControllerDeleteUserRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a single user with its associated agent
     */
    async userControllerGetUserWithAgentRaw(requestParameters: UserControllerGetUserWithAgentRequest): Promise<runtime.ApiResponse<UserAndAgentResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userControllerGetUserWithAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAndAgentResponseFromJSON(jsonValue));
    }

    /**
     * Returns a single user with its associated agent
     */
    async userControllerGetUserWithAgent(requestParameters: UserControllerGetUserWithAgentRequest): Promise<UserAndAgentResponse> {
        const response = await this.userControllerGetUserWithAgentRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a paginated list of all internal users
     */
    async userControllerListUsersRaw(requestParameters: UserControllerListUsersRequest): Promise<runtime.ApiResponse<ListUsersResponse>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling userControllerListUsers.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling userControllerListUsers.');
        }

        const queryParameters: any = {};

        if (requestParameters.role !== undefined) {
            queryParameters['role'] = requestParameters.role;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListUsersResponseFromJSON(jsonValue));
    }

    /**
     * Returns a paginated list of all internal users
     */
    async userControllerListUsers(requestParameters: UserControllerListUsersRequest): Promise<ListUsersResponse> {
        const response = await this.userControllerListUsersRaw(requestParameters);
        return await response.value();
    }

    /**
     * Allows users to reset password using an emailed token.
     */
    async userControllerResetPasswordRaw(requestParameters: UserControllerResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userResetPasswordParams === null || requestParameters.userResetPasswordParams === undefined) {
            throw new runtime.RequiredError('userResetPasswordParams','Required parameter requestParameters.userResetPasswordParams was null or undefined when calling userControllerResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserResetPasswordParamsToJSON(requestParameters.userResetPasswordParams),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows users to reset password using an emailed token.
     */
    async userControllerResetPassword(requestParameters: UserControllerResetPasswordRequest): Promise<void> {
        await this.userControllerResetPasswordRaw(requestParameters);
    }

    /**
     * Allows users to request a password reset email.
     */
    async userControllerStartResetPasswordRaw(requestParameters: UserControllerStartResetPasswordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userStartResetPasswordParams === null || requestParameters.userStartResetPasswordParams === undefined) {
            throw new runtime.RequiredError('userStartResetPasswordParams','Required parameter requestParameters.userStartResetPasswordParams was null or undefined when calling userControllerStartResetPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/start-reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserStartResetPasswordParamsToJSON(requestParameters.userStartResetPasswordParams),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows users to request a password reset email.
     */
    async userControllerStartResetPassword(requestParameters: UserControllerStartResetPasswordRequest): Promise<void> {
        await this.userControllerStartResetPasswordRaw(requestParameters);
    }

    /**
     * Updates a user. If an agent dto is passed, it also updates the associated agent
     */
    async userControllerUpdateUserWithOptionalAgentRaw(requestParameters: UserControllerUpdateUserWithOptionalAgentRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userControllerUpdateUserWithOptionalAgent.');
        }

        if (requestParameters.createUserWithOptionalAgent === null || requestParameters.createUserWithOptionalAgent === undefined) {
            throw new runtime.RequiredError('createUserWithOptionalAgent','Required parameter requestParameters.createUserWithOptionalAgent was null or undefined when calling userControllerUpdateUserWithOptionalAgent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{id}/edit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserWithOptionalAgentToJSON(requestParameters.createUserWithOptionalAgent),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Updates a user. If an agent dto is passed, it also updates the associated agent
     */
    async userControllerUpdateUserWithOptionalAgent(requestParameters: UserControllerUpdateUserWithOptionalAgentRequest): Promise<boolean> {
        const response = await this.userControllerUpdateUserWithOptionalAgentRaw(requestParameters);
        return await response.value();
    }

}

import { styled } from "@avenue-8/ui-2";
import { PropertyPhoto } from "src/modules/presentation/presentation-generation-logic";
import {
  ResponsiveImage,
  ResponsiveImageDimentionType,
} from "src/modules/shared/components/ResponsiveImage";

const PrintGalleryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-left: ${(p) => p.theme.presentation.spacing(1)};
  padding-right: ${(p) => p.theme.presentation.spacing(1)};
`;

const GalleryRowPrint = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  break-inside: avoid;
  position: relative;
  gap: 0.5rem;
  & > div {
    flex: 1;
    display: flex;
    max-height: 180px;
  }
`;

const RatioImage = styled.div`
  display: "block";
  padding-bottom: 50%;
  position: relative;
  width: 100%;
  cursor: pointer;
  max-height: 180px;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 180px;
    object-fit: cover;
  }
`;

interface PrintGalleryProps {
  photos: Array<PropertyPhoto[]>;
  photoSize?: ResponsiveImageDimentionType;
}
export const PrintGallery = ({ photos, photoSize = "sm" }: PrintGalleryProps) => {
  return (
    <PrintGalleryContainer>
      {photos.map((photoRow, i) => (
        <GalleryRowPrint key={i}>
          {photoRow.map(({ url, caption }, index) => {
            return (
              <div key={index}>
                <RatioImage>
                  <ResponsiveImage alt={caption ?? ""} src={url} size={photoSize} />
                </RatioImage>
              </div>
            );
          })}
        </GalleryRowPrint>
      ))}
    </PrintGalleryContainer>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SingleAgentResponseDto
 */
export interface SingleAgentResponseDto {
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof SingleAgentResponseDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    license: string;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    headshotUrl: string;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    sourceId: string;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof SingleAgentResponseDto
     */
    state: string;
}

export function SingleAgentResponseDtoFromJSON(json: any): SingleAgentResponseDto {
    return SingleAgentResponseDtoFromJSONTyped(json, false);
}

export function SingleAgentResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SingleAgentResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'title': json['title'],
        'license': json['license'],
        'phone': json['phone'],
        'headshotUrl': json['headshotUrl'],
        'sourceId': json['sourceId'],
        'email': json['email'],
        'state': json['state'],
    };
}

export function SingleAgentResponseDtoToJSON(value?: SingleAgentResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'title': value.title,
        'license': value.license,
        'phone': value.phone,
        'headshotUrl': value.headshotUrl,
        'sourceId': value.sourceId,
        'email': value.email,
        'state': value.state,
    };
}



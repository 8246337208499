import { Fragment, useRef, useState } from "react";
import { CreateCmaCard } from "./CreateCmaCard";
import { ListCMAResponseItemDto } from "../../../shared/apis/cma/generated";
import { media } from "../../../shared/css-snippets";
import { useAppContext } from "../../../../AppContext";
import { copyToClipboard } from "../../../shared/utils/copy-to-clipboard";
import { toast, styled, Loader } from "@avenue-8/ui-2";
import { useBreakpoint } from "../../hooks/useMobile";
import { useDashboardLogic } from "../../hooks/useDashboardLogic";
import { CMAContextMenu } from "./CMAContextMenu";
import { CMACard } from "./CMACard";
import { useHistory } from "react-router";
import { appEventEmitter } from "../../../../events/app-event-emitter";
import { asyncPopupUrlOpener } from "../../../shared/utils/async-popup-opener";
import { cmaRoutes } from "../../cma.routes";

const Grid = styled.div`
  display: grid;

  grid-auto-flow: row;
  gap: 24px 24px;

  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));

  ${() => media.smallDesktopDown} {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  ${() => media.mobileDown} {
    grid-template-columns: 1fr 1fr;
  }

  ${() => media.mobileDown} {
    grid-template-columns: 1fr 1fr;
  }
`;

const MessageContainer = styled.div`
  text-align: center;
  width: 30rem;
  max-width: calc(100% - 4rem);
  margin: 8rem auto 0 auto;
  h1 {
    font-size: ${(p) => p.theme.av8.typography.headers.size};
    font-family: ${(p) => p.theme.av8.typography.headers.fontFamily};
  }
  p {
    color: ${(p) => p.theme.av8.typography.secondaryColor};
  }
`;

const Footer = styled.footer`
  color: ${(p) => p.theme.av8.typography.primaryColor} !important;
  margin: 0 auto;
  text-align: center;
  min-height: 10rem;
  padding: 1rem 0;
`;

export const DashboardContent = ({ onCreateNewCma }: { onCreateNewCma?: () => void }) => {
  const { state, actions } = useDashboardLogic();
  const { actions: appActions } = useAppContext();
  const [cardDropdownCmaId, setCardDropdownCmaId] = useState<string | null>(null);
  const menuAnchorEl = useRef<any>(null);
  const isMobileDown = useBreakpoint("mobileDown");
  const history = useHistory();
  const isLoading = [state.agentsStatus, state.cmasStatus].includes("loading");
  const isError = [state.agentsStatus, state.cmasStatus].includes("error");

  const cards = (state.cmasPages && state.cmasPages.map((page) => page.items).flat()) ?? [];
  if (cards.length % 8 === 0) {
    // If we have more items to load and the "page is full" omit
    // the last card to make space for the create a new CMA card
    state.hasNextCmasPage && cards.pop();
  }
  const handleViewPresentation = async (cma: ListCMAResponseItemDto) => {
    if (cma.canPublish) {
      appEventEmitter.emit({
        eventType: "presentation-preview-clicked",
        presentationType: cma!.presentationType,
      });
      setCardDropdownCmaId(null);
      asyncPopupUrlOpener({
        buildUrl: async () => {
          return window.location.origin + `/presentation/${cma.id}/view`;
        },
      });
    }
  };

  const copyPresentationLink = async (cma: ListCMAResponseItemDto) => {
    if (cma.canPublish) {
      setCardDropdownCmaId(null);
      copyToClipboard(`/presentation/${cma.id}/view`, "local");
      appEventEmitter.emit({
        eventType: "presentation-link-copied-to-clipboard",
        from: "dashboard",
        presentationType: cma!.presentationType,
      });
      toast.success("The link has been copied.");
    }
  };

  const getDisabledActions = (cma: ListCMAResponseItemDto) => {
    const disabledActions = {
      viewPresentation: !cma.canPublish
        ? "You can only view or share a presentation once you have selected comparable properties and added an estimated price range."
        : undefined,
      copyPresentationLink: !cma.canPublish
        ? "You can only copy a presentation link once you have selected comparable properties and added an estimated price range."
        : undefined,
    };
    return disabledActions;
  };

  const handleDuplicateCma = async (cmaId: string) => {
    setCardDropdownCmaId(null);
    await actions.cloneCMA(cmaId);
  };

  const handleDeleteCma = async (cmaId: string, cmaName: string) => {
    setCardDropdownCmaId(null);
    actions.delete({ cmaId, cmaName });
  };

  const handleEditCma = async (cmaId: string, presentationType: string) => {
    if (isMobileDown) {
      appActions.confirm({
        title: "Editing is not supported on this device",
        message: "You can still view and share CMAs on your phone",
        confirmButtonText: "Continue",
      });
    } else {
      if (presentationType === "cma") {
        history.push(cmaRoutes.edit.generate({ id: cmaId }));
      } else if (presentationType === "general") {
        history.push(cmaRoutes.editGeneralPresentation.generate({ id: cmaId }));
      }
    }
  };

  if (isLoading) {
    return (
      <MessageContainer>
        <Loader />
        <p>Loading</p>
      </MessageContainer>
    );
  }
  if (isError) {
    return (
      <MessageContainer>
        <p>Failed</p>
      </MessageContainer>
    );
  }
  if (cards.length === 0) {
    return (
      <MessageContainer>
        <h1>Create your first presentation</h1>
        {isMobileDown && <p>Log into the desktop version to create your first presentation. </p>}
        {!isMobileDown && (
          <p>
            Create your first presentation by clicking “Create new" in the upper right hand corner
            of your screen.
          </p>
        )}
      </MessageContainer>
    );
  }

  return (
    <>
      <Grid>
        <CreateCmaCard onClick={() => onCreateNewCma?.()} />
        {cards.map((cma) => {
          return (
            <Fragment key={`card_${cma.id}`}>
              <CMACard
                disabled={cma.id.startsWith("tempclone")}
                imageUrl={cma.coverImage}
                title={
                  cma.title ||
                  `${cma.addressLine1 ?? ""} ${cma.addressLine2 ?? ""}`.trim() ||
                  "Untitled CMA"
                }
                presentationType={cma.presentationType}
                clientDisplayName={cma.clientDisplayName ?? ""}
                updatedAt={cma.updatedDate}
                cardProps={{
                  onClick: () => handleEditCma(cma.id, cma.presentationType),
                }}
                iconButtonProps={{
                  "aria-label": "Open menu options",
                  onClick: (e: any) => {
                    menuAnchorEl.current = e.target;
                    setCardDropdownCmaId(cma.id);
                  },
                }}
              />
              <CMAContextMenu
                anchorEl={menuAnchorEl.current}
                open={cardDropdownCmaId === cma.id}
                onClose={() => setCardDropdownCmaId(null)}
                onOpen={() => setCardDropdownCmaId(cma.id)}
                onCopyPresentationLink={() => copyPresentationLink(cma)}
                onDelete={() =>
                  handleDeleteCma(cma.id, cma.title ?? cma.addressLine1 ?? "Untitled CMA")
                }
                onDuplicate={() => handleDuplicateCma(cma.id)}
                onEdit={() => handleEditCma(cma.id, cma.presentationType)}
                onViewPresentation={() => handleViewPresentation(cma)}
                disabledActions={getDisabledActions(cma)}
              />
            </Fragment>
          );
        })}
      </Grid>
      <Footer>{isLoading || (state.isFetchingNextCmasPage && <Loader />)}</Footer>
    </>
  );
};

import * as React from "react";
import { Stepper, StepperItem } from "@avenue-8/ui-2";

export enum CreateCmaSteps {
  "Subject Property",
  "Search",
  "Estimate",
  "Customize Presentation",
  "Finished",
}

export const CMAStepper = ({
  currentStep,
  onClickStep,
}: {
  currentStep: CreateCmaSteps;
  onClickStep?: (step: CreateCmaSteps) => void;
}) => {
  const getHandleClick = (step: CreateCmaSteps) => {
    return onClickStep ? () => onClickStep(step) : undefined;
  };
  return (
    <Stepper data-testid="steppers">
      <StepperItem
        order={CreateCmaSteps["Subject Property"]}
        currentStep={currentStep}
        onClick={getHandleClick(CreateCmaSteps["Subject Property"])}
        data-testid="stepper-subject-property"
      >
        Subject Property
      </StepperItem>
      <StepperItem
        order={CreateCmaSteps["Search"]}
        currentStep={currentStep}
        onClick={getHandleClick(CreateCmaSteps["Search"])}
        data-testid="stepper-search"
      >
        Search
      </StepperItem>
      <StepperItem
        order={CreateCmaSteps["Estimate"]}
        currentStep={currentStep}
        onClick={getHandleClick(CreateCmaSteps.Estimate)}
        data-testid="stepper-estimate"
      >
        Estimate
      </StepperItem>
      <StepperItem
        order={CreateCmaSteps["Customize Presentation"]}
        currentStep={currentStep}
        onClick={getHandleClick(CreateCmaSteps["Customize Presentation"])}
        data-testid="stepper-customize"
      >
        Customize Presentation
      </StepperItem>
    </Stepper>
  );
};

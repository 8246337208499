import React from "react";
import { ConstrainedContainer } from "./ConstrainedContainer";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Title1 } from "./typography";
import { InsightsSectionView } from "../../../../presentation/presentation-generation-logic/models";
import { styledThemed as styled } from "./../theme";
import { InfoIcon, Tooltip } from "@avenue-8/ui-2";
import { SectionViewLayout } from "./SectionViewLayout";
import { AvoidPrintBreakInside } from "./AvoidPrintBreakInside";
import { IconContainer } from "src/modules/shared/components/IconContainer";

interface Props extends InsightsSectionView {
  mobile: boolean;
  editMode?: boolean;
}

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Text = styled.p`
  font-family: ${(p) => p.theme.typography.body1.fontFamily};
  text-align: center;
  font-size: 0.9rem;
`;

const InsightsContainer = styled(ConstrainedContainer)`
  max-width: none;
  & .swiper-pagination {
    bottom: 0px;
  }
  & .swiper-container {
    padding-bottom: 70px;
  }
  & .swiper-pagination-bullet {
    margin: 0 30px !important;
  }
  & .swiper-pagination-bullet-active {
    background: ${(p) => p.theme.av8.primaryColor};
  }
`;

const InsightContainerDesktop = styled(ConstrainedContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0 auto;
`;

const InsightBox = styled.div<{ $justifyContent?: string; $disabled?: boolean }>`
  background: ${(p) => p.theme.av8.backgroundAlt};
  color: ${(p) => p.theme.av8.primaryColor};
  margin: ${(p) => (p.theme.presentation.mobile ? "5px auto" : "5px")};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${(p) => (p.theme.presentation.mobile ? "75% !important" : "240px !important")};
  height: ${(p) => (p.theme.presentation.mobile ? "300px !important" : "auto !important")};
  ${(p) => p.$disabled && `opacity: .6`}
` as any;

const SwiperSlideInsightBox = InsightBox.withComponent(SwiperSlide);

const InsightBoxInner = styled.div`
  font-family: ${(p) => p.theme.presentation.headerFontFamily};
  font-size: ${(p) => (p.theme.presentation.mobile ? "1.5rem" : "1.3rem")};
  text-align: center;
`;

const BoxDisclaimer = styled.div`
  font-size: 0.8rem;
  text-align: center;
  margin-top: 20px;
  line-height: 1.3rem;
  font-weight: 300;
`;

export const Insights = ({ insights, id, mobile }: Props) => {
  const NoInsightsToDisplay = () => {
    return <Text>There are no insights to display. This section will be hidden.</Text>;
  };

  const hasNoDataToDisplay = !insights?.filter((x) => x.mode === "ok")?.length;

  return (
    <SectionViewLayout>
      <AvoidPrintBreakInside>
        <InsightsContainer id={id} $noMobilePadding={true}>
          <Title1 marginBottom={3} textAlign="center">
            Insights
          </Title1>
          {hasNoDataToDisplay && <NoInsightsToDisplay />}
          {mobile ? (
            <Swiper
              spaceBetween={10}
              slidesPerView={"auto"}
              pagination={{ clickable: true }}
              centeredSlides
              grabCursor
              mousewheel
            >
              {insights?.map(({ content, footer }, index) => {
                return (
                  <SwiperSlideInsightBox key={index}>
                    <InsightBoxInner>{content}</InsightBoxInner>
                    <BoxDisclaimer>{footer}</BoxDisclaimer>
                  </SwiperSlideInsightBox>
                );
              })}
            </Swiper>
          ) : (
            <InsightContainerDesktop>
              {insights?.map(({ content, footer, mode }, index) => {
                return (
                  <InsightBox
                    $disabled={mode === "not-enough-data"}
                    key={index}
                    data-testid="insight-box"
                  >
                    <InsightBoxInner>{content}</InsightBoxInner>
                    <BoxDisclaimer>{footer}</BoxDisclaimer>
                    {mode === "not-enough-data" && (
                      <Tooltip title="This insight will not be displayed.">
                        <IconContainer>
                          <InfoIcon />
                        </IconContainer>
                      </Tooltip>
                    )}
                  </InsightBox>
                );
              })}
            </InsightContainerDesktop>
          )}
        </InsightsContainer>
      </AvoidPrintBreakInside>
    </SectionViewLayout>
  );
};

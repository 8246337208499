import flagsmith from "flagsmith"; //Add this line if you're using flagsmith via npm
import { IFlagsmithFeature } from "flagsmith/types";
import { FeatureToggleStrategy } from "./feature-toggle-strategy";

//we use this to connect the flasmith with the instantiated strategies
const flagChangeListeners = new Set<(changedFlags: Set<string>) => void>();

//this is like a cache that we rebuild everytime the onChange is called
const flagValueMap = (() => {
  let map = new Map<string, string | null>();
  const flagsStr = localStorage.getItem("FLAGSMITH_FLAGS");
  if (flagsStr) {
    try {
      map = new Map<string, string | null>(Object.entries(JSON.parse(flagsStr)));
    } catch (e) {
      console.error("Could not load Flagsmith flags from cache.");
    }
  }
  return map;
})();

const storeFlags = () => {
  if (flagValueMap.size > 0)
    localStorage.setItem("FLAGSMITH_FLAGS", JSON.stringify(Object.fromEntries(flagValueMap)));
};

function getFlagValue(flag: IFlagsmithFeature): string | null {
  if (flag == null) return null;
  if (flag.enabled) {
    return flag.value?.toString() || "1";
  } else {
    return "0";
  }
}

let flagsmithStarted = false;
let flagsmithLoaded = false;
function initFlagsmith() {
  const env = process.env.REACT_APP_FLAGSMITH_ENV;
  if (flagsmithStarted || !env) return;
  flagsmithStarted = true;

  flagsmith.init({
    environmentID: env,
    enableAnalytics: true,
    onChange: (oldFlags, _params) => {
      flagValueMap.clear();
      const changed = new Set<string>();
      const flags = flagsmith.getAllFlags();
      Object.keys(flags).forEach((flag) => {
        const oldFlagValue = getFlagValue(oldFlags[flag]);
        const newFlagValue = getFlagValue(flags[flag]);
        flagValueMap.set(flag, newFlagValue);
        if (oldFlagValue !== newFlagValue) {
          changed.add(flag);
        }
      });
      flagChangeListeners.forEach((listener) => listener(changed));
      flagsmithLoaded = true;
      if (_params.isFromServer) storeFlags();
    },
  });
}

export class FlagsmithFeatureToggleStrategy implements FeatureToggleStrategy {
  _listeners = new Map<string, Set<(value: any) => void>>();

  identify(identity: string | number) {
    flagsmith.identify(`user_${identity}`);
  }

  constructor() {
    initFlagsmith();
    flagChangeListeners.add((flags) => {
      flags.forEach((flag) => {
        this._listeners.get(flag)?.forEach((listener) => {
          listener(flagValueMap.get(flag));
        });
      });
    });
  }

  getFeatureToggleValue(_key: string): any {
    if (!flagValueMap) return null;
    const value = flagValueMap.get(_key);
    if (flagsmithLoaded) {
      flagsmith.getValue(_key); //we don't need this - except for analytics data
    }
    return value;
  }

  addFeatureToggleListener(key: string, listener: (value: any) => void) {
    const listeners = this._listeners.get(key) || new Set<(value: any) => void>();
    listeners.add(listener);
    this._listeners.set(key, listeners);
  }

  removeFeatureToggleListener(key: string, listener: (value: any) => void) {
    const listeners = this._listeners.get(key) || new Set<(value: any) => void>();
    listeners.delete(listener);
    this._listeners.set(key, listeners);
  }

  refreshFlags() {
    flagsmith.getFlags();
  }
}

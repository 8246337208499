import { Card, CardProps, CardMedia, IconButton, Typography, styled } from "@avenue-8/ui-2";
import { media } from "../../../../shared/css-snippets";

export const MainIconButton = styled(IconButton)`
  position: absolute !important;
  bottom: 5px;
  right: 5px;
  z-index: 2;
  &:hover {
    background: ${(p) => p.theme.av8.backgroundAlt}!important;
  }
`;
export const BottomInfo = styled.div`
  position: relative;
  padding-right: 40px;
`;
export const Title = styled(Typography)`
  max-lines: 2;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 3em;
  margin-bottom: 0.5em;
  display: block;
  overflow: hidden;
  font-size: ${(p) => p.theme.av8.typography.sizes.mobile.medium};
  ${() => media.smallDesktopUp} {
    font-size: ${(p) => p.theme.av8.typography.sizes.desktop.medium};
  }
`;
export const TextLine = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1.25em;
  font-size: ${(p) => p.theme.av8.typography.sizes.mobile.small};
  ${() => media.smallDesktopUp} {
    font-size: ${(p) => p.theme.av8.typography.sizes.desktop.small};
  }
`;
export const ConstrainedCardMedia = styled(CardMedia)`
  height: 86px;
  position: relative;
  ${() => media.smallDesktopUp} {
    height: 152px;
  }
`;

export const CustomCard = styled(Card)<{ $disabled: boolean } & CardProps>`
  position: relative;
  ${(p) =>
    p.$disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const CardTag = styled.div`
  position: absolute;
  top: 14px;
  right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 19px;
  border-radius: 8px;
  background-color: #eeeefd;
`;

export const CardTagInnerText = styled(Typography)`
  letter-spacing: 2px;
  font-size: ${(p) => p.theme.av8.typography.sizes.mobile.small};
  ${() => media.smallDesktopUp} {
    font-size: ${(p) => p.theme.av8.typography.sizes.desktop.small};
  }
`;

import * as React from "react";
import { useState } from "react";
import { SideBySideComparisonSectionConfig } from "../../../../../../../../presentation/presentation-generation-logic/models/section-config";
import { SectionEditorProps } from "../SectionEditor";
import { LabeledCheckboxFieldModel, LabeledCheckbox } from "./LabeledCheckbox";

export const fields: Array<LabeledCheckboxFieldModel<keyof SideBySideComparisonSectionConfig>> = [
  { label: "Property Type", name: "showPropertyType", checked: false },
  { label: "List Price", name: "showListPrice", checked: true },
  { label: "Sold Price", name: "showSoldPrice", checked: true },
  { label: "Sold Date", name: "showSoldDate", checked: false },
  { label: "List Date", name: "showListDate", checked: false },
  { label: "Year Built", name: "showYearBuilt", checked: true },
  { label: "Listing Status", name: "showListingStatus", checked: true },
  { label: "Neighborhood", name: "showNeighborhood", checked: true },
  { label: "Living Space", name: "showLivingSpace", checked: true },
  { label: "Beds", name: "showBeds", checked: true },
  { label: "Baths", name: "showBaths", checked: true },
  { label: "Total Rooms", name: "showRoomsTotal", checked: false },
  { label: "Lot Size", name: "showLotSize", checked: false },
  { label: "Price / Living Space", name: "showPricePerLivingSpaceSqft", checked: true },
  { label: "Garage Parking Spaces", name: "showGarageParkingSpaces", checked: true },
  // { label: "Monthly Fees", name: "showHOAFees", checked: true },
  { label: "Monthlies", name: "showRecurringCosts", checked: true },
  { label: "Doorman / Attendance", name: "showDoormanAttendance", checked: false },
  { label: "Flip Tax", name: "showFlipTaxRemarks", checked: false },
  { label: "Days on Market", name: "showDaysOnMarket", checked: false },
];

export const SideBySideComparisonEditor = ({
  section,
  onChanged,
}: SectionEditorProps<SideBySideComparisonSectionConfig>) => {
  const initialModel = React.useMemo(() => {
    return fields.reduce((acc: any, field) => {
      acc[field.name] = section[field.name] ?? field.checked;
      return acc;
    }, {} as SideBySideComparisonSectionConfig);
  }, [section]);

  const [model, setModel] = useState<Partial<SideBySideComparisonSectionConfig>>(initialModel);

  const onChangeSwitch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name as keyof SideBySideComparisonSectionConfig;
      const updatedModel = { ...model, [name]: !model[name] };
      setModel(updatedModel);
      onChanged({ sectionConfig: { ...section, ...updatedModel } });
    },
    [model, setModel, onChanged, section]
  );

  return (
    <>
      {fields.map((field) => {
        return (
          <LabeledCheckbox
            key={field.name}
            label={field.label}
            checked={Boolean(model[field.name])}
            onChange={onChangeSwitch}
            name={field.name}
          />
        );
      })}
    </>
  );
};

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateRangeFilter
 */
export interface DateRangeFilter {
    /**
     * 
     * @type {Date}
     * @memberof DateRangeFilter
     */
    startAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof DateRangeFilter
     */
    endAt?: Date | null;
}

export function DateRangeFilterFromJSON(json: any): DateRangeFilter {
    return DateRangeFilterFromJSONTyped(json, false);
}

export function DateRangeFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateRangeFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startAt': !exists(json, 'startAt') ? undefined : (json['startAt'] === null ? null : new Date(json['startAt'])),
        'endAt': !exists(json, 'endAt') ? undefined : (json['endAt'] === null ? null : new Date(json['endAt'])),
    };
}

export function DateRangeFilterToJSON(value?: DateRangeFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'startAt': value.startAt === undefined ? undefined : (value.startAt === null ? null : value.startAt.toISOString()),
        'endAt': value.endAt === undefined ? undefined : (value.endAt === null ? null : value.endAt.toISOString()),
    };
}



import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  FormHelperText,
  Grid,
  Checkbox,
  FormControlLabel,
  Typography,
  styled,
} from "@avenue-8/ui-2";

const FormItem = styled(Grid)`
  margin-bottom: 1rem;
`;

export interface AgreementFormModel {
  agreeTermsAndConditions: boolean;
}

const CheckboxLabel = styled(Typography)`
  a {
    color: ${(p) => p.theme.av8.typography.secondaryColor};
  }
`;

export const AgreementForm = ({ defaultValues }: { defaultValues: AgreementFormModel }) => {
  const { control, errors } = useFormContext<AgreementFormModel>();

  return (
    <>
      <FormItem item xs={12}>
        <Controller
          name="agreeTermsAndConditions"
          control={control}
          defaultValue={defaultValues?.agreeTermsAndConditions ?? ""}
          rules={{
            required: {
              value: true,
              message: "You must agree to the Terms and Conditions & Privacy Policy.",
            },
          }}
          render={({ ref, ...rest }) => (
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={ref}
                  {...rest}
                  onChange={(e) => rest.onChange(e.target.checked)}
                  checked={rest.value}
                />
              }
              label={
                <CheckboxLabel variant="body2" color="secondary">
                  I have read and agree to the{" "}
                  <a
                    href="https://marketcomps.com/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>{" "}
                  &amp;{" "}
                  <a
                    href="https://marketcomps.com/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                </CheckboxLabel>
              }
            />
          )}
        />
        <FormHelperText error={!!errors.agreeTermsAndConditions}>
          {errors.agreeTermsAndConditions?.message}
        </FormHelperText>
      </FormItem>
    </>
  );
};

import { styled } from "@avenue-8/ui-2";
import { ToggleButton, Typography } from '@mui/material'

export const PresentationToggleGroupContainer = styled.div`
    margin-bottom: 10px;
`;

export const PresentationToggleButton = styled(ToggleButton)`
    height: 145px;

    text-transform: none;
    display: flex;
    flex-direction: column;

    justify-content: space-around;

    &:hover{
        background-color: white;
    }

    border: 1px solid ${p => p.theme.av8.input.borderColor} !important;
    border-radius: 4px !important;

    &.Mui-selected {
        background-color: ${p => p.theme.av8.backgroundAlt};
        border: 2px solid ${p => p.theme.av8.input.borderColor} !important;
        padding: 10px !important;
    }

    &.Mui-selected:hover {
        background-color: ${p => p.theme.av8.backgroundAlt};
    }
`;

export const PresentationToggleErrorMessage = styled(Typography)`
    letter-spacing: 2px;
    color: ${(p) => p.theme.av8.dangerColor};
    font-size: 10px;
`

export const InputContainer = styled.div`
    padding: 10px 0;
`;
import { ThemeProvider, Typography, Button, styled } from "@avenue-8/ui-2";
import { Link } from "react-router-dom";

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const NotFoundPage = () => {
  return (
    <Container>
      <div>
        <Typography variant="h4">Page not found.</Typography>
        <br />
        <Button component={Link} to="/">
          Back to Home
        </Button>
      </div>
    </Container>
  );
};

export const NotFound = () => {
  return (
    <ThemeProvider darkMode={false}>
      <NotFoundPage />
    </ThemeProvider>
  );
};

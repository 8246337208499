import { useHistory } from "react-router";
import { PropertyDto } from "./../../shared/apis/cma/generated/models/PropertyDto";
import * as React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { getCMAApiClient, getCMAPropertiesApiClient } from "../../shared/apis/cma/api-factories";
import { CMADto, PropertyControllerSearchV2Request } from "../../shared/apis/cma/generated";
import { toast } from "@avenue-8/ui-2";
import { useAppContext } from "../../../AppContext";
import { SearchComparableFormModel } from "../components/CreateCMA/Steps/Search/search-comparable-form-model";
import { makeBLContext } from "../../shared/hooks/makeBLContext";
import { useEstimatePriceLogic } from "../components/CreateCMA/Steps/Estimate/useEstimatePriceLogic";
import { appEventEmitter } from "../../../events/app-event-emitter";
import { buildPropertyKey } from "../../shared/utils/buildPropertyKey";
import { useCMARouteStepHelper } from "../components/CreateCMA/step-navigation-helper";
import { cmaRoutes } from "../cma.routes";

class PromiseChain {
  runningPromise: Promise<any> | null = null;
  activePromises = 0;
  onClear: (() => void) | null = null;

  setOnClear(onClear: () => void) {
    this.onClear = onClear;
  }

  async addAndWaitExecution<T>(promiseFactory: () => Promise<T>): Promise<T> {
    this.activePromises++;
    const build = () => {
      return promiseFactory().finally(() => {
        if (--this.activePromises === 0) {
          this.onClear?.();
          this.runningPromise = null;
        }
      });
    };
    if (this.runningPromise) {
      this.runningPromise = this.runningPromise.finally(() => {
        return build();
      });
    } else {
      this.runningPromise = build();
    }
    return this.runningPromise;
  }
}

function useCreateCMALogicInner({
  cmaId,
  presentationType,
}: {
  cmaId: string | undefined;
  presentationType: "cma" | "general" | undefined;
}) {
  const { actions: appActions } = useAppContext();
  const queryClient = useQueryClient();
  const cmaRouteStepHelper = useCMARouteStepHelper(presentationType);
  const currentStep = cmaRouteStepHelper.getCurrentStep();
  const history = useHistory();

  const cmaQuery = useQuery(
    ["cmas", cmaId],
    async () => getCMAApiClient().cMAControllerGet({ id: cmaId! }),
    {
      refetchOnWindowFocus: false,
      enabled: !!cmaId,
    }
  );
  const { data: cma, status: cmaStatus, isFetching: isFetchingCma } = cmaQuery;

  React.useEffect(() => {
    if (cma && presentationType !== cma.presentationType) {
      history.push(cmaRoutes.dashboard.route);
      return;
    }
    if (cmaStatus === "error" && !cma) {
      history.push(cmaRoutes.dashboard.route);
      toast.error("Presentation not found.");
      return;
    }
  }, [cma, cmaStatus, history, presentationType]);

  const { state: estimatePriceState, actions: estimatePriceActions } = useEstimatePriceLogic({
    cma,
  });
  // by desctructuring it on every hook call, we ensure that data: cma is a new memory reference

  const addComparablesChain = React.useRef(new PromiseChain());
  addComparablesChain.current.setOnClear(() => {
    if (cmaId) {
      queryClient.invalidateQueries(["cmas", cmaId]);
    }
  });

  const { mutateAsync: createSimpleCMA, status: createSimpleCMAStatus } = useMutation(
    async (data: {
      clientDisplayName: string;
      presentationType: string;
      presentationName?: string;
    }) =>
      await getCMAApiClient().cMAControllerCreateSimple({
        createEmptyCMADto: data,
      }),
    {
      onSuccess: (_data, { presentationType }) => {
        appEventEmitter.emit({ eventType: "cma-created", presentationType });
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to create Presentation.");
      },
    }
  );

  const { mutateAsync: publishCma, status: publishCmaStatus } = useMutation(
    async (cmaId: string) => await getCMAApiClient().cMAControllerPublish({ id: cmaId }),
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to publish Presentation.");
      },
    }
  );

  const { mutateAsync: updateCmaHeader, status: updateCmaHeaderStatus } = useMutation(
    async ({
      id,
      clientDisplayName,
      title,
    }: {
      id: string;
      clientDisplayName: string;
      title: string;
    }) => {
      const api = getCMAApiClient();
      await appActions.watchPromise(
        api.cMAControllerPatch({ id, patchCMARequestDto: { root: { clientDisplayName, title } } })
      );
    },
    {
      onMutate: ({ id, title, clientDisplayName }) => {
        const queryKey = ["cmas", id];
        queryClient.setQueryData<CMADto | undefined>(queryKey, (old) => {
          if (old == null) return old;

          if (title !== old.title)
            appEventEmitter.emit({
              eventType: "cma-title-changed",
              presentationType: cma!.presentationType,
            });
          if (clientDisplayName !== old.clientDisplayName)
            appEventEmitter.emit({
              eventType: "cma-client-display-name-changed",
              presentationType: cma!.presentationType,
            });

          const optimisticUpdatedData: CMADto = {
            ...old,
            title,
            clientDisplayName,
          };
          return optimisticUpdatedData;
        });
      },
      onError: (error) => {
        console.error(error);
        toast.error("Failed to save CMA header information.");
      },
      onSuccess: async (_data, variables) => {
        if (currentStep?.params.step === "customize-presentation") {
          await appActions.watchPromise(new Promise((resolve) => setTimeout(resolve, 2000)), {
            blocking: true,
            message: "Syncing Presentation...",
          });
          queryClient.invalidateQueries(["presentations", variables.id]);
        }
      },
    }
  );

  const {
    mutateAsync: searchProperties,
    status: searchPropertiesStatus,
    data: searchPropertiesData,
  } = useMutation(
    async (searchParams: SearchComparableFormModel) => {
      const params: PropertyControllerSearchV2Request = {
        searchV2Dto: {
          search: searchParams.search?.trim() ? searchParams.search.trim() : undefined,
          page: searchParams.page ?? 1,
          limit: searchParams.limit ?? 10,
        },
      };
      return await getCMAPropertiesApiClient().propertyControllerSearchV2(params);
    },
    {
      onError: (error) => {
        console.error(error);
        toast.error("Failed to search subject properties.");
      },
    }
  );

  const { mutateAsync: selectCompListing } = useMutation(
    async (property: PropertyDto) => {
      return await appActions.watchPromise(
        addComparablesChain.current.addAndWaitExecution(async () =>
          getCMAApiClient().cMAControllerAddComparableProperty({
            id: cmaId || "",
            mlsId: buildPropertyKey({ mlsSource: property?.mlsSource, mlsId: property?.mlsId }),
          })
        )
      );
    },
    {
      mutationKey: "add-remove-comp",
      onMutate: async (property) => {
        const queryKey = ["cmas", cma!.id];
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData<CMADto | undefined>(queryKey, (old) => {
          if (old == null) return old;
          const optimisticUpdatedData: CMADto = {
            ...old,
            comparableProperties: [...old.comparableProperties, { ...property }],
          };
          return optimisticUpdatedData;
        });
      },
      onError: (error) => {
        console.log(error);
        toast.error("Failed to select comparable property.", { shouldDeduplicate: true });
      },
      onSuccess: () => {
        if (addComparablesChain.current.activePromises === 0)
          toast.success("Selected comparable properties successfully.", {
            shouldDeduplicate: true,
          });
        appEventEmitter.emit({
          eventType: "cma-comparable-property-added",
          presentationType: cma!.presentationType,
        });
      },
    }
  );

  const { mutateAsync: removeCompListingMutate } = useMutation(
    async (property: PropertyDto) => {
      return await appActions.watchPromise(
        addComparablesChain.current.addAndWaitExecution(async () =>
          getCMAApiClient().cMAControllerRemoveComparableProperty({
            id: cmaId || "",
            mlsId: buildPropertyKey(property),
          })
        )
      );
    },
    {
      mutationKey: "add-remove-comp",
      onMutate: async (property) => {
        const queryKey = ["cmas", cma!.id];
        await queryClient.cancelQueries(queryKey);
        queryClient.setQueryData<CMADto | undefined>(queryKey, (old) => {
          if (old == null) return old;
          const optimisticUpdatedData: CMADto = {
            ...old,
            comparableProperties: old.comparableProperties.filter(
              (x) => buildPropertyKey(x) !== buildPropertyKey(property)
            ),
          };
          return optimisticUpdatedData;
        });
      },
      onError: (error) => {
        console.log(error);
        toast.error("Failed to remove comparable property.", { shouldDeduplicate: true });
      },
      onSuccess: () => {
        toast.success("Removed comparable property successfully.", { shouldDeduplicate: true });
        appEventEmitter.emit({
          eventType: "cma-comparable-property-removed",
          presentationType: cma!.presentationType,
        });
      },
    }
  );

  const removeCompListing = async (property: PropertyDto, confirm = false) => {
    if (confirm === false) return removeCompListingMutate(property);
    const comparable = cma?.comparableProperties?.find(
      (x) => buildPropertyKey(x) === buildPropertyKey(property)
    );
    if (comparable) {
      const title = `${comparable.addressLine1 ?? ""} ${comparable.addressLine2 ?? ""}`.trim();
      if (
        await appActions.confirm({
          title: `Remove "${title}"?`,
          message:
            "Are you sure you would like to remove this listing? This action cannot be undone.",
          cancelButtonText: "Cancel",
          confirmButtonText: "Remove",
        })
      ) {
        await removeCompListingMutate(property);
        return true;
      }
      return false;
    }
  };

  const { mutateAsync: reorderComps } = useMutation(
    async (properties: PropertyDto[]) => {
      return await appActions.watchPromise(
        getCMAApiClient().cMAControllerReorderComparableProperties({
          id: cmaId || "",
          reorderComparablePropertiesRequest: {
            order: properties.map((x) => ({ mlsId: x.mlsId || "", mlsSource: x.mlsSource || "" })),
          },
        })
      );
    },
    {
      onError: (error) => {
        console.log(error);
        toast.error("Failed to reorder comparable properties.", { shouldDeduplicate: true });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["cmas", cmaId]);
      },
    }
  );

  return {
    state: {
      cmaId,
      presentationType,
      cma,
      cmaStatus,
      publishCmaStatus,
      createSimpleCMAStatus,
      updateCmaHeaderStatus,
      currentStep,
      searchProperties: { data: searchPropertiesData, status: searchPropertiesStatus },
      isCompsListingMutating: (mlsId: string) => {
        return Boolean(
          queryClient.getMutationCache().find({
            mutationKey: "add-remove-comp",
            predicate: (a) => {
              return a.options.variables === mlsId && a.state.status === "loading";
            },
          })
        );
      },
      ...estimatePriceState,
      isFetchingCma,
    },
    actions: {
      createSimpleCMA,
      publishCma,
      updateCmaHeader,
      searchProperties,
      selectCompListing,
      removeCompListing,
      reorderComps,
      ...estimatePriceActions,
    },
  };
}

export const { LogicContextProvider: CreateCMALogicProvider, useLogicContext: useCreateCMALogic } =
  makeBLContext({ useLogic: useCreateCMALogicInner });

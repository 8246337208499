import * as React from "react";
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
  Theme as MuiTheme,
} from "@mui/material/styles";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import _emotionStyled from "@emotion/styled";
import { useTheme as useAv8UI2Theme, Av8Theme, ThemeProvider } from "@avenue-8/ui-2";

declare module "@mui/material/styles" {
  export interface Theme {
    av8: Av8Theme["av8"];
    presentation: PresentationBaseTheme;
  }
  // allow configuration using `createTheme`
  export interface ThemeOptions {
    av8: Av8Theme["av8"];
    presentation: PresentationBaseTheme;
  }
}

interface FontConfig {
  fontFamily?: string;
  fontSize?: string | number;
  fontWeight?: number;
  textTransform?: string;
  letterSpacing?: string;
  margin?: string | number;
}

export type Font = "h1" | "h2" | "subtitle1" | "subtitle2" | "body1" | "body2";

interface PresentationBaseTheme {
  h1: FontConfig;
  h2: FontConfig;
  subtitle1: FontConfig;
  subtitle2: FontConfig;
  body1: FontConfig;
  body2: FontConfig;
  fontAsCSS: (font: Font) => string;
  spacing: (value: number) => string;
  mobile: boolean;
  backgroundColor: string;
  backgroundColorAlt: string;
  borderColor: string;
  headerFontFamily: string;
  fontFamily: string;
}

export interface PresentationTheme extends MuiTheme {
  presentation: PresentationBaseTheme;
  av8: Av8Theme["av8"];
}

type StyledTaggedTemplate<PresentationTheme, ComponentProps = any> = <ExtensionProps = unknown>(
  strings: TemplateStringsArray,
  ...rest: Array<(props: ExtensionProps & ComponentProps & { theme: PresentationTheme }) => any>
) => React.ComponentType<ExtensionProps & ComponentProps>;

type StyledLikeThemed<PresentationTheme = any> = {
  [TTag in keyof JSX.IntrinsicElements]: StyledTaggedTemplate<
    PresentationTheme,
    JSX.IntrinsicElements[TTag]
  >;
} &
  (<C>(
    elementType: React.Component<C> | React.FC<C> | React.JSXElementConstructor<C>
  ) => StyledTaggedTemplate<PresentationTheme, C>);

export const styledThemed = _emotionStyled as any as StyledLikeThemed<PresentationTheme>;

export const generatePresentationStyledTheme = (
  mobile: boolean,
  baseTheme: Av8Theme
): { av8: PresentationTheme["av8"]; presentation: PresentationTheme["presentation"] } => {
  const baseFontF = baseTheme.av8.typography.fontFamily;
  const headerFontF = baseTheme.av8.typography.headers.fontFamily;
  const primaryColor = baseTheme.av8.typography.primaryColor;

  const presentationBaseTheme = {
    backgroundColor: baseTheme.av8.background,
    backgroundColorAlt: "#F3F3F7", // NEW COLOR
    primaryColor: primaryColor,
    borderColor: baseTheme.av8.input.borderColor,
    fontFamily: baseFontF,
    headerFontFamily: headerFontF,
    h1: {
      color: primaryColor,
      fontFamily: headerFontF,
      fontSize: mobile ? "1.5rem" : "3rem",
      fontWeight: 400,
      margin: 0,
    },
    h2: {
      fontFamily: headerFontF,
      fontSize: mobile ? "1.25rem" : "1.5rem",
      fontWeight: 400,
      margin: 0,
    },
    subtitle1: {
      fontFamily: baseFontF,
      fontSize: "0.6rem",
      fontWeight: 400,
      textTransform: "uppercase",
      letterSpacing: "0.3em",
      margin: 0,
    },
    subtitle2: {
      fontFamily: baseFontF,
      fontSize: "0.8rem",
      fontWeight: 400,
      textTransform: "uppercase",
      letterSpacing: "0.2em",
      margin: 0,
    },
    body1: {
      fontFamily: baseFontF,
      fontWeight: 400,
      fontSize: mobile ? "0.8rem" : "1rem",
      margin: 0,
    },
    body2: {
      fontFamily: baseFontF,
      fontWeight: 300,
      fontSize: mobile ? "0.8rem" : "1rem",
      margin: 0,
    },
    fontAsCSS: function (font: Font) {
      let result = "";
      const f: FontConfig = presentationBaseTheme[font];
      if (f.fontFamily) {
        result += `font-family: ${f.fontFamily}; `;
      }
      if (f.fontSize) {
        result += `font-size: ${f.fontSize}; `;
      }
      if (f.fontWeight) {
        result += `font-weight: ${f.fontWeight}; `;
      }
      if (f.letterSpacing) {
        result += `letter-spacing: ${f.letterSpacing}; `;
      }
      if (f.textTransform) {
        result += `text-transform: ${f.textTransform}; `;
      }
      result += `color: ${baseTheme.av8.primaryColor};`;
      return result;
    },
    spacing: (factor: number) => {
      return (mobile ? 0.5 : 1) * factor + "rem";
    },
    mobile: mobile,
  };
  return {
    av8: baseTheme.av8,
    presentation: presentationBaseTheme,
  };
};

// Delete later after migration to StyledComponent
export const generatePresentationTheme = (
  mobile: boolean,
  baseTheme: { av8: PresentationTheme["av8"]; presentation: PresentationTheme["presentation"] }
) => {
  const baseFontF = baseTheme.av8.typography.fontFamily;
  const headerFontF = baseTheme.av8.typography.headers.fontFamily;
  const themeData: ThemeOptions = {
    typography: {
      fontFamily: baseFontF,
      ["headerFontFamily" as any]: headerFontF, //workaround to add custom fonts
      h1: {
        //big title
        fontFamily: headerFontF,
        fontSize: mobile ? "1.5rem" : "3rem",
        fontWeight: 400,
      },
      h2: {
        //big title
        fontFamily: headerFontF,
        fontSize: mobile ? "1.25rem" : "1.5rem",
        fontWeight: 400,
      },
      subtitle1: {
        fontFamily: baseFontF,
        fontSize: "0.6rem",
        fontWeight: 400,
        textTransform: "uppercase",
        letterSpacing: "0.3em",
      },
      body2: {
        fontFamily: baseFontF,
        fontWeight: 300,
        fontSize: mobile ? "0.8rem" : "1rem",
      },
    },
    palette: {
      primary: {
        main: baseTheme.av8.primaryColor,
      },
      background: {
        default: baseTheme.av8.background,
      },
    },
    av8: baseTheme.av8,
    presentation: baseTheme.presentation,
  };

  return createTheme(themeData);
};

export const PresentationThemeProvider = ({
  mobile,
  children,
}: {
  mobile: boolean;
  children: React.ReactNode;
}) => {
  const av8UI2Theme = useAv8UI2Theme();
  const styledTheme = React.useMemo(() => {
    return generatePresentationStyledTheme(mobile, av8UI2Theme);
  }, [mobile, av8UI2Theme]);
  const legacyMaterialTheme = React.useMemo(() => {
    return generatePresentationTheme(mobile, styledTheme);
  }, [mobile, styledTheme]);

  // I compared both versions, with and without the ThemeProvider from av8-ui2 and it has just a few different results:
  // Maybe it is time to move entirely to our ThemeProvider or combine both using the same structure instead of this used on generatePresentationTheme
  return (
    <EmotionThemeProvider theme={styledTheme}>
      <MuiThemeProvider theme={legacyMaterialTheme}>
        <ThemeProvider darkMode={false}>{children}</ThemeProvider>
      </MuiThemeProvider>
    </EmotionThemeProvider>
  );
};

import { createContext, useContext, useState } from "react";
import { GalleryPhoto, PhotoGallery } from "@avenue-8/ui-2";

export interface PhotoGalleryContextType {
  photoIndex: number;
  galleryPhotos: GalleryPhoto[];
  resetGallery: () => void;
  changeGalleryPhoto: (index: number, galleryPhotos: GalleryPhoto[]) => void;
}

export const PhotoGalleryContext = createContext<PhotoGalleryContextType | null>(null);

export function PhotoGalleryContextProvider(
  props: React.PropsWithChildren<Record<string, unknown>>
) {
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [galleryPhotos, setGalleryPhotos] = useState<GalleryPhoto[]>([]);

  const resetGallery = () => {
    setGalleryPhotos([]);
    setPhotoIndex(-1);
  };

  const changeGalleryPhoto = (index: number, photos: GalleryPhoto[]) => {
    if (galleryPhotos.length === 0) {
      setGalleryPhotos(photos);
    }
    setPhotoIndex(index);
  };

  const value = {
    photoIndex,
    galleryPhotos,
    resetGallery,
    changeGalleryPhoto,
  };

  const clickedPhoto = galleryPhotos.length > 0 && photoIndex !== -1;

  return (
    <PhotoGalleryContext.Provider value={value}>
      {clickedPhoto && (
        <PhotoGallery
          open={photoIndex !== -1}
          onClose={resetGallery}
          photos={galleryPhotos || []}
          initialIndex={photoIndex}
          fullWidth
        />
      )}
      {props.children}
    </PhotoGalleryContext.Provider>
  );
}

export const usePhotoGalleryContext = () => {
  const context = useContext(PhotoGalleryContext);
  if (!context) {
    throw new Error("usePhotoGalleryContext must be used within the PhotoGalleryContext");
  }
  return context;
};

// STATE

import { ActionInvoker, makeReducerContext } from "./modules/shared/hooks/makeReducerContext";

interface State {
  activePromises: Array<{ promise: Promise<any>; blocking: boolean; message?: string }>;
  confirm?: {
    resolve: ((accept: boolean) => void) | null;
    message: string;
    title?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
  };
}

const defaultState: State = {
  activePromises: [],
};

// ACTION PAYLOADS

interface WatchPromise {
  type: "watch-promise";
  start: boolean;
  blocking: boolean;
  promise: Promise<any>;
  message?: string;
}
interface ShowConfirmDialog {
  type: "show-confirm-dialog";
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  resolve: (accept: boolean) => void;
}
interface CloseConfirmDialog {
  type: "close-confirm-dialog";
}

type Action = WatchPromise | ShowConfirmDialog | CloseConfirmDialog;

interface ActionInvokerContext {}

// ACTION INVOKER
class AppActions extends ActionInvoker<Action, ActionInvokerContext, State> {
  confirm = async ({
    title,
    message,
    confirmButtonText,
    cancelButtonText,
  }: {
    title: string;
    message: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
  }): Promise<boolean> => {
    const result = await new Promise<boolean>((resolve) => {
      this.dispatch({
        type: "show-confirm-dialog",
        resolve,
        message,
        title,
        confirmButtonText,
        cancelButtonText,
      });
    });
    this.dispatch({ type: "close-confirm-dialog" });
    return result;
  };

  watchPromise = <T,>(
    promise: Promise<T>,
    { blocking = false, message }: { blocking?: boolean; message?: string } = {}
  ) => {
    this.dispatch({ type: "watch-promise", start: true, promise, blocking, message });
    promise.then(
      () => {
        this.dispatch({ type: "watch-promise", start: false, promise, blocking, message });
      },
      () => {
        this.dispatch({ type: "watch-promise", start: false, promise, blocking, message });
      }
    );
    return promise;
  };
}
const defaultInvoker = new AppActions();

//REDUCER
const reducer: (state: State, action: Action) => State = (state, action) => {
  switch (action.type) {
    case "watch-promise": {
      const { blocking, start, promise, message } = action;
      if (start) {
        return {
          ...state,
          activePromises: [...state.activePromises, { promise, blocking, message }],
        };
      } else {
        return {
          ...state,
          activePromises: state.activePromises.filter((x) => x.promise !== promise),
        };
      }
    }
    case "show-confirm-dialog": {
      const { title, message, confirmButtonText, cancelButtonText, resolve } = action;
      return {
        ...state,
        confirm: { title, message, confirmButtonText, cancelButtonText, resolve },
      };
    }
    case "close-confirm-dialog": {
      const { message = "", ...confirmRest } = state.confirm ?? {};
      return { ...state, confirm: { ...confirmRest, message, resolve: null } };
    }
    default:
      break;
  }
  console.warn(`Could not find reducer handler for action ${(action as any).type}`);
  return state;
};

export const { useReducerContext: useAppContext, ReducerContextProvider: AppContextProvider } =
  makeReducerContext<State, Action, ActionInvokerContext, AppActions>({
    reducer,
    defaultState,
    defaultInvoker,
  });

import { PatchCMARecurringCostItemDto } from "./../../../shared/apis/cma/generated/models/PatchCMARecurringCostItemDto";
import { CMADto, SubjectProperty } from "../../../shared/apis/cma/generated";
import {
  stripNonNumericNumbers,
  strToNullableFloat,
  strToNullableInt,
  strToNullableString,
} from "../../../shared/utils/converters";

export interface SubjectPropertyStepModel {
  cmaId: string;
  agentId: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  mlsId: string;
  listingPrice: string;
  listDate: string;
  soldPrice: string;
  soldDate: string;
  neighborhood: string;
  livingSpace: string;
  lotSize: string;
  beds: string;
  baths: string;
  garageSpaces: string;
  yearBuilt: string;
  propertyType: string;
  propertyTypeOther: string;
  buildingStyles: string[];
  buildingStyleOther: string;
  lat: string;
  lng: string;
  floor: string;
  doorman: string;
  description: string;
  updateDate: string;
  flipTaxRemarks: string;
  roomsTotal: string;
  lease: string;
  listingStatus: string;

  // While we don't have an interface to add custom fees
  monthlyHoa?: string;
  annualTax?: string;
}

type BuildRecurringCostsParams = {
  annualTax: SubjectPropertyStepModel["annualTax"];
  monthlyHoa: SubjectPropertyStepModel["monthlyHoa"];
  state: SubjectPropertyStepModel["state"];
  propertyType: SubjectPropertyStepModel["propertyType"];
};
export const getRecurringCostsData = (input: BuildRecurringCostsParams) => {
  const recurringCostsData = {
    tax: {
      label: "Tax",
      value: normalizeNumber(input.annualTax),
      type: "tax",
      frequency: "yearly",
    },
    "hoa-fees": {
      label: "HOA Fees",
      value: normalizeNumber(input.monthlyHoa),
      type: "hoa-fees",
      frequency: "monthly",
    },
  };

  const isNYProperty = input?.state === "NY";
  if (isNYProperty) {
    if (input.propertyType === "Coop") {
      recurringCostsData["hoa-fees"].label = "Maintenance";
    } else {
      recurringCostsData["hoa-fees"].label = "Common Charges";
    }
  } else {
    if (input?.propertyType === "Condominium") {
      recurringCostsData["hoa-fees"].label = "HOA Fees";
    } else {
      recurringCostsData["hoa-fees"].label = "Monthly Fees";
    }
  }

  return recurringCostsData;
};

export const buildRecurringCosts = (input: BuildRecurringCostsParams) => {
  const recurringCosts = Object.values(getRecurringCostsData(input));
  return recurringCosts;
};

// Experimental. Since the form will be alterning from 1 field to 4 fields, test the usability, maybe always show the fields is the best option.
export const getPropertyFieldsToShow = (input: { state: SubjectPropertyStepModel["state"] }) => {
  const isNYProperty = input?.state === "NY";

  const fields = {
    annualTax: isNYProperty,
    flipTaxRemarks: isNYProperty,
    monthlies: isNYProperty,
  };

  return fields;
};

const parseRecurringCosts = (
  recurringCosts: PatchCMARecurringCostItemDto[],
  data: SubjectPropertyStepModel
) => {
  return recurringCosts.reduce((acc, curr) => {
    if (curr.type === "hoa-fees") {
      acc.monthlyHoa = curr.value?.toString() ?? "";
    }
    if (curr.type === "tax") {
      acc.annualTax = curr.value?.toString() ?? "";
    }
    return acc;
  }, data);
};

export const normalizeNumber = (input: string | null | undefined) =>
  strToNullableInt(stripNonNumericNumbers(input));

interface Photo {
  id: string;
  url: string;
  caption?: string;
}

export const convertSubjectPropertyStepModelToSubjectProperty = (
  data: SubjectPropertyStepModel
) => {
  const hoaFee = normalizeNumber(data.monthlyHoa);

  const subjectProperty: SubjectProperty = {
    addressLine1: strToNullableString(data.addressLine1),
    addressLine2: strToNullableString(data.addressLine2),
    bathCount: strToNullableFloat(data.baths),
    bedCount: strToNullableInt(data.beds),
    city: strToNullableString(data.city),
    currentPrice: normalizeNumber(data.listingPrice),
    description: strToNullableString(data.description),
    floor: strToNullableInt(data.floor),
    garageSpaces: normalizeNumber(data.garageSpaces),
    hoaFeeCents: hoaFee != null ? hoaFee * 100 : null,
    lat: strToNullableFloat(data.lat),
    listDate: data.listDate ? new Date(data.listDate) : null,
    livingAreaSqFt: normalizeNumber(data.livingSpace),
    lng: strToNullableFloat(data.lng),
    lotSizeSqFt: normalizeNumber(data.lotSize),
    mlsId: data.mlsId?.replace(/[^a-zA-Z0-9]/g, ""),
    neighborhood: strToNullableString(data.neighborhood),
    state: strToNullableString(data.state),
    styleOther: strToNullableString(data.buildingStyleOther),
    styles: data.buildingStyles ?? null,
    type: strToNullableString(data.propertyType),
    typeOther: strToNullableString(data.propertyTypeOther),
    yearBuilt: strToNullableInt(data.yearBuilt),
    zipCode: strToNullableString(data.zip),
    hasDoormanAttendance: data.doorman === "yes",
    roomsTotal: normalizeNumber(data.roomsTotal),
    flipTaxRemarks: strToNullableString(data.flipTaxRemarks),
    recurringCosts: buildRecurringCosts({
      annualTax: data.annualTax,
      monthlyHoa: data.monthlyHoa,
      propertyType: data.propertyType,
      state: data.state,
    }),
    isLease: data.lease === "yes",
    listingStatus: strToNullableString(data.listingStatus),
    soldDate: data.soldDate ? new Date(data.soldDate) : null,
    soldPrice: normalizeNumber(data.soldPrice),
  };

  return subjectProperty;
};

export const convertCmaToSubjectPropertyStepModel = (cma: CMADto) => {
  const parseISOStringDate = (date?: string | Date) => {
    return date ? new Date(date).toISOString()?.substring(0, 10) : "";
  };

  const sp = cma.subjectProperty;
  const data: SubjectPropertyStepModel = {
    addressLine1: sp.addressLine1 ?? "",
    addressLine2: sp.addressLine2 ?? "",
    agentId: cma.agent?.id.toString() ?? "",
    baths: sp.baths?.toString() ?? "",
    beds: sp.beds?.toString() ?? "",
    buildingStyleOther: sp.propertyStyleOther ?? "",
    buildingStyles: sp.propertyStyles?.filter((x) => x?.trim()) ?? [],
    city: sp.city ?? "",
    cmaId: cma.id,
    description: sp.description ?? "",
    doorman: sp.hasDoormanAttendance ? "yes" : "no",
    floor: sp.floor?.toString() ?? "",
    garageSpaces: sp.garageSpaces?.toString() ?? "",
    lat: sp.latitude?.toString() ?? "",
    listDate: parseISOStringDate(sp.listDate) ?? "",
    listingPrice: sp.listPrice?.toString() ?? "",
    livingSpace: sp.livingSpace?.toString() ?? "",
    lng: sp.longitude?.toString() ?? "",
    lotSize: sp.lotSize?.toString() ?? "",
    mlsId: sp.mlsId ?? "",
    monthlyHoa: sp.hoaFees?.toString() ?? "",
    neighborhood: sp.neighborhood ?? "",
    propertyType: sp.propertyType ?? "",
    propertyTypeOther: sp.propertyTypeOther ?? "",
    state: sp.state ?? "",
    updateDate: cma.updateDate?.toISOString() ?? "",
    yearBuilt: sp.yearBuilt?.toString() ?? "",
    zip: sp.zipCode ?? "",
    flipTaxRemarks: sp.flipTaxRemarks ?? "",
    roomsTotal: sp.roomsTotal?.toString() ?? "",
    lease: sp.isLease ? "yes" : "no",
    listingStatus: sp.listingStatus ?? "",
    soldPrice: sp.soldPrice?.toString() ?? "",
    soldDate: parseISOStringDate(sp.soldDate) ?? "",
  };

  const { monthlyHoa, annualTax } = parseRecurringCosts(sp.recurringCosts ?? [], data);

  data.monthlyHoa = monthlyHoa ?? "";
  data.annualTax = annualTax ?? "";

  return data;
};

export const getSubjectPropertyPhotos = (cma: CMADto) => {
  return cma.subjectProperty?.images?.map(({ id, url, cdnUrl, caption }) => {
    const photo: Photo = {
      id,
      url: cdnUrl || url,
      caption,
    };
    return photo;
  });
};

export const SUBJECT_PROPERTY_FORM_DEFAULT_STATE: SubjectPropertyStepModel = {
  cmaId: "",
  agentId: "",
  updateDate: "",
  addressLine1: "",
  addressLine2: "",
  baths: "",
  beds: "",
  city: "",
  description: "",
  floor: "",
  garageSpaces: "",
  lat: "",
  listDate: "",
  listingPrice: "",
  soldPrice: "",
  soldDate: "",
  livingSpace: "",
  lng: "",
  lotSize: "",
  mlsId: "",
  neighborhood: "",
  state: "",
  buildingStyleOther: "",
  buildingStyles: [],
  propertyType: "",
  propertyTypeOther: "",
  yearBuilt: "",
  zip: "",
  doorman: "",
  flipTaxRemarks: "",
  roomsTotal: "",
  lease: "",
  listingStatus: "",

  // Taxes
  monthlyHoa: "",
  annualTax: "",
};

import {
  PropertyControllerListSuggestionsRequest,
  PropertySuggestionDto,
  SuggestionsResponseDto,
} from "../../../shared/apis/cma/generated";

export const suggestionFields = [
  "address",
  "city",
  "neighborhood",
  "area",
  "zipCode",
  "mlsId",
  "county",
] as const;

export type SuggestionFields = typeof suggestionFields[number];
export interface SuggestionsQueryModel {
  text: string;
  size: number;
}

export const convertSuggestionsQueryToSuggestionsRequest = (
  input: SuggestionsQueryModel
): PropertyControllerListSuggestionsRequest => {
  const convertedForm: PropertyControllerListSuggestionsRequest = {
    ...input,
  };

  return convertedForm;
};

export const INITIAL_FORM_SUGGESTIONS = {
  text: "",
  size: 5,
  address: true,
  county: true,
  neighborhood: true,
  area: true,
  city: true,
  zipCode: true,
  mlsId: true,
};

export interface SuggestionOption extends PropertySuggestionDto {
  type: SuggestionFields;
  typeLabel: string;
}

export const parseSuggestions = (
  rawSuggestions: SuggestionsResponseDto,
  acceptedSuggestions?: SuggestionFields[]
): SuggestionOption[] => {
  const suggestions: SuggestionOption[] = [];
  const {
    addressSuggestions,
    citySuggestions,
    areaSuggestions,
    neighborhoodSuggestions,
    zipCodeSuggestions,
    mlsIdSuggestions,
    countySuggestions,
  } = rawSuggestions;

  if (countySuggestions && (!acceptedSuggestions || acceptedSuggestions?.includes("county"))) {
    const rawSuggestions = countySuggestions.filter((x) => x.county);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "county",
      typeLabel: "County",
    }));
    suggestions.push(...typedSuggestions);
  }

  if (addressSuggestions && (!acceptedSuggestions || acceptedSuggestions?.includes("address"))) {
    const rawSuggestions = addressSuggestions.filter((x) => x.addressLine1);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "address",
      typeLabel: "Address",
    }));
    suggestions.push(...typedSuggestions);
  }

  if (citySuggestions && (!acceptedSuggestions || acceptedSuggestions?.includes("city"))) {
    const rawSuggestions = citySuggestions.filter((x) => x.city);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "city",
      typeLabel: "City",
    }));
    suggestions.push(...typedSuggestions);
  }

  if (areaSuggestions && (!acceptedSuggestions || acceptedSuggestions?.includes("area"))) {
    const rawSuggestions = areaSuggestions.filter((x) => x.area);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "area",
      typeLabel: "Area",
    }));
    suggestions.push(...typedSuggestions);
  }

  if (
    neighborhoodSuggestions &&
    (!acceptedSuggestions || acceptedSuggestions?.includes("neighborhood"))
  ) {
    const rawSuggestions = neighborhoodSuggestions.filter((x) => x.neighborhood);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "neighborhood",
      typeLabel: "Neighborhood",
    }));
    suggestions.push(...typedSuggestions);
  }

  if (zipCodeSuggestions && (!acceptedSuggestions || acceptedSuggestions?.includes("zipCode"))) {
    const rawSuggestions = zipCodeSuggestions.filter((x) => x.zipCode);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "zipCode",
      typeLabel: "Zip code",
    }));
    suggestions.push(...typedSuggestions);
  }

  if (mlsIdSuggestions && (!acceptedSuggestions || acceptedSuggestions?.includes("mlsId"))) {
    const rawSuggestions = mlsIdSuggestions.filter((x) => x.mlsId);
    const typedSuggestions: SuggestionOption[] = rawSuggestions.map((x) => ({
      ...x,
      type: "mlsId",
      typeLabel: "MLS ID",
    }));
    suggestions.push(...typedSuggestions);
  }
  return suggestions;
};

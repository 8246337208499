import { styled } from "@avenue-8/ui-2";

export const StyledMap = styled.div`
  position: sticky;
  width: 100%;
  min-height: 38rem;
  max-height: 38rem;
  overflow: hidden;
  top: 104px;
`;
export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 38rem;
  max-height: 38rem;
  background-color: ${(p) => p.theme.av8.backgroundAlt};
`;

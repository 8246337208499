import * as React from "react";
import { SectionListEditor } from "../SectionListEditor";
import { SectionView } from "src/modules/presentation/presentation-generation-logic/models/section-view";
import {
  IconButton,
  InputLabel,
  DeleteIcon,
  ListItemText,
  Autocomplete,
  AutocompleteRenderInputParams,
} from "@avenue-8/ui-2";
import {
  PresentationSourceData,
  SectionConfig,
} from "src/modules/presentation/presentation-generation-logic/models";
import { SaveCMATemplateModal } from "../SaveCMATemplateModal";
import { TextFieldBase } from "../../../../../TextFieldBase";
import { TemplateListItemDto } from "src/modules/shared/apis/presentation/generated";
import { Controller, useForm } from "react-hook-form";
import { ResponsiveContextMenu } from "../../../../../ResponsiveContextMenu";
import { SectionEditorOnChangeHandler } from "../SectionEditor/SectionEditor";
import {
  Sidebar,
  SidebarHeader,
  TemplateOption,
  TemplateOptionText,
  SidebarFooter,
  SaveButton,
} from "./SidebarLayout.styles";

interface Props {
  title?: string;
  sidebarContent?: React.ReactNode;
  children?: React.ReactNode;
  toolbarContent?: React.ReactNode;
  sectionsConfig?: SectionConfig[];
  sectionsView?: SectionView[];
  templates?: TemplateListItemDto[];
  templateId: string | undefined | null;
  onMoveSection?: (from: number, to: number) => void;
  onUpdateSection: SectionEditorOnChangeHandler;
  onToggleSectionVisibility: (id: string) => void;
  handleSelectTemplate: (templateId: string) => void;
  handleDeleteTemplate: (template: TemplateListItemDto) => void;
  onAddFreeTextSection: () => void;
  onAddInsightsSection: () => void;
  onAddTeamProfileSection: () => void;
  onDeleteSection: (id: string) => void;
  onSectionExpanded?: (id?: string) => void;
  sourceData: PresentationSourceData;
}

export const SidebarLayout = (props: Props) => {
  const {
    sectionsConfig,
    sectionsView,
    templates,
    onMoveSection,
    onUpdateSection,
    onDeleteSection,
    onToggleSectionVisibility,
    handleSelectTemplate,
    handleDeleteTemplate,
    onAddFreeTextSection,
    onAddInsightsSection,
    onAddTeamProfileSection,
    templateId,
    onSectionExpanded,
    sourceData,
  } = props;
  const { errors, control } = useForm({
    mode: "onChange",
    defaultValues: { template: "" },
  });
  const [currentTemplateId, setCurrentTemplateId] = React.useState<string | undefined | null>(
    templateId
  );
  const [saveCmaTemplateModal, setSaveCmaTemplateModalOpen] = React.useState(false);
  const [menuRef, setMenuRef] = React.useState<any>(null);
  const showTemplateUi = sourceData.presentationType === "cma";

  React.useEffect(() => {
    if (templateId !== currentTemplateId) {
      setCurrentTemplateId(templateId);
    }
  }, [templateId, currentTemplateId]);

  return (
    <Sidebar>
      {showTemplateUi && (
        <SidebarHeader>
          <InputLabel htmlFor="template">SAVED TEMPLATE</InputLabel>
          <Controller
            name="template"
            control={control}
            render={({ onChange, value, ...rest }) => (
              <Autocomplete
                id="template"
                fullWidth
                disableClearable={true}
                value={value.id || null}
                getOptionLabel={(option: TemplateListItemDto) => option.name || ""}
                options={templates || []}
                disabled={!templates?.length}
                {...rest}
                renderInput={(params: AutocompleteRenderInputParams) => {
                  return (
                    <TextFieldBase
                      error={!!errors.template}
                      {...params}
                      inputProps={{ ...params.inputProps }}
                    />
                  );
                }}
                renderOption={(liProps, template: TemplateListItemDto, { selected }) => (
                  <TemplateOption {...liProps} $selected={selected}>
                    <TemplateOptionText>{template.name}</TemplateOptionText>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteTemplate(template);
                      }}
                      data-testid="delete-template"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TemplateOption>
                )}
                onChange={(_, value) => {
                  onChange(value.id);
                  handleSelectTemplate(value?.id);
                }}
              />
            )}
          />
        </SidebarHeader>
      )}
      <SectionListEditor
        onSortEnd={({ oldIndex, newIndex }) => {
          onMoveSection?.(oldIndex, newIndex);
        }}
        sectionsConfig={sectionsConfig || []}
        sectionsView={sectionsView || []}
        onUpdateSection={onUpdateSection}
        onDeleteSection={onDeleteSection}
        onToggleSectionVisibility={onToggleSectionVisibility}
        templateId={currentTemplateId}
        onItemExpanded={onSectionExpanded}
        sourceData={sourceData}
      />
      <SidebarFooter>
        <SaveButton
          fullWidth
          variant="text"
          onClick={(e) => {
            setMenuRef(e.currentTarget);
          }}
          data-testid="add-new-section"
        >
          Add New Section
        </SaveButton>
        <SectionsMenu
          anchorEl={menuRef}
          onClose={() => setMenuRef(null)}
          onAddFreeTextSection={onAddFreeTextSection}
          onAddInsightsSection={onAddInsightsSection}
          onAddTeamProfileSection={onAddTeamProfileSection}
          data-testid="new-sections-menu"
        />
        {showTemplateUi && (
          <SaveButton
            fullWidth
            variant="text"
            onClick={() => setSaveCmaTemplateModalOpen(true)}
            data-testid="save-current-template"
          >
            Save current template
          </SaveButton>
        )}
      </SidebarFooter>
      <SaveCMATemplateModal
        open={saveCmaTemplateModal}
        onClose={() => setSaveCmaTemplateModalOpen(false)}
      />
    </Sidebar>
  );
};

const SectionsMenu = ({
  onClose,
  anchorEl,
  onAddFreeTextSection,
  onAddInsightsSection,
  onAddTeamProfileSection,
}: {
  onClose: () => void;
  anchorEl: any;
  onAddFreeTextSection: () => void;
  onAddInsightsSection: () => void;
  onAddTeamProfileSection: () => void;
}) => {
  return (
    <ResponsiveContextMenu
      menuPositionProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
    >
      {({ ItemComponent }) => {
        return [
          <ItemComponent
            key="free-text-section"
            onClick={() => {
              onAddFreeTextSection();
              onClose();
            }}
          >
            <ListItemText primary="Free Text Section" />
          </ItemComponent>,
          <ItemComponent
            key="insights-section"
            onClick={() => {
              onAddInsightsSection();
              onClose();
            }}
          >
            <ListItemText primary="Insights" />
          </ItemComponent>,
          <ItemComponent
            key="team-profile-section"
            onClick={() => {
              onAddTeamProfileSection();
              onClose();
            }}
          >
            <ListItemText primary="Team Profile Section" />
          </ItemComponent>,
        ];
      }}
    </ResponsiveContextMenu>
  );
};

import { Button, styled } from "@avenue-8/ui-2";

export const Box = styled.div`
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Message = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const LoadFailedBox = () => {
  return (
    <Box>
      <Message>Oops. Something went wrong.</Message>
      <Message>To try again, reload this page.</Message>
      <Button variant="text" onClick={() => window.location.reload()}>
        Reload Page
      </Button>
    </Box>
  );
};

import { Grid, Typography, styled } from "@avenue-8/ui-2";

export const SummaryStatisticsContainer = styled.div`
  border: 1px solid ${(p) => p.theme.av8.input.borderColor};
  border-radius: 4px 4px 0px 0px;
`;

export const HeaderContainer = styled.div`
  padding: 1.5rem 1.8rem;
  border-bottom: 1px solid ${(p) => p.theme.av8.input.borderColor};
  background-color: ${(p) => p.theme.av8.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled(Typography)`
  font-family: ${(p) => p.theme.av8.typography.headers.fontFamily};
  font-size: 20px;
  color: ${(p) => p.theme.av8.typography.primaryColor};
`;

export const ResultContainer = styled(Grid)`
  width: 100%;
  background-color: ${(p) => p.theme.av8.background};
  padding-top: 33px;
  padding-left: 31px;
`;

export const DimensionTitle = styled(Typography)`
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  font-weight: 700;
  font-size: 10px;
  letter-spacing: 2px;
  margin-bottom: 31px;
  color: ${(p) => p.theme.av8.typography.primaryColor};
  text-transform: uppercase;
`;

export const MetricTitle = styled(Typography)`
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  font-size: 10px;
  letter-spacing: 2px;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  margin-bottom: 30px;
  color: ${(p) => p.theme.av8.typography.primaryColor};
`;

export const ResultValue = styled(Typography)`
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  font-size: 14px;
  text-align: left;
  margin-bottom: 28px;
  color: ${(p) => p.theme.av8.typography.primaryColor};
`;

import { Button, ButtonProps, styled } from "@avenue-8/ui-2";
import { GoogleSignInIcon } from "../../../assets/icons/GoogleSignInIcon";
import React from "react";

const LoginButton = styled(Button)`
  background-color: rgb(255, 255, 255);
  display: flex;
  gap: 1rem;
  align-items: center;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  text-transform: initial;
  letter-spacing: initial;
`;

export const GoogleLoginButton: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <LoginButton {...props} role="button">
      <GoogleSignInIcon />
      <span>Log in with Google</span>
    </LoginButton>
  );
};

import * as React from "react";
import { Location } from "history";
import { useHistory, Prompt, useLocation } from "react-router";

type RouteChangeResult = "navigate" | "cancel";

export const RouteChangeInterceptor = (props: {
  onIntercepting?: (location: Location) => Promise<RouteChangeResult>;
  onLoadingChanged?: (loading: boolean) => void;
}) => {
  const { onIntercepting, onLoadingChanged } = props;
  const lastLocation = React.useRef<Location | null>(null);
  const promptBehavior = React.useRef<RouteChangeResult>("cancel");
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    lastLocation.current = null;
    promptBehavior.current = "cancel";
  }, [location]);

  const handlePrompting = React.useCallback(
    (shouldPromptResult: RouteChangeResult) => {
      promptBehavior.current = shouldPromptResult;
      if (promptBehavior.current === "cancel") return;
      else if (promptBehavior.current === "navigate") {
        // history.push will trigger handleLocationChange again
        // but in this time it should allow to proceed to next location without prompting
        if (lastLocation.current) history.push(lastLocation.current.pathname);
      }
    },
    [history]
  );

  const handleLocationChanged = React.useCallback(
    (location: Location): boolean => {
      lastLocation.current = location;
      if (onIntercepting != null) {
        if (promptBehavior.current === "navigate") return true; //in case it was defined before

        onLoadingChanged?.(true);
        onIntercepting(location)
          .then(handlePrompting, (e) => {
            console.error(e);
          })
          .finally(() => {
            onLoadingChanged?.(false);
          });
        return false;
      }
      return false;
    },
    [handlePrompting, onIntercepting, onLoadingChanged]
  );

  return <Prompt when={true} message={handleLocationChanged} />;
};

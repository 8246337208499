import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button, ChevronIcon, styled } from "@avenue-8/ui-2";
import { PageLayout } from "../../cma-v2/components/PageLayout"; // Should be exported from a shared pholder later?
import { useBreakpoint, useMobile } from "../../cma-v2/hooks/useMobile"; // Should be exported from a shared pholder later?
import { SmartUserMenu } from "../../cma-v2/components/SmartUserMenu"; // Should be exported from a shared pholder later?
import { Logo } from "../../shared/components/Logo";
import { AgentOnboardingStepper, AgentOnboardingSteps } from "./AgentOnboardingStepper";
import { useAgentOnboardingLogic } from "../hooks/useAgentOnboardingLogic";
import { PersonalDetailsStep } from "./Steps/PersonalDetailsStep/PersonalDetailsStep";
import { PersonalDetailsFormModel } from "./Steps/PersonalDetailsStep/PersonalDetailsForm";
import { useAppContext } from "../../../AppContext";
import { UploadHeadshotStep } from "./Steps/UploadHeadshotStep/UploadHeadshotStep";
import { AgentDetailsStep } from "./Steps/AgentDetailsStep/AgentDetailsStep";
import { AgentDetailsFormModel } from "./Steps/AgentDetailsStep/AgentDetailsForm";
import { ReviewFormModel, ReviewStep } from "./Steps/ReviewStep/ReviewStep";
import { FinishStep } from "./Steps/FinishStep/FinishStep";
import {
  convertAgentDetailsFormModelToAgentOnboarding,
  convertPersonalDetailsFormModelToAgentOnboarding,
  convertReviewFormModelToAgentOnboarding,
} from "./agent-onboarding-converter";
import { cmaRoutes } from "src/modules/cma-v2/cma.routes";

const StyledUserMenu = styled(SmartUserMenu)`
  margin: 0 auto;
`;

const StepBottomBar = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  width: 100%;
  background-color: ${(p) => p.theme.av8.background};
`;

const NextButton = styled(Button)`
  min-width: 190px;
  svg {
    margin-right: -16px;
    margin-left: 4px;
  }
`;

const BackButton = styled(Button)`
  min-width: 120px;
`;

const StepCardLayout = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  padding: 2rem 2rem 2rem 2.5rem;
  background-color: ${(p) => p.theme.av8.background};
  box-shadow: 0px 4px 40px ${(p) => p.theme.av8.boxShadowColor};
  border-radius: 4px;
  width: 100%;
  max-width: 780px;
  div {
    box-sizing: border-box;
  }
`;

const StepHeader = styled.div`
  width: 100%;
  padding: 0 1rem 0 2.5rem;
`;

const StepContent = styled.div`
  width: 100%;
  padding: 0.5rem 2.5rem;
`;

const Footer = styled.div`
  padding: 2.5rem;
`;

export interface AgentOnboardingStepBaseProps {
  formId: string;
}

const routeSteps = Object.keys(AgentOnboardingSteps)
  .map((key) => key.toLowerCase().replace(/ /g, "-"))
  .filter((x) => isNaN(Number(x)));

export const AgentOnboarding = () => {
  const [currentStep, setCurrentStep] = useState<AgentOnboardingSteps>(
    AgentOnboardingSteps["Personal Details"]
  );
  const [agreedMobileExperience, setAgreedMobileExperience] = useState(false);
  const { actions: appActions } = useAppContext();
  const { actions } = useAgentOnboardingLogic();
  const isSmall = useBreakpoint("desktopDown");
  const isMobile = useMobile();
  const { state } = useAgentOnboardingLogic();
  const history = useHistory();
  const formId = `agent-onboarding-flow-${currentStep}`;

  useEffect(() => {
    const index = state.step ? routeSteps.indexOf(state.step as any) : -1;
    if (index !== -1) {
      setCurrentStep(index);
    } else {
      history.push(`/agent-onboarding`);
    }
  }, [state.step, history]);

  useEffect(() => {
    const handleMobileExperience = async () => {
      await appActions.confirm({
        title: "Mobile experience",
        message:
          "This experience is best had on a computer. Your onboarding may not be optimized for mobile.",
        confirmButtonText: "Continue",
      });
      setAgreedMobileExperience(true);
    };
    if (isMobile && !agreedMobileExperience) {
      handleMobileExperience();
    }
  }, [appActions, isMobile, agreedMobileExperience]);

  const changeStep = useCallback(
    (step: AgentOnboardingSteps) => {
      history.push(`/agent-onboarding/${routeSteps[step]}`);
    },
    [history]
  );

  useEffect(() => {
    if (
      currentStep === AgentOnboardingSteps["Finish"] &&
      !state.loadAgentOnboardingQuery.data?.isFinished
    ) {
      history.push(`/agent-onboarding`);
      return;
    }

    if (state.loadAgentOnboardingQuery.data?.isFinished) {
      changeStep(AgentOnboardingSteps["Finish"]);
    }
  }, [changeStep, currentStep, history, state.loadAgentOnboardingQuery.data]);

  const handleBackClick = () => {
    if (currentStep < 1) {
      changeStep(AgentOnboardingSteps["Personal Details"]);
      return;
    }
    changeStep(currentStep - 1);
  };

  const handlePersonalDetailsSave = async (data: PersonalDetailsFormModel) => {
    const personalDetails = convertPersonalDetailsFormModelToAgentOnboarding(data);
    await actions.saveAgentOnboarding({ data: personalDetails });
    changeStep(AgentOnboardingSteps["Agent Details"]);
  };

  const handleAgentDetailsSave = async (data: AgentDetailsFormModel) => {
    const agentDetails = convertAgentDetailsFormModelToAgentOnboarding(data);
    await actions.saveAgentOnboarding({ data: agentDetails });
    changeStep(AgentOnboardingSteps["Upload Headshot"]);
  };

  const handleHeadshotSave = () => {
    changeStep(AgentOnboardingSteps["Review"]);
  };

  const handleReviewSave = async (data: ReviewFormModel) => {
    const reviewForm = convertReviewFormModelToAgentOnboarding(data);
    await actions.saveAgentOnboarding({ data: reviewForm, tryFinish: true });
  };

  const handleFinishSave = () => {
    window.location.replace(cmaRoutes.dashboard.route);
  };

  let stepContent: React.ReactNode;
  if (currentStep === AgentOnboardingSteps["Personal Details"]) {
    stepContent = <PersonalDetailsStep formId={formId} onSave={handlePersonalDetailsSave} />;
  } else if (currentStep === AgentOnboardingSteps["Agent Details"]) {
    stepContent = <AgentDetailsStep formId={formId} onSave={handleAgentDetailsSave} />;
  } else if (currentStep === AgentOnboardingSteps["Upload Headshot"]) {
    stepContent = <UploadHeadshotStep formId={formId} onSave={handleHeadshotSave} />;
  } else if (currentStep === AgentOnboardingSteps["Review"]) {
    stepContent = <ReviewStep formId={formId} onSave={handleReviewSave} />;
  } else if (currentStep === AgentOnboardingSteps["Finish"]) {
    stepContent = <FinishStep formId={formId} onSave={handleFinishSave} />;
  } else {
    stepContent = null;
  }

  return (
    <PageLayout
      userMenu={<StyledUserMenu disableAccountClick={true} />}
      headerContent={<></>}
      logo={<Logo />}
      contentReservedHeight={isSmall ? 96 : 192}
      headerPosition={isSmall ? "static" : "fixed"}
    >
      <StepCardLayout>
        {currentStep < AgentOnboardingSteps["Finish"] && (
          <StepHeader>
            <AgentOnboardingStepper currentStep={currentStep} />
          </StepHeader>
        )}
        <StepContent>{stepContent}</StepContent>
        <StepBottomBar>
          {currentStep < AgentOnboardingSteps["Finish"] ? (
            <>
              {currentStep > AgentOnboardingSteps["Personal Details"] && (
                <BackButton variant="text" onClick={() => handleBackClick()}>
                  Back
                </BackButton>
              )}
              {currentStep < AgentOnboardingSteps["Review"] ? (
                <NextButton form={formId} type="submit">
                  Next
                  <ChevronIcon />
                </NextButton>
              ) : (
                <NextButton form={formId} type="submit">
                  Finish
                </NextButton>
              )}
            </>
          ) : (
            <NextButton form={formId} type="submit">
              Start
              <ChevronIcon />
            </NextButton>
          )}
        </StepBottomBar>
      </StepCardLayout>
      <Footer />
    </PageLayout>
  );
};

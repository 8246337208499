import { useQuery } from "react-query";
import { PropertyControllerListPropertyTypesRequest } from "src/modules/shared/apis/cma/generated";
import { getCMAPropertiesApiClient } from "../../shared/apis/cma/api-factories";

const CACHE_TIME_IN_MS = 1000 * 60 * 10; // 10 minutes

export function usePropertyTypeQueries(state?: PropertyControllerListPropertyTypesRequest) {
  const propertyTypesQuery = useQuery(
    ["property-types", state],
    async () => getCMAPropertiesApiClient().propertyControllerListPropertyTypes({ ...state }),
    {
      cacheTime: CACHE_TIME_IN_MS,
      staleTime: CACHE_TIME_IN_MS,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return { propertyTypesQuery };
}

import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { InputLabel, Grid, Autocomplete, ChevronIcon, CloseIcon, styled } from "@avenue-8/ui-2";
import { isNullOrEmptyString } from "../../../../shared/utils/validations";
import { alphanumericOnly } from "../../../../shared/utils/validation-patterns";
import { TextFieldBase } from "../../../../shared/components/TextFieldBase";
import {
  StateTitleCase,
  usaStatesTitleCase,
  usaStatesTitleCaseDictionary,
} from "../../../../shared/constants/usaStates";

const FormItem = styled(Grid)`
  margin-bottom: 1rem;
`;

export interface AgentDetailsFormModel {
  licenseNumber?: string | null;
  state?: string | null;
  brokerage?: string | null;
  team?: string | null;
}

const validateRequired = (v: any, label: string) =>
  isNullOrEmptyString(v) ? `Missing ${label}` : undefined;

export const AgentDetailsForm = ({
  defaultValues,
  readOnly = false,
}: {
  defaultValues: AgentDetailsFormModel;
  readOnly?: boolean;
}) => {
  const { control, errors, setValue, trigger } = useFormContext<AgentDetailsFormModel>();

  return (
    <>
      <FormItem item xs={12}>
        <InputLabel htmlFor="licenseNumber">License Number</InputLabel>
        <Controller
          name="licenseNumber"
          control={control}
          defaultValue={defaultValues.licenseNumber ?? ""}
          rules={{
            required: {
              value: true,
              message: "Missing License Number",
            },
            pattern: {
              value: alphanumericOnly,
              message: "Invalid License Number",
            },
          }}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter License Number"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
      <FormItem item xs={12}>
        <InputLabel htmlFor="state">State</InputLabel>
        <Controller
          name="state"
          control={control}
          defaultValue={defaultValues.state ?? null}
          rules={{ validate: { required: (v) => validateRequired(v, "State") } }}
          render={({ ref, onChange, ...rest }) => (
            <Autocomplete
              id="state"
              options={usaStatesTitleCase}
              getOptionLabel={(option) => option.name}
              onChange={(_, value) => {
                const state = value as StateTitleCase;
                setValue("state", state?.abbreviation ?? "");
                trigger("state");
              }}
              value={usaStatesTitleCaseDictionary[rest.value] ?? null}
              renderInput={(params) => (
                <TextFieldBase
                  {...params}
                  errors={errors}
                  placeholder={readOnly ? "" : "Enter State"}
                  inputRef={ref}
                  InputProps={{
                    ...params.InputProps,
                    readOnly,
                  }}
                  {...rest}
                />
              )}
              popupIcon={<ChevronIcon rotate={90} />}
              clearIcon={<CloseIcon size={18} />}
              disabled={readOnly}
            />
          )}
        />
      </FormItem>
      <FormItem item xs={12}>
        <InputLabel htmlFor="brokerage">Brokerage</InputLabel>
        <Controller
          name="brokerage"
          control={control}
          defaultValue={defaultValues.brokerage ?? ""}
          rules={{ validate: { required: (v) => validateRequired(v, "Brokerage") } }}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter Brokerage"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
      <FormItem item xs={12}>
        <InputLabel htmlFor="team">Team (optional)</InputLabel>
        <Controller
          name="team"
          control={control}
          defaultValue={defaultValues.team ?? ""}
          render={({ ref, ...rest }) => (
            <TextFieldBase
              placeholder={readOnly ? "" : "Enter Team"}
              inputRef={ref}
              errors={errors}
              InputProps={{
                readOnly,
              }}
              {...rest}
            />
          )}
        />
      </FormItem>
    </>
  );
};

import * as React from "react";
import { Autocomplete, Button } from "@avenue-8/ui-2";
import { TeamProfileSectionConfig } from "src/modules/presentation/presentation-generation-logic/models/section-config";
import { SectionEditorProps } from "../../SectionEditor";
import {
  TeamProfile,
  teams,
  teamsMap,
} from "src/modules/presentation/presentation-generation-logic/sections/team-profile/teams";
import { TextFieldBase } from "src/modules/cma-v2/components/TextFieldBase";

export const TeamProfileEditor = ({
  section,
  onChanged,
  onDelete,
}: SectionEditorProps<TeamProfileSectionConfig>) => {
  const [model, setModel] = React.useState<Partial<TeamProfileSectionConfig>>({ ...section });

  const handleChangeTeamProfile = React.useCallback(
    ({ slug }: { slug: string }) => {
      const newSectionTeam = teamsMap.get(slug);
      if (!newSectionTeam) {
        throw new Error(`Team ${slug} not found`);
      }
      const updatedModel = { ...model, ...newSectionTeam };
      setModel(updatedModel);
      onChanged({ sectionConfig: { ...section, ...updatedModel } });
    },
    [model, onChanged, section]
  );

  return (
    <>
      <div>
        <Autocomplete
          id="teamProfile"
          options={teams.sort((a, b) => a.name.localeCompare(b.name))}
          getOptionLabel={(team) => team.name}
          onChange={(_, value) => {
            const teamProfile = value as TeamProfile;
            handleChangeTeamProfile({ slug: teamProfile.slug });
          }}
          value={model.slug ? teamsMap.get(model.slug) : null}
          renderInput={(params) => <TextFieldBase {...params} label="Choose a team" />}
          disableClearable={!!model.slug}
        />
      </div>
      <Button variant="text" onClick={() => onDelete(section.id)}>
        Delete Section
      </Button>
    </>
  );
};

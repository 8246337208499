import React from "react";
import {
  Dialog,
  DialogProps,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  styled,
  InputLabel,
  CloseIcon,
  IconButton,
} from "@avenue-8/ui-2";
import { useCreateCMALogic } from "../../hooks/useCreateCMALogic";
import { useHistory } from "react-router";
import { Controller, useForm } from "react-hook-form";
import { TextFieldBase } from "../TextFieldBase";
import { isNullOrEmptyString } from "../../../shared/utils/validations";
import { cmaRoutes } from "../../cma.routes";
import { PresentationToggleGroup } from "./PresentationToggleGroup";
import { InputContainer } from "./PresentationToggleGroup/PresentationToggleGroup.styles";

const Form = styled.form`
  margin-bottom: 16px;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export interface CreateEmptyPresentationModalProps extends Omit<DialogProps, "onBackdropClick"> {
  onClose: () => void;
}

interface EmptyPresentationModel {
  clientDisplayName: string;
  presentationType: string;
  presentationName?: string;
}

const defaultValues: EmptyPresentationModel = {
  clientDisplayName: "",
  presentationType: "",
  presentationName: "",
};

export const CreateEmptyPresentationModal = ({
  onSubmit,
  onClose,
  ...rest
}: CreateEmptyPresentationModalProps) => {
  const { control, handleSubmit, errors, watch } = useForm({ defaultValues });
  const { actions, state } = useCreateCMALogic();
  const history = useHistory();
  const selectedType = watch("presentationType");
  const handleSave = async (data: EmptyPresentationModel) => {
    const cma = await actions.createSimpleCMA(data);
    if (cma) {
      data.presentationType === "cma" && history.push(cmaRoutes.edit.generate({ id: cma.id }));
      data.presentationType === "general" &&
        history.push(cmaRoutes.editGeneralPresentation.generate({ id: cma.id }));
    }
  };

  const creating = state.createSimpleCMAStatus === "loading";

  return (
    <Dialog fullWidth maxWidth="sm" onClose={onClose} {...rest}>
      <DialogTitle id="creare-empty-cma-dialog-title">
        Create new
        <CloseButton aria-label="close" onClick={onClose} data-testid="close">
          <CloseIcon />
        </CloseButton>
      </DialogTitle>
      <DialogContent>
        <Form id="create-simple-cma" onSubmit={handleSubmit(handleSave)}>
          <Controller
            name="presentationType"
            control={control}
            rules={{
              validate: {
                required: (v) => (v === "" || v === null ? "Missing presentation type" : undefined),
              },
            }}
            render={(props) => (
              <PresentationToggleGroup
                {...props}
                errorMessage={errors.presentationType?.message}
                onChange={(e, value) => {
                  props.onChange(value);
                }}
              />
            )}
          />
          <InputContainer>
            <InputLabel htmlFor="clientDisplayName">Prepared For</InputLabel>
            <Controller
              name="clientDisplayName"
              control={control}
              rules={{
                validate: {
                  required: (v) => (isNullOrEmptyString(v) ? "Missing Client Name" : undefined),
                },
              }}
              render={({ ref, ...rest }) => (
                <TextFieldBase
                  placeholder="Enter client name"
                  autoFocus
                  disabled={creating}
                  errors={errors}
                  inputRef={ref}
                  {...rest}
                />
              )}
            />
          </InputContainer>
          {selectedType === "general" && (
            <InputContainer>
              <InputLabel htmlFor="presentationName">Presentation Name</InputLabel>
              <Controller
                name="presentationName"
                control={control}
                rules={{
                  validate: {
                    required: (v) =>
                      isNullOrEmptyString(v) ? "Missing Presentation Name" : undefined,
                  },
                }}
                render={({ ref, ...rest }) => (
                  <TextFieldBase
                    placeholder="Enter presentation name"
                    autoFocus
                    disabled={creating}
                    errors={errors}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </InputContainer>
          )}
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" data-testid="cancel">
          Cancel
        </Button>
        <Button form="create-simple-cma" type="submit" disabled={creating} data-testid="create">
          {creating ? "Creating..." : "Next"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React from "react";
import { Typography } from "@avenue-8/ui-2";
import { SectionViewLayout } from "./SectionViewLayout";
import { ConstrainedContainer } from "./ConstrainedContainer";

export default function NotImplemented() {
  return (
    <SectionViewLayout>
      <ConstrainedContainer>
        <Typography variant="h2" align="center">
          Not Implemented Section
        </Typography>
      </ConstrainedContainer>
    </SectionViewLayout>
  );
}

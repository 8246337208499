import * as React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Grid, Typography, Loader, styled } from "@avenue-8/ui-2";
import { useAgentOnboardingLogic } from "../../../hooks/useAgentOnboardingLogic";
import { AgentOnboardingStepBaseProps } from "../../AgentOnboarding";
import { AgentDetailsForm, AgentDetailsFormModel } from "./AgentDetailsForm";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const Form = styled.form`
  margin: 1rem 0;
  width: 100%;
`;

const TitleHeaderGrid = styled(Grid)`
  margin-bottom: 40px;
`;

const LoaderContainer = styled(Grid)`
  min-height: 300px;
`;

interface AgentDetailsStepProps extends AgentOnboardingStepBaseProps {
  data?: AgentDetailsFormModel;
  onSave: (data: AgentDetailsFormModel) => Promise<any>;
}

export const AgentDetailsStep = ({ formId, onSave }: AgentDetailsStepProps) => {
  const { state } = useAgentOnboardingLogic();
  const formMethods = useForm<AgentDetailsFormModel>({
    defaultValues: { ...state.agentDetailsForm },
  });
  const { handleSubmit } = formMethods;

  return (
    <Container>
      <TitleHeaderGrid container alignItems={"center"} justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5">Enter your Agent Details</Typography>
        </Grid>
      </TitleHeaderGrid>
      {state.loadAgentOnboardingQuery.status === "loading" && (
        <LoaderContainer container item justifyContent="center" alignItems="center">
          <Loader color="black" />
        </LoaderContainer>
      )}
      {state.loadAgentOnboardingQuery.status === "success" && (
        <FormProvider {...formMethods}>
          <Form id={formId} onSubmit={handleSubmit(onSave)} noValidate autoComplete="off">
            <Grid container item md={7} spacing={3}>
              <AgentDetailsForm defaultValues={state.agentDetailsForm} />
            </Grid>
          </Form>
        </FormProvider>
      )}
    </Container>
  );
};

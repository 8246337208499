import * as React from "react";
import SwiperCore, { Navigation, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { styled, ImageUnavailable } from "@avenue-8/ui-2";
import { usePhotoGalleryContext } from "../../../../shared/contexts/PhotoGalleryContext";

SwiperCore.use([Virtual, Navigation]);

const SwiperContainer = styled.div`
  overflow: hidden;
  position: relative;

  & > .swiper-container {
    overflow: visible;
    padding-bottom: 2.5rem;
  }

  .swiper-pagination {
    bottom: 28px;
    max-width: 60%;
  }

  & .swiper-pagination-bullet {
    margin: 0 1rem !important;
    background-color: ${(p) => p.theme.av8.secondaryColor};
  }

  & .swiper-pagination-bullet-active {
    background-color: ${(p) => p.theme.av8.primaryColor};
  }

  .swiper-button-next-custom,
  .swiper-button-prev-custom {
    border: 1px solid ${(p) => p.theme.av8.input.borderColor};
    color: ${(p) => p.theme.av8.primaryColor};
    box-sizing: border-box;
    border-radius: 4px;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    position: absolute;
    right: 0;
    bottom: 8px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: ${(p) => p.theme.av8.backgroundAlt};
    }
  }

  .swiper-button-prev-custom {
    left: 0;
  }

  .swiper-button-next-custom::after,
  .swiper-button-prev-custom::after {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.4;
  }
`;

export const RatioImage = styled.div<{ extraMargin?: boolean }>`
  display: "block";
  padding-bottom: 60%;
  margin: ${(p) => (p.extraMargin ? "8px 0 8px 0;" : "0px")};
  position: relative;
  width: 100%;
  cursor: pointer;
  & > img {
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
`;

const ImageUnavailableMLS = styled(ImageUnavailable)`
  background-color: ${(p) => p.theme.av8.background};
`;

interface Photo {
  id: string;
  url: string;
  cdnUrl?: string;
  caption?: string;
}

interface Props {
  photos: Photo[];
  photosPerSlide?: number;
  spaceBetween?: number;
  centeredSlides?: boolean;
  swiperId: string;
}

export const PhotosSwiper = ({
  photos = [],
  photosPerSlide = 1,
  spaceBetween = 10,
  centeredSlides = true,
}: Props) => {
  const { changeGalleryPhoto } = usePhotoGalleryContext();
  const photosLength = React.useRef(photos.length);
  const lastIndex = React.useRef(0);

  if (photos.length === 0) {
    return (
      <ImageUnavailableMLS
        style={{ height: 160, gridColumn: "auto / span 1" }}
        message="Images unavailable via MLS"
      />
    );
  }

  const slides = photos.map(({ id: _id, caption, url, cdnUrl }, index) => {
    return (
      <SwiperSlide virtualIndex={index} key={index}>
        <RatioImage
          onClick={() => {
            changeGalleryPhoto(
              index,
              photos.map((photo) => ({
                src: photo.cdnUrl || photo.url,
                alt: photo.caption ?? "Property photo",
                id: photo.id,
              }))
            );
          }}
        >
          <img alt={caption ?? ""} src={cdnUrl || url} />
        </RatioImage>
      </SwiperSlide>
    );
  });

  return (
    <SwiperContainer>
      <Swiper
        initialSlide={0}
        key={photos.length}
        onSwiper={(swiper) => {
          if (photos.length > photosLength.current) {
            swiper.slideTo(0, 0);
          } else {
            swiper.slideTo(Math.max(0, lastIndex.current - 1), 0);
          }
          photosLength.current = photos.length;
        }}
        pagination={{ clickable: true, dynamicBullets: true, dynamicMainBullets: 4 }}
        centeredSlides={centeredSlides}
        spaceBetween={spaceBetween}
        onSlideChange={(swiper) => {
          lastIndex.current = swiper.activeIndex;
        }}
        mousewheel
        slidesPerView={photosPerSlide}
        virtual={{
          cache: false,
          addSlidesAfter: 2,
          addSlidesBefore: 2,
        }}
      >
        {slides}
      </Swiper>
    </SwiperContainer>
  );
};

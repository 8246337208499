/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CMADto,
    CMADtoFromJSON,
    CMADtoToJSON,
    CreateCMAResponseDto,
    CreateCMAResponseDtoFromJSON,
    CreateCMAResponseDtoToJSON,
    CreateEmptyCMADto,
    CreateEmptyCMADtoFromJSON,
    CreateEmptyCMADtoToJSON,
    DuplicateCMAResponseDto,
    DuplicateCMAResponseDtoFromJSON,
    DuplicateCMAResponseDtoToJSON,
    ListCMAResponseDto,
    ListCMAResponseDtoFromJSON,
    ListCMAResponseDtoToJSON,
    LoadCMASubjectPropertyImagesOutput,
    LoadCMASubjectPropertyImagesOutputFromJSON,
    LoadCMASubjectPropertyImagesOutputToJSON,
    PatchCMARequestDto,
    PatchCMARequestDtoFromJSON,
    PatchCMARequestDtoToJSON,
    PropertyImageDto,
    PropertyImageDtoFromJSON,
    PropertyImageDtoToJSON,
    ReorderComparablePropertiesRequest,
    ReorderComparablePropertiesRequestFromJSON,
    ReorderComparablePropertiesRequestToJSON,
    SelectCMACoverImageDto,
    SelectCMACoverImageDtoFromJSON,
    SelectCMACoverImageDtoToJSON,
    SelectComparablePropertyCoverImageRequestDto,
    SelectComparablePropertyCoverImageRequestDtoFromJSON,
    SelectComparablePropertyCoverImageRequestDtoToJSON,
    SetEstimatedPriceRequestDto,
    SetEstimatedPriceRequestDtoFromJSON,
    SetEstimatedPriceRequestDtoToJSON,
    UploadComparablePropertyImageResponseDto,
    UploadComparablePropertyImageResponseDtoFromJSON,
    UploadComparablePropertyImageResponseDtoToJSON,
    UploadCoverPhotoResponseDto,
    UploadCoverPhotoResponseDtoFromJSON,
    UploadCoverPhotoResponseDtoToJSON,
    UpsertCMASubjectPropertyByMlsIdRequestDto,
    UpsertCMASubjectPropertyByMlsIdRequestDtoFromJSON,
    UpsertCMASubjectPropertyByMlsIdRequestDtoToJSON,
    UpsertCMASubjectPropertyByMlsIdResponseDto,
    UpsertCMASubjectPropertyByMlsIdResponseDtoFromJSON,
    UpsertCMASubjectPropertyByMlsIdResponseDtoToJSON,
    UpsertComparablePropertyRequestDto,
    UpsertComparablePropertyRequestDtoFromJSON,
    UpsertComparablePropertyRequestDtoToJSON,
} from '../models';

export interface CMAControllerAddComparablePropertyRequest {
    id: string;
    mlsId: string;
}

export interface CMAControllerAddComprablePropertyImageRequest {
    id: string;
    mlsId: string;
    images: Array<Blob>;
}

export interface CMAControllerAddSubjectPropertyPhotoRequest {
    id: string;
    images: Array<Blob>;
}

export interface CMAControllerArchiveRequest {
    id: string;
}

export interface CMAControllerCloneRequest {
    id: string;
}

export interface CMAControllerCreateSimpleRequest {
    createEmptyCMADto: CreateEmptyCMADto;
}

export interface CMAControllerDeleteRequest {
    id: string;
}

export interface CMAControllerDeleteComparablePropertyImageRequest {
    id: string;
    mlsId: string;
    imgId: number;
}

export interface CMAControllerDeleteCoverPhotoRequest {
    id: string;
}

export interface CMAControllerGetRequest {
    id: string;
}

export interface CMAControllerGetInsightsRequest {
    id: string;
}

export interface CMAControllerListRequest {
    page: number;
    status?: string;
    orderBy?: string;
    agentId?: number;
}

export interface CMAControllerLoadSubjectPropertyImagesRequest {
    id: string;
}

export interface CMAControllerPatchRequest {
    id: string;
    patchCMARequestDto: PatchCMARequestDto;
}

export interface CMAControllerPublishRequest {
    id: string;
}

export interface CMAControllerRemoveComparablePropertyRequest {
    id: string;
    mlsId: string;
}

export interface CMAControllerRemoveSubjectPropertyPhotoRequest {
    id: string;
    imgId: number;
}

export interface CMAControllerReorderComparablePropertiesRequest {
    id: string;
    reorderComparablePropertiesRequest: ReorderComparablePropertiesRequest;
}

export interface CMAControllerSelectComparableCoverImageRequest {
    id: string;
    mlsId: string;
    selectComparablePropertyCoverImageRequestDto: SelectComparablePropertyCoverImageRequestDto;
}

export interface CMAControllerSelectCoverImageRequest {
    id: string;
    selectCMACoverImageDto: SelectCMACoverImageDto;
}

export interface CMAControllerSetEstimatedPriceRequest {
    id: string;
    setEstimatedPriceRequestDto: SetEstimatedPriceRequestDto;
}

export interface CMAControllerUnarchiveRequest {
    id: string;
}

export interface CMAControllerUploadCoverPhotoRequest {
    id: string;
    images: Array<Blob>;
}

export interface CMAControllerUpsertRequest {
    upsertCMASubjectPropertyByMlsIdRequestDto: UpsertCMASubjectPropertyByMlsIdRequestDto;
}

export interface CMAControllerUpsertComparablePropertyRequest {
    id: string;
    upsertComparablePropertyRequestDto: UpsertComparablePropertyRequestDto;
}

/**
 * 
 */
export class CMAApi extends runtime.BaseAPI {

    /**
     * - `id` is the CMA UUID - `mlsId` is \"mlsSource:mlsId\" format  
     * Adds a comparable property to a CMA by an MlsID
     */
    async cMAControllerAddComparablePropertyRaw(requestParameters: CMAControllerAddComparablePropertyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerAddComparableProperty.');
        }

        if (requestParameters.mlsId === null || requestParameters.mlsId === undefined) {
            throw new runtime.RequiredError('mlsId','Required parameter requestParameters.mlsId was null or undefined when calling cMAControllerAddComparableProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/comparable-properties/{mlsId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mlsId"}}`, encodeURIComponent(String(requestParameters.mlsId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - `id` is the CMA UUID - `mlsId` is \"mlsSource:mlsId\" format  
     * Adds a comparable property to a CMA by an MlsID
     */
    async cMAControllerAddComparableProperty(requestParameters: CMAControllerAddComparablePropertyRequest): Promise<void> {
        await this.cMAControllerAddComparablePropertyRaw(requestParameters);
    }

    /**
     */
    async cMAControllerAddComprablePropertyImageRaw(requestParameters: CMAControllerAddComprablePropertyImageRequest): Promise<runtime.ApiResponse<Array<UploadComparablePropertyImageResponseDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerAddComprablePropertyImage.');
        }

        if (requestParameters.mlsId === null || requestParameters.mlsId === undefined) {
            throw new runtime.RequiredError('mlsId','Required parameter requestParameters.mlsId was null or undefined when calling cMAControllerAddComprablePropertyImage.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling cMAControllerAddComprablePropertyImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/cmas/{id}/comparable-properties/{mlsId}/images`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mlsId"}}`, encodeURIComponent(String(requestParameters.mlsId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UploadComparablePropertyImageResponseDtoFromJSON));
    }

    /**
     */
    async cMAControllerAddComprablePropertyImage(requestParameters: CMAControllerAddComprablePropertyImageRequest): Promise<Array<UploadComparablePropertyImageResponseDto>> {
        const response = await this.cMAControllerAddComprablePropertyImageRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerAddSubjectPropertyPhotoRaw(requestParameters: CMAControllerAddSubjectPropertyPhotoRequest): Promise<runtime.ApiResponse<Array<PropertyImageDto>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerAddSubjectPropertyPhoto.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling cMAControllerAddSubjectPropertyPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/cmas/{id}/subject-property/images`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PropertyImageDtoFromJSON));
    }

    /**
     */
    async cMAControllerAddSubjectPropertyPhoto(requestParameters: CMAControllerAddSubjectPropertyPhotoRequest): Promise<Array<PropertyImageDto>> {
        const response = await this.cMAControllerAddSubjectPropertyPhotoRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerArchiveRaw(requestParameters: CMAControllerArchiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerArchive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerArchive(requestParameters: CMAControllerArchiveRequest): Promise<void> {
        await this.cMAControllerArchiveRaw(requestParameters);
    }

    /**
     */
    async cMAControllerCloneRaw(requestParameters: CMAControllerCloneRequest): Promise<runtime.ApiResponse<DuplicateCMAResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerClone.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/clone`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DuplicateCMAResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async cMAControllerClone(requestParameters: CMAControllerCloneRequest): Promise<DuplicateCMAResponseDto> {
        const response = await this.cMAControllerCloneRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerCreateSimpleRaw(requestParameters: CMAControllerCreateSimpleRequest): Promise<runtime.ApiResponse<CreateCMAResponseDto>> {
        if (requestParameters.createEmptyCMADto === null || requestParameters.createEmptyCMADto === undefined) {
            throw new runtime.RequiredError('createEmptyCMADto','Required parameter requestParameters.createEmptyCMADto was null or undefined when calling cMAControllerCreateSimple.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/create-simple`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmptyCMADtoToJSON(requestParameters.createEmptyCMADto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCMAResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async cMAControllerCreateSimple(requestParameters: CMAControllerCreateSimpleRequest): Promise<CreateCMAResponseDto> {
        const response = await this.cMAControllerCreateSimpleRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerDeleteRaw(requestParameters: CMAControllerDeleteRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerDelete(requestParameters: CMAControllerDeleteRequest): Promise<void> {
        await this.cMAControllerDeleteRaw(requestParameters);
    }

    /**
     */
    async cMAControllerDeleteComparablePropertyImageRaw(requestParameters: CMAControllerDeleteComparablePropertyImageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerDeleteComparablePropertyImage.');
        }

        if (requestParameters.mlsId === null || requestParameters.mlsId === undefined) {
            throw new runtime.RequiredError('mlsId','Required parameter requestParameters.mlsId was null or undefined when calling cMAControllerDeleteComparablePropertyImage.');
        }

        if (requestParameters.imgId === null || requestParameters.imgId === undefined) {
            throw new runtime.RequiredError('imgId','Required parameter requestParameters.imgId was null or undefined when calling cMAControllerDeleteComparablePropertyImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/comparable-properties/{mlsId}/images/{imgId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mlsId"}}`, encodeURIComponent(String(requestParameters.mlsId))).replace(`{${"imgId"}}`, encodeURIComponent(String(requestParameters.imgId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerDeleteComparablePropertyImage(requestParameters: CMAControllerDeleteComparablePropertyImageRequest): Promise<void> {
        await this.cMAControllerDeleteComparablePropertyImageRaw(requestParameters);
    }

    /**
     */
    async cMAControllerDeleteCoverPhotoRaw(requestParameters: CMAControllerDeleteCoverPhotoRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerDeleteCoverPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/cover-photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerDeleteCoverPhoto(requestParameters: CMAControllerDeleteCoverPhotoRequest): Promise<void> {
        await this.cMAControllerDeleteCoverPhotoRaw(requestParameters);
    }

    /**
     */
    async cMAControllerGetRaw(requestParameters: CMAControllerGetRequest): Promise<runtime.ApiResponse<CMADto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CMADtoFromJSON(jsonValue));
    }

    /**
     */
    async cMAControllerGet(requestParameters: CMAControllerGetRequest): Promise<CMADto> {
        const response = await this.cMAControllerGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerGetInsightsRaw(requestParameters: CMAControllerGetInsightsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerGetInsights.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/insights`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerGetInsights(requestParameters: CMAControllerGetInsightsRequest): Promise<void> {
        await this.cMAControllerGetInsightsRaw(requestParameters);
    }

    /**
     */
    async cMAControllerListRaw(requestParameters: CMAControllerListRequest): Promise<runtime.ApiResponse<ListCMAResponseDto>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling cMAControllerList.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.agentId !== undefined) {
            queryParameters['agentId'] = requestParameters.agentId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCMAResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async cMAControllerList(requestParameters: CMAControllerListRequest): Promise<ListCMAResponseDto> {
        const response = await this.cMAControllerListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerLoadSubjectPropertyImagesRaw(requestParameters: CMAControllerLoadSubjectPropertyImagesRequest): Promise<runtime.ApiResponse<LoadCMASubjectPropertyImagesOutput>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerLoadSubjectPropertyImages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/subject-properties/images/load`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoadCMASubjectPropertyImagesOutputFromJSON(jsonValue));
    }

    /**
     */
    async cMAControllerLoadSubjectPropertyImages(requestParameters: CMAControllerLoadSubjectPropertyImagesRequest): Promise<LoadCMASubjectPropertyImagesOutput> {
        const response = await this.cMAControllerLoadSubjectPropertyImagesRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async cMAControllerPatchRaw(requestParameters: CMAControllerPatchRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerPatch.');
        }

        if (requestParameters.patchCMARequestDto === null || requestParameters.patchCMARequestDto === undefined) {
            throw new runtime.RequiredError('patchCMARequestDto','Required parameter requestParameters.patchCMARequestDto was null or undefined when calling cMAControllerPatch.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchCMARequestDtoToJSON(requestParameters.patchCMARequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerPatch(requestParameters: CMAControllerPatchRequest): Promise<void> {
        await this.cMAControllerPatchRaw(requestParameters);
    }

    /**
     */
    async cMAControllerPublishRaw(requestParameters: CMAControllerPublishRequest): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerPublish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/publish`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async cMAControllerPublish(requestParameters: CMAControllerPublishRequest): Promise<boolean> {
        const response = await this.cMAControllerPublishRaw(requestParameters);
        return await response.value();
    }

    /**
     * - `id` is the CMA UUID - `mlsId` is \"mlsSource:mlsId\" format  
     * Removes a comparable property from a CMA by an MlsID
     */
    async cMAControllerRemoveComparablePropertyRaw(requestParameters: CMAControllerRemoveComparablePropertyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerRemoveComparableProperty.');
        }

        if (requestParameters.mlsId === null || requestParameters.mlsId === undefined) {
            throw new runtime.RequiredError('mlsId','Required parameter requestParameters.mlsId was null or undefined when calling cMAControllerRemoveComparableProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/comparable-properties/{mlsId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mlsId"}}`, encodeURIComponent(String(requestParameters.mlsId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - `id` is the CMA UUID - `mlsId` is \"mlsSource:mlsId\" format  
     * Removes a comparable property from a CMA by an MlsID
     */
    async cMAControllerRemoveComparableProperty(requestParameters: CMAControllerRemoveComparablePropertyRequest): Promise<void> {
        await this.cMAControllerRemoveComparablePropertyRaw(requestParameters);
    }

    /**
     */
    async cMAControllerRemoveSubjectPropertyPhotoRaw(requestParameters: CMAControllerRemoveSubjectPropertyPhotoRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerRemoveSubjectPropertyPhoto.');
        }

        if (requestParameters.imgId === null || requestParameters.imgId === undefined) {
            throw new runtime.RequiredError('imgId','Required parameter requestParameters.imgId was null or undefined when calling cMAControllerRemoveSubjectPropertyPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/subject-property/images/{imgId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"imgId"}}`, encodeURIComponent(String(requestParameters.imgId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerRemoveSubjectPropertyPhoto(requestParameters: CMAControllerRemoveSubjectPropertyPhotoRequest): Promise<void> {
        await this.cMAControllerRemoveSubjectPropertyPhotoRaw(requestParameters);
    }

    /**
     * - `id` is the CMA UUID - `order` is the ordered comparables
     * Udate the comparables order
     */
    async cMAControllerReorderComparablePropertiesRaw(requestParameters: CMAControllerReorderComparablePropertiesRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerReorderComparableProperties.');
        }

        if (requestParameters.reorderComparablePropertiesRequest === null || requestParameters.reorderComparablePropertiesRequest === undefined) {
            throw new runtime.RequiredError('reorderComparablePropertiesRequest','Required parameter requestParameters.reorderComparablePropertiesRequest was null or undefined when calling cMAControllerReorderComparableProperties.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/comparable-properties/order`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ReorderComparablePropertiesRequestToJSON(requestParameters.reorderComparablePropertiesRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - `id` is the CMA UUID - `order` is the ordered comparables
     * Udate the comparables order
     */
    async cMAControllerReorderComparableProperties(requestParameters: CMAControllerReorderComparablePropertiesRequest): Promise<void> {
        await this.cMAControllerReorderComparablePropertiesRaw(requestParameters);
    }

    /**
     */
    async cMAControllerSelectComparableCoverImageRaw(requestParameters: CMAControllerSelectComparableCoverImageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerSelectComparableCoverImage.');
        }

        if (requestParameters.mlsId === null || requestParameters.mlsId === undefined) {
            throw new runtime.RequiredError('mlsId','Required parameter requestParameters.mlsId was null or undefined when calling cMAControllerSelectComparableCoverImage.');
        }

        if (requestParameters.selectComparablePropertyCoverImageRequestDto === null || requestParameters.selectComparablePropertyCoverImageRequestDto === undefined) {
            throw new runtime.RequiredError('selectComparablePropertyCoverImageRequestDto','Required parameter requestParameters.selectComparablePropertyCoverImageRequestDto was null or undefined when calling cMAControllerSelectComparableCoverImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/comparable-property/{mlsId}/images/cover`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mlsId"}}`, encodeURIComponent(String(requestParameters.mlsId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectComparablePropertyCoverImageRequestDtoToJSON(requestParameters.selectComparablePropertyCoverImageRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerSelectComparableCoverImage(requestParameters: CMAControllerSelectComparableCoverImageRequest): Promise<void> {
        await this.cMAControllerSelectComparableCoverImageRaw(requestParameters);
    }

    /**
     */
    async cMAControllerSelectCoverImageRaw(requestParameters: CMAControllerSelectCoverImageRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerSelectCoverImage.');
        }

        if (requestParameters.selectCMACoverImageDto === null || requestParameters.selectCMACoverImageDto === undefined) {
            throw new runtime.RequiredError('selectCMACoverImageDto','Required parameter requestParameters.selectCMACoverImageDto was null or undefined when calling cMAControllerSelectCoverImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/subject-property/images/cover`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SelectCMACoverImageDtoToJSON(requestParameters.selectCMACoverImageDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerSelectCoverImage(requestParameters: CMAControllerSelectCoverImageRequest): Promise<void> {
        await this.cMAControllerSelectCoverImageRaw(requestParameters);
    }

    /**
     */
    async cMAControllerSetEstimatedPriceRaw(requestParameters: CMAControllerSetEstimatedPriceRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerSetEstimatedPrice.');
        }

        if (requestParameters.setEstimatedPriceRequestDto === null || requestParameters.setEstimatedPriceRequestDto === undefined) {
            throw new runtime.RequiredError('setEstimatedPriceRequestDto','Required parameter requestParameters.setEstimatedPriceRequestDto was null or undefined when calling cMAControllerSetEstimatedPrice.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/estimated-price/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SetEstimatedPriceRequestDtoToJSON(requestParameters.setEstimatedPriceRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerSetEstimatedPrice(requestParameters: CMAControllerSetEstimatedPriceRequest): Promise<void> {
        await this.cMAControllerSetEstimatedPriceRaw(requestParameters);
    }

    /**
     */
    async cMAControllerUnarchiveRaw(requestParameters: CMAControllerUnarchiveRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerUnarchive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/unarchive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async cMAControllerUnarchive(requestParameters: CMAControllerUnarchiveRequest): Promise<void> {
        await this.cMAControllerUnarchiveRaw(requestParameters);
    }

    /**
     */
    async cMAControllerUploadCoverPhotoRaw(requestParameters: CMAControllerUploadCoverPhotoRequest): Promise<runtime.ApiResponse<UploadCoverPhotoResponseDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerUploadCoverPhoto.');
        }

        if (requestParameters.images === null || requestParameters.images === undefined) {
            throw new runtime.RequiredError('images','Required parameter requestParameters.images was null or undefined when calling cMAControllerUploadCoverPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.images) {
            requestParameters.images.forEach((element) => {
                formParams.append('images', element as any);
            })
        }

        const response = await this.request({
            path: `/cmas/{id}/cover-photo`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadCoverPhotoResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async cMAControllerUploadCoverPhoto(requestParameters: CMAControllerUploadCoverPhotoRequest): Promise<UploadCoverPhotoResponseDto> {
        const response = await this.cMAControllerUploadCoverPhotoRaw(requestParameters);
        return await response.value();
    }

    /**
     * Searches for a property using the given `mlsId` and sets it as the subject property for an existing CMA or creates a new CMA if the `id` parameter is null. - `mlsId` is required  - `id` is an optional UUID
     * Update or Create a CMA by a MlsID
     */
    async cMAControllerUpsertRaw(requestParameters: CMAControllerUpsertRequest): Promise<runtime.ApiResponse<UpsertCMASubjectPropertyByMlsIdResponseDto>> {
        if (requestParameters.upsertCMASubjectPropertyByMlsIdRequestDto === null || requestParameters.upsertCMASubjectPropertyByMlsIdRequestDto === undefined) {
            throw new runtime.RequiredError('upsertCMASubjectPropertyByMlsIdRequestDto','Required parameter requestParameters.upsertCMASubjectPropertyByMlsIdRequestDto was null or undefined when calling cMAControllerUpsert.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertCMASubjectPropertyByMlsIdRequestDtoToJSON(requestParameters.upsertCMASubjectPropertyByMlsIdRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsertCMASubjectPropertyByMlsIdResponseDtoFromJSON(jsonValue));
    }

    /**
     * Searches for a property using the given `mlsId` and sets it as the subject property for an existing CMA or creates a new CMA if the `id` parameter is null. - `mlsId` is required  - `id` is an optional UUID
     * Update or Create a CMA by a MlsID
     */
    async cMAControllerUpsert(requestParameters: CMAControllerUpsertRequest): Promise<UpsertCMASubjectPropertyByMlsIdResponseDto> {
        const response = await this.cMAControllerUpsertRaw(requestParameters);
        return await response.value();
    }

    /**
     * - `id` is the CMA UUID - the body contains fields from the property that should be added as a comparable
     * Inserts or updates a comparable property into a CMA
     */
    async cMAControllerUpsertComparablePropertyRaw(requestParameters: CMAControllerUpsertComparablePropertyRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cMAControllerUpsertComparableProperty.');
        }

        if (requestParameters.upsertComparablePropertyRequestDto === null || requestParameters.upsertComparablePropertyRequestDto === undefined) {
            throw new runtime.RequiredError('upsertComparablePropertyRequestDto','Required parameter requestParameters.upsertComparablePropertyRequestDto was null or undefined when calling cMAControllerUpsertComparableProperty.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("bearer", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/cmas/{id}/comparable-properties`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpsertComparablePropertyRequestDtoToJSON(requestParameters.upsertComparablePropertyRequestDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * - `id` is the CMA UUID - the body contains fields from the property that should be added as a comparable
     * Inserts or updates a comparable property into a CMA
     */
    async cMAControllerUpsertComparableProperty(requestParameters: CMAControllerUpsertComparablePropertyRequest): Promise<void> {
        await this.cMAControllerUpsertComparablePropertyRaw(requestParameters);
    }

}

import { generateRoute as route } from "../shared/utils/generate-route";

const baseRoute = "/auth";
/**
 * All module routes should be placed here.
 * We can generate and match all paths using this
 * object and even use it to register react-router <Route>.
 */

interface ResetPasswordParams {
  token: string;
}

export const authRoutes = {
  auth: route<void>(`${baseRoute}/`),
  authSuccess: route<void>(`${baseRoute}/success`),
  authFailure: route<void>(`${baseRoute}/failure`),
  resetPassword: route<ResetPasswordParams>(`${baseRoute}/reset-password/:token`),
  forgotPassword: route<void>(`${baseRoute}/forgot-password`),
};

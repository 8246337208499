import { PrintConfiguration } from "../types";
import { defaultPrintConfiguration } from "../types";
import Browser from "./browser";
import Pdf from "./pdf";

const printTypes = ["pdf", "html", "image", "raw-html"];

const init = {
  init(config: PrintConfiguration) {
    const params = { ...defaultPrintConfiguration, ...config };

    // Validate printable
    if (!params.printable) throw new Error("Missing printable information.");

    // Validate type
    if (
      !params.type ||
      typeof params.type !== "string" ||
      printTypes.indexOf(params.type.toLowerCase()) === -1
    ) {
      throw new Error("Invalid print type. Available types are: pdf, html, image and json.");
    }

    // Check if we are showing a feedback message to the user (useful for large files)
    if (params.setModalState) params.setModalState(true);

    // Check for a print start hook function
    if (params.onLoadingStart) params.onLoadingStart();

    // To prevent duplication and issues, remove any used printFrame from the DOM
    const usedFrame = document.getElementById(params.frameId);

    if (usedFrame) usedFrame.parentNode?.removeChild(usedFrame);

    // Create a new iframe for the print job
    const printFrame = document.createElement("iframe");

    if (Browser.isFirefox()) {
      // Set the iframe to be is visible on the page (guaranteed by fixed position) but hidden using opacity 0, because
      // this works in Firefox. The height needs to be sufficient for some part of the document other than the PDF
      // viewer's toolbar to be visible in the page
      printFrame.setAttribute(
        "style",
        "width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0"
      );
    } else {
      // Hide the iframe in other browsers
      printFrame.setAttribute(
        "style",
        "visibility: hidden; height: 0; width: 0; position: absolute; border: 0"
      );
    }

    // Set iframe element id
    printFrame.setAttribute("id", params.frameId);

    // For non pdf printing, pass an html document string to srcdoc (force onload callback)
    if (params.type !== "pdf") {
      printFrame.srcdoc = "<html><head><title>" + params.documentTitle + "</title>";

      // Attach css files
      if (params.css) {
        // Add support for single file
        if (!Array.isArray(params.css)) params.css = [params.css];

        // Create link tags for each css file
        params.css.forEach((file: string) => {
          printFrame.srcdoc += '<link rel="stylesheet" href="' + file + '">';
        });
      }

      printFrame.srcdoc += "</head><body></body></html>";
    }

    // Check printable type
    switch (params.type) {
      case "pdf":
        // Check browser support for pdf and if not supported we will just open the pdf file instead
        if (Browser.isIE()) {
          try {
            console.info("Print.js doesn't support PDF printing in Internet Explorer.");
            const win = window.open(params.fallbackPrintable, "_blank");
            win?.focus();
            params.onIncompatibleBrowser();
          } catch (error: any) {
            params.onError(error);
          } finally {
            // Make sure there is no loading modal opened
            if (params.setModalState) params.setModalState(false);
            if (params.onLoadingEnd) params.onLoadingEnd();
          }
        } else {
          Pdf.print(params, printFrame);
        }
        break;
    }
  },
};

export default init;

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AppContextProvider } from "./AppContext";
import { StylesProvider } from "@mui/styles";
import { FlagsmithFeatureToggleStrategy } from "./modules/shared/contexts/FeatureToggleContex/flagsmith-strategy";
import { ReactEnvAndQueryFeatureToggleStrategy } from "./modules/shared/contexts/FeatureToggleContex/react-env-and-query-strategy";
import { FeatureToggleProvider } from "./modules/shared/contexts/FeatureToggleContex/FeatureToggleContext";
import { ErrorBoundaryProvider } from "./modules/shared/components/ErrorBoundaryProvider";
import { initDatadogRum } from "./modules/shared/services/datadog-rum/datadog-rum";
import smoothscroll from "smoothscroll-polyfill";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

smoothscroll.polyfill();

initDatadogRum();

const featureToggleStrategy = process.env.REACT_APP_FLAGSMITH_ENV
  ? new FlagsmithFeatureToggleStrategy()
  : new ReactEnvAndQueryFeatureToggleStrategy({ debug: true });

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundaryProvider>
      <StylesProvider injectFirst>
        <BrowserRouter>
          <QueryParamProvider adapter={ReactRouter5Adapter}>
            <FeatureToggleProvider strategy={featureToggleStrategy}>
              <AppContextProvider>
                <App />
              </AppContextProvider>
            </FeatureToggleProvider>
          </QueryParamProvider>
        </BrowserRouter>
      </StylesProvider>
    </ErrorBoundaryProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

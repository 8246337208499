import { Dialog } from "@avenue-8/ui-2";
import { baseStyledThemed as styled } from "../../shared/theme";

export const PageContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f3f3fa;
  padding: 0 16px;
`;

export const AuthCard = styled.div`
  max-width: 450px;
  width: 100%;
  background-color: #ffffff;
  padding: 32px;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const Container = styled.div`
  max-width: 380px;
  margin: 0 auto;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  width: 100%;
  margin-top: 8px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;

  button:not(:first-of-type) {
    margin-top: 1rem;
  }
`;

export const AuthDialog = styled(Dialog)`
  max-width: 450px;
  margin: auto;
  .MuiDialogContent-root {
    padding: 32px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;

import { PresentationType, SectionViewBuilderContext, SectionWidget } from "../section-widget";
import { GeneralHeaderSectionConfig } from "./general-header-section-config";
import { GeneralHeaderSectionView } from "./general-header-section-view";
import { v4 as uuidv4 } from "uuid";

export class GeneralHeaderSectionWidget
  implements SectionWidget<"general-header", GeneralHeaderSectionConfig, GeneralHeaderSectionView>
{
  type: "general-header" = "general-header";

  supportedPresentationTypes: PresentationType[] = ["general-presentation"];

  buildDefaultConfig(): GeneralHeaderSectionConfig {
    return {
      type: "general-header",
      navTitle: "Header",
      id: uuidv4(),
      visibility: true,
      showClientName: true,
      showPresentationTitle: true,
    };
  }

  buildView({
    sectionConfig: config,
    context,
  }: SectionViewBuilderContext<GeneralHeaderSectionConfig, null>): GeneralHeaderSectionView {
    if (config.type !== "general-header") throw new Error("Unexpected section type.");
    const { clientDisplayName, presentationName, coverPhotoCdnUrl } = context.sourceData;
    const {
      id,
      type,
      visibility = true,
      showClientName = true,
      showPresentationTitle = true,
    } = config;

    const view: GeneralHeaderSectionView = {
      id,
      presentationName,
      type,
      navTitle: "Header",
      visibility,
      clientName: clientDisplayName,
      propertyPhoto: coverPhotoCdnUrl || null,
      preferences: {
        showClientName,
        showPresentationTitle,
      },
    };
    return view;
  }
}

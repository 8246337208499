import { ConstrainedContainer } from "../ConstrainedContainer";
import { styledThemed as styled } from "../../theme";

export const MainImageFrame = styled.div`
  width: 100%;
  padding-bottom: 30%;
  @media (max-width: 1600px) {
    padding-bottom: 40%;
  }
  @media (max-width: 800px) {
    padding-bottom: 80%;
  }
  position: relative;
  transition: height ease-in-out 0.3s;
  background: #eee;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }
  @media print {
    padding-bottom: 400px;
  }
`;

export const OuterContainer = styled.div<{ $noPaddingTop?: boolean }>`
  text-align: center;
  padding-top: ${(p) => (p.$noPaddingTop ? "0px" : p.theme.presentation.spacing(3))};
`;

export const RatioImage = styled.div`
  display: "block";
  padding-bottom: 66%;
  position: relative;
  width: 100%;
  cursor: pointer;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const SwiperContainer = styled(ConstrainedContainer)`
  overflow: visible;
  position: relative;
  margin-bottom: 1.5rem;

  & > .swiper-container {
    overflow: visible;
    padding-bottom: 3.5rem;
  }

  & .swiper-pagination-bullet {
    margin: 0 30px !important;
  }

  & .swiper-pagination-bullet-active {
    background-color: ${(p) => p.theme.av8.primaryColor};
  }
`;

export const GalleryIconContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const OpacityContainer = styled.div<{ $disabled?: boolean }>`
  ${(p) => p.$disabled && `opacity: .3`};
  margin-bottom: 24px;
`;

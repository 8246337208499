import { CMADto, PropertyDto } from "../../../../modules/shared/apis/cma/generated";
import { differenceInDays } from "date-fns";
import {
  arrayAverage,
  arrayMax,
  arrayMedian,
  arrayMin,
} from "../../../../modules/shared/utils/array-math";
import { groupBy } from "../../../../modules/shared/utils/group-by";

type Property = CMADto["comparableProperties"][0];
interface ListingSummaryStatisticRow {
  dimension: string | null;
  average: number | null;
  median: number | null;
  low: number | null;
  high: number | null;
  count: number;
  validCount: number;
}
export type Field = "soldPrice" | "soldToListPriceRatio" | "soldPriceBySqFt" | "daysOnMarket";
export type Dimension = "global" | "neighborhood" | "listingStatus" | "propertyType";
export type SummaryStatisticsDataSet = { rows: ListingSummaryStatisticRow[] };

export const calculateSummaryStatistics = function ({
  properties,
  field,
  dimension,
}: {
  properties: PropertyDto[];
  field: Field;
  dimension: Dimension;
}): SummaryStatisticsDataSet {
  const fieldExtractor = fieldExtractors[field];
  if (!fieldExtractor) throw new Error(`Unknown field: ${field}`);
  const dimensionExtractor = dimensionExtractors[dimension];
  if (!dimensionExtractor) throw new Error(`Unknown dimension: ${dimension}`);

  const propertyGroups = groupBy(properties, (item) => dimensionExtractor(item) ?? "N/A");
  const dimensionValues = Object.keys(propertyGroups)
    .filter((x) => x != null)
    .sort();

  const rows = dimensionValues.map((dimension) => {
    const allItems = propertyGroups[dimension].map(fieldExtractor);
    const items: number[] = allItems.filter((x) => x != null) as any;
    const hasItems = items.length > 0;
    const high = hasItems ? arrayMax(items) : null;
    const low = hasItems ? arrayMin(items) : null;
    const median = hasItems ? arrayMedian(items) : null;
    const average = hasItems ? arrayAverage(items) : null;
    const row: ListingSummaryStatisticRow = {
      dimension,
      average,
      high,
      low,
      median,
      count: allItems.length,
      validCount: items.length,
    };
    return row;
  });

  return { rows };
};

const fieldExtractors: { [key: string]: (item: Property) => number | null } = {
  soldPrice: ({ soldPrice }) => soldPrice || null,
  soldToListPriceRatio: ({ soldPrice, listPrice }) =>
    soldPrice && listPrice ? (soldPrice / listPrice) * 100 : null,
  soldPriceBySqFt: ({ soldPrice, livingSpace }) =>
    soldPrice && livingSpace ? soldPrice / livingSpace : null,
  daysOnMarket: ({ listDate, soldDate }) => {
    if (!listDate || !soldDate) return null;
    const diff = differenceInDays(soldDate, listDate);
    return diff < 0 ? null : diff;
  },
};

const dimensionExtractors: { [key: string]: (item: Property) => string | null } = {
  global: (_item) => "global",
  neighborhood: (item) => item.neighborhood || null,
  listingStatus: (item) => item.listingStatus || null,
  propertyType: (item) => item.propertyType || null,
};

export const Avenue8BackgroundIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="299" height="274" viewBox="0 0 299 274" fill="none" {...props}>
      <path
        d="M141.27 0C97.9033 0 62.6328 11.5569 38.1878 32.5293C13.725 53.5169 0.204041 83.8492 0.204041 121.169C0.204041 156.007 12.3211 179.11 26.3483 194.155C40.3545 209.177 56.2157 216.115 63.6692 218.739L67.8871 220.221L63.6419 221.573C42.2573 228.382 18.5812 245.296 4.65921 273.413H6.89783C20.6242 246.332 43.5756 230.061 64.2487 223.479L74.184 220.314L64.3332 216.852C57.059 214.292 41.5216 207.496 27.8112 192.791C14.1218 178.109 2.20404 155.49 2.20404 121.169C2.20404 84.3657 15.5186 54.6133 39.4901 34.0472C63.4794 13.4659 98.242 2 141.27 2H157.201C200.417 2 235.332 13.4664 259.426 34.0486C283.502 54.6152 296.873 84.3672 296.873 121.169C296.873 155.265 284.955 177.857 271.26 192.583C257.545 207.332 242.001 214.24 234.723 216.857L225.168 220.285L234.785 223.467C251.833 229.112 276.709 244.83 291.688 273.413H293.945C278.748 243.725 253.043 227.406 235.414 221.568L231.304 220.209L235.399 218.74C242.854 216.059 258.717 209.008 272.725 193.945C286.753 178.86 298.873 155.785 298.873 121.169C298.873 83.8477 285.293 53.515 260.725 32.5279C236.175 11.5564 200.754 0 157.201 0H141.27Z"
        fill="#2A29A6"
      ></path>
      <path
        d="M214.288 273.413C213.535 272.634 212.761 271.874 211.966 271.133C198.795 258.863 179.934 252 157.133 252H142.927C120.469 252 101.613 259.031 88.3623 271.376C87.6498 272.04 86.9537 272.719 86.2744 273.413H89.1186C89.3195 273.22 89.5219 273.029 89.7256 272.839C102.546 260.895 120.887 254 142.927 254H157.133C179.535 254 197.874 260.738 210.603 272.597C210.892 272.866 211.179 273.138 211.462 273.413H214.288Z"
        fill="#2A29A6"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.198 62C122.46 62 105.038 68.3948 92.7902 79.6327C80.5344 90.8775 73.5342 106.904 73.5342 126.003C73.5342 145.099 80.5344 161.124 92.7902 172.368C105.038 183.605 122.46 190 143.198 190H154.868C175.607 190 193.029 183.605 205.278 172.368C217.534 161.124 224.534 145.099 224.534 126.003C224.534 106.904 217.534 90.8775 205.278 79.6327C193.029 68.3948 175.607 62 154.868 62H143.198ZM75.5342 126.003C75.5342 107.417 82.3328 91.9417 94.1423 81.1064C105.959 70.2641 122.869 64 143.198 64H154.868C175.198 64 192.108 70.2641 203.926 81.1064C215.736 91.9417 222.534 107.417 222.534 126.003C222.534 144.586 215.736 160.06 203.926 170.894C192.108 181.736 175.198 188 154.868 188H143.198C122.869 188 105.959 181.736 94.1423 170.894C82.3328 160.06 75.5342 144.586 75.5342 126.003Z"
        fill="#2A29A6"
      ></path>
    </svg>
  );
};

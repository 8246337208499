import { InsightItem } from "../insights-section-view";
import { INSIGHTS_DAYS, InsightViewBuilder } from "./insights-section-view-builder";
import { roundToFixed } from "./utils";

export const buildInventorySpeedComparison: InsightViewBuilder = ({ context, data }) => {
  const { averageDaysOnMarket } = data || {};
  const notEnoughData: InsightItem | null = context.editMode
    ? {
        content: "Inventory Speed Comparison",
        footer: "Not enough data was provided",
        mode: "not-enough-data",
      }
    : null;

  if (!averageDaysOnMarket) return notEnoughData;
  const { days, cityDays, area } = averageDaysOnMarket;
  if (days == null || cityDays == null || cityDays === 0) return notEnoughData;
  const city = data?.city ?? context.sourceData.featuredProperty.city;
  const propertyType = data?.propertyType ?? context.sourceData.featuredProperty.propertyType;
  const change = ((days - cityDays) / cityDays) * 100.0;
  const changeAbs = Math.abs(change);
  const changeAbsF = roundToFixed(changeAbs, 2).toFixed(2);
  const daysF = Math.floor(days).toString();
  const cityDaysF = Math.floor(cityDays).toString();
  let copy;
  if (change <= -5.0) {
    copy = `Inventory moves ${changeAbsF}% faster in ${area} than ${city}, taking ${daysF} days compared to ${cityDaysF} days.`;
  } else if (change > -5.0 && change < 0) {
    copy = `Inventory moves similarly in ${area} compared to ${city} as a whole moving ${changeAbsF}% faster, taking ${daysF} days compared to ${cityDaysF} days.`;
  } else if (change === 0.0) {
    copy = `Inventory moves at exactly the same pace in ${area} compared to ${city} as a whole, both taking ${daysF} days.`;
  } else if (change > 0.0 && change < 5.0) {
    copy = `Inventory moves similarly in ${area} compared to ${city} as a whole moving ${changeAbsF}% slower, taking ${daysF} days compared to ${cityDaysF} days.`;
  } else {
    copy = `Inventory moves ${changeAbsF}% slower in ${area} than ${city}, taking ${daysF} days compared to ${cityDaysF} days.`;
  }

  const getFooter = () => {
    if (propertyType) {
      return `Average days on market calculated over the past ${INSIGHTS_DAYS} days for ${propertyType}.`;
    } else {
      return `Average days on market calculated over the past ${INSIGHTS_DAYS} days.`;
    }
  };

  return {
    content: copy,
    footer: getFooter(),
    mode: "ok",
  };
};

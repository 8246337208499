import { Backdrop } from "@avenue-8/ui-2";
import * as React from "react";
import { styledThemed as styled } from "../theme";

interface Props {
  headline: string;
  open: boolean;
  onBackdropClick?: () => void;
  children?: React.ReactNodeArray;
  zIndex?: number;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding-bottom: 5rem;
`;

export const Title = styled.h1`
  font-family: ${(p) => p.theme.presentation.headerFontFamily};
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
`;

const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  text-align: center;
  & > li {
    list-style-type: none;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
    & > a {
      color: ${(p) => p.theme.av8.primaryColor};
      padding: 1rem;
      display: block;
      font-weight: 300;
      text-decoration: none;
    }
  }
`;

export const BackdropMenu: React.FunctionComponent<Props> = ({
  headline,
  open,
  onBackdropClick,
  children,
  zIndex,
}: Props) => {
  return (
    <Backdrop
      style={{ zIndex: zIndex ?? 5, background: "white" }}
      open={open}
      onClick={onBackdropClick}
    >
      <Root>
        <Title>{headline}</Title>
        <StyledList onClick={(e) => e.stopPropagation()}>
          {children?.map((x, i) => (
            <li key={i}>{x}</li>
          ))}
        </StyledList>
      </Root>
    </Backdrop>
  );
};

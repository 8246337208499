/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnboardingData,
    OnboardingDataFromJSON,
    OnboardingDataFromJSONTyped,
    OnboardingDataToJSON,
} from './';

/**
 * 
 * @export
 * @interface SaveOnboardingRequest
 */
export interface SaveOnboardingRequest {
    /**
     * 
     * @type {OnboardingData}
     * @memberof SaveOnboardingRequest
     */
    data: OnboardingData;
    /**
     * 
     * @type {boolean}
     * @memberof SaveOnboardingRequest
     */
    tryFinish: boolean;
}

export function SaveOnboardingRequestFromJSON(json: any): SaveOnboardingRequest {
    return SaveOnboardingRequestFromJSONTyped(json, false);
}

export function SaveOnboardingRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SaveOnboardingRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': OnboardingDataFromJSON(json['data']),
        'tryFinish': json['tryFinish'],
    };
}

export function SaveOnboardingRequestToJSON(value?: SaveOnboardingRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': OnboardingDataToJSON(value.data),
        'tryFinish': value.tryFinish,
    };
}



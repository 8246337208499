import { PrintConfiguration, defaultPrintConfiguration } from "../types";
import Browser from "./browser";

export function cleanUp(params: PrintConfiguration) {
  // If we are showing a feedback message to user, remove it
  if (params.setModalState) params.setModalState(false);

  // Check for a finished loading hook function
  if (params.onLoadingEnd) params.onLoadingEnd();

  // If preloading pdf files, clean blob url
  if (params.setModalState || params.onLoadingStart) window.URL.revokeObjectURL(params.printable);

  // Run onPrintDialogClose callback
  let event = "mouseover";

  if (Browser.isChrome() || Browser.isFirefox()) {
    // Ps.: Firefox will require an extra click in the document to fire the focus event.
    event = "focus";
  }

  const handler = () => {
    // Make sure the event only happens once.
    window.removeEventListener(event, handler);

    params.onPrintDialogClose?.();

    // Remove iframe from the DOM
    const iframe = document.getElementById(params.frameId ?? defaultPrintConfiguration.frameId);

    if (iframe) {
      iframe.remove();
    }
  };

  if (params.closeOnWindowFocus) window.addEventListener(event, handler);
}

import * as React from "react";
import { CMADto } from "../../../../../shared/apis/cma/generated";
import { draftManager } from "../../../../../shared/services/draft-manager";
import { EstimatePriceFormModel } from "./components/EstimatePriceForm";
import { convertCmaDtoToEstimatePriceFormModel } from "./estimate-price";
import { useSetEstimatePriceMutation } from "./useEstimatePriceMutation";

const draftEstimatePriceFormKey = "estimatePrice";

export function useEstimatePriceLogic({ cma }: { cma: CMADto | undefined | null }) {
  const estimatePriceForm: EstimatePriceFormModel = React.useMemo(() => {
    const defaultValues: EstimatePriceFormModel = {
      cmaId: "",
      estimatedPriceMax: "",
      estimatedPriceMin: "",
    };
    if (!cma) return defaultValues;
    const form = cma ? convertCmaDtoToEstimatePriceFormModel(cma) : defaultValues;
    const draft = draftManager.loadDraft({
      formKey: draftEstimatePriceFormKey,
      entityKey: cma?.id,
    });
    if (draft == null) {
      return form;
    }
    //check for outated drafts
    if (draft?.updateId !== (cma?.updateDate?.toISOString() || "new")) {
      draftManager.deleteDraft({ formKey: draftEstimatePriceFormKey, entityKey: cma?.id });
      return form;
    }

    return draft.value as EstimatePriceFormModel;
  }, [cma]);

  const getEstimatePriceFormDraft = React.useCallback(() => {
    return draftManager.loadDraft({ formKey: draftEstimatePriceFormKey, entityKey: cma?.id });
  }, [cma]);

  const updateEstimatePriceFormDraft = React.useCallback(
    (data: any) => {
      draftManager.storeDraft({ formKey: draftEstimatePriceFormKey, entityKey: cma?.id }, data, {
        updateId: cma?.updateDate?.toISOString() || "new",
      });
    },
    [cma]
  );

  const discardEstimatePriceFormDraft = React.useCallback(() => {
    draftManager.deleteDraft({ formKey: draftEstimatePriceFormKey, entityKey: cma?.id });
  }, [cma]);

  const { mutateAsync: setEstimatePrice, status: setEstimatePriceStatus } =
    useSetEstimatePriceMutation({
      onSuccess: () => {
        discardEstimatePriceFormDraft();
      },
    });

  return {
    actions: {
      setEstimatePrice,
      updateEstimatePriceFormDraft,
      discardEstimatePriceFormDraft,
      getEstimatePriceFormDraft,
    },
    state: {
      setEstimatePrice: {
        status: setEstimatePriceStatus,
      },
      estimatePriceForm,
    },
  };
}

/* tslint:disable */
/* eslint-disable */
/**
 * Users API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthCodeLoginResponse,
    AuthCodeLoginResponseFromJSON,
    AuthCodeLoginResponseToJSON,
    AuthCodeParams,
    AuthCodeParamsFromJSON,
    AuthCodeParamsToJSON,
    AuthTokenRequest,
    AuthTokenRequestFromJSON,
    AuthTokenRequestToJSON,
    LocalLoginParams,
    LocalLoginParamsFromJSON,
    LocalLoginParamsToJSON,
    LocalLoginResponse,
    LocalLoginResponseFromJSON,
    LocalLoginResponseToJSON,
} from '../models';

export interface AuthCodeControllerAuthenticateRequest {
    authCodeParams: AuthCodeParams;
}

export interface DevAuthControllerAuthenticateRequest {
    authTokenRequest: AuthTokenRequest;
}

export interface LocalAuthControllerAuthenticateRequest {
    localLoginParams: LocalLoginParams;
}

/**
 * 
 */
export class AuthenticationAuthorizationApi extends runtime.BaseAPI {

    /**
     * Allows users to sign-in using auth_code generated from shared secret. Returns a signed JWT token
     */
    async authCodeControllerAuthenticateRaw(requestParameters: AuthCodeControllerAuthenticateRequest): Promise<runtime.ApiResponse<AuthCodeLoginResponse>> {
        if (requestParameters.authCodeParams === null || requestParameters.authCodeParams === undefined) {
            throw new runtime.RequiredError('authCodeParams','Required parameter requestParameters.authCodeParams was null or undefined when calling authCodeControllerAuthenticate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/auth-code-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthCodeParamsToJSON(requestParameters.authCodeParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthCodeLoginResponseFromJSON(jsonValue));
    }

    /**
     * Allows users to sign-in using auth_code generated from shared secret. Returns a signed JWT token
     */
    async authCodeControllerAuthenticate(requestParameters: AuthCodeControllerAuthenticateRequest): Promise<AuthCodeLoginResponse> {
        const response = await this.authCodeControllerAuthenticateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Allows users to sign-in using a token. This endpoint was created to facilitate testing.
     */
    async devAuthControllerAuthenticateRaw(requestParameters: DevAuthControllerAuthenticateRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.authTokenRequest === null || requestParameters.authTokenRequest === undefined) {
            throw new runtime.RequiredError('authTokenRequest','Required parameter requestParameters.authTokenRequest was null or undefined when calling devAuthControllerAuthenticate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthTokenRequestToJSON(requestParameters.authTokenRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows users to sign-in using a token. This endpoint was created to facilitate testing.
     */
    async devAuthControllerAuthenticate(requestParameters: DevAuthControllerAuthenticateRequest): Promise<void> {
        await this.devAuthControllerAuthenticateRaw(requestParameters);
    }

    /**
     * Allows users to sign-up or sign-in to the system provided they have a @avenue8 account
     */
    async googleAuthControllerGoogleAuthRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/google`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows users to sign-up or sign-in to the system provided they have a @avenue8 account
     */
    async googleAuthControllerGoogleAuth(): Promise<void> {
        await this.googleAuthControllerGoogleAuthRaw();
    }

    /**
     * Callback called by google after authenticating user
     */
    async googleAuthControllerGoogleRedirectCallbackRaw(): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/auth/google/redirect`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Callback called by google after authenticating user
     */
    async googleAuthControllerGoogleRedirectCallback(): Promise<void> {
        await this.googleAuthControllerGoogleRedirectCallbackRaw();
    }

    /**
     * Allows users to sign-in using email and password. Returns a signed JWT token
     */
    async localAuthControllerAuthenticateRaw(requestParameters: LocalAuthControllerAuthenticateRequest): Promise<runtime.ApiResponse<LocalLoginResponse>> {
        if (requestParameters.localLoginParams === null || requestParameters.localLoginParams === undefined) {
            throw new runtime.RequiredError('localLoginParams','Required parameter requestParameters.localLoginParams was null or undefined when calling localAuthControllerAuthenticate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LocalLoginParamsToJSON(requestParameters.localLoginParams),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LocalLoginResponseFromJSON(jsonValue));
    }

    /**
     * Allows users to sign-in using email and password. Returns a signed JWT token
     */
    async localAuthControllerAuthenticate(requestParameters: LocalAuthControllerAuthenticateRequest): Promise<LocalLoginResponse> {
        const response = await this.localAuthControllerAuthenticateRaw(requestParameters);
        return await response.value();
    }

}

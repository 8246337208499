import * as React from "react";
import { InfoIcon } from "@avenue-8/ui-2";
import { SectionEditorProps } from "../../SectionEditor";
import { LabeledCheckbox } from "../LabeledCheckbox";
import { IconContainer } from "src/modules/shared/components/IconContainer";
import { EditorCheckboxContainer, EditorTooltip } from "./GeneralHeaderSectionEditor.styles";
import { GeneralHeaderSectionConfig } from "src/modules/presentation/presentation-generation-logic/sections/general-header/general-header-section-config";
import { MediaFieldForm } from "src/modules/cma-v2/components/MediaFieldForm";
import { useChangeCoverPhotoMutation } from "./useChangeCoverPhotoMutation";
import { useRemoveCoverPhotoMutation } from "./useRemoveCoverPhotoMutation";

export const GeneralHeaderSectionEditor = ({
  section,
  onChanged,
  sourceData,
}: SectionEditorProps<GeneralHeaderSectionConfig>) => {
  const initialModel = React.useMemo(() => {
    const { showClientName = true, showPresentationTitle = true } = section;
    return {
      showClientName,
      showPresentationTitle,
    };
  }, [section]);

  const [model, setModel] = React.useState(initialModel);

  const onChangeSwitch = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const name = e.target.name;
      const updatedModel = { ...model, [name]: !(model as any)[name] };
      setModel(updatedModel);
      onChanged({ sectionConfig: { ...section, ...updatedModel } });
    },
    [model, setModel, onChanged, section]
  );

  const changeCoverPhotoMutation = useChangeCoverPhotoMutation();
  const removeCoverPhotoMutation = useRemoveCoverPhotoMutation();

  const {
    coverPhotoCdnUrl,
    featuredProperty: { photos },
  } = sourceData;
  const coverPhotoUrl = coverPhotoCdnUrl ?? photos?.[0]?.url ?? "";
  const photo = coverPhotoCdnUrl ? [{ id: "presentation-cover-photo", url: coverPhotoUrl }] : [];

  const handleUploadCoverPhoto = React.useCallback(
    async (data) => {
      const photos = await changeCoverPhotoMutation.mutateAsync({
        id: sourceData.id || "",
        data,
      });

      onChanged({
        sectionConfig: section,
        partialSourceData: {
          coverPhotoCdnUrl: photos[0],
        },
      });

      return photos;
    },
    [changeCoverPhotoMutation, onChanged, section, sourceData.id]
  );

  const handleRemoveCoverPhoto = React.useCallback(async () => {
    await removeCoverPhotoMutation.mutateAsync({ id: sourceData.id || "" });
    onChanged({
      sectionConfig: section,
      partialSourceData: { coverPhotoCdnUrl: null },
    });
  }, [removeCoverPhotoMutation, onChanged, section, sourceData.id]);

  return (
    <>
      <EditorCheckboxContainer>
        <LabeledCheckbox
          label="Client Name"
          onChange={onChangeSwitch}
          checked={model.showClientName}
          name="showClientName"
        />
        <EditorTooltip title="You can edit this field in the header above.">
          <IconContainer>
            <InfoIcon />
          </IconContainer>
        </EditorTooltip>
      </EditorCheckboxContainer>
      <EditorCheckboxContainer>
        <LabeledCheckbox
          label="Presentation Title"
          onChange={onChangeSwitch}
          checked={model.showPresentationTitle}
          name="showPresentationTitle"
        />
        <EditorTooltip title="You can edit this field in the header above.">
          <IconContainer>
            <InfoIcon />
          </IconContainer>
        </EditorTooltip>
      </EditorCheckboxContainer>
      <MediaFieldForm
        photos={photo}
        isUploading={false}
        description="Drag + drop your photos here"
        onUploadFiles={handleUploadCoverPhoto}
        removePropertyPhoto={handleRemoveCoverPhoto}
        compact
        inputLabel="Header Photo"
        singlePhoto
      />
    </>
  );
};

import { PatchComparablePropertyRecurringCostItemDto } from "src/modules/shared/apis/cma/generated";
import {
  PropertyDto,
  UpsertComparablePropertyRequestDto,
} from "../../../../shared/apis/cma/generated";
import {
  stripNonNumericNumbers,
  strToNullableFloat,
  strToNullableInt,
  strToNullableString,
} from "../../../../shared/utils/converters";
import { Photo } from "../../PhotosUploader";

export interface EditComparablePropertyFormModel {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  listingStatus: string;
  listingPrice: string;
  listDate: string;
  soldPrice: string;
  soldDate: string;
  neighborhood: string;
  livingSpace: string;
  lotSize: string;
  beds: string;
  baths: string;
  garageSpaces: string;
  yearBuilt: string;
  propertyType: string;
  propertyTypeOther: string;
  buildingStyles: string[];
  buildingStyleOther: string;
  lat: string;
  lng: string;
  floor: string;
  doorman: string;
  description: string;
  updateDate?: string;
  mlsId?: string;
  mlsSource?: string;
  flipTaxRemarks?: string;
  roomsTotal?: string;
  lease: string;

  // While we don't have an interface to add custom fees
  monthlyHoa?: string;
  annualTax?: string;
}

type BuildRecurringCostsParams = {
  annualTax: EditComparablePropertyFormModel["annualTax"];
  monthlyHoa: EditComparablePropertyFormModel["monthlyHoa"];
  state: EditComparablePropertyFormModel["state"];
  propertyType: EditComparablePropertyFormModel["propertyType"];
};
export const getRecurringCostsData = (input: BuildRecurringCostsParams) => {
  const recurringCostsData = {
    tax: {
      label: "Tax",
      value: normalizeNumber(input.annualTax),
      type: "tax",
      frequency: "yearly",
    },
    "hoa-fees": {
      label: "HOA Fees",
      value: normalizeNumber(input.monthlyHoa),
      type: "hoa-fees",
      frequency: "monthly",
    },
  };

  const isNYProperty = input?.state === "NY";
  if (isNYProperty) {
    if (input.propertyType === "Coop") {
      recurringCostsData["hoa-fees"].label = "Maintenance";
    } else {
      recurringCostsData["hoa-fees"].label = "Common Charges";
    }
  } else {
    if (input?.propertyType === "Condominium") {
      recurringCostsData["hoa-fees"].label = "HOA Fees";
    } else {
      recurringCostsData["hoa-fees"].label = "Monthly Fees";
    }
  }

  return recurringCostsData;
};

export const buildRecurringCosts = (input: BuildRecurringCostsParams) => {
  const recurringCosts = Object.values(getRecurringCostsData(input));
  return recurringCosts;
};

// Experimental. Since the form will be alterning from 1 field to 4 fields, test the usability, maybe always show the fields is the best option.
export const getPropertyFieldsToShow = (input: {
  state: EditComparablePropertyFormModel["state"];
}) => {
  const isNYProperty = input?.state === "NY";

  const fields = {
    annualTax: isNYProperty,
    flipTaxRemarks: isNYProperty,
    monthlies: isNYProperty,
  };

  return fields;
};

const parseRecurringCosts = (
  recurringCosts: PatchComparablePropertyRecurringCostItemDto[],
  data: EditComparablePropertyFormModel
) => {
  return recurringCosts.reduce((acc, curr) => {
    if (curr.type === "hoa-fees") {
      acc.monthlyHoa = curr.value?.toString() ?? "";
    }
    if (curr.type === "tax") {
      acc.annualTax = curr.value?.toString() ?? "";
    }
    return acc;
  }, data);
};

export const normalizeNumber = (input: string | null | undefined) =>
  strToNullableInt(stripNonNumericNumbers(input));

export const convertEditComparablePropertyFormModelToComparablePropertyRequestDto = (
  input: EditComparablePropertyFormModel
) => {
  const hoaFee = normalizeNumber(input.monthlyHoa);

  const data: UpsertComparablePropertyRequestDto = {
    mlsId: input.mlsId,
    mlsSource: input.mlsSource,
    addressLine1: strToNullableString(input.addressLine1) ?? undefined,
    addressLine2: strToNullableString(input.addressLine2),
    bathCount: strToNullableFloat(input.baths),
    bedCount: strToNullableInt(input.beds),
    city: strToNullableString(input.city) ?? undefined,
    listPrice: normalizeNumber(input.listingPrice),
    description: strToNullableString(input.description),
    floor: strToNullableInt(input.floor),
    garageSpaces: normalizeNumber(input.garageSpaces),
    hoaFeeCents: hoaFee != null ? hoaFee * 100 : undefined,
    lat: strToNullableFloat(input.lat) ?? undefined,
    lng: strToNullableFloat(input.lng) ?? undefined,
    listDate: input.listDate ? new Date(input.listDate) : null,
    livingAreaSqFt: normalizeNumber(input.livingSpace),
    lotSizeSqFt: normalizeNumber(input.lotSize),
    listingStatus: strToNullableString(input.listingStatus) ?? undefined,
    neighborhood: strToNullableString(input.neighborhood) ?? undefined,
    state: strToNullableString(input.state) ?? undefined,
    styleOther: strToNullableString(input.buildingStyleOther),
    styles: input.buildingStyles || ["Other"],
    type: strToNullableString(input.propertyType) || "Other",
    typeOther: strToNullableString(input.propertyTypeOther),
    yearBuilt: strToNullableInt(input.yearBuilt),
    zipCode: strToNullableString(input.zip) ?? undefined,
    hasDoormanAttendance: input.doorman === "yes",
    roomsTotal: normalizeNumber(input.roomsTotal),
    flipTaxRemarks: strToNullableString(input.flipTaxRemarks),
    recurringCosts: buildRecurringCosts({
      annualTax: input.annualTax,
      monthlyHoa: input.monthlyHoa,
      propertyType: input.propertyType,
      state: input.state,
    }),
    isLease: input.lease === "yes",
    soldDate: input.soldDate ? new Date(input.soldDate) : null,
    soldPrice: normalizeNumber(input.soldPrice),
  };
  return data;
};

export const convertPropertyDtoToEditComparablePropertyFormModel = (input: PropertyDto) => {
  const parseISOStringDate = (date?: string | Date) => {
    return date ? new Date(date).toISOString()?.substring(0, 10) : "";
  };

  const data: EditComparablePropertyFormModel = {
    mlsId: input.mlsId ?? "",
    mlsSource: input.mlsSource ?? "",
    addressLine1: input.addressLine1 ?? "",
    addressLine2: input.addressLine2 ?? "",
    baths: input.baths?.toString() ?? "",
    beds: input.beds?.toString() ?? "",
    buildingStyleOther: input.propertyStyleOther ?? "",
    buildingStyles: input.propertyStyles?.filter((x) => x?.trim()) ?? [],
    city: input.city ?? "",
    description: input.description ?? "",
    doorman: input.hasDoormanAttendance ? "yes" : "no",
    floor: input.floor?.toString() ?? "",
    garageSpaces: input.garageSpaces?.toString() ?? "",
    lat: input.latitude?.toString() ?? "",
    listDate: parseISOStringDate(input.listDate) ?? "",
    listingPrice: input.listPrice?.toString() ?? "",
    livingSpace: input.livingSpace?.toString() ?? "",
    lng: input.longitude?.toString() ?? "",
    lotSize: input.lotSize?.toString() ?? "",
    listingStatus: input.listingStatus ?? "",
    monthlyHoa: input.hoaFees?.toString() ?? "",
    neighborhood: input.neighborhood ?? "",
    propertyType: input.propertyType ?? "",
    propertyTypeOther: input.propertyTypeOther ?? "",
    state: input.state ?? "",
    yearBuilt: input.yearBuilt?.toString() ?? "",
    zip: input.zipCode ?? "",
    flipTaxRemarks: input.flipTaxRemarks ?? "",
    roomsTotal: input.roomsTotal?.toString() ?? "",
    lease: input.isLease ? "yes" : "no",
    soldPrice: input.soldPrice?.toString() ?? "",
    soldDate: parseISOStringDate(input.soldDate) ?? "",
  };

  const { monthlyHoa, annualTax } = parseRecurringCosts(input.recurringCosts ?? [], data);

  data.monthlyHoa = monthlyHoa ?? "";
  data.annualTax = annualTax ?? "";

  return data;
};

export const getPropertyPhotos = (property: PropertyDto) => {
  return property?.images?.map(({ id, url, cdnUrl, caption }) => {
    const photo: Photo = {
      id,
      url: cdnUrl || url,
      caption,
    };
    return photo;
  });
};

export const COMPARABLE_PROPERTY_FORM_DEFAULT_STATE: EditComparablePropertyFormModel = {
  mlsId: "",
  mlsSource: "",
  updateDate: "",
  addressLine1: "",
  addressLine2: "",
  baths: "",
  beds: "",
  city: "",
  description: "",
  floor: "",
  garageSpaces: "",
  lat: "",
  listDate: "",
  listingPrice: "",
  soldPrice: "",
  soldDate: "",
  livingSpace: "",
  lng: "",
  lotSize: "",
  listingStatus: "",
  neighborhood: "",
  state: "",
  buildingStyleOther: "",
  buildingStyles: [],
  propertyType: "",
  propertyTypeOther: "",
  yearBuilt: "",
  zip: "",
  doorman: "",
  flipTaxRemarks: "",
  roomsTotal: "",
  lease: "",

  // Taxes
  monthlyHoa: "",
  annualTax: "",
};

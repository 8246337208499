import { Checkbox, Grid, Typography, styled } from "@avenue-8/ui-2";
import { listingStatusColor, media } from "../../../../../../../shared/css-snippets";

export const PropertyCardContainer = styled(Grid)`
  margin: 24px 0;
  cursor: pointer;
  word-break: break-word;

  &:hover {
    background-color: ${(p) => p.theme.av8.backgroundAlt};
  }
`;
export const PropertyImageColumn = styled(Grid)`
  padding-right: 15px;
`;
export const PropertyImage = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  & img {
    object-fit: cover;
    width: 100%;
    min-height: 112px;
    max-height: 112px;
    object-position: center;
    border-radius: 4px;

    ${() => media.smallDesktopDown} {
      max-height: initial;
    }
  }

  & div {
    width: 100%;
    max-height: 112px;
    border-radius: 4px;

    ${() => media.smallDesktopDown} {
      max-height: initial;
    }
  }
`;
export const PropertyResultCardInnerContainer = styled(Grid)`
  border-top: 2px solid ${(p) => p.theme.av8.dividerColor};
  padding-top: 15px;
  padding-right: 1rem;
`;
export const PropertyDataColumn = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const PropertyColumn = styled(Grid)`
  padding-right: 5;
`;
export const PropertyResultItem = styled.div<{ $flex?: boolean }>`
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.25px;
  color: #2a2a2a;
  margin-bottom: 16px;

  ${(p) => p.$flex && "display: flex;"}

  &:not(:first-of-type) {
    margin-bottom: 0;
  }
`;
export const PropertyItemLabel = styled(Typography)<{ smallWidth?: boolean }>`
  font-size: 0.625rem;
  text-transform: uppercase;
  line-height: 1;
  max-width: ${(p) => (p.smallWidth ? "180px" : "none")};
  font-weight: 400;
  letter-spacing: 2.5px;
  color: ${(p) => p.theme.av8.secondaryColor};
  margin-bottom: 10px;
`;
export const PropertyItemValue = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1;
`;
export const Address1Value = styled(PropertyItemValue)`
  margin-bottom: 0.5rem;
`;
export const PropertyBathsAndBedsValue = styled(PropertyItemValue)`
  margin-left: 8px;
`;
export const PropertyToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
`;
export const PropertyListinStatus = styled(Typography)<{ $listingStatus: string | undefined }>`
  text-align: right;
  font-size: 0.675rem;
  text-transform: uppercase;
  line-height: 1.43;
  font-weight: bolder;
  letter-spacing: 2.5px;

  ${(p) => listingStatusColor(p.$listingStatus)}
`;
export const PropertyCheckbox = styled(Checkbox)`
  padding: 0;
  &.Mui-disabled {
    opacity: 0.3;
  }
`;

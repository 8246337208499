import * as React from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import GenerateRoutes from "../../routes/GenerateRoutes";
import { Role, RouteModel } from "../../routes/routes";
import { ThemeProvider, toastStyles, styled } from "@avenue-8/ui-2";
import { AgentOnboarding } from "./components/AgentOnboarding";
import { RouteComponentProps } from "react-router";
import { useAuthContext } from "../shared/contexts/AuthContext/AuthContext";
import { AuthenticatedRedirect } from "../shared/contexts/AuthContext/AuthenticatedRedirect";
import { AuthenticationReconciliator } from "../shared/contexts/AuthContext/AuthenticationReconciliator";
import { AgentOnboardingLogicProvider } from "./hooks/useAgentOnboardingLogic";
import { AppBlockingLoader } from "../shared/components/AppBlockingLoader";

const Page = styled.div`
  background: ${(p) => p.theme.av8.backgroundAlt};
  min-height: 100vh;
  position: relative;
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  ${toastStyles}
`;

const renderAgentOnboardingFlow = (props: RouteComponentProps<any>) => {
  return (
    <AgentOnboardingLogicProvider step={props.match.params.step ?? "personal-details"}>
      <AgentOnboarding />
    </AgentOnboardingLogicProvider>
  );
};

const baseRoute = "/agent-onboarding";
const routes: RouteModel[] = [
  {
    path: `${baseRoute}`,
    render: renderAgentOnboardingFlow,
    exact: true,
    roles: [Role.Agent],
  },
  {
    path: `${baseRoute}/:step`,
    render: renderAgentOnboardingFlow,
    exact: true,
    roles: [Role.Agent],
  },
];

export const AgentOnboardingModule = React.memo(() => {
  const {
    state: { user },
  } = useAuthContext();

  return (
    <>
      <AuthenticationReconciliator />
      <AuthenticatedRedirect>
        <ThemeProvider darkMode={false}>
          <AppBlockingLoader />
          <Page>
            <GenerateRoutes routes={routes} userRole={user?.role} />
          </Page>
          <ReactQueryDevtools initialIsOpen={false} />
        </ThemeProvider>
      </AuthenticatedRedirect>
    </>
  );
});

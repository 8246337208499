import { getStatusByListingStatus } from "./normalizePropertyStatus";

type PropertyType = "comparable-active" | "comparable-pending" | "comparable-sold" | "other";

const colors = {
  active: "#107F10", // successColor
  sold: "#D90D02", // dangerColor
  pending: "#FFCE45", // warningColor
  other: "#131319", // primaryColor
};

export const getColorByType = (type: string) => {
  if (type === "comparable-active") return colors.active;
  else if (type === "comparable-sold") return colors.sold;
  else if (type === "comparable-pending") return colors.pending;
  return colors.other;
};

export const parseComparablePropertyType = (
  listingStatus: string | null | undefined
): PropertyType => {
  const normalizedStatus = getStatusByListingStatus(listingStatus as any);
  if (!normalizedStatus) return "other";
  if (normalizedStatus === "sold") return "comparable-sold";
  if (["active", "soon"].includes(normalizedStatus)) return "comparable-active";
  if (["pending", "contingent"].includes(normalizedStatus)) return "comparable-pending";
  return "other";
};

import * as React from "react";
import {
  Input,
  Typography,
  Button,
  FormControl,
  InputLabel,
  TextFieldProps,
  TextField,
  styled,
} from "@avenue-8/ui-2";
import { debounce } from "@mui/material";
import { FreeTextSectionConfig } from "src/modules/presentation/presentation-generation-logic/models/section-config";
import { SectionEditorProps } from "../SectionEditor";

const LimitHint = styled(Typography)<{ $error: boolean }>`
  position: absolute;
  right: 2px;
  bottom: 0;
  transform: translateY(100%);
  border-radius: 5px;
  padding: 0.2rem 0;
  color: ${(p) => (p.$error ? p.theme.av8.dangerColor : p.theme.av8.typography.secondaryColor)};
`;

const InputWithLimitHintContainer = styled.div`
  position: relative;
`;

const InputWithLimitHint = React.forwardRef(
  (
    {
      maxLength,
      autoCap = true,
      style: inputStyle,
      ...rest
    }: TextFieldProps["InputProps"] & { autoCap?: boolean; maxLength: number },
    ref: any
  ) => {
    const initValue: any = rest.value ?? rest.defaultValue ?? "";
    const [length, setLength] = React.useState<number>(initValue.toString().length);

    const InputPropsMod: TextFieldProps["InputProps"] = {
      ...rest,
      style: { ...inputStyle, paddingBottom: "2.2rem" },
      onInputCapture: (e: any) => {
        let value: string = e.target?.value ?? "";
        if (autoCap && value.length > maxLength) {
          value = value.substring(0, Math.min(maxLength, value.length));
          if (e.target) e.target.value = value;
        }
        rest?.onInputCapture?.(e);
        setTimeout(() => setLength(value.length), 0);
      },
    };

    return (
      <InputWithLimitHintContainer>
        <Input {...InputPropsMod} ref={ref} />
        <LimitHint variant="caption" $error={length > maxLength}>
          {length}/{maxLength}
        </LimitHint>
      </InputWithLimitHintContainer>
    );
  }
);

export const FreeTextEditor = ({
  section,
  onChanged,
  onDelete,
}: SectionEditorProps<FreeTextSectionConfig>) => {
  const lastestSection = React.useRef(section);
  lastestSection.current = section;

  const [model, setModel] = React.useState({ title: section.title, content: section.content });
  const latestModel = React.useRef(model);
  latestModel.current = model;
  const latestOnChanged = React.useRef(onChanged);
  latestOnChanged.current = onChanged;

  const debouncedOnChanged = React.useMemo(() => {
    return debounce((model: any) => {
      latestOnChanged.current({ sectionConfig: { ...lastestSection.current, ...model } });
    }, 1000);
  }, []);

  const onChangeText = React.useMemo(() => {
    return (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const name = e.target.name;
      const updatedModel = { ...latestModel.current, [name]: e.target.value };
      setModel(updatedModel);
      debouncedOnChanged(updatedModel);
    };
  }, [setModel, debouncedOnChanged]);

  return (
    <>
      <TextField label="Title" value={model.title} name="title" onChange={onChangeText} />
      <FormControl>
        <InputLabel>Content</InputLabel>
        <InputWithLimitHint
          autoCap={true}
          maxRows={6}
          maxLength={5000}
          multiline={true}
          value={model.content}
          name="content"
          onChange={onChangeText}
        />
      </FormControl>
      <Button variant="text" onClick={() => onDelete(section.id)}>
        Delete Section
      </Button>
    </>
  );
};

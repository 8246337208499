import { FeatureToggleStrategy } from "./feature-toggle-strategy";
import { FeatureToggle } from "./FeatureToggleContext";

export class FeatureToggleService {
  constructor(private strategy: FeatureToggleStrategy) {}

  getFeatureToggleValue<Key, Value>(toggle: FeatureToggle<Key, Value>): Value {
    const key = toggle.key as any;
    const rawValue = this.strategy.getFeatureToggleValue(key);
    return toggle.parseValue(rawValue);
  }

  identify(userId: string | number) {
    this.strategy.identify(userId);
  }
}

import { faker } from "@faker-js/faker";
import { SideBySideComparisonItem } from "src/modules/presentation/presentation-generation-logic/sections/side-by-side-comparison/side-by-side-comparison-section-view";
import { CMADto } from "src/modules/shared/apis/cma/generated";
import { PropertyDto } from "src/modules/shared/apis/cma/generated/models/PropertyDto";
import { SinglePropertyProps } from "src/modules/cma-v2/components/CreateCMA/Steps/Search/components/SideBySideProperties/SideBySideItem";
import { PropertyPhoto } from "src/modules/presentation/presentation-generation-logic";
import { v4 as uuidv4 } from "uuid";

function getRandomNumberId() {
  return Math.floor(Math.random() * 10000) + 1;
}

function getPropertyDto(): PropertyDto {
  return {
    id: uuidv4(),
    addressLine1: "Address Line 1",
    addressLine2: "Address Line 2",
    area: "area",
    baths: 1,
    beds: 1,
    buildingName: "Awesome Building",
    city: "New York",
    garageSpaces: 1,
    hoaFees: 12,
    listDate: new Date(),
    listPrice: 1000000,
    soldPrice: 900000,
    images: [
      {
        id: "1",
        url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        caption: "sample photo 1",
      },
      {
        id: "2",
        url: "https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        caption: "sample photo 2",
      },
      {
        id: "3",
        url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        caption: "sample photo 3",
      },
    ],
    latitude: 36.10213,
    livingSpace: 800,
    longitude: -115.17442,
    lotSize: 300,
    mlsId: "12343",
    mlsSource: "TEST_SOURCE",
    neighborhood: "neighborhood",
    nonGarageSpaces: 0,
    soldDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
    state: "NY",
    propertyStyle: "rococo",
    propertyType: "Single Family Home",
    yearBuilt: 1985,
    zipCode: "89109",
    description: "Description of the property",
    hasDoormanAttendance: false,
    listingAgent: {
      brokerage: "Brokerage",
      dre: "123456",
      lastSourceUpdateDate: new Date(),
      name: "John Doe",
    },
    flipTaxRemarks: "1% FOR THE SELLER",
    roomsTotal: 5,
    recurringCosts: [
      {
        label: "Tax",
        value: 4800,
        type: "tax",
        frequency: "yearly",
      },
      {
        label: "HOA Fees",
        value: 300,
        type: "hoa-fees",
        frequency: "monthly",
      },
    ],
    floor: 1,
  };
}

function getCmaDto(): CMADto {
  const cma: CMADto = {
    id: uuidv4(),
    subjectProperty: getPropertyDto(),
    agent: {
      id: getRandomNumberId(),
      name: "John Doe",
      email: "agent@email.com",
      state: "CA",
      license: "123456",
      title: "",
      phone: "9999988888",
      headshotUrl: "",
      sourceId: "",
    },
    comparableProperties: [getPropertyDto(), getPropertyDto(), getPropertyDto()],
    clientDisplayName: "Maria",
    isArchived: false,
    presentationType: "cma",
    status: "active",
    title: "My CMA",
    hasUnpublishedChanges: false,
    canPublish: true,
    canCustomize: true,
    updateDate: new Date(),
  };

  return cma;
}

function getPropertyPhoto(): PropertyPhoto {
  return {
    url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
    caption: `sample photo ${getRandomNumberId().toString()}`,
    thumbnail:
      "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=128",
  };
}

// RANDOM DATA
function getRandomPropertyDto(): PropertyDto {
  const state = faker.address.stateAbbr();

  return {
    id: uuidv4(),
    addressLine1: faker.address.streetAddress(),
    addressLine2: faker.datatype.number({ min: 0, max: 1 })
      ? faker.address.secondaryAddress()
      : undefined,
    latitude: Number(faker.address.latitude()),
    longitude: Number(faker.address.longitude()),
    neighborhood: faker.name.lastName(),
    state,
    zipCode: faker.address.zipCodeByState(state),
    area: faker.address.city(),
    baths: faker.datatype.number({ min: 1, max: 5 }),
    beds: faker.datatype.number({ min: 1, max: 10 }),
    buildingName: "Awesome Building",
    city: faker.address.cityName(),
    garageSpaces: faker.datatype.number({ min: 0, max: 3 }),
    hoaFees: faker.datatype.number({ min: 100, max: 5000 }),
    listDate: faker.date.past(),
    listPrice: faker.datatype.number({ min: 100000, max: 99_999_999 }),
    soldPrice: faker.datatype.number({ min: 100000, max: 99_999_999 }),
    images: [
      {
        id: "1",
        url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        caption: "sample photo 1",
      },
      {
        id: "2",
        url: "https://images.pexels.com/photos/269077/pexels-photo-269077.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        caption: "sample photo 2",
      },
      {
        id: "3",
        url: "https://images.pexels.com/photos/4394112/pexels-photo-4394112.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
        caption: "sample photo 3",
      },
    ],
    livingSpace: faker.datatype.number({ min: 500, max: 5000 }),
    lotSize: faker.datatype.number({ min: 100, max: 1000 }),
    mlsId: faker.random.numeric(7),
    mlsSource: faker.helpers.arrayElement(["CA_MRMLS", "CA_SFAR", "PERCHWELL"]),
    nonGarageSpaces: faker.datatype.number({ min: 0, max: 3 }),
    soldDate: faker.date.past(),
    propertyStyle: "rococo",
    propertyType: faker.helpers.arrayElement(["Single Family Home", "Condominium", "Loft"]),
    yearBuilt: faker.datatype.number({ min: 1900, max: new Date().getFullYear() }),
    description: "Description of the property",
    hasDoormanAttendance: faker.datatype.boolean(),
    listingAgent: {
      brokerage: faker.company.name(),
      dre: faker.random.numeric(7),
      lastSourceUpdateDate: faker.date.recent(),
      name: faker.name.fullName(),
    },
    flipTaxRemarks: "1% FOR THE SELLER",
    roomsTotal: faker.datatype.number({ min: 1, max: 15 }),
    recurringCosts: [
      {
        label: "Tax",
        value: 4800,
        type: "tax",
        frequency: "yearly",
      },
      {
        label: "HOA Fees",
        value: 300,
        type: "hoa-fees",
        frequency: "monthly",
      },
    ],
    floor: faker.datatype.number({ min: 1, max: 20 }),
  };
}

function getRandomCmaDto(): CMADto {
  const cma: CMADto = {
    id: uuidv4(),
    subjectProperty: getRandomPropertyDto(),
    agent: {
      id: getRandomNumberId(),
      name: faker.name.fullName(),
      email: faker.internet.email(),
      state: faker.address.state(),
      license: faker.datatype.number({ min: 100000, max: 999999 }).toString(),
      title: "",
      phone: faker.phone.number(),
      headshotUrl: faker.internet.avatar(),
      sourceId: "",
    },
    comparableProperties: [getRandomPropertyDto(), getRandomPropertyDto(), getRandomPropertyDto()],
    clientDisplayName: faker.name.firstName(),
    presentationType: "cma",
    title: `${faker.word.adjective()} ${faker.word.adverb()}`,
    updateDate: faker.date.recent(),
    coverPhotoCdnUrl: `https://placekitten.com/200/200?image=${faker.datatype.number({
      min: 1,
      max: 9,
    })}`,

    // Not used
    isArchived: false,
    status: "active",
    hasUnpublishedChanges: false,
    canPublish: true,
    canCustomize: true,
  };

  return cma;
}

function getSinglePropertyProps(): SinglePropertyProps {
  return {
    propertyData: getPropertyDto(),
    isSubjectProperty: false,
  };
}

function getSideBySideComparisonItem(): SideBySideComparisonItem {
  return {
    id: getRandomNumberId().toString(),
    addressLine1: faker.address.streetAddress(),
    addressLine2: faker.address.secondaryAddress(),
    city: faker.address.cityName(),
    state: faker.address.stateAbbr(),
    zipCode: faker.address.zipCode(),
    propertyType: faker.helpers.arrayElement([
      "single-family",
      "multi-family",
      "condo",
      "townhouse",
    ]),
    yearBuilt: faker.datatype.number({ min: 1900, max: new Date().getFullYear() - 1 }),
    neighborhood: faker.address.city(),
    livingSpace: faker.datatype.number({ min: 900, max: 10000 }),
    beds: faker.datatype.number({ min: 1, max: 20 }),
    baths: faker.datatype.number({ min: 1, max: 20 }),
    lotSize: faker.datatype.number({ min: 0, max: 1000 }),
    garageParkingSpaces: faker.datatype.number({ min: 0, max: 20 }),
    hoaFees: faker.datatype.number({ min: 0, max: 10000 }),
    costPerInternalSqFt: faker.datatype.number({ min: 0, max: 10000 }),
    currentPrice: faker.datatype.number({ min: 100_000, max: 900_000_000 }),
    soldPrice: faker.datatype.number({ min: 100_000, max: 900_000_000 }),
    photos: [getPropertyPhoto(), getPropertyPhoto(), getPropertyPhoto()],
    hasDoormanAttendance: faker.datatype.boolean(),
    listingStatus: faker.helpers.arrayElement(["active", "sold", "pending", "soon", "contingent"]),
    listDate: faker.date.past(),
    soldDate: faker.date.past(),
    roomsTotal: faker.datatype.number({ min: 1, max: 50 }),
    flipTaxRemarks: faker.lorem.sentence(),
    recurringCosts: [
      {
        label: faker.lorem.word(),
        value: faker.datatype.number({ min: 0, max: 10000 }),
        type: faker.helpers.arrayElement(["tax", "hoa-fees"]),
        frequency: faker.helpers.arrayElement(["monthly", "yearly"]),
      },
      {
        label: faker.lorem.word(),
        value: faker.datatype.number({ min: 0, max: 10000 }),
        type: faker.helpers.arrayElement(["tax", "hoa-fees"]),
        frequency: faker.helpers.arrayElement(["monthly", "yearly"]),
      },
    ],
    totalMonthlies: faker.datatype.number({ min: 0, max: 10000 }),
    daysOnMarket: faker.datatype.number({ min: 0, max: 1000 }),
  };
}

export const dataFactory = {
  getSinglePropertyProps,
  getPropertyDto,
  getCmaDto,
  getPropertyPhoto,
  getSideBySideComparisonItem,
  getRandomCmaDto,
};

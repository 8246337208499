export const arrayMedian = function (data: number[]): number {
  const mid = Math.floor(data.length / 2);
  const nums = [...data].sort((a, b) => a - b);
  return data.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

export const arrayMax = function (data: number[]): number {
  return Math.max(...data);
};

export const arrayMin = function (data: number[]): number {
  return Math.min(...data);
};

export const arrayAverage = function (data: number[]): number {
  return data.reduce((a, b) => a + b, 0) / data.length;
};

import { AgentDetailsFormModel } from "./Steps/AgentDetailsStep/AgentDetailsForm";
import { PersonalDetailsFormModel } from "./Steps/PersonalDetailsStep/PersonalDetailsForm";
import { ReviewFormModel } from "./Steps/ReviewStep/ReviewStep";

export const convertPersonalDetailsFormModelToAgentOnboarding = (
  data: PersonalDetailsFormModel
) => {
  const personalDetails: PersonalDetailsFormModel = {
    firstName: data.firstName,
    lastName: data.lastName,
    preferredContactEmail: data.preferredContactEmail ? data.preferredContactEmail : null,
    phoneNumber: data.phoneNumber ? data.phoneNumber : null,
  };
  return personalDetails;
};

export const convertAgentDetailsFormModelToAgentOnboarding = (data: AgentDetailsFormModel) => {
  const agentDetails: AgentDetailsFormModel = {
    licenseNumber: data.licenseNumber,
    state: data.state,
    brokerage: data.brokerage,
    team: data.team ? data.team : null,
  };
  return agentDetails;
};

export const convertReviewFormModelToAgentOnboarding = (data: ReviewFormModel) => {
  const personalDetails = convertPersonalDetailsFormModelToAgentOnboarding(data);
  const agentDetails = convertAgentDetailsFormModelToAgentOnboarding(data);
  const reviewForm: ReviewFormModel = {
    ...personalDetails,
    ...agentDetails,
    agreeTermsAndConditions: Boolean(data.agreeTermsAndConditions),
  };
  return reviewForm;
};

import {
  DefaultConfigBuilderContext,
  PresentationType,
  SectionViewBuilderContext,
  SectionWidget,
} from "../section-widget";
import { FreeHtmlTextSectionConfig } from "./free-html-text-section-config";
import { FreeHtmlTextSectionView } from "./free-html-text-section-view";
import { filterXSS } from "xss";
import { v4 as uuidv4 } from "uuid";

export class FreeHtmlTextSectionWidget
  implements SectionWidget<"free-html-text", FreeHtmlTextSectionConfig, FreeHtmlTextSectionView>
{
  type: "free-html-text" = "free-html-text";

  supportedPresentationTypes: PresentationType[] = ["cma", "general-presentation"];

  buildDefaultConfig(_: DefaultConfigBuilderContext): FreeHtmlTextSectionConfig {
    return {
      type: "free-html-text",
      navTitle: "Free Text",
      id: uuidv4(),
      visibility: true,
      title: "Untitled Section",
      htmlContent: `<h1 style="text-align:center;">Untitled Section</h1><p style="text-align:center;">This is the new free text section.</p>`,
    };
  }

  sanitizeSectionConfig(sectionConfig: FreeHtmlTextSectionConfig): FreeHtmlTextSectionConfig {
    const { htmlContent } = sectionConfig;
    const sanitizedHtmlContent = filterXSS(htmlContent, {
      whiteList: {
        h1: ["style"],
        h2: ["style"],
        b: ["style"],
        p: ["style"],
        div: ["style"],
        strong: ["style"],
        em: ["style"],
        a: ["href", "target", "rel"],
        ul: ["style"],
        ol: ["style"],
        li: ["style"],
        br: [],
        i: ["style"],
        span: ["style"],
      },
    });
    return { ...sectionConfig, htmlContent: sanitizedHtmlContent };
  }

  buildView({
    sectionConfig: config,
  }: SectionViewBuilderContext<FreeHtmlTextSectionConfig, null>): FreeHtmlTextSectionView {
    if (config.type !== "free-html-text") throw new Error("Unexpected section type.");
    const { id, type, visibility, htmlContent, title, navTitle } = config;
    const view: FreeHtmlTextSectionView = {
      id,
      type,
      visibility,
      htmlContent,
      title,
      navTitle,
    };
    return view;
  }
}

import { styled } from "@avenue-8/ui-2";
import { ResponsiveContextMenu } from "../../../../ResponsiveContextMenu";
import { COMPARABLE_SORT_OPTIONS_MAP, SortOptionKey } from "../comparable-sort-options";

const SortByItemText = styled.span`
  height: 2rem;
  line-height: 2rem;
  min-width: 16rem;
`;

interface Props {
  anchorEl: React.ReactNode;
  onClose: () => void;
  onSortOptionClick: (key: SortOptionKey) => void;
  sortOptions?: SortOptionKey[];
}

export const SearchSortMenu = (props: Props) => {
  const {
    anchorEl,
    onClose,
    onSortOptionClick,
    sortOptions = ["relevance", "solddate", "price", "distance"] as SortOptionKey[],
  } = props;
  return (
    <ResponsiveContextMenu
      anchorEl={anchorEl}
      menuPositionProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      {({ ItemComponent }) => {
        return sortOptions.map((key) => {
          const option = COMPARABLE_SORT_OPTIONS_MAP.get(key);
          return (
            Boolean(option) && (
              <ItemComponent key={key} onClick={() => onSortOptionClick(key)} data-testid="sort-option">
                <SortByItemText>{option?.label}</SortByItemText>
              </ItemComponent>
            )
          );
        });
      }}
    </ResponsiveContextMenu>
  );
};

import { useQuery } from "react-query";
import { appEventEmitter } from "../../../../../../events/app-event-emitter";
import { getCMAPropertiesApiClient } from "../../../../../shared/apis/cma/api-factories";
import { ListPropertyResponseDto, PropertyDto } from "../../../../../shared/apis/cma/generated";
import {
  convertSearchStepModelToPropertySearch,
  isModelEnoughToSearch,
  normalizeModel,
  SearchComparableFormModel,
} from "./search-comparable-form-model";

export function usePropertySearchQueries(
  state: SearchComparableFormModel,
  presentationType?: string,
  subjectProperty?: PropertyDto
) {
  const normalizedModel = normalizeModel(state);
  const searchQuery = useQuery<ListPropertyResponseDto>(
    ["properties", normalizedModel],
    async () => {
      const data = await getCMAPropertiesApiClient().propertyControllerSearchV2({
        searchV2Dto: convertSearchStepModelToPropertySearch(normalizedModel, subjectProperty),
      });
      presentationType &&
        appEventEmitter.emit({ eventType: "cma-search-items-loaded", presentationType });
      return data;
    },
    { enabled: isModelEnoughToSearch(state), keepPreviousData: true, refetchOnWindowFocus: false }
  );
  return { searchQuery };
}

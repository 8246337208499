import { css } from "@avenue-8/ui-2";
import { breakpoints } from "./breakpoints";
import { getStatusByListingStatus } from "./utils/normalizePropertyStatus";

export const media = {
  desktopUp: `@media screen and (min-width: ${breakpoints.desktop + 1}px)`,
  desktopDown: `@media screen and (max-width: ${breakpoints.desktop}px)`,
  smallDesktopUp: `@media screen and (min-width: ${breakpoints.smallDesktop + 1}px)`,
  smallDesktopDown: `@media screen and (max-width: ${breakpoints.smallDesktop}px)`,
  tabletUp: `@media screen and (min-width: ${breakpoints.tablet + 1}px)`,
  tabletDown: `@media screen and (max-width: ${breakpoints.tablet}px)`,
  mobileUp: `@media screen and (min-width: ${breakpoints.mobile + 1}px)`,
  mobileDown: `@media screen and (max-width: ${breakpoints.mobile}px)`,
};

export const listingStatusColor = (status?: string) => css`
  color: ${(p) => {
    const normalizedStatus = getStatusByListingStatus(status as any);
    switch (normalizedStatus) {
      case "active":
      case "soon":
        return p.theme.av8.successColor;
      case "sold":
        return p.theme.av8.dangerColor;
      case "pending":
      case "contingent":
        return p.theme.av8.warningColor;
      default:
        return p.theme.av8.secondaryColor;
    }
  }};
`;

export const cssMaxLines = (maxLines: number) => css`
  max-lines: ${() => maxLines};
  line-clamp: ${() => maxLines};
  display: -webkit-box;
  -webkit-line-clamp: ${() => maxLines};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
`;

import * as React from "react";
import { ConstrainedContainer } from "../ConstrainedContainer";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { AvoidPrintBreakInside } from "../AvoidPrintBreakInside";
import { Title1, Subtitle2 } from "../typography";
import { SmallDivider } from "../SmallDivider";
import {
  PropertyPhoto,
  SideBySideComparisonItem,
  SideBySideComparisonSectionView,
} from "src/modules/presentation/presentation-generation-logic";
import { NotPrintOff } from "../NotPrintOff";
import { PhotoGalleryContextProvider } from "../../../../../shared/contexts/PhotoGalleryContext";
import { ChevronIcon, useTheme } from "@avenue-8/ui-2";
import { SectionViewLayout } from "../SectionViewLayout";
import {
  ComparisionPrintItem,
  ComparisionRowPrint,
  FloatOverContainer,
  NavButton,
  NavigationButtonsContainer,
  PrintOffContainer,
  SwiperContainer,
} from "./SideBySideComparison.styles";
import { SinglePropertyContent } from "./SinglePropertyContent";
import { SingleDynamicProperty } from "./SingleDynamicProperty";
import { PrintOff } from "../PrintOff";

interface SideBySideComparisonProps extends SideBySideComparisonSectionView {
  displayMode: "desktop" | "mobile" | "print" | "tablet";
  galleries: { [key: string]: PropertyPhoto[] };
}
export const SideBySideComparison = (props: SideBySideComparisonProps) => {
  const { id, displayMode, properties, title, galleries, preferences } = props;
  const mobile = displayMode === "mobile";
  const [enableDrag, setEnableDrag] = React.useState(true);
  const theme = useTheme();

  if (!properties) {
    return null;
  }

  // Using the first property as subject property to check if it should be displayed or not
  const subjectProperty = properties[0];

  function checkEnableDrag(swiper: any) {
    setTimeout(function () {
      const bulletsLength = swiper.pagination?.bullets?.length ?? 0;
      const enable = bulletsLength > 1;
      if (enableDrag !== enable) {
        setEnableDrag(enable);
      }
    }, 1);
  }

  const propertiesPrintRows: Array<SideBySideComparisonItem[]> = [];
  const printCols = 4;
  const propertiesCopy = [...properties.slice(subjectProperty?.id ? 0 : 1)];

  while (propertiesCopy.length > 0) propertiesPrintRows.push(propertiesCopy.splice(0, printCols));

  const swiperKey = `swiper_key:${enableDrag}:${properties.length}`;

  const dynamicComparision =
    displayMode !== "print" ? (
      <PrintOffContainer>
        {enableDrag && !mobile && (
          <NavigationButtonsContainer>
            <NavButton className="swiper-button-prev">
              <ChevronIcon rotate={180} color={theme.av8.primaryColor} />
            </NavButton>
            <NavButton className="swiper-button-next">
              <ChevronIcon color={theme.av8.primaryColor} />
            </NavButton>
          </NavigationButtonsContainer>
        )}
        <SwiperContainer>
          {!!subjectProperty.id && (
            <FloatOverContainer>
              <SingleDynamicProperty
                propertyData={subjectProperty}
                fixedLabels={true}
                displayMode={displayMode}
                gallery={galleries[subjectProperty.id]}
                isSubjectProperty
                preferences={preferences}
              />
            </FloatOverContainer>
          )}
          <Swiper
            key={swiperKey}
            slidesPerView={"auto"}
            pagination={{ clickable: true, dynamicBullets: true }}
            centeredSlides={false}
            onSnapGridLengthChange={checkEnableDrag}
            onAfterInit={checkEnableDrag}
            grabCursor={enableDrag}
            allowTouchMove={enableDrag}
            mousewheel
            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
          >
            {!!subjectProperty.id && (
              <SwiperSlide
                key={`subject-property-${subjectProperty.id}`}
                style={{ width: mobile ? "150px" : "230px" }}
              />
            )}
            {properties.slice(1)?.map((property, index) => {
              return (
                <SwiperSlide key={index} style={{ width: mobile ? "150px" : "230px" }}>
                  <SingleDynamicProperty
                    propertyData={property}
                    highlight={false}
                    fixedLabels={!subjectProperty.id}
                    displayMode={displayMode}
                    gallery={galleries[property.id]}
                    isSubjectProperty={false}
                    preferences={preferences}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </SwiperContainer>
      </PrintOffContainer>
    ) : null;

  const comparisionPrintMode = (
    <NotPrintOff $disabled={displayMode === "print"}>
      <ConstrainedContainer>
        {propertiesPrintRows.map((row, rowIndex) => {
          return (
            <AvoidPrintBreakInside key={`row-${rowIndex}`}>
              <ComparisionRowPrint>
                {row.map((property, index) => {
                  return (
                    <ComparisionPrintItem
                      key={`col-${rowIndex}-${index}`}
                      $isSubjectProperty={subjectProperty?.id === property.id}
                    >
                      <SinglePropertyContent
                        propertyData={property}
                        key={index}
                        highlight={index === 0}
                        gallery={galleries[property.id]}
                        preferences={preferences}
                        isSubjectProperty={index === 0}
                      />
                    </ComparisionPrintItem>
                  );
                })}
              </ComparisionRowPrint>
            </AvoidPrintBreakInside>
          );
        })}
      </ConstrainedContainer>
    </NotPrintOff>
  );

  const comparisonTitle = displayMode !== "print" && (
    <PrintOff>
      <AvoidPrintBreakInside>
        <ConstrainedContainer id={id}>
          <div style={{ textAlign: "center" }}>
            <Title1 marginBottom={1}>{title ?? "Where We Searched"}</Title1>
            {/* <Text>{description ?? ''}</Text> */}
            <SmallDivider />
            <Subtitle2
              marginTop={1.5}
              mobileMarginTop={1.5}
              marginBottom={2}
              mobileMarginBottom={2}
            >
              Comparable Properties
            </Subtitle2>
          </div>
        </ConstrainedContainer>
      </AvoidPrintBreakInside>
    </PrintOff>
  );

  return (
    <SectionViewLayout condensedSpaceWhenPrint>
      <PhotoGalleryContextProvider>
        {comparisonTitle}
        {dynamicComparision}
        {comparisionPrintMode}
      </PhotoGalleryContextProvider>
    </SectionViewLayout>
  );
};

import { UserInfoModel, UserJwtTokenModel } from "./AuthContext";

class AuthStorageService {
  private static instance = new AuthStorageService();
  constructor() {
    if (AuthStorageService.instance) {
      return AuthStorageService.instance;
    }
  }

  store({
    remember: _remember,
    authToken,
    userInfo,
    updatedAt,
  }: {
    remember: boolean;
    authToken: string | null;
    userInfo: UserInfoModel | null;
    updatedAt?: number;
  }) {
    const storage: Storage = localStorage;

    if (authToken == null) storage.removeItem("authToken");
    else storage.setItem("authToken", authToken);

    if (userInfo == null) storage.removeItem("authInfo");
    else storage.setItem("authInfo", btoa(JSON.stringify(userInfo)));

    storage.setItem("authUpdatedAt", updatedAt?.toString() || new Date().getTime().toString());
  }

  private decodeBase64(base64: string) {
    try {
      return JSON.parse(atob(base64));
    } catch {
      return null;
    }
  }

  private validateToken(token: string | undefined | null) {
    const tokenParts = token?.split(".");

    if (tokenParts == null || tokenParts.length !== 3) return false;

    const headers = this.decodeBase64(tokenParts[0]);
    if (headers == null || headers.alg !== "HS256") return false;

    const payload = this.decodeBase64(tokenParts[1]);
    if (payload == null || payload.exp == null) return false;

    return true;
  }

  authTokenHasExpired(authToken: string | undefined | null) {
    if (!authToken || !this.validateToken(authToken)) return true;

    const tokenParts = authToken.split(".");
    const payload = this.decodeBase64(tokenParts[1]) as UserJwtTokenModel;
    const expirationDate = new Date(payload.exp * 1000);

    return expirationDate < new Date();
  }

  get(): {
    authToken: string;
    user: UserJwtTokenModel;
    userInfo: UserInfoModel;
    remember: boolean;
    updatedAt: number;
  } | null {
    try {
      const authToken = localStorage.getItem("authToken");

      if (!authToken) return null;

      if (!this.validateToken(authToken)) {
        this.clear();
        return null;
      }

      const remember = true;

      const userInfoEncoded = localStorage.getItem("authInfo") || "";
      const userInfo = JSON.parse(atob(userInfoEncoded));
      const user = JSON.parse(atob(authToken.split(".")[1]));
      const updatedAt = parseInt(localStorage.getItem("authUpdatedAt") || "0") || 0;

      return { authToken, user, userInfo, remember, updatedAt };
    } catch {
      //in case there is a problem to decode - maybe by consequence of a version mismatch
      return null;
    }
  }

  clear() {
    [localStorage, sessionStorage].forEach((storage) => {
      storage.removeItem("authToken");
      storage.removeItem("authInfo");
      storage.removeItem("authUpdatedAt");
    });
  }
}

export const authStorageService = Object.freeze(new AuthStorageService());

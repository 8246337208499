import { numberOnly } from "src/modules/shared/utils/validation-patterns";
import { isNullOrEmptyString } from "src/modules/shared/utils/validations";

export const validateMinMax = (min: any, max: any) => {
  if (isNullOrEmptyString(min) || isNullOrEmptyString(max)) return true;

  return numberParser(min) <= numberParser(max);
};

export const numberParser = (value: string) =>
  Number(value.replace(new RegExp(numberOnly, "g"), ""));

import { CircularProgress } from "@avenue-8/ui-2";
import { baseStyledThemed as styled } from "./../theme";

export const Box = styled.div`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerBox = () => {
  return (
    <Box>
      <CircularProgress />
    </Box>
  );
};

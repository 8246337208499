import * as React from "react";
import { ToggleButtonGroup, ToggleButtonGroupProps } from "@mui/material";
import { Typography } from "@avenue-8/ui-2";
import {
  PresentationToggleButton,
  PresentationToggleErrorMessage,
  PresentationToggleGroupContainer,
} from "./PresentationToggleGroup.styles";

interface Props extends ToggleButtonGroupProps {
  errorMessage?: string;
}

export const PresentationToggleGroup = React.forwardRef(
  ({ errorMessage, ...props }: Props, ref: any) => {
    return (
      <PresentationToggleGroupContainer>
        <ToggleButtonGroup exclusive aria-label="Presentation type" fullWidth {...props} ref={ref}>
          <PresentationToggleButton
            style={{ marginRight: "12px" }}
            value="cma"
            key="cma"
            data-testid="create-cma-presentation-button"
          >
            <Typography variant="body1">CMA Presentation</Typography>
            <Typography variant="body2">
              Create a Comparative Market Analysis about a particular subject property
            </Typography>
          </PresentationToggleButton>
          <PresentationToggleButton
            style={{ marginLeft: "12px" }}
            value="general"
            key="general"
            data-testid="create-general-presentation-button"
          >
            <Typography variant="body1">General Presentation</Typography>
            <Typography variant="body2">
              Ignore the subject property and find a collection of properties
            </Typography>
          </PresentationToggleButton>
        </ToggleButtonGroup>
        {errorMessage && (
          <PresentationToggleErrorMessage variant="overline">
            {errorMessage}
          </PresentationToggleErrorMessage>
        )}
      </PresentationToggleGroupContainer>
    );
  }
);

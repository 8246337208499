export const groupBy = function <C, K>(
  collection: C[],
  keyExtractor: (item: C) => K
): { [key: string]: C[] } {
  return collection.reduce((acc: any, item) => {
    const key = keyExtractor(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
};

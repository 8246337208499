import * as React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useAppContext } from "../../../AppContext";
import { toast } from "@avenue-8/ui-2";
import { getOnboadingApiClient } from "../../shared/apis/user/api-factories";
import { useAuthContext } from "../../shared/contexts/AuthContext/AuthContext";
import { makeBLContext } from "../../shared/hooks/makeBLContext";
import { AgentDetailsFormModel } from "../components/Steps/AgentDetailsStep/AgentDetailsForm";
import { PersonalDetailsFormModel } from "../components/Steps/PersonalDetailsStep/PersonalDetailsForm";
import { AgreementFormModel } from "../components/Steps/ReviewStep/AgreementForm";
import { ReviewFormModel } from "../components/Steps/ReviewStep/ReviewStep";

function useAgentOnboardingLogicInner({ step }: { step?: string }) {
  const onboardingApi = getOnboadingApiClient();
  const { actions: appActions } = useAppContext();
  const {
    state: { authenticationToken, user, userInfo },
    actions: authActions,
  } = useAuthContext();
  const queryClient = useQueryClient();

  const { data: loadAgentOnboardingData, status: loadAgentOnboardingStatus } = useQuery(
    ["agent-onboarding", user?.agentId],
    async () => await onboardingApi.onboardingControllerGetOnboarding(),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (userInfo && data.isFinished) {
          authActions.updateUserInfo({ ...userInfo, completedOnboardingAt: new Date() });
        }
      },
    }
  );

  const { mutateAsync: saveAgentOnboarding, status: saveAgentOnboardingStatus } = useMutation(
    async ({ data, tryFinish = false }: { data: Partial<ReviewFormModel>; tryFinish?: boolean }) =>
      await appActions.watchPromise(
        onboardingApi.onboardingControllerSaveOnboarding({
          saveOnboardingRequest: { data, tryFinish },
        }),
        { blocking: true, message: tryFinish ? "Setting up your account..." : "Saving changes..." }
      ),
    {
      onSuccess: (data, variables) => {
        const newFullName = variables.data.firstName
          ? `${variables.data.firstName} ${variables.data.lastName}`.trim()
          : null;
        if (userInfo && newFullName && newFullName !== userInfo?.fullName) {
          userInfo.fullName = newFullName;
          authActions.updateUserInfo(userInfo);
        }
        queryClient.invalidateQueries(["agent-onboarding", user?.agentId]);
      },
      onError: (error) => {
        toast.error("Failed to save onboarding data.");
        throw error;
      },
    }
  );

  const personalDetailsForm = React.useMemo(() => {
    if (loadAgentOnboardingStatus !== "success") return {} as PersonalDetailsFormModel;
    const formData: PersonalDetailsFormModel = {
      firstName: loadAgentOnboardingData?.data.firstName,
      lastName: loadAgentOnboardingData?.data.lastName,
      phoneNumber: loadAgentOnboardingData?.data.phoneNumber,
      preferredContactEmail: loadAgentOnboardingData?.data.preferredContactEmail,
    };
    return formData;
  }, [loadAgentOnboardingStatus, loadAgentOnboardingData]);

  const agentDetailsForm = React.useMemo(() => {
    if (loadAgentOnboardingStatus !== "success") return {} as AgentDetailsFormModel;
    const formData: AgentDetailsFormModel = {
      licenseNumber: loadAgentOnboardingData?.data.licenseNumber,
      state: loadAgentOnboardingData?.data.state,
      brokerage: loadAgentOnboardingData?.data.brokerage,
      team: loadAgentOnboardingData?.data.team,
    };
    return formData;
  }, [loadAgentOnboardingStatus, loadAgentOnboardingData]);

  const agreementForm = React.useMemo(() => {
    if (loadAgentOnboardingStatus !== "success") return {} as AgreementFormModel;
    const formData: AgreementFormModel = {
      agreeTermsAndConditions: Boolean(loadAgentOnboardingData?.isFinished),
    };
    return formData;
  }, [loadAgentOnboardingStatus, loadAgentOnboardingData]);

  const {
    mutateAsync: uploadAgentHeadshotPhoto,
    status: uploadAgentHeadshotPhotoStatus,
    data: uploadAgentHeadshotPhotoData,
    reset: uploadAgentHeadshotPhotoReset,
  } = useMutation(
    async (data: FormData) => {
      const execute = async () => {
        const response = await fetch(
          `${process.env.REACT_APP_CMA_API_URL}/cmas/agents/${user?.agentId}/headshot-image`,
          {
            method: "post",
            headers: {
              Authorization: `Bearer ${authenticationToken}`,
            },
            body: data,
          }
        );

        if (response.status >= 200 && response.status < 300) {
          return await response.json();
        } else {
          throw response;
        }
      };
      return await appActions.watchPromise(execute(), {
        blocking: true,
        message: "Uploading headshot..",
      });
    },
    {
      onSuccess: (data) => {
        if (userInfo && data) {
          userInfo.avatar = data.url;
          authActions.updateUserInfo(userInfo);
        }
      },
    }
  );

  return {
    state: {
      step,
      personalDetailsForm,
      agentDetailsForm,
      agreementForm,
      saveAgentOnboarding: {
        status: saveAgentOnboardingStatus,
      },
      uploadAgentHeadshotPhoto: {
        data: uploadAgentHeadshotPhotoData,
        status: uploadAgentHeadshotPhotoStatus,
      },
      loadAgentOnboardingQuery: {
        data: loadAgentOnboardingData,
        status: loadAgentOnboardingStatus,
      },
    },
    actions: {
      uploadAgentHeadshotPhoto,
      uploadAgentHeadshotPhotoReset,
      saveAgentOnboarding,
    },
  };
}

export const {
  LogicContextProvider: AgentOnboardingLogicProvider,
  useLogicContext: useAgentOnboardingLogic,
} = makeBLContext({ useLogic: useAgentOnboardingLogicInner });

import { ActionInvoker, makeReducerContext } from "../../../shared/hooks/makeReducerContext";
import { EditAgentAccountFormModel } from "./components/EditAgentAccountForm";
import { UserAndAgentResponse } from "./../../../shared/apis/user/generated/models";
import { getUsersApiClient } from "../../../shared/apis/user/api-factories";
import { UserControllerCreateUserWithOptionalAgentRequest } from "../../../shared/apis/user/generated";
import { Role } from "../../../../routes/routes";
import { toast } from "react-toastify";

// STATE
interface BaseState {}
interface NotLoadedState extends BaseState {
  loadState: "not-loaded";
}
interface LoadFailedState extends BaseState {
  loadState: "load-failed";
}
type SaveState = "idle" | "saving" | "saved" | "error";
type RefreshState = "idle" | "refreshing" | "refreshed" | "error";
interface LoadedState extends BaseState {
  agentAccountId?: string;
  loadState: "loaded";
  agentAccount?: UserAndAgentResponse;
  formData?: EditAgentAccountFormModel;
  saveState: SaveState;
  isDirty?: boolean;
  refreshState?: RefreshState;
  sendResetPasswordEmailState?: SendResetPasswordEmailState;
}
type State = LoadedState | NotLoadedState | LoadFailedState;

const defaultState: State = {
  loadState: "not-loaded",
};

// ACTION PAYLOADS
interface Load {
  type: "load";
  formData?: EditAgentAccountFormModel;
  agentAccount?: UserAndAgentResponse;
  agentAccountId?: string;
}
interface LoadFail {
  type: "load-fail";
}
interface SetIsDirty {
  type: "set-is-dirty";
}
interface SaveInit {
  type: "save-init";
  formData?: EditAgentAccountFormModel;
}
interface SaveError {
  type: "save-error";
}
interface Save {
  type: "save";
  agentAccountId: string;
}

export type SendResetPasswordEmailState = "idle" | "init" | "success" | "fail";
interface SendResetPasswordEmailInit {
  type: "send-reset-password-email-init";
}
interface SendResetPasswordEmail {
  type: "send-reset-password-email";
  sendResetPasswordEmailState?: SendResetPasswordEmailState;
}

type Action =
  | Load
  | LoadFail
  | SetIsDirty
  | Save
  | SaveInit
  | SaveError
  | SendResetPasswordEmailInit
  | SendResetPasswordEmail;

export interface ActionInvokerContext {
  wrapLoadPromise?: <T>(promise: Promise<T>) => Promise<T>;
  confirm: (windowConfig: {
    title: string;
    message: string;
    confirmButtonText: string;
  }) => Promise<boolean>;
  showToast: {
    success: (message: string) => void;
    error: (message: string) => void;
    info: (message: string) => void;
  };
}

// ACTION INVOKER
export class CreateAgentAccountActions extends ActionInvoker<Action, ActionInvokerContext, State> {
  userApi = getUsersApiClient;
  wrapLoadPromise<T>(promise: Promise<T>) {
    return this.context.wrapLoadPromise?.(promise) || promise;
  }

  private async loadAgentAccount(agentAccountId?: string) {
    const agentAccountResponse = agentAccountId
      ? await this.wrapLoadPromise(
          this.userApi().userControllerGetUserWithAgent({ id: agentAccountId })
        )
      : undefined;

    let formData: EditAgentAccountFormModel | undefined;

    if (agentAccountResponse != null) {
      const { user, agent } = agentAccountResponse;
      formData = {
        agentAccountId: user.id.toString(),
        name: user.firstName || agent.name,
        email: user.email,
        dre: agent.license,
        state: agent.state,
      };
    }
    return { agentAccountId, formData, agentAccount: agentAccountResponse };
  }

  async load(agentAccountId?: string) {
    if (this.state.loadState !== "not-loaded") return;
    try {
      const agentAccountResponse = await this.loadAgentAccount(agentAccountId);
      this.dispatch({
        type: "load",
        ...agentAccountResponse,
      });
    } catch (e) {
      console.error(e);
      this.dispatch({ type: "load-fail" });
    }
  }

  async save(data: EditAgentAccountFormModel) {
    if (this.state.loadState !== "loaded") return;
    data.agentAccountId = this.state.agentAccountId;
    data.name = data.name.trim();
    data.email = data.email.trim();
    data.dre = data.dre?.trim();

    this.dispatch({ type: "save-init", formData: data });

    const params: UserControllerCreateUserWithOptionalAgentRequest = {
      createUserWithOptionalAgent: {
        user: {
          email: data.email,
          firstName: data.name,
          role: Role.Agent,
        },
        agent: {
          name: data.name,
          email: data.email,
          license: data.dre ?? undefined,
          state: data.state ?? undefined,
        },
      },
    };

    try {
      if (!data.agentAccountId) {
        const { userId } = await this.wrapLoadPromise(
          this.userApi().userControllerCreateUserWithOptionalAgent(params)
        );
        this.dispatch({ type: "save", agentAccountId: String(userId) });
      } else {
        await this.wrapLoadPromise(
          this.userApi().userControllerUpdateUserWithOptionalAgent({
            id: parseInt(data.agentAccountId),
            ...params,
          })
        );
        this.dispatch({ type: "save", agentAccountId: data.agentAccountId });
      }
      this.context.showToast.success("Saved successfully.");
      return true;
    } catch (e) {
      console.error(e);
      this.dispatch({ type: "save-error" });
      this.context.showToast.error("Failed to save.");
      return false;
    }
  }

  async resetPasswordForAgent(params: { email: string }) {
    if (this.state.loadState !== "loaded") return;
    this.dispatch({ type: "send-reset-password-email-init" });

    try {
      await this.wrapLoadPromise(
        this.userApi().userControllerStartResetPassword({ userStartResetPasswordParams: params })
      );
      toast.success("Password reset email sent", {
        toastId: "agent-modal-sent-reset-password",
      });
      this.dispatch({ type: "send-reset-password-email" });
    } catch (e) {
      console.error(e);
      toast.error("Failed to send password reset email", {
        toastId: "agent-modal-sent-reset-password-failed",
      });
      this.dispatch({ type: "send-reset-password-email", sendResetPasswordEmailState: "fail" });
    }
  }

  setIsDirty() {
    if (this.state.loadState === "loaded" && this.state.isDirty !== true)
      this.dispatch({ type: "set-is-dirty" });
  }
}
const defaultInvoker = new CreateAgentAccountActions();

//REDUCER
const reducer: (state: State, action: Action) => State = (state, action) => {
  if (state.loadState === "loaded") {
    switch (action.type) {
      case "set-is-dirty": {
        if (state.isDirty === true) return state;
        return { ...state, isDirty: true };
      }
      case "save-init": {
        return { ...state, formData: action.formData ?? state.formData, saveState: "saving" };
      }
      case "save": {
        return {
          ...state,
          saveState: "saved",
          isDirty: false,
          agentAccountId: action.agentAccountId,
        };
      }
      case "save-error": {
        return { ...state, saveState: "error" };
      }
      case "send-reset-password-email-init": {
        return { ...state, sendResetPasswordEmailState: "init" };
      }
      case "send-reset-password-email": {
        return {
          ...state,
          sendResetPasswordEmailState: action.sendResetPasswordEmailState ?? "success",
        };
      }
      default:
        break;
    }
  } else if (state.loadState === "not-loaded") {
    switch (action.type) {
      case "load": {
        const { formData, agentAccountId, agentAccount } = action;
        return {
          ...state,
          saveState: "idle",
          loadState: "loaded",
          formData,
          agentAccountId,
          agentAccount,
        };
      }
      case "load-fail": {
        return { loadState: "load-failed" };
      }
      default:
        break;
    }
  }
  console.warn(
    `Could not find reducer handler for action ${action.type} in loadState ${state.loadState}`
  );
  return state;
};

export const {
  useReducerContext: useCreateAgentAccountContext,
  ReducerContextProvider: CreateAgentAccountContextProvider,
} = makeReducerContext<State, Action, ActionInvokerContext, CreateAgentAccountActions>({
  reducer,
  defaultState,
  defaultInvoker,
});

import { Button, styled } from "@avenue-8/ui-2";

export const SearchControls = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  margin-bottom: 1rem;
`;
export const SearchFieldWrapper = styled.div<{ $expand: boolean }>`
  grid-area: 1 / span ${(p) => (p.$expand ? "3" : "2")};
  min-width: 220px;
`;
export const SearchAction = styled(Button)`
  display: flex;
  justify-content: space-between;
`;
export const ResultsText = styled.div`
  margin-bottom: 1.25rem;
`;
export const ListControls = styled.div<{ $extraMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  ${(p) => p.$extraMargin && "margin: 2rem 0;"});
  & > div:first-of-type {
    margin-left: 0;
  }
  & > div:last-child {
    margin-right: 0;
  }
`;
export const ListControl = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0.75rem;
  gap: 0.5rem;
  cursor: pointer;
  color: ${(p) => p.theme.av8.secondaryColor};
`;
export const ManualCompCTAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    text-decoration: underline;
    display: inline;
    cursor: pointer;
  }
`;

/* tslint:disable */
/* eslint-disable */
/**
 * Presentation API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TemplateListItemDto,
    TemplateListItemDtoFromJSON,
    TemplateListItemDtoFromJSONTyped,
    TemplateListItemDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface ListTemplateResponseDto
 */
export interface ListTemplateResponseDto {
    /**
     * 
     * @type {Array<TemplateListItemDto>}
     * @memberof ListTemplateResponseDto
     */
    items: Array<TemplateListItemDto>;
}

export function ListTemplateResponseDtoFromJSON(json: any): ListTemplateResponseDto {
    return ListTemplateResponseDtoFromJSONTyped(json, false);
}

export function ListTemplateResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTemplateResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'items': ((json['items'] as Array<any>).map(TemplateListItemDtoFromJSON)),
    };
}

export function ListTemplateResponseDtoToJSON(value?: ListTemplateResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'items': ((value.items as Array<any>).map(TemplateListItemDtoToJSON)),
    };
}



import * as React from "react";
import {
  Select,
  InputLabel,
  SelectOption,
  TextFieldProps,
  SelectProps,
  FormHelperText,
} from "@avenue-8/ui-2";
import { Controller, useFormContext } from "react-hook-form";
import {
  PropertyControllerListPropertyTypesRequest,
  PropertyDto,
  PropertyTypeItemDto,
} from "../../../shared/apis/cma/generated";
import { TextFieldBase } from "../TextFieldBase";
import { usePropertyTypeQueries } from "../../queries/usePropertyTypeQueries";

const renderPropertyTypeOptions = (list?: Array<PropertyTypeItemDto | undefined>) => {
  list = [undefined, ...(list ?? [])];
  return list?.map((item) => {
    return (
      <SelectOption value={item?.propertyType} key={`property-type-${item?.id}`}>
        {item?.propertyType ?? "None"}
      </SelectOption>
    );
  });
};

export type PropertyTypeFieldSelectProps = {
  label?: string;
  name?: string;
  propertyType?: PropertyDto["propertyType"];
  selectProps?: SelectProps;
  filter?: PropertyControllerListPropertyTypesRequest;
};
export const PropertyTypeFieldSelect = (props: PropertyTypeFieldSelectProps) => {
  const { label, name, propertyType, selectProps, filter } = props;
  const { errors, setValue } = useFormContext();
  const { propertyTypesQuery } = usePropertyTypeQueries(filter);

  const propertyTypes = React.useMemo(
    () => propertyTypesQuery.data?.propertyTypes || [],
    [propertyTypesQuery.data?.propertyTypes]
  );

  React.useEffect(() => {
    if (propertyTypes?.length && propertyType) {
      const propertyTypeExists = propertyTypes.some(
        (item) => item.propertyType.toLowerCase() === propertyType.toLowerCase()
      );
      if (!propertyTypeExists) {
        setValue("propertyType", "Other");
        setValue("propertyTypeOther", propertyType);
      }
    }
  }, [propertyTypes, propertyType, setValue]);

  return (
    <>
      <InputLabel htmlFor={props.name ?? "propertyType"} id="property-type-label">
        {label ?? "Property Type"}
      </InputLabel>
      {propertyTypes?.length ? (
        <Controller
          name={name ?? "propertyType"}
          render={({ name, onChange, ...rest }) => (
            <Select
              labelId="property-type-label"
              id={name}
              fullWidth
              {...rest}
              {...selectProps}
              onBlur={(e) => {
                selectProps?.onBlur?.(e);
                rest.onBlur();
              }}
              onChange={(e) => {
                const value = e.target.value;
                onChange(e);
                selectProps?.onChange?.(e, value);
                if (String(e.target.value).toLowerCase() !== "other") {
                  setValue("propertyTypeOther", "");
                }
              }}
              disabled={propertyTypesQuery.isLoading || selectProps?.disabled}
            >
              {renderPropertyTypeOptions(propertyTypes)}
            </Select>
          )}
        />
      ) : (
        <Select value="" />
      )}
      <FormHelperText error={!!errors.propertyType}>{errors.propertyType?.message}</FormHelperText>
    </>
  );
};

export type PropertyTypeFieldOtherProps = {
  label?: string;
  name?: string;
  textFieldProps?: TextFieldProps;
};
export const PropertyTypeFieldOther = (props: PropertyTypeFieldOtherProps) => {
  const { name, textFieldProps } = props;
  const { errors, setValue } = useFormContext();
  return (
    <>
      <InputLabel htmlFor={name ?? "propertyTypeOther"}>If other type, specify</InputLabel>
      <Controller
        name={name ?? "propertyTypeOther"}
        rules={{ required: false }}
        render={({ ref, onChange, ...rest }) => (
          <TextFieldBase
            placeholder="N/A"
            errors={errors}
            inputRef={ref}
            {...rest}
            onBlur={(e) => {
              textFieldProps?.onBlur?.(e);
              rest.onBlur();
            }}
            onChange={(e: any) => {
              const value = e.target.value;
              onChange(e);
              if (String(value).trim()) {
                setValue("propertyType", "Other");
              }
            }}
          />
        )}
      />
    </>
  );
};

export const PropertyTypeField = {
  Select: PropertyTypeFieldSelect,
  Other: PropertyTypeFieldOther,
};

import * as React from "react";
import GoogleMapReact from "google-map-react";
import { Grid, Typography, MapMarker } from "@avenue-8/ui-2";
import { StatBlock } from "../../../../SearchSubjectProperty/SearchSubjectPropertyResultItem";
import { getPropertyTypeLabel } from "../../../../../../../shared/constants";
import { PropertyDto } from "../../../../../../../shared/apis/cma/generated";
import { PhotoGalleryContextProvider } from "../../../../../../../shared/contexts/PhotoGalleryContext";
import { PhotosSwiper } from "../../../../SearchSubjectProperty/PhotosSwiper";
import { BreakdownPopover } from "src/modules/cma-v2/components/PresentationView/components/BreakdownPopover";
import { calculateMonthlies } from "../../../../SearchSubjectProperty/subject-property-helper";
import {
  Wrapper,
  ItemTitle,
  DetailsGrid,
  ContentTitle,
  ComparisonItemListingStatus,
  Description,
  StyledMap,
} from "./SideBySideExpandedItem.styles";

const usedPropertyKeys = new Map<string, number>();
function getSafePropertyKey(key: string | undefined) {
  if (!key) return;
  const index = usedPropertyKeys.get(key) ?? 0;
  usedPropertyKeys.set(key, index + 1);
  return `${key}-${index}`;
}

interface Props {
  propertyData?: PropertyDto;
}

export const SideBySideExpandedItem = (props: Props) => {
  const { propertyData } = props;
  const doorman =
    typeof propertyData?.hasDoormanAttendance === "boolean"
      ? propertyData?.hasDoormanAttendance
        ? "Yes"
        : "No"
      : "-";

  const isManuallyAdded = propertyData?.mlsSource === "CUSTOM";
  const totalMonthlies = calculateMonthlies(propertyData?.recurringCosts ?? []);

  return (
    <Wrapper>
      <ItemTitle>
        <Typography variant="h5" data-testid="expanded-address">
          {propertyData?.addressLine1}
        </Typography>
        <Typography variant="body1" data-testid="expanded-city-state-zip">
          {propertyData?.city && propertyData?.state
            ? `${propertyData?.city}, ${propertyData?.state} `
            : ""}
          {propertyData?.zipCode}
        </Typography>
      </ItemTitle>
      <div data-testid="expanded-photos">
        <PhotoGalleryContextProvider>
          <PhotosSwiper
            photos={propertyData?.images ?? []}
            swiperId={propertyData?.id || ""}
            spaceBetween={16}
            photosPerSlide={4}
            centeredSlides={false}
          />
        </PhotoGalleryContextProvider>
      </div>
      <Grid container item marginTop={2}>
        <DetailsGrid container item md={5}>
          <Grid item md={6}>
            <ContentTitle variant="body2" color="secondary">
              Listing Status
            </ContentTitle>
            <ComparisonItemListingStatus
              variant="body2"
              color="primary"
              $listingStatus={propertyData?.listingStatus}
              data-testid="expanded-listing-status"
            >
              {propertyData?.listingStatus}
            </ComparisonItemListingStatus>
          </Grid>
          <Grid container item md={6}>
            <Grid item md={6} data-testid="expanded-beds">
              <StatBlock statTitle={"Beds"} statContent={propertyData?.beds ?? "-"} />
            </Grid>
            <Grid item md={6} data-testid="expanded-baths">
              <StatBlock statTitle={"Baths"} statContent={propertyData?.baths ?? "-"} />
            </Grid>
          </Grid>
          <Grid item md={6} data-testid="expanded-list-price">
            <StatBlock
              statTitle={"List Price"}
              statContent={
                propertyData?.listPrice
                  ? `$${propertyData?.listPrice?.toLocaleString("en-US")}`
                  : "-"
              }
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-sold-price">
            <StatBlock
              statTitle={"Sold Price"}
              statContent={
                propertyData?.soldPrice
                  ? `$${propertyData?.soldPrice?.toLocaleString("en-US")}`
                  : "-"
              }
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-mls-id">
            <StatBlock
              statTitle={"MLS ID"}
              statContent={isManuallyAdded ? "-" : propertyData?.mlsId ?? "-"}
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-neighborhood">
            <StatBlock statTitle={"Neighborhood"} statContent={propertyData?.neighborhood ?? "-"} />
          </Grid>
          <Grid item md={6} data-testid="expanded-year">
            <StatBlock statTitle={"Year Built"} statContent={propertyData?.yearBuilt ?? "-"} />
          </Grid>
          <Grid item md={6} data-testid="expanded-property-type">
            <StatBlock
              statTitle={"Property Type"}
              statContent={getPropertyTypeLabel(propertyData?.propertyType) ?? "-"}
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-monthlies">
            <StatBlock
              statTitle={
                <>
                  Monthlies{" "}
                  {Boolean(totalMonthlies) && (
                    <BreakdownPopover fees={propertyData?.recurringCosts ?? []} />
                  )}
                </>
              }
              statContent={totalMonthlies ? `$${totalMonthlies.toLocaleString("en-US")}` : "-"}
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-living-space">
            <StatBlock
              statTitle={"Living Space"}
              statContent={
                propertyData?.livingSpace
                  ? `${propertyData.livingSpace.toLocaleString("en-US")} Sq. ft.`
                  : "-"
              }
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-lot-size">
            <StatBlock
              statTitle={"Lot Size"}
              statContent={
                propertyData?.lotSize
                  ? `${propertyData.lotSize.toLocaleString("en-US")} Sq. ft.`
                  : "-"
              }
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-doorman">
            <StatBlock statTitle={"Doorman"} statContent={doorman} />
          </Grid>
          <Grid item md={6} data-testid="expanded-garage">
            <StatBlock
              statTitle={"Parking Spaces"}
              statContent={propertyData?.garageSpaces ?? "-"}
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-agent">
            <StatBlock
              statTitle={"Listing Agent"}
              statContent={propertyData?.listingAgent?.name ?? "-"}
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-license">
            <StatBlock
              statTitle={"License Number"}
              statContent={propertyData?.listingAgent?.dre ?? "-"}
            />
          </Grid>
          <Grid item md={6} data-testid="expanded-mls-source">
            <StatBlock
              statTitle={"MLS Source"}
              statContent={isManuallyAdded ? "-" : propertyData?.mlsSource ?? "-"}
            />
          </Grid>
        </DetailsGrid>
        <Grid item md={7} data-testid="expanded-description">
          <Description>{propertyData?.description ?? "No description available"}</Description>
          <StyledMap>
            <GoogleMapReact
              options={{
                clickableIcons: false,
                fullscreenControl: false,
                controlSize: 36,
              }}
              center={
                propertyData
                  ? {
                      lat: propertyData.latitude || 0,
                      lng: propertyData.longitude || 0,
                    }
                  : undefined
              }
              bootstrapURLKeys={{ key: "AIzaSyB-zsIKNAhya9smgBJvvSqwYVcArAbmzts" }}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={11}
              style={{
                width: "100%",
              }}
            >
              <MapMarker
                key={getSafePropertyKey(propertyData?.id)}
                isActive={false}
                lat={propertyData?.latitude || 0}
                lng={propertyData?.longitude || 0}
                propertyData={{ ...propertyData, cover: "test" }}
                color={"#000000"}
              />
            </GoogleMapReact>
          </StyledMap>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

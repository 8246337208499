import { Avatar, Typography, EditIcon, styled } from "@avenue-8/ui-2";
import { Skeleton } from "@mui/material";
import { CustomOverlineButton } from "../../shared/components/CustomOverlineButton";

interface SubjectPropertyHeaderProps {
  imageUrl?: string;
  address?: string;
  clientName?: string;
  onEditClick?: () => void;
  loading: boolean;
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50px;
  gap: 0.75rem;
`;

const CustomAvatar = styled(Avatar)`
  height: 48px;
  width: 48px;
  margin-right: 24px;
`;

const CustomOverlineName = styled(Typography)`
  color: ${(p) => p.theme.av8.typography.secondaryColor};
  line-height: 0.9rem;
  font-size: 0.9rem;
  text-transform: none;
`;

const OverlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const CustomH5 = styled(Typography)`
  color: ${(p) => p.theme.av8.typography.primaryColor};
  line-height: 1.5rem;
`;

export const SubjectPropertyHeader = ({
  imageUrl,
  clientName,
  address,
  onEditClick,
  loading,
}: SubjectPropertyHeaderProps) => {
  return (
    <HeaderContainer>
      <CustomAvatar variant="rounded" alt="Listing" src={imageUrl} />
      <DetailsContainer>
        {loading ? (
          <Skeleton animation="wave" width={180} />
        ) : (
          <OverlineContainer>
            <CustomOverlineName variant="overline" data-testid="header-client-name">
              For {clientName ?? "-"}
            </CustomOverlineName>
            <CustomOverlineButton
              onClick={onEditClick}
              data-testid="edit-header-button"
              variant="text"
            >
              <EditIcon size={16} />
              EDIT
            </CustomOverlineButton>
          </OverlineContainer>
        )}
        <CustomH5 variant="h5" data-testid="header-cma-title">
          {loading ? <Skeleton animation="wave" width={180} /> : address ?? "-"}
        </CustomH5>
      </DetailsContainer>
    </HeaderContainer>
  );
};

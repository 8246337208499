import * as React from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldProps,
  styled,
} from "@avenue-8/ui-2";
import { useSuggestionsQueries } from "./useSuggestionsQueries";
import {
  INITIAL_FORM_SUGGESTIONS,
  parseSuggestions,
  suggestionFields,
  SuggestionFields,
  SuggestionOption,
} from "../../../components/CreateCMA/suggestions-form-model";
import { Popper } from "@mui/material";

const StyledPopper = styled(Popper)`
  .MuiListSubheader-root {
    line-height: 2rem;
  }
  ul {
    max-height: 250px;
  }
`;

export interface SuggestionsInputProps {
  textFieldProps: TextFieldProps;
  onSuggestionChosen: (data: { [type: string]: string | null }) => void;
  acceptedSuggestions: SuggestionFields[];
  numberOfSuggestions?: number;
  optionLabelText?: keyof SuggestionOption;
  deduplicateSuggestions?: boolean;
}

// Since we are deduplicating in the frontend, most of the time we are getting less then 5 results.
// This is part of a temporary fix, I believe it won't be in the frontend forever.
const MULTIPLIER = 2;

export const SuggestionsInput = React.forwardRef(
  (
    {
      textFieldProps,
      onSuggestionChosen,
      acceptedSuggestions,
      numberOfSuggestions = 5,
      optionLabelText = "text",
      deduplicateSuggestions = true,
    }: SuggestionsInputProps,
    _ref
  ) => {
    const [value, setValue] = React.useState(
      (textFieldProps.value ?? textFieldProps.defaultValue ?? "") as string
    );
    const [suggestions, setSuggestions] = React.useState<Array<SuggestionOption>>([]);

    const enableAcceptedSuggestions = React.useMemo(() => {
      const enabledSuggestions = {} as { [type: string]: boolean };
      suggestionFields.forEach((field) => {
        enabledSuggestions[field] = acceptedSuggestions.includes(field);
      });
      return enabledSuggestions;
    }, [acceptedSuggestions]);

    const singleSuggestionType = React.useMemo(
      () => acceptedSuggestions && acceptedSuggestions.length === 1,
      [acceptedSuggestions]
    );

    numberOfSuggestions =
      singleSuggestionType && deduplicateSuggestions
        ? numberOfSuggestions * MULTIPLIER
        : numberOfSuggestions;

    const { suggestionsQuery } = useSuggestionsQueries({
      ...INITIAL_FORM_SUGGESTIONS,
      ...enableAcceptedSuggestions,
      text: value,
      size: numberOfSuggestions,
    });

    React.useEffect(() => {
      const queryData = suggestionsQuery.data;

      if (!queryData) {
        setSuggestions([]);
        return;
      }

      const parsedSuggestions = parseSuggestions(queryData, acceptedSuggestions);

      if (singleSuggestionType && deduplicateSuggestions) {
        const deduplicatedSuggestions = parsedSuggestions.reduce((acc, curr) => {
          if (!acc.some((x) => x[optionLabelText] === curr[optionLabelText])) {
            acc.push(curr);
          }
          return acc;
        }, [] as SuggestionOption[]);

        const suggestionsFound =
          deduplicatedSuggestions?.slice(0, Math.round(numberOfSuggestions / MULTIPLIER)) || [];

        setSuggestions(suggestionsFound);
      } else {
        setSuggestions(parsedSuggestions);
      }
    }, [
      suggestionsQuery.data,
      acceptedSuggestions,
      singleSuggestionType,
      numberOfSuggestions,
      optionLabelText,
      deduplicateSuggestions,
    ]);

    React.useEffect(() => {
      setValue((textFieldProps.value ?? textFieldProps.defaultValue ?? "") as string);
    }, [textFieldProps.value, textFieldProps.defaultValue, setValue]);

    const handleSuggestionInputChange = async (e: any, value: string | SuggestionOption) => {
      e.preventDefault();
      if (typeof value === "string") {
        return;
      }
      const { type } = value;
      let valueToApply;

      if (type === "address" && value.addressLine1) {
        valueToApply = value.addressLine1;
      }

      if (type !== "address" && value[type]) {
        valueToApply = value[type];
      }

      if (Boolean(valueToApply) && Boolean(valueToApply?.trim())) {
        onSuggestionChosen({ ...value });
      }
    };

    return (
      <Autocomplete
        fullWidth
        freeSolo
        blurOnSelect
        includeInputInList
        disableClearable
        PopperComponent={StyledPopper}
        inputMode="search"
        value={value}
        options={suggestions}
        groupBy={(option) => (singleSuggestionType ? "" : option.typeLabel)}
        getOptionLabel={(option) => option[optionLabelText] || value}
        onChange={handleSuggestionInputChange}
        onInputChange={(_e, value) => setValue(value)}
        filterOptions={(options, _state) => options}
        renderInput={(params: AutocompleteRenderInputParams) => {
          return (
            <TextField
              {...params}
              {...textFieldProps}
              InputProps={{ ...params.InputProps, ...textFieldProps.InputProps }}
            />
          );
        }}
      />
    );
  }
);

import React from "react";

import { FormProvider, useForm } from "react-hook-form";
import { UseQueryResult } from "react-query";
import { TwoStepSearchBarField } from "src/modules/cma-v2/components/Fields/TwoStepSearchBarField";

import {
  Button,
  ChevronIcon,
  CompsTableIcon,
  FilterIcon,
  IconButton,
  SuggestedSearch,
  Typography,
} from "@avenue-8/ui-2";

import { appEventEmitter } from "../../../../../../../../events/app-event-emitter";
import {
  CMADto,
  ListPropertyResponseDto,
  PropertyDto,
} from "../../../../../../../shared/apis/cma/generated";
import { INITIAL_GEOGRAPHICAL_FILTERS, INITIAL_MAP_FILTERS } from "../../comparable-filters";
import { COMPARABLE_SORT_OPTIONS_MAP, SortOptionKey } from "../../comparable-sort-options";
import {
  activeFiltersCount,
  isModelEnoughToSearch,
  SearchComparableFormModel,
} from "../../search-comparable-form-model";
import { SearchFiltersModal } from "../SearchFiltersModal";
import { SearchSortMenu } from "../SearchSortMenu";
import { SideBySidePropertiesModal } from "../SideBySideProperties/SideBySidePropertiesModal";
import {
  SearchControls,
  SearchFieldWrapper,
  SearchAction,
  ResultsText,
  ListControls,
  ListControl,
} from "./SearchOptions.styles";
import RoleAuthorized from "src/modules/auth/components/RoleAuthorized";
import { blurActiveInputElement } from "src/modules/shared/utils/input-helpers";
import { ManualCompCTA } from "./ManualCompCTA";

interface PageConfig {
  [type: string]: {
    compsCount: string;
    sortOptions: SortOptionKey[];
  };
}
interface SearchOptionsProps {
  subjectProperty: PropertyDto | undefined;
  sameBuildingAddress: string;
  comparableProperties: CMADto["comparableProperties"];
  presentationType: string;
  filters: SearchComparableFormModel;
  searchProperties: UseQueryResult<ListPropertyResponseDto>;
  radiusFilterCoords: { lat: number; lng: number } | undefined;
  onApplyFilters: (data: SearchComparableFormModel) => void;
  onSearch: (data: Pick<SearchComparableFormModel, "search">) => void;
  onClearFilters: (presentationType: string) => void;
  onApplySuggestionWithFilters: (
    data: SearchComparableFormModel,
    chosenSuggestion: { [type: string]: string | null }
  ) => void;
  onDeleteProperty: (property: PropertyDto) => void;
  reorderComps: (properties: PropertyDto[]) => void;
  onAddComparablePropertyClick: () => void;
}

export const SearchOptions = (props: SearchOptionsProps) => {
  const {
    subjectProperty,
    sameBuildingAddress,
    comparableProperties,
    presentationType,
    filters,
    searchProperties,
    radiusFilterCoords,
    onApplyFilters,
    onClearFilters,
    onSearch,
    reorderComps,
    onApplySuggestionWithFilters,
    onDeleteProperty,
    onAddComparablePropertyClick,
  } = props;
  const { data } = searchProperties;
  const formMethods = useForm<SearchComparableFormModel>({
    defaultValues: { ...filters },
    reValidateMode: "onChange",
  });
  const { handleSubmit, setValue } = formMethods;
  const [filtersOpen, setFiltersOpen] = React.useState(false);
  const [isSideBySideModalOpened, setIsSideBySideModalOpened] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<React.ReactNode | null>(null);

  // maximum of 10000 results, 1000 pages of 10 items
  const resultsCount = Math.min(data?.meta?.totalItems || 0, 10000);
  const isResultsPanelVisible = isModelEnoughToSearch(filters);
  const numberOfComparableProperties = comparableProperties.length;
  const hasSeletedComps = numberOfComparableProperties > 0;

  const pageConfig = React.useMemo(() => {
    if (!presentationType) return null;
    const config: PageConfig = {
      cma: {
        compsCount: "Comps",
        sortOptions: ["relevance", "solddate", "price", "distance"],
      },
      general: {
        compsCount: "Listings",
        sortOptions: ["relevance", "solddate", "price"],
      },
    };
    return config[presentationType];
  }, [presentationType]);

  React.useEffect(() => {
    if (filters.sameBuildingSearch) {
      setValue("search", sameBuildingAddress);
    } else {
      setValue("search", "");
    }
  }, [filters.sameBuildingSearch, sameBuildingAddress, setValue]);

  const handleSearchBySameBuilding = () => {
    onApplyFilters({
      ...filters,
      ...INITIAL_GEOGRAPHICAL_FILTERS,
      mapFilters: INITIAL_MAP_FILTERS,
      sameBuildingSearch: true,
      search: subjectProperty?.addressLine1,
    });
  };

  const emitSortChanged = ({ asc, criteria }: { asc: boolean; criteria: string }) => {
    appEventEmitter.emit({
      eventType: "cma-search-sort-changed",
      asc,
      criteria,
      presentationType,
    });
  };

  const handleSort = (sortKey: SortOptionKey): void => {
    const sortOption = COMPARABLE_SORT_OPTIONS_MAP.get(sortKey);
    if (!sortOption) return;
    setAnchorEl(null);
    const sortDirection: boolean =
      filters.sort?.criteria === sortOption.key
        ? !filters.sort?.asc
        : sortOption.initialDirection === "asc";
    const sort = { asc: sortDirection, criteria: sortOption.key };
    const radiusFilter: Pick<SearchComparableFormModel, "radius"> =
      sortKey === "distance" ? { radius: "50" } : { radius: "" };
    onApplyFilters({ sort, ...radiusFilter });
    emitSortChanged(sort);
  };

  const handleToggleSortDirection = (): void => {
    const sort = { asc: !filters.sort?.asc, criteria: filters.sort?.criteria };
    onApplyFilters({ sort });
    emitSortChanged(sort);
  };

  const appliedFiltersText = () => {
    const appliedFiltersCount = activeFiltersCount(filters);
    if (!appliedFiltersCount) return null;
    return <span> ({appliedFiltersCount})</span>;
  };

  const handleSearch = (input: Pick<SearchComparableFormModel, "search">) => {
    onSearch(input);
    blurActiveInputElement();
  };

  return (
    <>
      <SearchControls>
        <SearchFieldWrapper $expand={!hasSeletedComps} id="search-field-wrapper">
          <FormProvider {...formMethods}>
            <form id="properties-search-form" onSubmit={handleSubmit(handleSearch)}>
              <TwoStepSearchBarField
                acceptedSuggestions={["address", "neighborhood", "city", "county", "mlsId"]}
                fieldProps={{
                  placeholder: "Search city, address, neighborhood, MLS...",
                  autoFocus: true,
                }}
                handleSearch={(value: string) => handleSearch({ ...filters, search: value })}
                onSuggestionChosen={(chosenSuggestion) => {
                  onApplySuggestionWithFilters(filters, chosenSuggestion);
                }}
              />
            </form>
          </FormProvider>
        </SearchFieldWrapper>
        <SearchAction
          endIcon={<FilterIcon />}
          onClick={() => setFiltersOpen(true)}
          data-testid="filter"
          id="open-filters-button"
        >
          <span>Filters{appliedFiltersText()}</span>
        </SearchAction>
        {hasSeletedComps && (
          <Button
            fullWidth
            endIcon={<CompsTableIcon />}
            onClick={() => setIsSideBySideModalOpened(true)}
            data-testid="comps-table"
          >
            {pageConfig?.compsCount}&nbsp;({numberOfComparableProperties})
          </Button>
        )}
        <SideBySidePropertiesModal
          open={isSideBySideModalOpened}
          title={`${String(comparableProperties.length).padStart(2, "0")} listings selected`}
          onClose={() => setIsSideBySideModalOpened(false)}
          subjectProperty={subjectProperty}
          properties={comparableProperties}
          onDeleteProperty={(property) => onDeleteProperty(property)}
          reorderComps={reorderComps}
        />
      </SearchControls>

      {!isResultsPanelVisible && (
        <>
          <ListControls $extraMargin>
            <ManualCompCTA onAddComparablePropertyClick={onAddComparablePropertyClick} />
          </ListControls>
          <RoleAuthorized
            role={"cma"}
            entityRole={presentationType}
            authorizedComponent={
              <SuggestedSearch
                text="search listings within the same building"
                onClick={handleSearchBySameBuilding}
                style={{ marginBottom: 32 }}
                data-testid="same-building"
              />
            }
          />
        </>
      )}

      {isResultsPanelVisible && (
        <>
          <ResultsText>
            <span data-testid="search-result-count">{resultsCount}</span> results
          </ResultsText>
          <ListControls>
            <ManualCompCTA onAddComparablePropertyClick={onAddComparablePropertyClick} />
            <ListControl>
              <Typography
                variant="body2"
                color="secondary"
                onClick={(e) => setAnchorEl(e.currentTarget)}
                data-testid="sort"
              >
                Sort by: {`${COMPARABLE_SORT_OPTIONS_MAP.get(filters.sort?.criteria)?.label}`}
              </Typography>
              <IconButton
                size="small"
                onClick={handleToggleSortDirection}
                disabled={filters.sort?.criteria === "relevance"}
                data-testid="sort-icon"
              >
                <ChevronIcon
                  rotate={filters.sort?.asc || filters.sort?.criteria === "relevance" ? 90 : -90}
                />
              </IconButton>
            </ListControl>
            <SearchSortMenu
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              onSortOptionClick={(key) => handleSort(key)}
              sortOptions={pageConfig?.sortOptions}
            />
          </ListControls>
        </>
      )}
      <SearchFiltersModal
        open={filtersOpen}
        filters={filters}
        onApplyFilters={onApplyFilters}
        onClearFilters={onClearFilters}
        onClose={() => setFiltersOpen(false)}
        presentationType={presentationType}
        radiusFilterCoords={radiusFilterCoords}
      />
    </>
  );
};

import { styled, Paper } from "@avenue-8/ui-2";

export const FixedTopNavigation = styled(Paper)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0 1rem;
  z-index: 4;
  @media print {
    position: static;
    transition: none;
    box-shadow: none;
  }
`;

export const StaticTopNavigation = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  padding: 0 1rem;
  box-shadow: 0;
  @media print {
    display: none;
  }
`;

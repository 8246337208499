import * as React from "react";

export function makeBLContext<LogicFacade, LogicProps>({
  useLogic,
}: {
  useLogic: (props: LogicProps) => LogicFacade;
}) {
  const ContainerContext = React.createContext<LogicFacade>(null as any);

  const ContainerContextProvider = (props: LogicProps & { children: React.ReactNode }) => {
    const { children, ...rest } = props;
    const facade = useLogic(rest as any);
    return <ContainerContext.Provider value={{ ...facade }}>{children}</ContainerContext.Provider>;
  };

  return {
    useLogicContext: () => {
      return React.useContext(ContainerContext);
    },
    LogicContextProvider: ContainerContextProvider,
  };
}

/********* USAGE ***********

function useSomeLogic(context: { id: number }){
    console.log(context);
    return {
      id,
      sayHello: () => console.log('hello')
    };
}

export const { LogicContextProvider: SomeLogicContextProvider, useLogicContext: useSomeLogicContext } = makeBLContext({ useLogic: useSomeLogic });

****************************/

import { Typography } from "@avenue-8/ui-2";
import { styledThemed as styled } from "../../theme";

export const Description = styled(Typography)`
  max-width: 520px;
  text-align: center;
  padding: 20px 0 20px 0;
  margin: 0 auto;
`;
export const Container = styled.div`
  background: ${(p) => p.theme.av8.backgroundAlt};
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > div {
    padding: 20px;
    width: 180px;
  }
`;
export const LabelWrapper = styled.div`
  display: flex;
  gap: 0rem;
  justify-content: center;
  align-items: center;
`;
export const ItemLabel = styled(Typography)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 300;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
  gap: 0.5rem;
  padding-top: 4px;
`;
export const ItemValue = styled(Typography)`
  font-family: ${(p) => p.theme.presentation.headerFontFamily};
  font-size: 1.2rem;
  text-align: center;
`;
export const BedBathsItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

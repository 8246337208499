import { Typography, Select, styled } from "@avenue-8/ui-2";

export const Form = styled.form`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.5rem;
  label {
    font-size: 12px;
  }
`;

export const CustomSelect = styled(Select)`
  width: 196px;
`;

export const ByDivider = styled(Typography)`
  font-family: ${(p) => p.theme.av8.typography.fontFamily};
  font-size: 10px;
  letter-spacing: 2px;
  color: ${(p) => p.theme.av8.secondaryColor};
`;

/* tslint:disable */
/* eslint-disable */
/**
 * CMA API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CMA,
    CMAFromJSON,
    CMAFromJSONTyped,
    CMAToJSON,
    SubjectProperty,
    SubjectPropertyFromJSON,
    SubjectPropertyFromJSONTyped,
    SubjectPropertyToJSON,
} from './';

/**
 * 
 * @export
 * @interface PatchCMARequestDto
 */
export interface PatchCMARequestDto {
    /**
     * 
     * @type {SubjectProperty}
     * @memberof PatchCMARequestDto
     */
    subjectProperty?: SubjectProperty;
    /**
     * 
     * @type {CMA}
     * @memberof PatchCMARequestDto
     */
    root?: CMA;
}

export function PatchCMARequestDtoFromJSON(json: any): PatchCMARequestDto {
    return PatchCMARequestDtoFromJSONTyped(json, false);
}

export function PatchCMARequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchCMARequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'subjectProperty': !exists(json, 'subjectProperty') ? undefined : SubjectPropertyFromJSON(json['subjectProperty']),
        'root': !exists(json, 'root') ? undefined : CMAFromJSON(json['root']),
    };
}

export function PatchCMARequestDtoToJSON(value?: PatchCMARequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'subjectProperty': SubjectPropertyToJSON(value.subjectProperty),
        'root': CMAToJSON(value.root),
    };
}



import { Loader } from "@avenue-8/ui-2";
import { UseQueryResult } from "react-query";
import {
  ListPropertyResponseDto,
  PropertyDto,
} from "../../../../../../../shared/apis/cma/generated";
import { ComparablePropertyResultItem } from "../ComparablePropertyResultItem";
import { useCreateCMALogic } from "../../../../../../hooks/useCreateCMALogic";
import { buildPropertyKey } from "../../../../../../../shared/utils/buildPropertyKey";
import {
  Wrapper,
  NoResultsContainer,
  NoResultsTitle,
  NoResultsSubtitle,
} from "./SearchResults.styles";

interface SearchResultsProps {
  searchQuery: UseQueryResult<ListPropertyResponseDto, unknown>;
  onResultHover: (resultId: string | null) => void;
  onResultClicked: (result: PropertyDto | null) => void;
}

export const SearchResults = (props: SearchResultsProps) => {
  const { searchQuery, onResultHover, onResultClicked } = props;
  const { state, actions } = useCreateCMALogic();
  const selectedCompsComposedIds = state.cma?.comparableProperties.map(buildPropertyKey);
  if (searchQuery.isIdle) return <div></div>;
  // isPreviousData is a boolean which is true when we are seeing a "cached" result, i.e. we are fetching a new page
  if (searchQuery.isLoading || (searchQuery.isFetching && searchQuery.isPreviousData))
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  if (searchQuery.isError) return <div>Error during properties search</div>;

  if (searchQuery.data?.properties.length === 0) {
    return (
      <NoResultsContainer>
        <NoResultsTitle variant="body1" color="secondary">
          No Search Results
        </NoResultsTitle>
        <NoResultsSubtitle variant="body2">
          We couldn't find any listings with the applied filters. Please expand your search to find
          properties.
        </NoResultsSubtitle>
      </NoResultsContainer>
    );
  }

  const handleSelectProperty = (property: PropertyDto | undefined, action: string) => {
    if (!property) return;
    if (action === "ADD") {
      actions.selectCompListing(property);
    } else {
      actions.removeCompListing(property);
    }
  };

  const handleClickProperty = (property: PropertyDto | null) => {
    onResultClicked(property);
  };

  return (
    <div data-testid="search-results">
      {searchQuery.data?.properties.map((property, i) => {
        const composedKey = buildPropertyKey(property);
        return (
          <ComparablePropertyResultItem
            property={property}
            key={`${composedKey}-${i}`}
            onMouseEnter={() => onResultHover(composedKey || null)}
            onMouseLeave={() => onResultHover(null)}
            onSelectProperty={(action) => handleSelectProperty(property, action)}
            isCompsListingMutating={state.isCompsListingMutating}
            onClickProperty={() => handleClickProperty(property)}
            isChecked={selectedCompsComposedIds?.includes(composedKey)}
          />
        );
      })}
    </div>
  );
};

import { useState } from "react";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { appEventEmitter } from "../../../../events/app-event-emitter";
import { getCMAApiClient, getCMAAgentsApiClient } from "../../../shared/apis/cma/api-factories";
import { ListCMAResponseItemDto } from "../../../shared/apis/cma/generated";
import { makeBLContext } from "../../../shared/hooks/makeBLContext";
import { useLatest } from "../../../shared/hooks/useLatest";
import { useOnAnyIsMutating } from "../../../shared/react-query-extensions";
import { useCloneCMA } from "./useCloneCMA";
import { useDeleteCMA } from "./useDeleteCMA";

interface DashboardState {
  orderBy: "created-desc" | "updated-desc";
  agentFilter?: number;
  statusFilter?: string;
}

function useDashboardLogicInner() {
  const [state, setState] = useState<DashboardState>({ orderBy: "updated-desc" });
  const latestOrderBy = useLatest(state.orderBy);

  const queryClient = useQueryClient();

  const { data: agents, status: agentsStatus } = useQuery(["cma-agents"], async () =>
    getCMAAgentsApiClient().agentControllerGetAllAgentsSimple()
  );

  useOnAnyIsMutating({
    mutationFilters: [{ mutationKey: "delete-cma" }, { mutationKey: "clone-cma" }],
    handler: (isMutating) => {
      if (!isMutating) {
        const mutationKey = "cmas";
        queryClient.cancelQueries(mutationKey);
        queryClient.invalidateQueries(mutationKey);
      }
    },
  });

  const {
    data: cmasData,
    status: cmasStatus,
    fetchNextPage: fetchNextCmasPage,
    hasNextPage: hasNextCmasPage,
    isFetchingNextPage: isFetchingNextCmasPage,
  } = useInfiniteQuery(
    ["cmas"],
    async ({ pageParam = 1 }) => {
      const result = await getCMAApiClient().cMAControllerList({
        page: pageParam,
        agentId: state.agentFilter,
        orderBy: state.orderBy,
        status: state.statusFilter,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage, _allPages) => {
        const {
          meta: { currentPage, totalPages },
        } = lastPage;
        return totalPages > currentPage && currentPage + 1;
      },
    }
  );
  const cmasPages = cmasData?.pages;

  const getLoadedCMAItem = (cmaId: string): ListCMAResponseItemDto | undefined => {
    const allCmas = cmasPages?.flatMap((page) => page.items);
    return allCmas?.find((item) => item.id === cmaId);
  };

  const cloneCMA = useCloneCMA({ getLoadedCMAItem, latestOrderBy });

  const deleteCMA = useDeleteCMA({ getLoadedCMAItem });

  const setOrderBy = (orderBy: DashboardState["orderBy"]) => {
    setState((p) => ({ ...p, orderBy }));
    appEventEmitter.emit({ eventType: "cma-dashboard-sort-changed", sortBy: orderBy });
    queryClient.invalidateQueries("cmas");
  };

  const setAgentFilter = (agentFilter?: number) => {
    setState((p) => ({ ...p, agentFilter }));
    appEventEmitter.emit({ eventType: "cma-dashboard-filters-changed", filter: "agent" });
    queryClient.invalidateQueries("cmas");
  };

  const setStatusFilter = (statusFilter?: string) => {
    setState((p) => ({ ...p, statusFilter }));
    appEventEmitter.emit({ eventType: "cma-dashboard-filters-changed", filter: "status" });
    queryClient.invalidateQueries("cmas");
  };

  return {
    state: {
      agents,
      agentsStatus,
      cmasPages,
      cmasStatus,
      isFetchingNextCmasPage,
      hasNextCmasPage,
    },
    actions: {
      setOrderBy,
      setAgentFilter,
      setStatusFilter,
      cloneCMA,
      delete: deleteCMA,
      fetchNextCmasPage: hasNextCmasPage && fetchNextCmasPage,
    },
  };
}

export const { LogicContextProvider: DahsboardLogicProvider, useLogicContext: useDashboardLogic } =
  makeBLContext({ useLogic: useDashboardLogicInner });

import { AccordionSummary, Typography } from "@mui/material";
import {
  styled,
} from "@avenue-8/ui-2";

export const DragHandleContainer = styled.div`
  cursor: move;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 16px 0;
  width: 40px;
  min-width: 40px;
  box-sizing: border-box;
  svg {
    color: ${(p) => p.theme.av8.primaryColor};
    max-height: 16px;
  }
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
  &:hover {
    .eye-hidden-icon {
      opacity: 1 !important;
    }
  }
  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 8px;
    padding: 0 12px;
  }
`;

export const AccordionItemText = styled(Typography)`
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: ${(p) => p.theme.av8.typography.primaryColor};
  width: 100%;
  word-break: break-word;
`;

export const AccordionSummaryContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ShowHideContainer = styled.div<{ $hidden?: boolean }>`
  width: 24px;
  height: 24px;
  justify-self: flex-end;

  .eye-hidden-icon {
    opacity: ${(p) => (p.$hidden ? 1 : 0)};
  }
`;
import { Typography, css, styled } from "@avenue-8/ui-2";
import { media } from "../../../shared/css-snippets";

export const WelcomeMessage = styled(Typography)`
  flex-grow: 1;
  span[data-name="username"] {
    display: block;
  }
`;
const shrinkStyle = css`
  h2 {
    transform: translateY(0.25em);
    font-size: 1.5rem;
    line-height: 40px;
    span[data-name="username"] {
      display: inline;
    }
  }
`;
export const StyledDashboardBar = styled.div<{ $shrink: boolean }>`
  display: flex;
  align-items: flex-start;
  h2 {
    font-size: 4rem;
    transition: all 250ms;
    line-height: 70px;
    letter-spacing: -1.5px;
  }
  ${() => media.mobileDown} {
    h2 {
      font-size: clamp(2rem, 12vw, 3rem);
      letter-spacing: -1px;
      line-height: 1.1;
    }
  }
  padding: 0;
  ${(p) => p.$shrink && shrinkStyle}
`;

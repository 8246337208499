import * as React from "react";
import { InputLabel } from "@avenue-8/ui-2";
import { FormHelperText } from "@mui/material";
import { EstimatePriceFormModel } from "../../../../../Estimate/components/EstimatePriceForm";
import { validateMinMax } from "../../../../../Estimate/estimate-price";
import NumberFormat from "react-number-format";
import { Controller, useFormContext } from "react-hook-form";
import { FormItem } from "./EstimateRangeForm.styles";
import { TextFieldBase } from "src/modules/cma-v2/components/TextFieldBase";
import { MAX_INTEGER_VALUE } from "src/modules/shared/constants";

type EstimateRangeFormProps = {
  handleSave: () => Promise<void>;
};

export const EstimateRangeForm = (props: EstimateRangeFormProps) => {
  const { handleSave } = props;
  const { getValues, trigger, errors } = useFormContext<EstimatePriceFormModel>();

  return (
    <form autoComplete="off" id="estimate-range">
      <FormItem fullWidth>
        <InputLabel htmlFor="estimate-range">From (Minimum)</InputLabel>
        <Controller
          name="estimatedPriceMin"
          rules={{
            validate: (estimatedPriceMin) =>
              validateMinMax(estimatedPriceMin, getValues("estimatedPriceMax")),
          }}
          render={({ ref, ...rest }) => (
            <NumberFormat
              customInput={TextFieldBase}
              prefix={"$"}
              thousandSeparator={true}
              decimalScale={0}
              placeholder="Min Price"
              error={!!errors.estimatedPriceMin}
              onValueChange={(v) => {
                rest.onChange(v.value);
              }}
              {...rest}
              onChange={(e) => {
                rest.onChange(e);
                handleSave();
              }}
              getInputRef={ref}
              isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
            />
          )}
        />
      </FormItem>
      <FormItem fullWidth margin="normal">
        <InputLabel htmlFor="estimate-range">To (Maximum)</InputLabel>
        <Controller
          name="estimatedPriceMax"
          render={({ ref, ...rest }) => (
            <NumberFormat
              customInput={TextFieldBase}
              prefix={"$"}
              thousandSeparator={true}
              decimalScale={0}
              error={!!errors.estimatedPriceMin}
              placeholder="Max Price"
              onValueChange={(v) => {
                rest.onChange(v.value);
                trigger("estimatedPriceMin");
              }}
              {...rest}
              onChange={(e) => {
                rest.onChange(e);
                handleSave();
              }}
              getInputRef={ref}
              isAllowed={({ floatValue }) => Number(floatValue ?? 0) <= MAX_INTEGER_VALUE}
            />
          )}
        />
      </FormItem>
      <FormHelperText error={!!errors.estimatedPriceMin} margin="dense">
        {errors.estimatedPriceMin?.message}
      </FormHelperText>
    </form>
  );
};
